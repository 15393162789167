import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CancerSite, DisclaimerVariant, Drug, GenePattern, HotspotClinicalTrailReqDto, InterpretationReqDto, PrognasticBiomarkerReqDto, TestTypeGene } from '../_interfaces.ts/admin';
import { pluck, share, shareReplay, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DataAdminService {

  constructor(
    private httpClient: HttpClient
  ) { }

  addCancerType(body: any) {
    return this.httpClient.post('/admin/add_cancer_type', body);
  }

  cancerTypeList() {
    return this.httpClient.get('/admin/get_cancer_types');
  }

  deleteCancerType(id: number) {
    return this.httpClient.get('/admin/' + id + '/delete_cancer_type');
  }

  addGene(body: any) {
    return this.httpClient.post('/admin/add_gene', body);
  }
  addDomainFunction(body: any) {
    return this.httpClient.post('/admin/add_domain_function', body);
  }
  addTruncationDetails(body: any) {
    return this.httpClient.post('/admin/add_truncation_details', body);
  }
  getGeneByName(geneName: string){
    return this.httpClient.get('/admin/' + geneName + '/get_gene');
  }

  geneList() {
    return this.httpClient.get('/admin/gene_list');
  }

  deleteGene(id: number) {
    return this.httpClient.get('/admin/' + id + '/delete_gene');
  }

  getDomainFunctionsList() {
    return this.httpClient.get('/admin/domain_function_list');
  }
  deleteDomainFunction(id: number) {
    return this.httpClient.get('/admin/' + id + '/delete_domain_function');
  }

  getTruncationDetailsList() {
    return this.httpClient.get('/admin/get_truncation_details_list');
  }
  deleteTruncationDetails(id: number) {
    return this.httpClient.get('/admin/' + id + '/delete_truncation_details');
  }
  uploadAndGetTruncationDetailsDataFromExcel(params: any){
    return this.httpClient.post('/admin/upload_truncation_details_excel', params);
  }

  uploadAndGetDomainFunctionDataFromExcel(params: any){
    return this.httpClient.post('/admin/upload_domain_function_excel', params);

  }
  addPathway(body: any) {
    return this.httpClient.post('/admin/add_pathway', body);
  }

  getPathway(pathwayId: number) {
    return this.httpClient.get('/admin/' + pathwayId + '/get_pathway');
  }

  getPathwayList() {
    return this.httpClient.get('/admin/pathway_list');
  }

  deletePathway(id: number) {
    return this.httpClient.get('/admin/' + id + '/delete_pathway');
  }

  addGenePathway(body: any) {
    return this.httpClient.post('/admin/add_gene_pathway', body);
  }

  getGenePathwayList() {
    return this.httpClient.get('/admin/gene_pathway_list');
  }

  deleteGenePathway(id: number) {
    return this.httpClient.get('/admin/' + id + '/delete_gene_pathway');
  }

  addDrug(drug: Drug){
    return this.httpClient.post('/admin/add_drug',drug);
  }

  getDrugById(id: number){
    return this.httpClient.get('/admin/' + id + '/get_drug');
  }

  getDrugs(){
    return this.httpClient.get('/admin/get_drugs');
  }

  deleteDrug(id: number) {
    return this.httpClient.get('/admin/' + id + '/delete_drug');
  }

  addTherapy(body: any){
    return this.httpClient.post('/admin/add_therapy',body);
  }

  getTherapyList(){
    return this.httpClient.get('/admin/therapy_list');
  }

  deleteTherapy(id: number) {
    return this.httpClient.get('/admin/' + id + '/delete_therapy');
  }

  addClinicalTrail(body: any){
    return this.httpClient.post('/admin/add_clinical_trail',body);
  }

  getClinicalTrail(id: number){
    return this.httpClient.get('/admin/' + id +'/get_clinical_trial');
  }

  getAllClinicalTrailsCount(geneName: string){
    return this.httpClient.get('/admin/clinical_trail_count/' + geneName);
  }

  getAllClinicalTrailByGene(refGeneX: string){
    return this.httpClient.get('/admin/' + refGeneX + '/clinical_trial_list');
  }

  getClinicalTrailList(){
    return this.httpClient.get('/admin/clinical_trial_list');
  }

  deleteClinicalTrail(id: number){
    return this.httpClient.get('/admin/'+id+'/delete_clinical_trial');
  }

  addHotspotClinicalTrail(body: HotspotClinicalTrailReqDto){
    return this.httpClient.post('/admin/add_hotspot_clinical_trail',body);
  }

  getHotspotClinicalTrail(id: number){
    return this.httpClient.get('/admin/' + id +'/get_hotspot_clinical_trial');
  }

  getAllHotspotClinicalTrailsCount(refGeneX: string){
    return this.httpClient.get('/admin/hotspot_clinical_trial_count/' + refGeneX);
  }

  getAllHotspotClinicalTrailByGene(refGeneX: string){
    return this.httpClient.get('/admin/' + refGeneX + '/hotspot_clinical_trial_list');
  }

  hotspotClinicalTrailList(){
    return this.httpClient.get('/admin/hotspot_clinical_trial_list').pipe(shareReplay())
  }

  deleteHotspotClinicalTrail(id: number){
    return this.httpClient.get('/admin/'+id+'/delete_hotspot_clinical_trial');
  }

  addInterpretation(body: InterpretationReqDto){
    return this.httpClient.post('/admin/add_interpretation',body);
  }

  interpretationList(){
    return this.httpClient.get('/admin/interpretation_list');
  }
  interpretationListByType(type:string){
    return this.httpClient.get(`/admin/${type}/interpretation_list`);
  }

  deleteInterpretation(id: number){
    return this.httpClient.get('/admin/'+id+'/delete_interpretation');
  }

  uploadImage(params: any) {
    return this.httpClient.post('/admin/upload_image', params);
  }
  getInterpretationById(id: number){
    return this.httpClient.get('/admin/' + id + '/get_Interpretation');
  }

  addDisclaimer(body:DisclaimerVariant){
    return this.httpClient.post('/admin/add_disclaimer_details',body);
  }

  disclaimerList(){
    return this.httpClient.get('/admin/disclaimer_list');
  }
  disclaimerListByType(type:string){
    return this.httpClient.get(`/admin/${type}/disclaimer_list`);
  }

  getDisclaimerById(id:number){
    return this.httpClient.get('/admin/' + id + '/get_disclaimer');
  }

  deleteDisclaimer(id:number){
    return this.httpClient.get('/admin/'+id+'/delete_disclaimer');
  }


  addGenePattern(body:GenePattern){
    return this.httpClient.post('/admin/add_genepattern_details',body);
  }

  genePatternList(){
    return this.httpClient.get('/admin/genepattern_list');
  }

  getGenePatternById(id:number){
    return this.httpClient.get('/admin/' + id + '/get_genepattern');
  }

  deleteGenePattern(id:number){
    return this.httpClient.get('/admin/'+id+'/delete_genepattern');
  }

  addTestTypeGenesDetails(body:TestTypeGene){
    return this.httpClient.post('/admin/add_test_type_genes_details',body);
  }

  geneTestTypeGeneList(){
    return this.httpClient.get('/admin/test_type_gene_list');
  }

  getTestTypeGeneById(id:number){
    return this.httpClient.get('/admin/' + id + '/get_test_type_gene');
  }

  deleteTestTypeGene(id:number){
    return this.httpClient.get('/admin/'+id+'/delete_test_type_gene');
  }

  addFilterEngine(body:any){
    return this.httpClient.post('/admin/add_filterEngine',body);
  }

  getFilterEngineList(){
    return this.httpClient.get('/admin/filterengine_list');
  }
  getFilterEngineById(id:number){
    return this.httpClient.get('/admin/'+id+'/get_filterengine');
  }

  deleteFilterEngineById(id:number){
    return this.httpClient.get('/admin/'+id+'/delete_filterengine');
  }
  addCancerSite(body:CancerSite){
    return this.httpClient.post('/admin/add_cancersite_details',body);
  }

  getCancerSiteList(){
    return this.httpClient.get('/admin/cancersite_list');
  }
  getAllReportTypes(){
    return this.httpClient.get('/admin/list_report_type');
  }

  getCancerSiteById(id:number){
    return this.httpClient.get('/admin/'+id+'/get_cancersite');
  }

  deleteCancerSite(id: number){
    return this.httpClient.get('/admin/'+id+'/delete_cancersite');
  }

  addCancerSiteGene(body:any){
    return this.httpClient.post('/admin/add_cancersite_gene_details',body);
  }

  getCancerSiteGeneList(){
    return this.httpClient.get('/admin/cancersitegene_list');
  }

  getCancerSiteGeneById(id:number){
    return this.httpClient.get('/admin/'+id+'/get_cancersite_gene');
  }

  deleteCancerSiteGene(id: number){
    return this.httpClient.get('/admin/'+id+'/delete_cancersite_gene');
  }

  addPrognasticBiomarker(body: PrognasticBiomarkerReqDto){
    return this.httpClient.post('/admin/add_prognastic_biomarker',body);
  }

  prognasticBiomarkerList(){
    return this.httpClient.get('/admin/prognastic_biomarker_list');
  }

  deletePrognasticBiomarker(id: number){
    return this.httpClient.get('/admin/'+id+'/delete_prognastic_biomarker');
  }

  getPrognasticBiomarkerById(id: number){
    return this.httpClient.get('/admin/' + id + '/get_prognastic_biomarker');
  }

  getPrognasticBySignificance(type: string){
    return this.httpClient.get('/admin/' + type + '/get_prognastic_by_significance');
  }

  uploadAndGetGenesFromExcel(params: any){
    return this.httpClient.post('/admin/get_genes_upload',params);
  }

}

