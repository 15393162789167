import { DnaAndRnaDetailsReq, SampleIdData } from './../_interfaces.ts/reports';
import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ImmunoDetails } from '../_interfaces.ts/reports';
import { ReportVariant } from '../_interfaces.ts/excel';
import { PaginationReqInterface } from '../_interfaces.ts/pagination';

@Injectable({
  providedIn: 'root'
})
export class ReportService {
  constructor(
    private httpClient: HttpClient
  ) { }


  addReportType(reportId: number, reportType: string) {
    return this.httpClient.get('/report/' + reportId + '/add_report_type/' + reportType);
  }

  changeReportStatus(reportId: number, reportStatus: string){
    return this.httpClient.get('/report/' + reportId + '/change_report_status/' + reportStatus);
  }

  addFirstCancerType(reportId: number, cancerTypeId: number) {
    return this.httpClient.get('/report/' + reportId + '/add_cancer_type/' + cancerTypeId);
  }

  addGermlineSignificance(reportId: number, significanceResult: string) {
    return this.httpClient.post('/report/' + reportId + '/add_germline_significance', significanceResult);
  }

  addClinicalSummary(reportId: number, clinicalSummary: string) {
    return this.httpClient.post('/report/' + reportId + '/add_clinical_summary', clinicalSummary);
  }

  addAdditionalNotes(reportId: number, addNotes: string) {
    return this.httpClient.post('/report/' + reportId + '/add_additional_notes', addNotes);
  }

  addInterestGenes(reportId: number, interestGenes: string) {
    return this.httpClient.post('/report/' + reportId + '/add_interest_genes', interestGenes);
  }

  addingRnaAndDna(reportId: number, body: DnaAndRnaDetailsReq) {
    return this.httpClient.post('/report/' + reportId + '/add_dna_rna', body);
  }

  addEvidenceSummary(reportId: number, evidenceSummary: string) {
    return this.httpClient.post('/report/' + reportId + '/add_evidence_summary', evidenceSummary);
  }

  addPMIds(reportId: number, pmIds: string) {
    let requestBody:any={
      pmid:pmIds
    }
    return this.httpClient.post('/report/' + reportId + '/add_pmIds', requestBody);

  }

  addInterpretation(reportId: number, interpretationDTO: any) {
    return this.httpClient.post('/report/' + reportId + '/add_interpretation', interpretationDTO)
  }

  addDisclaimer(reportId: number, disclaimer: string) {
    return this.httpClient.post('/report/' + reportId + '/add_disclaimer', disclaimer)
  }
  addDisclaimerNote(reportId: number, disclaimerNote: string) {
    return this.httpClient.post('/report/' + reportId + '/add_disclaimer_note', disclaimerNote)
  }

  addReferenceWebsite(reportId: number, referenceWebsite: string) {
    return this.httpClient.post('/report/' + reportId + '/add_reference_website', referenceWebsite)
  }

  addImmunoDetails(reportId: number, body: ImmunoDetails) {
    return this.httpClient.post('/report/' + reportId + '/add_immuno_details', body)
  }

  getReports(paginationReq:PaginationReqInterface) {
    if(paginationReq.organisationName != null && paginationReq.organisationName != '4basecare'){
      return this.httpClient.post('/report/list/' + paginationReq.organisationName, paginationReq);
    } else {
    return this.httpClient.post('/report/list',paginationReq);
    }
  }

  getNewReports(paginationReq:PaginationReqInterface) {
    if(paginationReq.organisationName != null && paginationReq.organisationName != '4basecare'){
      return this.httpClient.post('/report/newlist/' + paginationReq.organisationName, paginationReq);
    } else {
    return this.httpClient.post('/report/newlist',paginationReq);
    }
  }

  getReportedReports(paginationReq:PaginationReqInterface) {
    if(paginationReq.organisationName != null && paginationReq.organisationName != '4basecare'){
      return this.httpClient.post('/report/reportedlist/' + paginationReq.organisationName, paginationReq);
    } else {
    return this.httpClient.post('/report/reportedlist',paginationReq);
    }
  }

  getReport(reportId: number) {
    return this.httpClient.get('/report/' + reportId + '/get_report');
  }

  getPdfReportRes(reportId: number) {
    return this.httpClient.get('/report/' + reportId + '/pdf_response');
  }

  firstReportPdfResponse(reportId: number) {
    return this.httpClient.get('/report/' + reportId + '/first_pdf_response');
  }

  lungLiquidAdvancedReportPdfResponse(reportId: number) {
    return this.httpClient.get('/report/' + reportId + '/lung_liquid_advanced_pdf_response');
  }

  lungLiquidBasicReportPdfResponse(reportId: number) {
    return this.httpClient.get('/report/' + reportId + '/lung_liquid_basic_pdf_response');
  }
  somaticReportPdfResponse(reportId: number) {
    return this.httpClient.get('/report/' + reportId + '/somatic_pdf_response');
  }


  germlineReportPdfResponse(reportId: number) {
    return this.httpClient.get('/report/' + reportId + '/germline_pdf_response');
  }

  brcaReportPdfResponse(reportId: number) {
    return this.httpClient.get('/report/' + reportId + '/brca_pdf_response');
  }

  pdfChecking(reportId: number) {
    return this.httpClient.get('/report/' + reportId + '/pdf_checking');
  }

  uploadAndGetDataFromExcel(params: any, reportId: Number) {
    return this.httpClient.post('/report/' + reportId + '/upload_filteredCVS_file', params);
  }

  autoUploadFengFile(reportId: Number) {
    return this.httpClient.post('/report/' + reportId + '/auto_upload_feng_file',null);
  }

  selectAAChangeknownGene(reportVariantId: number, selectedAAChangeknownGene: string) {
    return this.httpClient.post('/report/' + reportVariantId + '/select_aaChangeknownGene', selectedAAChangeknownGene);
  }

  getReportVariantById(reportVariantId: number) {
    return this.httpClient.get('/report/' + reportVariantId + '/get_report_variant');
  }

  getAllReportVariantByReportId(reportId: number) {
    return this.httpClient.get('/report/' + reportId + '/get_report_variants');
  }

  getSelectedReportedVariants(reportId: number) {
    return this.httpClient.get('/report/' + reportId + '/get_selected_report_variants');
  }

  changeReportVariantStatus(reportVariantId: number, aBoolean: boolean) {
    return this.httpClient.post('/report/' + reportVariantId + '/report_variant_status', aBoolean);
  }

  reportVariantDeSelectAllTrails(reportVariantId: number) {
    return this.httpClient.get('/report/' + reportVariantId + '/report_variant_deselect_all');
  }

  changeReportVariantStarStatus(reportVariantId: number, aBoolean: boolean) {
    return this.httpClient.post('/report/' + reportVariantId + '/report_variant_star_status', aBoolean);
  }

  setPriorityOrder(reportVariantId: number, priorityOrderNumber: number) {
    return this.httpClient.post('/report/' + reportVariantId + '/priority_order', priorityOrderNumber);
  }

  setTailsPriorityOrder(selectedTrailsId: number, priorityOrderNumber: number) {
    return this.httpClient.post('/report/' + selectedTrailsId + '/trail_priority_order', priorityOrderNumber);
  }

  addReportVariantSignificance(reportVariantId: number, body: any) {
    return this.httpClient.post('/report/' + reportVariantId + '/add_significance', body);
  }

  addSampleID(body: SampleIdData) {
    return this.httpClient.post('/patient/add/sample_id', body);
  }

  getDocuments(ecrfId: string){
    return this.httpClient.get('/report/get_documents/' + ecrfId);
  }

  // uploadFinalReport(params: any, ecrfId: string, reportStatus: string){
  //   return this.httpClient.post('/report/upload/final_report/' + ecrfId + "/" + reportStatus, params);
  // }

  uploadFinalReport( ecrfId: string,body: any){
    return this.httpClient.post('/report/upload/final_report/' + ecrfId , body);
  }

  updateReportVariant(variantId:number,body:ReportVariant){
    return this.httpClient.post('/report/' + variantId + "/updateVariant" , body);
  }
  // {reportId}/createVariant
  createReportVariant(reportId:number,body:ReportVariant){
    return this.httpClient.post('/report/' + reportId + "/createVariant" , body);
  }

  public downloadPdf(url:any): any {
    return this.httpClient.get(url, {responseType: 'arraybuffer'},)
  }
  getFiltersValue(reportId: number,filters:string){
    return this.httpClient.post(`/report/${reportId}/getFilters`,filters);
  }

  getFilterData(reportId: number,filters:any){
    return this.httpClient.post(`/report/${reportId}/getFilterData`,filters);
  }

  saveFilter(reportId: number,filters:any){
    return this.httpClient.post(`/report/${reportId}/saveReportFilter`,filters);
  }

  addFirstCancerSite(reportId: number, cancerTypeId: number) {
    return this.httpClient.get('/report/' + reportId + '/add_cancer_site/' + cancerTypeId);
  }
  first72ReportPdfResponse(reportId: number) {
    return this.httpClient.get('/report/' + reportId + '/first72_pdf_response');
  }

  getAndSetOncoBenchPatientsDetails(patientId: string) {
    return this.httpClient.get('/report/' + patientId + '/oncobench_details');
  }

  updateOncoBenchStatus(reportId: string){
    return this.httpClient.get('/report/update_oncobench_status/' + reportId);
  }

  addResultValue(reportId: number, resultValue: any) {
    return this.httpClient.post(`/report/${reportId}/add_result_value`,resultValue);
  }

  addKeyInformation(reportId: number, keyInformation: string) {
    return this.httpClient.post('/report/' + reportId + '/add_key_information', keyInformation)
  }
}
