<!-- segment tabs -->
<div class="container-fluid mt-3">
  <div class="row">
    <div class="col pl-0">
      <ul class="segmented-control d-flex list-unstyled ml-0">
        <li class="segmented-control__item">
          <a (click)="newReports()"
            ><input
              class="segmented-control__input"
              type="radio"
              value="MALE"
              name="gender"
              id="option-1"
              checked
            />
            <label
              class="segmented-control__label position-relative"
              for="option-1"
              >New
              <div class="notification">{{ newCount }}</div></label
            >
          </a>
        </li>
        <li class="segmented-control__item">
          <a (click)="processReports()"
            ><input
              class="segmented-control__input"
              type="radio"
              value="reports"
              name="gender"
              id="option-2"
            />
            <label
              class="segmented-control__label position-relative"
              for="option-2"
              >Processing
              <div class="notification">
                {{ processCount }}
              </div></label
            ></a
          >
        </li>
        <li class="segmented-control__item">
          <a (click)="deliveredReports()"
            ><input
              class="segmented-control__input"
              type="radio"
              value="new_reports"
              name="gender"
              id="option-3"
            />
            <label
              class="segmented-control__label position-relative"
              for="option-3"
              >Delivered
              <div class="notification">
                {{ deliveredCount }}
              </div></label
            ></a
          >
        </li>
      </ul>
    </div>
  </div>
</div>

<!--  -->
<div *ngIf="newReportsView" class="container-fluid px-4 mt-5">
     <div class="d-flex justify-content-between align-items-center rep_min-height">
      <!-- <h5 class="col-9 text_color  font-weight-bold mb-0">Process Reports</h5> -->
      <div class="d-flex justify-content-between align-items-center">
        <h5 class="text_color  font-weight-bold mb-0">New Reports</h5>
        <div class=" ml-2">
          <select
          class=" bg-white form-control w-80 dropdown-toggle"
          (change)="changePageSize($event)"
        >
        <option *ngFor="let page of pageSize" [value]="page">{{page}}</option>
        </select>
        </div>

      </div>
      <div class="row reports_info  reports_info mr-0">
        <label class="col-4 text-right ">Search:  </label>
        <input
          class="col form-control"
          type="text"
          name="search"
          (ngModelChange)="searchNewData($event)"
          [(ngModel)]="searchNewText"
          autocomplete="off"
          placeholder="Key"
        />

      </div>
    </div>

  <div class="p-4 bg-white cust_shadow rounded mb-4">
    <table class="table">
      <thead class="thead-light">
        <tr>
          <th>Patient Id</th>
          <th>Patient Name</th>
          <th>Org Id</th>
          <th>Collected On</th>
          <th>Organisation</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let report of newReportsList| paginate  : {
          id: 'new_pagination',
          itemsPerPage: 10,
          currentPage: newCurrentPage,
          totalItems: newTotalPages * 10
        };">
          <td>{{ report.patient.ecrfId }}</td>
          <td>{{ report.patient.name }}</td>
          <td>{{ report.patient.organisationPatientId }}</td>
          <td *ngIf="report.labDetails.sampleCollected != null">
            {{ report.labDetails.sampleCollected }} ({{report.testPackage}})
          </td>
          <td *ngIf="report.labDetails.sampleCollected == null">NA</td>
          <td>{{ report.patient.organisationName }}</td>
          <td>
            <ul class="list-unstyled d-flex actions mb-0">
              <li>
                <a
                  href=""
                  (click)="getReport(report.id, report.patient)"
                  data-toggle="modal"
                  data-target="#viewPatient"
                  ><i class="zmdi zmdi-eye mr-2"></i>Patient</a
                >
              </li>
              <!-- <li class="ml-1"><a [routerLink]="[ '/edit_patient', report.patient.ecrfId ]">| Edit</a></li> -->
              <li class="ml-1">
                <a [routerLink]="['/dash-reports', report.id]"
                  >| Start Reporting</a
                >
              </li>
            </ul>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="text-right mx-3">
      <pagination-controls id="new_pagination" (pageChange)="changeNewPage($event)">
      </pagination-controls>
    </div>
  </div>
  <!-- </div> -->
</div>

<div *ngIf="processReportsView" class="container-fluid px-4 mt-5">
  <div class="d-flex justify-content-between align-items-center rep_min-height">

    <div class="d-flex justify-content-between align-items-center">
      <h5 class=" text_color  font-weight-bold mb-0">Process Reports</h5>
      <select
        class=" ml-2 bg-white form-control w-80  dropdown-toggle"
        (change)="changePageSize($event)"
      >
      <option *ngFor="let page of pageSize" [value]="page">{{page}}</option>
      </select>
    </div>

    <div class="levels_section d-flex mt-0 mr-3  rounded mx-0">
      <!-- <div class="row reports_info mr-2">
        <label  class=" mr-2"> Organisation:</label>
          <select
            class="col bg-white form-control  dropdown-toggle"
            (change)="addOrganization($event)"
          >
            <option selected disabled>Organisation</option>
            <option value="All">All</option>
            <option *ngFor="let org of organisation" [value]="org">{{org}}</option>
          </select>
      </div>
      <div class="row reports_info">
        <label  class="col-3 text-right "> Type:</label>
          <select
            class="col bg-white form-control  dropdown-toggle"
            (change)="addReportType($event)"
          >
            <option selected disabled>Report Type</option>
            <option value="All">All</option>
            <option value="Absolute">Absolute</option>
            <option value="Core">Core</option>
            <option value="Indiegene">Indiegene</option>
            <option value="Focus">Focus</option>
            <option value="First">First</option>
            <option value="Germline+">Germline+</option>
            <option value="HRR Germline">HRR Germline</option>
          </select>

      </div> -->
      <div class="row reports_info ml-5 reports_info">
        <label class="col-4 text-right ">Search:  </label>
        <input
          class="col form-control"
          type="text"
          name="search"
          (ngModelChange)="searchData($event)"
          [(ngModel)]="searchText"
          autocomplete="off"
          placeholder="Key"
        />

      </div>
    </div>
  </div>

  <div class="p-4 bg-white cust_shadow rounded mb-4">
    <table class="table">
      <thead class="thead-light">
        <tr>
          <th>Patient Id</th>
          <th>Org Id</th>
          <th>Patient Name</th>
          <th>Report Type</th>
          <th>Organisation</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let report of processingReportsList | paginate  : {
          id: 'listing_pagination',
          itemsPerPage: 10,
          currentPage: currentPage,
          totalItems: totalPages * 10
        };">
          <td>{{ report.patient.ecrfId }}</td>
          <td>{{ report.patient.organisationPatientId }}</td>
          <td>{{ report.patient.name }}</td>
          <td *ngIf="report.reportType != null">
            {{ report.reportType.name }}
          </td>
          <td *ngIf="report.reportType == null">NA</td>
          <td>{{ report.patient.organisationName }}</td>
          <td>
            <ul class="list-unstyled d-flex actions mb-0">
              <li>
                <a [routerLink]="['/dash-reports', report.id]" target="_blank"
                  >Edit Reporting</a
                >
                <span class="ml-1">|</span>
              </li>
              <li class="ml-1">
                <a
                  (click)="getDocuments(report.patient.ecrfId)"
                  title="documents"
                  >Files</a
                >
              </li>
            </ul>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="text-right mx-3">
      <pagination-controls id="listing_pagination" (pageChange)="changePage($event)">
      </pagination-controls>
    </div>
  </div>
</div>

<div *ngIf="deliveredReportsView" class="container-fluid px-4 mt-5">
  <div class="d-flex justify-content-between align-items-center rep_min-height">
    <!-- <h5 class="text_color  font-weight-bold mb-0"></h5> -->
    <div class="d-flex justify-content-between align-items-center">
      <h5 class=" text_color  font-weight-bold mb-0">Delivered Reports</h5>
      <div class=" ml-2">
        <select
        class=" bg-white w-80 form-control  dropdown-toggle"
        (change)="changePageSize($event)"
      >
      <option *ngFor="let page of pageSize" [value]="page">{{page}}</option>
      </select>
      </div>

    </div>
    <div class="row reports_info  reports_info mr-0">
      <label class="col-4 text-right ">Search:  </label>
      <input
        class="col form-control"
        type="text"
        name="search"
        (ngModelChange)="searchDeliveredData($event)"
        [(ngModel)]="searchDeliveredText"
        autocomplete="off"
        placeholder="Key"
      />

    </div>
  </div>
  <div class="p-4 bg-white cust_shadow  rounded mb-4">
    <table class="table">
      <thead class="thead-light">
        <tr>
          <th>Patient Id</th>
          <th>Patient Name</th>
          <th>Report Type</th>
          <th>Estimated Duration</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let report of deliveredReportsList| paginate  : {
          id: 'delivered_pagination',
          itemsPerPage: 10,
          currentPage: deliveredCurrentPage,
          totalItems: deliveredTotalPages * 10
        };">
          <td>{{ report.patient.ecrfId }}</td>
          <td>{{ report.patient.name }}</td>
          <td *ngIf="report.reportType != null">
            {{ report.reportType.name }}
          </td>
          <td *ngIf="report.reportType == null">NA</td>
          <td *ngIf="report.sampleCollected != null">
            {{ report.sampleCollected }}
          </td>
          <td *ngIf="report.sampleCollected == null">NA</td>
          <td>
            <ul class="list-unstyled d-flex actions mb-0">
              <li>
                <a
                  (click)="getReport(report.id, report.patient)"
                  data-toggle="modal"
                  data-target="#viewPatient"
                  ><i class="zmdi zmdi-eye mr-2"></i>Patient</a
                >
                <span class="ml-1">|</span>
              </li>
              <li class="ml-1">
                <a
                  (click)="navigateToReport(report.reportType.name,report.id)"
                  
                  class="text_color"
                >
                  View Report</a>
                <!-- <a
                  *ngIf="report.reportType.name == 'First'"
                  [routerLink]="['/first_view_report', report.id]"
                  target="_blank"
                  class="text_color"
                >
                  View Report</a -->
                <!-- > -->
                <span class="ml-1">|</span>
              </li>
              <li class="ml-1">
                <a
                (click)="getReport(report.id, report.patient)"
                data-toggle="modal"
                data-target="#revertRecord"
                >Revert</a>
              </li>
            </ul>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="text-right mx-3">
      <pagination-controls id="delivered_pagination" (pageChange)="changedeliveredPage($event)">
      </pagination-controls>
    </div>
  </div>
</div>

<!-- view patient modal -->
<div class="modal fade" id="viewPatient" #closeModal>
  <div class="modal-dialog modal-dialog-centered" style="max-width: 1100px">
    <div class="modal-content surgery-modal">
      <div class="modal-header px-4 border-bottom-0 bg-light">
        <h5 class="modal-title text-softblue">Patient Details</h5>
        <a class="text-softblue" data-dismiss="modal">
          <i _ngcontent-esd-c8="" class="zmdi zmdi-close"></i>
        </a>
      </div>

      <div class="modal-body p-4" style="text-align: left">
        <div class="row">
          <div class="col">
            <div class="form-group row mb-0">
              <label class="col-sm-4 control-label text-right">Ecrf Id :</label>
              <div class="col-sm-8">
                <label>{{ patient.ecrfId }}</label>
              </div>
            </div>

            <div class="form-group row mb-0">
              <label class="col-sm-4 control-label text-right">Name :</label>
              <div class="col-sm-8">
                <label>{{ patient.name }}</label>
              </div>
            </div>

            <div class="form-group row mb-0">
              <label class="col-sm-4 control-label text-right"
                >Cancer Type :</label
              >
              <div class="col-sm-8">
                <label *ngIf="report.cancerType != null">{{
                  report.cancerType
                }}</label>
                <label *ngIf="report.cancerType == null">NA</label>
              </div>
            </div>

            <div class="form-group row mb-0">
              <label class="col-sm-4 control-label text-right">Gender :</label>
              <div class="col-sm-8">
                <label>{{ report.patient?.gender }}</label>
              </div>
            </div>

            <div class="form-group row mb-0">
              <label class="col-sm-4 control-label text-right">Age/DOB :</label>
              <div class="col-sm-8">
                <label>{{
                  report.patient?.dateOfBirth | date: "dd/MM/yyyy"
                }}</label>
              </div>
            </div>

            <div class="form-group row mb-0">
              <label class="col-sm-4 control-label text-right"
                >Collected On :</label
              >
              <div class="col-sm-8">
                <label>{{ report.labDetails?.sampleCollected }}</label>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="form-group row mb-0">
              <label class="col-sm-4 control-label text-right"
                >Sample Id :</label
              >
              <div class="col-sm-8">
                <label>{{ report.labDetails?.sampleBlockId }}</label>
              </div>
            </div>

            <div class="form-group row mb-0">
              <label class="col-sm-4 control-label text-right"
                >Report Type :</label
              >
              <div class="col-sm-8">
                <label *ngIf="report.reportType?.name != null"
                  >Targt {{ report.reportType?.name }}</label
                >
                <label *ngIf="report.reportType?.name == null">NA</label>
              </div>
            </div>
            <div class="form-group row mb-0">
              <label class="col-sm-4 control-label text-right"
                >Sample Source :</label
              >
              <div class="col-sm-8">
                <label>{{ report.labDetails?.sampleSource }}</label>
              </div>
            </div>

            <div class="form-group row mb-0">
              <label class="col-sm-4 control-label text-right"
                >Hospital :</label
              >
              <div class="col-sm-8">
                <label>{{ report.labDetails?.hospital }}</label>
              </div>
            </div>

            <div class="form-group row mb-0">
              <!-- <label class="col-sm-4 control-label text-right">Referring Clinician :</label> -->
              <div class="col-sm-8">
                <!-- <label>{{ report.labDetails?.referringClinician }}</label> -->
              </div>
            </div>

            <div *ngIf="deliveredReportsView" class="form-group row mb-0">
              <label class="col-sm-4 control-label text-right">Revert to Processing :</label
              >
              <div class="col-sm-8">
                <label>
                  <select class="form-control" (change)="changeReportStatus($event.target)">
                    <option selected disabled> select</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                </select>
                </label>
              </div>
            </div>
          </div>
        </div>

        <!-- <button type="button" class="btn btn-primary text-uppercase bg-softblue" style="float:right"
        data-dismiss="modal"> Save </button> -->
      </div>
    </div>
  </div>
</div>


<!--Revert Model-->

<div class="modal fade" id="revertRecord" #closeModal>
  <div class="modal-dialog modal-dialog-centered" style="max-width: 600px">
    <div class="modal-content surgery-modal">
      <div class="modal-header px-4 border-bottom-0 bg-light">
        <h5 class="modal-title text-softblue">Revert Record</h5>
        <a class="text-softblue" data-dismiss="modal">
          <i _ngcontent-esd-c8="" class="zmdi zmdi-close"></i>
        </a>
      </div>

      <div class="modal-body row p-4" style="text-align: left">

        <div class=" col-12">
          <span>Are you sure you want to revert this record? </span>
        </div>
        <div class="col-12 mt-2">
          <button type="button" value="Yes" class="btn btn-primary text-uppercase bg-softblue" style="float:right"
          data-dismiss="modal" (click)="changeReportStatus($event.target)"> Yes </button>
          <button type="button" value="NO" class="btn btn-primary text-uppercase bg-softblue mr-3" style="float:right"
          data-dismiss="modal"> NO </button>

        </div>


      </div>
    </div>
  </div>
</div>
