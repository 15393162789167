<div *ngIf="!reportData" class="d-flex justify-content-center mt-5">
    <span class="visually-hidden"></span>
    <div
      class="spinner-border"
      style="width: 8rem; height: 8rem"
      role="status"
    ></div>
  </div>

  <div
    *ngIf="reportData"
    style="height: 1600px; padding: 10px; margin: 0 auto"
    class="main_wrapper"
  >
    <button
      [disabled]="downloadSpinner"
      type="button"
      class="btn btn-primary m-2 float-right download-btn"
      (click)="downloadReport()"
    >
      <span *ngIf="!downloadSpinner">
        <i class="zmdi zmdi-download"></i>
        Download Report
      </span>
      <div *ngIf="downloadSpinner" class="spinner-border text-dark">
        <span class="sr-only"></span>
      </div>
    </button>

    <!-- For 4basecare -->
    <div *ngIf="is4basecare && temp=='no_temp'">
      <div
        id="page"
        class="container position-relative page"
        style="overflow-y: clip; background-color: white"
      >
        <div class="row header">
          <div class="w-100 overflow-hidden">
            <div class="col">
              <div class="cover_page">
                <img
                  src="../../../assets/tarGT_FirstSolid-Cover.png"
                  alt=""
                  class="img-fluid w-100"
                />
              </div>
            </div>
          </div>
          <div></div>
        </div>
      </div>

      <div
        id="page1"
        class="container position-relative page"
        style="background-color: white"
      >
        <div class="row header mt-1 py-4">
          <div>
            <div class="col-3 pl-0">
              <div class="logo">
                <img src="../../../assets/logo.png" alt="" style="width: 140px" />
              </div>
            </div>
          </div>
          <div></div>
          <div class="col">
            <div class="patient-detailes ml-auto float-right">
              <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
                <li>
                  Patient Id <br />
                  <span>{{ report.patient?.ecrfId }}</span>
                </li>
                <li class="ml-4">
                  Cancer Site<br />
                  <span>{{ report.cancerType }}</span>
                </li>
                <li class="ml-4">
                  SAMPLE ID<br />
                  <span>{{ report.labDetails?.sampleId }}</span>
                </li>
                <li class="ml-4">
                  Registration Date<br />
                  <span>{{
                    report.labDetails?.sampleCollected | date : "dd/MM/yyyy"
                  }}</span>
                </li>
              </ul>
            </div>
          </div>
          <div *ngIf="report.clientType == '4basecare_client'">
            <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
              <div class="logo">
                <img
                  src="../../../assets/oncoquest logo.png"
                  alt=""
                  style="width: 100px"
                />
              </div>
            </div>
            <div *ngIf="report.clientName == 'path_labs'" class="col-3">
              <div class="logo">
                <img
                  src="../../../assets/pathlab.png"
                  alt=""
                  style="width: 100px"
                />
              </div>
            </div>
          </div>
        </div>
        <!--  -->
        <div class="mar_top"></div>
        <!--  -->
        <div class="row">
          <div class="col-7">
            <div class="">
              <div class="w-50" style="min-height: 80px">
                <p class="py-1 font-weight-bold" [ngClass]="'txt-skyblue'">
                  SCOPE OF THE TEST
                </p>
                <p>SNVs, InDels, CNAs, Gene Fusions status</p>
              </div>
              <img src="../../../assets/BorderLine.png" class="mr-2 w-100" />
            </div>
          </div>
          <div class="col">
            <div class="">
              <div class="w-100">
                <!-- <p style="color: #208dcb;" class="py-1">
                        <span style="color: #000 !important"></span>
                      </p> -->
                <div class="w-100" style="min-height: 80px">
                  <p
                    [ngClass]="'txt-skyblue'"
                    class="py-1 font-weight-bold"
                    *ngIf="report.clinicalSummary != null"
                  >
                    <span *ngIf="report.clinicalSummary != ' '"
                      >CLINICAL INDICATION</span
                    >
                  </p>

                  <p *ngIf="report.clinicalSummary != null">
                    <span
                      style="color: #000 !important"
                      *ngIf="report.clinicalSummary != ' '"
                      >{{ report.clinicalSummary }}</span
                    >
                  </p>
                </div>
                <!-- </div> -->
                <img src="../../../assets/BorderLine.png" class="mr-2 w-100" />

                <!-- </div> -->
              </div>
            </div>
          </div>
        </div>
        <!--  -->
        <div class="row pt-2">
          <div class="col-7">
            <h6
              class="text-uppercase py-2 font-weight-bold"
              [ngClass]="'txt-skyblue'"
            >
              Report Details
            </h6>
            <ul class="list-unstyled">
              <li class="pb-1">
                <span class="clr-cyan" [ngClass]="'txt-skyblue'">Name : </span>
                <span class="text-black">{{
                  report.patient?.name | uppercase
                }}</span>
              </li>
              <li class="pb-1">
                <span class="clr-cyan" [ngClass]="'txt-skyblue'">Gender : </span>
                <span class="text-black">{{
                  report.patient?.gender | titlecase
                }}</span>
              </li>
              <li class="pb-1">
                <span class="clr-cyan" [ngClass]="'txt-skyblue'">Age/DOB : </span>
                <span class="text-black">{{
                  report.patient?.dateOfBirth
                    ? (report.patient?.dateOfBirth | date : "dd/MM/yyyy")
                    : report.patient?.age + " Years"
                }}</span>
              </li>
              <li class="pb-1">
                <span class="clr-cyan" [ngClass]="'txt-skyblue'"
                  >Reporting Date :
                </span>
                <span class="text-black">{{ date | date : "dd/MM/yyyy" }}</span>
              </li>
            </ul>
          </div>
          <div class="col">
            <ul class="list-unstyled pt-4 mt-3">
              <li *ngIf="report.cancerSubType != null" class="pb-1">
                <span class="clr-cyan" [ngClass]="'txt-skyblue'"
                  >Cancer Celltype :
                </span>
                <span class="text-black">{{ report.cancerSubType }}</span>
              </li>
              <li class="pb-1">
                <span class="clr-cyan" [ngClass]="'txt-skyblue'"
                  >Sample Source :
                </span>
                <span class="text-black">{{ sampleSource }}</span>
              </li>
              <li class="pb-1">
                <span class="clr-cyan" [ngClass]="'txt-skyblue'"
                  >Consulting Clinician :
                </span>
                <span class="text-black">{{referringClinician}}</span>
              </li>
              <li class="pb-1">
                <span class="clr-cyan" [ngClass]="'txt-skyblue'"
                  >Hospital :
                </span>
                <span class="text-black">{{ labHospital }}</span>
              </li>
            </ul>
          </div>
        </div>
        <!--  -->
        <div class="row mt-2 py-2">
          <div class="col">
            <h6 class="mb-0 text-uppercase f-weight_700">Results</h6>
          </div>
        </div>
        <h6 class="py-2 h6_bg" [ngClass]="'skyBlue'">
          Genomic findings from tumor profiling
        </h6>

        <!--  -->
        <div class="row">
          <div  style="width: 100%;" *ngIf="isReportDownload">
            <img [src]="firstPageTable" alt="" width="100%" class="img-fluid" />
          </div>
          <div class="col-12" id="firstPageTable" *ngIf="!isReportDownload">
            <table
              class="page_1 cust_table w-100 mt-3 first_1"
              [ngClass]="'table_skyblue'"
            >
              <tr>
                <th>Genomic Alteration</th>
                <th>Relevant Therapies (in Same Cancer Type)</th>
                <th>Relevant Therapies (in Different Cancer)</th>
              </tr>


              <tr *ngIf="tableCount== 0 && prognasticCount>0 ">
                <td colspan="3" class="text-center mt-2">
                  Clinically relevant genomic alterations associated with therapeutic significance were not detected.
                </td>
              </tr>
              <tr *ngIf="tableCount== 0 && prognasticCount == 0">
                <td colspan="3" class="text-center mt-2">
                  Clinically relevant genomic alterations associated with both therapeutic and prognostic significance were not detected.
                </td>
              </tr>
              <!-- SNV -->
              <ng-container>
                <tr
                  *ngFor="
                    let snvHotspotTrail of reportPdfRes.snvHotspotTrailResList
                  "
                >
                  <td>
                  <i>{{ snvHotspotTrail.gene }}</i>&nbsp;
                  <span *ngIf="!snvHotspotTrail.gene.includes('Wild type')">
                  <span *ngIf="snvHotspotTrail?.multiVariant==null">
                  <span *ngIf="snvHotspotTrail.exonNumber != null">{{snvHotspotTrail.exonNumber}}</span>&nbsp;<span *ngIf="snvHotspotTrail.pposition != null && snvHotspotTrail.pposition != ''">({{snvHotspotTrail.pposition?.replace(' ','')}})</span>
                    <p *ngIf="snvHotspotTrail.mutantBurdenPercent != null && !snvHotspotTrail.gene.includes('Wild type')">
                      Allelic burden: {{ snvHotspotTrail.mutantBurdenPercent }}%
                    </p></span>

                    <span *ngIf="snvHotspotTrail?.multiVariant!=null"><br/>
                      <span  style="font-size: 14px !important" [innerHtml]="snvHotspotTrail?.multiVariant"></span>
                    </span></span>
                  </td>
                  <td>
                    <table class="table first_table w-100">
                      <thead class="border-bottom cust_clr">
                        <tr>
                          <th class="w-50">
                            <span class="font-weight-normal"><i>Therapy</i></span>
                          </th>
                          <th class="w-33">
                            <span class="font-weight-normal"
                              ><i>Clinical Relevance</i></span
                            >
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngIf="snvHotspotTrail.sameCancerType == null">
                          <td class="w-50">NA</td>
                          <td class="w-50">NA</td>
                        </tr>

                        <!-- <tr *ngIf="snvHotspotTrail.sameCancerType != null">
                            <td class="w-50">
                              <div *ngFor="let drugs of snvHotspotTrail.sameCancerType.drugList">
                              <span *ngFor="let drug of drugs, let i=index">
                                  {{drug.name}}<span *ngIf="drugs.length-1 != i">,</span>
                              </span>
                          </div>
                        </td>
                          <td class="w-50">
                              <div *ngFor="let association of snvHotspotTrail.sameCancerType.associationList">
                              {{association}}
                          </div>
                        </td>
                          </tr> -->
                        <ng-container
                          *ngIf="snvHotspotTrail.sameCancerType != null"
                        >
                          <tr
                            *ngFor="
                              let item of snvHotspotTrail.sameTypeRelevantData
                            "
                          >
                            <td>{{ item.therapy }}</td>
                            <td>{{ item.association }}</td>
                            <!-- <td>{{item.cancerType}}</td> -->
                          </tr>
                        </ng-container>
                      </tbody>
                    </table>
                  </td>
                  <td>
                    <table class="table first_table w-100">
                      <thead class="border-bottom cust_clr">
                        <tr>
                          <th class="w-33">
                            <span class="font-weight-normal"><i>Therapy</i></span>
                          </th>
                          <th class="w-33">
                            <span class="font-weight-normal"
                              ><i>Clinical Relevance</i></span
                            >
                          </th>
                          <th class="w-33">
                            <span class="font-weight-normal"
                              ><i>Cancer Type</i></span
                            >
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <!-- <tr *ngIf="snvHotspotTrail.otherCancerType != null">
                            <td class="w-33 ">
                              <div *ngFor="let drugs of snvHotspotTrail.otherCancerType.drugList">
                              <span *ngFor="let drug of drugs, let i=index">
                                  {{drug.name}}<span *ngIf="drugs.length-1 != i">,</span>
                              </span>
                          </div>
                        </td>
                          <td class="w-33">
                              <div *ngFor="let association of snvHotspotTrail.otherCancerType.associationList">
                              {{association}}
                          </div>
                        </td>
                        <td class="w-33">
                          <div *ngFor="let cancerType of snvHotspotTrail.otherCancerType.cancerTypeList">
                          {{cancerType.name}}
                          </div>
                        </td>
                          </tr> -->
                        <ng-container
                          *ngIf="snvHotspotTrail.otherCancerType != null"
                        >
                          <tr
                            *ngFor="
                              let item of snvHotspotTrail.otherTypeRelevantData
                            "
                          >
                            <td>{{ item.therapy }}</td>
                            <td>{{ item.association }}</td>
                            <td>{{ item.cancerType }}</td>
                          </tr>
                        </ng-container>
                        <tr *ngIf="snvHotspotTrail.otherCancerType == null">
                          <td class="w-33">NA</td>
                          <td class="w-33">NA</td>
                          <td class="w-33">NA</td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>

                <!-- CNA -->
                <tr
                  *ngFor="
                    let cnaHotspotTrail of reportPdfRes.cnaHotspotTrailResList
                  "
                >
                  <td>
                    <i>{{ cnaHotspotTrail.gene }}</i>&nbsp;
                    <span *ngIf="cnaHotspotTrail?.multiVariant==null">
                      {{ cnaHotspotTrail.copyNumber }}</span>

                      <span *ngIf="cnaHotspotTrail?.multiVariant!=null"><br/>
                        <span  style="font-size: 14px !important" [innerHtml]="cnaHotspotTrail?.multiVariant"></span>
                      </span>
                      </td>
                  <td>
                    <table class="table first_table w-100">
                      <thead class="border-bottom cust_clr">
                        <tr>
                          <th class="w-50">
                            <span class="font-weight-normal"><i>Therapy</i></span>
                          </th>
                          <th class="w-50">
                            <span class="font-weight-normal"
                              ><i>Clinical Relevance</i></span
                            >
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <!-- <tr *ngIf="cnaHotspotTrail.sameCancerType != null">
                            <td class="w-50">
                              <div *ngFor="let drugs of cnaHotspotTrail.sameCancerType.drugList">
                              <span *ngFor="let drug of drugs, let i=index">
                                  {{drug.name}}<span *ngIf="drugs.length-1 != i">,</span>
                              </span>
                          </div>
                        </td>
                          <td class="w-50">
                              <div *ngFor="let association of cnaHotspotTrail.sameCancerType.associationList">
                              {{association}}
                          </div>
                        </td>
                          </tr> -->
                        <ng-container
                          *ngIf="cnaHotspotTrail.sameCancerType != null"
                        >
                          <tr
                            *ngFor="
                              let item of cnaHotspotTrail.sameTypeRelevantData
                            "
                          >
                            <td>{{ item.therapy }}</td>
                            <td>{{ item.association }}</td>
                            <!-- <td>{{item.cancerType}}</td> -->
                          </tr>
                        </ng-container>
                        <tr *ngIf="cnaHotspotTrail.sameCancerType == null">
                          <td class="w-50">NA</td>
                          <td class="w-50">NA</td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                  <td>
                    <table class="table first_table w-100">
                      <thead class="border-bottom cust_clr">
                        <tr>
                          <th class="w-33">
                            <span class="font-weight-normal"><i>Therapy</i></span>
                          </th>
                          <th class="w-33">
                            <span class="font-weight-normal"
                              ><i>Clinical Relevance</i></span
                            >
                          </th>
                          <th class="w-33">
                            <span class="font-weight-normal"
                              ><i>Cancer Type</i></span
                            >
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <!-- <tr *ngIf="cnaHotspotTrail.otherCancerType != null">
                            <td class="w-33 ">
                              <div *ngFor="let drugs of cnaHotspotTrail.otherCancerType.drugList">
                              <span *ngFor="let drug of drugs, let i=index">
                                  {{drug.name}}<span *ngIf="drugs.length-1 != i">,</span>
                              </span>
                          </div>
                        </td>
                          <td class="w-33">
                              <div *ngFor="let association of cnaHotspotTrail.otherCancerType.associationList">
                              {{association}}
                          </div>
                        </td>
                        <td class="w-33">
                          <div *ngFor="let cancerType of cnaHotspotTrail.otherCancerType.cancerTypeList">
                          {{cancerType.name}}
                          </div>
                        </td>
                          </tr> -->

                        <ng-container
                          *ngIf="cnaHotspotTrail.otherCancerType != null"
                        >
                          <tr
                            *ngFor="
                              let item of cnaHotspotTrail.otherTypeRelevantData
                            "
                          >
                            <td>{{ item.therapy }}</td>
                            <td>{{ item.association }}</td>
                            <td>{{ item.cancerType }}</td>
                          </tr>
                        </ng-container>

                        <tr *ngIf="cnaHotspotTrail.otherCancerType == null">
                          <td class="w-33">NA</td>
                          <td class="w-33">NA</td>
                          <td class="w-33">NA</td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>

                <!-- Fusion -->
                <tr
                  *ngFor="
                    let fusionHotspotTrail of reportPdfRes.fusionHotspotTrailResList
                  "
                >
                  <td>
                    <i>{{ fusionHotspotTrail.gene }}</i>
                  </td>
                  <td>
                    <table class="table first_table w-100">
                      <thead class="border-bottom cust_clr">
                        <tr>
                          <th class="w-50">
                            <span class="font-weight-normal"><i>Therapy</i></span>
                          </th>
                          <th class="w-33">
                            <span class="font-weight-normal"
                              ><i>Clinical Relevance</i></span
                            >
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngIf="fusionHotspotTrail.sameCancerType == null">
                          <td class="w-50">NA</td>
                          <td class="w-50">NA</td>
                        </tr>

                        <!-- <tr *ngIf="fusionHotspotTrail.sameCancerType != null">
                            <td class="w-50">
                              <div *ngFor="let drugs of fusionHotspotTrail.sameCancerType.drugList">
                              <span *ngFor="let drug of drugs, let i=index">
                                  {{drug.name}}<span *ngIf="drugs.length-1 != i">,</span>
                              </span>
                          </div>
                        </td>
                          <td class="w-50">
                              <div *ngFor="let association of fusionHotspotTrail.sameCancerType.associationList">
                              {{association}}
                          </div>
                        </td>
                          </tr> -->

                        <ng-container
                          *ngIf="fusionHotspotTrail.sameCancerType != null"
                        >
                          <tr
                            *ngFor="
                              let item of fusionHotspotTrail.sameTypeRelevantData
                            "
                          >
                            <td  style="word-break: break-word">{{ item.therapy }}</td>
                            <td>{{ item.association }}</td>
                            <!-- <td>{{item.cancerType}}</td> -->
                          </tr>
                        </ng-container>
                      </tbody>
                    </table>
                  </td>
                  <td>
                    <table class="table first_table w-100">
                      <thead class="border-bottom cust_clr">
                        <tr>
                          <th class="w-33">
                            <span class="font-weight-normal"><i>Therapy</i></span>
                          </th>
                          <th class="w-33">
                            <span class="font-weight-normal"
                              ><i>Clinical Relevance</i></span
                            >
                          </th>
                          <th class="w-33">
                            <span class="font-weight-normal"
                              ><i>Cancer Type</i></span
                            >
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <!-- <tr *ngIf="fusionHotspotTrail.otherCancerType != null">
                            <td class="w-33 ">
                              <div *ngFor="let drugs of fusionHotspotTrail.otherCancerType.drugList">
                              <span *ngFor="let drug of drugs, let i=index">
                                  {{drug.name}}<span *ngIf="drugs.length-1 != i">,</span>
                              </span>
                          </div>
                        </td>
                          <td class="w-33">
                              <div *ngFor="let association of fusionHotspotTrail.otherCancerType.associationList">
                              {{association}}
                          </div>
                        </td>
                        <td class="w-33">
                          <div *ngFor="let cancerType of fusionHotspotTrail.otherCancerType.cancerTypeList">
                          {{cancerType.name}}
                          </div>
                        </td>
                          </tr> -->
                        <ng-container
                          *ngIf="fusionHotspotTrail.otherCancerType != null"
                        >
                          <tr
                            *ngFor="
                              let item of fusionHotspotTrail.otherTypeRelevantData
                            "
                          >
                            <td>{{ item.therapy }}</td>
                            <td>{{ item.association }}</td>
                            <td>{{ item.cancerType }}</td>
                          </tr>
                        </ng-container>
                        <tr *ngIf="fusionHotspotTrail.otherCancerType == null">
                          <td class="w-33">NA</td>
                          <td class="w-33">NA</td>
                          <td class="w-33">NA</td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </ng-container>
        </table>
            <p
              *ngIf="
                reportPdfRes.snvHotspotTrailResList.length != 0 ||
                reportPdfRes.cnaHotspotTrailResList.length != 0
              "
              style="font-size: 13px !important"
              class="mt-3"
            >
              <i> *NA: Not Applicable</i>
            </p>
          </div>
        </div>
        <!--  -->
        <div *ngIf="(tableCount>0) &&
          prognasticCount==0">
          <h6 class="py-2 h6_bg"> Prognostic Biomarkers</h6>
        </div>
        <div class="row" *ngIf="(tableCount>0 )&&
          prognasticCount==0">
          <div class="col-12">
            <table class="page_1 cust_table w-100 mt-3 varient_details prognasticbiomarkertable"
              [ngClass]="'table_variant-blue'">
              <thead>
                <tr>
                  <th>Genomic Alteration</th>
                  <th>Prognostic Significance</th>

                </tr>
              </thead>
              <ng-container>
                <tr>
                  <td colspan="2" style="text-align:center">
                    Clinically relevant genomic alterations associated with prognostic significance were not detected.
                  </td>
                </tr>
              </ng-container>
            </table>
          </div>
        </div>
        <div *ngIf="prognasticCount>0">
          <h6 class="py-2 h6_bg"> Prognostic Biomarkers</h6>
        </div>

        <div class="row" *ngIf="prognasticCount>0">
          <div class="col-12">
            <table class="page_1 cust_table w-100 mt-3 varient_details prognasticbiomarkertable"
              [ngClass]="'table_variant-blue'">
              <thead>
                <tr>
                  <th>Genomic Alteration</th>
                  <th>Prognostic Significance</th>

                </tr>
              </thead>
            <ng-container *ngFor="let prog of reportPdfRes.prognosticBiomarkers">
              <tr>
                <td style="padding-left: 3px !important">
                  <i>{{ prog.gene }}&nbsp; </i>
                  <span [innerHtml]="prog.geneDetails"></span>
                </td>
                <td style="padding-left: 3px !important;">{{prog.statement}}
                </td>
              </tr>
            </ng-container>



          </table>
        </div>
      </div>
        <!--  -->

        <!-- gene Data -->
        <div *ngIf="!geneFlag && isStatusDisplay">
          <div *ngIf="geneMutationlist != null" class="mt-3">
            <h6 class="py-2 h6_bg" [ngClass]="'skyBlue'">
              Status of variants in cancer related Biomarkers
            </h6>
          </div>

          <table
            class="staus_gene bg_skyblue"
            *ngIf="geneMutationlist != null"
            [ngClass]="{
              biomarker_table:
                geneMutationlist.length >= 4 &&
                geneMutationlist.length < 8
            }"
          >
            <tr style="background: #daedf7">
              <td
                style="font-size: 18px !important; padding-left: 10px !important"
              >
                Gene
              </td>
              <td
                *ngFor="
                  let geneMutation of geneMutationlist
                    | slice : 0 : 8
                "
              >
                <i>{{ geneMutation.gene }}</i>
              </td>
            </tr>
            <tr>
              <td
                style="font-size: 18px !important; padding-left: 10px !important"
              >
                <b>Status</b>
              </td>
              <td
                *ngFor="
                  let geneMutation of geneMutationlist
                    | slice : 0 : 8
                "
              >
                <span
                  *ngIf="
                    geneMutation.status == 'Pathogenic' ||
                    geneMutation.status == 'Likely Pathogenic' ||
                    geneMutation.status == 'Detected'
                  "
                  style="color: red; font-weight: 600px"
                  ><b>{{ geneMutation.status }}</b></span
                >
                <span
                  *ngIf="geneMutation.status == 'VUS'"
                  style="color: black; font-weight: 600px"
                  ><b>{{ geneMutation.status }}</b></span
                >
                <span
                  *ngIf="
                    geneMutation.status == 'Not Detected' ||
                    geneMutation.status == 'Benige'
                  "
                  >{{ geneMutation.status }}</span
                >
              </td>
            </tr>
            <br/>
          </table>
          <table
            class="staus_gene"
            [ngClass]="'bg_skyblue'"
            *ngIf="
              geneMutationlist != null &&
              geneMutationlist.length > 8
            "
          >
            <tr style="background: #daedf7">
              <td
                style="font-size: 18px !important; padding-left: 10px !important"
              >
                Gene
              </td>
              <td
                *ngFor="
                  let geneMutation of geneMutationlist
                    | slice : 8 : 17
                "
              >
                <i>{{ geneMutation.gene }}</i>
              </td>
            </tr>
            <tr>
              <td
                style="font-size: 18px !important; padding-left: 10px !important"
              >
                <b>Status</b>
              </td>
              <td
                *ngFor="
                  let geneMutation of geneMutationlist
                    | slice : 8 : 17
                "
              >
                <span
                  *ngIf="
                    geneMutation.status == 'Pathogenic' ||
                    geneMutation.status == 'Likely Pathogenic' ||
                    geneMutation.status == 'Detected'
                  "
                  style="color: red; font-weight: 600px"
                  ><b>{{ geneMutation.status }}</b></span
                >
                <span
                  *ngIf="geneMutation.status == 'VUS'"
                  style="color: black; font-weight: 600px"
                  ><b>{{ geneMutation.status }}</b></span
                >
                <span
                  *ngIf="
                    geneMutation.status == 'Not Detected' ||
                    geneMutation.status == 'Benige'
                  "
                  >{{ geneMutation.status }}</span
                >
              </td>
            </tr>
            <br/>
          </table>
          <table class="staus_gene" [ngClass]="'bg_skyblue'" *ngIf="
          ((reportPdfRes.snvHotspotTrailResList.length ==0 &&
          reportPdfRes.cnaHotspotTrailResList.length ==0  &&
          reportPdfRes.fusionHotspotTrailResList.length ==0) &&
          reportPdfRes.snvReportedVariantList.length <=1 ) &&
            (geneMutationlist != null &&
            geneMutationlist.length > 17)

              ">
            <tr style="background: #daedf7">
              <td style="font-size: 18px !important; padding-left: 10px !important">
                Gene
              </td>
              <td *ngFor="
                    let geneMutation of geneMutationlist
                      | slice : 17 : 25
                  ">
                <i>{{ geneMutation.gene }}</i>
              </td>
            </tr>
            <tr>
              <td style="font-size: 18px !important; padding-left: 10px !important">
                <b>Status</b>
              </td>
              <td *ngFor="
                    let geneMutation of geneMutationlist
                      | slice : 17 : 25
                  ">
                <span *ngIf="
                      geneMutation.status == 'Pathogenic' ||
                      geneMutation.status == 'Likely Pathogenic' ||
                      geneMutation.status == 'Detected'
                    " style="color: red; font-weight: 600px"><b>{{ geneMutation.status }}</b></span>
                <span *ngIf="geneMutation.status == 'VUS'" style="color: black; font-weight: 600px"><b>{{
                    geneMutation.status }}</b></span>
                <span *ngIf="
                      geneMutation.status == 'Not Detected' ||
                      geneMutation.status == 'Benige'
                    ">{{ geneMutation.status }}</span>
              </td>
            </tr>
            <br/>
          </table>
          <table class="staus_gene" [ngClass]="'bg_skyblue'" *ngIf="
          ((reportPdfRes.snvHotspotTrailResList.length ==0 &&
          reportPdfRes.cnaHotspotTrailResList.length ==0  &&
          reportPdfRes.fusionHotspotTrailResList.length ==0) &&
          reportPdfRes.snvReportedVariantList.length <=1 ) &&
                (geneMutationlist != null &&
                geneMutationlist.length > 25)
              ">
            <tr style="background: #daedf7">
              <td style="font-size: 18px !important; padding-left: 10px !important">
                Gene
              </td>
              <td *ngFor="
                    let geneMutation of geneMutationlist
                      | slice : 25 : 33
                  ">
                <i>{{ geneMutation.gene }}</i>
              </td>
            </tr>
            <tr>
              <td style="font-size: 18px !important; padding-left: 10px !important">
                <b>Status</b>
              </td>
              <td *ngFor="
                    let geneMutation of geneMutationlist
                      | slice : 25 : 33
                  ">
                <span *ngIf="
                      geneMutation.status == 'Pathogenic' ||
                      geneMutation.status == 'Likely Pathogenic' ||
                      geneMutation.status == 'Detected'
                    " style="color: red; font-weight: 600px"><b>{{ geneMutation.status }}</b></span>
                <span *ngIf="geneMutation.status == 'VUS'" style="color: black; font-weight: 600px"><b>{{
                    geneMutation.status }}</b></span>
                <span *ngIf="
                      geneMutation.status == 'Not Detected' ||
                      geneMutation.status == 'Benige'
                    ">{{ geneMutation.status }}</span>
              </td>
            </tr>
            <br/>
          </table>
          <table class="staus_gene" [ngClass]="'bg_skyblue'" *ngIf="
          ((reportPdfRes.snvHotspotTrailResList.length ==0 &&
          reportPdfRes.cnaHotspotTrailResList.length ==0  &&
          reportPdfRes.fusionHotspotTrailResList.length ==0) &&
          reportPdfRes.snvReportedVariantList.length <=1 ) &&
                (geneMutationlist != null &&
                geneMutationlist.length > 33)
              ">
            <tr style="background: #daedf7">
              <td style="font-size: 18px !important; padding-left: 10px !important">
                Gene
              </td>
              <td *ngFor="
                    let geneMutation of geneMutationlist
                      | slice : 33 : 41
                  ">
                <i>{{ geneMutation.gene }}</i>
              </td>
            </tr>
            <tr>
              <td style="font-size: 18px !important; padding-left: 10px !important">
                <b>Status</b>
              </td>
              <td *ngFor="
                    let geneMutation of geneMutationlist
                      | slice : 33 : 41
                  ">
                <span *ngIf="
                      geneMutation.status == 'Pathogenic' ||
                      geneMutation.status == 'Likely Pathogenic' ||
                      geneMutation.status == 'Detected'
                    " style="color: red; font-weight: 600px"><b>{{ geneMutation.status }}</b></span>
                <span *ngIf="geneMutation.status == 'VUS'" style="color: black; font-weight: 600px"><b>{{
                    geneMutation.status }}</b></span>
                <span *ngIf="
                      geneMutation.status == 'Not Detected' ||
                      geneMutation.status == 'Benige'
                    ">{{ geneMutation.status }}</span>
              </td>
            </tr>
            <br/>
          </table>
          <h6
            *ngIf="
            ((reportPdfRes.snvHotspotTrailResList.length ==0 &&
            reportPdfRes.cnaHotspotTrailResList.length ==0  &&
            reportPdfRes.fusionHotspotTrailResList.length ==0) &&
            reportPdfRes.snvReportedVariantList.length <=1 ) &&
              (fusionGeneMutationList != null &&
              fusionGeneMutationList.length > 0)
            "
            class="font-weight-bold clr-cyan text-uppercase"
            [ngClass]="'txt-skyblue'"
          >
            Gene Fusion
          </h6>
          <table
            class="staus_gene"
            *ngIf="
            ((reportPdfRes.snvHotspotTrailResList.length ==0 &&
            reportPdfRes.cnaHotspotTrailResList.length ==0  &&
            reportPdfRes.fusionHotspotTrailResList.length ==0) &&
            reportPdfRes.snvReportedVariantList.length <=1 ) &&
              (fusionGeneMutationList != null &&
              fusionGeneMutationList.length > 0)
            "
            [ngClass]="'bg_skyblue'"
          >
            <tr style="background: #daedf7">
              <td
                style="font-size: 18px !important; padding-left: 10px !important"
              >
                Gene
              </td>
              <td
                *ngFor="
                  let geneMutation of fusionGeneMutationList
                    | slice : 0 : 8
                "
              >
                <i>{{ geneMutation.gene }}</i>
              </td>
            </tr>
            <tr>
              <td
                style="font-size: 18px !important; padding-left: 10px !important"
              >
                <b>Status</b>
              </td>
              <td
                *ngFor="
                  let geneMutation of fusionGeneMutationList
                    | slice : 0 : 8
                "
              >
                <span
                  *ngIf="
                    geneMutation.status == 'Pathogenic' ||
                    geneMutation.status == 'Likely Pathogenic' ||
                    geneMutation.status == 'Detected'
                  "
                  style="color: red; font-weight: 600px"
                  ><b>{{ geneMutation.status }}</b></span
                >
                <span
                  *ngIf="geneMutation.status == 'VUS'"
                  style="color: black; font-weight: 600px"
                  ><b>{{ geneMutation.status }}</b></span
                >
                <span
                  *ngIf="
                    geneMutation.status == 'Not Detected' ||
                    geneMutation.status == 'Benige'
                  "
                  >{{ geneMutation.status }}</span
                >
              </td>
            </tr>
          </table>
          <br/>
          <table
            class="staus_gene"
            *ngIf="
            ((reportPdfRes.snvHotspotTrailResList.length ==0 &&
            reportPdfRes.cnaHotspotTrailResList.length ==0  &&
            reportPdfRes.fusionHotspotTrailResList.length ==0) &&
            reportPdfRes.snvReportedVariantList.length <=1 ) &&
              (fusionGeneMutationList != null &&
              fusionGeneMutationList.length > 8)
            "
            [ngClass]="'bg_skyblue'"
          >
            <tr style="background: #daedf7">
              <td
                style="font-size: 18px !important; padding-left: 10px !important"
              >
                Gene
              </td>
              <td
                *ngFor="
                  let geneMutation of fusionGeneMutationList
                    | slice : 8 : 17
                "
              >
                <i>{{ geneMutation.gene }}</i>
              </td>
            </tr>
            <tr>
              <td
                style="font-size: 18px !important; padding-left: 10px !important"
              >
                <b>Status</b>
              </td>
              <td
                *ngFor="
                  let geneMutation of fusionGeneMutationList
                    | slice : 8 : 17
                "
              >
                <span
                  *ngIf="
                    geneMutation.status == 'Pathogenic' ||
                    geneMutation.status == 'Likely Pathogenic' ||
                    geneMutation.status == 'Detected'
                  "
                  style="color: red; font-weight: 600px"
                  ><b>{{ geneMutation.status }}</b></span
                >
                <span
                  *ngIf="geneMutation.status == 'VUS'"
                  style="color: black; font-weight: 600px"
                  ><b>{{ geneMutation.status }}</b></span
                >
                <span
                  *ngIf="
                    geneMutation.status == 'Not Detected' ||
                    geneMutation.status == 'Benige'
                  "
                  >{{ geneMutation.status }}</span
                >
              </td>
            </tr>
          </table>
        </div>
        <!-- <div *ngIf="geneMutationlist != null"  class="row">
           <div class="col">
             <div class="gene_name d-flex rounded">
               <h6 class="gene_title mb-0">GENE</h6>
               <span *ngFor= "let geneMutation of geneMutationlist | slice:0:8"><i>{{geneMutation.gene}}</i></span>

             </div>
             <div class="gene_status d-flex ">
              <h6 class="gene_title mb-0">STATUS</h6>
              <span *ngFor= "let geneMutation of geneMutationlist | slice:0:8" style="font-size :14pximportant;">
                <span *ngIf="geneMutation.status=='Pathogenic' || geneMutation.status=='Likely Pathogenic'" style="color:red; font-weight:600px"><b>{{geneMutation.status}}</b></span>
                <span *ngIf="geneMutation.status=='VUS'" style="color:black; font-weight:600px"><b>{{geneMutation.status}}</b></span>
                <span *ngIf="geneMutation.status == 'Not Detected' || geneMutation.status =='Benige'">{{geneMutation.status}}</span>
              </span>
              <!-- <span class="det_cyan">Detected</span> -->
        <!-- </div>
           </div>
         </div> -->

        <!-- <div *ngIf="geneMutationlist != null &&  geneMutationlist.length > 8"  class="row mt-4">
          <div class="col">
            <div class="gene_name d-flex rounded">
              <h6 class="gene_title mb-0">GENE</h6>
              <span *ngFor= "let geneMutation of geneMutationlist | slice:8:17"><i>{{geneMutation.gene}}</i></span>

            </div>
            <div class="gene_status d-flex ">
             <h6 class="gene_title mb-0">STATUS</h6>
             <span *ngFor= "let geneMutation of geneMutationlist | slice:8:17" style="font-size :14pximportant;">
              <span *ngIf="geneMutation.status=='Pathogenic' || geneMutation.status=='Likely Pathogenic'" style="color:red; font-weight:600px"><b>{{geneMutation.status}}</b></span>
              <span *ngIf="geneMutation.status=='VUS'" style="color:black; font-weight:600px"><b>{{geneMutation.status}}</b></span>
              <span *ngIf="geneMutation.status == 'Not Detected' || geneMutation.status =='Benige'">{{geneMutation.status}}</span>
            </span>
           </div>
          </div>
        </div> -->

        <div
          *ngIf="
            report.disclaimerNote != ' ' &&
            report.disclaimerNote != null &&
            fusionGeneMutationList.length <= 0
          "
        >

          <h6 style="color: #208dcb"><b>Note: </b></h6>
          <div
            style="font-size: 14px !important;text-align: justify"
            [innerHtml]="report.disclaimerNote"
          ></div>
          <!-- <div class="py-2" [innerHtml]='report.disclaimerNote' style=" text-align: justify;font-size: 14px!important;"></div> -->

          <!-- <p>The data size we had received for this sample was inadequate. However, with the patient's best interest in mind we have further processed the sample. Kindly correlate clinically before making any treatment decisions.</p> -->
          <!-- <p>The data size we had received for this sample was inadequate. However, with the patient's best interest in mind we have further processed the sample and a false negative status of the result cannot be ruled out. Kindly correlate clinically before making any treatment decisions.</p> -->
        </div>

        <!--  -->
        <div class="row footer">
          <div class="col-12">
            <div class="pb-2 pl-4 text">
              <div class="d-block">
                <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
              </div>
              <div class="footer_body" [ngClass]="'gene-bg_blue'">
                <div class="footer_list">
                  <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
                  <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
                  <span>Gin - Q</span><span>Gly - G</span><span>His - H</span>
                  <span>Ile - I</span>
                </div>
                <div class="footer_list">
                  <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
                  <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
                  <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
                  <span>Val - V</span>
                </div>
              </div>
            </div>
            <div class="col-12 pt-1 ">
              <p style="font-size: 8px!important; line-height: 14px!important;">
                {{footerDiscription}}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div
        id="page1_2"
        *ngIf="newsnvHotspotTrailResList.length > 0"
        class="container position-relative page"
        style="background-color: white"
      >
        <div class="row header mt-1 py-4">
          <div>
            <div class="col-3">
              <img src="../../../assets/logo.png" alt="" style="width: 140px" />
            </div>
          </div>
          <div></div>
          <div class="col">
            <div class="patient-detailes ml-auto float-right">
              <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
                <li>
                  Patient Id <br />
                  <span>{{ report.patient?.ecrfId }} </span>
                </li>
                <li class="ml-4">
                  Cancer Site<br />
                  <span>{{ report.cancerType }}</span>
                </li>
                <li class="ml-4">
                  SAMPLE ID<br />
                  <span>{{ report.labDetails?.sampleId }}</span>
                </li>
                <li class="ml-4">
                  Registration Date<br />
                  <span>{{
                    report.labDetails?.sampleCollected | date : "dd/MM/yyyy"
                  }}</span>
                </li>
              </ul>
            </div>
          </div>
          <!--  -->
          <div *ngIf="report.clientType == '4basecare_client'">
            <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
              <div class="logo">
                <img
                  src="../../../assets/oncoquest logo.png"
                  alt=""
                  style="width: 100px"
                />
              </div>
            </div>
            <div *ngIf="report.clientName == 'path_labs'" class="col-3">
              <div class="logo">
                <img
                  src="../../../assets/pathlab.png"
                  alt=""
                  style="width: 100px"
                />
              </div>
            </div>
          </div>
        </div>

        <h6 class="py-2 h6_bg" [ngClass]="'skyBlue'">
          Genomic findings from tumor profiling
        </h6>
        <div class="row">
          <!-- <div class="col-12"  *ngIf="isReportDownload">
            <img [src]="firstPageTable" alt="" class="img-fluid w-90">
          </div> -->
          <div class="col-12" id="firstPageTable">
            <table
              class="page_1 cust_table w-100 mt-3 first_1"
              [ngClass]="'table_skyblue'"
            >
              <tr>
                <th>Genomic Alteration</th>
                <th>Relevant Therapies (in Same Cancer Type)</th>
                <th>Relevant Therapies (in Different Cancer)</th>
              </tr>



              <!-- SNV -->
              <ng-container>
                <tr *ngFor="let snvHotspotTrail of newsnvHotspotTrailResList">
                  <td>
                    <i>{{ snvHotspotTrail.gene }}</i>&nbsp;
                    <span *ngIf="!snvHotspotTrail.gene.includes('Wild type')">
                    <span *ngIf="snvHotspotTrail?.multiVariant==null">
                      <span *ngIf="snvHotspotTrail.exonNumber != null">{{snvHotspotTrail.exonNumber}}</span>&nbsp;<span *ngIf="snvHotspotTrail.pposition != null && snvHotspotTrail.pposition != ''">({{snvHotspotTrail.pposition?.replace(' ','')}})</span>
                        <p *ngIf="snvHotspotTrail.mutantBurdenPercent != null && !snvHotspotTrail.gene.includes('Wild type')">
                          Allelic burden: {{ snvHotspotTrail.mutantBurdenPercent }}%
                        </p></span>

                        <span *ngIf="snvHotspotTrail?.multiVariant!=null"><br/>
                          <span  style="font-size: 14px !important" [innerHtml]="snvHotspotTrail?.multiVariant"></span>
                        </span></span>
                  </td>
                  <td>
                    <table class="first_table w-100">
                      <thead class="border-bottom cust_clr">
                        <tr>
                          <th class="w-50">
                            <span class="font-weight-normal"><i>Therapy</i></span>
                          </th>
                          <th class="w-33">
                            <span class="font-weight-normal"
                              ><i>Clinical Relevance</i></span
                            >
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngIf="snvHotspotTrail.sameCancerType == null">
                          <td class="w-50">NA</td>
                          <td class="w-50">NA</td>
                        </tr>

                        <!-- <tr *ngIf="snvHotspotTrail.sameCancerType != null">
                            <td class="w-50">
                              <div *ngFor="let drugs of snvHotspotTrail.sameCancerType.drugList">
                              <span *ngFor="let drug of drugs, let i=index">
                                  {{drug.name}}<span *ngIf="drugs.length-1 != i">,</span>
                              </span>
                          </div>
                        </td>
                          <td class="w-50">
                              <div *ngFor="let association of snvHotspotTrail.sameCancerType.associationList">
                              {{association}}
                          </div>
                        </td>
                          </tr> -->
                        <ng-container
                          *ngIf="snvHotspotTrail.sameCancerType != null"
                        >
                          <tr
                            *ngFor="
                              let item of snvHotspotTrail.sameTypeRelevantData
                            "
                          >
                            <td  style="word-break: break-word">{{ item.therapy }}</td>
                            <td>{{ item.association }}</td>
                            <!-- <td>{{item.cancerType}}</td> -->
                          </tr>
                        </ng-container>
                      </tbody>
                    </table>
                  </td>
                  <td>
                    <table class="first_table w-100">
                      <thead class="border-bottom cust_clr">
                        <tr>
                          <th class="w-33">
                            <span class="font-weight-normal"><i>Therapy</i></span>
                          </th>
                          <th class="w-33">
                            <span class="font-weight-normal"
                              ><i>Clinical Relevance</i></span
                            >
                          </th>
                          <th class="w-33">
                            <span class="font-weight-normal"
                              ><i>Cancer Type</i></span
                            >
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <!-- <tr *ngIf="snvHotspotTrail.otherCancerType != null">
                            <td class="w-33 ">
                              <div *ngFor="let drugs of snvHotspotTrail.otherCancerType.drugList">
                              <span *ngFor="let drug of drugs, let i=index">
                                  {{drug.name}}<span *ngIf="drugs.length-1 != i">,</span>
                              </span>
                          </div>
                        </td>
                          <td class="w-33">
                              <div *ngFor="let association of snvHotspotTrail.otherCancerType.associationList">
                              {{association}}
                          </div>
                        </td>
                        <td class="w-33">
                          <div *ngFor="let cancerType of snvHotspotTrail.otherCancerType.cancerTypeList">
                          {{cancerType.name}}
                          </div>
                        </td>
                          </tr> -->
                        <ng-container
                          *ngIf="snvHotspotTrail.otherCancerType != null"
                        >
                          <tr
                            *ngFor="
                              let item of snvHotspotTrail.otherTypeRelevantData
                            "
                          >
                            <td>{{ item.therapy }}</td>
                            <td>{{ item.association }}</td>
                            <td>{{ item.cancerType }}</td>
                          </tr>
                        </ng-container>
                        <tr *ngIf="snvHotspotTrail.otherCancerType == null">
                          <td class="w-33">NA</td>
                          <td class="w-33">NA</td>
                          <td class="w-33">NA</td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </ng-container>
            </table>
            <p
              *ngIf="newsnvHotspotTrailResList.length != 0"
              style="font-size: 13px !important"
              class="mt-3"
            >
              <i> *NA: Not Applicable</i>
            </p>
          </div>
        </div>
        <!--  -->
        <div class="row footer">
          <div class="col-12">
            <div class="pb-2 pl-4 text">
              <div class="d-block">
                <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
              </div>
              <div class="footer_body" [ngClass]="'gene-bg_blue'">
                <div class="footer_list">
                  <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
                  <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
                  <span>Gin - Q</span><span>Gly - G</span><span>His - H</span>
                  <span>Ile - I</span>
                </div>
                <div class="footer_list">
                  <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
                  <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
                  <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
                  <span>Val - V</span>
                </div>
              </div>
            </div>
            <div class="col-12 pt-1 ">
              <p style="font-size: 8px!important; line-height: 14px!important;">
                {{footerDiscription}}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div
        id="page2"
        *ngIf="(report.addNotes != '<div></div>' && report.addNotes != null) ||
        otherCountSNV == true ||
        reportPdfRes.allVariantsList.length > 0 ||
        (report.disclaimerNote != ' ' && report.disclaimerNote != null)
        "
        class="container position-relative page"
        style="background-color: white"
      >
        <div class="row header mt-1 py-4">
          <div>
            <div class="col-3">
              <img src="../../../assets/logo.png" alt="" style="width: 140px" />
            </div>
          </div>
          <div></div>
          <div class="col">
            <div class="patient-detailes ml-auto float-right">
              <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
                <li>
                  Patient Id <br />
                  <span>{{ report.patient?.ecrfId }} </span>
                </li>
                <li class="ml-4">
                  Cancer Site<br />
                  <span>{{ report.cancerType }}</span>
                </li>
                <li class="ml-4">
                  SAMPLE ID<br />
                  <span>{{ report.labDetails?.sampleId }}</span>
                </li>
                <li class="ml-4">
                  Registration Date<br />
                  <span>{{
                    report.labDetails?.sampleCollected | date : "dd/MM/yyyy"
                  }}</span>
                </li>
              </ul>
            </div>
          </div>
          <!--  -->
          <div *ngIf="report.clientType == '4basecare_client'">
            <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
              <div class="logo">
                <img
                  src="../../../assets/oncoquest logo.png"
                  alt=""
                  style="width: 100px"
                />
              </div>
            </div>
            <div *ngIf="report.clientName == 'path_labs'" class="col-3">
              <div class="logo">
                <img
                  src="../../../assets/pathlab.png"
                  alt=""
                  style="width: 100px"
                />
              </div>
            </div>
          </div>
        </div>
        <table class="staus_gene" [ngClass]="'bg_skyblue'" *ngIf="
        ((reportPdfRes.snvHotspotTrailResList.length >1  ||
        reportPdfRes.cnaHotspotTrailResList.length >1  &&
        reportPdfRes.fusionHotspotTrailResList.length > 0) ||
        reportPdfRes.snvReportedVariantList!=null ) &&
        !geneFlag &&
          (geneMutationlist != null &&
          geneMutationlist.length > 17)

            ">
          <tr style="background: #daedf7">
            <td style="font-size: 18px !important; padding-left: 10px !important">
              Gene
            </td>
            <td *ngFor="
                  let geneMutation of geneMutationlist
                    | slice : 17 : 25
                ">
              <i>{{ geneMutation.gene }}</i>
            </td>
          </tr>
          <tr>
            <td style="font-size: 18px !important; padding-left: 10px !important">
              <b>Status</b>
            </td>
            <td *ngFor="
                  let geneMutation of geneMutationlist
                    | slice : 17 : 25
                ">
              <span *ngIf="
                    geneMutation.status == 'Pathogenic' ||
                    geneMutation.status == 'Likely Pathogenic' ||
                    geneMutation.status == 'Detected'
                  " style="color: red; font-weight: 600px"><b>{{ geneMutation.status }}</b></span>
              <span *ngIf="geneMutation.status == 'VUS'" style="color: black; font-weight: 600px"><b>{{
                  geneMutation.status }}</b></span>
              <span *ngIf="
                    geneMutation.status == 'Not Detected' ||
                    geneMutation.status == 'Benige'
                  ">{{ geneMutation.status }}</span>
            </td>
          </tr>
        </table>
        <br />
        <table class="staus_gene" [ngClass]="'bg_skyblue'" *ngIf="
        ((reportPdfRes.snvHotspotTrailResList.length >1  ||
        reportPdfRes.cnaHotspotTrailResList.length >1  &&
        reportPdfRes.fusionHotspotTrailResList.length > 0) ||
        reportPdfRes.snvReportedVariantList!=null ) &&
        !geneFlag &&
              (geneMutationlist != null &&
              geneMutationlist.length > 25)
            ">
          <tr style="background: #daedf7">
            <td style="font-size: 18px !important; padding-left: 10px !important">
              Gene
            </td>
            <td *ngFor="
                  let geneMutation of geneMutationlist
                    | slice : 25 : 33
                ">
              <i>{{ geneMutation.gene }}</i>
            </td>
          </tr>
          <tr>
            <td style="font-size: 18px !important; padding-left: 10px !important">
              <b>Status</b>
            </td>
            <td *ngFor="
                  let geneMutation of geneMutationlist
                    | slice : 25 : 33
                ">
              <span *ngIf="
                    geneMutation.status == 'Pathogenic' ||
                    geneMutation.status == 'Likely Pathogenic' ||
                    geneMutation.status == 'Detected'
                  " style="color: red; font-weight: 600px"><b>{{ geneMutation.status }}</b></span>
              <span *ngIf="geneMutation.status == 'VUS'" style="color: black; font-weight: 600px"><b>{{
                  geneMutation.status }}</b></span>
              <span *ngIf="
                    geneMutation.status == 'Not Detected' ||
                    geneMutation.status == 'Benige'
                  ">{{ geneMutation.status }}</span>
            </td>
          </tr>
        </table>
        <br />
        <table class="staus_gene" [ngClass]="'bg_skyblue'" *ngIf="
        ((reportPdfRes.snvHotspotTrailResList.length >1  ||
        reportPdfRes.cnaHotspotTrailResList.length >1  &&
        reportPdfRes.fusionHotspotTrailResList.length > 0) ||
        reportPdfRes.snvReportedVariantList!=null ) &&
        !geneFlag &&
              (geneMutationlist != null &&
              geneMutationlist.length > 33)
            ">
          <tr style="background: #daedf7">
            <td style="font-size: 18px !important; padding-left: 10px !important">
              Gene
            </td>
            <td *ngFor="
                  let geneMutation of geneMutationlist
                    | slice : 33 : 41
                ">
              <i>{{ geneMutation.gene }}</i>
            </td>
          </tr>
          <tr>
            <td style="font-size: 18px !important; padding-left: 10px !important">
              <b>Status</b>
            </td>
            <td *ngFor="
                  let geneMutation of geneMutationlist
                    | slice : 33 : 41
                ">
              <span *ngIf="
                    geneMutation.status == 'Pathogenic' ||
                    geneMutation.status == 'Likely Pathogenic' ||
                    geneMutation.status == 'Detected'
                  " style="color: red; font-weight: 600px"><b>{{ geneMutation.status }}</b></span>
              <span *ngIf="geneMutation.status == 'VUS'" style="color: black; font-weight: 600px"><b>{{
                  geneMutation.status }}</b></span>
              <span *ngIf="
                    geneMutation.status == 'Not Detected' ||
                    geneMutation.status == 'Benige'
                  ">{{ geneMutation.status }}</span>
            </td>
          </tr>
        </table>
        <h6
        *ngIf="
        ((reportPdfRes.snvHotspotTrailResList.length >1  ||
        reportPdfRes.cnaHotspotTrailResList.length >1  &&
        reportPdfRes.fusionHotspotTrailResList.length > 0) ||
        reportPdfRes.snvReportedVariantList!=null ) &&
        !geneFlag &&
          (fusionGeneMutationList != null &&
          fusionGeneMutationList.length > 0)"
        class="font-weight-bold clr-cyan text-uppercase"
        [ngClass]="'txt-skyblue'"
      >
        Gene Fusion
      </h6>
      <table
        class="staus_gene"
        *ngIf="
        ((reportPdfRes.snvHotspotTrailResList.length >1  ||
        reportPdfRes.cnaHotspotTrailResList.length >1  &&
        reportPdfRes.fusionHotspotTrailResList.length > 0) ||
        reportPdfRes.snvReportedVariantList!=null ) &&
        !geneFlag &&
          (fusionGeneMutationList != null &&
          fusionGeneMutationList.length > 0)" [ngClass]="'bg_skyblue'"
      >
        <tr style="background: #daedf7">
          <td
            style="font-size: 18px !important; padding-left: 10px !important"
          >
            Gene
          </td>
          <td
            *ngFor="
              let geneMutation of fusionGeneMutationList
                | slice : 0 : 8
            "
          >
            <i>{{ geneMutation.gene }}</i>
          </td>
        </tr>
        <tr>
          <td
            style="font-size: 18px !important; padding-left: 10px !important"
          >
            <b>Status</b>
          </td>
          <td
            *ngFor="
              let geneMutation of fusionGeneMutationList
                | slice : 0 : 8
            "
          >
            <span
              *ngIf="
                geneMutation.status == 'Pathogenic' ||
                geneMutation.status == 'Likely Pathogenic' ||
                geneMutation.status == 'Detected'
              "
              style="color: red; font-weight: 600px"
              ><b>{{ geneMutation.status }}</b></span
            >
            <span
              *ngIf="geneMutation.status == 'VUS'"
              style="color: black; font-weight: 600px"
              ><b>{{ geneMutation.status }}</b></span
            >
            <span
              *ngIf="
                geneMutation.status == 'Not Detected' ||
                geneMutation.status == 'Benige'
              "
              >{{ geneMutation.status }}</span
            >
          </td>
        </tr>
      </table>
      <br/>
      <table
        class="staus_gene"
        *ngIf="
        ((reportPdfRes.snvHotspotTrailResList.length >1  ||
        reportPdfRes.cnaHotspotTrailResList.length >1  &&
        reportPdfRes.fusionHotspotTrailResList.length > 0) ||
        reportPdfRes.snvReportedVariantList!=null ) &&
        !geneFlag &&
          (fusionGeneMutationList != null &&
          fusionGeneMutationList.length > 8)" [ngClass]="'bg_skyblue'"
      >
        <tr style="background: #daedf7">
          <td
            style="font-size: 18px !important; padding-left: 10px !important"
          >
            Gene
          </td>
          <td
            *ngFor="
              let geneMutation of fusionGeneMutationList
                | slice : 8 : 17
            "
          >
            <i>{{ geneMutation.gene }}</i>
          </td>
        </tr>
        <tr>
          <td
            style="font-size: 18px !important; padding-left: 10px !important"
          >
            <b>Status</b>
          </td>
          <td
            *ngFor="
              let geneMutation of fusionGeneMutationList
                | slice : 8 : 17
            "
          >
            <span
              *ngIf="
                geneMutation.status == 'Pathogenic' ||
                geneMutation.status == 'Likely Pathogenic' ||
                geneMutation.status == 'Detected'
              "
              style="color: red; font-weight: 600px"
              ><b>{{ geneMutation.status }}</b></span
            >
            <span
              *ngIf="geneMutation.status == 'VUS'"
              style="color: black; font-weight: 600px"
              ><b>{{ geneMutation.status }}</b></span
            >
            <span
              *ngIf="
                geneMutation.status == 'Not Detected' ||
                geneMutation.status == 'Benige'
              "
              >{{ geneMutation.status }}</span
            >
          </td>
        </tr>
      </table>
        <div
          *ngIf="
            report.disclaimerNote != ' ' &&
            report.disclaimerNote != null &&
            tableCount >= 6
          "
        >
          <h6 style="color: #208dcb"><b>Note: </b></h6>
          <div
            style="font-size: 14px !important;text-align: justify"
            [innerHtml]="report.disclaimerNote"
          ></div>
          <!-- <div class="py-2" [innerHtml]='report.disclaimerNote' style=" text-align: justify;font-size: 14px!important;"></div> -->

          <!-- <p>The data size we had received for this sample was inadequate. However, with the patient's best interest in mind we have further processed the sample. Kindly correlate clinically before making any treatment decisions.</p> -->
          <!-- <p>The data size we had received for this sample was inadequate. However, with the patient's best interest in mind we have further processed the sample and a false negative status of the result cannot be ruled out. Kindly correlate clinically before making any treatment decisions.</p> -->
        </div>
        <div *ngIf="geneFlag && isStatusDisplay">
          <div *ngIf="geneMutationlist != null" class="mt-3">
            <h6 class="py-2 h6_bg" [ngClass]="'skyBlue'">
              Status of variants in cancer related Biomarkers
            </h6>
          </div>
          <table
            class="staus_gene"
            *ngIf="geneMutationlist != null"
            [ngClass]="'bg_skyblue'"
          >
            <tr style="background: #daedf7">
              <td
                style="font-size: 18px !important; padding-left: 10px !important"
              >
                Gene
              </td>
              <td
                *ngFor="
                  let geneMutation of geneMutationlist
                    | slice : 0 : 8
                "
              >
                <i>{{ geneMutation.gene }}</i>
              </td>
            </tr>
            <tr>
              <td
                style="font-size: 18px !important; padding-left: 10px !important"
              >
                <b>Status</b>
              </td>
              <td
                *ngFor="
                  let geneMutation of geneMutationlist
                    | slice : 0 : 8
                "
              >
                <span
                  *ngIf="
                    geneMutation.status == 'Pathogenic' ||
                    geneMutation.status == 'Likely Pathogenic' ||
                    geneMutation.status == 'Detected'
                  "
                  style="color: red; font-weight: 600px"
                  ><b>{{ geneMutation.status }}</b></span
                >
                <span
                  *ngIf="geneMutation.status == 'VUS'"
                  style="color: black; font-weight: 600px"
                  ><b>{{ geneMutation.status }}</b></span
                >
                <span
                  *ngIf="
                    geneMutation.status == 'Not Detected' ||
                    geneMutation.status == 'Benige'
                  "
                  >{{ geneMutation.status }}</span
                >
              </td>
            </tr>
          </table>
          <br />
          <table
            class="staus_gene"
            [ngClass]="'bg_skyblue'"
            *ngIf="
              geneMutationlist != null &&
              geneMutationlist.length > 8
            "
          >
            <tr style="background: #daedf7">
              <td
                style="font-size: 18px !important; padding-left: 10px !important"
              >
                Gene
              </td>
              <td
                *ngFor="
                  let geneMutation of geneMutationlist
                    | slice : 8 : 17
                "
              >
                <i>{{ geneMutation.gene }}</i>
              </td>
            </tr>
            <tr>
              <td
                style="font-size: 18px !important; padding-left: 10px !important"
              >
                <b>Status</b>
              </td>
              <td
                *ngFor="
                  let geneMutation of geneMutationlist
                    | slice : 8 : 17
                "
              >
                <span
                  *ngIf="
                    geneMutation.status == 'Pathogenic' ||
                    geneMutation.status == 'Likely Pathogenic' ||
                    geneMutation.status == 'Detected'
                  "
                  style="color: red; font-weight: 600px"
                  ><b>{{ geneMutation.status }}</b></span
                >
                <span
                  *ngIf="geneMutation.status == 'VUS'"
                  style="color: black; font-weight: 600px"
                  ><b>{{ geneMutation.status }}</b></span
                >
                <span
                  *ngIf="
                    geneMutation.status == 'Not Detected' ||
                    geneMutation.status == 'Benige'
                  "
                  >{{ geneMutation.status }}</span
                >
              </td>
            </tr>
            <br/>
          </table>
          <table
            class="staus_gene"
            [ngClass]="'bg_skyblue'"
            *ngIf="
              geneMutationlist != null &&
              geneMutationlist.length > 17
            "
          >
            <tr style="background: #daedf7">
              <td
                style="font-size: 18px !important; padding-left: 10px !important"
              >
                Gene
              </td>
              <td
                *ngFor="
                  let geneMutation of geneMutationlist
                    | slice : 17 : 25
                "
              >
                <i>{{ geneMutation.gene }}</i>
              </td>
            </tr>
            <tr>
              <td
                style="font-size: 18px !important; padding-left: 10px !important"
              >
                <b>Status</b>
              </td>
              <td
                *ngFor="
                  let geneMutation of geneMutationlist
                    | slice : 17 : 25
                "
              >
                <span
                  *ngIf="
                    geneMutation.status == 'Pathogenic' ||
                    geneMutation.status == 'Likely Pathogenic' ||
                    geneMutation.status == 'Detected'
                  "
                  style="color: red; font-weight: 600px"
                  ><b>{{ geneMutation.status }}</b></span
                >
                <span
                  *ngIf="geneMutation.status == 'VUS'"
                  style="color: black; font-weight: 600px"
                  ><b>{{ geneMutation.status }}</b></span
                >
                <span
                  *ngIf="
                    geneMutation.status == 'Not Detected' ||
                    geneMutation.status == 'Benige'
                  "
                  >{{ geneMutation.status }}</span
                >
              </td>
            </tr>
            <br/>
          </table>
          <table class="staus_gene" [ngClass]="'bg_skyblue'" *ngIf="
                geneMutationlist != null &&
                geneMutationlist.length > 25
              ">
            <tr style="background: #daedf7">
              <td style="font-size: 18px !important; padding-left: 10px !important">
                Gene
              </td>
              <td *ngFor="
                    let geneMutation of geneMutationlist
                      | slice : 25 : 33
                  ">
                <i>{{ geneMutation.gene }}</i>
              </td>
            </tr>
            <tr>
              <td style="font-size: 18px !important; padding-left: 10px !important">
                <b>Status</b>
              </td>
              <td *ngFor="
                    let geneMutation of geneMutationlist
                      | slice : 25 : 33
                  ">
                <span *ngIf="
                      geneMutation.status == 'Pathogenic' ||
                      geneMutation.status == 'Likely Pathogenic' ||
                      geneMutation.status == 'Detected'
                    " style="color: red; font-weight: 600px"><b>{{ geneMutation.status }}</b></span>
                <span *ngIf="geneMutation.status == 'VUS'" style="color: black; font-weight: 600px"><b>{{
                    geneMutation.status }}</b></span>
                <span *ngIf="
                      geneMutation.status == 'Not Detected' ||
                      geneMutation.status == 'Benige'
                    ">{{ geneMutation.status }}</span>
              </td>
            </tr>
            <br/>
          </table>
          <table class="staus_gene" [ngClass]="'bg_skyblue'" *ngIf="
                geneMutationlist != null &&
                geneMutationlist.length > 33
              ">
            <tr style="background: #daedf7">
              <td style="font-size: 18px !important; padding-left: 10px !important">
                Gene
              </td>
              <td *ngFor="
                    let geneMutation of geneMutationlist
                      | slice : 33 : 41
                  ">
                <i>{{ geneMutation.gene }}</i>
              </td>
            </tr>
            <tr>
              <td style="font-size: 18px !important; padding-left: 10px !important">
                <b>Status</b>
              </td>
              <td *ngFor="
                    let geneMutation of geneMutationlist
                      | slice : 33 : 41
                  ">
                <span *ngIf="
                      geneMutation.status == 'Pathogenic' ||
                      geneMutation.status == 'Likely Pathogenic' ||
                      geneMutation.status == 'Detected'
                    " style="color: red; font-weight: 600px"><b>{{ geneMutation.status }}</b></span>
                <span *ngIf="geneMutation.status == 'VUS'" style="color: black; font-weight: 600px"><b>{{
                    geneMutation.status }}</b></span>
                <span *ngIf="
                      geneMutation.status == 'Not Detected' ||
                      geneMutation.status == 'Benige'
                    ">{{ geneMutation.status }}</span>
              </td>
            </tr>
            <br/>
          </table>
          <h6
            *ngIf="
              fusionGeneMutationList != null &&
              fusionGeneMutationList.length > 0
            "
            class="font-weight-bold clr-cyan text-uppercase"
            [ngClass]="'txt-skyblue'"
          >
            Gene Fusion
          </h6>
          <table
            class="staus_gene"
            *ngIf="
              fusionGeneMutationList != null &&
              fusionGeneMutationList.length > 0
            "
            [ngClass]="'bg_skyblue'"
          >
            <tr style="background: #daedf7">
              <td
                style="font-size: 18px !important; padding-left: 10px !important"
              >
                Gene
              </td>
              <td
                *ngFor="
                  let geneMutation of fusionGeneMutationList
                    | slice : 0 : 8
                "
              >
                <i>{{ geneMutation.gene }}</i>
              </td>
            </tr>
            <tr>
              <td
                style="font-size: 18px !important; padding-left: 10px !important"
              >
                <b>Status</b>
              </td>
              <td
                *ngFor="
                  let geneMutation of fusionGeneMutationList
                    | slice : 0 : 8
                "
              >
                <span
                  *ngIf="
                    geneMutation.status == 'Pathogenic' ||
                    geneMutation.status == 'Likely Pathogenic' ||
                    geneMutation.status == 'Detected'
                  "
                  style="color: red; font-weight: 600px"
                  ><b>{{ geneMutation.status }}</b></span
                >
                <span
                  *ngIf="geneMutation.status == 'VUS'"
                  style="color: black; font-weight: 600px"
                  ><b>{{ geneMutation.status }}</b></span
                >
                <span
                  *ngIf="
                    geneMutation.status == 'Not Detected' ||
                    geneMutation.status == 'Benige'
                  "
                  >{{ geneMutation.status }}</span
                >
              </td>
            </tr>
          </table>
          <br/>
          <table
            class="staus_gene"
            *ngIf="
              fusionGeneMutationList != null &&
              fusionGeneMutationList.length > 0
            "
            [ngClass]="'bg_skyblue'"
          >
            <tr style="background: #daedf7">
              <td
                style="font-size: 18px !important; padding-left: 10px !important"
              >
                Gene
              </td>
              <td
                *ngFor="
                  let geneMutation of fusionGeneMutationList
                    | slice : 8 : 17
                "
              >
                <i>{{ geneMutation.gene }}</i>
              </td>
            </tr>
            <tr>
              <td
                style="font-size: 18px !important; padding-left: 10px !important"
              >
                <b>Status</b>
              </td>
              <td
                *ngFor="
                  let geneMutation of fusionGeneMutationList
                    | slice : 8 : 17
                "
              >
                <span
                  *ngIf="
                    geneMutation.status == 'Pathogenic' ||
                    geneMutation.status == 'Likely Pathogenic' ||
                    geneMutation.status == 'Detected'
                  "
                  style="color: red; font-weight: 600px"
                  ><b>{{ geneMutation.status }}</b></span
                >
                <span
                  *ngIf="geneMutation.status == 'VUS'"
                  style="color: black; font-weight: 600px"
                  ><b>{{ geneMutation.status }}</b></span
                >
                <span
                  *ngIf="
                    geneMutation.status == 'Not Detected' ||
                    geneMutation.status == 'Benige'
                  "
                  >{{ geneMutation.status }}</span
                >
              </td>
            </tr>
          </table>
        </div>

        <!--  -->
        <div
        *ngIf="
          report.disclaimerNote != ' ' &&
          report.disclaimerNote != null &&
          fusionGeneMutationList.length > 0
        "
      >

        <h6 style="color: #208dcb"><b>Note: </b></h6>
        <div
          style="font-size: 14px !important;text-align: justify"
          [innerHtml]="report.disclaimerNote"
        ></div>
        <!-- <div class="py-2" [innerHtml]='report.disclaimerNote' style=" text-align: justify;font-size: 14px!important;"></div> -->

        <!-- <p>The data size we had received for this sample was inadequate. However, with the patient's best interest in mind we have further processed the sample. Kindly correlate clinically before making any treatment decisions.</p> -->
        <!-- <p>The data size we had received for this sample was inadequate. However, with the patient's best interest in mind we have further processed the sample and a false negative status of the result cannot be ruled out. Kindly correlate clinically before making any treatment decisions.</p> -->
      </div>

        <div
          class="row mt-3"
          *ngIf="
            otherCountSNV == true ||
            reportPdfRes.allVariantsList.length != 0
          "
        >
          <div class="col">
            <h6
              class="font-weight-bold clr-cyan text-uppercase"
              [ngClass]="'txt-skyblue'"
            >
              Variant Details:
            </h6>
          </div>
        </div>

        <div
          class="row"
          *ngIf="
            otherCountSNV == true ||
            reportPdfRes.allVariantsList.length != 0
          "
        >
          <div class="col-12">
            <table
              class="page_1 cust_table w-100 mt-3 varient_details"
              [ngClass]="'table_variant-blue'"
            >
              <thead>
                <tr>
                  <th>Gene</th>
                  <th>Variant Location</th>
                  <th>Variant Consequence</th>
                  <th>Clinical Significance</th>
                  <th>Variant Type</th>
                  <th>Reference</th>
                </tr>
              </thead>

              <!-- SNV -->
              <tr
                *ngFor="
                  let selectedReportedVariant of reportPdfRes.snvReportedVariantList
                "
              >
                <td
                  *ngIf="
                    selectedReportedVariant.significance != 'NA' &&
                    selectedReportedVariant.significance != 'Not Classified'
                  "
                  style="padding-left: 3px !important"
                >
                  <i>{{ selectedReportedVariant.refGeneX }}</i>
                </td>
                <td
                  *ngIf="
                    selectedReportedVariant.significance != 'NA' &&
                    selectedReportedVariant.significance != 'Not Classified'
                  "
                  style="padding-left: 3px !important"
                >
                  {{ selectedReportedVariant.genomicAlteration }},<br />
                  {{ selectedReportedVariant.ensembleValue }},<br />
                  {{ selectedReportedVariant.exonNumber }}
                </td>
                <td
                  *ngIf="
                    selectedReportedVariant.significance != 'NA' &&
                    selectedReportedVariant.significance != 'Not Classified'
                  "
                  style="padding-left: 3px !important"
                >
                <span *ngIf="selectedReportedVariant.selectedAAChangeknownGene">{{ selectedReportedVariant.selectedAAChangeknownGene|splitComma }}</span>, {{selectedReportedVariant.mutantBurdenPercent * 0.01 | percent}}
                </td>
                <td
                  *ngIf="
                    selectedReportedVariant.significance != 'NA' &&
                    selectedReportedVariant.significance != 'Not Classified'
                  "
                  style="padding-left: 3px !important"
                >
                  {{ selectedReportedVariant.significance }}
                </td>
                <td
                  *ngIf="
                    selectedReportedVariant.significance != 'NA' &&
                    selectedReportedVariant.significance != 'Not Classified'
                  "
                  style="padding-left: 3px !important"
                >
                  {{ selectedReportedVariant.exonicFuncknownGene }}
                </td>
                <td
                  *ngIf="
                    selectedReportedVariant.significance != 'NA' &&
                    selectedReportedVariant.significance != 'Not Classified'
                  "
                  style="padding-left: 3px !important"
                >
                  {{ selectedReportedVariant.reference }}
                </td>
              </tr>

              <!-- CNA -->
              <tr
                *ngFor="
                  let cnaReportedVariant of reportPdfRes.cnaReportedVariantList
                "
              >
                <td
                  *ngIf="
                    cnaReportedVariant.significance != 'NA' &&
                    cnaReportedVariant.significance != 'Not Classified'
                  "
                  style="padding-left: 3px !important"
                >
                  <i>{{ cnaReportedVariant.gene }}</i>
                </td>
                <td
                  *ngIf="
                    cnaReportedVariant.significance != 'NA' &&
                    cnaReportedVariant.significance != 'Not Classified'
                  "
                  style="padding-left: 3px !important"
                >
                  chr{{ cnaReportedVariant.sample.split(":")[0] }}:g.{{
                    cnaReportedVariant.sample
                      .split(":")[1]
                      .replace("-", "_")
                      .split("|")[0]
                  }}
                </td>
                <td
                  *ngIf="
                    cnaReportedVariant.significance != 'NA' &&
                    cnaReportedVariant.significance != 'Not Classified'
                  "
                  style="padding-left: 3px !important"
                >
                  NA
                </td>
                <td
                  *ngIf="
                    cnaReportedVariant.significance != 'NA' &&
                    cnaReportedVariant.significance != 'Not Classified'
                  "
                  style="padding-left: 3px !important"
                >
                  {{ cnaReportedVariant.significance }}
                </td>
                <td
                  *ngIf="
                    cnaReportedVariant.significance != 'NA' &&
                    cnaReportedVariant.significance != 'Not Classified'
                  "
                  style="padding-left: 3px !important"
                >
                  Copy Number
                  {{ cnaReportedVariant.sample.split(":")[2] | titlecase }}
                  ({{ cnaReportedVariant.sample.split("|")[1].split(":")[0] }})
                </td>
                <td
                  *ngIf="
                    cnaReportedVariant.significance != 'NA' &&
                    cnaReportedVariant.significance != 'Not Classified'
                  "
                  style="padding-left: 3px !important"
                >
                  {{ cnaReportedVariant.reference }}
                </td>
              </tr>
              <!-- Fusion -->
              <tr
                *ngFor="
                  let fusionReportedVariant of reportPdfRes.fusionReportedVariantList
                "
              >
                <td
                  *ngIf="
                    fusionReportedVariant.significance != 'NA' &&
                    fusionReportedVariant.significance != 'Not Classified'
                  "
                  style="padding-left: 3px !important"
                >
                  <i>{{ fusionReportedVariant.fusionGene }}</i>
                </td>
                <td
                  *ngIf="
                    fusionReportedVariant.significance != 'NA' &&
                    fusionReportedVariant.significance != 'Not Classified'
                  "
                  style="padding-left: 3px !important"
                >
                  {{ fusionReportedVariant.leftBreakPoint }}<br />{{
                    fusionReportedVariant.rightBreakPoint
                  }}
                </td>
                <td
                  *ngIf="
                    fusionReportedVariant.significance != 'NA' &&
                    fusionReportedVariant.significance != 'Not Classified'
                  "
                  style="padding-left: 3px !important"
                >
                  NA
                </td>
                <td
                  *ngIf="
                    fusionReportedVariant.significance != 'NA' &&
                    fusionReportedVariant.significance != 'Not Classified'
                  "
                  style="padding-left: 3px !important"
                >
                  {{ fusionReportedVariant.significance }}
                </td>
                <td
                  *ngIf="
                    fusionReportedVariant.significance != 'NA' &&
                    fusionReportedVariant.significance != 'Not Classified'
                  "
                  style="padding-left: 3px !important"
                >
                  Gene Fusions
                </td>
                <td
                  *ngIf="
                    fusionReportedVariant.significance != 'NA' &&
                    fusionReportedVariant.significance != 'Not Classified'
                  "
                  style="padding-left: 3px !important"
                >
                  {{ fusionReportedVariant.reference }}
                </td>
              </tr>
            </table>
            <p
              *ngIf="
                otherCountSNV == true ||
                reportPdfRes.allVariantsList.length > 0
              "
              style="font-size: 13px !important"
              class="mt-3"
            >
              <i> *NA: Not Applicable</i>
            </p>
          </div>
        </div>

        <div class="row mt-5" *ngIf="
                  !variantFlag &&
                  report.addNotes != null && report.addNotes != '<div></div>'
                ">
          <div class="col">
            <h6 class="py-2 h6_bg">Additional Notes</h6>
            <div class="py-2" [innerHtml]="report.addNotes" style="text-align: justify; font-size: 14px !important"></div>
          </div>
        </div>



        <!--  -->
        <div class="row footer">
          <div class="col-12">
            <div class="pb-2 pl-4 text">
              <div class="d-block">
                <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
              </div>
              <div class="footer_body" [ngClass]="'gene-bg_blue'">
                <div class="footer_list">
                  <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
                  <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
                  <span>Gin - Q</span><span>Gly - G</span><span>His - H</span>
                  <span>Ile - I</span>
                </div>
                <div class="footer_list">
                  <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
                  <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
                  <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
                  <span>Val - V</span>
                </div>
              </div>
            </div>
            <div class="col-12 pt-1 ">
              <p style="font-size: 8px!important; line-height: 14px!important;">
                {{footerDiscription}}
              </p>
            </div>
          </div>
        </div>
      </div>


      <!--  -->
    <div
        id="page2_1"
        *ngIf="
          variantFlag &&
          ((report.addNotes != '<div></div>' && report.addNotes != null) ||
            otherCountSNV == true ||
            reportPdfRes.allVariantsList.length > 0 ||
            (report.disclaimerNote != ' ' && report.disclaimerNote != null))
        "
        class="container position-relative page"
        style="background-color: white"
      >
        <div class="row header mt-1 py-4">
          <div>
            <div class="col-3">
              <img src="../../../assets/logo.png" alt="" style="width: 140px" />
            </div>
          </div>
          <div></div>
          <div class="col">
            <div class="patient-detailes ml-auto float-right">
              <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
                <li>
                  Patient Id <br />
                  <span>{{ report.patient?.ecrfId }} </span>
                </li>
                <li class="ml-4">
                  Cancer Site<br />
                  <span>{{ report.cancerType }}</span>
                </li>
                <li class="ml-4">
                  SAMPLE ID<br />
                  <span>{{ report.labDetails?.sampleId }}</span>
                </li>
                <li class="ml-4">
                  Registration Date<br />
                  <span>{{
                    report.labDetails?.sampleCollected | date : "dd/MM/yyyy"
                  }}</span>
                </li>
              </ul>
            </div>
          </div>
          <!--  -->
          <div *ngIf="report.clientType == '4basecare_client'">
            <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
              <div class="logo">
                <img
                  src="../../../assets/oncoquest logo.png"
                  alt=""
                  style="width: 100px"
                />
              </div>
            </div>
            <div *ngIf="report.clientName == 'path_labs'" class="col-3">
              <div class="logo">
                <img
                  src="../../../assets/pathlab.png"
                  alt=""
                  style="width: 100px"
                />
              </div>
            </div>
          </div>
        </div>

        <!--  -->

        <div class="row mt-5" *ngIf="
                  !variantFlag &&
                  report.addNotes != null && report.addNotes != '<div></div>'
                ">
          <div class="col">
            <h6 class="py-2 h6_bg">Additional Notes</h6>
            <div class="py-2" [innerHtml]="report.addNotes" style="text-align: justify; font-size: 14px !important"></div>
          </div>
        </div>

        <!--  -->
        <div class="row footer">
          <div class="col-12">
            <div class="pb-2 pl-4 text">
              <div class="d-block">
                <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
              </div>
              <div class="footer_body" [ngClass]="'gene-bg_blue'">
                <div class="footer_list">
                  <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
                  <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
                  <span>Gin - Q</span><span>Gly - G</span><span>His - H</span>
                  <span>Ile - I</span>
                </div>
                <div class="footer_list">
                  <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
                  <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
                  <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
                  <span>Val - V</span>
                </div>
              </div>
            </div>
            <div class="col-12 pt-1 ">
              <p style="font-size: 8px!important; line-height: 14px!important;">
                {{footerDiscription}}
              </p>
            </div>
          </div>
        </div>
      </div>
    <!-- -->
<!-- page 3 -->
<div id="page3" *ngIf="reportPdfRes.drugsResList.length > 0" class="container position-relative page"
  style="background-color: white">
  <div class="row header mt-1 py-4">
    <div>
      <div class="col-3">
        <img src="../../../assets/logo.png" alt="" style="width: 140px" />
      </div>
    </div>
    <div></div>
    <div class="col">
      <div class="patient-detailes ml-auto float-right">
        <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
          <li>
            Patient Id <br />
            <span>{{ report.patient?.ecrfId }}</span>
          </li>
          <li class="ml-4">
            Cancer Site<br />
            <span>{{ report.cancerType }}</span>
          </li>
          <li class="ml-4">
            SAMPLE ID<br />
            <span>{{ report.labDetails?.sampleId }}</span>
          </li>
          <li class="ml-4">
            Registration Date<br />
            <span>{{
              report.labDetails?.sampleCollected | date : "dd/MM/yyyy"
              }}</span>
          </li>
        </ul>
      </div>
    </div>
    <!--  -->
    <div *ngIf="report.clientType == '4basecare_client'">
      <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
        <div class="logo">
          <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
        </div>
      </div>
      <div *ngIf="report.clientName == 'path_labs'" class="col-3">
        <div class="logo">
          <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
        </div>
      </div>
    </div>
  </div>
  <!--  -->
  <div class="mar_top"></div>
  <!--  -->

  <h6 class="py-2 h6_bg" [ngClass]="'skyBlue'">
    Alterations With Clinically Relevant Therapies
  </h6>
  <!--  -->
  <div class="row">
    <div class="col-12">
      <table class="page_1 cust_table w-100 mt-3 first_2 custom-table" [ngClass]="'bg_cyan'">
        <thead>
          <tr>
            <th>Gene (Alterations) - Drug association</th>
            <th>Summary</th>
          </tr>
        </thead>
        <!-- SNV -->

        <tbody>
          <tr *ngFor="
                                                      let drugsRes of reportPdfRes.drugsResList | slice : 0 : 4;
                                                      let i = index
                                                    ">
            <td>
              <ul class="ml-0 pl-2">
                <li>
                  <i>{{ drugsRes.gene.replace("Fusion","") }}</i><span *ngIf="drugsRes.gene.includes('Fusion')">Fusion</span> {{ drugsRes.pposition?.replace(' ','') }} :
                  <br />
                  <span *ngFor="let drug of drugsRes.drugs; let i = index">
                    {{ drug.name
                    }}<span *ngIf="drugsRes.drugs.length - 1 != i">,</span>
                  </span>
                  - {{ drugsRes.association }}
                  <p>{{ drugsRes.cancerType }}</p>
                </li>
              </ul>
            </td>
            <td>
              <div [innerHtml]="drugsRes.summary"></div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <div class="row footer">
    <div class="col-12">
      <div class="pb-2 pl-4 text">
        <div class="d-block">
          <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
        </div>
        <div class="footer_body" [ngClass]="'gene-bg_blue'">
          <div class="footer_list">
            <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
            <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
            <span>Gin - Q</span><span>Gly - G</span><span>His - H</span>
            <span>Ile - I</span>
          </div>
          <div class="footer_list">
            <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
            <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
            <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
            <span>Val - V</span>
          </div>
        </div>
      </div>
      <div class="col-12 pt-1 ">
        <p style="font-size: 8px!important; line-height: 14px!important;">
          {{footerDiscription}}
        </p>
      </div>
    </div>
  </div>
</div>

<!-- page 3_1 -->
<div id="page3_1" *ngIf="reportPdfRes.drugsResList.length > 4" class="container position-relative page"
  style="background-color: white">
  <div class="row header mt-1 py-4">
    <div>
      <div class="col-3">
        <img src="../../../assets/logo.png" alt="" style="width: 140px" />
      </div>
    </div>
    <div></div>
    <div class="col">
      <div class="patient-detailes ml-auto float-right">
        <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
          <li>
            Patient Id <br />
            <span>{{ report.patient?.ecrfId }}</span>
          </li>
          <li class="ml-4">
            Cancer Site<br />
            <span>{{ report.cancerType }}</span>
          </li>
          <li class="ml-4">
            SAMPLE ID<br />
            <span>{{ report.labDetails?.sampleId }}</span>
          </li>
          <li class="ml-4">
            Registration Date<br />
            <span>{{
              report.labDetails?.sampleCollected | date : "dd/MM/yyyy"
              }}</span>
          </li>
        </ul>
      </div>
    </div>
    <!--  -->
    <div *ngIf="report.clientType == '4basecare_client'">
      <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
        <div class="logo">
          <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
        </div>
      </div>
      <div *ngIf="report.clientName == 'path_labs'" class="col-3">
        <div class="logo">
          <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
        </div>
      </div>
    </div>
  </div>
  <!--  -->
  <div class="mar_top"></div>
  <!--  -->

  <h6 class="py-2 h6_bg" [ngClass]="'skyBlue'">
    Alterations With Clinically Relevant Therapies
  </h6>
  <!--  -->
  <div class="row">
    <div class="col-12">
      <table class="page_1 cust_table w-100 mt-3 first_2 2nd_table">
        <thead>
          <tr>
            <th>Gene (Alterations) - Drug association</th>
            <th>Summary</th>
          </tr>
        </thead>
        <!-- SNV -->

        <tbody>
          <tr *ngFor="
                                                                let drugsRes of reportPdfRes.drugsResList | slice : 4 : 8;
                                                                let i = index
                                                              ">
            <td>
              <ul class="ml-0 pl-2">
                <li>
                  <i>{{ drugsRes.gene.replace("Fusion","") }}</i><span *ngIf="drugsRes.gene.includes('Fusion')">Fusion</span> {{ drugsRes.pposition?.replace(' ','') }} :
                  <br />
                  <span *ngFor="let drug of drugsRes.drugs; let i = index">
                    {{ drug.name
                    }}<span *ngIf="drugsRes.drugs.length - 1 != i">,</span>
                  </span>
                  - {{ drugsRes.association }}
                  <p>{{ drugsRes.cancerType }}</p>
                </li>
              </ul>
            </td>
            <td>
              <div [innerHtml]="drugsRes.summary"></div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <!--  -->

  <!--  -->
  <div class="row footer">
    <div class="col-12">
      <div class="pb-2 pl-4 text">
        <div class="d-block">
          <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
        </div>
        <div class="footer_body" [ngClass]="'gene-bg_blue'">
          <div class="footer_list">
            <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
            <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
            <span>Gin - Q</span><span>Gly - G</span><span>His - H</span>
            <span>Ile - I</span>
          </div>
          <div class="footer_list">
            <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
            <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
            <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
            <span>Val - V</span>
          </div>
        </div>
      </div>
      <div class="col-12 pt-1 ">
        <p style="font-size: 8px!important; line-height: 14px!important;">
          {{footerDiscription}}
        </p>
      </div>
    </div>
  </div>
</div>

<!-- page 3_2 -->
<div id="page3_2" *ngIf="reportPdfRes.drugsResList.length > 8" class="container position-relative page"
  style="background-color: white">
  <div class="row header mt-1 py-4">
    <div>
      <div class="col-3">
        <img src="../../../assets/logo.png" alt="" style="width: 140px" />
      </div>
    </div>
    <div></div>
    <div class="col">
      <div class="patient-detailes ml-auto float-right">
        <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
          <li>
            Patient Id <br />
            <span>{{ report.patient?.ecrfId }}</span>
          </li>
          <li class="ml-4">
            Cancer Site<br />
            <span>{{ report.cancerType }}</span>
          </li>
          <li class="ml-4">
            SAMPLE ID<br />
            <span>{{ report.labDetails?.sampleId }}</span>
          </li>
          <li class="ml-4">
            Registration Date<br />
            <span>{{
              report.labDetails?.sampleCollected | date : "dd/MM/yyyy"
              }}</span>
          </li>
        </ul>
      </div>
    </div>
    <!--  -->
    <div *ngIf="report.clientType == '4basecare_client'">
      <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
        <div class="logo">
          <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
        </div>
      </div>
      <div *ngIf="report.clientName == 'path_labs'" class="col-3">
        <div class="logo">
          <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
        </div>
      </div>
    </div>
  </div>
  <!--  -->
  <div class="mar_top"></div>
  <!--  -->

  <h6 class="py-2 h6_bg" [ngClass]="'skyBlue'">
    Alterations With Clinically Relevant Therapies
  </h6>
  <!--  -->
  <div class="row">
    <div class="col-12">
      <table class="page_1 cust_table w-100 mt-3 first_2 2nd_table">
        <thead>
          <tr>
            <th>Gene (Alterations) - Drug association</th>
            <th>Summary</th>
          </tr>
        </thead>
        <!-- SNV -->

        <tbody>
          <tr *ngFor="
                                                                                    let drugsRes of reportPdfRes.drugsResList | slice : 8 : 12;
                                                                                    let i = index
                                                                                  ">
            <td>
              <ul class="ml-0 pl-2">
                <li>
                  <i>{{ drugsRes.gene.replace("Fusion","") }}</i><span *ngIf="drugsRes.gene.includes('Fusion')">Fusion</span> {{ drugsRes.pposition?.replace(' ','')}} :
                  <br />
                  <span *ngFor="let drug of drugsRes.drugs; let i = index">
                    {{ drug.name
                    }}<span *ngIf="drugsRes.drugs.length - 1 != i">,</span>
                  </span>
                  - {{ drugsRes.association }}
                  <p>{{ drugsRes.cancerType }}</p>
                </li>
              </ul>
            </td>
            <td>
              <div [innerHtml]="drugsRes.summary"></div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <!--  -->

  <!--  -->
  <div class="row footer">
    <div class="col-12">
      <div class="pb-2 pl-4 text">
        <div class="d-block">
          <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
        </div>
        <div class="footer_body" [ngClass]="'gene-bg_blue'">
          <div class="footer_list">
            <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
            <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
            <span>Gin - Q</span><span>Gly - G</span><span>His - H</span>
            <span>Ile - I</span>
          </div>
          <div class="footer_list">
            <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
            <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
            <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
            <span>Val - V</span>
          </div>
        </div>
      </div>
      <div class="col-12 pt-1 ">
        <p style="font-size: 8px!important; line-height: 14px!important;">
          {{footerDiscription}}
        </p>
      </div>
    </div>
  </div>
</div>

<!-- page 3_3 -->
<div id="page3_3" *ngIf="reportPdfRes.drugsResList.length > 12" class="container position-relative page"
  style="background-color: white">
  <div class="row header mt-1 py-4">
    <div>
      <div class="col-3">
        <img src="../../../assets/logo.png" alt="" style="width: 140px" />
      </div>
    </div>
    <div></div>
    <div class="col">
      <div class="patient-detailes ml-auto float-right">
        <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
          <li>
            Patient Id <br />
            <span>{{ report.patient?.ecrfId }}</span>
          </li>
          <li class="ml-4">
            Cancer Site<br />
            <span>{{ report.cancerType }}</span>
          </li>
          <li class="ml-4">
            SAMPLE ID<br />
            <span>{{ report.labDetails?.sampleId }}</span>
          </li>
          <li class="ml-4">
            Registration Date<br />
            <span>{{
              report.labDetails?.sampleCollected | date : "dd/MM/yyyy"
              }}</span>
          </li>
        </ul>
      </div>
    </div>
    <!--  -->
    <div *ngIf="report.clientType == '4basecare_client'">
      <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
        <div class="logo">
          <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
        </div>
      </div>
      <div *ngIf="report.clientName == 'path_labs'" class="col-3">
        <div class="logo">
          <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
        </div>
      </div>
    </div>
  </div>
  <!--  -->
  <div class="mar_top"></div>
  <!--  -->

  <h6 class="py-2 h6_bg" [ngClass]="'skyBlue'">
    Alterations With Clinically Relevant Therapies
  </h6>
  <!--  -->
  <div class="row">
    <div class="col-12">
      <table class="page_1 cust_table w-100 mt-3 first_2 2nd_table">
        <thead>
          <tr>
            <th>Gene (Alterations) - Drug association</th>
            <th>Summary</th>
          </tr>
        </thead>
        <!-- SNV -->

        <tbody>
          <tr *ngFor="
                                                                                                          let drugsRes of reportPdfRes.drugsResList | slice : 12 : 16;
                                                                                                          let i = index
                                                                                                        ">
            <td>
              <ul class="ml-0 pl-2">
                <li>
                  <i>{{ drugsRes.gene.replace("Fusion","") }}</i><span *ngIf="drugsRes.gene.includes('Fusion')">Fusion</span> {{ drugsRes.pposition?.replace(' ','')}} :
                  <br />
                  <span *ngFor="let drug of drugsRes.drugs; let i = index">
                    {{ drug.name
                    }}<span *ngIf="drugsRes.drugs.length - 1 != i">,</span>
                  </span>
                  - {{ drugsRes.association }}
                  <p>{{ drugsRes.cancerType }}</p>
                </li>
              </ul>
            </td>
            <td>
              <div [innerHtml]="drugsRes.summary"></div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <!--  -->

  <!--  -->
  <div class="row footer">
    <div class="col-12">
      <div class="pb-2 pl-4 text">
        <div class="d-block">
          <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
        </div>
        <div class="footer_body" [ngClass]="'gene-bg_blue'">
          <div class="footer_list">
            <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
            <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
            <span>Gin - Q</span><span>Gly - G</span><span>His - H</span>
            <span>Ile - I</span>
          </div>
          <div class="footer_list">
            <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
            <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
            <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
            <span>Val - V</span>
          </div>
        </div>
      </div>
      <div class="col-12 pt-1 ">
        <p style="font-size: 8px!important; line-height: 14px!important;">
          {{footerDiscription}}
        </p>
      </div>
    </div>
  </div>
</div>

<!-- page 3_4 -->
<div id="page3_4" *ngIf="reportPdfRes.drugsResList.length > 16" class="container position-relative page"
  style="background-color: white">
  <div class="row header mt-1 py-4">
    <div>
      <div class="col-3">
        <img src="../../../assets/logo.png" alt="" style="width: 140px" />
      </div>
    </div>
    <div></div>
    <div class="col">
      <div class="patient-detailes ml-auto float-right">
        <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
          <li>
            Patient Id <br />
            <span>{{ report.patient?.ecrfId }}</span>
          </li>
          <li class="ml-4">
            Cancer Site<br />
            <span>{{ report.cancerType }}</span>
          </li>
          <li class="ml-4">
            SAMPLE ID<br />
            <span>{{ report.labDetails?.sampleId }}</span>
          </li>
          <li class="ml-4">
            Registration Date<br />
            <span>{{
              report.labDetails?.sampleCollected | date : "dd/MM/yyyy"
              }}</span>
          </li>
        </ul>
      </div>
    </div>
    <!--  -->
    <div *ngIf="report.clientType == '4basecare_client'">
      <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
        <div class="logo">
          <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
        </div>
      </div>
      <div *ngIf="report.clientName == 'path_labs'" class="col-3">
        <div class="logo">
          <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
        </div>
      </div>
    </div>
  </div>
  <!--  -->
  <div class="mar_top"></div>
  <!--  -->

  <h6 class="py-2 h6_bg" [ngClass]="'skyBlue'">
    Alterations With Clinically Relevant Therapies
  </h6>
  <!--  -->
  <div class="row">
    <div class="col-12">
      <table class="page_1 cust_table w-100 mt-3 first_2 2nd_table">
        <thead>
          <tr>
            <th>Gene (Alterations) - Drug association</th>
            <th>Summary</th>
          </tr>
        </thead>
        <!-- SNV -->

        <tbody>
          <tr *ngFor="
                                                                                                let drugsRes of reportPdfRes.drugsResList | slice : 16 : 20;
                                                                                                let i = index
                                                                                              ">
            <td>
              <ul class="ml-0 pl-2">
                <li>
                  <i>{{ drugsRes.gene.replace("Fusion","") }}</i><span *ngIf="drugsRes.gene.includes('Fusion')">Fusion</span> {{ drugsRes.pposition?.replace(' ','')}} :
                  <br />
                  <span *ngFor="let drug of drugsRes.drugs; let i = index">
                    {{ drug.name
                    }}<span *ngIf="drugsRes.drugs.length - 1 != i">,</span>
                  </span>
                  - {{ drugsRes.association }}
                  <p>{{ drugsRes.cancerType }}</p>
                </li>
              </ul>
            </td>
            <td>
              <div [innerHtml]="drugsRes.summary"></div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <!--  -->

  <!--  -->
  <div class="row footer">
    <div class="col-12">
      <div class="pb-2 pl-4 text">
        <div class="d-block">
          <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
        </div>
        <div class="footer_body" [ngClass]="'gene-bg_blue'">
          <div class="footer_list">
            <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
            <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
            <span>Gin - Q</span><span>Gly - G</span><span>His - H</span>
            <span>Ile - I</span>
          </div>
          <div class="footer_list">
            <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
            <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
            <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
            <span>Val - V</span>
          </div>
        </div>
      </div>
      <div class="col-12 pt-1 ">
        <p style="font-size: 8px!important; line-height: 14px!important;">
          {{footerDiscription}}
        </p>
      </div>
    </div>
  </div>
</div>

<!-- page 3_5 -->
<div id="page3_5" *ngIf="reportPdfRes.drugsResList.length > 20" class="container position-relative page"
  style="background-color: white">
  <div class="row header mt-1 py-4">
    <div>
      <div class="col-3">
        <img src="../../../assets/logo.png" alt="" style="width: 140px" />
      </div>
    </div>
    <div></div>
    <div class="col">
      <div class="patient-detailes ml-auto float-right">
        <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
          <li>
            Patient Id <br />
            <span>{{ report.patient?.ecrfId }}</span>
          </li>
          <li class="ml-4">
            Cancer Site<br />
            <span>{{ report.cancerType }}</span>
          </li>
          <li class="ml-4">
            SAMPLE ID<br />
            <span>{{ report.labDetails?.sampleId }}</span>
          </li>
          <li class="ml-4">
            Registration Date<br />
            <span>{{
              report.labDetails?.sampleCollected | date : "dd/MM/yyyy"
              }}</span>
          </li>
        </ul>
      </div>
    </div>
    <!--  -->
    <div *ngIf="report.clientType == '4basecare_client'">
      <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
        <div class="logo">
          <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
        </div>
      </div>
      <div *ngIf="report.clientName == 'path_labs'" class="col-3">
        <div class="logo">
          <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
        </div>
      </div>
    </div>
  </div>
  <!--  -->
  <div class="mar_top"></div>
  <!--  -->

  <h6 class="py-2 h6_bg" [ngClass]="'skyBlue'">
    Alterations With Clinically Relevant Therapies
  </h6>
  <!--  -->
  <div class="row">
    <div class="col-12">
      <table class="page_1 cust_table w-100 mt-3 first_2 2nd_table">
        <thead>
          <tr>
            <th>Gene (Alterations) - Drug association</th>
            <th>Summary</th>
          </tr>
        </thead>
        <!-- SNV -->

        <tbody>
          <tr
            *ngFor="
            let drugsRes of reportPdfRes.drugsResList | slice : 20 : 24; let i = index ">
            <td>
              <ul class="ml-0 pl-2">
                <li>
                  <i>{{ drugsRes.gene.replace("Fusion","") }}</i><span *ngIf="drugsRes.gene.includes('Fusion')">Fusion</span> {{ drugsRes.pposition?.replace(' ','')}} :
                  <br />
                  <span *ngFor="let drug of drugsRes.drugs; let i = index">
                    {{ drug.name
                    }}<span *ngIf="drugsRes.drugs.length - 1 != i">,</span>
                  </span>
                  - {{ drugsRes.association }}
                  <p>{{ drugsRes.cancerType }}</p>
                </li>
              </ul>
            </td>
            <td>
              <div [innerHtml]="drugsRes.summary"></div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <!--  -->

  <!--  -->
  <div class="row footer">
    <div class="col-12">
      <div class="pb-2 pl-4 text">
        <div class="d-block">
          <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
        </div>
        <div class="footer_body" [ngClass]="'gene-bg_blue'">
          <div class="footer_list">
            <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
            <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
            <span>Gin - Q</span><span>Gly - G</span><span>His - H</span>
            <span>Ile - I</span>
          </div>
          <div class="footer_list">
            <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
            <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
            <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
            <span>Val - V</span>
          </div>
        </div>
      </div>
      <div class="col-12 pt-1 ">
        <p style="font-size: 8px!important; line-height: 14px!important;">
          {{footerDiscription}}
        </p>
      </div>
    </div>
  </div>
</div>

<!-- page 3_6 -->
<div id="page3_6" *ngIf="reportPdfRes.drugsResList.length > 24" class="container position-relative page"
  style="background-color: white">
  <div class="row header mt-1 py-4">
    <div>
      <div class="col-3">
        <img src="../../../assets/logo.png" alt="" style="width: 140px" />
      </div>
    </div>
    <div></div>
    <div class="col">
      <div class="patient-detailes ml-auto float-right">
        <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
          <li>
            Patient Id <br />
            <span>{{ report.patient?.ecrfId }}</span>
          </li>
          <li class="ml-4">
            Cancer Site<br />
            <span>{{ report.cancerType }}</span>
          </li>
          <li class="ml-4">
            SAMPLE ID<br />
            <span>{{ report.labDetails?.sampleId }}</span>
          </li>
          <li class="ml-4">
            Registration Date<br />
            <span>{{
              report.labDetails?.sampleCollected | date : "dd/MM/yyyy"
              }}</span>
          </li>
        </ul>
      </div>
    </div>
    <!--  -->
    <div *ngIf="report.clientType == '4basecare_client'">
      <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
        <div class="logo">
          <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
        </div>
      </div>
      <div *ngIf="report.clientName == 'path_labs'" class="col-3">
        <div class="logo">
          <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
        </div>
      </div>
    </div>
  </div>
  <!--  -->
  <div class="mar_top"></div>
  <!--  -->

  <h6 class="py-2 h6_bg" [ngClass]="'skyBlue'">
    Alterations With Clinically Relevant Therapies
  </h6>
  <!--  -->
  <div class="row">
    <div class="col-12">
      <table class="page_1 cust_table w-100 mt-3 first_2 2nd_table">
        <thead>
          <tr>
            <th>Gene (Alterations) - Drug association</th>
            <th>Summary</th>
          </tr>
        </thead>
        <!-- SNV -->

        <tbody>
          <tr
            *ngFor=" let drugsRes of reportPdfRes.drugsResList | slice : 24 : 28; let i = index ">
            <td>
              <ul class="ml-0 pl-2">
                <li>
                  <i>{{ drugsRes.gene.replace("Fusion","") }}</i><span *ngIf="drugsRes.gene.includes('Fusion')">Fusion</span> {{ drugsRes.pposition?.replace(' ','')}} :
                  <br />
                  <span *ngFor="let drug of drugsRes.drugs; let i = index">
                    {{ drug.name
                    }}<span *ngIf="drugsRes.drugs.length - 1 != i">,</span>
                  </span>
                  - {{ drugsRes.association }}
                  <p>{{ drugsRes.cancerType }}</p>
                </li>
              </ul>
            </td>
            <td>
              <div [innerHtml]="drugsRes.summary"></div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <!--  -->

  <!--  -->
  <div class="row footer">
    <div class="col-12">
      <div class="pb-2 pl-4 text">
        <div class="d-block">
          <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
        </div>
        <div class="footer_body" [ngClass]="'gene-bg_blue'">
          <div class="footer_list">
            <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
            <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
            <span>Gin - Q</span><span>Gly - G</span><span>His - H</span>
            <span>Ile - I</span>
          </div>
          <div class="footer_list">
            <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
            <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
            <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
            <span>Val - V</span>
          </div>
        </div>
      </div>
      <div class="col-12 pt-1 ">
        <p style="font-size: 8px!important; line-height: 14px!important;">
          {{footerDiscription}}
        </p>
      </div>
    </div>
  </div>
</div>

<!-- page 3_7 -->
<div id="page3_7" *ngIf="reportPdfRes.drugsResList.length > 28" class="container position-relative page"
  style="background-color: white">
  <div class="row header mt-1 py-4">
    <div>
      <div class="col-3">
        <img src="../../../assets/logo.png" alt="" style="width: 140px" />
      </div>
    </div>
    <div></div>
    <div class="col">
      <div class="patient-detailes ml-auto float-right">
        <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
          <li>
            Patient Id <br />
            <span>{{ report.patient?.ecrfId }}</span>
          </li>
          <li class="ml-4">
            Cancer Site<br />
            <span>{{ report.cancerType }}</span>
          </li>
          <li class="ml-4">
            SAMPLE ID<br />
            <span>{{ report.labDetails?.sampleId }}</span>
          </li>
          <li class="ml-4">
            Registration Date<br />
            <span>{{
              report.labDetails?.sampleCollected | date : "dd/MM/yyyy"
              }}</span>
          </li>
        </ul>
      </div>
    </div>
    <!--  -->
    <div *ngIf="report.clientType == '4basecare_client'">
      <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
        <div class="logo">
          <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
        </div>
      </div>
      <div *ngIf="report.clientName == 'path_labs'" class="col-3">
        <div class="logo">
          <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
        </div>
      </div>
    </div>
  </div>
  <!--  -->
  <div class="mar_top"></div>
  <!--  -->

  <h6 class="py-2 h6_bg" [ngClass]="'skyBlue'">
    Alterations With Clinically Relevant Therapies
  </h6>
  <!--  -->
  <div class="row">
    <div class="col-12">
      <table class="page_1 cust_table w-100 mt-3 first_2 2nd_table">
        <thead>
          <tr>
            <th>Gene (Alterations) - Drug association</th>
            <th>Summary</th>
          </tr>
        </thead>
        <!-- SNV -->

        <tbody>
          <tr
            *ngFor="
                                                                                                                                                                let drugsRes of reportPdfRes.drugsResList | slice : 28 : 32;
                                                                                                                                                                let i = index
                                                                                                                                                              ">
            <td>
              <ul class="ml-0 pl-2">
                <li>
                  <i>{{ drugsRes.gene.replace("Fusion","") }}</i><span *ngIf="drugsRes.gene.includes('Fusion')">Fusion</span> {{ drugsRes.pposition?.replace(' ','')}} :
                  <br />
                  <span *ngFor="let drug of drugsRes.drugs; let i = index">
                    {{ drug.name
                    }}<span *ngIf="drugsRes.drugs.length - 1 != i">,</span>
                  </span>
                  - {{ drugsRes.association }}
                  <p>{{ drugsRes.cancerType }}</p>
                </li>
              </ul>
            </td>
            <td>
              <div [innerHtml]="drugsRes.summary"></div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <!--  -->

  <!--  -->
  <div class="row footer">
    <div class="col-12">
      <div class="pb-2 pl-4 text">
        <div class="d-block">
          <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
        </div>
        <div class="footer_body" [ngClass]="'gene-bg_blue'">
          <div class="footer_list">
            <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
            <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
            <span>Gin - Q</span><span>Gly - G</span><span>His - H</span>
            <span>Ile - I</span>
          </div>
          <div class="footer_list">
            <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
            <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
            <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
            <span>Val - V</span>
          </div>
        </div>
      </div>
      <div class="col-12 pt-1 ">
        <p style="font-size: 8px!important; line-height: 14px!important;">
          {{footerDiscription}}
        </p>
      </div>
    </div>
  </div>
</div>

<!-- page 3_8 -->
<div id="page3_8" *ngIf="reportPdfRes.drugsResList.length > 32" class="container position-relative page"
  style="background-color: white">
  <div class="row header mt-1 py-4">
    <div>
      <div class="col-3">
        <img src="../../../assets/logo.png" alt="" style="width: 140px" />
      </div>
    </div>
    <div></div>
    <div class="col">
      <div class="patient-detailes ml-auto float-right">
        <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
          <li>
            Patient Id <br />
            <span>{{ report.patient?.ecrfId }}</span>
          </li>
          <li class="ml-4">
            Cancer Site<br />
            <span>{{ report.cancerType }}</span>
          </li>
          <li class="ml-4">
            SAMPLE ID<br />
            <span>{{ report.labDetails?.sampleId }}</span>
          </li>
          <li class="ml-4">
            Registration Date<br />
            <span>{{
              report.labDetails?.sampleCollected | date : "dd/MM/yyyy"
              }}</span>
          </li>
        </ul>
      </div>
    </div>
    <!--  -->
    <div *ngIf="report.clientType == '4basecare_client'">
      <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
        <div class="logo">
          <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
        </div>
      </div>
      <div *ngIf="report.clientName == 'path_labs'" class="col-3">
        <div class="logo">
          <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
        </div>
      </div>
    </div>
  </div>
  <!--  -->
  <div class="mar_top"></div>
  <!--  -->

  <h6 class="py-2 h6_bg" [ngClass]="'skyBlue'">
    Alterations With Clinically Relevant Therapies
  </h6>
  <!--  -->
  <div class="row">
    <div class="col-12">
      <table class="page_1 cust_table w-100 mt-3 first_2 2nd_table">
        <thead>
          <tr>
            <th>Gene (Alterations) - Drug association</th>
            <th>Summary</th>
          </tr>
        </thead>
        <!-- SNV -->

        <tbody>
          <tr *ngFor=" let drugsRes of reportPdfRes.drugsResList | slice : 32 : 36; let i = index ">
            <td>
              <ul class="ml-0 pl-2">
                <li>
                  <i>{{ drugsRes.gene.replace("Fusion","") }}</i><span *ngIf="drugsRes.gene.includes('Fusion')">Fusion</span> {{ drugsRes.pposition?.replace(' ','')}} :
                  <br />
                  <span *ngFor="let drug of drugsRes.drugs; let i = index">
                    {{ drug.name
                    }}<span *ngIf="drugsRes.drugs.length - 1 != i">,</span>
                  </span>
                  - {{ drugsRes.association }}
                  <p>{{ drugsRes.cancerType }}</p>
                </li>
              </ul>
            </td>
            <td>
              <div [innerHtml]="drugsRes.summary"></div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <!--  -->

  <!--  -->
  <div class="row footer">
    <div class="col-12">
      <div class="pb-2 pl-4 text">
        <div class="d-block">
          <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
        </div>
        <div class="footer_body" [ngClass]="'gene-bg_blue'">
          <div class="footer_list">
            <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
            <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
            <span>Gin - Q</span><span>Gly - G</span><span>His - H</span>
            <span>Ile - I</span>
          </div>
          <div class="footer_list">
            <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
            <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
            <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
            <span>Val - V</span>
          </div>
        </div>
      </div>
      <div class="col-12 pt-1 ">
        <p style="font-size: 8px!important; line-height: 14px!important;">
          {{footerDiscription}}
        </p>
      </div>
    </div>
  </div>
</div>

          <!-- page 3_9 -->
          <div id="page3_9" *ngIf="reportPdfRes.drugsResList.length > 36" class="container position-relative page"
            style="background-color: white">
            <div class="row header mt-1 py-4">
              <div>
                <div class="col-3">
                  <img src="../../../assets/logo.png" alt="" style="width: 140px" />
                </div>
              </div>
              <div></div>
              <div class="col">
                <div class="patient-detailes ml-auto float-right">
                  <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
                    <li>
                      Patient Id <br />
                      <span>{{ report.patient?.ecrfId }}</span>
                    </li>
                    <li class="ml-4">
                      Cancer Site<br />
                      <span>{{ report.cancerType }}</span>
                    </li>
                    <li class="ml-4">
                      SAMPLE ID<br />
                      <span>{{ report.labDetails?.sampleId }}</span>
                    </li>
                    <li class="ml-4">
                      Registration Date<br />
                      <span>{{
                        report.labDetails?.sampleCollected | date : "dd/MM/yyyy"
                        }}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <!--  -->
              <div *ngIf="report.clientType == '4basecare_client'">
                <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
                  </div>
                </div>
                <div *ngIf="report.clientName == 'path_labs'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
                  </div>
                </div>
              </div>
            </div>
            <!--  -->
            <div class="mar_top"></div>
            <!--  -->

            <h6 class="py-2 h6_bg" [ngClass]="'skyBlue'">
              Alterations With Clinically Relevant Therapies
            </h6>
            <!--  -->
            <div class="row">
              <div class="col-12">
                <table class="page_1 cust_table w-100 mt-3 first_2 2nd_table">
                  <thead>
                    <tr>
                      <th>Gene (Alterations) - Drug association</th>
                      <th>Summary</th>
                    </tr>
                  </thead>
                  <!-- SNV -->

                  <tbody>
                    <tr *ngFor=" let drugsRes of reportPdfRes.drugsResList | slice : 36 : 40; let i = index ">
                      <td>
                        <ul class="ml-0 pl-2">
                          <li>
                            <i>{{ drugsRes.gene.replace("Fusion","") }}</i><span *ngIf="drugsRes.gene.includes('Fusion')">Fusion</span> {{ drugsRes.pposition?.replace(' ','')}} :
                            <br />
                            <span *ngFor="let drug of drugsRes.drugs; let i = index">
                              {{ drug.name
                              }}<span *ngIf="drugsRes.drugs.length - 1 != i">,</span>
                            </span>
                            - {{ drugsRes.association }}
                            <p>{{ drugsRes.cancerType }}</p>
                          </li>
                        </ul>
                      </td>
                      <td>
                        <div [innerHtml]="drugsRes.summary"></div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <!--  -->

            <!--  -->
            <div class="row footer">
              <div class="col-12">
                <div class="pb-2 pl-4 text">
                  <div class="d-block">
                    <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
                  </div>
                  <div class="footer_body" [ngClass]="'gene-bg_blue'">
                    <div class="footer_list">
                      <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
                      <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
                      <span>Gin - Q</span><span>Gly - G</span><span>His - H</span>
                      <span>Ile - I</span>
                    </div>
                    <div class="footer_list">
                      <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
                      <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
                      <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
                      <span>Val - V</span>
                    </div>
                  </div>
                </div>
                <div class="col-12 pt-1 ">
                  <p style="font-size: 8px!important; line-height: 14px!important;">
                    {{footerDiscription}}
                  </p>
                </div>
              </div>
            </div>
          </div>

      <!--  -->


      <!-- test -->

      <div
        id="page4_1"  *ngIf="prognasticCount>0"
        class="container position-relative page"
        style="background-color: white"
      >
        <div class="row header mt-1 py-4">
          <div>
            <div class="col-3">
              <img src="../../../assets/logo.png" alt="" style="width: 140px" />
            </div>
          </div>
          <div></div>
          <div class="col">
            <div class="patient-detailes ml-auto float-right">
              <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
                <li>
                  Patient Id <br />
                  <span>{{ report.patient?.ecrfId }}</span>
                </li>
                <li class="ml-4">
                  Cancer Site<br />
                  <span>{{ report.cancerType }}</span>
                </li>
                <li class="ml-4">
                  SAMPLE ID<br />
                  <span>{{ report.labDetails?.sampleId }}</span>
                </li>
                <li class="ml-4">
                  Registration Date<br />
                  <span>{{
                    report.labDetails?.sampleCollected | date : "dd/MM/yyyy"
                  }}</span>
                </li>
              </ul>
            </div>
          </div>
          <!--  -->
          <div *ngIf="report.clientType == '4basecare_client'">
            <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
              <div class="logo">
                <img
                  src="../../../assets/oncoquest logo.png"
                  alt=""
                  style="width: 100px"
                />
              </div>
            </div>
            <div *ngIf="report.clientName == 'path_labs'" class="col-3">
              <div class="logo">
                <img
                  src="../../../assets/pathlab.png"
                  alt=""
                  style="width: 100px"
                />
              </div>
            </div>
          </div>
        </div>
        <!--  -->
        <div class="mar_top"></div>
        <!--  -->
        <div *ngIf="prognasticCount>0">
          <div >
            <h6 class="py-2 h6_bg"> Alterations associated with prognostic significance</h6>

          </div>

            <div
              class="row"
            >
              <div class="col-12">
                <table
                  class="page_1 cust_table w-100 mt-3 varient_details prognastictable"
                  [ngClass]="'table_variant-blue'"
                >
                  <thead>
                    <tr>
                      <th>Gene (Alterations)</th>
                      <th>Prognostic Significance</th>
                      <th>Summary</th>

                    </tr>
                  </thead>

                  <ng-container *ngFor=" let prog of reportPdfRes.prognosticAlterations ">
                    <tr>
                      <td style="padding-left: 3px !important">
                        <i>{{ prog.gene }}&nbsp;</i> <span [innerHtml]="prog.pOrcDot"></span>
                      </td>
                      <td style="padding-left: 3px !important">
                        {{prog.prognosticSignificance}}
                      </td>
                      <td style="padding-left: 3px !important">
                        <span [innerHtml]="prog.description"></span>
                      </td>

                    </tr>
                  </ng-container>


                </table>
              </div>
            </div>
        </div>


        <!--  -->
        <div class="row footer">
          <div class="col-12">
            <div class="pb-2 pl-4 text">
              <div class="d-block">
                <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
              </div>
              <div class="footer_body" [ngClass]="'gene-bg_blue'">
                <div class="footer_list">
                  <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
                  <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
                  <span>Gin - Q</span><span>Gly - G</span><span>His - H</span>
                  <span>Ile - I</span>
                </div>
                <div class="footer_list">
                  <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
                  <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
                  <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
                  <span>Val - V</span>
                </div>
              </div>
            </div>
            <div class="col-12 pt-1 ">
              <p style="font-size: 8px!important; line-height: 14px!important;">
                {{footerDiscription}}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div
        id="page4"
        class="container position-relative page"
        style="background-color: white"
      >
        <div class="row header mt-1 py-4">
          <div>
            <div class="col-3">
              <img src="../../../assets/logo.png" alt="" style="width: 140px" />
            </div>
          </div>
          <div></div>
          <div class="col">
            <div class="patient-detailes ml-auto float-right">
              <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
                <li>
                  Patient Id <br />
                  <span>{{ report.patient?.ecrfId }}</span>
                </li>
                <li class="ml-4">
                  Cancer Site<br />
                  <span>{{ report.cancerType }}</span>
                </li>
                <li class="ml-4">
                  SAMPLE ID<br />
                  <span>{{ report.labDetails?.sampleId }}</span>
                </li>
                <li class="ml-4">
                  Registration Date<br />
                  <span>{{
                    report.labDetails?.sampleCollected | date : "dd/MM/yyyy"
                  }}</span>
                </li>
              </ul>
            </div>
          </div>
          <!--  -->
          <div *ngIf="report.clientType == '4basecare_client'">
            <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
              <div class="logo">
                <img
                  src="../../../assets/oncoquest logo.png"
                  alt=""
                  style="width: 100px"
                />
              </div>
            </div>
            <div *ngIf="report.clientName == 'path_labs'" class="col-3">
              <div class="logo">
                <img
                  src="../../../assets/pathlab.png"
                  alt=""
                  style="width: 100px"
                />
              </div>
            </div>
          </div>
        </div>
        <!--  -->
        <div class="mar_top"></div>
        <!--  -->

        <h6 class="py-2 h6_bg" [ngClass]="'skyBlue'">References</h6>
        <!--  -->
        <div class="row">
          <div class="col">
            <div class="disc">
              <div class="ref_data mt-4">
                <ul class="break-word" style="text-align: justify">
                  <li
                    *ngFor="let referenceArticle of reportPdfRes.referenceArticles"
                    style="
                      text-align: justify;
                      /* white-space:pre-wrap; */
                      word-break: break-word;
                    "
                  >
                    {{ referenceArticle }}
                  </li>
                </ul>
              </div>
            </div>

            <!-- <div class="img_signs">
            <img src="../../../assets/signatures.png" alt="" class="img-fluid">
          </div> -->
            <!--  -->

            <!--  -->
            <!-- <div class="mt-4">
            <h6 class="mt-3 mb-4 py-2 h6_bg" >
              GENES EVALUATED </h6>
             <p><span class="font-weight-normal">taR</span><b>GT</b> <span class="clr-cyan pl-2">First</span> detects mutations (SNVs and Short Indels), Copy Number Variations (CNVs), gene fusions and splice variants in the 53 genes :</p>
          </div> -->

            <!-- <div class="row mx-0">
            <div class="col-7 px-0">
              <div class="genes_info">
                <div class="title py-2">
                  <h6 class="mb-0 d-inline-block border-bottom  py-2"><b>SNVs/InDels</b> Coverage in <span class="font-weight-normal">TAR</span><b>GT</b> <span class="clr-cyan pl-2">First</span></h6></div>
             <div class="gene_body">
              <div class="gene_list"><span>ABL1</span><span>ALK</span><span>AR</span><span>ATM</span><span>BARD1</span><span>BRAF</span><span>BRCA1</span><span>BRCA2</span><span>BRIP1</span><span>CDK12</span></div>
              <div class="gene_list"><span>CDK4</span><span>CDK6</span><span>CDKN2A</span><span>CHEK1</span><span>CHEK2</span><span>EGFR</span><span>EPCAM</span><span>ERBB2</span><span>ERBB3</span><span>EZH2</span></div>
              <div class="gene_list"><span>FANCL</span><span>FGFR3</span><span>GAPDH</span><span>IDH1</span><span>IDH2</span><span>JAK2</span><span>KIT</span><span>KRAS</span><span>MAP2K1</span><span>MAP2K2</span></div>
              <div class="gene_list"><span>MDM2</span><span>MET</span><span>MLH1</span><span>MLH3</span><span>MSH2</span><span>MSH6</span><span>NRAS</span><span>PALB2</span><span>PDGFRA</span><span>PDGFRB</span></div>
              <div class="gene_list"><span>PIK3CA</span><span>PMS1</span><span>PMS2</span><span>PPP2R2A</span><span>RAD51B</span><span>RAD51C</span><span>RAD51D</span><span>RAD54L</span><span>RET</span><span>ROS1</span></div>
              <div class="gene_list"><span>MDM2</span><span>MET</span><span>MLH1</span></div>
             </div>
                </div>
            </div>
            <div class="col">
              <div class="title py-2">
                <h6 class=" mb-0 d-inline-block border-bottom  py-2"><b>Gene Fusions </b> Coverage in <span class="font-weight-normal">TAR</span><b>GT</b> <span class="clr-cyan pl-2">First</span></h6></div>
            </div>


          </div> -->
          </div>
        </div>
        <div class="img_signs above-footer">
          <!-- <div class="col" *ngIf="isReportDownload">
            <img [src]="imgsings" alt="" class="img-fluid w-90" />
          </div> -->
          <div class="row" id="imgsings" >
            <div class="col">

              <p style="
                    text-align: center;

                    font-size: 15px !important;
                  ">(Electronically signed by)
              </p>
              <p style="
                      text-align: center;
                      font-weight: bolder;
                      font-size: 15px !important;
                    ">
                Vidya H Veldore, PhD<br />Vice President - Clinical Operations
              </p>
            </div>
            <div class="col">
              <p style="text-align: center; font-size: 15px !important;">
                (Electronically signed by)</p>
                <p style="
                text-align: center;
                font-weight: bolder;
                font-size: 15px !important;
              ">
              Dr. Jinumary Aji John<br />Head - Clinical Reporting </p>
            </div>
            <div class="col">
              <p style="
                text-align: center;

                font-size: 15px !important;
              ">(Electronically signed by)
              </p>
              <p style="
                      text-align: center;
                      font-weight: bolder;
                      font-size: 15px !important;
                    ">
                Vyomesh Javle<br />Head - Clinical Bioinformatics
              </p>
            </div>
          </div>
        </div>
                <!--  -->
                <div class="row footer">
                  <div class="col-12">
                    <div class="pb-2 pl-4 text">
                      <div class="d-block">
                        <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
                      </div>
                      <div class="footer_body" [ngClass]="'gene-bg_blue'">
                        <div class="footer_list">
                          <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
                          <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
                          <span>Gin - Q</span><span>Gly - G</span><span>His - H</span>
                          <span>Ile - I</span>
                        </div>
                        <div class="footer_list">
                          <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
                          <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
                          <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
                          <span>Val - V</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 pt-1 ">
                      <p style="font-size: 8px!important; line-height: 14px!important;">
                        {{footerDiscription}}
                      </p>
                    </div>
                  </div>
                </div>
      </div>
      <div
      id="page4_1"
      class="container position-relative page"
      style="background-color: white"
    >
      <div class="row header mt-1 py-4">
        <div>
          <div class="col-3">
            <img src="../../../assets/logo.png" alt="" style="width: 140px" />
          </div>
        </div>
        <div></div>
        <div class="col">
          <div class="patient-detailes ml-auto float-right">
            <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
              <li>
                Patient Id <br />
                <span>{{ report.patient?.ecrfId }}</span>
              </li>
              <li class="ml-4">
                Cancer Site<br />
                <span>{{ report.cancerType }}</span>
              </li>
              <li class="ml-4">
                SAMPLE ID<br />
                <span>{{ report.labDetails?.sampleId }}</span>
              </li>
              <li class="ml-4">
                Registration Date<br />
                <span>{{
                  report.labDetails?.sampleCollected | date : "dd/MM/yyyy"
                }}</span>
              </li>
            </ul>
          </div>
        </div>
        <!--  -->
        <div *ngIf="report.clientType == '4basecare_client'">
          <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
            <div class="logo">
              <img
                src="../../../assets/oncoquest logo.png"
                alt=""
                style="width: 100px"
              />
            </div>
          </div>
          <div *ngIf="report.clientName == 'path_labs'" class="col-3">
            <div class="logo">
              <img
                src="../../../assets/pathlab.png"
                alt=""
                style="width: 100px"
              />
            </div>
          </div>
        </div>
      </div>
      <!--  -->
      <div class="mar_top"></div>
      <!--  -->

      <div class="">
        <h6 class="py-2 h6_bg" [ngClass]="'skyBlue'">TEST DESCRIPTION</h6>
        <p class="mb-2" style="text-align:justify">
          <span class="font-weight-normal">TAR</span><b>GT</b>
          <span class="clr-cyan pl-2" [ngClass]="'txt-skyblue'">First Solid</span>
          is a Next Generation Sequencing based test which identifies
          genetic alterations in a comprehensive panel of well curated 72  genes which are having an impact response to approved
           therapy for a particular cancer type. Some of the alterations
           detected may have bearing on prognosis and/or therapeutic options
            and may provide relevant information that allows
            oncologists/clinicians to consider various lines of
            targeted treatment for the patient.
        </p>
      </div>

      <!--  -->
      <div class="row">
        <div class="col">
          <div class="">
            <h6 class="py-2 h6_bg" [ngClass]="'skyBlue'">GENES EVALUATED</h6>
            <p>
              <span class="font-weight-normal">TAR</span><b>GT</b>
              <span class="clr-cyan pl-2" [ngClass]="'txt-skyblue'">First Solid</span>
              detects mutations (SNVs and Short InDels), Copy Number Variations (CNVs),
              Gene Fusions and splice variants in the 72 genes:
              <!-- detects mutations (SNVs and Short Indels), Copy Number Variations
              (CNVs), gene fusions and splice variants in the 53 genes : -->
            </p>
          </div>
          <!--  -->
          <div class="row mx-0">
            <div class="col-12 px-0">
              <div class="genes_info">
                <div class="title py-2">
                   <h6 class="mb-0 d-inline-block border-bottom py-2">
                    <b>SNVs, SHORT INDELS and CNVs
                    </b> Covered in
                    <span class="font-weight-normal">TAR</span><b>GT</b>
                    <span class="clr-cyan pl-2" [ngClass]="'txt-skyblue'"
                      >First Solid</span
                    >
                  </h6>
                </div>
                <div class="gene_body" [ngClass]="'gene-bg_blue'">
                  <i>
                    <div class="gene_list">
                      <span>ABL1</span><span>ALK</span><span>APC</span>
                      <span>AR</span><span>ATM</span><span>BARD1</span>
                      <span>BMPR1A</span><span>BRAF</span><span>BRCA1</span>
                      <span>BRCA2</span>
                      <!-- <span>ABL1</span><span>ALK</span><span>AR</span
                      ><span>ATM</span><span>BARD1</span><span>BRAF</span
                      ><span>BRCA1</span><span>BRCA2</span><span>BRIP1</span
                      ><span>CDK12</span> -->
                    </div>
                    <div class="gene_list bg-white">
                      <span>BRIP1</span><span>CDK12</span><span>CDK4</span>
                      <span>CDK6</span><span>CDKN2A</span><span>CHEK1</span>
                      <span>CHEK2</span><span>CTNNB1</span><span>EGFR</span>
                      <span>EPCAM</span>
                      <!-- <span>CDK4</span><span>CDK6</span><span>CDKN2A</span
                      ><span>CHEK1</span><span>CHEK2</span><span>EGFR</span
                      ><span>EPCAM</span><span>ERBB2</span><span>ERBB3</span
                      ><span>EZH2</span> -->
                    </div>
                    <div class="gene_list">
                      <span>ERBB2</span><span>ERBB3</span><span>EZH2</span>
                      <span>FANCL</span><span>FGFR1</span><span>FGFR2</span>
                      <span>FGFR3</span><span>GAPDH</span><span>IDH1</span>
                      <span>IDH2</span>
                      <!-- <span>FANCL</span><span>FGFR3</span><span>GAPDH</span
                      ><span>IDH1</span><span>IDH2</span><span>JAK2</span
                      ><span>KIT</span><span>KRAS</span><span>MAP2K1</span
                      ><span>MAP2K2</span> -->
                    </div>
                    <div class="gene_list">
                      <span>JAK2</span><span>KIT</span><span>KRAS</span>
                      <span>MAP2K1</span><span>MAP2K2</span><span>MDM2</span>
                      <span>MET</span><span>MLH1</span><span>MLH3</span>
                      <span>MSH2</span>
                      <!-- <span>MDM2</span><span>MET</span><span>MLH1</span
                      ><span>MLH3</span><span>MSH2</span><span>MSH6</span
                      ><span>NRAS</span><span>PALB2</span><span>PDGFRA</span
                      ><span>PDGFRB</span> -->
                    </div>
                    <div class="gene_list">
                      <span>MSH6</span><span>MUTYH</span><span>NRAS</span>
                      <span>PALB2</span><span>PDGFRA</span><span>PDGFRB</span>
                      <span>PIK3CA</span><span>PMS1</span><span>PMS2</span>
                      <span>POLD1</span>
                      <!-- <span>PIK3CA</span><span>PMS1</span><span>PMS2</span
                      ><span>PPP2R2A</span><span>RAD51B</span><span>RAD51C</span
                      ><span>RAD51D</span><span>RAD54L</span><span>RET</span
                      ><span>ROS1</span> -->
                    </div>
                    <div class="gene_list">
                      <span>POLE</span><span>POLH</span><span>PTEN</span>
                      <span>RAD50</span><span>RAD51</span><span>RAD51B</span>
                      <span>RAD51C</span><span>RAD51D</span><span>RAD54L</span>
                      <span>RB1</span>
                      <!-- <span>STK11</span><span>TSC1</span><span>TSC2</span> -->
                    </div>
                    <div class="gene_list">
                      <span>RET</span><span>ROS1</span><span>SMAD4</span>
                      <span>STK11</span><span>TP53</span><span>TSC1</span>
                      <span>TSC2</span>
                      <!-- <span>STK11</span><span>TSC1</span><span>TSC2</span> -->
                    </div>
                  </i>
                </div>

                <!-- <div class="title py-2 mt-4">
                  <h6 class="mb-0 d-inline-block border-bottom py-2">
                    <b>CNAs</b> Covered in
                    <span class="font-weight-normal">TAR</span><b>GT</b>
                    <span class="clr-cyan pl-2" [ngClass]="'txt-skyblue'"
                      >First</span
                    >
                  </h6>
                </div> -->
                <!-- <div class="gene_body" [ngClass]="'gene-bg_blue'">
                  <i>
                    <div class="gene_list">
                      <span>ABL1</span><span>AR</span><span>ATM</span
                      ><span>BARD1</span><span>BRCA1</span><span>BRCA2</span
                      ><span>BRIP1</span><span>CDK12</span><span>CDK4</span
                      ><span>CDK6</span>
                    </div>
                    <div class="gene_list">
                      <span>CDKN2A</span><span>CHEK1</span><span>CHEK2</span
                      ><span>EGFR</span><span>EPCAM</span><span>ERBB2</span
                      ><span>ERBB3</span><span>EZH2</span><span>FANCL</span
                      ><span>FGFR3</span>
                    </div>
                    <div class="gene_list">
                      <span>GAPDH</span><span>IDH2</span><span>JAK2</span
                      ><span>KIT</span><span>MDM2</span><span>NRAS</span
                      ><span>PALB2</span><span>PDGFRA</span><span>PDGFRB</span
                      ><span>PPP2R2A</span>
                    </div>
                    <div class="gene_list">
                      <span>RAD51B</span><span>RAD51C</span><span>RAD51D</span
                      ><span>RAD54L</span><span>STK11</span><span>TSC1</span
                      ><span>TSC2</span>
                    </div>
                  </i>
                </div> -->
              </div>
            </div>

            <!-- cnv_genes -->

            <div class="col-12 px-0" *ngIf="isKokilaben">
              <div class="genes_info">
                <div class="title py-2">
                  <h6 class="mb-0 d-inline-block border-bottom py-2">
                    <b> CNVs
                    </b> Covered in
                    <span class="font-weight-normal">TAR</span><b>GT</b>
                    <span class="clr-cyan pl-2" [ngClass]="'txt-skyblue'"
                      >First Solid</span
                    >
                  </h6>
                </div>
                <div class="gene_body" [ngClass]="'gene-bg_blue'">
                  <i>
                    <div class="gene_list">
                      <span>ABL1</span><span>ALK</span><span>APC</span>
                      <span>AR</span><span>ATM</span><span>BARD1</span>
                      <span>BMPR1A</span><span>BRAF</span><span>BRCA1</span>
                      <span>BRCA2</span>
                    </div>
                    <div class="gene_list bg-white">
                      <span>BRIP1</span><span>CDK12</span><span>CDK4</span>
                      <span>CDK6</span><span>CDKN2A</span><span>CHEK1</span>
                      <span>CHEK2</span><span>CTNNB1</span><span>EGFR</span>
                      <span>EPCAM</span>
                    </div>
                    <div class="gene_list">
                      <span>ERBB2</span><span>ERBB3</span><span>EZH2</span>
                      <span>FANCL</span><span>FGFR1</span><span>FGFR2</span>
                      <span>FGFR3</span><span>GAPDH</span><span>IDH2</span>
                      <span>JAK2</span>
                    </div>
                    <div class="gene_list">
                     <span>KIT</span><span>MDM2</span>
                      <span>MET</span><span>MLH1</span><span>MUTYH</span>
                      <span>NRAS</span><span>PALB2</span><span>PDGFRA</span>
                      <span>PDGFRAB</span><span>POLD1</span>
                    </div>

                    <div class="gene_list">
                      <span>POLE</span><span>POLH</span><span>PTEN</span>
                      <span>RAD50</span><span>RAD51</span><span>RAD51B</span>
                      <span>RAD51C</span><span>RAD51D</span><span>RAD54L</span>
                      <span>RB1</span>
                    </div>
                    <div class="gene_list">
                      <span>RET</span><span>ROS1</span><span>SMAD4</span>
                      <span>STK11</span><span>TP53</span><span>TSC1</span>
                      <span>TSC2</span>
                    </div>
                  </i>
                </div>


              </div>
            </div>





            <div class="col-12 px-0">
              <div class="genes_info">
                <div class="title py-2">
                  <h6 class="mb-0 d-inline-block border-bottom py-2">
                    <b>Gene Fusions/Splicing Variations</b> Covered in
                  <span class="font-weight-normal">TAR</span><b>GT</b>
                  <span class="clr-cyan pl-2" [ngClass]="'txt-skyblue'"
                    >First Solid</span
                  >
                  </h6>
                </div>
                <div class="gene_body" [ngClass]="'gene-bg_blue'">
                  <i>
                    <div class="gene_list">
                    <span>ALK</span><span>FGFR2</span><span>FGFR3</span>
                    <span>MET</span><span>NRG1</span><span>NRG2</span>
                    <span>NTRK1</span><span>NTRK2</span> <span>NTRK3</span><span>RET</span>

                    </div>
                    <div class="gene_list">
                      <span>ROS1</span>
                    </div>
                  </i>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
      <h6 class="py-2 h6_bg mt-3" [ngClass]="'skyBlue'">TEST METHODOLOGY</h6>
      <!--  -->
      <div class="row mt-3">
        <div class="col">
          <h6 class="font-weight-bold">
            Sample preparation and Library preparation :
          </h6>
          <!-- <p>
            DNA isolated from FFPE, or any other fresh tumor tissue source was
            used to perform targeted gene capture using a custom capture kit.
            The libraries were sequenced to mean >350X coverage on Illumina
            sequencing platform.
          </p> -->
          <p style="text-align: justify;">
            DNA isolated from FFPE, or any other fresh tumor tissue source was used to perform targeted gene capture using a custom capture kit. The libraries were sequenced to mean
            >350X coverage on Illumina sequencing platform.
          </p>
        </div>
      </div>
      <!--  -->
      <div class="row mt-4">
        <div class="col">
          <h6 class="font-weight-bold">
            Bioinformatics Analysis and Reporting :
          </h6>
          <!-- <p>
            The sequences obtained are aligned to human reference genome
            (GRCh37/hg19) and variant analysis was performed using set of
            Bioinformatics Pipeline. Only non-synonymous and splice site
            variants found in the panel consisting of specific set of genes were
            used for clinical interpretation. Silent variations that do not
            result in any change in amino acid in the coding region are not
            reported. Clinically relevant mutations were annotated using
            published variants in literature and a set of databases – ClinVar,
            COSMIC and dbSNP. Common variants are filtered based on allele
            frequency in 1000 Genome Phase 3, ExAC, dbSNP, gnomAD, etc. In the
            absence of a clinically significant reported known variation(s),
            pathogenicity will be predicted based on <i>in-silico</i> gene
            prioritization tools: CADD, SIFT, PolyPhen-2, Condel and Mutation
            taster and prioritized for clinical correlation. The identified
            pathogenic variant will be correlated with observed phenotypic
            features of the patient and interpreted according to American
            College of Medical Genetics (ACMG) guidelines.
          </p>
          <p class="mb-2 d-block">
            Somatic variants are classified into three tiers based on their level
            of clinical significance in cancer diagnosis, prognosis, and/or
            therapeutics as per international guidelines: ACMG, ASCO, AMP, CAP,
            NCCN and ESMO
          </p> -->
          <p style="text-align: justify;">
            The sequences obtained are aligned to human reference genome (GRCh37/hg19) and variant analysis was performed using set of Bioinformatics Pipeline. Only non-synonymous and splice site variants found in the panel consisting of specific set of genes were used for clinical interpretation. Silent variations that do not result in any change
            in amino acid in the coding region are not reported. Clinically relevant mutations were annotated using published variants in literature and a set of databases – ClinVar, COSMIC
            and dbSNP. Common variants are filtered based on allele frequency in 1000 Genome Phase 3, ExAC, dbSNP, gnomAD, etc. In the absence of a clinically significant reported
            known variation(s), pathogenicity will be predicted based on <i>in-silico</i> gene prioritization tools: CADD, SIFT, PolyPhen-2, Condel and Mutation taster and prioritized for clinical
            correlation. The identified pathogenic variant will be correlated with observed phenotypic features of the patient and interpreted according to ACMG/AMP guidelines.
          </p>
          <p class="mb-2 d-block" style="text-align: justify;">
            Somatic variants are classified into three tiers based on their level of clinical significance in cancer diagnosis, prognosis, and/or therapeutics as per international guidelines:
            ACMG, ASCO, AMP, CAP, NCCN and ESMO.
          </p>
        </div>
      </div>
      <!--  -->
      <div class="row footer">
        <div class="col-12">
          <div class="pb-2 pl-4 text">
            <div class="d-block">
              <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
            </div>
            <div class="footer_body" [ngClass]="'gene-bg_blue'">
              <div class="footer_list">
                <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
                <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
                <span>Gin - Q</span><span>Gly - G</span><span>His - H</span>
                <span>Ile - I</span>
              </div>
              <div class="footer_list">
                <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
                <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
                <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
                <span>Val - V</span>
              </div>
            </div>
          </div>
          <div class="col-12 pt-1 ">
            <p style="font-size: 8px!important; line-height: 14px!important;">
              {{footerDiscription}}
            </p>
          </div>
        </div>
      </div>
      </div>
      <div
        id="page5"
        class="container position-relative page"
        style="background-color: white"
      >
        <div class="row header mt-1 py-4">
          <div>
            <div class="col-3">
              <img src="../../../assets/logo.png" alt="" style="width: 140px" />
            </div>
          </div>
          <div></div>
          <div class="col">
            <div class="patient-detailes ml-auto float-right">
              <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
                <li>
                  Patient Id <br />
                  <span>{{ report.patient?.ecrfId }}</span>
                </li>
                <li class="ml-4">
                  Cancer Site<br />
                  <span>{{ report.cancerType }}</span>
                </li>
                <li class="ml-4">
                  SAMPLE ID<br />
                  <span>{{ report.labDetails?.sampleId }}</span>
                </li>
                <li class="ml-4">
                  Registration Date<br />
                  <span>{{
                    report.labDetails?.sampleCollected | date : "dd/MM/yyyy"
                  }}</span>
                </li>
              </ul>
            </div>
          </div>
          <!--  -->
          <div *ngIf="report.clientType == '4basecare_client'">
            <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
              <div class="logo">
                <img
                  src="../../../assets/oncoquest logo.png"
                  alt=""
                  style="width: 100px"
                />
              </div>
            </div>
            <div *ngIf="report.clientName == 'path_labs'" class="col-3">
              <div class="logo">
                <img
                  src="../../../assets/pathlab.png"
                  alt=""
                  style="width: 100px"
                />
              </div>
            </div>
          </div>
        </div>
        <!--  -->
        <div class="mar_top"></div>
        <!--  -->


        <h6 class="py-2 h6_bg mt-2" [ngClass]="'skyBlue'">
          LIMITATIONS AND DISCLAIMER
        </h6>

        <div class="row">
          <div class="col">
            <div class="disc">
              <ul class="ml-0 pl-4"  style="text-align: justify;">
                <li>
                  TARGT FIRST SOLID test has been developed, validated and performed by 4baseCare Oncosolutions Pvt. Ltd and has not been cleared or approved by the FDA.
                </li>
                <li>
                  DNA studies do not constitute a definitive test for the selected condition(s) in all individuals. It should be realized that there are possible sources of error. Errors can result
                  from trace contamination, rare technical errors, rare genetic variants that interfere with analysis, recent scientific developments, and alternative classification systems. This
                  test should be one of the many aspects used by the healthcare provider to help with a diagnosis and treatment plan.
                </li>
                <li>
                  We are using the canonical transcript for clinical reporting which is usually the longest coding transcript with strong/multiple supporting evidence. However, in rare cases,
                  clinically relevant variants annotated in alternate complete coding transcripts could also be reported.
                </li>
                <li>
                  The contents of this test should be carefully assessed by the treating physician and further interpreted along with clinical, histopathological findings, contraindications and
                  guidelines before deciding the course of therapy.
                </li>
                <li>
                  The CNVs detected must be confirmed by an alternate method, such as IHC, for further clinical management decisions.                </li>
                <!-- <li>
                  The sensitivity of this assay to detect large deletions/duplications of more than 10 bp or copy number variations (CNV) is 70-75%. The CNVs detected have to be confirmed
                  by alternate method.
                </li> -->
                <li>
                  Most recent block is recommended for testing as the mutation profile may change in response to treatment and hence differ at different sampling points.
                </li>
                <li>
                  The identified pathogenic variant will be correlated with observed phenotypic features of the patient and interpreted according to AMP guidelines.
                </li>
                <li>
                  Certain genes may not be covered completely, and few mutations could be missed. A negative result cannot rule out the possibility that the tested tumor sample carries
                  mutations not previously associated with cancer and hence not included in the panel.
                </li>
                <li>
                  Our limit of detection for TARGT FIRST SOLID is 5% for SNVs, 10% for InDels and CNV gain &#8805; 6. In addition to this, sequencing quality and coverage is dependent on many factors
                  such as homopolymers, GC-rich regions, intrinsic quality of FFPE DNA and that might impact the variant detection.
                </li>
              </ul>
            </div>
          </div>
        </div>

        <!--  -->
        <div class="row footer">
          <div class="col-12">
            <div class="pb-2 pl-4 text">
              <div class="d-block">
                <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
              </div>
              <div class="footer_body" [ngClass]="'gene-bg_blue'">
                <div class="footer_list">
                  <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
                  <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
                  <span>Gin - Q</span><span>Gly - G</span><span>His - H</span>
                  <span>Ile - I</span>
                </div>
                <div class="footer_list">
                  <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
                  <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
                  <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
                  <span>Val - V</span>
                </div>
              </div>
            </div>
            <div class="col-12 pt-1 ">
              <p style="font-size: 8px!important; line-height: 14px!important;">
                {{footerDiscription}}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- For TMH -->
    <div *ngIf="!is4basecare && isTMH && temp=='no_temp'">
      <!-- <div
        id="page"
        class="container position-relative page"
        style="overflow-y: clip; background-color: white"
      >
        <div class="row header">
          <div class="w-100 overflow-hidden">
            <div class="col">
              <div class="cover_page">
                <img
                  src="../../../assets/tarGT_FirstSolid-Cover.png"
                  alt=""
                  class="img-fluid w-100"
                />
              </div>
            </div>
          </div>
          <div></div>
        </div>
      </div> -->

      <div
        id="page1"
        class="container position-relative page"
        style="background-color: white"
      >
      <div class="d-flex justify-content-between logo_panel py-3">
        <div class="logo_part">
          <img
            src="../../../assets/tmc/tmc_log1.png"
            alt=""
            class="logo_first"
          />
        </div>
        <div class="logo_part text-center">
          <img
            src="../../../assets/tmc/tmc_logo2.png"
            alt=""
            class="logo_two"
          />
          <p style="font-size: 10px">
            An Initiative of Bihar Govt. & Doctors For You
          </p>
          <p class="text-uppercase">MEDICAL GENOMICS & MOLECULAR ONCOLOGY</p>
        </div>
        <div class="logo_part">
          <img
            src="../../../assets/tmc/tmc_logo3.jpg"
            alt=""
            class="logo_three"
          />
        </div>
      </div>
        <div class="row header mt-1">
          <div>
            <div class="col-3 pl-0">
              <!-- <div class="logo">
                <img src="../../../assets/logo.png" alt="" style="width: 140px" />
              </div> -->
            </div>
          </div>
          <div>

          </div>
          <div class="col">
            <!-- <div class="patient-detailes ml-auto float-right">

            </div> -->
          </div>
        </div>
        <!--  -->
        <div class="mar_top"></div>
        <!--  -->
        <div class="row">
          <div class="col-7">
            <div class="">
              <div class="w-50" style="min-height: 80px">
                <p class="py-1 font-weight-bold" [ngClass]="'txt-skyblue'">
                  SCOPE OF THE TEST
                </p>
                <p>SNVs, InDels, CNAs, Gene Fusions status</p>
              </div>
              <img src="../../../assets/BorderLine.png" class="mr-2 w-100" />
            </div>
          </div>
          <div class="col">
            <div class="">
              <div class="w-100">
                <!-- <p style="color: #208dcb;" class="py-1">
                        <span style="color: #000 !important"></span>
                      </p> -->
                <div class="w-100" style="min-height: 80px">
                  <p
                    [ngClass]="'txt-skyblue'"
                    class="py-1 font-weight-bold"
                    *ngIf="report.clinicalSummary != null"
                  >
                    <span *ngIf="report.clinicalSummary != ' '"
                      >CLINICAL INDICATION</span
                    >
                  </p>

                  <p *ngIf="report.clinicalSummary != null">
                    <span
                      style="color: #000 !important"
                      *ngIf="report.clinicalSummary != ' '"
                      >{{ report.clinicalSummary }}</span
                    >
                  </p>
                </div>
                <!-- </div> -->
                <img src="../../../assets/BorderLine.png" class="mr-2 w-100" />

                <!-- </div> -->
              </div>
            </div>
          </div>
        </div>
        <!--  -->
        <div class="row pt-2">
          <div class="col-7">
            <h6 class="text-uppercase py-2 font-weight-bold">Report Details</h6>
            <ul class="list-unstyled">
              <li class="pb-1">
                <span class="clr-cyan">Patient Name : </span>
                <span>{{ report.patient?.name }}</span>
              </li>
              <li class="pb-1">
                <span class="clr-cyan" [ngClass]="'txt-skyblue'">Gender : </span>
                <span class="text-black">{{
                  report.patient?.gender | titlecase
                }}</span>
              </li>
              <li class="pb-1">
                <span class="clr-cyan" [ngClass]="'txt-skyblue'">Age/DOB : </span>
                <span class="text-black">{{
                  report.patient?.dateOfBirth
                    ? (report.patient?.dateOfBirth | date : "dd/MM/yyyy")
                    : report.patient?.age + " Years"
                }}</span>
              </li>
              <li class="pb-1">
                <span class="clr-cyan">Case No : </span
                ><span>{{ report?.tmhReportInfo?.caseNo }}</span>
              </li>
              <li class="pb-1">
                <span class="clr-cyan">Path No : </span
                ><span>{{ report?.tmhReportInfo?.pathNo }}</span>
              </li>
              <li class="pb-1">
                <span class="clr-cyan">Referred By : </span
                ><span>{{ referringClinician }}</span>
              </li>
            </ul>
          </div>
          <div class="col">
            <ul class="list-unstyled mt-4 pt-4">
              <li class="pb-1">
                <span class="clr-cyan">Patient ID : </span
                ><span>{{ report.patient?.organisationPatientId }}</span>
              </li>
              <li class="pb-1">
                <span class="clr-cyan">Category : </span
                ><span>{{ report?.tmhReportInfo?.category }}</span>
              </li>
              <li class="pb-1">
                <span class="clr-cyan">Collected on : </span
                ><span>{{
                  report.labDetails?.sampleCollected | date : "dd/MM/yyyy"
                }}</span>
              </li>
              <li class="pb-1">
                <span class="clr-cyan">Reported on : </span
                ><span>{{ date | date : "dd/MM/yyyy" }}</span>
              </li>
              <li class="pb-1">
                <span class="clr-cyan">Hospital Name : </span
                ><span>{{ labHospital }}</span>
              </li>
            </ul>
          </div>
        </div>
        <!--  -->
              <!--  -->
      <h6 class="py-2 h6_bg">CLINICAL HISTORY/SAMPLE DETAILS</h6>
      <div class="row mt-2 py-2">
        <div class="col-5">
          <h6 class="mb-0 font-weight-bold">Clinical Evaluations</h6>
          <span>{{ report?.cancerIdentifier }}</span>
        </div>
        <div class="col-5">
          <h6 class="mb-0 font-weight-bold">Sample Details</h6>
          <span *ngIf="report.labDetails?.sampleSource">{{
            report.labDetails?.sampleSource
          }}</span>
          <span *ngIf="!report.labDetails?.sampleSource">FFPE Block</span>
        </div>
        <div class="col">
          <h6 class="mb-0 font-weight-bold">Tumor content</h6>
          <span>{{ report?.tmhReportInfo?.tumorContent }}</span>
        </div>
        <div class="w-100">
          <div class="col pt-4">
            <p>(Confirmed by - Consultant Pathologist, HBCHRC, Muzaffarpur)</p>
          </div>
        </div>
      </div>
      <!--  -->
        <div class="row mt-2 py-2">
          <div class="col">
            <h6 class="mb-0 text-uppercase f-weight_700">Results</h6>
          </div>
        </div>
        <h6 class="py-2 h6_bg" [ngClass]="'skyBlue'">
          Genomic findings from tumor profiling
        </h6>

        <!--  -->
        <div class="row">
          <div  style="width: 100%;" *ngIf="isReportDownload">
            <img [src]="firstPageTable" alt="" width="100%" class="img-fluid" />
          </div>
          <div class="col-12" id="firstPageTable" *ngIf="!isReportDownload">
            <table
              class="page_1 cust_table w-100 mt-3 first_1"
              [ngClass]="'table_skyblue'"
            >
              <tr>
                <th>Genomic Alteration</th>
                <th>Relevant Therapies (in Same Cancer Type)</th>
                <th>Relevant Therapies (in Different Cancer)</th>
              </tr>

              <tr *ngIf="tableCount== 0 && prognasticCount>0 ">
                <td colspan="3" class="text-center mt-2">
                  Clinically relevant genomic alterations associated with therapeutic significance were not detected.
                </td>
              </tr>
              <tr *ngIf="tableCount== 0 && prognasticCount == 0">
                <td colspan="3" class="text-center mt-2">
                  Clinically relevant genomic alterations associated with both therapeutic and prognostic significance were not detected.
                </td>
              </tr>

              <!-- SNV -->
              <ng-container>
                <tr
                  *ngFor="
                    let snvHotspotTrail of reportPdfRes.snvHotspotTrailResList
                  "
                >
                <td>
                  <i>{{ snvHotspotTrail.gene }}</i>&nbsp;
                  <span *ngIf="!snvHotspotTrail.gene.includes('Wild type')">

                  <span *ngIf="snvHotspotTrail?.multiVariant==null">
                    <span *ngIf="snvHotspotTrail.exonNumber != null">{{snvHotspotTrail.exonNumber}}</span>&nbsp;<span *ngIf="snvHotspotTrail.pposition != null && snvHotspotTrail.pposition != ''">({{snvHotspotTrail.pposition?.replace(' ','')}})</span>
                      <p *ngIf="snvHotspotTrail.mutantBurdenPercent != null && !snvHotspotTrail.gene.includes('Wild type')">
                        Allelic burden: {{ snvHotspotTrail.mutantBurdenPercent }}%
                      </p></span>

                      <span *ngIf="snvHotspotTrail?.multiVariant!=null"><br/>
                        <span  style="font-size: 14px !important" [innerHtml]="snvHotspotTrail?.multiVariant"></span>
                      </span></span>
                </td>
                  <td>
                    <table class="table first_table w-100">
                      <thead class="border-bottom cust_clr">
                        <tr>
                          <th class="w-50">
                            <span class="font-weight-normal"><i>Therapy</i></span>
                          </th>
                          <th class="w-33">
                            <span class="font-weight-normal"
                              ><i>Clinical Relevance</i></span
                            >
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngIf="snvHotspotTrail.sameCancerType == null">
                          <td class="w-50">NA</td>
                          <td class="w-50">NA</td>
                        </tr>

                        <!-- <tr *ngIf="snvHotspotTrail.sameCancerType != null">
                            <td class="w-50">
                              <div *ngFor="let drugs of snvHotspotTrail.sameCancerType.drugList">
                              <span *ngFor="let drug of drugs, let i=index">
                                  {{drug.name}}<span *ngIf="drugs.length-1 != i">,</span>
                              </span>
                          </div>
                        </td>
                          <td class="w-50">
                              <div *ngFor="let association of snvHotspotTrail.sameCancerType.associationList">
                              {{association}}
                          </div>
                        </td>
                          </tr> -->
                        <ng-container
                          *ngIf="snvHotspotTrail.sameCancerType != null"
                        >
                          <tr
                            *ngFor="
                              let item of snvHotspotTrail.sameTypeRelevantData
                            "
                          >
                            <td>{{ item.therapy }}</td>
                            <td>{{ item.association }}</td>
                            <!-- <td>{{item.cancerType}}</td> -->
                          </tr>
                        </ng-container>
                      </tbody>
                    </table>
                  </td>
                  <td>
                    <table class="table first_table w-100">
                      <thead class="border-bottom cust_clr">
                        <tr>
                          <th class="w-33">
                            <span class="font-weight-normal"><i>Therapy</i></span>
                          </th>
                          <th class="w-33">
                            <span class="font-weight-normal"
                              ><i>Clinical Relevance</i></span
                            >
                          </th>
                          <th class="w-33">
                            <span class="font-weight-normal"
                              ><i>Cancer Type</i></span
                            >
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <!-- <tr *ngIf="snvHotspotTrail.otherCancerType != null">
                            <td class="w-33 ">
                              <div *ngFor="let drugs of snvHotspotTrail.otherCancerType.drugList">
                              <span *ngFor="let drug of drugs, let i=index">
                                  {{drug.name}}<span *ngIf="drugs.length-1 != i">,</span>
                              </span>
                          </div>
                        </td>
                          <td class="w-33">
                              <div *ngFor="let association of snvHotspotTrail.otherCancerType.associationList">
                              {{association}}
                          </div>
                        </td>
                        <td class="w-33">
                          <div *ngFor="let cancerType of snvHotspotTrail.otherCancerType.cancerTypeList">
                          {{cancerType.name}}
                          </div>
                        </td>
                          </tr> -->
                        <ng-container
                          *ngIf="snvHotspotTrail.otherCancerType != null"
                        >
                          <tr
                            *ngFor="
                              let item of snvHotspotTrail.otherTypeRelevantData
                            "
                          >
                            <td>{{ item.therapy }}</td>
                            <td>{{ item.association }}</td>
                            <td>{{ item.cancerType }}</td>
                          </tr>
                        </ng-container>
                        <tr *ngIf="snvHotspotTrail.otherCancerType == null">
                          <td class="w-33">NA</td>
                          <td class="w-33">NA</td>
                          <td class="w-33">NA</td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>

                <!-- CNA -->
                <tr
                  *ngFor="
                    let cnaHotspotTrail of reportPdfRes.cnaHotspotTrailResList
                  "
                >
                  <td>
                    <i>{{ cnaHotspotTrail.gene }}</i>&nbsp;
                    <span *ngIf="cnaHotspotTrail?.multiVariant==null">
                      {{ cnaHotspotTrail.copyNumber }}</span>
                      <span *ngIf="cnaHotspotTrail?.multiVariant!=null"><br/>
                        <span  style="font-size: 14px !important" [innerHtml]="cnaHotspotTrail?.multiVariant"></span>
                        </span>
                      </td>
                  <td>
                    <table class="table first_table w-100">
                      <thead class="border-bottom cust_clr">
                        <tr>
                          <th class="w-50">
                            <span class="font-weight-normal"><i>Therapy</i></span>
                          </th>
                          <th class="w-50">
                            <span class="font-weight-normal"
                              ><i>Clinical Relevance</i></span
                            >
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <!-- <tr *ngIf="cnaHotspotTrail.sameCancerType != null">
                            <td class="w-50">
                              <div *ngFor="let drugs of cnaHotspotTrail.sameCancerType.drugList">
                              <span *ngFor="let drug of drugs, let i=index">
                                  {{drug.name}}<span *ngIf="drugs.length-1 != i">,</span>
                              </span>
                          </div>
                        </td>
                          <td class="w-50">
                              <div *ngFor="let association of cnaHotspotTrail.sameCancerType.associationList">
                              {{association}}
                          </div>
                        </td>
                          </tr> -->
                        <ng-container
                          *ngIf="cnaHotspotTrail.sameCancerType != null"
                        >
                          <tr
                            *ngFor="
                              let item of cnaHotspotTrail.sameTypeRelevantData
                            "
                          >
                            <td>{{ item.therapy }}</td>
                            <td>{{ item.association }}</td>
                            <!-- <td>{{item.cancerType}}</td> -->
                          </tr>
                        </ng-container>
                        <tr *ngIf="cnaHotspotTrail.sameCancerType == null">
                          <td class="w-50">NA</td>
                          <td class="w-50">NA</td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                  <td>
                    <table class="table first_table w-100">
                      <thead class="border-bottom cust_clr">
                        <tr>
                          <th class="w-33">
                            <span class="font-weight-normal"><i>Therapy</i></span>
                          </th>
                          <th class="w-33">
                            <span class="font-weight-normal"
                              ><i>Clinical Relevance</i></span
                            >
                          </th>
                          <th class="w-33">
                            <span class="font-weight-normal"
                              ><i>Cancer Type</i></span
                            >
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <!-- <tr *ngIf="cnaHotspotTrail.otherCancerType != null">
                            <td class="w-33 ">
                              <div *ngFor="let drugs of cnaHotspotTrail.otherCancerType.drugList">
                              <span *ngFor="let drug of drugs, let i=index">
                                  {{drug.name}}<span *ngIf="drugs.length-1 != i">,</span>
                              </span>
                          </div>
                        </td>
                          <td class="w-33">
                              <div *ngFor="let association of cnaHotspotTrail.otherCancerType.associationList">
                              {{association}}
                          </div>
                        </td>
                        <td class="w-33">
                          <div *ngFor="let cancerType of cnaHotspotTrail.otherCancerType.cancerTypeList">
                          {{cancerType.name}}
                          </div>
                        </td>
                          </tr> -->

                        <ng-container
                          *ngIf="cnaHotspotTrail.otherCancerType != null"
                        >
                          <tr
                            *ngFor="
                              let item of cnaHotspotTrail.otherTypeRelevantData
                            "
                          >
                            <td>{{ item.therapy }}</td>
                            <td>{{ item.association }}</td>
                            <td>{{ item.cancerType }}</td>
                          </tr>
                        </ng-container>

                        <tr *ngIf="cnaHotspotTrail.otherCancerType == null">
                          <td class="w-33">NA</td>
                          <td class="w-33">NA</td>
                          <td class="w-33">NA</td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>

                <!-- Fusion -->
                <tr
                  *ngFor="
                    let fusionHotspotTrail of reportPdfRes.fusionHotspotTrailResList
                  "
                >
                  <td>
                    <i>{{ fusionHotspotTrail.gene }}</i>
                  </td>
                  <td>
                    <table class="table first_table w-100">
                      <thead class="border-bottom cust_clr">
                        <tr>
                          <th class="w-50">
                            <span class="font-weight-normal"><i>Therapy</i></span>
                          </th>
                          <th class="w-33">
                            <span class="font-weight-normal"
                              ><i>Clinical Relevance</i></span
                            >
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngIf="fusionHotspotTrail.sameCancerType == null">
                          <td class="w-50">NA</td>
                          <td class="w-50">NA</td>
                        </tr>

                        <!-- <tr *ngIf="fusionHotspotTrail.sameCancerType != null">
                            <td class="w-50">
                              <div *ngFor="let drugs of fusionHotspotTrail.sameCancerType.drugList">
                              <span *ngFor="let drug of drugs, let i=index">
                                  {{drug.name}}<span *ngIf="drugs.length-1 != i">,</span>
                              </span>
                          </div>
                        </td>
                          <td class="w-50">
                              <div *ngFor="let association of fusionHotspotTrail.sameCancerType.associationList">
                              {{association}}
                          </div>
                        </td>
                          </tr> -->

                        <ng-container
                          *ngIf="fusionHotspotTrail.sameCancerType != null"
                        >
                          <tr
                            *ngFor="
                              let item of fusionHotspotTrail.sameTypeRelevantData
                            "
                          >
                            <td>{{ item.therapy }}</td>
                            <td>{{ item.association }}</td>
                            <!-- <td>{{item.cancerType}}</td> -->
                          </tr>
                        </ng-container>
                      </tbody>
                    </table>
                  </td>
                  <td>
                    <table class="table first_table w-100">
                      <thead class="border-bottom cust_clr">
                        <tr>
                          <th class="w-33">
                            <span class="font-weight-normal"><i>Therapy</i></span>
                          </th>
                          <th class="w-33">
                            <span class="font-weight-normal"
                              ><i>Clinical Relevance</i></span
                            >
                          </th>
                          <th class="w-33">
                            <span class="font-weight-normal"
                              ><i>Cancer Type</i></span
                            >
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <!-- <tr *ngIf="fusionHotspotTrail.otherCancerType != null">
                            <td class="w-33 ">
                              <div *ngFor="let drugs of fusionHotspotTrail.otherCancerType.drugList">
                              <span *ngFor="let drug of drugs, let i=index">
                                  {{drug.name}}<span *ngIf="drugs.length-1 != i">,</span>
                              </span>
                          </div>
                        </td>
                          <td class="w-33">
                              <div *ngFor="let association of fusionHotspotTrail.otherCancerType.associationList">
                              {{association}}
                          </div>
                        </td>
                        <td class="w-33">
                          <div *ngFor="let cancerType of fusionHotspotTrail.otherCancerType.cancerTypeList">
                          {{cancerType.name}}
                          </div>
                        </td>
                          </tr> -->
                        <ng-container
                          *ngIf="fusionHotspotTrail.otherCancerType != null"
                        >
                          <tr
                            *ngFor="
                              let item of fusionHotspotTrail.otherTypeRelevantData
                            "
                          >
                            <td>{{ item.therapy }}</td>
                            <td>{{ item.association }}</td>
                            <td>{{ item.cancerType }}</td>
                          </tr>
                        </ng-container>
                        <tr *ngIf="fusionHotspotTrail.otherCancerType == null">
                          <td class="w-33">NA</td>
                          <td class="w-33">NA</td>
                          <td class="w-33">NA</td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </ng-container>
            </table>
            <p
              *ngIf="
                reportPdfRes.snvHotspotTrailResList.length != 0 ||
                reportPdfRes.cnaHotspotTrailResList.length != 0
              "
              style="font-size: 13px !important"
              class="mt-3"
            >
              <i> *NA: Not Applicable</i>
            </p>
          </div>
        </div>
        <!--  -->
        <div *ngIf="(tableCount>0) &&
          prognasticCount==0">
          <h6 class="py-2 h6_bg"> Prognostic Biomarkers</h6>
        </div>
        <div class="row" *ngIf="(tableCount>0 )&&
          prognasticCount==0">
          <div class="col-12">
            <table class="page_1 cust_table w-100 mt-3 varient_details prognasticbiomarkertable"
              [ngClass]="'table_variant-blue'">
              <thead>
                <tr>
                  <th>Genomic Alteration</th>
                  <th>Prognostic Significance</th>

                </tr>
              </thead>
              <ng-container>
                <tr>
                  <td colspan="2" style="text-align:center">
                    Clinically relevant genomic alterations associated with prognostic significance were not detected.
                  </td>
                </tr>
              </ng-container>
            </table>
          </div>
        </div>
        <div *ngIf="prognasticCount>0">
          <h6 class="py-2 h6_bg"> Prognostic Biomarkers</h6>
        </div>

        <div class="row" *ngIf="prognasticCount>0">
          <div class="col-12">
            <table class="page_1 cust_table w-100 mt-3 varient_details prognasticbiomarkertable"
              [ngClass]="'table_variant-blue'">
              <thead>
                <tr>
                  <th>Genomic Alteration</th>
                  <th>Prognostic Significance</th>

                </tr>
              </thead>
              <ng-container *ngFor="let prog of reportPdfRes.prognosticBiomarkers">
                <tr>
                  <td style="padding-left: 3px !important">
                    <i>{{ prog.gene }}&nbsp; </i>
                    <span [innerHtml]="prog.geneDetails"></span>
                  </td>
                  <td style="padding-left: 3px !important;">{{prog.statement}}
                  </td>
                </tr>
              </ng-container>


          </table>
        </div>
      </div>
        <!--  -->


        <!-- <div *ngIf="geneMutationlist != null"  class="row">
           <div class="col">
             <div class="gene_name d-flex rounded">
               <h6 class="gene_title mb-0">GENE</h6>
               <span *ngFor= "let geneMutation of geneMutationlist | slice:0:8"><i>{{geneMutation.gene}}</i></span>

             </div>
             <div class="gene_status d-flex ">
              <h6 class="gene_title mb-0">STATUS</h6>
              <span *ngFor= "let geneMutation of geneMutationlist | slice:0:8" style="font-size :14pximportant;">
                <span *ngIf="geneMutation.status=='Pathogenic' || geneMutation.status=='Likely Pathogenic'" style="color:red; font-weight:600px"><b>{{geneMutation.status}}</b></span>
                <span *ngIf="geneMutation.status=='VUS'" style="color:black; font-weight:600px"><b>{{geneMutation.status}}</b></span>
                <span *ngIf="geneMutation.status == 'Not Detected' || geneMutation.status =='Benige'">{{geneMutation.status}}</span>
              </span>
              <!-- <span class="det_cyan">Detected</span> -->
        <!-- </div>
           </div>
         </div> -->

        <!-- <div *ngIf="geneMutationlist != null &&  geneMutationlist.length > 8"  class="row mt-4">
          <div class="col">
            <div class="gene_name d-flex rounded">
              <h6 class="gene_title mb-0">GENE</h6>
              <span *ngFor= "let geneMutation of geneMutationlist | slice:8:17"><i>{{geneMutation.gene}}</i></span>

            </div>
            <div class="gene_status d-flex ">
             <h6 class="gene_title mb-0">STATUS</h6>
             <span *ngFor= "let geneMutation of geneMutationlist | slice:8:17" style="font-size :14pximportant;">
              <span *ngIf="geneMutation.status=='Pathogenic' || geneMutation.status=='Likely Pathogenic'" style="color:red; font-weight:600px"><b>{{geneMutation.status}}</b></span>
              <span *ngIf="geneMutation.status=='VUS'" style="color:black; font-weight:600px"><b>{{geneMutation.status}}</b></span>
              <span *ngIf="geneMutation.status == 'Not Detected' || geneMutation.status =='Benige'">{{geneMutation.status}}</span>
            </span>
           </div>
          </div>
        </div> -->


        <!--  -->
        <div class="row footer d-flex justify-content-between align-items-center">
          <div class="col">
            <div class="py-2">
              <p>
                <span class="font-weight-bold">Genomics Bihar,</span>
              </p>
              <p>Homi Bhabha cancer hospital & Research centre</p>
              <p>Inside Srikrishna Medical College & Hospital</p>
              <p>Umanagar, Muzaffarpur-842004.</p>
              <p>Contact: ngs.mfp@gmail.com</p>
            </div>
          </div>
          <div class="col">
            <p class="text-right">
              <img
                src="../../../assets/tmc/tmc_footer.png"
                alt="footer_image"
                class="footer_img"
              />
            </p>
          </div>
        </div>
      </div>

      <div
        id="page1_2"
        *ngIf="newsnvHotspotTrailResList.length > 0"
        class="container position-relative page"
        style="background-color: white"
      >
      <div class="d-flex justify-content-between logo_panel py-3">
        <div class="logo_part">
          <img
            src="../../../assets/tmc/tmc_log1.png"
            alt=""
            class="logo_first"
          />
        </div>
        <div class="logo_part text-center">
          <img
            src="../../../assets/tmc/tmc_logo2.png"
            alt=""
            class="logo_two"
          />
          <p style="font-size: 10px">
            An Initiative of Bihar Govt. & Doctors For You
          </p>
          <p class="text-uppercase">MEDICAL GENOMICS & MOLECULAR ONCOLOGY</p>
        </div>
        <div class="logo_part">
          <img
            src="../../../assets/tmc/tmc_logo3.jpg"
            alt=""
            class="logo_three"
          />
        </div>
      </div>
        <div class="row header mt-1">
          <div>
            <!-- <div class="col-3">
              <img src="../../../assets/logo.png" alt="" style="width: 140px" />
            </div> -->
          </div>
          <div></div>
          <div class="col">
            <div class="patient-detailes ml-auto float-right">
              <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
                <li>
                  Patient Id <br />
                  <span>{{ report.patient?.organisationPatientId }}</span>
                </li>
                <li class="ml-4">
                  Patient Name<br />
                  <span>{{ report.patient?.name }}</span>
                </li>
              </ul>
            </div>
          </div>
          <!--  -->
          <div *ngIf="report.clientType == '4basecare_client'">
            <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
              <div class="logo">
                <img
                  src="../../../assets/oncoquest logo.png"
                  alt=""
                  style="width: 100px"
                />
              </div>
            </div>
            <div *ngIf="report.clientName == 'path_labs'" class="col-3">
              <div class="logo">
                <img
                  src="../../../assets/pathlab.png"
                  alt=""
                  style="width: 100px"
                />
              </div>
            </div>
          </div>
        </div>
        <h6 class="py-2 h6_bg" [ngClass]="'skyBlue'">
          Genomic findings from tumor profiling
        </h6>
        <div class="row">
          <!-- <div class="col-12"  *ngIf="isReportDownload">
            <img [src]="firstPageTable" alt="" class="img-fluid w-90">
          </div> -->
          <div class="col-12" id="firstPageTable">
            <table
              class="page_1 cust_table w-100 mt-3 first_1"
              [ngClass]="'table_skyblue'"
            >
              <tr>
                <th>Genomic Alteration</th>
                <th>Relevant Therapies (in Same Cancer Type)</th>
                <th>Relevant Therapies (in Different Cancer)</th>
              </tr>



              <!-- SNV -->
              <ng-container>
                <tr *ngFor="let snvHotspotTrail of newsnvHotspotTrailResList">
                  <td>
                    <i>{{ snvHotspotTrail.gene }}</i>&nbsp;
                    <span *ngIf="!snvHotspotTrail.gene.includes('Wild type')">

                    <span *ngIf="snvHotspotTrail?.multiVariant==null">
                      <span *ngIf="snvHotspotTrail.exonNumber != null">{{snvHotspotTrail.exonNumber}}</span>&nbsp;<span *ngIf="snvHotspotTrail.pposition != null && snvHotspotTrail.pposition != ''">({{snvHotspotTrail.pposition?.replace(' ','')}})</span>
                        <p *ngIf="snvHotspotTrail.mutantBurdenPercent != null && !snvHotspotTrail.gene.includes('Wild type')">
                          Allelic burden: {{ snvHotspotTrail.mutantBurdenPercent }}%
                        </p></span>

                        <span *ngIf="snvHotspotTrail?.multiVariant!=null"><br/>
                          <span  style="font-size: 14px !important" [innerHtml]="snvHotspotTrail?.multiVariant"></span>
                        </span></span>
                  </td>
                  <td>
                    <table class="first_table w-100">
                      <thead class="border-bottom cust_clr">
                        <tr>
                          <th class="w-50">
                            <span class="font-weight-normal"><i>Therapy</i></span>
                          </th>
                          <th class="w-33">
                            <span class="font-weight-normal"
                              ><i>Clinical Relevance</i></span
                            >
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngIf="snvHotspotTrail.sameCancerType == null">
                          <td class="w-50">NA</td>
                          <td class="w-50">NA</td>
                        </tr>

                        <!-- <tr *ngIf="snvHotspotTrail.sameCancerType != null">
                            <td class="w-50">
                              <div *ngFor="let drugs of snvHotspotTrail.sameCancerType.drugList">
                              <span *ngFor="let drug of drugs, let i=index">
                                  {{drug.name}}<span *ngIf="drugs.length-1 != i">,</span>
                              </span>
                          </div>
                        </td>
                          <td class="w-50">
                              <div *ngFor="let association of snvHotspotTrail.sameCancerType.associationList">
                              {{association}}
                          </div>
                        </td>
                          </tr> -->
                        <ng-container
                          *ngIf="snvHotspotTrail.sameCancerType != null"
                        >
                          <tr
                            *ngFor="
                              let item of snvHotspotTrail.sameTypeRelevantData
                            "
                          >
                            <td>{{ item.therapy }}</td>
                            <td>{{ item.association }}</td>
                            <!-- <td>{{item.cancerType}}</td> -->
                          </tr>
                        </ng-container>
                      </tbody>
                    </table>
                  </td>
                  <td>
                    <table class="first_table w-100">
                      <thead class="border-bottom cust_clr">
                        <tr>
                          <th class="w-33">
                            <span class="font-weight-normal"><i>Therapy</i></span>
                          </th>
                          <th class="w-33">
                            <span class="font-weight-normal"
                              ><i>Clinical Relevance</i></span
                            >
                          </th>
                          <th class="w-33">
                            <span class="font-weight-normal"
                              ><i>Cancer Type</i></span
                            >
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <!-- <tr *ngIf="snvHotspotTrail.otherCancerType != null">
                            <td class="w-33 ">
                              <div *ngFor="let drugs of snvHotspotTrail.otherCancerType.drugList">
                              <span *ngFor="let drug of drugs, let i=index">
                                  {{drug.name}}<span *ngIf="drugs.length-1 != i">,</span>
                              </span>
                          </div>
                        </td>
                          <td class="w-33">
                              <div *ngFor="let association of snvHotspotTrail.otherCancerType.associationList">
                              {{association}}
                          </div>
                        </td>
                        <td class="w-33">
                          <div *ngFor="let cancerType of snvHotspotTrail.otherCancerType.cancerTypeList">
                          {{cancerType.name}}
                          </div>
                        </td>
                          </tr> -->
                        <ng-container
                          *ngIf="snvHotspotTrail.otherCancerType != null"
                        >
                          <tr
                            *ngFor="
                              let item of snvHotspotTrail.otherTypeRelevantData
                            "
                          >
                            <td>{{ item.therapy }}</td>
                            <td>{{ item.association }}</td>
                            <td>{{ item.cancerType }}</td>
                          </tr>
                        </ng-container>
                        <tr *ngIf="snvHotspotTrail.otherCancerType == null">
                          <td class="w-33">NA</td>
                          <td class="w-33">NA</td>
                          <td class="w-33">NA</td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </ng-container>
            </table>
            <p
              *ngIf="newsnvHotspotTrailResList.length != 0"
              style="font-size: 13px !important"
              class="mt-3"
            >
              <i> *NA: Not Applicable</i>
            </p>
          </div>
        </div>
        <!--  -->
        <div class="row footer d-flex justify-content-between align-items-center">
          <div class="col">
            <div class="py-2">
              <p>
                <span class="font-weight-bold">Genomics Bihar,</span>
              </p>
              <p>Homi Bhabha cancer hospital & Research centre</p>
              <p>Inside Srikrishna Medical College & Hospital</p>
              <p>Umanagar, Muzaffarpur-842004.</p>
              <p>Contact: ngs.mfp@gmail.com</p>
            </div>
          </div>
          <div class="col">
            <p class="text-right">
              <img
                src="../../../assets/tmc/tmc_footer.png"
                alt="footer_image"
                class="footer_img"
              />
            </p>
          </div>
        </div>
      </div>

      <div
        id="page2"
        *ngIf="(report.addNotes != '<div></div>' && report.addNotes != null) ||
        geneMutationlist != null ||
        otherCountSNV == true ||
        reportPdfRes.allVariantsList.length > 0 ||
        (report.disclaimerNote != ' ' && report.disclaimerNote != null)
        "
        class="container position-relative page"
        style="background-color: white"
      >
      <div class="d-flex justify-content-between logo_panel py-3">
        <div class="logo_part">
          <img
            src="../../../assets/tmc/tmc_log1.png"
            alt=""
            class="logo_first"
          />
        </div>
        <div class="logo_part text-center">
          <img
            src="../../../assets/tmc/tmc_logo2.png"
            alt=""
            class="logo_two"
          />
          <p style="font-size: 10px">
            An Initiative of Bihar Govt. & Doctors For You
          </p>
          <p class="text-uppercase">MEDICAL GENOMICS & MOLECULAR ONCOLOGY</p>
        </div>
        <div class="logo_part">
          <img
            src="../../../assets/tmc/tmc_logo3.jpg"
            alt=""
            class="logo_three"
          />
        </div>
      </div>
        <div class="row header mt-1">
          <div>
            <!-- <div class="col-3">
              <img src="../../../assets/logo.png" alt="" style="width: 140px" />
            </div> -->
          </div>
          <div></div>
          <div class="col">
            <div class="patient-detailes ml-auto float-right">
              <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
                <li>
                  Patient Id <br />
                  <span>{{ report.patient?.organisationPatientId }}</span>
                </li>
                <li class="ml-4">
                  Patient Name<br />
                  <span>{{ report.patient?.name }}</span>
                </li>
              </ul>
            </div>
          </div>
          <!--  -->
          <div *ngIf="report.clientType == '4basecare_client'">
            <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
              <div class="logo">
                <img
                  src="../../../assets/oncoquest logo.png"
                  alt=""
                  style="width: 100px"
                />
              </div>
            </div>
            <div *ngIf="report.clientName == 'path_labs'" class="col-3">
              <div class="logo">
                <img
                  src="../../../assets/pathlab.png"
                  alt=""
                  style="width: 100px"
                />
              </div>
            </div>
          </div>
        </div>
        <div
          *ngIf="
            report.disclaimerNote != ' ' &&
            report.disclaimerNote != null &&
            tableCount >= 6
          "
        >
          <h6 style="color: #208dcb"><b>Note: </b></h6>
          <div
            style="font-size: 14px !important ;text-align: justify"
            [innerHtml]="report.disclaimerNote"
          ></div>
          <!-- <div class="py-2" [innerHtml]='report.disclaimerNote' style=" text-align: justify;font-size: 14px!important;"></div> -->

          <!-- <p>The data size we had received for this sample was inadequate. However, with the patient's best interest in mind we have further processed the sample. Kindly correlate clinically before making any treatment decisions.</p> -->
          <!-- <p>The data size we had received for this sample was inadequate. However, with the patient's best interest in mind we have further processed the sample and a false negative status of the result cannot be ruled out. Kindly correlate clinically before making any treatment decisions.</p> -->
        </div>
                <!-- gene Data -->
                <!-- <div *ngIf="!geneFlag && isStatusDisplay">
                  <div *ngIf="geneMutationlist != null" class="mt-3">
                    <h6 class="py-2 h6_bg" [ngClass]="'skyBlue'">
                      Status of variants in cancer related Biomarkers
                    </h6>
                  </div>

                  <table
                    class="staus_gene bg_skyblue"
                    *ngIf="geneMutationlist != null"
                    [ngClass]="{
                      biomarker_table:
                        geneMutationlist.length >= 4 &&
                        geneMutationlist.length < 8
                    }"
                  >
                    <tr style="background: #daedf7">
                      <td
                        style="font-size: 18px !important; padding-left: 10px !important"
                      >
                        Gene
                      </td>
                      <td
                        *ngFor="
                          let geneMutation of geneMutationlist
                            | slice : 0 : 8
                        "
                      >
                        <i>{{ geneMutation.gene }}</i>
                      </td>
                    </tr>
                    <tr>
                      <td
                        style="font-size: 18px !important; padding-left: 10px !important"
                      >
                        <b>Status</b>
                      </td>
                      <td
                        *ngFor="
                          let geneMutation of geneMutationlist
                            | slice : 0 : 8
                        "
                      >
                        <span
                          *ngIf="
                            geneMutation.status == 'Pathogenic' ||
                            geneMutation.status == 'Likely Pathogenic' ||
                            geneMutation.status == 'Detected'
                          "
                          style="color: red; font-weight: 600px"
                          ><b>{{ geneMutation.status }}</b></span
                        >
                        <span
                          *ngIf="geneMutation.status == 'VUS'"
                          style="color: black; font-weight: 600px"
                          ><b>{{ geneMutation.status }}</b></span
                        >
                        <span
                          *ngIf="
                            geneMutation.status == 'Not Detected' ||
                            geneMutation.status == 'Benige'
                          "
                          >{{ geneMutation.status }}</span
                        >
                      </td>
                    </tr>
                    <br/>
                  </table>
                  <table
                    class="staus_gene"
                    [ngClass]="'bg_skyblue'"
                    *ngIf="
                      geneMutationlist != null &&
                      geneMutationlist.length > 8
                    "
                  >
                    <tr style="background: #daedf7">
                      <td
                        style="font-size: 18px !important; padding-left: 10px !important"
                      >
                        Gene
                      </td>
                      <td
                        *ngFor="
                          let geneMutation of geneMutationlist
                            | slice : 8 : 17
                        "
                      >
                        <i>{{ geneMutation.gene }}</i>
                      </td>
                    </tr>
                    <tr>
                      <td
                        style="font-size: 18px !important; padding-left: 10px !important"
                      >
                        <b>Status</b>
                      </td>
                      <td
                        *ngFor="
                          let geneMutation of geneMutationlist
                            | slice : 8 : 17
                        "
                      >
                        <span
                          *ngIf="
                            geneMutation.status == 'Pathogenic' ||
                            geneMutation.status == 'Likely Pathogenic' ||
                            geneMutation.status == 'Detected'
                          "
                          style="color: red; font-weight: 600px"
                          ><b>{{ geneMutation.status }}</b></span
                        >
                        <span
                          *ngIf="geneMutation.status == 'VUS'"
                          style="color: black; font-weight: 600px"
                          ><b>{{ geneMutation.status }}</b></span
                        >
                        <span
                          *ngIf="
                            geneMutation.status == 'Not Detected' ||
                            geneMutation.status == 'Benige'
                          "
                          >{{ geneMutation.status }}</span
                        >
                      </td>
                    </tr>
                    <br/>
                  </table>
                  <table
                    class="staus_gene"
                    [ngClass]="'bg_skyblue'"
                    *ngIf="
                      geneMutationlist != null &&
                      geneMutationlist.length > 17
                    "
                  >
                    <tr style="background: #daedf7">
                      <td
                        style="font-size: 18px !important; padding-left: 10px !important"
                      >
                        Gene
                      </td>
                      <td
                        *ngFor="
                          let geneMutation of geneMutationlist
                            | slice : 17 : 25
                        "
                      >
                        <i>{{ geneMutation.gene }}</i>
                      </td>
                    </tr>
                    <tr>
                      <td
                        style="font-size: 18px !important; padding-left: 10px !important"
                      >
                        <b>Status</b>
                      </td>
                      <td
                        *ngFor="
                          let geneMutation of geneMutationlist
                            | slice : 17 : 25
                        "
                      >
                        <span
                          *ngIf="
                            geneMutation.status == 'Pathogenic' ||
                            geneMutation.status == 'Likely Pathogenic' ||
                            geneMutation.status == 'Detected'
                          "
                          style="color: red; font-weight: 600px"
                          ><b>{{ geneMutation.status }}</b></span
                        >
                        <span
                          *ngIf="geneMutation.status == 'VUS'"
                          style="color: black; font-weight: 600px"
                          ><b>{{ geneMutation.status }}</b></span
                        >
                        <span
                          *ngIf="
                            geneMutation.status == 'Not Detected' ||
                            geneMutation.status == 'Benige'
                          "
                          >{{ geneMutation.status }}</span
                        >
                      </td>
                    </tr>
                    <br/>
                  </table>
                  <table class="staus_gene" [ngClass]="'bg_skyblue'" *ngIf="
                        geneMutationlist != null &&
                        geneMutationlist.length > 25
                      ">
                    <tr style="background: #daedf7">
                      <td style="font-size: 18px !important; padding-left: 10px !important">
                        Gene
                      </td>
                      <td *ngFor="
                            let geneMutation of geneMutationlist
                              | slice : 25 : 33
                          ">
                        <i>{{ geneMutation.gene }}</i>
                      </td>
                    </tr>
                    <tr>
                      <td style="font-size: 18px !important; padding-left: 10px !important">
                        <b>Status</b>
                      </td>
                      <td *ngFor="
                            let geneMutation of geneMutationlist
                              | slice : 25 : 33
                          ">
                        <span *ngIf="
                              geneMutation.status == 'Pathogenic' ||
                              geneMutation.status == 'Likely Pathogenic' ||
                              geneMutation.status == 'Detected'
                            " style="color: red; font-weight: 600px"><b>{{ geneMutation.status }}</b></span>
                        <span *ngIf="geneMutation.status == 'VUS'" style="color: black; font-weight: 600px"><b>{{
                            geneMutation.status }}</b></span>
                        <span *ngIf="
                              geneMutation.status == 'Not Detected' ||
                              geneMutation.status == 'Benige'
                            ">{{ geneMutation.status }}</span>
                      </td>
                    </tr>
                    <br/>
                  </table>
          <table class="staus_gene" [ngClass]="'bg_skyblue'" *ngIf="
                geneMutationlist != null &&
                geneMutationlist.length > 33
              ">
            <tr style="background: #daedf7">
              <td style="font-size: 18px !important; padding-left: 10px !important">
                Gene
              </td>
              <td *ngFor="
                    let geneMutation of geneMutationlist
                      | slice : 33 : 41
                  ">
                <i>{{ geneMutation.gene }}</i>
              </td>
            </tr>
            <tr>
              <td style="font-size: 18px !important; padding-left: 10px !important">
                <b>Status</b>
              </td>
              <td *ngFor="
                    let geneMutation of geneMutationlist
                      | slice : 33 : 41
                  ">
                <span *ngIf="
                      geneMutation.status == 'Pathogenic' ||
                      geneMutation.status == 'Likely Pathogenic' ||
                      geneMutation.status == 'Detected'
                    " style="color: red; font-weight: 600px"><b>{{ geneMutation.status }}</b></span>
                <span *ngIf="geneMutation.status == 'VUS'" style="color: black; font-weight: 600px"><b>{{
                    geneMutation.status }}</b></span>
                <span *ngIf="
                      geneMutation.status == 'Not Detected' ||
                      geneMutation.status == 'Benige'
                    ">{{ geneMutation.status }}</span>
              </td>
            </tr>
            <br/>
          </table>
                  <h6
                    *ngIf="
                    tableCount<=1 &&
                      fusionGeneMutationList != null &&
                      fusionGeneMutationList.length > 0
                    "
                    class="font-weight-bold clr-cyan text-uppercase"
                    [ngClass]="'txt-skyblue'"
                  >
                    Gene Fusion
                  </h6>
                  <table
                    class="staus_gene"
                    *ngIf="
                      fusionGeneMutationList != null &&
                      fusionGeneMutationList.length > 0
                    "
                    [ngClass]="'bg_skyblue'"
                  >
                    <tr style="background: #daedf7">
                      <td
                        style="font-size: 18px !important; padding-left: 10px !important"
                      >
                        Gene
                      </td>
                      <td
                        *ngFor="
                          let geneMutation of fusionGeneMutationList
                            | slice : 0 : 8
                        "
                      >
                        <i>{{ geneMutation.gene }}</i>
                      </td>
                    </tr>
                    <tr>
                      <td
                        style="font-size: 18px !important; padding-left: 10px !important"
                      >
                        <b>Status</b>
                      </td>
                      <td
                        *ngFor="
                          let geneMutation of fusionGeneMutationList
                            | slice : 0 : 8
                        "
                      >
                        <span
                          *ngIf="
                            geneMutation.status == 'Pathogenic' ||
                            geneMutation.status == 'Likely Pathogenic' ||
                            geneMutation.status == 'Detected'
                          "
                          style="color: red; font-weight: 600px"
                          ><b>{{ geneMutation.status }}</b></span
                        >
                        <span
                          *ngIf="geneMutation.status == 'VUS'"
                          style="color: black; font-weight: 600px"
                          ><b>{{ geneMutation.status }}</b></span
                        >
                        <span
                          *ngIf="
                            geneMutation.status == 'Not Detected' ||
                            geneMutation.status == 'Benige'
                          "
                          >{{ geneMutation.status }}</span
                        >
                      </td>
                    </tr>
                  </table>
                  <br/>
                  <table
                    class="staus_gene"
                    *ngIf="
                      fusionGeneMutationList != null &&
                      fusionGeneMutationList.length > 8
                    "
                    [ngClass]="'bg_skyblue'"
                  >
                    <tr style="background: #daedf7">
                      <td
                        style="font-size: 18px !important; padding-left: 10px !important"
                      >
                        Gene
                      </td>
                      <td
                        *ngFor="
                          let geneMutation of fusionGeneMutationList
                            | slice : 8 : 17
                        "
                      >
                        <i>{{ geneMutation.gene }}</i>
                      </td>
                    </tr>
                    <tr>
                      <td
                        style="font-size: 18px !important; padding-left: 10px !important"
                      >
                        <b>Status</b>
                      </td>
                      <td
                        *ngFor="
                          let geneMutation of fusionGeneMutationList
                            | slice : 8 : 17
                        "
                      >
                        <span
                          *ngIf="
                            geneMutation.status == 'Pathogenic' ||
                            geneMutation.status == 'Likely Pathogenic' ||
                            geneMutation.status == 'Detected'
                          "
                          style="color: red; font-weight: 600px"
                          ><b>{{ geneMutation.status }}</b></span
                        >
                        <span
                          *ngIf="geneMutation.status == 'VUS'"
                          style="color: black; font-weight: 600px"
                          ><b>{{ geneMutation.status }}</b></span
                        >
                        <span
                          *ngIf="
                            geneMutation.status == 'Not Detected' ||
                            geneMutation.status == 'Benige'
                          "
                          >{{ geneMutation.status }}</span
                        >
                      </td>
                    </tr>
                  </table>
                </div>
        <div *ngIf="geneFlag && isStatusDisplay">
          <div *ngIf="geneMutationlist != null" class="mt-3">
            <h6 class="py-2 h6_bg" [ngClass]="'skyBlue'">
              Status of variants in cancer related Biomarkers
            </h6>
          </div>
          <table
            class="staus_gene"
            *ngIf="geneMutationlist != null"
            [ngClass]="'bg_skyblue'"
          >
            <tr style="background: #daedf7">
              <td
                style="font-size: 18px !important; padding-left: 10px !important"
              >
                Gene
              </td>
              <td
                *ngFor="
                  let geneMutation of geneMutationlist
                    | slice : 0 : 8
                "
              >
                <i>{{ geneMutation.gene }}</i>
              </td>
            </tr>
            <tr>
              <td
                style="font-size: 18px !important; padding-left: 10px !important"
              >
                <b>Status</b>
              </td>
              <td
                *ngFor="
                  let geneMutation of geneMutationlist
                    | slice : 0 : 8
                "
              >
                <span
                  *ngIf="
                    geneMutation.status == 'Pathogenic' ||
                    geneMutation.status == 'Likely Pathogenic' ||
                    geneMutation.status == 'Detected'
                  "
                  style="color: red; font-weight: 600px"
                  ><b>{{ geneMutation.status }}</b></span
                >
                <span
                  *ngIf="geneMutation.status == 'VUS'"
                  style="color: black; font-weight: 600px"
                  ><b>{{ geneMutation.status }}</b></span
                >
                <span
                  *ngIf="
                    geneMutation.status == 'Not Detected' ||
                    geneMutation.status == 'Benige'
                  "
                  >{{ geneMutation.status }}</span
                >
              </td>
            </tr>
            <br/>
          </table>
          <table
            class="staus_gene"
            [ngClass]="'bg_skyblue'"
            *ngIf="
              geneMutationlist != null &&
              geneMutationlist.length > 8
            "
          >
            <tr style="background: #daedf7">
              <td
                style="font-size: 18px !important; padding-left: 10px !important"
              >
                Gene
              </td>
              <td
                *ngFor="
                  let geneMutation of geneMutationlist
                    | slice : 8 : 17
                "
              >
                <i>{{ geneMutation.gene }}</i>
              </td>
            </tr>
            <tr>
              <td
                style="font-size: 18px !important; padding-left: 10px !important"
              >
                <b>Status</b>
              </td>
              <td
                *ngFor="
                  let geneMutation of geneMutationlist
                    | slice : 8 : 17
                "
              >
                <span
                  *ngIf="
                    geneMutation.status == 'Pathogenic' ||
                    geneMutation.status == 'Likely Pathogenic' ||
                    geneMutation.status == 'Detected'
                  "
                  style="color: red; font-weight: 600px"
                  ><b>{{ geneMutation.status }}</b></span
                >
                <span
                  *ngIf="geneMutation.status == 'VUS'"
                  style="color: black; font-weight: 600px"
                  ><b>{{ geneMutation.status }}</b></span
                >
                <span
                  *ngIf="
                    geneMutation.status == 'Not Detected' ||
                    geneMutation.status == 'Benige'
                  "
                  >{{ geneMutation.status }}</span
                >
              </td>
            </tr>
            <br/>
          </table>
          <table
            class="staus_gene"
            [ngClass]="'bg_skyblue'"
            *ngIf="
              geneMutationlist != null &&
              geneMutationlist.length > 17
            "
          >
            <tr style="background: #daedf7">
              <td
                style="font-size: 18px !important; padding-left: 10px !important"
              >
                Gene
              </td>
              <td
                *ngFor="
                  let geneMutation of geneMutationlist
                    | slice : 17 : 25
                "
              >
                <i>{{ geneMutation.gene }}</i>
              </td>
            </tr>
            <tr>
              <td
                style="font-size: 18px !important; padding-left: 10px !important"
              >
                <b>Status</b>
              </td>
              <td
                *ngFor="
                  let geneMutation of geneMutationlist
                    | slice : 17 : 25
                "
              >
                <span
                  *ngIf="
                    geneMutation.status == 'Pathogenic' ||
                    geneMutation.status == 'Likely Pathogenic' ||
                    geneMutation.status == 'Detected'
                  "
                  style="color: red; font-weight: 600px"
                  ><b>{{ geneMutation.status }}</b></span
                >
                <span
                  *ngIf="geneMutation.status == 'VUS'"
                  style="color: black; font-weight: 600px"
                  ><b>{{ geneMutation.status }}</b></span
                >
                <span
                  *ngIf="
                    geneMutation.status == 'Not Detected' ||
                    geneMutation.status == 'Benige'
                  "
                  >{{ geneMutation.status }}</span
                >
              </td>
            </tr>
            <br/>
          </table>
          <table class="staus_gene" [ngClass]="'bg_skyblue'" *ngIf="
                geneMutationlist != null &&
                geneMutationlist.length > 25
              ">
            <tr style="background: #daedf7">
              <td style="font-size: 18px !important; padding-left: 10px !important">
                Gene
              </td>
              <td *ngFor="
                    let geneMutation of geneMutationlist
                      | slice : 25 : 33
                  ">
                <i>{{ geneMutation.gene }}</i>
              </td>
            </tr>
            <tr>
              <td style="font-size: 18px !important; padding-left: 10px !important">
                <b>Status</b>
              </td>
              <td *ngFor="
                    let geneMutation of geneMutationlist
                      | slice : 25 : 33
                  ">
                <span *ngIf="
                      geneMutation.status == 'Pathogenic' ||
                      geneMutation.status == 'Likely Pathogenic' ||
                      geneMutation.status == 'Detected'
                    " style="color: red; font-weight: 600px"><b>{{ geneMutation.status }}</b></span>
                <span *ngIf="geneMutation.status == 'VUS'" style="color: black; font-weight: 600px"><b>{{
                    geneMutation.status }}</b></span>
                <span *ngIf="
                      geneMutation.status == 'Not Detected' ||
                      geneMutation.status == 'Benige'
                    ">{{ geneMutation.status }}</span>
              </td>
            </tr>
            <br/>
          </table>

          <table class="staus_gene" [ngClass]="'bg_skyblue'" *ngIf="
                geneMutationlist != null &&
                geneMutationlist.length > 33
              ">
            <tr style="background: #daedf7">
              <td style="font-size: 18px !important; padding-left: 10px !important">
                Gene
              </td>
              <td *ngFor="
                    let geneMutation of geneMutationlist
                      | slice : 33 : 41
                  ">
                <i>{{ geneMutation.gene }}</i>
              </td>
            </tr>
            <tr>
              <td style="font-size: 18px !important; padding-left: 10px !important">
                <b>Status</b>
              </td>
              <td *ngFor="
                    let geneMutation of geneMutationlist
                      | slice : 33 : 41
                  ">
                <span *ngIf="
                      geneMutation.status == 'Pathogenic' ||
                      geneMutation.status == 'Likely Pathogenic' ||
                      geneMutation.status == 'Detected'
                    " style="color: red; font-weight: 600px"><b>{{ geneMutation.status }}</b></span>
                <span *ngIf="geneMutation.status == 'VUS'" style="color: black; font-weight: 600px"><b>{{
                    geneMutation.status }}</b></span>
                <span *ngIf="
                      geneMutation.status == 'Not Detected' ||
                      geneMutation.status == 'Benige'
                    ">{{ geneMutation.status }}</span>
              </td>
            </tr>
          </table>
          <h6
            *ngIf="
              fusionGeneMutationList != null &&
              fusionGeneMutationList.length > 0
            "
            class="font-weight-bold clr-cyan text-uppercase"
            [ngClass]="'txt-skyblue'"
          >
            Gene Fusion
          </h6>
          <table
            class="staus_gene"
            *ngIf="
              fusionGeneMutationList != null &&
              fusionGeneMutationList.length > 0
            "
            [ngClass]="'bg_skyblue'"
          >
            <tr style="background: #daedf7">
              <td
                style="font-size: 18px !important; padding-left: 10px !important"
              >
                Gene
              </td>
              <td
                *ngFor="
                  let geneMutation of fusionGeneMutationList
                    | slice : 0 : 8
                "
              >
                <i>{{ geneMutation.gene }}</i>
              </td>
            </tr>
            <tr>
              <td
                style="font-size: 18px !important; padding-left: 10px !important"
              >
                <b>Status</b>
              </td>
              <td
                *ngFor="
                  let geneMutation of fusionGeneMutationList
                    | slice : 0 : 8
                "
              >
                <span
                  *ngIf="
                    geneMutation.status == 'Pathogenic' ||
                    geneMutation.status == 'Likely Pathogenic' ||
                    geneMutation.status == 'Detected'
                  "
                  style="color: red; font-weight: 600px"
                  ><b>{{ geneMutation.status }}</b></span
                >
                <span
                  *ngIf="geneMutation.status == 'VUS'"
                  style="color: black; font-weight: 600px"
                  ><b>{{ geneMutation.status }}</b></span
                >
                <span
                  *ngIf="
                    geneMutation.status == 'Not Detected' ||
                    geneMutation.status == 'Benige'
                  "
                  >{{ geneMutation.status }}</span
                >
              </td>
            </tr>
          </table>
          <br/>
          <table
            class="staus_gene"
            *ngIf="
              fusionGeneMutationList != null &&
              fusionGeneMutationList.length > 8
            "
            [ngClass]="'bg_skyblue'"
          >
            <tr style="background: #daedf7">
              <td
                style="font-size: 18px !important; padding-left: 10px !important"
              >
                Gene
              </td>
              <td
                *ngFor="
                  let geneMutation of fusionGeneMutationList
                    | slice : 8 : 17
                "
              >
                <i>{{ geneMutation.gene }}</i>
              </td>
            </tr>
            <tr>
              <td
                style="font-size: 18px !important; padding-left: 10px !important"
              >
                <b>Status</b>
              </td>
              <td
                *ngFor="
                  let geneMutation of fusionGeneMutationList
                    | slice : 8 : 17
                "
              >
                <span
                  *ngIf="
                    geneMutation.status == 'Pathogenic' ||
                    geneMutation.status == 'Likely Pathogenic' ||
                    geneMutation.status == 'Detected'
                  "
                  style="color: red; font-weight: 600px"
                  ><b>{{ geneMutation.status }}</b></span
                >
                <span
                  *ngIf="geneMutation.status == 'VUS'"
                  style="color: black; font-weight: 600px"
                  ><b>{{ geneMutation.status }}</b></span
                >
                <span
                  *ngIf="
                    geneMutation.status == 'Not Detected' ||
                    geneMutation.status == 'Benige'
                  "
                  >{{ geneMutation.status }}</span
                >
              </td>
            </tr>
          </table>
        </div> -->
        <div
          *ngIf="
            report.disclaimerNote != ' ' &&
            report.disclaimerNote != null
          "
        >
          <h6 style="color: #208dcb"><b>Note: </b></h6>
          <div
            style="font-size: 14px !important;text-align: justify"
            [innerHtml]="report.disclaimerNote"
          ></div>
        </div>
        <!--  -->

        <div
          class="row mt-3"
          *ngIf="
            otherCountSNV == true ||
            reportPdfRes.allVariantsList.length != 0
          "
        >
          <div class="col">
            <h6
              class="font-weight-bold clr-cyan text-uppercase"
              [ngClass]="'txt-skyblue'"
            >
              Variant Details:
            </h6>
          </div>
        </div>

        <div
          class="row"
          *ngIf="
            otherCountSNV == true ||
            reportPdfRes.allVariantsList.length != 0
          "
        >
          <div class="col-12">
            <table
              class="page_1 cust_table w-100 mt-3 varient_details"
              [ngClass]="'table_variant-blue'"
            >
              <thead>
                <tr>
                  <th>Gene</th>
                  <th>Variant Location</th>
                  <th>Variant Consequence</th>
                  <th>Clinical Significance</th>
                  <th>Variant Type</th>
                  <th>Reference</th>
                </tr>
              </thead>

              <!-- SNV -->
              <tr
                *ngFor="
                  let selectedReportedVariant of reportPdfRes.snvReportedVariantList
                "
              >
                <td
                  *ngIf="
                    selectedReportedVariant.significance != 'NA' &&
                    selectedReportedVariant.significance != 'Not Classified'
                  "
                  style="padding-left: 3px !important"
                >
                  <i>{{ selectedReportedVariant.refGeneX }}</i>
                </td>
                <td
                  *ngIf="
                    selectedReportedVariant.significance != 'NA' &&
                    selectedReportedVariant.significance != 'Not Classified'
                  "
                  style="padding-left: 3px !important"
                >
                  {{ selectedReportedVariant.genomicAlteration }},<br />
                  {{ selectedReportedVariant.ensembleValue }},<br />
                  {{ selectedReportedVariant.exonNumber }}
                </td>
                <td
                  *ngIf="
                    selectedReportedVariant.significance != 'NA' &&
                    selectedReportedVariant.significance != 'Not Classified'
                  "
                  style="padding-left: 3px !important"
                >
                <span *ngIf="selectedReportedVariant.selectedAAChangeknownGene">{{ selectedReportedVariant.selectedAAChangeknownGene|splitComma }}</span>,{{selectedReportedVariant.mutantBurdenPercent * 0.01 | percent}}
                </td>
                <td
                  *ngIf="
                    selectedReportedVariant.significance != 'NA' &&
                    selectedReportedVariant.significance != 'Not Classified'
                  "
                  style="padding-left: 3px !important"
                >
                  {{ selectedReportedVariant.significance }}
                </td>
                <td
                  *ngIf="
                    selectedReportedVariant.significance != 'NA' &&
                    selectedReportedVariant.significance != 'Not Classified'
                  "
                  style="padding-left: 3px !important"
                >
                  {{ selectedReportedVariant.exonicFuncknownGene }}
                </td>
                <td
                  *ngIf="
                    selectedReportedVariant.significance != 'NA' &&
                    selectedReportedVariant.significance != 'Not Classified'
                  "
                  style="padding-left: 3px !important"
                >
                  {{ selectedReportedVariant.reference }}
                </td>
              </tr>

              <!-- CNA -->
              <tr
                *ngFor="
                  let cnaReportedVariant of reportPdfRes.cnaReportedVariantList
                "
              >
                <td
                  *ngIf="
                    cnaReportedVariant.significance != 'NA' &&
                    cnaReportedVariant.significance != 'Not Classified'
                  "
                  style="padding-left: 3px !important"
                >
                  <i>{{ cnaReportedVariant.gene }}</i>
                </td>
                <td
                  *ngIf="
                    cnaReportedVariant.significance != 'NA' &&
                    cnaReportedVariant.significance != 'Not Classified'
                  "
                  style="padding-left: 3px !important"
                >
                  chr{{ cnaReportedVariant.sample.split(":")[0] }}:g.{{
                    cnaReportedVariant.sample
                      .split(":")[1]
                      .replace("-", "_")
                      .split("|")[0]
                  }}
                </td>
                <td
                  *ngIf="
                    cnaReportedVariant.significance != 'NA' &&
                    cnaReportedVariant.significance != 'Not Classified'
                  "
                  style="padding-left: 3px !important"
                >
                  NA
                </td>
                <td
                  *ngIf="
                    cnaReportedVariant.significance != 'NA' &&
                    cnaReportedVariant.significance != 'Not Classified'
                  "
                  style="padding-left: 3px !important"
                >
                  {{ cnaReportedVariant.significance }}
                </td>
                <td
                  *ngIf="
                    cnaReportedVariant.significance != 'NA' &&
                    cnaReportedVariant.significance != 'Not Classified'
                  "
                  style="padding-left: 3px !important"
                >
                  Copy Number
                  {{ cnaReportedVariant.sample.split(":")[2] | titlecase }}
                  ({{ cnaReportedVariant.sample.split("|")[1].split(":")[0] }})
                </td>
                <td
                  *ngIf="
                    cnaReportedVariant.significance != 'NA' &&
                    cnaReportedVariant.significance != 'Not Classified'
                  "
                  style="padding-left: 3px !important"
                >
                  {{ cnaReportedVariant.reference }}
                </td>
              </tr>
              <!-- Fusion -->
              <tr
                *ngFor="
                  let fusionReportedVariant of reportPdfRes.fusionReportedVariantList
                "
              >
                <td
                  *ngIf="
                    fusionReportedVariant.significance != 'NA' &&
                    fusionReportedVariant.significance != 'Not Classified'
                  "
                  style="padding-left: 3px !important"
                >
                  <i>{{ fusionReportedVariant.fusionGene }}</i>
                </td>
                <td
                  *ngIf="
                    fusionReportedVariant.significance != 'NA' &&
                    fusionReportedVariant.significance != 'Not Classified'
                  "
                  style="padding-left: 3px !important"
                >
                  {{ fusionReportedVariant.leftBreakPoint }}<br />{{
                    fusionReportedVariant.rightBreakPoint
                  }}
                </td>
                <td
                  *ngIf="
                    fusionReportedVariant.significance != 'NA' &&
                    fusionReportedVariant.significance != 'Not Classified'
                  "
                  style="padding-left: 3px !important"
                >
                  NA
                </td>
                <td
                  *ngIf="
                    fusionReportedVariant.significance != 'NA' &&
                    fusionReportedVariant.significance != 'Not Classified'
                  "
                  style="padding-left: 3px !important"
                >
                  {{ fusionReportedVariant.significance }}
                </td>
                <td
                  *ngIf="
                    fusionReportedVariant.significance != 'NA' &&
                    fusionReportedVariant.significance != 'Not Classified'
                  "
                  style="padding-left: 3px !important"
                >
                  Gene Fusions
                </td>
                <td
                  *ngIf="
                    fusionReportedVariant.significance != 'NA' &&
                    fusionReportedVariant.significance != 'Not Classified'
                  "
                  style="padding-left: 3px !important"
                >
                  {{ fusionReportedVariant.reference }}
                </td>
              </tr>
            </table>
            <p
              *ngIf="
                otherCountSNV == true ||
                reportPdfRes.allVariantsList.length > 0
              "
              style="font-size: 13px !important"
              class="mt-3"
            >
              <i> *NA: Not Applicable</i>
            </p>
          </div>
        </div>

        <div class="row mt-5" *ngIf="
                  !variantFlag &&
                  report.addNotes != null && report.addNotes != '<div></div>'
                ">
          <div class="col">
            <h6 class="py-2 h6_bg">Additional Notes</h6>
            <div class="py-2" [innerHtml]="report.addNotes" style="text-align: justify; font-size: 14px !important"></div>
          </div>
        </div>

        <!--  -->
        <div class="row footer d-flex justify-content-between align-items-center">
          <div class="col">
            <div class="py-2">
              <p>
                <span class="font-weight-bold">Genomics Bihar,</span>
              </p>
              <p>Homi Bhabha cancer hospital & Research centre</p>
              <p>Inside Srikrishna Medical College & Hospital</p>
              <p>Umanagar, Muzaffarpur-842004.</p>
              <p>Contact: ngs.mfp@gmail.com</p>
            </div>
          </div>
          <div class="col">
            <p class="text-right">
              <img
                src="../../../assets/tmc/tmc_footer.png"
                alt="footer_image"
                class="footer_img"
              />
            </p>
          </div>
        </div>
      </div>


      <!--  -->

      <!-- page 3 -->
      <!-- <div id="page3" *ngIf="reportPdfRes.drugsResList.length > 0" class="container position-relative page"
        style="background-color: white">
        <div class="d-flex justify-content-between logo_panel py-3">
          <div class="logo_part">
            <img src="../../../assets/tmc/tmc_log1.png" alt="" class="logo_first" />
          </div>
          <div class="logo_part text-center">
            <img src="../../../assets/tmc/tmc_logo2.png" alt="" class="logo_two" />
            <p style="font-size: 10px">
              An Initiative of Bihar Govt. & Doctors For You
            </p>
            <p class="text-uppercase">MEDICAL GENOMICS & MOLECULAR ONCOLOGY</p>
          </div>
          <div class="logo_part">
            <img src="../../../assets/tmc/tmc_logo3.jpg" alt="" class="logo_three" />
          </div>
        </div>
        <div class="row header mt-1">
          <div>

          </div>
          <div></div>
          <div class="col">
            <div class="patient-detailes ml-auto float-right">
              <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
                <li>
                  Patient Id <br />
                  <span>{{ report.patient?.organisationPatientId }}</span>
                </li>
                <li class="ml-4">
                  Patient Name<br />
                  <span>{{ report.patient?.name }}</span>
                </li>
              </ul>
            </div>
          </div>
          <div *ngIf="report.clientType == '4basecare_client'">
            <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
              <div class="logo">
                <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
              </div>
            </div>
            <div *ngIf="report.clientName == 'path_labs'" class="col-3">
              <div class="logo">
                <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
              </div>
            </div>
          </div>
        </div>
        <div class="mar_top"></div>

        <h6 class="py-2 h6_bg" [ngClass]="'skyBlue'">
          Alterations With Clinically Relevant Therapies
        </h6>
        <div class="row">
          <div class="col-12">
            <table class="page_1 cust_table w-100 mt-3 first_2 custom-table" [ngClass]="'bg_cyan'">
              <thead>
                <tr>
                  <th>Gene (Alterations) - Drug association</th>
                  <th>Summary</th>
                </tr>
              </thead>

              <tbody>
                <tr *ngFor="
                                                                  let drugsRes of reportPdfRes.drugsResList | slice : 0 : 4;
                                                                  let i = index
                                                                ">
                  <td>
                    <ul class="ml-0 pl-2">
                      <li>
                        <i>{{ drugsRes.gene.replace("Fusion","") }}</i><span *ngIf="drugsRes.gene.includes('Fusion')">Fusion</span> {{ drugsRes.pposition?.replace(' ','') }} :
                        <br />
                        <span *ngFor="let drug of drugsRes.drugs; let i = index">
                          {{ drug.name
                          }}<span *ngIf="drugsRes.drugs.length - 1 != i">,</span>
                        </span>
                        - {{ drugsRes.association }}
                        <p>{{ drugsRes.cancerType }}</p>
                      </li>
                    </ul>
                  </td>
                  <td>
                    <div [innerHtml]="drugsRes.summary"></div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div class="row footer d-flex justify-content-between align-items-center">
          <div class="col">
            <div class="py-2">
              <p>
                <span class="font-weight-bold">Genomics Bihar,</span>
              </p>
              <p>Homi Bhabha cancer hospital & Research centre</p>
              <p>Inside Srikrishna Medical College & Hospital</p>
              <p>Umanagar, Muzaffarpur-842004.</p>
              <p>Contact: ngs.mfp@gmail.com</p>
            </div>
          </div>
          <div class="col">
            <p class="text-right">
              <img src="../../../assets/tmc/tmc_footer.png" alt="footer_image" class="footer_img" />
            </p>
          </div>
        </div>
      </div> -->

      <!-- page 3_1 -->

      <!-- page 3_2 -->


      <!-- page 3_3 -->


      <!-- page 3_4 -->

      <!-- page 3_5 -->


      <!-- page 3_6 -->


      <!-- page 3_7 -->


      <!-- page 3_8 -->


      <!-- page 3_9 -->


      <!-- test -->


      <div
        id="page4"
        class="container position-relative page"
        style="background-color: white"
      >
      <div class="d-flex justify-content-between logo_panel py-3">
        <div class="logo_part">
          <img
            src="../../../assets/tmc/tmc_log1.png"
            alt=""
            class="logo_first"
          />
        </div>
        <div class="logo_part text-center">
          <img
            src="../../../assets/tmc/tmc_logo2.png"
            alt=""
            class="logo_two"
          />
          <p style="font-size: 10px">
            An Initiative of Bihar Govt. & Doctors For You
          </p>
          <p class="text-uppercase">MEDICAL GENOMICS & MOLECULAR ONCOLOGY</p>
        </div>
        <div class="logo_part">
          <img
            src="../../../assets/tmc/tmc_logo3.jpg"
            alt=""
            class="logo_three"
          />
        </div>
      </div>
        <div class="row header mt-1">
          <div>
            <!-- <div class="col-3">
              <img src="../../../assets/logo.png" alt="" style="width: 140px" />
            </div> -->
          </div>
          <div></div>
          <div class="col">
            <div class="patient-detailes ml-auto float-right">
              <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
                <li>
                  Patient Id <br />
                  <span>{{ report.patient?.organisationPatientId }}</span>
                </li>
                <li class="ml-4">
                  Patient Name<br />
                  <span>{{ report.patient?.name }}</span>
                </li>
              </ul>
            </div>
          </div>
          <!--  -->
          <div *ngIf="report.clientType == '4basecare_client'">
            <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
              <div class="logo">
                <img
                  src="../../../assets/oncoquest logo.png"
                  alt=""
                  style="width: 100px"
                />
              </div>
            </div>
            <div *ngIf="report.clientName == 'path_labs'" class="col-3">
              <div class="logo">
                <img
                  src="../../../assets/pathlab.png"
                  alt=""
                  style="width: 100px"
                />
              </div>
            </div>
          </div>
        </div>
        <!--  -->
        <div class="mar_top"></div>
        <!--  -->

        <h6 class="py-2 h6_bg" [ngClass]="'skyBlue'">References</h6>
        <!--  -->
        <div class="row">
          <div class="col">
            <div class="disc">
              <div class="ref_data mt-4">
                <ul class="break-word" style="text-align: justify">
                  <li
                    *ngFor="let referenceArticle of reportPdfRes.referenceArticles"
                    style="
                      text-align: justify;
                      /* white-space:pre-wrap; */
                      word-break: break-word;
                    "
                  >
                    {{ referenceArticle }}
                  </li>
                </ul>
              </div>
            </div>

            <!-- <div class="img_signs">
            <img src="../../../assets/signatures.png" alt="" class="img-fluid">
          </div> -->
            <!--  -->

            <!--  -->
            <!-- <div class="mt-4">
            <h6 class="mt-3 mb-4 py-2 h6_bg" >
              GENES EVALUATED </h6>
             <p><span class="font-weight-normal">taR</span><b>GT</b> <span class="clr-cyan pl-2">First</span> detects mutations (SNVs and Short Indels), Copy Number Variations (CNVs), gene fusions and splice variants in the 53 genes :</p>
          </div> -->

            <!-- <div class="row mx-0">
            <div class="col-7 px-0">
              <div class="genes_info">
                <div class="title py-2">
                  <h6 class="mb-0 d-inline-block border-bottom  py-2"><b>SNVs/InDels</b> Coverage in <span class="font-weight-normal">TAR</span><b>GT</b> <span class="clr-cyan pl-2">First</span></h6></div>
             <div class="gene_body">
              <div class="gene_list"><span>ABL1</span><span>ALK</span><span>AR</span><span>ATM</span><span>BARD1</span><span>BRAF</span><span>BRCA1</span><span>BRCA2</span><span>BRIP1</span><span>CDK12</span></div>
              <div class="gene_list"><span>CDK4</span><span>CDK6</span><span>CDKN2A</span><span>CHEK1</span><span>CHEK2</span><span>EGFR</span><span>EPCAM</span><span>ERBB2</span><span>ERBB3</span><span>EZH2</span></div>
              <div class="gene_list"><span>FANCL</span><span>FGFR3</span><span>GAPDH</span><span>IDH1</span><span>IDH2</span><span>JAK2</span><span>KIT</span><span>KRAS</span><span>MAP2K1</span><span>MAP2K2</span></div>
              <div class="gene_list"><span>MDM2</span><span>MET</span><span>MLH1</span><span>MLH3</span><span>MSH2</span><span>MSH6</span><span>NRAS</span><span>PALB2</span><span>PDGFRA</span><span>PDGFRB</span></div>
              <div class="gene_list"><span>PIK3CA</span><span>PMS1</span><span>PMS2</span><span>PPP2R2A</span><span>RAD51B</span><span>RAD51C</span><span>RAD51D</span><span>RAD54L</span><span>RET</span><span>ROS1</span></div>
              <div class="gene_list"><span>MDM2</span><span>MET</span><span>MLH1</span></div>
             </div>
                </div>
            </div>
            <div class="col">
              <div class="title py-2">
                <h6 class=" mb-0 d-inline-block border-bottom  py-2"><b>Gene Fusions </b> Coverage in <span class="font-weight-normal">TAR</span><b>GT</b> <span class="clr-cyan pl-2">First</span></h6></div>
            </div>


          </div> -->
          </div>
        </div>
        <div class="row pr-2 above-footer">
          <div class="col pl-5">
            <img
              src="../../../assets/tmc/basusigns1.png"
              alt=""
              class="mb-2 sign_width"
            />
            <div class="sign_text">
              <p>Dr. Moitri Basu, Ph.D.</p>
              <p>Scientific Officer</p>
              <p>Homi Bhabha cancer hospital & Research centre, <br/>Muzaffarpur, A unit of Tata Memorial centre.</p>
            </div>

          </div>
        </div>


        <!--  -->


        <div class="row footer d-flex justify-content-between align-items-center">

          <div class="col">
            <div class="py-2">
              <p>
                <span class="font-weight-bold">Genomics Bihar,</span>
              </p>
              <p>Homi Bhabha cancer hospital & Research centre</p>
              <p>Inside Srikrishna Medical College & Hospital</p>
              <p>Umanagar, Muzaffarpur-842004.</p>
              <p>Contact: ngs.mfp@gmail.com</p>
            </div>
          </div>
          <div class="col">
            <p class="text-right">
              <img
                src="../../../assets/tmc/tmc_footer.png"
                alt="footer_image"
                class="footer_img"
              />
            </p>
          </div>
        </div>

      </div>

      <div
      id="page4_1"
      class="container position-relative page"
      style="background-color: white"
    >
    <div class="d-flex justify-content-between logo_panel py-3">
      <div class="logo_part">
        <img
          src="../../../assets/tmc/tmc_log1.png"
          alt=""
          class="logo_first"
        />
      </div>
      <div class="logo_part text-center">
        <img
          src="../../../assets/tmc/tmc_logo2.png"
          alt=""
          class="logo_two"
        />
        <p style="font-size: 10px">
          An Initiative of Bihar Govt. & Doctors For You
        </p>
        <p class="text-uppercase">MEDICAL GENOMICS & MOLECULAR ONCOLOGY</p>
      </div>
      <div class="logo_part">
        <img
          src="../../../assets/tmc/tmc_logo3.jpg"
          alt=""
          class="logo_three"
        />
      </div>
    </div>
      <div class="row header mt-1">
        <div>
          <!-- <div class="col-3">
            <img src="../../../assets/logo.png" alt="" style="width: 140px" />
          </div> -->
        </div>
        <div></div>
        <div class="col">
          <div class="patient-detailes ml-auto float-right">
            <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
              <li>
                Patient Id <br />
                <span>{{ report.patient?.organisationPatientId }}</span>
              </li>
              <li class="ml-4">
                Patient Name<br />
                <span>{{ report.patient?.name }}</span>
              </li>
            </ul>
          </div>
        </div>
        <!--  -->
        <div *ngIf="report.clientType == '4basecare_client'">
          <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
            <div class="logo">
              <img
                src="../../../assets/oncoquest logo.png"
                alt=""
                style="width: 100px"
              />
            </div>
          </div>
          <div *ngIf="report.clientName == 'path_labs'" class="col-3">
            <div class="logo">
              <img
                src="../../../assets/pathlab.png"
                alt=""
                style="width: 100px"
              />
            </div>
          </div>
        </div>
      </div>
      <!--  -->
      <div class="mar_top"></div>
      <!--  -->

      <div class="">
        <h6 class="py-2 h6_bg" [ngClass]="'skyBlue'">TEST DESCRIPTION</h6>
        <p class="mb-2" style="text-align:justify">
          <span class="font-weight-normal">TAR</span><b>GT</b>
          <span class="clr-cyan pl-2" [ngClass]="'txt-skyblue'">First Solid</span>
          is a Next Generation Sequencing based test which identifies
          genetic alterations in a comprehensive panel of well curated 72  genes which are having an impact response to approved
           therapy for a particular cancer type. Some of the alterations
           detected may have bearing on prognosis and/or therapeutic options
            and may provide relevant information that allows
            oncologists/clinicians to consider various lines of
            targeted treatment for the patient.
        </p>
      </div>

      <!--  -->
      <div class="row">
        <div class="col">
          <div class="">
            <h6 class="py-2 h6_bg" [ngClass]="'skyBlue'">GENES EVALUATED</h6>
            <p>
              <span class="font-weight-normal">TAR</span><b>GT</b>
              <span class="clr-cyan pl-2" [ngClass]="'txt-skyblue'">First Solid</span>
              detects mutations (SNVs and Short InDels), Copy Number Variations (CNVs),
              Gene Fusions and splice variants in the 72 genes:
              <!-- detects mutations (SNVs and Short Indels), Copy Number Variations
              (CNVs), gene fusions and splice variants in the 53 genes : -->
            </p>
          </div>
          <!--  -->
          <div class="row mx-0">
            <div class="col-12 px-0">
              <div class="genes_info">
                <div class="title py-2">
                   <h6 class="mb-0 d-inline-block border-bottom py-2">
                    <b>SNVs, SHORT INDELS and CNVs
                    </b> Covered in
                    <span class="font-weight-normal">TAR</span><b>GT</b>
                    <span class="clr-cyan pl-2" [ngClass]="'txt-skyblue'"
                      >First Solid</span
                    >
                  </h6>
                </div>
                <div class="gene_body" [ngClass]="'gene-bg_blue'">
                  <i>
                    <div class="gene_list">
                      <span>ABL1</span><span>ALK</span><span>APC</span>
                      <span>AR</span><span>ATM</span><span>BARD1</span>
                      <span>BMPR1A</span><span>BRAF</span><span>BRCA1</span>
                      <span>BRCA2</span>
                      <!-- <span>ABL1</span><span>ALK</span><span>AR</span
                      ><span>ATM</span><span>BARD1</span><span>BRAF</span
                      ><span>BRCA1</span><span>BRCA2</span><span>BRIP1</span
                      ><span>CDK12</span> -->
                    </div>
                    <div class="gene_list bg-white">
                      <span>BRIP1</span><span>CDK12</span><span>CDK4</span>
                      <span>CDK6</span><span>CDKN2A</span><span>CHEK1</span>
                      <span>CHEK2</span><span>CTNNB1</span><span>EGFR</span>
                      <span>EPCAM</span>
                      <!-- <span>CDK4</span><span>CDK6</span><span>CDKN2A</span
                      ><span>CHEK1</span><span>CHEK2</span><span>EGFR</span
                      ><span>EPCAM</span><span>ERBB2</span><span>ERBB3</span
                      ><span>EZH2</span> -->
                    </div>
                    <div class="gene_list">
                      <span>ERBB2</span><span>ERBB3</span><span>EZH2</span>
                      <span>FANCL</span><span>FGFR1</span><span>FGFR2</span>
                      <span>FGFR3</span><span>GAPDH</span><span>IDH1</span>
                      <span>IDH2</span>
                      <!-- <span>FANCL</span><span>FGFR3</span><span>GAPDH</span
                      ><span>IDH1</span><span>IDH2</span><span>JAK2</span
                      ><span>KIT</span><span>KRAS</span><span>MAP2K1</span
                      ><span>MAP2K2</span> -->
                    </div>
                    <div class="gene_list">
                      <span>JAK2</span><span>KIT</span><span>KRAS</span>
                      <span>MAP2K1</span><span>MAP2K2</span><span>MDM2</span>
                      <span>MET</span><span>MLH1</span><span>MLH3</span>
                      <span>MSH2</span>
                      <!-- <span>MDM2</span><span>MET</span><span>MLH1</span
                      ><span>MLH3</span><span>MSH2</span><span>MSH6</span
                      ><span>NRAS</span><span>PALB2</span><span>PDGFRA</span
                      ><span>PDGFRB</span> -->
                    </div>
                    <div class="gene_list">
                      <span>MSH6</span><span>MUTYH</span><span>NRAS</span>
                      <span>PALB2</span><span>PDGFRA</span><span>PDGFRB</span>
                      <span>PIK3CA</span><span>PMS1</span><span>PMS2</span>
                      <span>POLD1</span>
                      <!-- <span>PIK3CA</span><span>PMS1</span><span>PMS2</span
                      ><span>PPP2R2A</span><span>RAD51B</span><span>RAD51C</span
                      ><span>RAD51D</span><span>RAD54L</span><span>RET</span
                      ><span>ROS1</span> -->
                    </div>
                    <div class="gene_list">
                      <span>POLE</span><span>POLH</span><span>PTEN</span>
                      <span>RAD50</span><span>RAD51</span><span>RAD51B</span>
                      <span>RAD51C</span><span>RAD51D</span><span>RAD54L</span>
                      <span>RB1</span>
                      <!-- <span>STK11</span><span>TSC1</span><span>TSC2</span> -->
                    </div>
                    <div class="gene_list">
                      <span>RET</span><span>ROS1</span><span>SMAD4</span>
                      <span>STK11</span><span>TP53</span><span>TSC1</span>
                      <span>TSC2</span>
                      <!-- <span>STK11</span><span>TSC1</span><span>TSC2</span> -->
                    </div>
                  </i>
                </div>

                <!-- <div class="title py-2 mt-4">
                  <h6 class="mb-0 d-inline-block border-bottom py-2">
                    <b>CNAs</b> Covered in
                    <span class="font-weight-normal">TAR</span><b>GT</b>
                    <span class="clr-cyan pl-2" [ngClass]="'txt-skyblue'"
                      >First</span
                    >
                  </h6>
                </div> -->
                <!-- <div class="gene_body" [ngClass]="'gene-bg_blue'">
                  <i>
                    <div class="gene_list">
                      <span>ABL1</span><span>AR</span><span>ATM</span
                      ><span>BARD1</span><span>BRCA1</span><span>BRCA2</span
                      ><span>BRIP1</span><span>CDK12</span><span>CDK4</span
                      ><span>CDK6</span>
                    </div>
                    <div class="gene_list">
                      <span>CDKN2A</span><span>CHEK1</span><span>CHEK2</span
                      ><span>EGFR</span><span>EPCAM</span><span>ERBB2</span
                      ><span>ERBB3</span><span>EZH2</span><span>FANCL</span
                      ><span>FGFR3</span>
                    </div>
                    <div class="gene_list">
                      <span>GAPDH</span><span>IDH2</span><span>JAK2</span
                      ><span>KIT</span><span>MDM2</span><span>NRAS</span
                      ><span>PALB2</span><span>PDGFRA</span><span>PDGFRB</span
                      ><span>PPP2R2A</span>
                    </div>
                    <div class="gene_list">
                      <span>RAD51B</span><span>RAD51C</span><span>RAD51D</span
                      ><span>RAD54L</span><span>STK11</span><span>TSC1</span
                      ><span>TSC2</span>
                    </div>
                  </i>
                </div> -->
              </div>
            </div>
            <!-- cnv_genes -->

            <!-- <div class="col-12 px-0">
              <div class="genes_info">
                <div class="title py-2">
                  <h6 class="mb-0 d-inline-block border-bottom py-2">
                    <b> CNVs
                    </b> Covered in
                    <span class="font-weight-normal">TAR</span><b>GT</b>
                    <span class="clr-cyan pl-2" [ngClass]="'txt-skyblue'"
                      >First Solid</span
                    >
                  </h6>
                </div>
                <div class="gene_body" [ngClass]="'gene-bg_blue'">
                  <i>
                    <div class="gene_list">
                      <span>ABL1</span><span>ALK</span><span>APC</span>
                      <span>AR</span><span>ATM</span><span>BARD1</span>
                      <span>BMPR1A</span><span>BRAF</span><span>BRCA1</span>
                      <span>BRCA2</span>
                    </div>
                    <div class="gene_list bg-white">
                      <span>BRIP1</span><span>CDK12</span><span>CDK4</span>
                      <span>CDK6</span><span>CDKN2A</span><span>CHEK1</span>
                      <span>CHEK2</span><span>CTNNB1</span><span>EGFR</span>
                      <span>EPCAM</span>
                    </div>
                    <div class="gene_list">
                      <span>ERBB2</span><span>ERBB3</span><span>EZH2</span>
                      <span>FANCL</span><span>FGFR1</span><span>FGFR2</span>
                      <span>FGFR3</span><span>GAPDH</span><span>IDH2</span>
                      <span>JAK2</span>
                    </div>
                    <div class="gene_list">
                     <span>KIT</span><span>MDM2</span>
                      <span>MET</span><span>MLH1</span><span>MUTYH</span>
                      <span>NRAS</span><span>PALB2</span><span>PDGFRA</span>
                      <span>PDGFRAB</span><span>POLD1</span>
                    </div>

                    <div class="gene_list">
                      <span>POLE</span><span>POLH</span><span>PTEN</span>
                      <span>RAD50</span><span>RAD51</span><span>RAD51B</span>
                      <span>RAD51C</span><span>RAD51D</span><span>RAD54L</span>
                      <span>RB1</span>
                    </div>
                    <div class="gene_list">
                      <span>RET</span><span>ROS1</span><span>SMAD4</span>
                      <span>STK11</span><span>TP53</span><span>TSC1</span>
                      <span>TSC2</span>
                    </div>
                  </i>
                </div>


              </div>
            </div> -->




            <div class="col-12 px-0">
              <div class="genes_info">
                <div class="title py-2">
                  <h6 class="mb-0 d-inline-block border-bottom py-2">
                    <b>Gene Fusions/Splicing Variations</b> Covered in
                  <span class="font-weight-normal">TAR</span><b>GT</b>
                  <span class="clr-cyan pl-2" [ngClass]="'txt-skyblue'"
                    >First Solid</span
                  >
                  </h6>
                </div>
                <div class="gene_body" [ngClass]="'gene-bg_blue'">
                  <i>
                    <div class="gene_list">
                    <span>ALK</span><span>FGFR2</span><span>FGFR3</span>
                    <span>MET</span><span>NRG1</span><span>NRG2</span>
                    <span>NTRK1</span><span>NTRK2</span> <span>NTRK3</span><span>RET</span>

                    </div>
                    <div class="gene_list">
                      <span>ROS1</span>
                    </div>
                  </i>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>




      <!--  -->
      <div class="row footer d-flex justify-content-between align-items-center">
        <div class="col">
          <div class="py-2">
            <p>
              <span class="font-weight-bold">Genomics Bihar,</span>
            </p>
            <p>Homi Bhabha cancer hospital & Research centre</p>
            <p>Inside Srikrishna Medical College & Hospital</p>
            <p>Umanagar, Muzaffarpur-842004.</p>
            <p>Contact: ngs.mfp@gmail.com</p>
          </div>
        </div>
        <div class="col">
          <p class="text-right">
            <img
              src="../../../assets/tmc/tmc_footer.png"
              alt="footer_image"
              class="footer_img"
            />
          </p>
        </div>
      </div>
    </div>

      <div
        id="page5"
        class="container position-relative page"
        style="background-color: white"
      >
      <div class="d-flex justify-content-between logo_panel py-3">
        <div class="logo_part">
          <img
            src="../../../assets/tmc/tmc_log1.png"
            alt=""
            class="logo_first"
          />
        </div>
        <div class="logo_part text-center">
          <img
            src="../../../assets/tmc/tmc_logo2.png"
            alt=""
            class="logo_two"
          />
          <p style="font-size: 10px">
            An Initiative of Bihar Govt. & Doctors For You
          </p>
          <p class="text-uppercase">MEDICAL GENOMICS & MOLECULAR ONCOLOGY</p>
        </div>
        <div class="logo_part">
          <img
            src="../../../assets/tmc/tmc_logo3.jpg"
            alt=""
            class="logo_three"
          />
        </div>
      </div>
        <div class="row header mt-1">
          <div>
            <!-- <div class="col-3">
              <img src="../../../assets/logo.png" alt="" style="width: 140px" />
            </div> -->
          </div>
          <div></div>
          <div class="col">
            <div class="patient-detailes ml-auto float-right">
              <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
                <li>
                  Patient Id <br />
                  <span>{{ report.patient?.organisationPatientId }}</span>
                </li>
                <li class="ml-4">
                  Patient Name<br />
                  <span>{{ report.patient?.name }}</span>
                </li>
              </ul>
            </div>
          </div>
          <!--  -->
          <div *ngIf="report.clientType == '4basecare_client'">
            <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
              <div class="logo">
                <img
                  src="../../../assets/oncoquest logo.png"
                  alt=""
                  style="width: 100px"
                />
              </div>
            </div>
            <div *ngIf="report.clientName == 'path_labs'" class="col-3">
              <div class="logo">
                <img
                  src="../../../assets/pathlab.png"
                  alt=""
                  style="width: 100px"
                />
              </div>
            </div>
          </div>
        </div>
        <!--  -->
        <div class="mar_top"></div>
        <!--  -->
        <h6 class="py-2 h6_bg mt-3" [ngClass]="'skyBlue'">TEST METHODOLOGY</h6>
        <!--  -->
        <div class="row mt-3">
          <div class="col">
            <h6 class="font-weight-bold">
              Sample preparation and Library preparation :
            </h6>
            <!-- <p>
              DNA isolated from FFPE, or any other fresh tumor tissue source was
              used to perform targeted gene capture using a custom capture kit.
              The libraries were sequenced to mean >350X coverage on Illumina
              sequencing platform.
            </p> -->
            <p style="text-align: justify;">
              DNA isolated from FFPE, or any other fresh tumor tissue source was used to perform targeted gene capture using a custom capture kit. The libraries were sequenced to mean
              >350X coverage on Illumina sequencing platform.
            </p>
          </div>
        </div>
        <!--  -->
        <div class="row mt-4">
          <div class="col">
            <h6 class="font-weight-bold">
              Bioinformatics Analysis and Reporting :
            </h6>
            <!-- <p>
              The sequences obtained are aligned to human reference genome
              (GRCh37/hg19) and variant analysis was performed using set of
              Bioinformatics Pipeline. Only non-synonymous and splice site
              variants found in the panel consisting of specific set of genes were
              used for clinical interpretation. Silent variations that do not
              result in any change in amino acid in the coding region are not
              reported. Clinically relevant mutations were annotated using
              published variants in literature and a set of databases – ClinVar,
              COSMIC and dbSNP. Common variants are filtered based on allele
              frequency in 1000 Genome Phase 3, ExAC, dbSNP, gnomAD, etc. In the
              absence of a clinically significant reported known variation(s),
              pathogenicity will be predicted based on <i>in-silico</i> gene
              prioritization tools: CADD, SIFT, PolyPhen-2, Condel and Mutation
              taster and prioritized for clinical correlation. The identified
              pathogenic variant will be correlated with observed phenotypic
              features of the patient and interpreted according to American
              College of Medical Genetics (ACMG) guidelines.
            </p>
            <p class="mb-2 d-block">
              Somatic variants are classified into three tiers based on their level
              of clinical significance in cancer diagnosis, prognosis, and/or
              therapeutics as per international guidelines: ACMG, ASCO, AMP, CAP,
              NCCN and ESMO
            </p> -->
            <p style="text-align: justify;">
              The sequences obtained are aligned to human reference genome (GRCh37/hg19) and variant analysis was performed using set of Bioinformatics Pipeline. Only non-synonymous and splice site variants found in the panel consisting of specific set of genes were used for clinical interpretation. Silent variations that do not result in any change
              in amino acid in the coding region are not reported. Clinically relevant mutations were annotated using published variants in literature and a set of databases – ClinVar, COSMIC
              and dbSNP. Common variants are filtered based on allele frequency in 1000 Genome Phase 3, ExAC, dbSNP, gnomAD, etc. In the absence of a clinically significant reported
              known variation(s), pathogenicity will be predicted based on <i>in-silico</i> gene prioritization tools: CADD, SIFT, PolyPhen-2, Condel and Mutation taster and prioritized for clinical
              correlation. The identified pathogenic variant will be correlated with observed phenotypic features of the patient and interpreted according to ACMG/AMP guidelines.
            </p>
            <p class="mb-2 d-block" style="text-align: justify;">
              Somatic variants are classified into three tiers based on their level of clinical significance in cancer diagnosis, prognosis, and/or therapeutics as per international guidelines:
              ACMG, ASCO, AMP, CAP, NCCN and ESMO.
            </p>
          </div>
        </div>

        <h6 class="py-2 h6_bg mt-2" [ngClass]="'skyBlue'">
          LIMITATIONS AND DISCLAIMER
        </h6>

        <div class="row">
          <div class="col">
            <div class="disc">
              <ul class="ml-0 pl-4"  style="text-align: justify;">
                <li>
                  TARGT FIRST SOLID test has been developed, validated and performed by 4baseCare Oncosolutions Pvt. Ltd and has not been cleared or approved by the FDA.
                </li>
                <li>
                  DNA studies do not constitute a definitive test for the selected condition(s) in all individuals. It should be realized that there are possible sources of error. Errors can result
                  from trace contamination, rare technical errors, rare genetic variants that interfere with analysis, recent scientific developments, and alternative classification systems. This
                  test should be one of the many aspects used by the healthcare provider to help with a diagnosis and treatment plan.
                </li>
                <li>
                  We are using the canonical transcript for clinical reporting which is usually the longest coding transcript with strong/multiple supporting evidence. However, in rare cases,
                  clinically relevant variants annotated in alternate complete coding transcripts could also be reported.
                </li>
                <li>
                  The contents of this test should be carefully assessed by the treating physician and further interpreted along with clinical, histopathological findings, contraindications and
                  guidelines before deciding the course of therapy.
                </li>
                <li>
                  The CNVs detected must be confirmed by an alternate method, such as IHC, for further clinical management decisions.
                </li>
                <!-- <li>
                  The sensitivity of this assay to detect large deletions/duplications of more than 10 bp or copy number variations (CNV) is 70-75%. The CNVs detected have to be confirmed
                  by alternate method.
                </li> -->
                <li>
                  Most recent block is recommended for testing as the mutation profile may change in response to treatment and hence differ at different sampling points.
                </li>
                <li>
                  The identified pathogenic variant will be correlated with observed phenotypic features of the patient and interpreted according to AMP guidelines.
                </li>
                <li>
                  Certain genes may not be covered completely, and few mutations could be missed. A negative result cannot rule out the possibility that the tested tumor sample carries
                  mutations not previously associated with cancer and hence not included in the panel.
                </li>
                <li>
                  Our limit of detection for TARGT FIRST SOLID is 5% for SNVs, 10% for InDels and CNV gain &#8805; 6. In addition to this, sequencing quality and coverage is dependent on many factors
                  such as homopolymers, GC-rich regions, intrinsic quality of FFPE DNA and that might impact the variant detection.
                </li>
              </ul>
            </div>
          </div>
        </div>

        <!--  -->
        <div class="row footer d-flex justify-content-between align-items-center">
          <div class="col">
            <div class="py-2">
              <p>
                <span class="font-weight-bold">Genomics Bihar,</span>
              </p>
              <p>Homi Bhabha cancer hospital & Research centre</p>
              <p>Inside Srikrishna Medical College & Hospital</p>
              <p>Umanagar, Muzaffarpur-842004.</p>
              <p>Contact: ngs.mfp@gmail.com</p>
            </div>
          </div>
          <div class="col">
            <p class="text-right">
              <img
                src="../../../assets/tmc/tmc_footer.png"
                alt="footer_image"
                class="footer_img"
              />
            </p>
          </div>
        </div>
      </div>

    </div>

        <!-- For HGC -->
        <div class="hcg" *ngIf="!is4basecare && isHCG && temp=='no_temp'">

          <div id="page1" class="container position-relative page" style="background-color: white">
            <div class="d-flex justify-content-between logo_panel py-3">
              <div class="logo_part">
                <img
                style="width:240px!important" src="../../../assets/hcg-new-logo.jpeg"
                  alt=""
                  class="logo_first"
                />
              </div>
              <div style=" border-left: 2px solid #fab216;padding-top:10px;
              height: 120px;"></div>

              <div class="logo_part text-center" style="font-family:arial Mt!important">

                <h5 style="font-weight: bolder;margin-bottom:0px!important;">Triesta Sciences</h5>
                <p style="font-size: 15px!important;">
                  A Unit of Health Care Global Enterprises Ltd <br/>
                  5th Floor, Tower 1, #8, P.Kalinga Rao Road,<br/>
                  Sampangiram Nagar, Bengaluru-560027. Tel: 080-40206305
                </p>
                <h6 style="font-weight: bolder;font-size:15px!important">Department of Molecular and Clinical Genomics</h6>
              </div>
              <div style=" border-left: 2px solid #fab216;padding-top:10px;
              height: 120px;"></div>
              <div class="logo_part">
                <img  style="width:240px!important; margin-top:40px!important"
                  src="../../../assets//oncoquer1.png"
                  alt=""
                  class="logo_three"
                />
              </div>
            </div>

            <div class="row header mt-1">
              <div>
                <div class="col-3 pl-0">

                </div>
              </div>
              <div>

              </div>
              <div class="col">

              </div>
            </div>
            <!--  -->
            <div class="mar_top"></div>
            <!--  -->
            <div class="row">
              <div class="col-7">
                <div class="">
                  <div class="w-50" style="min-height: 80px">
                    <p class="py-1 font-weight-bold" [ngClass]="'txt-black'">
                      SCOPE OF THE TEST
                    </p>
                    <p>SNVs, InDels, CNAs, Gene Fusions status</p>
                  </div>


                  <!-- <div style=" border-bottom: 3px solid #fab216;padding-top:10px; width: 610px;"></div> -->
                  <!-- <img src="../../../assets/BorderLine.png" class="mr-2 w-100" /> -->
                </div>
              </div>
              <div class="col">
                <div class="">
                  <div class="w-100" style="min-height: 80px">
                    <div class="w-50" style="min-height: 80px">
                      <p class="py-1 font-weight-bold" [ngClass]="'txt-black'" >
                        NAME OF THE TEST
                      </p>
                      <p> OncQuer<sup>TM</sup> Assist Panel</p>
                    </div>
                    <!-- <p [ngClass]="'txt-yellow'" class="py-1 font-weight-bold" *ngIf="report.clinicalSummary != null">
                      <span *ngIf="report.clinicalSummary != ' '">CLINICAL INDICATION</span>
                    </p>

                    <p *ngIf="report.clinicalSummary != null">
                      <span style="color: #000 !important" *ngIf="report.clinicalSummary != ' '">{{ report.clinicalSummary
                        }}</span>
                    </p> -->
                  </div>
                  <!-- <div class="w-100" style="min-height: 80px">
                    <p [ngClass]="'txt-yellow'">
                    </p>

                    <p>

                    </p>
                  </div> -->
                  <!-- <div style=" border-bottom: 3px solid #fab216;padding-top:10px; width: 430px;"></div> -->
                  <!-- <img src="../../../assets/BorderLine.png" class="mr-2 w-100" /> -->
                </div>
              </div>
            </div>
            <div class="text-center" style=" border-bottom: 3px solid #fab216;padding-right: 10px;
            width: 1074px;
            margin-left: 10px;
            margin-right: 10px;"></div>

            <!--  -->
            <div class="row pt-2">
              <div class="col-7">
                <h6 class="text-uppercase py-2 font-weight-bold" [ngClass]="'txt-black'">
                  Report Details
                </h6>
                <ul class="list-unstyled">
                  <li class="pb-1">
                    <span class="clr-cyan" [ngClass]="'txt-black'">Patient Name : </span>
                    <span class="text-black font-weight-bold">{{
                      report.patient?.name | uppercase
                      }}</span>
                  </li>
                  <li class="pb-1">
                    <span class="clr-cyan" [ngClass]="'txt-black'">Gender : </span>
                    <span class="text-black font-weight-bold">{{
                      report.patient?.gender | titlecase
                    }}</span>
                  </li>
                  <li class="pb-1">
                    <span class="clr-cyan" [ngClass]="'txt-black'">Age/DOB : </span>
                    <span class="text-black font-weight-bold">{{
                      report.patient?.dateOfBirth
                        ? (report.patient?.dateOfBirth | date : "dd/MM/yyyy")
                        : report.patient?.age + " Years"
                    }}</span>
                  </li>
                  <li class="pb-1">
                    <span class="clr-cyan" [ngClass]="'txt-black'">MRN No : </span>
                    <span class="text-black font-weight-bold">{{ report?.patient?.orgMRNNo }}</span>
                  </li>

                  <li class="pb-1">
                    <span class="clr-cyan" [ngClass]="'txt-black'">Hospital Name : </span>
                    <span class="text-black font-weight-bold">{{ labHospital }}</span>
                  </li>
                </ul>
              </div>

              <div class="col">
                <ul class="list-unstyled pt-4 mt-3">
                  <li class="pb-1">
                    <span class="clr-cyan" [ngClass]="'txt-black'">Patient Id :
                    </span>
                    <span class="text-black font-weight-bold">{{ report.patient?.organisationPatientId}}</span>
                  </li>
                  <li class="pb-1">
                    <span class="clr-cyan" [ngClass]="'txt-black'">Collected on :
                    </span>
                    <span class="text-black font-weight-bold">{{
                      report.labDetails?.sampleCollected | date : "dd/MM/yyyy"
                    }}</span>
                  </li>
                  <li class="pb-1">
                    <span class="clr-cyan" [ngClass]="'txt-black'">Reported on :
                    </span>
                    <span class="text-black font-weight-bold">{{ date | date : "dd/MM/yyyy" }}</span>
                  </li>
                  <li class="pb-1">
                    <span class="clr-cyan" [ngClass]="'txt-black'">Referred By :
                    </span>
                    <span class="text-black font-weight-bold">{{ referringClinician }}</span>
                  </li>

                </ul>
              </div>
            </div>
            <!--  -->
            <h6 class="py-2 h6_bg" [ngClass]="'yellow'">CLINICAL HISTORY/SAMPLE DETAILS</h6>
            <div class="row mt-2 py-2">
              <div class="col-3">
                <h6 class="mb-0 font-weight-bold">Clinical Indications</h6>
                <span>{{ report?.cancerIdentifier }}</span>
              </div>
              <div class="col-3">
                <h6 class="mb-0 font-weight-bold">Sample Details</h6>
                <span *ngIf="report.labDetails?.sampleSource">{{
                  report.labDetails?.sampleSource
                  }}</span>
                <span *ngIf="!report.labDetails?.sampleSource">FFPE Block</span>
              </div>
              <div class="col-3">
                <h6 class="mb-0 font-weight-bold">Tumor content</h6>
                <span>{{report?.tmhReportInfo?.tumorContent}}</span>
              </div>
              <div class="col-3">
                <h6 class="mb-0 font-weight-bold">Specimen site</h6>
                <span>{{report?.tmhReportInfo?.biopsySite}}</span>
              </div>

            </div>

            <div class="row mt-2 py-2">
              <div class="col">
                <h6 class="mb-0 text-uppercase" style="font-weight: bolder;">Results</h6>
              </div>
            </div>
            <h6 class="py-2 h6_bg" [ngClass]="'yellow'">
              Genomic findings from tumor profiling
            </h6>

            <!--  -->
            <div class="row">
              <div style="width: 100%;" *ngIf="isReportDownload">
                <img [src]="firstPageTable" alt="" width="100%" class="img-fluid" />
              </div>
              <div class="col-12" id="firstPageTable" *ngIf="!isReportDownload">
                <table class="page_1 cust_table w-100 mt-3 first_1" [ngClass]="'table_yellow'">
                  <tr>
                    <th style="font-size:15px!important">Genomic Alteration</th>
                    <th style="font-size:15px!important">Relevant Therapies (in Same Cancer Type)</th>
                    <th style="font-size:15px!important">Relevant Therapies (in Different Cancer)</th>
                  </tr>

                  <tr *ngIf="tableCount== 0 && prognasticCount>0 ">
                    <td colspan="3" class="text-center mt-2">
                      Clinically relevant genomic alterations associated with therapeutic significance were not detected.
                    </td>
                  </tr>
                  <tr *ngIf="tableCount== 0 && prognasticCount == 0">
                    <td colspan="3" class="text-center mt-2">
                      Clinically relevant genomic alterations associated with both therapeutic and prognostic significance were not detected.
                    </td>
                  </tr>

                  <!-- SNV -->
                  <ng-container>
                    <tr class="font-weight-bold" *ngFor="
                                        let snvHotspotTrail of reportPdfRes.snvHotspotTrailResList
                                      ">
                      <td>
                  <i>{{ snvHotspotTrail.gene }}</i>&nbsp;
                  <span *ngIf="!snvHotspotTrail.gene.includes('Wild type')">

                  <span *ngIf="snvHotspotTrail?.multiVariant==null">
                    <span *ngIf="snvHotspotTrail.exonNumber != null">{{snvHotspotTrail.exonNumber}}</span>&nbsp;<span *ngIf="snvHotspotTrail.pposition != null && snvHotspotTrail.pposition != ''">({{snvHotspotTrail.pposition?.replace(' ','')}})</span>
                      <p *ngIf="snvHotspotTrail.mutantBurdenPercent != null && !snvHotspotTrail.gene.includes('Wild type')">
                        Allelic burden: {{ snvHotspotTrail.mutantBurdenPercent }}%
                      </p></span>

                      <span *ngIf="snvHotspotTrail?.multiVariant!=null"><br/>
                        <span  style="font-size: 14px !important" [innerHtml]="snvHotspotTrail?.multiVariant"></span>
                      </span></span>
                </td>
                      <td>
                        <table class="table first_tabley w-100">
                          <thead class="border-bottom cust_clr">
                            <tr>
                              <th class="w-50">
                                <span><i>Therapy</i></span>
                              </th>
                              <th class="w-33">
                                <span><i>Clinical Relevance</i></span>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngIf="snvHotspotTrail.sameCancerType == null">
                              <td class="w-50">NA</td>
                              <td class="w-50">NA</td>
                            </tr>

                            <ng-container *ngIf="snvHotspotTrail.sameCancerType != null">
                              <tr *ngFor="
                                                  let item of snvHotspotTrail.sameTypeRelevantData
                                                ">
                                <td>{{ item.therapy }}</td>
                                <td>{{ item.association }}</td>
                                <!-- <td>{{item.cancerType}}</td> -->
                              </tr>
                            </ng-container>
                          </tbody>
                        </table>
                      </td>
                      <td>
                        <table class="table first_tabley w-100">
                          <thead class="border-bottom cust_clr">
                            <tr>
                              <th class="w-33">
                                <span><i>Therapy</i></span>
                              </th>
                              <th class="w-33">
                                <span><i>Clinical Relevance</i></span>
                              </th>
                              <th class="w-33">
                                <span><i>Cancer Type</i></span>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <!-- <tr *ngIf="snvHotspotTrail.otherCancerType != null">
                                                <td class="w-33 ">
                                                  <div *ngFor="let drugs of snvHotspotTrail.otherCancerType.drugList">
                                                  <span *ngFor="let drug of drugs, let i=index">
                                                      {{drug.name}}<span *ngIf="drugs.length-1 != i">,</span>
                                                  </span>
                                              </div>
                                            </td>
                                              <td class="w-33">
                                                  <div *ngFor="let association of snvHotspotTrail.otherCancerType.associationList">
                                                  {{association}}
                                              </div>
                                            </td>
                                            <td class="w-33">
                                              <div *ngFor="let cancerType of snvHotspotTrail.otherCancerType.cancerTypeList">
                                              {{cancerType.name}}
                                              </div>
                                            </td>
                                              </tr> -->
                            <ng-container *ngIf="snvHotspotTrail.otherCancerType != null">
                              <tr *ngFor="
                                                  let item of snvHotspotTrail.otherTypeRelevantData
                                                ">
                                <td>{{ item.therapy }}</td>
                                <td>{{ item.association }}</td>
                                <td>{{ item.cancerType }}</td>
                              </tr>
                            </ng-container>
                            <tr *ngIf="snvHotspotTrail.otherCancerType == null">
                              <td class="w-33">NA</td>
                              <td class="w-33">NA</td>
                              <td class="w-33">NA</td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>

                    <!-- CNA -->
                    <tr class="text-black font-weight-bold" *ngFor="
                                        let cnaHotspotTrail of reportPdfRes.cnaHotspotTrailResList
                                      ">
                      <td>
                        <i>{{ cnaHotspotTrail.gene }}</i>&nbsp;
                        <span *ngIf="cnaHotspotTrail?.multiVariant==null">
                          {{ cnaHotspotTrail.copyNumber }}</span>
                          <span *ngIf="cnaHotspotTrail?.multiVariant!=null"><br/>
                          <span  style="font-size: 14px !important" [innerHtml]="cnaHotspotTrail?.multiVariant"></span>
                        </span>
                              </td>
                      <td>
                        <table class="table first_tabley w-100">
                          <thead class="border-bottom cust_clr">
                            <tr>
                              <th class="w-50">
                                <span><i>Therapy</i></span>
                              </th>
                              <th class="w-50">
                                <span><i>Clinical Relevance</i></span>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <!-- <tr *ngIf="cnaHotspotTrail.sameCancerType != null">
                                                <td class="w-50">
                                                  <div *ngFor="let drugs of cnaHotspotTrail.sameCancerType.drugList">
                                                  <span *ngFor="let drug of drugs, let i=index">
                                                      {{drug.name}}<span *ngIf="drugs.length-1 != i">,</span>
                                                  </span>
                                              </div>
                                            </td>
                                              <td class="w-50">
                                                  <div *ngFor="let association of cnaHotspotTrail.sameCancerType.associationList">
                                                  {{association}}
                                              </div>
                                            </td>
                                              </tr> -->
                            <ng-container *ngIf="cnaHotspotTrail.sameCancerType != null">
                              <tr *ngFor="
                                                  let item of cnaHotspotTrail.sameTypeRelevantData
                                                ">
                                <td>{{ item.therapy }}</td>
                                <td>{{ item.association }}</td>
                                <!-- <td>{{item.cancerType}}</td> -->
                              </tr>
                            </ng-container>
                            <tr *ngIf="cnaHotspotTrail.sameCancerType == null">
                              <td class="w-50">NA</td>
                              <td class="w-50">NA</td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                      <td>
                        <table class="table first_tabley w-100">
                          <thead class="border-bottom cust_clr">
                            <tr>
                              <th class="w-33">
                                <span><i>Therapy</i></span>
                              </th>
                              <th class="w-33">
                                <span><i>Clinical Relevance</i></span>
                              </th>
                              <th class="w-33">
                                <span><i>Cancer Type</i></span>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <!-- <tr *ngIf="cnaHotspotTrail.otherCancerType != null">
                                                <td class="w-33 ">
                                                  <div *ngFor="let drugs of cnaHotspotTrail.otherCancerType.drugList">
                                                  <span *ngFor="let drug of drugs, let i=index">
                                                      {{drug.name}}<span *ngIf="drugs.length-1 != i">,</span>
                                                  </span>
                                              </div>
                                            </td>
                                              <td class="w-33">
                                                  <div *ngFor="let association of cnaHotspotTrail.otherCancerType.associationList">
                                                  {{association}}
                                              </div>
                                            </td>
                                            <td class="w-33">
                                              <div *ngFor="let cancerType of cnaHotspotTrail.otherCancerType.cancerTypeList">
                                              {{cancerType.name}}
                                              </div>
                                            </td>
                                              </tr> -->

                            <ng-container *ngIf="cnaHotspotTrail.otherCancerType != null">
                              <tr *ngFor="
                                                  let item of cnaHotspotTrail.otherTypeRelevantData
                                                ">
                                <td>{{ item.therapy }}</td>
                                <td>{{ item.association }}</td>
                                <td>{{ item.cancerType }}</td>
                              </tr>
                            </ng-container>

                            <tr *ngIf="cnaHotspotTrail.otherCancerType == null">
                              <td class="w-33">NA</td>
                              <td class="w-33">NA</td>
                              <td class="w-33">NA</td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>

                    <!-- Fusion -->
                    <tr class="text-black font-weight-bold" *ngFor="
                                        let fusionHotspotTrail of reportPdfRes.fusionHotspotTrailResList
                                      ">
                      <td>
                        <i>{{ fusionHotspotTrail.gene }}</i>
                      </td>
                      <td>
                        <table class="table first_tabley w-100">
                          <thead class="border-bottom cust_clr">
                            <tr>
                              <th class="w-50">
                                <span><i>Therapy</i></span>
                              </th>
                              <th class="w-33">
                                <span><i>Clinical Relevance</i></span>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngIf="fusionHotspotTrail.sameCancerType == null">
                              <td class="w-50">NA</td>
                              <td class="w-50">NA</td>
                            </tr>

                            <!-- <tr *ngIf="fusionHotspotTrail.sameCancerType != null">
                                                <td class="w-50">
                                                  <div *ngFor="let drugs of fusionHotspotTrail.sameCancerType.drugList">
                                                  <span *ngFor="let drug of drugs, let i=index">
                                                      {{drug.name}}<span *ngIf="drugs.length-1 != i">,</span>
                                                  </span>
                                              </div>
                                            </td>
                                              <td class="w-50">
                                                  <div *ngFor="let association of fusionHotspotTrail.sameCancerType.associationList">
                                                  {{association}}
                                              </div>
                                            </td>
                                              </tr> -->

                            <ng-container *ngIf="fusionHotspotTrail.sameCancerType != null">
                              <tr *ngFor="
                                                  let item of fusionHotspotTrail.sameTypeRelevantData
                                                ">
                                <td>{{ item.therapy }}</td>
                                <td>{{ item.association }}</td>
                                <!-- <td>{{item.cancerType}}</td> -->
                              </tr>
                            </ng-container>
                          </tbody>
                        </table>
                      </td>
                      <td>
                        <table class="table first_tabley w-100">
                          <thead class="border-bottom cust_clr">
                            <tr>
                              <th class="w-33">
                                <span><i>Therapy</i></span>
                              </th>
                              <th class="w-33">
                                <span><i>Clinical Relevance</i></span>
                              </th>
                              <th class="w-33">
                                <span><i>Cancer Type</i></span>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <!-- <tr *ngIf="fusionHotspotTrail.otherCancerType != null">
                                                <td class="w-33 ">
                                                  <div *ngFor="let drugs of fusionHotspotTrail.otherCancerType.drugList">
                                                  <span *ngFor="let drug of drugs, let i=index">
                                                      {{drug.name}}<span *ngIf="drugs.length-1 != i">,</span>
                                                  </span>
                                              </div>
                                            </td>
                                              <td class="w-33">
                                                  <div *ngFor="let association of fusionHotspotTrail.otherCancerType.associationList">
                                                  {{association}}
                                              </div>
                                            </td>
                                            <td class="w-33">
                                              <div *ngFor="let cancerType of fusionHotspotTrail.otherCancerType.cancerTypeList">
                                              {{cancerType.name}}
                                              </div>
                                            </td>
                                              </tr> -->
                            <ng-container *ngIf="fusionHotspotTrail.otherCancerType != null">
                              <tr *ngFor="
                                                  let item of fusionHotspotTrail.otherTypeRelevantData
                                                ">
                                <td>{{ item.therapy }}</td>
                                <td>{{ item.association }}</td>
                                <td>{{ item.cancerType }}</td>
                              </tr>
                            </ng-container>
                            <tr *ngIf="fusionHotspotTrail.otherCancerType == null">
                              <td class="w-33">NA</td>
                              <td class="w-33">NA</td>
                              <td class="w-33">NA</td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </ng-container>
                </table>
                <p *ngIf="
                                    reportPdfRes.snvHotspotTrailResList.length != 0 ||
                                    reportPdfRes.cnaHotspotTrailResList.length != 0
                                  " style="font-size: 13px !important" class="mt-3">
                  <i> *NA: Not Applicable</i>
                </p>
              </div>
            </div>
            <!--  -->
            <div *ngIf="(tableCount>0) &&
              prognasticCount==0">
              <h6 class="py-2 h6_bg" [ngClass]="'yellow'"> Prognostic Biomarkers</h6>
            </div>
            <div class="row" *ngIf="(tableCount>0 )&&
              prognasticCount==0">
              <div class="col-12">
                <table class="page_1 cust_table w-100 mt-3 varient_detailsy prognasticbiomarkertable"
                  [ngClass]="'table_variant-blue'">
                  <thead>
                    <tr>
                      <th>Genomic Alteration</th>
                      <th>Prognostic Significance</th>

                    </tr>
                  </thead>
                  <ng-container>
                    <tr>
                      <td colspan="2" style="text-align:center">
                        Clinically relevant genomic alterations associated with prognostic significance were not detected.
                      </td>
                    </tr>
                  </ng-container>
                </table>
              </div>
            </div>
            <div *ngIf="prognasticCount>0">
              <h6 class="py-2 h6_bg" [ngClass]="'yellow'"> Prognostic Biomarkers</h6>
            </div>

            <div class="row" *ngIf="prognasticCount>0">
              <div class="col-12">
                <table class="page_1 cust_table w-100 mt-3 varient_detailsy prognasticbiomarkertable"
                  [ngClass]="'table_variant-blue'">
                  <thead>
                    <tr>
                      <th>Genomic Alteration</th>
                      <th>Prognostic Significance</th>

                    </tr>
                  </thead>
                  <ng-container *ngFor="let prog of reportPdfRes.prognosticBiomarkers">
                    <tr>
                      <td style="padding-left: 3px !important">
                        <i>{{ prog.gene }}&nbsp; </i>
                        <span [innerHtml]="prog.geneDetails"></span>
                      </td>
                      <td style="padding-left: 3px !important;">{{prog.statement}}
                      </td>
                    </tr>
                  </ng-container>


                </table>
              </div>
            </div>
            <!--  -->

            <!-- gene Data -->
            <div *ngIf="!geneFlag && isStatusDisplay">
              <div *ngIf="geneMutationlist != null" class="mt-3">
                <h6 class="py-2 h6_bg" [ngClass]="'yellow'">
                  Status of variants in cancer related Biomarkers
                </h6>
              </div>

              <table class="staus_gene bg_yellow" *ngIf="geneMutationlist != null" [ngClass]="{
                                  biomarker_table:
                                    geneMutationlist.length >= 4 &&
                                    geneMutationlist.length < 8
                                }">
                <tr style="background: #bfe6fa">
                  <td style="font-size: 18px !important; padding-left: 10px !important">
                    Gene
                  </td>
                  <td *ngFor="
                                      let geneMutation of geneMutationlist
                                        | slice : 0 : 8
                                    ">
                    <i>{{ geneMutation.gene }}</i>
                  </td>
                </tr>
                <tr>
                  <td style="font-size: 18px !important; padding-left: 10px !important">
                    <b>Status</b>
                  </td>
                  <td *ngFor="
                                      let geneMutation of geneMutationlist
                                        | slice : 0 : 8
                                    ">
                    <span *ngIf="
                                        geneMutation.status == 'Pathogenic' ||
                                        geneMutation.status == 'Likely Pathogenic' ||
                                        geneMutation.status == 'Detected'
                                      " style="color: red; font-weight: 600px"><b>{{ geneMutation.status }}</b></span>
                    <span *ngIf="geneMutation.status == 'VUS'" style="color: black; font-weight: 600px"><b>{{
                        geneMutation.status }}</b></span>
                    <span *ngIf="
                                        geneMutation.status == 'Not Detected' ||
                                        geneMutation.status == 'Benige'
                                      ">{{ geneMutation.status }}</span>
                  </td>
                </tr>
                <br />

              </table>
              <table class="staus_gene" [ngClass]="'bg_yellow'" *ngIf="
                                  geneMutationlist != null &&
                                  geneMutationlist.length > 8
                                ">
                <tr style="background: #bfe6fa">
                  <td style="font-size: 18px !important; padding-left: 10px !important">
                    Gene
                  </td>
                  <td *ngFor="
                                      let geneMutation of geneMutationlist
                                        | slice : 8 : 17
                                    ">
                    <i>{{ geneMutation.gene }}</i>
                  </td>
                </tr>
                <tr>
                  <td style="font-size: 18px !important; padding-left: 10px !important">
                    <b>Status</b>
                  </td>
                  <td *ngFor="
                                      let geneMutation of geneMutationlist
                                        | slice : 8 : 17
                                    ">
                    <span *ngIf="
                                        geneMutation.status == 'Pathogenic' ||
                                        geneMutation.status == 'Likely Pathogenic' ||
                                        geneMutation.status == 'Detected'
                                      " style="color: red; font-weight: 600px"><b>{{ geneMutation.status }}</b></span>
                    <span *ngIf="geneMutation.status == 'VUS'" style="color: black; font-weight: 600px"><b>{{
                        geneMutation.status }}</b></span>
                    <span *ngIf="
                                        geneMutation.status == 'Not Detected' ||
                                        geneMutation.status == 'Benige'
                                      ">{{ geneMutation.status }}</span>
                  </td>
                </tr>
                <br/>

              </table>
              <table
                class="staus_gene"
                [ngClass]="'bg_yellow'"
                *ngIf="
                  geneMutationlist != null &&
                  geneMutationlist.length > 17
                "
              >
                <tr style="background: #bfe6fa">
                  <td
                    style="font-size: 18px !important; padding-left: 10px !important"
                  >
                    Gene
                  </td>
                  <td
                    *ngFor="
                      let geneMutation of geneMutationlist
                        | slice : 17 : 25
                    "
                  >
                    <i>{{ geneMutation.gene }}</i>
                  </td>
                </tr>
                <tr>
                  <td
                    style="font-size: 18px !important; padding-left: 10px !important"
                  >
                    <b>Status</b>
                  </td>
                  <td
                    *ngFor="
                      let geneMutation of geneMutationlist
                        | slice : 17 : 25
                    "
                  >
                    <span
                      *ngIf="
                        geneMutation.status == 'Pathogenic' ||
                        geneMutation.status == 'Likely Pathogenic' ||
                        geneMutation.status == 'Detected'
                      "
                      style="color: red; font-weight: 600px"
                      ><b>{{ geneMutation.status }}</b></span
                    >
                    <span
                      *ngIf="geneMutation.status == 'VUS'"
                      style="color: black; font-weight: 600px"
                      ><b>{{ geneMutation.status }}</b></span
                    >
                    <span
                      *ngIf="
                        geneMutation.status == 'Not Detected' ||
                        geneMutation.status == 'Benige'
                      "
                      >{{ geneMutation.status }}</span
                    >
                  </td>
                </tr>
                <br/>
              </table>

              <table class="staus_gene" [ngClass]="'bg_yellow'" *ngIf="
                    geneMutationlist != null &&
                    geneMutationlist.length > 25
                  ">
                <tr style="background: #bfe6fa">
                  <td style="font-size: 18px !important; padding-left: 10px !important">
                    Gene
                  </td>
                  <td *ngFor="
                        let geneMutation of geneMutationlist
                          | slice : 25 : 33
                      ">
                    <i>{{ geneMutation.gene }}</i>
                  </td>
                </tr>
                <tr>
                  <td style="font-size: 18px !important; padding-left: 10px !important">
                    <b>Status</b>
                  </td>
                  <td *ngFor="
                        let geneMutation of geneMutationlist
                          | slice : 25 : 33
                      ">
                    <span *ngIf="
                          geneMutation.status == 'Pathogenic' ||
                          geneMutation.status == 'Likely Pathogenic' ||
                          geneMutation.status == 'Detected'
                        " style="color: red; font-weight: 600px"><b>{{ geneMutation.status }}</b></span>
                    <span *ngIf="geneMutation.status == 'VUS'" style="color: black; font-weight: 600px"><b>{{
                        geneMutation.status }}</b></span>
                    <span *ngIf="
                          geneMutation.status == 'Not Detected' ||
                          geneMutation.status == 'Benige'
                        ">{{ geneMutation.status }}</span>
                  </td>
                </tr>
<br/>
              </table>

          <table class="staus_gene" [ngClass]="'bg_yellow'" *ngIf="
                geneMutationlist != null &&
                geneMutationlist.length > 33
              ">
            <tr style="background: #bfe6fa">
              <td style="font-size: 18px !important; padding-left: 10px !important">
                Gene
              </td>
              <td *ngFor="
                    let geneMutation of geneMutationlist
                      | slice : 33 : 41
                  ">
                <i>{{ geneMutation.gene }}</i>
              </td>
            </tr>
            <tr>
              <td style="font-size: 18px !important; padding-left: 10px !important">
                <b>Status</b>
              </td>
              <td *ngFor="
                    let geneMutation of geneMutationlist
                      | slice : 33 : 41
                  ">
                <span *ngIf="
                      geneMutation.status == 'Pathogenic' ||
                      geneMutation.status == 'Likely Pathogenic' ||
                      geneMutation.status == 'Detected'
                    " style="color: red; font-weight: 600px"><b>{{ geneMutation.status }}</b></span>
                <span *ngIf="geneMutation.status == 'VUS'" style="color: black; font-weight: 600px"><b>{{
                    geneMutation.status }}</b></span>
                <span *ngIf="
                      geneMutation.status == 'Not Detected' ||
                      geneMutation.status == 'Benige'
                    ">{{ geneMutation.status }}</span>
              </td>
            </tr>
            <br/>
          </table>
              <h6 *ngIf="
                                  fusionGeneMutationList != null &&
                                  fusionGeneMutationList.length > 0
                                " class="font-weight-bold clr-cyan text-uppercase" [ngClass]="'txt-black'">
                Gene Fusion
              </h6>
              <table class="staus_gene" *ngIf="
                                  fusionGeneMutationList != null &&
                                  fusionGeneMutationList.length > 0
                                " [ngClass]="'bg_yellow'">
                <tr style="background: #bfe6fa">
                  <td style="font-size: 18px !important; padding-left: 10px !important">
                    Gene
                  </td>
                  <td *ngFor="
                                      let geneMutation of fusionGeneMutationList
                                        | slice : 0 : 8
                                    ">
                    <i>{{ geneMutation.gene }}</i>
                  </td>
                </tr>
                <tr>
                  <td style="font-size: 18px !important; padding-left: 10px !important">
                    <b>Status</b>
                  </td>
                  <td *ngFor="
                                      let geneMutation of fusionGeneMutationList
                                        | slice : 0 : 8
                                    ">
                    <span *ngIf="
                                        geneMutation.status == 'Pathogenic' ||
                                        geneMutation.status == 'Likely Pathogenic' ||
                                        geneMutation.status == 'Detected'
                                      " style="color: red; font-weight: 600px"><b>{{ geneMutation.status }}</b></span>
                    <span *ngIf="geneMutation.status == 'VUS'" style="color: black; font-weight: 600px"><b>{{
                        geneMutation.status }}</b></span>
                    <span *ngIf="
                                        geneMutation.status == 'Not Detected' ||
                                        geneMutation.status == 'Benige'
                                      ">{{ geneMutation.status }}</span>
                  </td>
                </tr>
<br/>
              </table>
              <table class="staus_gene" *ngIf="
                                  fusionGeneMutationList != null &&
                                  fusionGeneMutationList.length > 8
                                " [ngClass]="'bg_yellow'">
                <tr style="background: #bfe6fa">
                  <td style="font-size: 18px !important; padding-left: 10px !important">
                    Gene
                  </td>
                  <td *ngFor="
                                      let geneMutation of fusionGeneMutationList
                                        | slice : 8 : 17
                                    ">
                    <i>{{ geneMutation.gene }}</i>
                  </td>
                </tr>
                <tr>
                  <td style="font-size: 18px !important; padding-left: 10px !important">
                    <b>Status</b>
                  </td>
                  <td *ngFor="
                                      let geneMutation of fusionGeneMutationList
                                        | slice : 8 : 17
                                    ">
                    <span *ngIf="
                                        geneMutation.status == 'Pathogenic' ||
                                        geneMutation.status == 'Likely Pathogenic' ||
                                        geneMutation.status == 'Detected'
                                      " style="color: red; font-weight: 600px"><b>{{ geneMutation.status }}</b></span>
                    <span *ngIf="geneMutation.status == 'VUS'" style="color: black; font-weight: 600px"><b>{{
                        geneMutation.status }}</b></span>
                    <span *ngIf="
                                        geneMutation.status == 'Not Detected' ||
                                        geneMutation.status == 'Benige'
                                      ">{{ geneMutation.status }}</span>
                  </td>
                </tr>
              </table>
            </div>
            <!-- <div *ngIf="geneMutationlist != null"  class="row">
                               <div class="col">
                                 <div class="gene_name d-flex rounded">
                                   <h6 class="gene_title mb-0">GENE</h6>
                                   <span *ngFor= "let geneMutation of geneMutationlist | slice:0:8"><i>{{geneMutation.gene}}</i></span>

                                 </div>
                                 <div class="gene_status d-flex ">
                                  <h6 class="gene_title mb-0">STATUS</h6>
                                  <span *ngFor= "let geneMutation of geneMutationlist | slice:0:8" style="font-size :14pximportant;">
                                    <span *ngIf="geneMutation.status=='Pathogenic' || geneMutation.status=='Likely Pathogenic'" style="color:red; font-weight:600px"><b>{{geneMutation.status}}</b></span>
                                    <span *ngIf="geneMutation.status=='VUS'" style="color:black; font-weight:600px"><b>{{geneMutation.status}}</b></span>
                                    <span *ngIf="geneMutation.status == 'Not Detected' || geneMutation.status =='Benige'">{{geneMutation.status}}</span>
                                  </span>
                                  <!-- <span class="det_cyan">Detected</span> -->
            <!-- </div>
                               </div>
                             </div> -->

            <!-- <div *ngIf="geneMutationlist != null &&  geneMutationlist.length > 8"  class="row mt-4">
                              <div class="col">
                                <div class="gene_name d-flex rounded">
                                  <h6 class="gene_title mb-0">GENE</h6>
                                  <span *ngFor= "let geneMutation of geneMutationlist | slice:8:17"><i>{{geneMutation.gene}}</i></span>

                                </div>
                                <div class="gene_status d-flex ">
                                 <h6 class="gene_title mb-0">STATUS</h6>
                                 <span *ngFor= "let geneMutation of geneMutationlist | slice:8:17" style="font-size :14pximportant;">
                                  <span *ngIf="geneMutation.status=='Pathogenic' || geneMutation.status=='Likely Pathogenic'" style="color:red; font-weight:600px"><b>{{geneMutation.status}}</b></span>
                                  <span *ngIf="geneMutation.status=='VUS'" style="color:black; font-weight:600px"><b>{{geneMutation.status}}</b></span>
                                  <span *ngIf="geneMutation.status == 'Not Detected' || geneMutation.status =='Benige'">{{geneMutation.status}}</span>
                                </span>
                               </div>
                              </div>
                            </div> -->

            <div *ngIf="
                                report.disclaimerNote != ' ' &&
                                report.disclaimerNote != null &&
                                fusionGeneMutationList.length <= 0
                              ">
              <h6 style="color: #000"><b>Note: </b></h6>
              <div style="font-size: 14px !important;text-align: justify" [innerHtml]="report.disclaimerNote"></div>
              <!-- <div class="py-2" [innerHtml]='report.disclaimerNote' style=" text-align: justify;font-size: 14px!important;"></div> -->

              <!-- <p>The data size we had received for this sample was inadequate. However, with the patient's best interest in mind we have further processed the sample. Kindly correlate clinically before making any treatment decisions.</p> -->
              <!-- <p>The data size we had received for this sample was inadequate. However, with the patient's best interest in mind we have further processed the sample and a false negative status of the result cannot be ruled out. Kindly correlate clinically before making any treatment decisions.</p> -->
            </div>

            <!--  -->
                  <div class="row footer">
          <!-- <div class="col-12">
            <div class="pb-2 pl-4 text-center">
              <div class="d-block">
                  <span class="font-weight-bold" style="font-size: 13px !important"
                    >4baseCare (Genomics Tarang ODC),</span
                  >
                <span>
                  SJP2-S1-1F- C wing, Wipro Limited, SEZ, Sarjapur 2, Sy.No.69 (P),
                </span>
              </div>
              <div class="d-block">
                <span>Doddakannelli, Sarjapura Road,</span>
                <span> Bengaluru 560035, KA, India</span></div>


            </div>
          </div>
          <div class="col-12 pt-1 ">
            <p  style="font-size: 8px!important; line-height: 14px!important;">{{footerDiscription}} </p>
          </div> -->


        </div>
          </div>

          <div id="page1_2" *ngIf="newsnvHotspotTrailResList.length > 0" class="container position-relative page"
            style="background-color: white">
            <div class="d-flex justify-content-between logo_panel py-3">
              <div class="logo_part">
                <img
                style="width:240px!important" src="../../../assets/hcg-new-logo.jpeg"
                  alt=""
                  class="logo_first"
                />
              </div>
              <div style=" border-left: 2px solid #fab216;padding-top:10px;
              height: 120px;"></div>

              <div class="logo_part text-center" style="font-family:arial Mt!important">

                <h5 style="font-weight: bolder;margin-bottom:0px!important;">Triesta Sciences</h5>
                <p style="font-size: 15px!important;">
                  A Unit of Health Care Global Enterprises Ltd <br/>
                  5th Floor, Tower 1, #8, P.Kalinga Rao Road,<br/>
                  Sampangiram Nagar, Bengaluru-560027. Tel: 080-40206305
                </p>
                <h6 style="font-weight: bolder;font-size:15px!important">Department of Molecular and Clinical Genomics</h6>
              </div>
              <div style=" border-left: 2px solid #fab216;padding-top:10px;
              height: 120px;"></div>
              <div class="logo_part">
                <img  style="width:240px!important; margin-top:40px!important"
                  src="../../../assets//oncoquer1.png"
                  alt=""
                  class="logo_three"
                />
              </div>
            </div>
            <div class="row header mt-1">
              <div>
                <!-- <div class="col-3">
                                  <img src="../../../assets/logo.png" alt="" style="width: 140px" />
                                </div> -->
              </div>
              <div></div>
              <div class="col">
                <div class="patient-detailes ml-auto float-right">
                  <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
                    <li>
                      Patient Id <br />
                      <span>{{ report.patient?.organisationPatientId }}</span>
                    </li>
                    <li class="ml-4">
                      Patient Name<br />
                      <span>{{ report.patient?.name }}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <!--  -->
              <div *ngIf="report.clientType == '4basecare_client'">
                <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
                  </div>
                </div>
                <div *ngIf="report.clientName == 'path_labs'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
                  </div>
                </div>
              </div>
            </div>
            <h6 class="py-2 h6_bg" [ngClass]="'yellow'">
              Genomic findings from tumor profiling
            </h6>
            <div class="row">
              <!-- <div class="col-12"  *ngIf="isReportDownload">
                                <img [src]="firstPageTable" alt="" class="img-fluid w-90">
                              </div> -->
              <div class="col-12" id="firstPageTable">
                <table class="page_1 cust_table w-100 mt-3 first_1" [ngClass]="'table_yellow'">
                  <tr>
                    <th  style="font-size:15px!important">Genomic Alteration</th>
                    <th style="font-size:15px!important">Relevant Therapies (in Same Cancer Type)</th>
                    <th style="font-size:15px!important">Relevant Therapies (in Different Cancer)</th>
                  </tr>
                  <tr *ngIf="tableCount== 0 && prognasticCount>0 ">
                    <td colspan="3" class="text-center mt-2">
                      Clinically relevant genomic alterations associated with therapeutic significance were not detected.
                    </td>
                  </tr>
                  <tr *ngIf="tableCount== 0 && prognasticCount == 0">
                    <td colspan="3" class="text-center mt-2">
                      Clinically relevant genomic alterations associated with both therapeutic and prognostic significance were not detected.
                    </td>
                  </tr>


                  <!-- SNV -->
                  <ng-container>
                    <tr class="text-black font-weight-bold" *ngFor="let snvHotspotTrail of newsnvHotspotTrailResList">
                      <td>
                        <i>{{ snvHotspotTrail.gene }}</i>&nbsp;
                        <span *ngIf="!snvHotspotTrail.gene.includes('Wild type')">

                        <span *ngIf="snvHotspotTrail?.multiVariant==null">
                          <span *ngIf="snvHotspotTrail.exonNumber != null">{{snvHotspotTrail.exonNumber}}</span>&nbsp;<span *ngIf="snvHotspotTrail.pposition != null && snvHotspotTrail.pposition != ''">({{snvHotspotTrail.pposition?.replace(' ','')}})</span>
                            <p *ngIf="snvHotspotTrail.mutantBurdenPercent != null && !snvHotspotTrail.gene.includes('Wild type')">
                              Allelic burden: {{ snvHotspotTrail.mutantBurdenPercent }}%
                            </p></span>

                            <span *ngIf="snvHotspotTrail?.multiVariant!=null"><br/>
                              <span  style="font-size: 14px !important" [innerHtml]="snvHotspotTrail?.multiVariant"></span>
                            </span>
                            </span>
                      </td>
                      <td>
                        <table class="first_tabley w-100">
                          <thead class="border-bottom cust_clr">
                            <tr>
                              <th class="w-50">
                                <span><i>Therapy</i></span>
                              </th>
                              <th class="w-33">
                                <span><i>Clinical Relevance</i></span>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngIf="snvHotspotTrail.sameCancerType == null">
                              <td class="w-50">NA</td>
                              <td class="w-50">NA</td>
                            </tr>

                            <!-- <tr *ngIf="snvHotspotTrail.sameCancerType != null">
                                                <td class="w-50">
                                                  <div *ngFor="let drugs of snvHotspotTrail.sameCancerType.drugList">
                                                  <span *ngFor="let drug of drugs, let i=index">
                                                      {{drug.name}}<span *ngIf="drugs.length-1 != i">,</span>
                                                  </span>
                                              </div>
                                            </td>
                                              <td class="w-50">
                                                  <div *ngFor="let association of snvHotspotTrail.sameCancerType.associationList">
                                                  {{association}}
                                              </div>
                                            </td>
                                              </tr> -->
                            <ng-container *ngIf="snvHotspotTrail.sameCancerType != null">
                              <tr *ngFor="
                                                  let item of snvHotspotTrail.sameTypeRelevantData
                                                ">
                                <td>{{ item.therapy }}</td>
                                <td>{{ item.association }}</td>
                                <!-- <td>{{item.cancerType}}</td> -->
                              </tr>
                            </ng-container>
                          </tbody>
                        </table>
                      </td>
                      <td>
                        <table class="first_tabley w-100">
                          <thead class="border-bottom cust_clr">
                            <tr>
                              <th class="w-33">
                                <span><i>Therapy</i></span>
                              </th>
                              <th class="w-33">
                                <span><i>Clinical Relevance</i></span>
                              </th>
                              <th class="w-33">
                                <span><i>Cancer Type</i></span>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <!-- <tr *ngIf="snvHotspotTrail.otherCancerType != null">
                                                <td class="w-33 ">
                                                  <div *ngFor="let drugs of snvHotspotTrail.otherCancerType.drugList">
                                                  <span *ngFor="let drug of drugs, let i=index">
                                                      {{drug.name}}<span *ngIf="drugs.length-1 != i">,</span>
                                                  </span>
                                              </div>
                                            </td>
                                              <td class="w-33">
                                                  <div *ngFor="let association of snvHotspotTrail.otherCancerType.associationList">
                                                  {{association}}
                                              </div>
                                            </td>
                                            <td class="w-33">
                                              <div *ngFor="let cancerType of snvHotspotTrail.otherCancerType.cancerTypeList">
                                              {{cancerType.name}}
                                              </div>
                                            </td>
                                              </tr> -->
                            <ng-container *ngIf="snvHotspotTrail.otherCancerType != null">
                              <tr *ngFor="
                                                  let item of snvHotspotTrail.otherTypeRelevantData
                                                ">
                                <td>{{ item.therapy }}</td>
                                <td>{{ item.association }}</td>
                                <td>{{ item.cancerType }}</td>
                              </tr>
                            </ng-container>
                            <tr *ngIf="snvHotspotTrail.otherCancerType == null">
                              <td class="w-33">NA</td>
                              <td class="w-33">NA</td>
                              <td class="w-33">NA</td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </ng-container>
                </table>
                <p *ngIf="newsnvHotspotTrailResList.length != 0" style="font-size: 13px !important" class="mt-3">
                  <i> *NA: Not Applicable</i>
                </p>
              </div>
            </div>
            <!--  -->
                  <div class="row footer">
          <!-- <div class="col-12">
            <div class="pb-2 pl-4 text-center">
              <div class="d-block">
                  <span class="font-weight-bold" style="font-size: 13px !important"
                    >4baseCare (Genomics Tarang ODC),</span
                  >
                <span>
                  SJP2-S1-1F- C wing, Wipro Limited, SEZ, Sarjapur 2, Sy.No.69 (P),
                </span>
              </div>
              <div class="d-block">
                <span>Doddakannelli, Sarjapura Road,</span>
                <span> Bengaluru 560035, KA, India</span></div>


            </div>
          </div>
          <div class="col-12 pt-1 ">
            <p  style="font-size: 8px!important; line-height: 14px!important;">{{footerDiscription}} </p>
          </div> -->


        </div>
          </div>

          <div id="page2" *ngIf="(report.addNotes != '<div></div>' && report.addNotes != null) ||
                            otherCountSNV == true ||
                            reportPdfRes.allVariantsList.length > 0 ||
                            (report.disclaimerNote != ' ' && report.disclaimerNote != null)
                            " class="container position-relative page" style="background-color: white">
                            <div class="d-flex justify-content-between logo_panel py-3">
                              <div class="logo_part">
                                <img
                                style="width:240px!important" src="../../../assets/hcg-new-logo.jpeg"
                                  alt=""
                                  class="logo_first"
                                />
                              </div>
                              <div style=" border-left: 2px solid #fab216;padding-top:10px;
                              height: 120px;"></div>

                              <div class="logo_part text-center" style="font-family:arial Mt!important">

                                <h5 style="font-weight: bolder;margin-bottom:0px!important;">Triesta Sciences</h5>
                                <p style="font-size: 15px!important;">
                                  A Unit of Health Care Global Enterprises Ltd <br/>
                                  5th Floor, Tower 1, #8, P.Kalinga Rao Road,<br/>
                                  Sampangiram Nagar, Bengaluru-560027. Tel: 080-40206305
                                </p>
                                <h6 style="font-weight: bolder;font-size:15px!important">Department of Molecular and Clinical Genomics</h6>
                              </div>
                              <div style=" border-left: 2px solid #fab216;padding-top:10px;
                              height: 120px;"></div>
                              <div class="logo_part">
                                <img  style="width:240px!important; margin-top:40px!important"
                                  src="../../../assets//oncoquer1.png"
                                  alt=""
                                  class="logo_three"
                                />
                              </div>
                            </div>
            <div class="row header mt-1">
              <div>
                <!-- <div class="col-3">
                                  <img src="../../../assets/logo.png" alt="" style="width: 140px" />
                                </div> -->
              </div>
              <div></div>
              <div class="col">
                <div class="patient-detailes ml-auto float-right">
                  <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
                    <li>
                      Patient Id <br />
                      <span>{{ report.patient?.organisationPatientId }}</span>
                    </li>
                    <li class="ml-4">
                      Patient Name<br />
                      <span>{{ report.patient?.name }}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <!--  -->
              <div *ngIf="report.clientType == '4basecare_client'">
                <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
                  </div>
                </div>
                <div *ngIf="report.clientName == 'path_labs'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
                  </div>
                </div>
              </div>
            </div>

            <div *ngIf="geneFlag && isStatusDisplay">
              <div *ngIf="geneMutationlist != null" class="mt-3">
                <h6 class="py-2 h6_bg" [ngClass]="'yellow'">
                  Status of variants in cancer related Biomarkers
                </h6>
              </div>
              <table class="staus_gene" *ngIf="geneMutationlist != null" [ngClass]="'bg_yellow'">
                <tr style="background: #bfe6fa">
                  <td style="font-size: 18px !important; padding-left: 10px !important">
                    Gene
                  </td>
                  <td *ngFor="
                                      let geneMutation of geneMutationlist
                                        | slice : 0 : 8
                                    ">
                    <i>{{ geneMutation.gene }}</i>
                  </td>
                </tr>
                <tr>
                  <td style="font-size: 18px !important; padding-left: 10px !important">
                    <b>Status</b>
                  </td>
                  <td *ngFor="
                                      let geneMutation of geneMutationlist
                                        | slice : 0 : 8
                                    ">
                    <span *ngIf="
                                        geneMutation.status == 'Pathogenic' ||
                                        geneMutation.status == 'Likely Pathogenic' ||
                                        geneMutation.status == 'Detected'
                                      " style="color: red; font-weight: 600px"><b>{{ geneMutation.status }}</b></span>
                    <span *ngIf="geneMutation.status == 'VUS'" style="color: black; font-weight: 600px"><b>{{
                        geneMutation.status }}</b></span>
                    <span *ngIf="
                                        geneMutation.status == 'Not Detected' ||
                                        geneMutation.status == 'Benige'
                                      ">{{ geneMutation.status }}</span>
                  </td>
                </tr>
                <br />
              </table>
              <table class="staus_gene" [ngClass]="'bg_yellow'" *ngIf="
                                  geneMutationlist != null &&
                                  geneMutationlist.length > 8
                                ">
                <tr style="background: #bfe6fa">
                  <td style="font-size: 18px !important; padding-left: 10px !important">
                    Gene
                  </td>
                  <td *ngFor="
                                      let geneMutation of geneMutationlist
                                        | slice : 8 : 17
                                    ">
                    <i>{{ geneMutation.gene }}</i>
                  </td>
                </tr>
                <tr>
                  <td style="font-size: 18px !important; padding-left: 10px !important">
                    <b>Status</b>
                  </td>
                  <td *ngFor="
                                      let geneMutation of geneMutationlist
                                        | slice : 8 : 17
                                    ">
                    <span *ngIf="
                                        geneMutation.status == 'Pathogenic' ||
                                        geneMutation.status == 'Likely Pathogenic' ||
                                        geneMutation.status == 'Detected'
                                      " style="color: red; font-weight: 600px"><b>{{ geneMutation.status }}</b></span>
                    <span *ngIf="geneMutation.status == 'VUS'" style="color: black; font-weight: 600px"><b>{{
                        geneMutation.status }}</b></span>
                    <span *ngIf="
                                        geneMutation.status == 'Not Detected' ||
                                        geneMutation.status == 'Benige'
                                      ">{{ geneMutation.status }}</span>
                  </td>
                </tr>
                <br />
              </table>
              <table
                class="staus_gene"
                [ngClass]="'bg_yellow'"
                *ngIf="
                  geneMutationlist != null &&
                  geneMutationlist.length > 17
                "
              >
                <tr style="background: #bfe6fa">
                  <td
                    style="font-size: 18px !important; padding-left: 10px !important"
                  >
                    Gene
                  </td>
                  <td
                    *ngFor="
                      let geneMutation of geneMutationlist
                        | slice : 17 : 25
                    "
                  >
                    <i>{{ geneMutation.gene }}</i>
                  </td>
                </tr>
                <tr>
                  <td
                    style="font-size: 18px !important; padding-left: 10px !important"
                  >
                    <b>Status</b>
                  </td>
                  <td
                    *ngFor="
                      let geneMutation of geneMutationlist
                        | slice : 17 : 25
                    "
                  >
                    <span
                      *ngIf="
                        geneMutation.status == 'Pathogenic' ||
                        geneMutation.status == 'Likely Pathogenic' ||
                        geneMutation.status == 'Detected'
                      "
                      style="color: red; font-weight: 600px"
                      ><b>{{ geneMutation.status }}</b></span
                    >
                    <span
                      *ngIf="geneMutation.status == 'VUS'"
                      style="color: black; font-weight: 600px"
                      ><b>{{ geneMutation.status }}</b></span
                    >
                    <span
                      *ngIf="
                        geneMutation.status == 'Not Detected' ||
                        geneMutation.status == 'Benige'
                      "
                      >{{ geneMutation.status }}</span
                    >
                  </td>
                </tr>
                              <br />
              </table>
              <table class="staus_gene" [ngClass]="'bg_yellow'" *ngIf="
                    geneMutationlist != null &&
                    geneMutationlist.length > 25
                  ">
                <tr style="background: #bfe6fa">
                  <td style="font-size: 18px !important; padding-left: 10px !important">
                    Gene
                  </td>
                  <td *ngFor="
                        let geneMutation of geneMutationlist
                          | slice : 25 : 33
                      ">
                    <i>{{ geneMutation.gene }}</i>
                  </td>
                </tr>
                <tr>
                  <td style="font-size: 18px !important; padding-left: 10px !important">
                    <b>Status</b>
                  </td>
                  <td *ngFor="
                        let geneMutation of geneMutationlist
                          | slice : 25 : 33
                      ">
                    <span *ngIf="
                          geneMutation.status == 'Pathogenic' ||
                          geneMutation.status == 'Likely Pathogenic' ||
                          geneMutation.status == 'Detected'
                        " style="color: red; font-weight: 600px"><b>{{ geneMutation.status }}</b></span>
                    <span *ngIf="geneMutation.status == 'VUS'" style="color: black; font-weight: 600px"><b>{{
                        geneMutation.status }}</b></span>
                    <span *ngIf="
                          geneMutation.status == 'Not Detected' ||
                          geneMutation.status == 'Benige'
                        ">{{ geneMutation.status }}</span>
                  </td>
                </tr>
                <br />

              </table>
          <table class="staus_gene" [ngClass]="'bg_yellow'" *ngIf="
                geneMutationlist != null &&
                geneMutationlist.length > 33
              ">
            <tr style="background: #bfe6fa">
              <td style="font-size: 18px !important; padding-left: 10px !important">
                Gene
              </td>
              <td *ngFor="
                    let geneMutation of geneMutationlist
                      | slice : 33 : 41
                  ">
                <i>{{ geneMutation.gene }}</i>
              </td>
            </tr>
            <tr>
              <td style="font-size: 18px !important; padding-left: 10px !important">
                <b>Status</b>
              </td>
              <td *ngFor="
                    let geneMutation of geneMutationlist
                      | slice : 33 : 41
                  ">
                <span *ngIf="
                      geneMutation.status == 'Pathogenic' ||
                      geneMutation.status == 'Likely Pathogenic' ||
                      geneMutation.status == 'Detected'
                    " style="color: red; font-weight: 600px"><b>{{ geneMutation.status }}</b></span>
                <span *ngIf="geneMutation.status == 'VUS'" style="color: black; font-weight: 600px"><b>{{
                    geneMutation.status }}</b></span>
                <span *ngIf="
                      geneMutation.status == 'Not Detected' ||
                      geneMutation.status == 'Benige'
                    ">{{ geneMutation.status }}</span>
              </td>
            </tr>
            <br />
          </table>
              <h6 *ngIf="
                                  fusionGeneMutationList != null &&
                                  fusionGeneMutationList.length > 0
                                " class="font-weight-bold clr-cyan text-uppercase" [ngClass]="'txt-black'">
                Gene Fusion
              </h6>
              <table class="staus_gene" *ngIf="
                                  fusionGeneMutationList != null &&
                                  fusionGeneMutationList.length > 0
                                " [ngClass]="'bg_yellow'">
                <tr style="background: #bfe6fa">
                  <td style="font-size: 18px !important; padding-left: 10px !important">
                    Gene
                  </td>
                  <td *ngFor="
                                      let geneMutation of fusionGeneMutationList
                                        | slice : 0 : 8
                                    ">
                    <i>{{ geneMutation.gene }}</i>
                  </td>
                </tr>
                <tr>
                  <td style="font-size: 18px !important; padding-left: 10px !important">
                    <b>Status</b>
                  </td>
                  <td *ngFor="
                                      let geneMutation of fusionGeneMutationList
                                        | slice : 0 : 8
                                    ">
                    <span *ngIf="
                                        geneMutation.status == 'Pathogenic' ||
                                        geneMutation.status == 'Likely Pathogenic' ||
                                        geneMutation.status == 'Detected'
                                      " style="color: red; font-weight: 600px"><b>{{ geneMutation.status }}</b></span>
                    <span *ngIf="geneMutation.status == 'VUS'" style="color: black; font-weight: 600px"><b>{{
                        geneMutation.status }}</b></span>
                    <span *ngIf="
                                        geneMutation.status == 'Not Detected' ||
                                        geneMutation.status == 'Benige'
                                      ">{{ geneMutation.status }}</span>
                  </td>
                </tr>
<br/>
              </table>
              <!-- <br/> -->
              <table class="staus_gene" *ngIf="
                                  fusionGeneMutationList != null &&
                                  fusionGeneMutationList.length > 8
                                " [ngClass]="'bg_yellow'">
                <tr style="background: #bfe6fa">
                  <td style="font-size: 18px !important; padding-left: 10px !important">
                    Gene
                  </td>
                  <td *ngFor="
                                      let geneMutation of fusionGeneMutationList
                                        | slice : 8 : 17
                                    ">
                    <i>{{ geneMutation.gene }}</i>
                  </td>
                </tr>
                <tr>
                  <td style="font-size: 18px !important; padding-left: 10px !important">
                    <b>Status</b>
                  </td>
                  <td *ngFor="
                                      let geneMutation of fusionGeneMutationList
                                        | slice : 8 : 17
                                    ">
                    <span *ngIf="
                                        geneMutation.status == 'Pathogenic' ||
                                        geneMutation.status == 'Likely Pathogenic' ||
                                        geneMutation.status == 'Detected'
                                      " style="color: red; font-weight: 600px"><b>{{ geneMutation.status }}</b></span>
                    <span *ngIf="geneMutation.status == 'VUS'" style="color: black; font-weight: 600px"><b>{{
                        geneMutation.status }}</b></span>
                    <span *ngIf="
                                        geneMutation.status == 'Not Detected' ||
                                        geneMutation.status == 'Benige'
                                      ">{{ geneMutation.status }}</span>
                  </td>
                </tr>
              </table>
            </div>

            <div *ngIf="
                                report.disclaimerNote != ' ' &&
                                report.disclaimerNote != null &&
                                fusionGeneMutationList.length > 0
                              ">
              <h6 style="color: #000"><b>Note: </b></h6>
              <div style="font-size: 14px !important;text-align: justify" [innerHtml]="report.disclaimerNote"></div>
              <!-- <div class="py-2" [innerHtml]='report.disclaimerNote' style=" text-align: justify;font-size: 14px!important;"></div> -->

              <!-- <p>The data size we had received for this sample was inadequate. However, with the patient's best interest in mind we have further processed the sample. Kindly correlate clinically before making any treatment decisions.</p> -->
              <!-- <p>The data size we had received for this sample was inadequate. However, with the patient's best interest in mind we have further processed the sample and a false negative status of the result cannot be ruled out. Kindly correlate clinically before making any treatment decisions.</p> -->
            </div>
            <!--  -->

            <div class="row mt-3" *ngIf="
                                otherCountSNV == true ||
                                reportPdfRes.allVariantsList.length != 0
                              ">
              <div class="col">
                <h6 class="font-weight-bold clr-cyan text-uppercase" [ngClass]="'txt-black'">
                  Variant Details:
                </h6>
              </div>
            </div>

            <div class="row" *ngIf="
                                otherCountSNV == true ||
                                reportPdfRes.allVariantsList.length != 0
                              ">
              <div class="col-12">
                <table class="page_1 cust_table w-100 mt-3 varient_detailsy" [ngClass]="'table_variant-blue'">
                  <thead>
                    <tr>
                      <th>Gene</th>
                      <th>Variant Location</th>
                      <th>Variant Consequence</th>
                      <th>Clinical Significance</th>
                      <th>Variant Type</th>
                      <th>Reference</th>
                    </tr>
                  </thead>

                  <!-- SNV -->
                  <tr class="font-weight-bold" *ngFor="
                                      let selectedReportedVariant of reportPdfRes.snvReportedVariantList
                                    ">
                    <td *ngIf="
                                        selectedReportedVariant.significance != 'NA' &&
                                        selectedReportedVariant.significance != 'Not Classified'
                                      " style="padding-left: 3px !important">
                      <i>{{ selectedReportedVariant.refGeneX }}</i>
                    </td>
                    <td *ngIf="
                                        selectedReportedVariant.significance != 'NA' &&
                                        selectedReportedVariant.significance != 'Not Classified'
                                      " style="padding-left: 3px !important">
                      {{ selectedReportedVariant.genomicAlteration }},<br />
                      {{ selectedReportedVariant.ensembleValue }},<br />
                      {{ selectedReportedVariant.exonNumber }}
                    </td>
                    <td *ngIf="
                                        selectedReportedVariant.significance != 'NA' &&
                                        selectedReportedVariant.significance != 'Not Classified'
                                      " style="padding-left: 3px !important">
                      <span *ngIf="selectedReportedVariant.selectedAAChangeknownGene">{{
                        selectedReportedVariant.selectedAAChangeknownGene|splitComma }}</span>,{{ selectedReportedVariant.mutantBurdenPercent * 0.01 | percent}}
                    </td>
                    <td *ngIf="
                                        selectedReportedVariant.significance != 'NA' &&
                                        selectedReportedVariant.significance != 'Not Classified'
                                      " style="padding-left: 3px !important">
                      {{ selectedReportedVariant.significance }}
                    </td>
                    <td *ngIf="
                                        selectedReportedVariant.significance != 'NA' &&
                                        selectedReportedVariant.significance != 'Not Classified'
                                      " style="padding-left: 3px !important">
                      {{ selectedReportedVariant.exonicFuncknownGene }}
                    </td>
                    <td *ngIf="
                                        selectedReportedVariant.significance != 'NA' &&
                                        selectedReportedVariant.significance != 'Not Classified'
                                      " style="padding-left: 3px !important">
                      {{ selectedReportedVariant.reference }}
                    </td>
                  </tr>

                  <!-- CNA -->
                  <tr *ngFor="
                                      let cnaReportedVariant of reportPdfRes.cnaReportedVariantList
                                    ">
                    <td *ngIf="
                                        cnaReportedVariant.significance != 'NA' &&
                                        cnaReportedVariant.significance != 'Not Classified'
                                      " style="padding-left: 3px !important">
                      <i>{{ cnaReportedVariant.gene }}</i>
                    </td>
                    <td *ngIf="
                                        cnaReportedVariant.significance != 'NA' &&
                                        cnaReportedVariant.significance != 'Not Classified'
                                      " style="padding-left: 3px !important">
                      chr{{ cnaReportedVariant.sample.split(":")[0] }}:g.{{
                      cnaReportedVariant.sample
                      .split(":")[1]
                      .replace("-", "_")
                      .split("|")[0]
                      }}
                    </td>
                    <td *ngIf="
                                        cnaReportedVariant.significance != 'NA' &&
                                        cnaReportedVariant.significance != 'Not Classified'
                                      " style="padding-left: 3px !important">
                      NA
                    </td>
                    <td *ngIf="
                                        cnaReportedVariant.significance != 'NA' &&
                                        cnaReportedVariant.significance != 'Not Classified'
                                      " style="padding-left: 3px !important">
                      {{ cnaReportedVariant.significance }}
                    </td>
                    <td *ngIf="
                                        cnaReportedVariant.significance != 'NA' &&
                                        cnaReportedVariant.significance != 'Not Classified'
                                      " style="padding-left: 3px !important">
                      Copy Number
                      {{ cnaReportedVariant.sample.split(":")[2] | titlecase }}
                      ({{ cnaReportedVariant.sample.split("|")[1].split(":")[0] }})
                    </td>
                    <td *ngIf="
                                        cnaReportedVariant.significance != 'NA' &&
                                        cnaReportedVariant.significance != 'Not Classified'
                                      " style="padding-left: 3px !important">
                      {{ cnaReportedVariant.reference }}
                    </td>
                  </tr>
                  <!-- Fusion -->
                  <tr *ngFor="
                                      let fusionReportedVariant of reportPdfRes.fusionReportedVariantList
                                    ">
                    <td *ngIf="
                                        fusionReportedVariant.significance != 'NA' &&
                                        fusionReportedVariant.significance != 'Not Classified'
                                      " style="padding-left: 3px !important">
                      <i>{{ fusionReportedVariant.fusionGene }}</i>
                    </td>
                    <td *ngIf="
                                        fusionReportedVariant.significance != 'NA' &&
                                        fusionReportedVariant.significance != 'Not Classified'
                                      " style="padding-left: 3px !important">
                      {{ fusionReportedVariant.leftBreakPoint }}<br />{{
                      fusionReportedVariant.rightBreakPoint
                      }}
                    </td>
                    <td *ngIf="
                                        fusionReportedVariant.significance != 'NA' &&
                                        fusionReportedVariant.significance != 'Not Classified'
                                      " style="padding-left: 3px !important">
                      NA
                    </td>
                    <td *ngIf="
                                        fusionReportedVariant.significance != 'NA' &&
                                        fusionReportedVariant.significance != 'Not Classified'
                                      " style="padding-left: 3px !important">
                      {{ fusionReportedVariant.significance }}
                    </td>
                    <td *ngIf="
                                        fusionReportedVariant.significance != 'NA' &&
                                        fusionReportedVariant.significance != 'Not Classified'
                                      " style="padding-left: 3px !important">
                      Gene Fusions
                    </td>
                    <td *ngIf="
                                        fusionReportedVariant.significance != 'NA' &&
                                        fusionReportedVariant.significance != 'Not Classified'
                                      " style="padding-left: 3px !important">
                      {{ fusionReportedVariant.reference }}
                    </td>
                  </tr>
                </table>
                <p *ngIf="
                                    otherCountSNV == true ||
                                    reportPdfRes.allVariantsList.length > 0
                                  " style="font-size: 13px !important" class="mt-3">
                  <i> *NA: Not Applicable</i>
                </p>
              </div>
            </div>

            <div class="row mt-5" *ngIf="
            !variantFlag &&
            report.addNotes != null && report.addNotes != '<div></div>'
          ">
    <div class="col">
      <h6 class="py-2 h6_bg" [ngClass]="'yellow'">Additional Notes</h6>
      <div class="py-2" [innerHtml]="report.addNotes" style="text-align: justify; font-size: 14px !important"></div>
    </div>
  </div>

            <!--  -->
                  <div class="row footer">
          <!-- <div class="col-12">
            <div class="pb-2 pl-4 text-center">
              <div class="d-block">
                  <span class="font-weight-bold" style="font-size: 13px !important"
                    >4baseCare (Genomics Tarang ODC),</span
                  >
                <span>
                  SJP2-S1-1F- C wing, Wipro Limited, SEZ, Sarjapur 2, Sy.No.69 (P),
                </span>
              </div>
              <div class="d-block">
                <span>Doddakannelli, Sarjapura Road,</span>
                <span> Bengaluru 560035, KA, India</span></div>


            </div>
          </div>
          <div class="col-12 pt-1 ">
            <p  style="font-size: 8px!important; line-height: 14px!important;">{{footerDiscription}} </p>
          </div> -->


        </div>
          </div>


          <!--  -->
          <div id="page2_1" *ngIf="
                              variantFlag && newsnvHotspotTrailResList?.lenght<=0 &&
                              (report.addNotes != '<div></div>' ||
                                otherCountSNV == true ||
                                reportPdfRes.allVariantsList.length > 0 ||
                                (report.disclaimerNote != ' ' && report.disclaimerNote != null))
                            " class="container position-relative page" style="background-color: white">
                            <div class="d-flex justify-content-between logo_panel py-3">
                              <div class="logo_part">
                                <img
                                style="width:240px!important" src="../../../assets/hcg-new-logo.jpeg"
                                  alt=""
                                  class="logo_first"
                                />
                              </div>
                              <div style=" border-left: 2px solid #fab216;padding-top:10px;
                              height: 120px;"></div>

                              <div class="logo_part text-center" style="font-family:arial Mt!important">

                                <h5 style="font-weight: bolder;margin-bottom:0px!important;">Triesta Sciences</h5>
                                <p style="font-size: 15px!important;">
                                  A Unit of Health Care Global Enterprises Ltd <br/>
                                  5th Floor, Tower 1, #8, P.Kalinga Rao Road,<br/>
                                  Sampangiram Nagar, Bengaluru-560027. Tel: 080-40206305
                                </p>
                                <h6 style="font-weight: bolder;font-size:15px!important">Department of Molecular and Clinical Genomics</h6>
                              </div>
                              <div style=" border-left: 2px solid #fab216;padding-top:10px;
                              height: 120px;"></div>
                              <div class="logo_part">
                                <img  style="width:240px!important; margin-top:40px!important"
                                  src="../../../assets//oncoquer1.png"
                                  alt=""
                                  class="logo_three"
                                />
                              </div>
                            </div>
            <div class="row header mt-1">
              <div>
                <!-- <div class="col-3">
                                  <img src="../../../assets/logo.png" alt="" style="width: 140px" />
                                </div> -->
              </div>
              <div></div>
              <div class="col">
                <div class="patient-detailes ml-auto float-right">
                  <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
                    <li>
                      Patient Id <br />
                      <span>{{ report.patient?.organisationPatientId }} </span>
                    </li>
                    <li class="ml-4">
                      Patient Name<br />
                      <span>{{ report.patient?.name }}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <!--  -->
              <div *ngIf="report.clientType == '4basecare_client'">
                <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
                  </div>
                </div>
                <div *ngIf="report.clientName == 'path_labs'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
                  </div>
                </div>
              </div>
            </div>

            <!--  -->

            <div class="row mt-5" *ngIf="
            !variantFlag &&
            report.addNotes != null && report.addNotes != '<div></div>'
          ">
    <div class="col">
      <h6 class="py-2 h6_bg" [ngClass]="'yellow'">Additional Notes</h6>
      <div class="py-2" [innerHtml]="report.addNotes" style="text-align: justify; font-size: 14px !important"></div>
    </div>
  </div>

            <!--  -->
                  <div class="row footer">
          <!-- <div class="col-12">
            <div class="pb-2 pl-4 text-center">
              <div class="d-block">
                  <span class="font-weight-bold" style="font-size: 13px !important"
                    >4baseCare (Genomics Tarang ODC),</span
                  >
                <span>
                  SJP2-S1-1F- C wing, Wipro Limited, SEZ, Sarjapur 2, Sy.No.69 (P),
                </span>
              </div>
              <div class="d-block">
                <span>Doddakannelli, Sarjapura Road,</span>
                <span> Bengaluru 560035, KA, India</span></div>


            </div>
          </div>
          <div class="col-12 pt-1 ">
            <p  style="font-size: 8px!important; line-height: 14px!important;">{{footerDiscription}} </p>
          </div> -->


        </div>
          </div>
          <!-- -->
          <!-- page 3 -->
          <div id="page3" *ngIf="reportPdfRes.drugsResList.length > 0" class="container position-relative page"
            style="background-color: white">
            <div class="d-flex justify-content-between logo_panel py-3">
              <div class="logo_part">
                <img style="width:240px!important" src="../../../assets/hcg-new-logo.jpeg" alt="" class="logo_first" />
              </div>
              <div style=" border-left: 2px solid #fab216;padding-top:10px;
                        height: 120px;"></div>

              <div class="logo_part text-center" style="font-family:arial Mt!important">

                                <h5 style="font-weight: bolder;margin-bottom:0px!important;">Triesta Sciences</h5>
                <p style="font-size: 15px!important;">
                  A Unit of Health Care Global Enterprises Ltd <br />
                  5th Floor, Tower 1, #8, P.Kalinga Rao Road,<br />
                  Sampangiram Nagar, Bengaluru-560027. Tel: 080-40206305
                </p>
                <h6 style="font-weight: bolder;font-size:15px!important">Department of Molecular and Clinical Genomics</h6>
              </div>
              <div style=" border-left: 2px solid #fab216;padding-top:10px;
                        height: 120px;"></div>
              <div class="logo_part">
                <img style="width:240px!important; margin-top:40px!important" src="../../../assets//oncoquer1.png" alt=""
                  class="logo_three" />
              </div>
            </div>
            <div class="row header mt-1">
              <div>
                <!-- <div class="col-3">
                            <img src="../../../assets/logo.png" alt="" style="width: 140px" />
                          </div> -->
              </div>
              <div></div>
              <div class="col">
                <div class="patient-detailes ml-auto float-right">
                  <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
                    <li>
                      Patient Id <br />
                      <span>{{ report.patient?.organisationPatientId }}</span>
                    </li>
                    <li class="ml-4">
                      Patient Name<br />
                      <span>{{ report.patient?.name }}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <!--  -->
              <div *ngIf="report.clientType == '4basecare_client'">
                <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
                  </div>
                </div>
                <div *ngIf="report.clientName == 'path_labs'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
                  </div>
                </div>
              </div>
            </div>
            <!--  -->
            <div class="mar_top"></div>
            <!--  -->

            <h6 class="py-2 h6_bg" [ngClass]="'yellow'">
              Alterations With Clinically Relevant Therapies
            </h6>
            <!--  -->
            <div class="row">
              <div class="col-12">
                <table class="page_1 cust_table w-100 mt-3 varient_detailsy prognastictable1">
                  <thead>
                    <tr>
                      <th>Gene (Alterations) - Drug association</th>
                      <th>Summary</th>
                    </tr>
                  </thead>
                  <!-- SNV -->

                  <tbody>
                    <tr class="font-weight-bold" *ngFor="
                                                                          let drugsRes of reportPdfRes.drugsResList | slice : 0 : 4;
                                                                          let i = index
                                                                        ">
                      <td>

                            <i>{{ drugsRes.gene.replace("Fusion","") }}</i><span *ngIf="drugsRes.gene.includes('Fusion')">Fusion</span> {{ drugsRes.pposition?.replace(' ','') }} :
                            <br />
                            <span *ngFor="let drug of drugsRes.drugs; let i = index">
                              {{ drug.name
                              }}<span *ngIf="drugsRes.drugs.length - 1 != i">,</span>
                            </span>
                            - {{ drugsRes.association }}
                            <p>{{ drugsRes.cancerType }}</p>

                      </td>
                      <td>
                        <div [innerHtml]="drugsRes.summary"></div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div class="row footer">
              <!-- <div class="col-12">
                      <div class="pb-2 pl-4 text-center">
                        <div class="d-block">
                            <span class="font-weight-bold" style="font-size: 13px !important"
                              >4baseCare (Genomics Tarang ODC),</span
                            >
                          <span>
                            SJP2-S1-1F- C wing, Wipro Limited, SEZ, Sarjapur 2, Sy.No.69 (P),
                          </span>
                        </div>
                        <div class="d-block">
                          <span>Doddakannelli, Sarjapura Road,</span>
                          <span> Bengaluru 560035, KA, India</span></div>


                      </div>
                    </div>
                    <div class="col-12 pt-1 ">
                      <p  style="font-size: 8px!important; line-height: 14px!important;">{{footerDiscription}} </p>
                    </div> -->


            </div>
          </div>

          <!-- page 3_1 -->
          <div id="page3_1" *ngIf="reportPdfRes.drugsResList.length > 4" class="container position-relative page"
            style="background-color: white">
            <div class="d-flex justify-content-between logo_panel py-3">
              <div class="logo_part">
                <img style="width:240px!important" src="../../../assets/hcg-new-logo.jpeg" alt="" class="logo_first" />
              </div>
              <div style=" border-left: 2px solid #fab216;padding-top:10px;
                        height: 120px;"></div>

              <div class="logo_part text-center" style="font-family:arial Mt!important">

                                <h5 style="font-weight: bolder;margin-bottom:0px!important;">Triesta Sciences</h5>
                <p style="font-size: 15px!important;">
                  A Unit of Health Care Global Enterprises Ltd <br />
                  5th Floor, Tower 1, #8, P.Kalinga Rao Road,<br />
                  Sampangiram Nagar, Bengaluru-560027. Tel: 080-40206305
                </p>
                <h6 style="font-weight: bolder;font-size:15px!important">Department of Molecular and Clinical Genomics</h6>
              </div>
              <div style=" border-left: 2px solid #fab216;padding-top:10px;
                        height: 120px;"></div>
              <div class="logo_part">
                <img style="width:240px!important; margin-top:40px!important" src="../../../assets//oncoquer1.png" alt=""
                  class="logo_three" />
              </div>
            </div>
            <div class="row header mt-1">
              <div>
                <!-- <div class="col-3">
                            <img src="../../../assets/logo.png" alt="" style="width: 140px" />
                          </div> -->
              </div>
              <div></div>
              <div class="col">
                <div class="patient-detailes ml-auto float-right">
                  <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
                    <li>
                      Patient Id <br />
                      <span>{{ report.patient?.organisationPatientId }}</span>
                    </li>
                    <li class="ml-4">
                      Patient Name<br />
                      <span>{{ report.patient?.name }}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <!--  -->
              <div *ngIf="report.clientType == '4basecare_client'">
                <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
                  </div>
                </div>
                <div *ngIf="report.clientName == 'path_labs'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
                  </div>
                </div>
              </div>
            </div>
            <!--  -->
            <div class="mar_top"></div>
            <!--  -->

            <h6 class="py-2 h6_bg" [ngClass]="'yellow'">
              Alterations With Clinically Relevant Therapies
            </h6>
            <!--  -->
            <div class="row">
              <div class="col-12">
                <table class="page_1 cust_table w-100 mt-3 varient_detailsy prognastictable1">
                  <thead>
                    <tr>
                      <th>Gene (Alterations) - Drug association</th>
                      <th>Summary</th>
                    </tr>
                  </thead>
                  <!-- SNV -->

                  <tbody>
                    <tr *ngFor="
                                                                                    let drugsRes of reportPdfRes.drugsResList | slice : 4 : 8;
                                                                                    let i = index
                                                                                  ">
                      <td>

                            <i>{{ drugsRes.gene.replace("Fusion","") }}</i><span *ngIf="drugsRes.gene.includes('Fusion')">Fusion</span> {{ drugsRes.pposition?.replace(' ','') }} :
                            <br />
                            <span *ngFor="let drug of drugsRes.drugs; let i = index">
                              {{ drug.name
                              }}<span *ngIf="drugsRes.drugs.length - 1 != i">,</span>
                            </span>
                            - {{ drugsRes.association }}
                            <p>{{ drugsRes.cancerType }}</p>

                      </td>
                      <td>
                        <div [innerHtml]="drugsRes.summary"></div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <!--  -->

            <!--  -->
            <div class="row footer">
              <!-- <div class="col-12">
                      <div class="pb-2 pl-4 text-center">
                        <div class="d-block">
                            <span class="font-weight-bold" style="font-size: 13px !important"
                              >4baseCare (Genomics Tarang ODC),</span
                            >
                          <span>
                            SJP2-S1-1F- C wing, Wipro Limited, SEZ, Sarjapur 2, Sy.No.69 (P),
                          </span>
                        </div>
                        <div class="d-block">
                          <span>Doddakannelli, Sarjapura Road,</span>
                          <span> Bengaluru 560035, KA, India</span></div>


                      </div>
                    </div>
                    <div class="col-12 pt-1 ">
                      <p  style="font-size: 8px!important; line-height: 14px!important;">{{footerDiscription}} </p>
                    </div> -->


            </div>
          </div>

          <!-- page 3_2 -->
          <div id="page3_2" *ngIf="reportPdfRes.drugsResList.length > 8" class="container position-relative page"
            style="background-color: white">
            <div class="d-flex justify-content-between logo_panel py-3">
              <div class="logo_part">
                <img style="width:240px!important" src="../../../assets/hcg-new-logo.jpeg" alt="" class="logo_first" />
              </div>
              <div style=" border-left: 2px solid #fab216;padding-top:10px;
                        height: 120px;"></div>

              <div class="logo_part text-center" style="font-family:arial Mt!important">

                                <h5 style="font-weight: bolder;margin-bottom:0px!important;">Triesta Sciences</h5>
                <p style="font-size: 15px!important;">
                  A Unit of Health Care Global Enterprises Ltd <br />
                  5th Floor, Tower 1, #8, P.Kalinga Rao Road,<br />
                  Sampangiram Nagar, Bengaluru-560027. Tel: 080-40206305
                </p>
                <h6 style="font-weight: bolder;font-size:15px!important">Department of Molecular and Clinical Genomics</h6>
              </div>
              <div style=" border-left: 2px solid #fab216;padding-top:10px;
                        height: 120px;"></div>
              <div class="logo_part">
                <img style="width:240px!important; margin-top:40px!important" src="../../../assets//oncoquer1.png" alt=""
                  class="logo_three" />
              </div>
            </div>
            <div class="row header mt-1">
              <div>
                <!-- <div class="col-3">
                            <img src="../../../assets/logo.png" alt="" style="width: 140px" />
                          </div> -->
              </div>
              <div></div>
              <div class="col">
                <div class="patient-detailes ml-auto float-right">
                  <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
                    <li>
                      Patient Id <br />
                      <span>{{ report.patient?.organisationPatientId }}</span>
                    </li>
                    <li class="ml-4">
                      Patient Name<br />
                      <span>{{ report.patient?.name }}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <!--  -->
              <div *ngIf="report.clientType == '4basecare_client'">
                <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
                  </div>
                </div>
                <div *ngIf="report.clientName == 'path_labs'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
                  </div>
                </div>
              </div>
            </div>
            <!--  -->
            <div class="mar_top"></div>
            <!--  -->

            <h6 class="py-2 h6_bg" [ngClass]="'yellow'">
              Alterations With Clinically Relevant Therapies
            </h6>
            <!--  -->
            <div class="row">
              <div class="col-12">
                <table class="page_1 cust_table w-100 mt-3 varient_detailsy prognastictable1">
                  <thead>
                    <tr>
                      <th>Gene (Alterations) - Drug association</th>
                      <th>Summary</th>
                    </tr>
                  </thead>
                  <!-- SNV -->

                  <tbody>
                    <tr *ngFor="
                                                                                                        let drugsRes of reportPdfRes.drugsResList | slice : 8 : 12;
                                                                                                        let i = index
                                                                                                      ">
                      <td>

                            <i>{{ drugsRes.gene.replace("Fusion","") }}</i><span *ngIf="drugsRes.gene.includes('Fusion')">Fusion</span> {{ drugsRes.pposition?.replace(' ','')}} :
                            <br />
                            <span *ngFor="let drug of drugsRes.drugs; let i = index">
                              {{ drug.name
                              }}<span *ngIf="drugsRes.drugs.length - 1 != i">,</span>
                            </span>
                            - {{ drugsRes.association }}
                            <p>{{ drugsRes.cancerType }}</p>

                      </td>
                      <td>
                        <div [innerHtml]="drugsRes.summary"></div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <!--  -->

            <!--  -->
            <div class="row footer">



            </div>
          </div>

          <!-- page 3_3 -->
          <div id="page3_3" *ngIf="reportPdfRes.drugsResList.length > 12" class="container position-relative page"
            style="background-color: white">
            <div class="d-flex justify-content-between logo_panel py-3">
              <div class="logo_part">
                <img style="width:240px!important" src="../../../assets/hcg-new-logo.jpeg" alt="" class="logo_first" />
              </div>
              <div style=" border-left: 2px solid #fab216;padding-top:10px;
                        height: 120px;"></div>

              <div class="logo_part text-center" style="font-family:arial Mt!important">

                                <h5 style="font-weight: bolder;margin-bottom:0px!important;">Triesta Sciences</h5>
                <p style="font-size: 15px!important;">
                  A Unit of Health Care Global Enterprises Ltd <br />
                  5th Floor, Tower 1, #8, P.Kalinga Rao Road,<br />
                  Sampangiram Nagar, Bengaluru-560027. Tel: 080-40206305
                </p>
                <h6 style="font-weight: bolder;font-size:15px!important">Department of Molecular and Clinical Genomics</h6>
              </div>
              <div style=" border-left: 2px solid #fab216;padding-top:10px;
                        height: 120px;"></div>
              <div class="logo_part">
                <img style="width:240px!important; margin-top:40px!important" src="../../../assets//oncoquer1.png" alt=""
                  class="logo_three" />
              </div>
            </div>
            <div class="row header mt-1">
              <div>
                <!-- <div class="col-3">
                            <img src="../../../assets/logo.png" alt="" style="width: 140px" />
                          </div> -->
              </div>
              <div></div>
              <div class="col">
                <div class="patient-detailes ml-auto float-right">
                  <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
                    <li>
                      Patient Id <br />
                      <span>{{ report.patient?.organisationPatientId }}</span>
                    </li>
                    <li class="ml-4">
                      Patient Name<br />
                      <span>{{ report.patient?.name }}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <!--  -->
              <div *ngIf="report.clientType == '4basecare_client'">
                <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
                  </div>
                </div>
                <div *ngIf="report.clientName == 'path_labs'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
                  </div>
                </div>
              </div>
            </div>
            <!--  -->
            <div class="mar_top"></div>
            <!--  -->

            <h6 class="py-2 h6_bg" [ngClass]="'yellow'">
              Alterations With Clinically Relevant Therapies
            </h6>
            <!--  -->
            <div class="row">
              <div class="col-12">
                <table class="page_1 cust_table w-100 mt-3 varient_detailsy prognastictable1">
                  <thead>
                    <tr>
                      <th>Gene (Alterations) - Drug association</th>
                      <th>Summary</th>
                    </tr>
                  </thead>
                  <!-- SNV -->

                  <tbody>
                    <tr *ngFor="
                                                                                                                              let drugsRes of reportPdfRes.drugsResList | slice : 12 : 16;
                                                                                                                              let i = index
                                                                                                                            ">
                      <td>

                            <i>{{ drugsRes.gene.replace("Fusion","") }}</i><span *ngIf="drugsRes.gene.includes('Fusion')">Fusion</span> {{ drugsRes.pposition?.replace(' ','')}} :
                            <br />
                            <span *ngFor="let drug of drugsRes.drugs; let i = index">
                              {{ drug.name
                              }}<span *ngIf="drugsRes.drugs.length - 1 != i">,</span>
                            </span>
                            - {{ drugsRes.association }}
                            <p>{{ drugsRes.cancerType }}</p>

                      </td>
                      <td>
                        <div [innerHtml]="drugsRes.summary"></div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <!--  -->

            <!--  -->
            <div class="row footer">



            </div>
          </div>

          <!-- page 3_4 -->
          <div id="page3_4" *ngIf="reportPdfRes.drugsResList.length > 16" class="container position-relative page"
            style="background-color: white">
            <div class="d-flex justify-content-between logo_panel py-3">
              <div class="logo_part">
                <img style="width:240px!important" src="../../../assets/hcg-new-logo.jpeg" alt="" class="logo_first" />
              </div>
              <div style=" border-left: 2px solid #fab216;padding-top:10px;
                        height: 120px;"></div>

              <div class="logo_part text-center" style="font-family:arial Mt!important">

                                <h5 style="font-weight: bolder;margin-bottom:0px!important;">Triesta Sciences</h5>
                <p style="font-size: 15px!important;">
                  A Unit of Health Care Global Enterprises Ltd <br />
                  5th Floor, Tower 1, #8, P.Kalinga Rao Road,<br />
                  Sampangiram Nagar, Bengaluru-560027. Tel: 080-40206305
                </p>
                <h6 style="font-weight: bolder;font-size:15px!important">Department of Molecular and Clinical Genomics</h6>
              </div>
              <div style=" border-left: 2px solid #fab216;padding-top:10px;
                        height: 120px;"></div>
              <div class="logo_part">
                <img style="width:240px!important; margin-top:40px!important" src="../../../assets//oncoquer1.png" alt=""
                  class="logo_three" />
              </div>
            </div>
            <div class="row header mt-1">
              <div>
                <!-- <div class="col-3">
                            <img src="../../../assets/logo.png" alt="" style="width: 140px" />
                          </div> -->
              </div>
              <div></div>
              <div class="col">
                <div class="patient-detailes ml-auto float-right">
                  <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
                    <li>
                      Patient Id <br />
                      <span>{{ report.patient?.organisationPatientId }}</span>
                    </li>
                    <li class="ml-4">
                      Patient Name<br />
                      <span>{{ report.patient?.name }}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <!--  -->
              <div *ngIf="report.clientType == '4basecare_client'">
                <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
                  </div>
                </div>
                <div *ngIf="report.clientName == 'path_labs'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
                  </div>
                </div>
              </div>
            </div>
            <!--  -->
            <div class="mar_top"></div>
            <!--  -->

            <h6 class="py-2 h6_bg" [ngClass]="'yellow'">
              Alterations With Clinically Relevant Therapies
            </h6>
            <!--  -->
            <div class="row">
              <div class="col-12">
                <table class="page_1 cust_table w-100 mt-3 varient_detailsy prognastictable1">
                  <thead>
                    <tr>
                      <th>Gene (Alterations) - Drug association</th>
                      <th>Summary</th>
                    </tr>
                  </thead>
                  <!-- SNV -->

                  <tbody>
                    <tr *ngFor="
                                                                                                                    let drugsRes of reportPdfRes.drugsResList | slice : 16 : 20;
                                                                                                                    let i = index
                                                                                                                  ">
                      <td>

                            <i>{{ drugsRes.gene.replace("Fusion","") }}</i><span *ngIf="drugsRes.gene.includes('Fusion')">Fusion</span> {{ drugsRes.pposition?.replace(' ','')}} :
                            <br />
                            <span *ngFor="let drug of drugsRes.drugs; let i = index">
                              {{ drug.name
                              }}<span *ngIf="drugsRes.drugs.length - 1 != i">,</span>
                            </span>
                            - {{ drugsRes.association }}
                            <p>{{ drugsRes.cancerType }}</p>

                      </td>
                      <td>
                        <div [innerHtml]="drugsRes.summary"></div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <!--  -->

            <!--  -->
            <div class="row footer">



            </div>
          </div>

          <!-- page 3_5 -->
          <div id="page3_5" *ngIf="reportPdfRes.drugsResList.length > 20" class="container position-relative page"
            style="background-color: white">
            <div class="d-flex justify-content-between logo_panel py-3">
              <div class="logo_part">
                <img style="width:240px!important" src="../../../assets/hcg-new-logo.jpeg" alt="" class="logo_first" />
              </div>
              <div style=" border-left: 2px solid #fab216;padding-top:10px;
                        height: 120px;"></div>

              <div class="logo_part text-center" style="font-family:arial Mt!important">

                                <h5 style="font-weight: bolder;margin-bottom:0px!important;">Triesta Sciences</h5>
                <p style="font-size: 15px!important;">
                  A Unit of Health Care Global Enterprises Ltd <br />
                  5th Floor, Tower 1, #8, P.Kalinga Rao Road,<br />
                  Sampangiram Nagar, Bengaluru-560027. Tel: 080-40206305
                </p>
                <h6 style="font-weight: bolder;font-size:15px!important">Department of Molecular and Clinical Genomics</h6>
              </div>
              <div style=" border-left: 2px solid #fab216;padding-top:10px;
                        height: 120px;"></div>
              <div class="logo_part">
                <img style="width:240px!important; margin-top:40px!important" src="../../../assets//oncoquer1.png" alt=""
                  class="logo_three" />
              </div>
            </div>
            <div class="row header mt-1">
              <div>
                <!-- <div class="col-3">
                            <img src="../../../assets/logo.png" alt="" style="width: 140px" />
                          </div> -->
              </div>
              <div></div>
              <div class="col">
                <div class="patient-detailes ml-auto float-right">
                  <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
                    <li>
                      Patient Id <br />
                      <span>{{ report.patient?.organisationPatientId }}</span>
                    </li>
                    <li class="ml-4">
                      Patient Name<br />
                      <span>{{ report.patient?.name }}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <!--  -->
              <div *ngIf="report.clientType == '4basecare_client'">
                <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
                  </div>
                </div>
                <div *ngIf="report.clientName == 'path_labs'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
                  </div>
                </div>
              </div>
            </div>
            <!--  -->
            <div class="mar_top"></div>
            <!--  -->

            <h6 class="py-2 h6_bg" [ngClass]="'yellow'">
              Alterations With Clinically Relevant Therapies
            </h6>
            <!--  -->
            <div class="row">
              <div class="col-12">
                <table class="page_1 cust_table w-100 mt-3 varient_detailsy prognastictable1">
                  <thead>
                    <tr>
                      <th>Gene (Alterations) - Drug association</th>
                      <th>Summary</th>
                    </tr>
                  </thead>
                  <!-- SNV -->

                  <tbody>
                    <tr *ngFor="
                                let drugsRes of reportPdfRes.drugsResList | slice : 20 : 24; let i = index ">
                      <td>

                            <i>{{ drugsRes.gene.replace("Fusion","") }}</i><span *ngIf="drugsRes.gene.includes('Fusion')">Fusion</span> {{ drugsRes.pposition?.replace(' ','')}} :
                            <br />
                            <span *ngFor="let drug of drugsRes.drugs; let i = index">
                              {{ drug.name
                              }}<span *ngIf="drugsRes.drugs.length - 1 != i">,</span>
                            </span>
                            - {{ drugsRes.association }}
                            <p>{{ drugsRes.cancerType }}</p>

                      </td>
                      <td>
                        <div [innerHtml]="drugsRes.summary"></div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <!--  -->

            <!--  -->
            <div class="row footer">



            </div>
          </div>

          <!-- page 3_6 -->
          <div id="page3_6" *ngIf="reportPdfRes.drugsResList.length > 24" class="container position-relative page"
            style="background-color: white">
            <div class="d-flex justify-content-between logo_panel py-3">
              <div class="logo_part">
                <img style="width:240px!important" src="../../../assets/hcg-new-logo.jpeg" alt="" class="logo_first" />
              </div>
              <div style=" border-left: 2px solid #fab216;padding-top:10px;
                        height: 120px;"></div>

              <div class="logo_part text-center" style="font-family:arial Mt!important">

                                <h5 style="font-weight: bolder;margin-bottom:0px!important;">Triesta Sciences</h5>
                <p style="font-size: 15px!important;">
                  A Unit of Health Care Global Enterprises Ltd <br />
                  5th Floor, Tower 1, #8, P.Kalinga Rao Road,<br />
                  Sampangiram Nagar, Bengaluru-560027. Tel: 080-40206305
                </p>
                <h6 style="font-weight: bolder;font-size:15px!important">Department of Molecular and Clinical Genomics</h6>
              </div>
              <div style=" border-left: 2px solid #fab216;padding-top:10px;
                        height: 120px;"></div>
              <div class="logo_part">
                <img style="width:240px!important; margin-top:40px!important" src="../../../assets//oncoquer1.png" alt=""
                  class="logo_three" />
              </div>
            </div>
            <div class="row header mt-1">
              <div>
                <!-- <div class="col-3">
                            <img src="../../../assets/logo.png" alt="" style="width: 140px" />
                          </div> -->
              </div>
              <div></div>
              <div class="col">
                <div class="patient-detailes ml-auto float-right">
                  <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
                    <li>
                      Patient Id <br />
                      <span>{{ report.patient?.organisationPatientId }}</span>
                    </li>
                    <li class="ml-4">
                      Patient Name<br />
                      <span>{{ report.patient?.name }}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <!--  -->
              <div *ngIf="report.clientType == '4basecare_client'">
                <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
                  </div>
                </div>
                <div *ngIf="report.clientName == 'path_labs'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
                  </div>
                </div>
              </div>
            </div>
            <!--  -->
            <div class="mar_top"></div>
            <!--  -->

            <h6 class="py-2 h6_bg" [ngClass]="'yellow'">
              Alterations With Clinically Relevant Therapies
            </h6>
            <!--  -->
            <div class="row">
              <div class="col-12">
                <table class="page_1 cust_table w-100 mt-3 varient_detailsy prognastictable1">
                  <thead>
                    <tr>
                      <th>Gene (Alterations) - Drug association</th>
                      <th>Summary</th>
                    </tr>
                  </thead>
                  <!-- SNV -->

                  <tbody>
                    <tr *ngFor=" let drugsRes of reportPdfRes.drugsResList | slice : 24 : 28; let i = index ">
                      <td>

                            <i>{{ drugsRes.gene.replace("Fusion","") }}</i><span *ngIf="drugsRes.gene.includes('Fusion')">Fusion</span> {{ drugsRes.pposition?.replace(' ','')}} :
                            <br />
                            <span *ngFor="let drug of drugsRes.drugs; let i = index">
                              {{ drug.name
                              }}<span *ngIf="drugsRes.drugs.length - 1 != i">,</span>
                            </span>
                            - {{ drugsRes.association }}
                            <p>{{ drugsRes.cancerType }}</p>

                      </td>
                      <td>
                        <div [innerHtml]="drugsRes.summary"></div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <!--  -->

            <!--  -->
            <div class="row footer">



            </div>
          </div>

          <!-- page 3_7 -->
          <div id="page3_7" *ngIf="reportPdfRes.drugsResList.length > 28" class="container position-relative page"
            style="background-color: white">
            <div class="d-flex justify-content-between logo_panel py-3">
              <div class="logo_part">
                <img style="width:240px!important" src="../../../assets/hcg-new-logo.jpeg" alt="" class="logo_first" />
              </div>
              <div style=" border-left: 2px solid #fab216;padding-top:10px;
                        height: 120px;"></div>

              <div class="logo_part text-center" style="font-family:arial Mt!important">

                                <h5 style="font-weight: bolder;margin-bottom:0px!important;">Triesta Sciences</h5>
                <p style="font-size: 15px!important;">
                  A Unit of Health Care Global Enterprises Ltd <br />
                  5th Floor, Tower 1, #8, P.Kalinga Rao Road,<br />
                  Sampangiram Nagar, Bengaluru-560027. Tel: 080-40206305
                </p>
                <h6 style="font-weight: bolder;font-size:15px!important">Department of Molecular and Clinical Genomics</h6>
              </div>
              <div style=" border-left: 2px solid #fab216;padding-top:10px;
                        height: 120px;"></div>
              <div class="logo_part">
                <img style="width:240px!important; margin-top:40px!important" src="../../../assets//oncoquer1.png" alt=""
                  class="logo_three" />
              </div>
            </div>
            <div class="row header mt-1">
              <div>
                <!-- <div class="col-3">
                            <img src="../../../assets/logo.png" alt="" style="width: 140px" />
                          </div> -->
              </div>
              <div></div>
              <div class="col">
                <div class="patient-detailes ml-auto float-right">
                  <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
                    <li>
                      Patient Id <br />
                      <span>{{ report.patient?.organisationPatientId }}</span>
                    </li>
                    <li class="ml-4">
                      Patient Name<br />
                      <span>{{ report.patient?.name }}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <!--  -->
              <div *ngIf="report.clientType == '4basecare_client'">
                <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
                  </div>
                </div>
                <div *ngIf="report.clientName == 'path_labs'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
                  </div>
                </div>
              </div>
            </div>
            <!--  -->
            <div class="mar_top"></div>
            <!--  -->

            <h6 class="py-2 h6_bg" [ngClass]="'yellow'">
              Alterations With Clinically Relevant Therapies
            </h6>
            <!--  -->
            <div class="row">
              <div class="col-12">
                <table class="page_1 cust_table w-100 mt-3 varient_detailsy prognastictable1">
                  <thead>
                    <tr>
                      <th>Gene (Alterations) - Drug association</th>
                      <th>Summary</th>
                    </tr>
                  </thead>
                  <!-- SNV -->

                  <tbody>
                    <tr
                      *ngFor="
                                                                                                                                                                                    let drugsRes of reportPdfRes.drugsResList | slice : 28 : 32;
                                                                                                                                                                                    let i = index
                                                                                                                                                                                  ">
                      <td>

                            <i>{{ drugsRes.gene.replace("Fusion","") }}</i><span *ngIf="drugsRes.gene.includes('Fusion')">Fusion</span> {{ drugsRes.pposition?.replace(' ','')}} :
                            <br />
                            <span *ngFor="let drug of drugsRes.drugs; let i = index">
                              {{ drug.name
                              }}<span *ngIf="drugsRes.drugs.length - 1 != i">,</span>
                            </span>
                            - {{ drugsRes.association }}
                            <p>{{ drugsRes.cancerType }}</p>

                      </td>
                      <td>
                        <div [innerHtml]="drugsRes.summary"></div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <!--  -->

            <!--  -->
            <div class="row footer">



            </div>
          </div>

          <!-- page 3_8 -->
          <div id="page3_8" *ngIf="reportPdfRes.drugsResList.length > 32" class="container position-relative page"
            style="background-color: white">
            <div class="d-flex justify-content-between logo_panel py-3">
              <div class="logo_part">
                <img style="width:240px!important" src="../../../assets/hcg-new-logo.jpeg" alt="" class="logo_first" />
              </div>
              <div style=" border-left: 2px solid #fab216;padding-top:10px;
                        height: 120px;"></div>

              <div class="logo_part text-center" style="font-family:arial Mt!important">

                                <h5 style="font-weight: bolder;margin-bottom:0px!important;">Triesta Sciences</h5>
                <p style="font-size: 15px!important;">
                  A Unit of Health Care Global Enterprises Ltd <br />
                  5th Floor, Tower 1, #8, P.Kalinga Rao Road,<br />
                  Sampangiram Nagar, Bengaluru-560027. Tel: 080-40206305
                </p>
                <h6 style="font-weight: bolder;font-size:15px!important">Department of Molecular and Clinical Genomics</h6>
              </div>
              <div style=" border-left: 2px solid #fab216;padding-top:10px;
                        height: 120px;"></div>
              <div class="logo_part">
                <img style="width:240px!important; margin-top:40px!important" src="../../../assets//oncoquer1.png" alt=""
                  class="logo_three" />
              </div>
            </div>
            <div class="row header mt-1">
              <div>
                <!-- <div class="col-3">
                            <img src="../../../assets/logo.png" alt="" style="width: 140px" />
                          </div> -->
              </div>
              <div></div>
              <div class="col">
                <div class="patient-detailes ml-auto float-right">
                  <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
                    <li>
                      Patient Id <br />
                      <span>{{ report.patient?.organisationPatientId }}</span>
                    </li>
                    <li class="ml-4">
                      Patient Name<br />
                      <span>{{ report.patient?.name }}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <!--  -->
              <div *ngIf="report.clientType == '4basecare_client'">
                <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
                  </div>
                </div>
                <div *ngIf="report.clientName == 'path_labs'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
                  </div>
                </div>
              </div>
            </div>
            <!--  -->
            <div class="mar_top"></div>
            <!--  -->

            <h6 class="py-2 h6_bg" [ngClass]="'yellow'">
              Alterations With Clinically Relevant Therapies
            </h6>
            <!--  -->
            <div class="row">
              <div class="col-12">
                <table class="page_1 cust_table w-100 mt-3 varient_detailsy prognastictable1">
                  <thead>
                    <tr>
                      <th>Gene (Alterations) - Drug association</th>
                      <th>Summary</th>
                    </tr>
                  </thead>
                  <!-- SNV -->

                  <tbody>
                    <tr *ngFor=" let drugsRes of reportPdfRes.drugsResList | slice : 32 : 36; let i = index ">
                      <td>

                            <i>{{ drugsRes.gene.replace("Fusion","") }}</i><span *ngIf="drugsRes.gene.includes('Fusion')">Fusion</span> {{ drugsRes.pposition?.replace(' ','')}} :
                            <br />
                            <span *ngFor="let drug of drugsRes.drugs; let i = index">
                              {{ drug.name
                              }}<span *ngIf="drugsRes.drugs.length - 1 != i">,</span>
                            </span>
                            - {{ drugsRes.association }}
                            <p>{{ drugsRes.cancerType }}</p>

                      </td>
                      <td>
                        <div [innerHtml]="drugsRes.summary"></div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <!--  -->

            <!--  -->
            <div class="row footer">



            </div>
          </div>

          <!-- page 3_9 -->
          <div id="page3_9" *ngIf="reportPdfRes.drugsResList.length > 36" class="container position-relative page"
            style="background-color: white">
            <div class="d-flex justify-content-between logo_panel py-3">
              <div class="logo_part">
                <img style="width:240px!important" src="../../../assets/hcg-new-logo.jpeg" alt="" class="logo_first" />
              </div>
              <div style=" border-left: 2px solid #fab216;padding-top:10px;
                                  height: 120px;"></div>

              <div class="logo_part text-center" style="font-family:arial Mt!important">

                                <h5 style="font-weight: bolder;margin-bottom:0px!important;">Triesta Sciences</h5>
                <p style="font-size: 15px!important;">
                  A Unit of Health Care Global Enterprises Ltd <br />
                  5th Floor, Tower 1, #8, P.Kalinga Rao Road,<br />
                  Sampangiram Nagar, Bengaluru-560027. Tel: 080-40206305
                </p>
                <h6 style="font-weight: bolder;font-size:15px!important">Department of Molecular and Clinical Genomics</h6>
              </div>
              <div style=" border-left: 2px solid #fab216;padding-top:10px;
                                  height: 120px;"></div>
              <div class="logo_part">
                <img style="width:240px!important; margin-top:40px!important" src="../../../assets//oncoquer1.png" alt=""
                  class="logo_three" />
              </div>
            </div>
            <div class="row header mt-1">
              <div>
                <!-- <div class="col-3">
                                      <img src="../../../assets/logo.png" alt="" style="width: 140px" />
                                    </div> -->
              </div>
              <div></div>
              <div class="col">
                <div class="patient-detailes ml-auto float-right">
                  <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
                    <li>
                      Patient Id <br />
                      <span>{{ report.patient?.organisationPatientId }}</span>
                    </li>
                    <li class="ml-4">
                      Patient Name<br />
                      <span>{{ report.patient?.name }}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <!--  -->
              <div *ngIf="report.clientType == '4basecare_client'">
                <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
                  </div>
                </div>
                <div *ngIf="report.clientName == 'path_labs'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
                  </div>
                </div>
              </div>
            </div>
            <!--  -->
            <div class="mar_top"></div>
            <!--  -->

            <h6 class="py-2 h6_bg" [ngClass]="'yellow'">
              Alterations With Clinically Relevant Therapies
            </h6>
            <!--  -->
            <div class="row">
              <div class="col-12">
                <table class="page_1 cust_table w-100 mt-3 varient_detailsy prognastictable1">
                  <thead>
                    <tr>
                      <th>Gene (Alterations) - Drug association</th>
                      <th>Summary</th>
                    </tr>
                  </thead>
                  <!-- SNV -->

                  <tbody>
                    <tr *ngFor=" let drugsRes of reportPdfRes.drugsResList | slice : 36 : 40; let i = index ">
                      <td>

                            <i>{{ drugsRes.gene.replace("Fusion","") }}</i><span *ngIf="drugsRes.gene.includes('Fusion')">Fusion</span> {{ drugsRes.pposition?.replace(' ','')}} :
                            <br />
                            <span *ngFor="let drug of drugsRes.drugs; let i = index">
                              {{ drug.name
                              }}<span *ngIf="drugsRes.drugs.length - 1 != i">,</span>
                            </span>
                            - {{ drugsRes.association }}
                            <p>{{ drugsRes.cancerType }}</p>

                      </td>
                      <td>
                        <div [innerHtml]="drugsRes.summary"></div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <!--  -->

            <!--  -->
            <div class="row footer">



            </div>
          </div>

          <!-- test -->

          <div *ngIf="prognasticCount>0" id="page4_1" class="container position-relative page"
            style="background-color: white">
            <div class="d-flex justify-content-between logo_panel py-3">
              <div class="logo_part">
                <img
                style="width:240px!important" src="../../../assets/hcg-new-logo.jpeg"
                  alt=""
                  class="logo_first"
                />
              </div>
              <div style=" border-left: 2px solid #fab216;padding-top:10px;
              height: 120px;"></div>

              <div class="logo_part text-center" style="font-family:arial Mt!important">

                                <h5 style="font-weight: bolder;margin-bottom:0px!important;">Triesta Sciences</h5>
                <p style="font-size: 15px!important;">
                                  A Unit of Health Care Global Enterprises Ltd <br/>
                                  5th Floor, Tower 1, #8, P.Kalinga Rao Road,<br/>
                                  Sampangiram Nagar, Bengaluru-560027. Tel: 080-40206305
                                </p>
                <h6 style="font-weight: bolder;font-size:15px!important">Department of Molecular and Clinical Genomics</h6>
              </div>
              <div style=" border-left: 2px solid #fab216;padding-top:10px;
              height: 120px;"></div>
              <div class="logo_part">
                <img  style="width:240px!important; margin-top:40px!important"
                  src="../../../assets//oncoquer1.png"
                  alt=""
                  class="logo_three"
                />
              </div>
            </div>
            <div class="row header mt-1">
              <div>
                <!-- <div class="col-3">
                                  <img src="../../../assets/logo.png" alt="" style="width: 140px" />
                                </div> -->
              </div>
              <div></div>
              <div class="col">
                <div class="patient-detailes ml-auto float-right">
                  <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
                    <li>
                      Patient Id <br />
                      <span>{{ report.patient?.organisationPatientId }} </span>
                    </li>
                    <li class="ml-4">
                      Patient Name<br />
                      <span>{{ report.patient?.name }}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <!--  -->
              <div *ngIf="report.clientType == '4basecare_client'">
                <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
                  </div>
                </div>
                <div *ngIf="report.clientName == 'path_labs'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
                  </div>
                </div>
              </div>
            </div>
            <!--  -->
            <div class="mar_top"></div>
            <!--  -->
            <div *ngIf="prognasticCount>0">
              <div>
                <h6 class="py-2 h6_bg" [ngClass]="'yellow'"> Alterations associated with prognostic significancess</h6>

              </div>

              <div class="row">
                <div class="col-12">
                  <table class="page_1 cust_table w-100 mt-3 varient_detailsy prognastictable" [ngClass]="'table_variant-blue'">
                    <thead>
                      <tr>
                        <th>Gene (Alterations)</th>
                        <th>Prognostic Significance</th>
                        <th>Summary</th>

                      </tr>
                    </thead>

                    <ng-container *ngFor="
                    let prog of reportPdfRes.prognosticAlterations
                  ">
                  <tr >
                    <td style="padding-left: 3px !important">
                      <i>{{ prog.gene }}&nbsp;</i> <span [innerHtml]="prog.pOrcDot"></span>
                    </td>
                    <td style="padding-left: 3px !important">
                      {{prog.prognosticSignificance}}
                    </td>
                    <td style="padding-left: 3px !important">
                      <span [innerHtml]="prog.description"></span>
                    </td>

                  </tr>
                </ng-container>


                  </table>
                </div>
              </div>
            </div>


            <!--  -->
                  <div class="row footer">
          <!-- <div class="col-12">
            <div class="pb-2 pl-4 text-center">
              <div class="d-block">
                  <span class="font-weight-bold" style="font-size: 13px !important"
                    >4baseCare (Genomics Tarang ODC),</span
                  >
                <span>
                  SJP2-S1-1F- C wing, Wipro Limited, SEZ, Sarjapur 2, Sy.No.69 (P),
                </span>
              </div>
              <div class="d-block">
                <span>Doddakannelli, Sarjapura Road,</span>
                <span> Bengaluru 560035, KA, India</span></div>


            </div>
          </div>
          <div class="col-12 pt-1 ">
            <p  style="font-size: 8px!important; line-height: 14px!important;">{{footerDiscription}} </p>
          </div> -->


        </div>
          </div>

          <div id="page4" class="container position-relative page" style="background-color: white">
            <div class="d-flex justify-content-between logo_panel py-3">
              <div class="logo_part">
                <img
                style="width:240px!important" src="../../../assets/hcg-new-logo.jpeg"
                  alt=""
                  class="logo_first"
                />
              </div>
              <div style=" border-left: 2px solid #fab216;padding-top:10px;
              height: 120px;"></div>

              <div class="logo_part text-center" style="font-family:arial Mt!important">

                                <h5 style="font-weight: bolder;margin-bottom:0px!important;">Triesta Sciences</h5>
                <p style="font-size: 15px!important;">
                                  A Unit of Health Care Global Enterprises Ltd <br/>
                                  5th Floor, Tower 1, #8, P.Kalinga Rao Road,<br/>
                                  Sampangiram Nagar, Bengaluru-560027. Tel: 080-40206305
                                </p>
                <h6 style="font-weight: bolder;font-size:15px!important">Department of Molecular and Clinical Genomics</h6>
              </div>
              <div style=" border-left: 2px solid #fab216;padding-top:10px;
              height: 120px;"></div>
              <div class="logo_part">
                <img  style="width:240px!important; margin-top:40px!important"
                  src="../../../assets//oncoquer1.png"
                  alt=""
                  class="logo_three"
                />
              </div>
            </div>
            <div class="row header mt-1">
              <div>
                <!-- <div class="col-3">
                                  <img src="../../../assets/logo.png" alt="" style="width: 140px" />
                                </div> -->
              </div>
              <div></div>
              <div class="col">
                <div class="patient-detailes ml-auto float-right">
                  <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
                    <li>
                      Patient Id <br />
                      <span>{{ report.patient?.organisationPatientId }} </span>
                    </li>
                    <li class="ml-4">
                      Patient Name<br />
                      <span>{{ report.patient?.name }}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <!--  -->
              <div *ngIf="report.clientType == '4basecare_client'">
                <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
                  </div>
                </div>
                <div *ngIf="report.clientName == 'path_labs'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
                  </div>
                </div>
              </div>
            </div>
            <!--  -->
            <div class="mar_top"></div>
            <!--  -->

            <h6 class="py-2 h6_bg" [ngClass]="'yellow'">References</h6>
            <!--  -->
            <div class="row">
              <div class="col">
                <div class="disc">
                  <div class="ref_data mt-4">
                    <ul class="break-word" style="text-align: justify">
                      <li *ngFor="let referenceArticle of reportPdfRes.referenceArticles" style="
                                          text-align: justify;
                                          /* white-space:pre-wrap; */
                                          word-break: break-word;
                                        ">
                        {{ referenceArticle }}
                      </li>
                    </ul>
                  </div>
                </div>


              </div>
            </div>

            <div class="row pr-2 above-footer">
              <div class="col-4">
                <!-- <img
                  src="../../../assets/HCG/vijay.png"
                  alt=""
                  style="padding-top:20px;margin-left:20px;padding-left: 60px; padding-right: 60px; width: 90%"
                  class="mb-2"
                /> -->
                <span style="padding-left: 126px; font-weight: bolder"
                  >Dr. Vijay.K, PhD<br /><span
                    style="padding-left: 63px; font-weight: bolder"
                    >Senior Scientist I-Clinical Diagnostics</span
                  ></span
                >
              </div>
              <div class="col-4">
                <!-- <img
                  src="../../../assets/HCG/gautam.png"
                  alt=""
                  style="magin-left:20px;padding-left: 60px; padding-right: 60px; width: 90%"
                  class="mb-2"
                /> -->
                <span style="padding-left: 67px; font-weight: bolder"
                  >Dr. Gautam Balaram, MBBS, MD<br /><span
                    style="padding-left: 100px; font-weight: bolder"
                    >Clinical Pathologist</span
                  ></span
                >
              </div>
              <div class="col-4">
                <!-- <img
                  src="../../../assets/HCG/mithua.png"
                  alt=""
                  style="padding-top:30px;padding-left: 60px; padding-right: 60px; width: 100%"
                  class="mb-2"
                /> -->
                <span style="padding-left: 97px; font-weight: bolder"
                  >Dr. Mithua Ghosh, PhD, PD<br /><span
                    style="padding-left: 90px; font-weight: bolder"
                    >Director-Clinical Diagnostics</span
                  ></span
                >
              </div>

            </div>

            <!--  -->
                  <div class="row footer">

          <!-- <div class="col-12">
            <div class="pb-2 pl-4 text-center">
              <div class="d-block">
                  <span class="font-weight-bold" style="font-size: 13px !important"
                    >4baseCare (Genomics Tarang ODC),</span
                  >
                <span>
                  SJP2-S1-1F- C wing, Wipro Limited, SEZ, Sarjapur 2, Sy.No.69 (P),
                </span>
              </div>
              <div class="d-block">
                <span>Doddakannelli, Sarjapura Road,</span>
                <span> Bengaluru 560035, KA, India</span></div>


            </div>
          </div>
          <div class="col-12 pt-1 ">
            <p  style="font-size: 8px!important; line-height: 14px!important;">{{footerDiscription}} </p>
          </div> -->


        </div>
          </div>

          <div id="page4_1" class="container position-relative page" style="background-color: white">
            <div class="d-flex justify-content-between logo_panel py-3">
              <div class="logo_part">
                <img
                style="width:240px!important" src="../../../assets/hcg-new-logo.jpeg"
                  alt=""
                  class="logo_first"
                />
              </div>
              <div style=" border-left: 2px solid #fab216;padding-top:10px;
              height: 120px;"></div>

              <div class="logo_part text-center" style="font-family:arial Mt!important">

                                <h5 style="font-weight: bolder;margin-bottom:0px!important;">Triesta Sciences</h5>
                <p style="font-size: 15px!important;">
                                  A Unit of Health Care Global Enterprises Ltd <br/>
                                  5th Floor, Tower 1, #8, P.Kalinga Rao Road,<br/>
                                  Sampangiram Nagar, Bengaluru-560027. Tel: 080-40206305
                                </p>
                <h6 style="font-weight: bolder;font-size:15px!important">Department of Molecular and Clinical Genomics</h6>
              </div>
              <div style=" border-left: 2px solid #fab216;padding-top:10px;
              height: 120px;"></div>
              <div class="logo_part">
                <img  style="width:240px!important; margin-top:40px!important"
                  src="../../../assets//oncoquer1.png"
                  alt=""
                  class="logo_three"
                />
              </div>
            </div>
            <div class="row header mt-1">
              <div>
                <!-- <div class="col-3">
                                <img src="../../../assets/logo.png" alt="" style="width: 140px" />
                              </div> -->
              </div>
              <div></div>
              <div class="col">
                <div class="patient-detailes ml-auto float-right">
                  <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
                    <li>
                      Patient Id <br />
                      <span>{{ report.patient?.organisationPatientId }}</span>
                    </li>
                    <li class="ml-4">
                      Patient Name<br />
                      <span>{{ report.patient?.name }}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <!--  -->
              <div *ngIf="report.clientType == '4basecare_client'">
                <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
                  </div>
                </div>
                <div *ngIf="report.clientName == 'path_labs'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
                  </div>
                </div>
              </div>
            </div>
            <!--  -->
            <div class="mar_top"></div>
            <!--  -->

            <div class="">
              <h6 class="py-2 h6_bg" [ngClass]="'yellow'">TEST DESCRIPTION</h6>
              <p class="mb-2" style="text-align:justify">
                <span class="clr-cyan pl-2" [ngClass]="'txt-yellow'">OncQuer<sup>TM</sup> Assist Panel</span>
                is a Next Generation Sequencing based test which identifies
                genetic alterations in a comprehensive panel of well curated 72  genes which are having an impact response to approved
                therapy for a particular cancer type. Some of the alterations
                detected may have bearing on prognosis and/or therapeutic options
                and may provide relevant information that allows
                oncologists/clinicians to consider various lines of
                targeted treatment for the patient.
              </p>
            </div>

            <!--  -->
            <div class="row">
              <div class="col">
                <div class="">
                  <h6 class="py-2 h6_bg" [ngClass]="'yellow'">GENES EVALUATED</h6>
                  <p>
                    <span class="clr-cyan pl-2" [ngClass]="'txt-yellow'">OncQuer<sup>TM</sup> Assist Panel</span>
                    detects mutations (SNVs and Short InDels), Copy Number Variations (CNVs),
                    Gene Fusions and splice variants in the 72 genes:
                    <!-- detects mutations (SNVs and Short Indels), Copy Number Variations
                                  (CNVs), gene fusions and splice variants in the 53 genes : -->
                  </p>
                </div>
                <!--  -->
                <div class="row mx-0">
                  <div class="col-12 px-0">
                    <div class="genes_info">
                      <div class="title py-2">
                        <h6 class="mb-0 d-inline-block border-bottom py-2">
                          <b>SNVs, SHORT INDELS and CNVs
                          </b> Covered in
                          <span class="clr-cyan pl-2" [ngClass]="'txt-yellow'">OncQuer<sup>TM</sup> Assist Panel</span>
                        </h6>
                      </div>
                      <div class="genes_body" [ngClass]="'gene-bg_blue'">
                        <i>
                          <div class="genes_list">
                            <span>ABL1</span><span>ALK</span><span>APC</span>
                            <span>AR</span><span>ATM</span><span>BARD1</span>
                            <span>BMPR1A</span><span>BRAF</span><span>BRCA1</span>
                            <span>BRCA2</span>
                            <!-- <span>ABL1</span><span>ALK</span><span>AR</span
                                          ><span>ATM</span><span>BARD1</span><span>BRAF</span
                                          ><span>BRCA1</span><span>BRCA2</span><span>BRIP1</span
                                          ><span>CDK12</span> -->
                          </div>
                          <div class="genes_list bg-white">
                            <span>BRIP1</span><span>CDK12</span><span>CDK4</span>
                            <span>CDK6</span><span>CDKN2A</span><span>CHEK1</span>
                            <span>CHEK2</span><span>CTNNB1</span><span>EGFR</span>
                            <span>EPCAM</span>
                            <!-- <span>CDK4</span><span>CDK6</span><span>CDKN2A</span
                                          ><span>CHEK1</span><span>CHEK2</span><span>EGFR</span
                                          ><span>EPCAM</span><span>ERBB2</span><span>ERBB3</span
                                          ><span>EZH2</span> -->
                          </div>
                          <div class="genes_list">
                            <span>ERBB2</span><span>ERBB3</span><span>EZH2</span>
                            <span>FANCL</span><span>FGFR1</span><span>FGFR2</span>
                            <span>FGFR3</span><span>GAPDH</span><span>IDH1</span>
                            <span>IDH2</span>
                            <!-- <span>FANCL</span><span>FGFR3</span><span>GAPDH</span
                                          ><span>IDH1</span><span>IDH2</span><span>JAK2</span
                                          ><span>KIT</span><span>KRAS</span><span>MAP2K1</span
                                          ><span>MAP2K2</span> -->
                          </div>
                          <div class="genes_list">
                            <span>JAK2</span><span>KIT</span><span>KRAS</span>
                            <span>MAP2K1</span><span>MAP2K2</span><span>MDM2</span>
                            <span>MET</span><span>MLH1</span><span>MLH3</span>
                            <span>MSH2</span>
                            <!-- <span>MDM2</span><span>MET</span><span>MLH1</span
                                          ><span>MLH3</span><span>MSH2</span><span>MSH6</span
                                          ><span>NRAS</span><span>PALB2</span><span>PDGFRA</span
                                          ><span>PDGFRB</span> -->
                          </div>
                          <div class="genes_list">
                            <span>MSH6</span><span>MUTYH</span><span>NRAS</span>
                            <span>PALB2</span><span>PDGFRA</span><span>PDGFRB</span>
                            <span>PIK3CA</span><span>PMS1</span><span>PMS2</span>
                            <span>POLD1</span>
                            <!-- <span>PIK3CA</span><span>PMS1</span><span>PMS2</span
                                          ><span>PPP2R2A</span><span>RAD51B</span><span>RAD51C</span
                                          ><span>RAD51D</span><span>RAD54L</span><span>RET</span
                                          ><span>ROS1</span> -->
                          </div>
                          <div class="genes_list">
                            <span>POLE</span><span>POLH</span><span>PTEN</span>
                            <span>RAD50</span><span>RAD51</span><span>RAD51B</span>
                            <span>RAD51C</span><span>RAD51D</span><span>RAD54L</span>
                            <span>RB1</span>
                            <!-- <span>STK11</span><span>TSC1</span><span>TSC2</span> -->
                          </div>
                          <div class="genes_list">
                            <span>RET</span><span>ROS1</span><span>SMAD4</span>
                            <span>STK11</span><span>TP53</span><span>TSC1</span>
                            <span>TSC2</span>
                            <!-- <span>STK11</span><span>TSC1</span><span>TSC2</span> -->
                          </div>
                        </i>
                      </div>

                      <!-- <div class="title py-2 mt-4">
                                      <h6 class="mb-0 d-inline-block border-bottom py-2">
                                        <b>CNAs</b> Covered in
                                        <span class="font-weight-normal">TAR</span><b>GT</b>
                                        <span class="clr-cyan pl-2" [ngClass]="'txt-yellow'"
                                          >First</span
                                        >
                                      </h6>
                                    </div> -->
                      <!-- <div class="gene_body" [ngClass]="'gene-bg_blue'">
                                      <i>
                                        <div class="gene_list">
                                          <span>ABL1</span><span>AR</span><span>ATM</span
                                          ><span>BARD1</span><span>BRCA1</span><span>BRCA2</span
                                          ><span>BRIP1</span><span>CDK12</span><span>CDK4</span
                                          ><span>CDK6</span>
                                        </div>
                                        <div class="gene_list">
                                          <span>CDKN2A</span><span>CHEK1</span><span>CHEK2</span
                                          ><span>EGFR</span><span>EPCAM</span><span>ERBB2</span
                                          ><span>ERBB3</span><span>EZH2</span><span>FANCL</span
                                          ><span>FGFR3</span>
                                        </div>
                                        <div class="gene_list">
                                          <span>GAPDH</span><span>IDH2</span><span>JAK2</span
                                          ><span>KIT</span><span>MDM2</span><span>NRAS</span
                                          ><span>PALB2</span><span>PDGFRA</span><span>PDGFRB</span
                                          ><span>PPP2R2A</span>
                                        </div>
                                        <div class="gene_list">
                                          <span>RAD51B</span><span>RAD51C</span><span>RAD51D</span
                                          ><span>RAD54L</span><span>STK11</span><span>TSC1</span
                                          ><span>TSC2</span>
                                        </div>
                                      </i>
                                    </div> -->
                    </div>
                  </div>
                  <!-- cnv_genes -->

            <!-- <div class="col-12 px-0">
              <div class="genes_info">
                <div class="title py-2">
                  <h6 class="mb-0 d-inline-block border-bottom py-2">
                    <b> CNVs
                    </b> Covered in
                    <span class="clr-cyan pl-2" [ngClass]="'txt-yellow'">OncQuer<sup>TM</sup> Assist Panel</span>
                  </h6>
                </div>
                <div class="genes_body" [ngClass]="'gene-bg_blue'">
                  <i>
                    <div class="genes_list">
                      <span>ABL1</span><span>ALK</span><span>APC</span>
                      <span>AR</span><span>ATM</span><span>BARD1</span>
                      <span>BMPR1A</span><span>BRAF</span><span>BRCA1</span>
                      <span>BRCA2</span>
                    </div>
                    <div class="genes_list bg-white">
                      <span>BRIP1</span><span>CDK12</span><span>CDK4</span>
                      <span>CDK6</span><span>CDKN2A</span><span>CHEK1</span>
                      <span>CHEK2</span><span>CTNNB1</span><span>EGFR</span>
                      <span>EPCAM</span>
                    </div>
                    <div class="genes_list">
                      <span>ERBB2</span><span>ERBB3</span><span>EZH2</span>
                      <span>FANCL</span><span>FGFR1</span><span>FGFR2</span>
                      <span>FGFR3</span><span>GAPDH</span><span>IDH2</span>
                      <span>JAK2</span>
                    </div>
                    <div class="genes_list">
                     <span>KIT</span><span>MDM2</span>
                      <span>MET</span><span>MLH1</span><span>MUTYH</span>
                      <span>NRAS</span><span>PALB2</span><span>PDGFRA</span>
                      <span>PDGFRAB</span><span>POLD1</span>
                    </div>

                    <div class="genes_list">
                      <span>POLE</span><span>POLH</span><span>PTEN</span>
                      <span>RAD50</span><span>RAD51</span><span>RAD51B</span>
                      <span>RAD51C</span><span>RAD51D</span><span>RAD54L</span>
                      <span>RB1</span>
                    </div>
                    <div class="genes_list">
                      <span>RET</span><span>ROS1</span><span>SMAD4</span>
                      <span>STK11</span><span>TP53</span><span>TSC1</span>
                      <span>TSC2</span>
                    </div>
                  </i>
                </div>


              </div>
            </div> -->




                  <div class="col-12 px-0">
                    <div class="genes_info">
                      <div class="title py-2">
                        <h6 class="mb-0 d-inline-block border-bottom py-2">
                          <b>Gene Fusions/Splicing Variations</b> Covered in
                          <span class="clr-cyan pl-2" [ngClass]="'txt-yellow'">OncQuer<sup>TM</sup> Assist Panel</span>
                        </h6>
                      </div>
                      <div class="genes_body" [ngClass]="'gene-bg_blue'">
                        <i>
                          <div class="genes_list">
                          <span>ALK</span><span>FGFR2</span><span>FGFR3</span>
                          <span>MET</span><span>NRG1</span><span>NRG2</span>
                          <span>NTRK1</span><span>NTRK2</span> <span>NTRK3</span><span>RET</span>

                          </div>
                          <div class="genes_list">
                            <span>ROS1</span>
                          </div>
                        </i>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>




            <!--  -->
                  <div class="row footer">
          <!-- <div class="col-12">
            <div class="pb-2 pl-4 text-center">
              <div class="d-block">
                  <span class="font-weight-bold" style="font-size: 13px !important"
                    >4baseCare (Genomics Tarang ODC),</span
                  >
                <span>
                  SJP2-S1-1F- C wing, Wipro Limited, SEZ, Sarjapur 2, Sy.No.69 (P),
                </span>
              </div>
              <div class="d-block">
                <span>Doddakannelli, Sarjapura Road,</span>
                <span> Bengaluru 560035, KA, India</span></div>


            </div>
          </div>
          <div class="col-12 pt-1 ">
            <p  style="font-size: 8px!important; line-height: 14px!important;">{{footerDiscription}} </p>
          </div> -->


        </div>
          </div>

          <div id="page5" class="container position-relative page" style="background-color: white">
            <div class="d-flex justify-content-between logo_panel py-3">
              <div class="logo_part">
                <img
                style="width:240px!important" src="../../../assets/hcg-new-logo.jpeg"
                  alt=""
                  class="logo_first"
                />
              </div>
              <div style=" border-left: 2px solid #fab216;padding-top:10px;
              height: 120px;"></div>

              <div class="logo_part text-center" style="font-family:arial Mt!important">

                                <h5 style="font-weight: bolder;margin-bottom:0px!important;">Triesta Sciences</h5>
                <p style="font-size: 15px!important;">
                                  A Unit of Health Care Global Enterprises Ltd <br/>
                                  5th Floor, Tower 1, #8, P.Kalinga Rao Road,<br/>
                                  Sampangiram Nagar, Bengaluru-560027. Tel: 080-40206305
                                </p>
                <h6 style="font-weight: bolder;font-size:15px!important">Department of Molecular and Clinical Genomics</h6>
              </div>
              <div style=" border-left: 2px solid #fab216;padding-top:10px;
              height: 120px;"></div>
              <div class="logo_part">
                <img  style="width:240px!important; margin-top:40px!important"
                  src="../../../assets//oncoquer1.png"
                  alt=""
                  class="logo_three"
                />
              </div>
            </div>
            <div class="row header mt-1">
              <div>
                <!-- <div class="col-3">
                                  <img src="../../../assets/logo.png" alt="" style="width: 140px" />
                                </div> -->
              </div>
              <div></div>
              <div class="col">
                <div class="patient-detailes ml-auto float-right">
                  <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
                    <li>
                      Patient Id <br />
                      <span>{{ report.patient?.organisationPatientId }} </span>
                    </li>
                    <li class="ml-4">
                      Patient Name<br />
                      <span>{{ report.patient?.name }}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <!--  -->
              <div *ngIf="report.clientType == '4basecare_client'">
                <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
                  </div>
                </div>
                <div *ngIf="report.clientName == 'path_labs'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
                  </div>
                </div>
              </div>
            </div>
            <!--  -->
            <div class="mar_top"></div>
            <!--  -->
            <h6 class="py-2 h6_bg mt-3" [ngClass]="'yellow'">TEST METHODOLOGY</h6>
            <!--  -->
            <div class="row mt-3">
              <div class="col">
                <h6 class="font-weight-bold">
                  Sample preparation and Library preparation :
                </h6>
                <!-- <p>
                                DNA isolated from FFPE, or any other fresh tumor tissue source was
                                used to perform targeted gene capture using a custom capture kit.
                                The libraries were sequenced to mean >350X coverage on Illumina
                                sequencing platform.
                              </p> -->
                <p style="text-align: justify;">
                  DNA isolated from FFPE, or any other fresh tumor tissue source was used to perform targeted
                  gene captured in OncQuer<sup>TM</sup> Assist Panel using a TARGT First kit from 4baseCare.
                  The libraries were sequenced to mean>350X coverage on Illumina sequencing platform.
                </p>
              </div>
            </div>
            <!--  -->
            <div class="row mt-4">
              <div class="col">
                <h6 class="font-weight-bold">
                  Bioinformatics Analysis and Reporting :
                </h6>
                <!-- <p>
                                The sequences obtained are aligned to human reference genome
                                (GRCh37/hg19) and variant analysis was performed using set of
                                Bioinformatics Pipeline. Only non-synonymous and splice site
                                variants found in the panel consisting of specific set of genes were
                                used for clinical interpretation. Silent variations that do not
                                result in any change in amino acid in the coding region are not
                                reported. Clinically relevant mutations were annotated using
                                published variants in literature and a set of databases – ClinVar,
                                COSMIC and dbSNP. Common variants are filtered based on allele
                                frequency in 1000 Genome Phase 3, ExAC, dbSNP, gnomAD, etc. In the
                                absence of a clinically significant reported known variation(s),
                                pathogenicity will be predicted based on <i>in-silico</i> gene
                                prioritization tools: CADD, SIFT, PolyPhen-2, Condel and Mutation
                                taster and prioritized for clinical correlation. The identified
                                pathogenic variant will be correlated with observed phenotypic
                                features of the patient and interpreted according to American
                                College of Medical Genetics (ACMG) guidelines.
                              </p>
                              <p class="mb-2 d-block">
                                Somatic variants are classified into three tiers based on their level
                                of clinical significance in cancer diagnosis, prognosis, and/or
                                therapeutics as per international guidelines: ACMG, ASCO, AMP, CAP,
                                NCCN and ESMO
                              </p> -->
                <p style="text-align: justify;">
                  The sequences obtained are aligned to human reference genome (GRCh37/hg19) and variant analysis was performed
                  using set of Bioinformatics Pipeline. Only non-synonymous and splice site variants found in the panel
                  consisting of specific set of genes were used for clinical interpretation. Silent variations that do not
                  result in any change
                  in amino acid in the coding region are not reported. Clinically relevant mutations were annotated using
                  published variants in literature and a set of databases – ClinVar, COSMIC
                  and dbSNP. Common variants are filtered based on allele frequency in 1000 Genome Phase 3, ExAC, dbSNP, gnomAD,
                  etc. In the absence of a clinically significant reported
                  known variation(s), pathogenicity will be predicted based on <i>in-silico</i> gene prioritization tools: CADD, SIFT,
                  PolyPhen-2, Condel and Mutation taster and prioritized for clinical
                  correlation. The identified pathogenic variant will be correlated with observed phenotypic features of the patient and interpreted according to ACMG/AMP guidelines.
                </p>
                <p class="mb-2 d-block" style="text-align: justify;">
                  Somatic variants are classified into three tiers based on their level of clinical significance in cancer
                  diagnosis, prognosis, and/or therapeutics as per international guidelines:
                  ACMG, ASCO, AMP, CAP, NCCN and ESMO.
                </p>
              </div>
            </div>
            <!--  -->

            <h6 class="py-2 h6_bg mt-2" [ngClass]="'yellow'">
              LIMITATIONS AND DISCLAIMER
            </h6>

            <div class="row">
              <div class="col">
                <div class="disc">
                  <ul class="ml-0 pl-4" style="text-align: justify;">
                    <li>
                      OncQuer<sup>TM</sup> Assist Panel has been developed, validated and
                      has
                      not been cleared or approved by the FDA.
                    </li>
                    <li>
                      DNA studies do not constitute a definitive test for the selected condition(s) in all individuals. It
                      should be realized that there are possible sources of error. Errors can result
                      from trace contamination, rare technical errors, rare genetic variants that interfere with analysis,
                      recent scientific developments, and alternative classification systems. This
                      test should be one of the many aspects used by the healthcare provider to help with a diagnosis and
                      treatment plan.
                    </li>
                    <li>
                      We are using the canonical transcript for clinical reporting which is usually the longest coding
                      transcript with strong/multiple supporting evidence. However, in rare cases,
                      clinically relevant variants annotated in alternate complete coding transcripts could also be reported.
                    </li>
                    <li>
                      The contents of this test should be carefully assessed by the treating physician and further interpreted
                      along with clinical, histopathological findings, contraindications and
                      guidelines before deciding the course of therapy.
                    </li>
                    <li>
                      The CNVs detected must be confirmed by an alternate method, such as IHC, for further clinical management decisions.                    </li>
                    <!-- <li>
                      The sensitivity of this assay to detect large deletions/duplications of more than 10 bp or copy number
                      variations (CNV) is 70-75%. The CNVs detected have to be confirmed
                      by alternate method.
                    </li> -->
                    <li>
                      Most recent block is recommended for testing as the mutation profile may change in response to treatment
                      and hence differ at different sampling points.
                    </li>
                    <li>
                      The identified pathogenic variant will be correlated with observed phenotypic features of the patient and interpreted according to AMP guidelines.
                    </li>
                    <li>
                      Certain genes may not be covered completely, and few mutations could be missed. A negative result cannot
                      rule out the possibility that the tested tumor sample carries
                      mutations not previously associated with cancer and hence not included in the panel.
                    </li>
                    <li>
                      Our limit of detection for OncQuer<sup>TM</sup> Assist Panel is 5% for SNVs, 10% for InDels and CNV gain &#8805; 6. In addition
                      to
                      this, sequencing quality and coverage is dependent on many factors
                      such as homopolymers, GC-rich regions, intrinsic quality of FFPE DNA and that might impact the variant
                      detection.
                    </li>
                    <li> A negative result does not rule out the possibility of mutations in the patient’s tumor tissue.</li>
                    <li>Certain regions may be undercovered or poorly amplified due to intrinsic nature of the sample or the tumor
                      biology across the panel, due to which the variants may be detected with compromised QC such as less number
                      of unique reads or lack of paired reads. In such instances, a reflex testing on alternate technologies such
                      as ddPCR or Realtime PCR may be suggested.</li>
                  </ul>
                </div>
              </div>
            </div>

            <!--  -->
                  <div class="row footer">
          <!-- <div class="col-12">
            <div class="pb-2 pl-4 text-center">
              <div class="d-block">
                  <span class="font-weight-bold" style="font-size: 13px !important"
                    >4baseCare (Genomics Tarang ODC),</span
                  >
                <span>
                  SJP2-S1-1F- C wing, Wipro Limited, SEZ, Sarjapur 2, Sy.No.69 (P),
                </span>
              </div>
              <div class="d-block">
                <span>Doddakannelli, Sarjapura Road,</span>
                <span> Bengaluru 560035, KA, India</span></div>


            </div>
          </div>
          <div class="col-12 pt-1 ">
            <p  style="font-size: 8px!important; line-height: 14px!important;">{{footerDiscription}} </p>
          </div> -->


        </div>
          </div>
        </div>

        <!-- For 4basecare Template 1-->
        <div *ngIf="is4basecare && temp=='temp1'">
          <div id="page" class="container position-relative page" style="overflow-y: clip; background-color: white">
            <div class="row header">
              <div class="w-100 overflow-hidden">
                <div class="col">
                  <div class="cover_page">
                    <img src="../../../assets/tarGT_FirstSolid-Cover.png" alt="" class="img-fluid w-100" />
                  </div>
                </div>
              </div>
              <div></div>
            </div>
          </div>
          <!-- Page 1 -->
          <div id="page1" class="container position-relative page" style="background-color: white">
            <div class="row header mt-1 py-4">
              <div>
                <div class="col-3 pl-0">
                  <div class="logo">
                    <img src="../../../assets/logo.png" alt="" style="width: 140px" />
                  </div>
                </div>
              </div>
              <div></div>
              <div class="col">
                <div class="patient-detailes ml-auto float-right">
                  <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
                    <li>
                      Patient Id <br />
                      <span>{{ report.patient?.ecrfId }}</span>
                    </li>
                    <li class="ml-4">
                      Cancer Site<br />
                      <span>{{ report.cancerType }}</span>
                    </li>
                    <li class="ml-4">
                      SAMPLE ID<br />
                      <span>{{ report.labDetails?.sampleId }}</span>
                    </li>
                    <li class="ml-4">
                      Registration Date<br />
                      <span>{{
                        report.labDetails?.sampleCollected | date : "dd/MM/yyyy"
                        }}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <div *ngIf="report.clientType == '4basecare_client'">
                <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
                  </div>
                </div>
                <div *ngIf="report.clientName == 'path_labs'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
                  </div>
                </div>
              </div>
            </div>
            <!--  -->
            <div class="mar_top"></div>
            <!--  -->
            <div class="row">
              <div class="col-7">
                <div class="">
                  <div class="w-50" style="min-height: 80px">
                    <p class="py-1 font-weight-bold" [ngClass]="'txt-skyblue'">
                      SCOPE OF THE TEST
                    </p>
                    <p>SNVs, InDels, CNAs, Gene Fusions status</p>
                  </div>
                  <img src="../../../assets/BorderLine.png" class="mr-2 w-100" />
                </div>
              </div>
              <div class="col">
                <div class="">
                  <div class="w-100">
                    <!-- <p style="color: #208dcb;" class="py-1">
                                            <span style="color: #000 !important"></span>
                                          </p> -->
                    <div class="w-100" style="min-height: 80px">
                      <p [ngClass]="'txt-skyblue'" class="py-1 font-weight-bold" *ngIf="report.clinicalSummary != null">
                        <span *ngIf="report.clinicalSummary != ' '">CLINICAL INDICATION</span>
                      </p>

                      <p *ngIf="report.clinicalSummary != null">
                        <span style="color: #000 !important" *ngIf="report.clinicalSummary != ' '">{{ report.clinicalSummary
                          }}</span>
                      </p>
                    </div>
                    <!-- </div> -->
                    <img src="../../../assets/BorderLine.png" class="mr-2 w-100" />

                    <!-- </div> -->
                  </div>
                </div>
              </div>
            </div>
            <!--  -->
            <div class="row pt-2">
              <div class="col-7">
                <h6 class="text-uppercase py-2 font-weight-bold" [ngClass]="'txt-skyblue'">
                  Report Details
                </h6>
                <ul class="list-unstyled">
                  <li class="pb-1">
                    <span class="clr-cyan" [ngClass]="'txt-skyblue'">Name : </span>
                    <span class="text-black">{{
                      report.patient?.name | uppercase
                      }}</span>
                  </li>
                  <li class="pb-1">
                    <span class="clr-cyan" [ngClass]="'txt-skyblue'">Gender : </span>
                    <span class="text-black">{{
                      report.patient?.gender | titlecase
                      }}</span>
                  </li>
                  <li class="pb-1">
                    <span class="clr-cyan" [ngClass]="'txt-skyblue'">Age/DOB : </span>
                    <span class="text-black">{{
                      report.patient?.dateOfBirth
                      ? (report.patient?.dateOfBirth | date : "dd/MM/yyyy")
                      : report.patient?.age + " Years"
                      }}</span>
                  </li>
                  <li class="pb-1">
                    <span class="clr-cyan" [ngClass]="'txt-skyblue'">Reporting Date :
                    </span>
                    <span class="text-black">{{ date | date : "dd/MM/yyyy" }}</span>
                  </li>
                </ul>
              </div>
              <div class="col">
                <ul class="list-unstyled pt-4 mt-3">
                  <li *ngIf="report.cancerSubType != null" class="pb-1">
                    <span class="clr-cyan" [ngClass]="'txt-skyblue'">Cancer Celltype :
                    </span>
                    <span class="text-black">{{ report.cancerSubType }}</span>
                  </li>
                  <li class="pb-1">
                    <span class="clr-cyan" [ngClass]="'txt-skyblue'">Sample Source :
                    </span>
                    <span class="text-black">{{ sampleSource }}</span>
                  </li>
                  <li class="pb-1">
                    <span class="clr-cyan" [ngClass]="'txt-skyblue'">Consulting Clinician :
                    </span>
                    <span class="text-black">{{referringClinician}}</span>
                  </li>
                  <li class="pb-1">
                    <span class="clr-cyan" [ngClass]="'txt-skyblue'">Hospital :
                    </span>
                    <span class="text-black">{{ labHospital }}</span>
                  </li>
                </ul>
              </div>
            </div>
            <!--  -->
            <div class="row mt-2 py-2">
              <div class="col">
                <h6 class="mb-0 text-uppercase f-weight_700">Results</h6>
              </div>
            </div>
            <h6 class="py-2 h6_bg" [ngClass]="'skyBlue'">
              Genomic findings from tumor profiling
            </h6>

            <!--  -->
            <div class="row">
              <div style="width: 100%;" *ngIf="isReportDownload">
                <img [src]="firstPageTable" alt="" width="100%" class="img-fluid" />
              </div>
              <div class="col-12" id="firstPageTable" *ngIf="!isReportDownload">
                <table class="page_1 cust_table w-100 mt-3 first_1" [ngClass]="'table_skyblue'">
                  <tr>
                    <th>Genomic Alteration</th>
                    <th>Relevant Therapies (in Same Cancer Type)</th>
                    <th>Relevant Therapies (in Different Cancer)</th>
                  </tr>
                  <tr *ngIf="tableCount== 0 && prognasticCount>0 ">
                    <td colspan="3" class="text-center mt-2">
                      Clinically relevant genomic alterations associated with therapeutic significance were not detected.
                    </td>
                  </tr>
                  <tr *ngIf="tableCount== 0 && prognasticCount == 0">
                    <td colspan="3" class="text-center mt-2">
                      Clinically relevant genomic alterations associated with both therapeutic and prognostic significance were not detected.
                    </td>
                  </tr>



                  <ng-container>
                    <tr *ngFor="
                                                                let snvHotspotTrail of reportPdfRes?.allHotspotTrailList  | slice : 0 : 5 ; let i=index
                                                              ">
                      <td>
                        <i>{{ snvHotspotTrail.gene }}</i>
                        &nbsp;
                        <span *ngIf="!snvHotspotTrail.gene.includes('Wild type')">

                        <span *ngIf="snvHotspotTrail?.multiVariant==null">
                          <span *ngIf="snvHotspotTrail.exonNumber != null || snvHotspotTrail.pposition != null && snvHotspotTrail.pposition != ''">

                            <span *ngIf="snvHotspotTrail.exonNumber != null">{{snvHotspotTrail.exonNumber}}</span>&nbsp;
                            <span *ngIf="snvHotspotTrail.pposition != null && snvHotspotTrail.pposition != ''">({{snvHotspotTrail.pposition?.replace(' ','')}})</span></span>
                            <p *ngIf="snvHotspotTrail.mutantBurdenPercent != null && !snvHotspotTrail.gene.includes('Wild type')">
                              Allelic burden: {{ snvHotspotTrail.mutantBurdenPercent }}%
                            </p>
                            <span *ngIf="snvHotspotTrail.copyNumber!=null && snvHotspotTrail.copyNumber.length>0"> {{ snvHotspotTrail.copyNumber }}
                          </span>
                        </span>
                        <span *ngIf="snvHotspotTrail?.multiVariant!=null"><br/>
                          <span  style="font-size: 14px !important" [innerHtml]="snvHotspotTrail?.multiVariant"></span>
                        </span></span>
                      </td>
                      <td>
                        <table class="table first_table w-100">
                          <thead class="border-bottom cust_clr">
                            <tr>
                              <th class="w-50">
                                <span class="font-weight-normal"><i>Therapy</i></span>
                              </th>
                              <th class="w-33">
                                <span class="font-weight-normal"><i>Clinical Relevance</i></span>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngIf="snvHotspotTrail.sameCancerType == null">
                              <td class="w-50">NA</td>
                              <td class="w-50">NA</td>
                            </tr>

                            <ng-container *ngIf="snvHotspotTrail.sameCancerType != null">
                              <tr *ngFor="
                                                                          let item of snvHotspotTrail.sameTypeRelevantData
                                                                        ">
                                <td>{{ item.therapy }}</td>
                                <td>{{ item.association }}</td>
                                <!-- <td>{{item.cancerType}}</td> -->
                              </tr>
                            </ng-container>
                          </tbody>
                        </table>
                      </td>
                      <td>
                        <table class="table first_table w-100">
                          <thead class="border-bottom cust_clr">
                            <tr>
                              <th class="w-33">
                                <span class="font-weight-normal"><i>Therapy</i></span>
                              </th>
                              <th class="w-33">
                                <span class="font-weight-normal"><i>Clinical Relevance</i></span>
                              </th>
                              <th class="w-33">
                                <span class="font-weight-normal"><i>Cancer Type</i></span>
                              </th>
                            </tr>
                          </thead>
                          <tbody>

                            <ng-container *ngIf="snvHotspotTrail.otherCancerType != null">
                              <tr *ngFor="
                                                                          let item of snvHotspotTrail.otherTypeRelevantData
                                                                        ">
                                <td>{{ item.therapy }}</td>
                                <td>{{ item.association }}</td>
                                <td>{{ item.cancerType }}</td>
                              </tr>
                            </ng-container>
                            <tr *ngIf="snvHotspotTrail.otherCancerType == null">
                              <td class="w-33">NA</td>
                              <td class="w-33">NA</td>
                              <td class="w-33">NA</td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>


                  </ng-container>
                </table>
                <p *ngIf="
                snvHotSpot.length != 0 ||
                cnaHotSpot.length != 0 ||
                                    fusionHotSpot.length !=0
                                  " style="font-size: 13px !important" class="mt-0">
                  <i> *NA: Not Applicable</i>
                </p>
              </div>
            </div>
            <!--  -->
            <div *ngIf="(tableCount>0) &&
              prognasticCount==0">
              <h6 class="py-2 h6_bg"> Prognostic Biomarkers</h6>
            </div>
            <div class="row" *ngIf="(tableCount>0 )&&
              prognasticCount==0">
              <div class="col-12">
                <table class="page_1 cust_table w-100 mt-3 varient_details prognasticbiomarkertable"
                  [ngClass]="'table_variant-blue'">
                  <thead>
                    <tr>
                      <th>Genomic Alteration</th>
                      <th>Prognostic Significance</th>

                    </tr>
                  </thead>
                  <ng-container>
                    <tr>
                      <td colspan="2" style="text-align:center">
                        Clinically relevant genomic alterations associated with prognostic significance were not detected.
                      </td>
                    </tr>
                  </ng-container>
                </table>
              </div>
            </div>
            <div *ngIf="prognasticCount>0">
              <h6 class="py-2 h6_bg"> Prognostic Biomarkers</h6>
            </div>

            <div class="row" *ngIf="prognasticCount>0">
              <div class="col-12">
                <table class="page_1 cust_table w-100 mt-3 varient_details prognasticbiomarkertable"
                  [ngClass]="'table_variant-blue'">
                  <thead>
                    <tr>
                      <th>Genomic Alteration</th>
                      <th>Prognostic Significance</th>

                    </tr>
                  </thead>
                  <ng-container *ngFor="let prog of reportPdfRes.prognosticBiomarkers">
                    <tr>
                      <td style="padding-left: 3px !important">
                        <i>{{ prog.gene }}&nbsp; </i>
                        <span [innerHtml]="prog.geneDetails"></span>
                      </td>
                      <td style="padding-left: 3px !important;">{{prog.statement}}
                      </td>
                    </tr>
                  </ng-container>



                </table>
              </div>
            </div>
            <!--  -->

            <!-- gene Data -->
            <div *ngIf="geneMutationlist != null && isStatusDisplay">
              <div class="mt-3">
                <h6 class="py-2 h6_bg" [ngClass]="'skyBlue'">
                  Status of variants in cancer related Biomarkers
                </h6>
              </div>

              <table class="staus_gene bg_skyblue" *ngIf="geneMutationlist != null" [ngClass]="{
                                  biomarker_table:
                                    geneMutationlist.length >= 4 &&
                                    geneMutationlist.length < 8
                                }">
                <tr style="background: #daedf7">
                  <td style="font-size: 18px !important; padding-left: 10px !important">
                    Gene
                  </td>
                  <td *ngFor="
                                      let geneMutation of geneMutationlist
                                        | slice : 0 : 8
                                    ">
                    <i>{{ geneMutation.gene }}</i>
                  </td>
                </tr>
                <tr>
                  <td style="font-size: 18px !important; padding-left: 10px !important">
                    <b>Status</b>
                  </td>
                  <td *ngFor="
                                      let geneMutation of geneMutationlist
                                        | slice : 0 : 8
                                    ">
                    <span *ngIf="
                                        geneMutation.status == 'Pathogenic' ||
                                        geneMutation.status == 'Likely Pathogenic' ||
                                        geneMutation.status == 'Detected'
                                      " style="color: red; font-weight: 600px"><b>{{ geneMutation.status }}</b></span>
                    <span *ngIf="geneMutation.status == 'VUS'" style="color: black; font-weight: 600px"><b>{{
                        geneMutation.status }}</b></span>
                    <span *ngIf="
                                        geneMutation.status == 'Not Detected' ||
                                        geneMutation.status == 'Benige'
                                      ">{{ geneMutation.status }}</span>
                  </td>
                </tr>
<br/>
              </table>
              <table class="staus_gene" [ngClass]="'bg_skyblue'" *ngIf="
                                  geneMutationlist != null &&
                                  geneMutationlist.length > 8
                                ">
                <tr style="background: #daedf7">
                  <td style="font-size: 18px !important; padding-left: 10px !important">
                    Gene
                  </td>
                  <td *ngFor="
                                      let geneMutation of geneMutationlist
                                        | slice : 8 : 17
                                    ">
                    <i>{{ geneMutation.gene }}</i>
                  </td>
                </tr>
                <tr>
                  <td style="font-size: 18px !important; padding-left: 10px !important">
                    <b>Status</b>
                  </td>
                  <td *ngFor="
                                      let geneMutation of geneMutationlist
                                        | slice : 8 : 17
                                    ">
                    <span *ngIf="
                                        geneMutation.status == 'Pathogenic' ||
                                        geneMutation.status == 'Likely Pathogenic' ||
                                        geneMutation.status == 'Detected'
                                      " style="color: red; font-weight: 600px"><b>{{ geneMutation.status }}</b></span>
                    <span *ngIf="geneMutation.status == 'VUS'" style="color: black; font-weight: 600px"><b>{{
                        geneMutation.status }}</b></span>
                    <span *ngIf="
                                        geneMutation.status == 'Not Detected' ||
                                        geneMutation.status == 'Benige'
                                      ">{{ geneMutation.status }}</span>
                  </td>
                </tr>
<br/>
              </table>
              <table
                class="staus_gene"
                [ngClass]="'bg_skyblue'"
                *ngIf="
                  geneMutationlist != null &&
                  geneMutationlist.length > 17
                "
              >
                <tr style="background: #daedf7">
                  <td
                    style="font-size: 18px !important; padding-left: 10px !important"
                  >
                    Gene
                  </td>
                  <td
                    *ngFor="
                      let geneMutation of geneMutationlist
                        | slice : 17 : 25
                    "
                  >
                    <i>{{ geneMutation.gene }}</i>
                  </td>
                </tr>
                <tr>
                  <td
                    style="font-size: 18px !important; padding-left: 10px !important"
                  >
                    <b>Status</b>
                  </td>
                  <td
                    *ngFor="
                      let geneMutation of geneMutationlist
                        | slice : 17 : 25
                    "
                  >
                    <span
                      *ngIf="
                        geneMutation.status == 'Pathogenic' ||
                        geneMutation.status == 'Likely Pathogenic' ||
                        geneMutation.status == 'Detected'
                      "
                      style="color: red; font-weight: 600px"
                      ><b>{{ geneMutation.status }}</b></span
                    >
                    <span
                      *ngIf="geneMutation.status == 'VUS'"
                      style="color: black; font-weight: 600px"
                      ><b>{{ geneMutation.status }}</b></span
                    >
                    <span
                      *ngIf="
                        geneMutation.status == 'Not Detected' ||
                        geneMutation.status == 'Benige'
                      "
                      >{{ geneMutation.status }}</span
                    >
                  </td>
                </tr>
<br/>
              </table>
              <table class="staus_gene" [ngClass]="'bg_skyblue'" *ngIf="
                    geneMutationlist != null &&
                    geneMutationlist.length > 25
                  ">
                <tr style="background: #daedf7">
                  <td style="font-size: 18px !important; padding-left: 10px !important">
                    Gene
                  </td>
                  <td *ngFor="
                        let geneMutation of geneMutationlist
                          | slice : 25 : 33
                      ">
                    <i>{{ geneMutation.gene }}</i>
                  </td>
                </tr>
                <tr>
                  <td style="font-size: 18px !important; padding-left: 10px !important">
                    <b>Status</b>
                  </td>
                  <td *ngFor="
                        let geneMutation of geneMutationlist
                          | slice : 25 : 33
                      ">
                    <span *ngIf="
                          geneMutation.status == 'Pathogenic' ||
                          geneMutation.status == 'Likely Pathogenic' ||
                          geneMutation.status == 'Detected'
                        " style="color: red; font-weight: 600px"><b>{{ geneMutation.status }}</b></span>
                    <span *ngIf="geneMutation.status == 'VUS'" style="color: black; font-weight: 600px"><b>{{
                        geneMutation.status }}</b></span>
                    <span *ngIf="
                          geneMutation.status == 'Not Detected' ||
                          geneMutation.status == 'Benige'
                        ">{{ geneMutation.status }}</span>
                  </td>
                </tr>
<br/>
              </table>
          <table class="staus_gene" [ngClass]="'bg_skyblue'" *ngIf="
                geneMutationlist != null &&
                geneMutationlist.length > 33
              ">
            <tr style="background: #daedf7">
              <td style="font-size: 18px !important; padding-left: 10px !important">
                Gene
              </td>
              <td *ngFor="
                    let geneMutation of geneMutationlist
                      | slice : 33 : 41
                  ">
                <i>{{ geneMutation.gene }}</i>
              </td>
            </tr>
            <tr>
              <td style="font-size: 18px !important; padding-left: 10px !important">
                <b>Status</b>
              </td>
              <td *ngFor="
                    let geneMutation of geneMutationlist
                      | slice : 33 : 41
                  ">
                <span *ngIf="
                      geneMutation.status == 'Pathogenic' ||
                      geneMutation.status == 'Likely Pathogenic' ||
                      geneMutation.status == 'Detected'
                    " style="color: red; font-weight: 600px"><b>{{ geneMutation.status }}</b></span>
                <span *ngIf="geneMutation.status == 'VUS'" style="color: black; font-weight: 600px"><b>{{
                    geneMutation.status }}</b></span>
                <span *ngIf="
                      geneMutation.status == 'Not Detected' ||
                      geneMutation.status == 'Benige'
                    ">{{ geneMutation.status }}</span>
              </td>
            </tr>
            <br/>
          </table>
              <h6 *ngIf="
                                  (fusionGeneMutationList != null &&
                                  fusionGeneMutationList.length > 0)
                                " class="font-weight-bold clr-cyan text-uppercase" [ngClass]="'txt-skyblue'">
                Gene Fusion
              </h6>
              <table class="staus_gene" *ngIf="
                                  (fusionGeneMutationList != null &&
                                  fusionGeneMutationList.length > 0)
                                " [ngClass]="'bg_skyblue'">
                <tr style="background: #daedf7">
                  <td style="font-size: 18px !important; padding-left: 10px !important">
                    Gene
                  </td>
                  <td *ngFor="
                                      let geneMutation of fusionGeneMutationList
                                        | slice : 0 : 8
                                    ">
                    <i>{{ geneMutation.gene }}</i>
                  </td>
                </tr>
                <tr>
                  <td style="font-size: 18px !important; padding-left: 10px !important">
                    <b>Status</b>
                  </td>
                  <td *ngFor="
                                      let geneMutation of fusionGeneMutationList
                                        | slice : 0 : 8
                                    ">
                    <span *ngIf="
                                        geneMutation.status == 'Pathogenic' ||
                                        geneMutation.status == 'Likely Pathogenic' ||
                                        geneMutation.status == 'Detected'
                                      " style="color: red; font-weight: 600px"><b>{{ geneMutation.status }}</b></span>
                    <span *ngIf="geneMutation.status == 'VUS'" style="color: black; font-weight: 600px"><b>{{
                        geneMutation.status }}</b></span>
                    <span *ngIf="
                                        geneMutation.status == 'Not Detected' ||
                                        geneMutation.status == 'Benige'
                                      ">{{ geneMutation.status }}</span>
                  </td>
                </tr>
<br/>
              </table>
              <table class="staus_gene" *ngIf="
                                  (fusionGeneMutationList != null &&
                                  fusionGeneMutationList.length > 8)
                                " [ngClass]="'bg_skyblue'">
                <tr style="background: #daedf7">
                  <td style="font-size: 18px !important; padding-left: 10px !important">
                    Gene
                  </td>
                  <td *ngFor="
                                      let geneMutation of fusionGeneMutationList
                                        | slice : 8 : 17
                                    ">
                    <i>{{ geneMutation.gene }}</i>
                  </td>
                </tr>
                <tr>
                  <td style="font-size: 18px !important; padding-left: 10px !important">
                    <b>Status</b>
                  </td>
                  <td *ngFor="
                                      let geneMutation of fusionGeneMutationList
                                        | slice : 8 : 17
                                    ">
                    <span *ngIf="
                                        geneMutation.status == 'Pathogenic' ||
                                        geneMutation.status == 'Likely Pathogenic' ||
                                        geneMutation.status == 'Detected'
                                      " style="color: red; font-weight: 600px"><b>{{ geneMutation.status }}</b></span>
                    <span *ngIf="geneMutation.status == 'VUS'" style="color: black; font-weight: 600px"><b>{{
                        geneMutation.status }}</b></span>
                    <span *ngIf="
                                        geneMutation.status == 'Not Detected' ||
                                        geneMutation.status == 'Benige'
                                      ">{{ geneMutation.status }}</span>
                  </td>
                </tr>
              </table>
            </div>
            <div
            *ngIf="
              report.disclaimerNote != ' ' &&
              report.disclaimerNote != null &&
              fusionGeneMutationList.length <= 0
            "
          >

            <h6 style="color: #208dcb"><b>Note: </b></h6>
            <div
              style="font-size: 14px !important;text-align: justify"
              [innerHtml]="report.disclaimerNote"
            ></div>
          </div>

            <!--  -->
            <div class="row footer">
              <div class="col-12">
                <div class="pb-2 pl-4 text">
                  <div class="d-block">
                    <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
                  </div>
                  <div class="footer_body" [ngClass]="'gene-bg_blue'">
                    <div class="footer_list">
                      <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
                      <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
                      <span>Gin - Q</span><span>Gly - G</span><span>His - H</span>
                      <span>Ile - I</span>
                    </div>
                    <div class="footer_list">
                      <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
                      <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
                      <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
                      <span>Val - V</span>
                    </div>
                  </div>
                </div>
                <div class="col-12 pt-1 ">
                  <p style="font-size: 8px!important; line-height: 14px!important;">
                    {{footerDiscription}}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <!-- page 2 -->
          <div id="page2" *ngIf="
                            otherCountSNV == true ||
                            reportPdfRes.allVariantsList.length > 0 ||
                            (report.disclaimerNote != ' ' &&
              report.disclaimerNote != null &&
              fusionGeneMutationList.length > 0) ||
              (report.disclaimer != '<p></p>' &&
            report.disclaimer != null) || (report.addNotes != '<div></div>')
                            " class="container position-relative page" style="background-color: white">
            <div class="row header mt-1 py-4">
              <div>
                <div class="col-3">
                  <img src="../../../assets/logo.png" alt="" style="width: 140px" />
                </div>
              </div>
              <div></div>
              <div class="col">
                <div class="patient-detailes ml-auto float-right">
                  <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
                    <li>
                      Patient Id <br />
                      <span>{{ report.patient?.ecrfId }} </span>
                    </li>
                    <li class="ml-4">
                      Cancer Site<br />
                      <span>{{ report.cancerType }}</span>
                    </li>
                    <li class="ml-4">
                      SAMPLE ID<br />
                      <span>{{ report.labDetails?.sampleId }}</span>
                    </li>
                    <li class="ml-4">
                      Registration Date<br />
                      <span>{{
                        report.labDetails?.sampleCollected | date : "dd/MM/yyyy"
                        }}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <!--  -->
              <div *ngIf="report.clientType == '4basecare_client'">
                <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
                  </div>
                </div>
                <div *ngIf="report.clientName == 'path_labs'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
                  </div>
                </div>
              </div>
            </div>


            <!--  -->
            <div
            *ngIf="
              report.disclaimerNote != ' ' &&
              report.disclaimerNote != null &&
              fusionGeneMutationList.length > 0
            "
          >

            <h6 style="color: #208dcb"><b>Note: </b></h6>
            <div
              style="font-size: 14px !important;text-align: justify"
              [innerHtml]="report.disclaimerNote"
            ></div>
          </div>
            <div class="row mt-3" *ngIf="
                                otherCountSNV == true ||
                                reportPdfRes.allVariantsList.length != 0
                              ">
              <div class="col">
                <h6 class="font-weight-bold clr-cyan text-uppercase" [ngClass]="'txt-skyblue'">
                  Variant Details:
                </h6>
              </div>
            </div>

            <div class="row" *ngIf="
                                otherCountSNV == true ||
                                reportPdfRes.allVariantsList.length != 0
                              ">
              <div class="col-12">
                <table class="page_1 cust_table w-100 mt-3 varient_details" [ngClass]="'table_variant-blue'">
                  <thead>
                    <tr>
                      <th>Gene</th>
                      <th>Variant Location</th>
                      <th>Variant Consequence</th>
                      <th>Clinical Significance</th>
                      <th>Variant Type</th>
                      <th>Reference</th>
                    </tr>
                  </thead>
                  <tr *ngFor="let variant of reportPdfRes.allVariantsList">
                    <td *ngIf="variant.significance != 'NA' && variant.significance != 'Not Classified'" style="padding-left: 3px !important">
                      <i>{{ variant.gene }}</i>
                    </td>
                    <td *ngIf="variant.significance != 'NA' && variant.significance != 'Not Classified'" style="padding-left: 3px !important">
                      <span *ngIf="variant?.variantType=='snv'">
                        {{ variant?.genomicAlteration }},<br />
                        {{ variant?.ensembleValue }},<br />
                        {{ variant?.exonNumber }}
                      </span>
                      <span *ngIf="variant?.variantType=='cna'">
                        chr{{ variant?.sample?.split(":")[0] }}:g.{{
                          variant.sample
                          .split(":")[1]
                          .replace("-", "_")
                          .split("|")[0]
                          }}
                      </span>
                      <span *ngIf="variant.variantType=='fusion'">
                        {{ variant?.leftBreakPoint }}<br />{{variant?.rightBreakPoint}}
                      </span>
                    </td>
                    <td *ngIf="variant?.significance != 'NA' && variant?.significance != 'Not Classified'" style="padding-left: 3px !important">
                      <span *ngIf="variant.variantType=='snv'">
                        <span *ngIf="variant?.selectedAAChangeknownGene">{{
                          variant?.selectedAAChangeknownGene|splitComma }}</span>, {{variant?.mutantBurdenPercent * 0.01 | percent}}
                        </span>
                      <span *ngIf="variant.variantType=='cna'">NA</span>
                      <span *ngIf="variant.variantType=='fusion'">NA</span>
                    </td>
                    <td *ngIf="variant?.significance != 'NA' && variant?.significance != 'Not Classified'" style="padding-left: 3px !important">
                      {{ variant?.significance }}
                    </td>
                    <td *ngIf="variant?.significance != 'NA' && variant?.significance != 'Not Classified'" style="padding-left: 3px !important">
                      <span *ngIf="variant.variantType=='snv'">
                        {{ variant?.exonicFuncknownGene }}
                      </span>
                      <span *ngIf="variant.variantType=='cna'">
                        Copy Number
                        {{ variant?.sample.split(":")[2] | titlecase }}
                        ({{ variant?.sample.split("|")[1].split(":")[0] }})
                      </span>
                      <span *ngIf="variant.variantType=='fusion'">Gene Fusions</span>
                    </td>
                    <td *ngIf="variant?.significance != 'NA' && variant?.significance != 'Not Classified'" style="padding-left: 3px !important">
                      {{ variant?.reference }}
                    </td>
                  </tr>
                </table>
                <p *ngIf="reportPdfRes.allVariantsList.length > 0 " style="font-size: 13px !important" class="mt-0">
                  <i> *NA: Not Applicable</i>
                </p>
              </div>
            </div>



            <!--  -->
            <div class="row mt-5" *ngIf="
            !variantFlag &&
            report.addNotes != null && report.addNotes != '<div></div>'
          ">
    <div class="col">
      <h6 class="py-2 h6_bg">Additional Notes</h6>
      <div class="py-2" [innerHtml]="report.addNotes" style="text-align: justify; font-size: 14px !important"></div>
    </div>
  </div>

            <!--  -->
            <div class="row footer">
              <div class="col-12">
                <div class="pb-2 pl-4 text">
                  <div class="d-block">
                    <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
                  </div>
                  <div class="footer_body" [ngClass]="'gene-bg_blue'">
                    <div class="footer_list">
                      <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
                      <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
                      <span>Gin - Q</span><span>Gly - G</span><span>His - H</span>
                      <span>Ile - I</span>
                    </div>
                    <div class="footer_list">
                      <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
                      <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
                      <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
                      <span>Val - V</span>
                    </div>
                  </div>
                </div>
                <div class="col-12 pt-1 ">
                  <p style="font-size: 8px!important; line-height: 14px!important;">
                    {{footerDiscription}}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <!-- page 3 -->
          <div id="page3" *ngIf="reportPdfRes.drugsResList.length > 0" class="container position-relative page"
            style="background-color: white">
            <div class="row header mt-1 py-4">
              <div>
                <div class="col-3">
                  <img src="../../../assets/logo.png" alt="" style="width: 140px" />
                </div>
              </div>
              <div></div>
              <div class="col">
                <div class="patient-detailes ml-auto float-right">
                  <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
                    <li>
                      Patient Id <br />
                      <span>{{ report.patient?.ecrfId }}</span>
                    </li>
                    <li class="ml-4">
                      Cancer Site<br />
                      <span>{{ report.cancerType }}</span>
                    </li>
                    <li class="ml-4">
                      SAMPLE ID<br />
                      <span>{{ report.labDetails?.sampleId }}</span>
                    </li>
                    <li class="ml-4">
                      Registration Date<br />
                      <span>{{
                        report.labDetails?.sampleCollected | date : "dd/MM/yyyy"
                        }}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <!--  -->
              <div *ngIf="report.clientType == '4basecare_client'">
                <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
                  </div>
                </div>
                <div *ngIf="report.clientName == 'path_labs'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
                  </div>
                </div>
              </div>
            </div>
            <!--  -->
            <div class="mar_top"></div>
            <!--  -->

            <h6 class="py-2 h6_bg" [ngClass]="'skyBlue'">
              Alterations With Clinically Relevant Therapies
            </h6>
            <!--  -->
            <div class="row">
              <div class="col-12">
                <table class="page_1 cust_table w-100 mt-3 first_2 custom-table" [ngClass]="'bg_cyan'">
                  <thead>
                    <tr>
                      <th>Gene (Alterations) - Drug association</th>
                      <th>Summary</th>
                    </tr>
                  </thead>
                  <!-- SNV -->

                  <tbody>
                    <tr *ngFor="
                                                                let drugsRes of reportPdfRes.drugsResList | slice : 0 : 4;
                                                                let i = index
                                                              ">
                      <td>
                        <ul class="ml-0 pl-2">
                          <li>
                            <i>{{ drugsRes.gene.replace("Fusion","") }}</i><span *ngIf="drugsRes.gene.includes('Fusion')">Fusion</span> {{ drugsRes.pposition?.replace(' ','') }} :
                            <br />
                            <span *ngFor="let drug of drugsRes.drugs; let i = index">
                              {{ drug.name
                              }}<span *ngIf="drugsRes.drugs.length - 1 != i">,</span>
                            </span>
                            - {{ drugsRes.association }}
                            <p>{{ drugsRes.cancerType }}</p>
                          </li>
                        </ul>
                      </td>
                      <td>
                        <div [innerHtml]="drugsRes.summary"></div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div class="row footer">
              <div class="col-12">
                <div class="pb-2 pl-4 text">
                  <div class="d-block">
                    <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
                  </div>
                  <div class="footer_body" [ngClass]="'gene-bg_blue'">
                    <div class="footer_list">
                      <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
                      <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
                      <span>Gin - Q</span><span>Gly - G</span><span>His - H</span>
                      <span>Ile - I</span>
                    </div>
                    <div class="footer_list">
                      <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
                      <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
                      <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
                      <span>Val - V</span>
                    </div>
                  </div>
                </div>
                <div class="col-12 pt-1 ">
                  <p style="font-size: 8px!important; line-height: 14px!important;">
                    {{footerDiscription}}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <!-- page 3_1 -->
          <div id="page3_1" *ngIf="reportPdfRes.drugsResList.length > 4" class="container position-relative page"
            style="background-color: white">
            <div class="row header mt-1 py-4">
              <div>
                <div class="col-3">
                  <img src="../../../assets/logo.png" alt="" style="width: 140px" />
                </div>
              </div>
              <div></div>
              <div class="col">
                <div class="patient-detailes ml-auto float-right">
                  <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
                    <li>
                      Patient Id <br />
                      <span>{{ report.patient?.ecrfId }}</span>
                    </li>
                    <li class="ml-4">
                      Cancer Site<br />
                      <span>{{ report.cancerType }}</span>
                    </li>
                    <li class="ml-4">
                      SAMPLE ID<br />
                      <span>{{ report.labDetails?.sampleId }}</span>
                    </li>
                    <li class="ml-4">
                      Registration Date<br />
                      <span>{{
                        report.labDetails?.sampleCollected | date : "dd/MM/yyyy"
                        }}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <!--  -->
              <div *ngIf="report.clientType == '4basecare_client'">
                <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
                  </div>
                </div>
                <div *ngIf="report.clientName == 'path_labs'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
                  </div>
                </div>
              </div>
            </div>
            <!--  -->
            <div class="mar_top"></div>
            <!--  -->

            <h6 class="py-2 h6_bg" [ngClass]="'skyBlue'">
              Alterations With Clinically Relevant Therapies
            </h6>
            <!--  -->
            <div class="row">
              <div class="col-12">
                <table class="page_1 cust_table w-100 mt-3 first_2 2nd_table">
                  <thead>
                    <tr>
                      <th>Gene (Alterations) - Drug association</th>
                      <th>Summary</th>
                    </tr>
                  </thead>
                  <!-- SNV -->

                  <tbody>
                    <tr *ngFor="
                                                                          let drugsRes of reportPdfRes.drugsResList | slice : 4 : 8;
                                                                          let i = index
                                                                        ">
                      <td>
                        <ul class="ml-0 pl-2">
                          <li>
                            <i>{{ drugsRes.gene.replace("Fusion","") }}</i><span *ngIf="drugsRes.gene.includes('Fusion')">Fusion</span> {{ drugsRes.pposition?.replace(' ','') }} :
                            <br />
                            <span *ngFor="let drug of drugsRes.drugs; let i = index">
                              {{ drug.name
                              }}<span *ngIf="drugsRes.drugs.length - 1 != i">,</span>
                            </span>
                            - {{ drugsRes.association }}
                            <p>{{ drugsRes.cancerType }}</p>
                          </li>
                        </ul>
                      </td>
                      <td>
                        <div [innerHtml]="drugsRes.summary"></div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <!--  -->

            <!--  -->
            <div class="row footer">
              <div class="col-12">
                <div class="pb-2 pl-4 text">
                  <div class="d-block">
                    <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
                  </div>
                  <div class="footer_body" [ngClass]="'gene-bg_blue'">
                    <div class="footer_list">
                      <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
                      <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
                      <span>Gin - Q</span><span>Gly - G</span><span>His - H</span>
                      <span>Ile - I</span>
                    </div>
                    <div class="footer_list">
                      <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
                      <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
                      <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
                      <span>Val - V</span>
                    </div>
                  </div>
                </div>
                <div class="col-12 pt-1 ">
                  <p style="font-size: 8px!important; line-height: 14px!important;">
                    {{footerDiscription}}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <!-- page 3_2 -->
          <div id="page3_2" *ngIf="reportPdfRes.drugsResList.length > 8" class="container position-relative page"
            style="background-color: white">
            <div class="row header mt-1 py-4">
              <div>
                <div class="col-3">
                  <img src="../../../assets/logo.png" alt="" style="width: 140px" />
                </div>
              </div>
              <div></div>
              <div class="col">
                <div class="patient-detailes ml-auto float-right">
                  <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
                    <li>
                      Patient Id <br />
                      <span>{{ report.patient?.ecrfId }}</span>
                    </li>
                    <li class="ml-4">
                      Cancer Site<br />
                      <span>{{ report.cancerType }}</span>
                    </li>
                    <li class="ml-4">
                      SAMPLE ID<br />
                      <span>{{ report.labDetails?.sampleId }}</span>
                    </li>
                    <li class="ml-4">
                      Registration Date<br />
                      <span>{{
                        report.labDetails?.sampleCollected | date : "dd/MM/yyyy"
                        }}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <!--  -->
              <div *ngIf="report.clientType == '4basecare_client'">
                <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
                  </div>
                </div>
                <div *ngIf="report.clientName == 'path_labs'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
                  </div>
                </div>
              </div>
            </div>
            <!--  -->
            <div class="mar_top"></div>
            <!--  -->

            <h6 class="py-2 h6_bg" [ngClass]="'skyBlue'">
              Alterations With Clinically Relevant Therapies
            </h6>
            <!--  -->
            <div class="row">
              <div class="col-12">
                <table class="page_1 cust_table w-100 mt-3 first_2 2nd_table">
                  <thead>
                    <tr>
                      <th>Gene (Alterations) - Drug association</th>
                      <th>Summary</th>
                    </tr>
                  </thead>
                  <!-- SNV -->

                  <tbody>
                    <tr *ngFor="
                                                                                              let drugsRes of reportPdfRes.drugsResList | slice : 8 : 12;
                                                                                              let i = index
                                                                                            ">
                      <td>
                        <ul class="ml-0 pl-2">
                          <li>
                            <i>{{ drugsRes.gene.replace("Fusion","") }}</i><span *ngIf="drugsRes.gene.includes('Fusion')">Fusion</span> {{ drugsRes.pposition?.replace(' ','')}} :
                            <br />
                            <span *ngFor="let drug of drugsRes.drugs; let i = index">
                              {{ drug.name
                              }}<span *ngIf="drugsRes.drugs.length - 1 != i">,</span>
                            </span>
                            - {{ drugsRes.association }}
                            <p>{{ drugsRes.cancerType }}</p>
                          </li>
                        </ul>
                      </td>
                      <td>
                        <div [innerHtml]="drugsRes.summary"></div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <!--  -->

            <!--  -->
            <div class="row footer">
              <div class="col-12">
                <div class="pb-2 pl-4 text">
                  <div class="d-block">
                    <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
                  </div>
                  <div class="footer_body" [ngClass]="'gene-bg_blue'">
                    <div class="footer_list">
                      <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
                      <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
                      <span>Gin - Q</span><span>Gly - G</span><span>His - H</span>
                      <span>Ile - I</span>
                    </div>
                    <div class="footer_list">
                      <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
                      <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
                      <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
                      <span>Val - V</span>
                    </div>
                  </div>
                </div>
                <div class="col-12 pt-1 ">
                  <p style="font-size: 8px!important; line-height: 14px!important;">
                    {{footerDiscription}}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <!-- page 3_3 -->
          <div id="page3_3" *ngIf="reportPdfRes.drugsResList.length > 12" class="container position-relative page"
            style="background-color: white">
            <div class="row header mt-1 py-4">
              <div>
                <div class="col-3">
                  <img src="../../../assets/logo.png" alt="" style="width: 140px" />
                </div>
              </div>
              <div></div>
              <div class="col">
                <div class="patient-detailes ml-auto float-right">
                  <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
                    <li>
                      Patient Id <br />
                      <span>{{ report.patient?.ecrfId }}</span>
                    </li>
                    <li class="ml-4">
                      Cancer Site<br />
                      <span>{{ report.cancerType }}</span>
                    </li>
                    <li class="ml-4">
                      SAMPLE ID<br />
                      <span>{{ report.labDetails?.sampleId }}</span>
                    </li>
                    <li class="ml-4">
                      Registration Date<br />
                      <span>{{
                        report.labDetails?.sampleCollected | date : "dd/MM/yyyy"
                        }}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <!--  -->
              <div *ngIf="report.clientType == '4basecare_client'">
                <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
                  </div>
                </div>
                <div *ngIf="report.clientName == 'path_labs'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
                  </div>
                </div>
              </div>
            </div>
            <!--  -->
            <div class="mar_top"></div>
            <!--  -->

            <h6 class="py-2 h6_bg" [ngClass]="'skyBlue'">
              Alterations With Clinically Relevant Therapies
            </h6>
            <!--  -->
            <div class="row">
              <div class="col-12">
                <table class="page_1 cust_table w-100 mt-3 first_2 2nd_table">
                  <thead>
                    <tr>
                      <th>Gene (Alterations) - Drug association</th>
                      <th>Summary</th>
                    </tr>
                  </thead>
                  <!-- SNV -->

                  <tbody>
                    <tr *ngFor="
                                                                                                                    let drugsRes of reportPdfRes.drugsResList | slice : 12 : 16;
                                                                                                                    let i = index
                                                                                                                  ">
                      <td>
                        <ul class="ml-0 pl-2">
                          <li>
                            <i>{{ drugsRes.gene.replace("Fusion","") }}</i><span *ngIf="drugsRes.gene.includes('Fusion')">Fusion</span> {{ drugsRes.pposition?.replace(' ','')}} :
                            <br />
                            <span *ngFor="let drug of drugsRes.drugs; let i = index">
                              {{ drug.name
                              }}<span *ngIf="drugsRes.drugs.length - 1 != i">,</span>
                            </span>
                            - {{ drugsRes.association }}
                            <p>{{ drugsRes.cancerType }}</p>
                          </li>
                        </ul>
                      </td>
                      <td>
                        <div [innerHtml]="drugsRes.summary"></div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <!--  -->

            <!--  -->
            <div class="row footer">
              <div class="col-12">
                <div class="pb-2 pl-4 text">
                  <div class="d-block">
                    <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
                  </div>
                  <div class="footer_body" [ngClass]="'gene-bg_blue'">
                    <div class="footer_list">
                      <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
                      <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
                      <span>Gin - Q</span><span>Gly - G</span><span>His - H</span>
                      <span>Ile - I</span>
                    </div>
                    <div class="footer_list">
                      <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
                      <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
                      <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
                      <span>Val - V</span>
                    </div>
                  </div>
                </div>
                <div class="col-12 pt-1 ">
                  <p style="font-size: 8px!important; line-height: 14px!important;">
                    {{footerDiscription}}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <!-- page 3_4 -->
          <div id="page3_4" *ngIf="reportPdfRes.drugsResList.length > 16" class="container position-relative page"
            style="background-color: white">
            <div class="row header mt-1 py-4">
              <div>
                <div class="col-3">
                  <img src="../../../assets/logo.png" alt="" style="width: 140px" />
                </div>
              </div>
              <div></div>
              <div class="col">
                <div class="patient-detailes ml-auto float-right">
                  <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
                    <li>
                      Patient Id <br />
                      <span>{{ report.patient?.ecrfId }}</span>
                    </li>
                    <li class="ml-4">
                      Cancer Site<br />
                      <span>{{ report.cancerType }}</span>
                    </li>
                    <li class="ml-4">
                      SAMPLE ID<br />
                      <span>{{ report.labDetails?.sampleId }}</span>
                    </li>
                    <li class="ml-4">
                      Registration Date<br />
                      <span>{{
                        report.labDetails?.sampleCollected | date : "dd/MM/yyyy"
                        }}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <!--  -->
              <div *ngIf="report.clientType == '4basecare_client'">
                <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
                  </div>
                </div>
                <div *ngIf="report.clientName == 'path_labs'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
                  </div>
                </div>
              </div>
            </div>
            <!--  -->
            <div class="mar_top"></div>
            <!--  -->

            <h6 class="py-2 h6_bg" [ngClass]="'skyBlue'">
              Alterations With Clinically Relevant Therapies
            </h6>
            <!--  -->
            <div class="row">
              <div class="col-12">
                <table class="page_1 cust_table w-100 mt-3 first_2 2nd_table">
                  <thead>
                    <tr>
                      <th>Gene (Alterations) - Drug association</th>
                      <th>Summary</th>
                    </tr>
                  </thead>
                  <!-- SNV -->

                  <tbody>
                    <tr *ngFor="
                                                                                                          let drugsRes of reportPdfRes.drugsResList | slice : 16 : 20;
                                                                                                          let i = index
                                                                                                        ">
                      <td>
                        <ul class="ml-0 pl-2">
                          <li>
                            <i>{{ drugsRes.gene.replace("Fusion","") }}</i><span *ngIf="drugsRes.gene.includes('Fusion')">Fusion</span> {{ drugsRes.pposition?.replace(' ','')}} :
                            <br />
                            <span *ngFor="let drug of drugsRes.drugs; let i = index">
                              {{ drug.name
                              }}<span *ngIf="drugsRes.drugs.length - 1 != i">,</span>
                            </span>
                            - {{ drugsRes.association }}
                            <p>{{ drugsRes.cancerType }}</p>
                          </li>
                        </ul>
                      </td>
                      <td>
                        <div [innerHtml]="drugsRes.summary"></div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <!--  -->

            <!--  -->
            <div class="row footer">
              <div class="col-12">
                <div class="pb-2 pl-4 text">
                  <div class="d-block">
                    <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
                  </div>
                  <div class="footer_body" [ngClass]="'gene-bg_blue'">
                    <div class="footer_list">
                      <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
                      <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
                      <span>Gin - Q</span><span>Gly - G</span><span>His - H</span>
                      <span>Ile - I</span>
                    </div>
                    <div class="footer_list">
                      <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
                      <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
                      <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
                      <span>Val - V</span>
                    </div>
                  </div>
                </div>
                <div class="col-12 pt-1 ">
                  <p style="font-size: 8px!important; line-height: 14px!important;">
                    {{footerDiscription}}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <!-- page 3_5 -->
          <div id="page3_5" *ngIf="reportPdfRes.drugsResList.length > 20" class="container position-relative page"
            style="background-color: white">
            <div class="row header mt-1 py-4">
              <div>
                <div class="col-3">
                  <img src="../../../assets/logo.png" alt="" style="width: 140px" />
                </div>
              </div>
              <div></div>
              <div class="col">
                <div class="patient-detailes ml-auto float-right">
                  <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
                    <li>
                      Patient Id <br />
                      <span>{{ report.patient?.ecrfId }}</span>
                    </li>
                    <li class="ml-4">
                      Cancer Site<br />
                      <span>{{ report.cancerType }}</span>
                    </li>
                    <li class="ml-4">
                      SAMPLE ID<br />
                      <span>{{ report.labDetails?.sampleId }}</span>
                    </li>
                    <li class="ml-4">
                      Registration Date<br />
                      <span>{{
                        report.labDetails?.sampleCollected | date : "dd/MM/yyyy"
                        }}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <!--  -->
              <div *ngIf="report.clientType == '4basecare_client'">
                <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
                  </div>
                </div>
                <div *ngIf="report.clientName == 'path_labs'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
                  </div>
                </div>
              </div>
            </div>
            <!--  -->
            <div class="mar_top"></div>
            <!--  -->

            <h6 class="py-2 h6_bg" [ngClass]="'skyBlue'">
              Alterations With Clinically Relevant Therapies
            </h6>
            <!--  -->
            <div class="row">
              <div class="col-12">
                <table class="page_1 cust_table w-100 mt-3 first_2 2nd_table">
                  <thead>
                    <tr>
                      <th>Gene (Alterations) - Drug association</th>
                      <th>Summary</th>
                    </tr>
                  </thead>
                  <!-- SNV -->

                  <tbody>
                    <tr
                      *ngFor="
                      let drugsRes of reportPdfRes.drugsResList | slice : 20 : 24; let i = index ">
                      <td>
                        <ul class="ml-0 pl-2">
                          <li>
                            <i>{{ drugsRes.gene.replace("Fusion","") }}</i><span *ngIf="drugsRes.gene.includes('Fusion')">Fusion</span> {{ drugsRes.pposition?.replace(' ','')}} :
                            <br />
                            <span *ngFor="let drug of drugsRes.drugs; let i = index">
                              {{ drug.name
                              }}<span *ngIf="drugsRes.drugs.length - 1 != i">,</span>
                            </span>
                            - {{ drugsRes.association }}
                            <p>{{ drugsRes.cancerType }}</p>
                          </li>
                        </ul>
                      </td>
                      <td>
                        <div [innerHtml]="drugsRes.summary"></div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <!--  -->

            <!--  -->
            <div class="row footer">
              <div class="col-12">
                <div class="pb-2 pl-4 text">
                  <div class="d-block">
                    <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
                  </div>
                  <div class="footer_body" [ngClass]="'gene-bg_blue'">
                    <div class="footer_list">
                      <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
                      <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
                      <span>Gin - Q</span><span>Gly - G</span><span>His - H</span>
                      <span>Ile - I</span>
                    </div>
                    <div class="footer_list">
                      <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
                      <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
                      <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
                      <span>Val - V</span>
                    </div>
                  </div>
                </div>
                <div class="col-12 pt-1 ">
                  <p style="font-size: 8px!important; line-height: 14px!important;">
                    {{footerDiscription}}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <!-- page 3_6 -->
          <div id="page3_6" *ngIf="reportPdfRes.drugsResList.length > 24" class="container position-relative page"
            style="background-color: white">
            <div class="row header mt-1 py-4">
              <div>
                <div class="col-3">
                  <img src="../../../assets/logo.png" alt="" style="width: 140px" />
                </div>
              </div>
              <div></div>
              <div class="col">
                <div class="patient-detailes ml-auto float-right">
                  <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
                    <li>
                      Patient Id <br />
                      <span>{{ report.patient?.ecrfId }}</span>
                    </li>
                    <li class="ml-4">
                      Cancer Site<br />
                      <span>{{ report.cancerType }}</span>
                    </li>
                    <li class="ml-4">
                      SAMPLE ID<br />
                      <span>{{ report.labDetails?.sampleId }}</span>
                    </li>
                    <li class="ml-4">
                      Registration Date<br />
                      <span>{{
                        report.labDetails?.sampleCollected | date : "dd/MM/yyyy"
                        }}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <!--  -->
              <div *ngIf="report.clientType == '4basecare_client'">
                <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
                  </div>
                </div>
                <div *ngIf="report.clientName == 'path_labs'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
                  </div>
                </div>
              </div>
            </div>
            <!--  -->
            <div class="mar_top"></div>
            <!--  -->

            <h6 class="py-2 h6_bg" [ngClass]="'skyBlue'">
              Alterations With Clinically Relevant Therapies
            </h6>
            <!--  -->
            <div class="row">
              <div class="col-12">
                <table class="page_1 cust_table w-100 mt-3 first_2 2nd_table">
                  <thead>
                    <tr>
                      <th>Gene (Alterations) - Drug association</th>
                      <th>Summary</th>
                    </tr>
                  </thead>
                  <!-- SNV -->

                  <tbody>
                    <tr
                      *ngFor=" let drugsRes of reportPdfRes.drugsResList | slice : 24 : 28; let i = index ">
                      <td>
                        <ul class="ml-0 pl-2">
                          <li>
                            <i>{{ drugsRes.gene.replace("Fusion","") }}</i><span *ngIf="drugsRes.gene.includes('Fusion')">Fusion</span> {{ drugsRes.pposition?.replace(' ','')}} :
                            <br />
                            <span *ngFor="let drug of drugsRes.drugs; let i = index">
                              {{ drug.name
                              }}<span *ngIf="drugsRes.drugs.length - 1 != i">,</span>
                            </span>
                            - {{ drugsRes.association }}
                            <p>{{ drugsRes.cancerType }}</p>
                          </li>
                        </ul>
                      </td>
                      <td>
                        <div [innerHtml]="drugsRes.summary"></div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <!--  -->

            <!--  -->
            <div class="row footer">
              <div class="col-12">
                <div class="pb-2 pl-4 text">
                  <div class="d-block">
                    <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
                  </div>
                  <div class="footer_body" [ngClass]="'gene-bg_blue'">
                    <div class="footer_list">
                      <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
                      <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
                      <span>Gin - Q</span><span>Gly - G</span><span>His - H</span>
                      <span>Ile - I</span>
                    </div>
                    <div class="footer_list">
                      <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
                      <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
                      <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
                      <span>Val - V</span>
                    </div>
                  </div>
                </div>
                <div class="col-12 pt-1 ">
                  <p style="font-size: 8px!important; line-height: 14px!important;">
                    {{footerDiscription}}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <!-- page 3_7 -->
          <div id="page3_7" *ngIf="reportPdfRes.drugsResList.length > 28" class="container position-relative page"
            style="background-color: white">
            <div class="row header mt-1 py-4">
              <div>
                <div class="col-3">
                  <img src="../../../assets/logo.png" alt="" style="width: 140px" />
                </div>
              </div>
              <div></div>
              <div class="col">
                <div class="patient-detailes ml-auto float-right">
                  <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
                    <li>
                      Patient Id <br />
                      <span>{{ report.patient?.ecrfId }}</span>
                    </li>
                    <li class="ml-4">
                      Cancer Site<br />
                      <span>{{ report.cancerType }}</span>
                    </li>
                    <li class="ml-4">
                      SAMPLE ID<br />
                      <span>{{ report.labDetails?.sampleId }}</span>
                    </li>
                    <li class="ml-4">
                      Registration Date<br />
                      <span>{{
                        report.labDetails?.sampleCollected | date : "dd/MM/yyyy"
                        }}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <!--  -->
              <div *ngIf="report.clientType == '4basecare_client'">
                <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
                  </div>
                </div>
                <div *ngIf="report.clientName == 'path_labs'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
                  </div>
                </div>
              </div>
            </div>
            <!--  -->
            <div class="mar_top"></div>
            <!--  -->

            <h6 class="py-2 h6_bg" [ngClass]="'skyBlue'">
              Alterations With Clinically Relevant Therapies
            </h6>
            <!--  -->
            <div class="row">
              <div class="col-12">
                <table class="page_1 cust_table w-100 mt-3 first_2 2nd_table">
                  <thead>
                    <tr>
                      <th>Gene (Alterations) - Drug association</th>
                      <th>Summary</th>
                    </tr>
                  </thead>
                  <!-- SNV -->

                  <tbody>
                    <tr
                      *ngFor="
                                                                                                                                                                          let drugsRes of reportPdfRes.drugsResList | slice : 28 : 32;
                                                                                                                                                                          let i = index
                                                                                                                                                                        ">
                      <td>
                        <ul class="ml-0 pl-2">
                          <li>
                            <i>{{ drugsRes.gene.replace("Fusion","") }}</i><span *ngIf="drugsRes.gene.includes('Fusion')">Fusion</span> {{ drugsRes.pposition?.replace(' ','')}} :
                            <br />
                            <span *ngFor="let drug of drugsRes.drugs; let i = index">
                              {{ drug.name
                              }}<span *ngIf="drugsRes.drugs.length - 1 != i">,</span>
                            </span>
                            - {{ drugsRes.association }}
                            <p>{{ drugsRes.cancerType }}</p>
                          </li>
                        </ul>
                      </td>
                      <td>
                        <div [innerHtml]="drugsRes.summary"></div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <!--  -->

            <!--  -->
            <div class="row footer">
              <div class="col-12">
                <div class="pb-2 pl-4 text">
                  <div class="d-block">
                    <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
                  </div>
                  <div class="footer_body" [ngClass]="'gene-bg_blue'">
                    <div class="footer_list">
                      <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
                      <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
                      <span>Gin - Q</span><span>Gly - G</span><span>His - H</span>
                      <span>Ile - I</span>
                    </div>
                    <div class="footer_list">
                      <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
                      <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
                      <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
                      <span>Val - V</span>
                    </div>
                  </div>
                </div>
                <div class="col-12 pt-1 ">
                  <p style="font-size: 8px!important; line-height: 14px!important;">
                    {{footerDiscription}}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <!-- page 3_8 -->
          <div id="page3_8" *ngIf="reportPdfRes.drugsResList.length > 32" class="container position-relative page"
            style="background-color: white">
            <div class="row header mt-1 py-4">
              <div>
                <div class="col-3">
                  <img src="../../../assets/logo.png" alt="" style="width: 140px" />
                </div>
              </div>
              <div></div>
              <div class="col">
                <div class="patient-detailes ml-auto float-right">
                  <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
                    <li>
                      Patient Id <br />
                      <span>{{ report.patient?.ecrfId }}</span>
                    </li>
                    <li class="ml-4">
                      Cancer Site<br />
                      <span>{{ report.cancerType }}</span>
                    </li>
                    <li class="ml-4">
                      SAMPLE ID<br />
                      <span>{{ report.labDetails?.sampleId }}</span>
                    </li>
                    <li class="ml-4">
                      Registration Date<br />
                      <span>{{
                        report.labDetails?.sampleCollected | date : "dd/MM/yyyy"
                        }}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <!--  -->
              <div *ngIf="report.clientType == '4basecare_client'">
                <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
                  </div>
                </div>
                <div *ngIf="report.clientName == 'path_labs'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
                  </div>
                </div>
              </div>
            </div>
            <!--  -->
            <div class="mar_top"></div>
            <!--  -->

            <h6 class="py-2 h6_bg" [ngClass]="'skyBlue'">
              Alterations With Clinically Relevant Therapies
            </h6>
            <!--  -->
            <div class="row">
              <div class="col-12">
                <table class="page_1 cust_table w-100 mt-3 first_2 2nd_table">
                  <thead>
                    <tr>
                      <th>Gene (Alterations) - Drug association</th>
                      <th>Summary</th>
                    </tr>
                  </thead>
                  <!-- SNV -->

                  <tbody>
                    <tr *ngFor=" let drugsRes of reportPdfRes.drugsResList | slice : 32 : 36; let i = index ">
                      <td>
                        <ul class="ml-0 pl-2">
                          <li>
                            <i>{{ drugsRes.gene.replace("Fusion","") }}</i><span *ngIf="drugsRes.gene.includes('Fusion')">Fusion</span> {{ drugsRes.pposition?.replace(' ','')}} :
                            <br />
                            <span *ngFor="let drug of drugsRes.drugs; let i = index">
                              {{ drug.name
                              }}<span *ngIf="drugsRes.drugs.length - 1 != i">,</span>
                            </span>
                            - {{ drugsRes.association }}
                            <p>{{ drugsRes.cancerType }}</p>
                          </li>
                        </ul>
                      </td>
                      <td>
                        <div [innerHtml]="drugsRes.summary"></div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <!--  -->

            <!--  -->
            <div class="row footer">
              <div class="col-12">
                <div class="pb-2 pl-4 text">
                  <div class="d-block">
                    <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
                  </div>
                  <div class="footer_body" [ngClass]="'gene-bg_blue'">
                    <div class="footer_list">
                      <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
                      <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
                      <span>Gin - Q</span><span>Gly - G</span><span>His - H</span>
                      <span>Ile - I</span>
                    </div>
                    <div class="footer_list">
                      <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
                      <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
                      <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
                      <span>Val - V</span>
                    </div>
                  </div>
                </div>
                <div class="col-12 pt-1 ">
                  <p style="font-size: 8px!important; line-height: 14px!important;">
                    {{footerDiscription}}
                  </p>
                </div>
              </div>
            </div>
          </div>

                    <!-- page 3_9 -->
                    <div id="page3_9" *ngIf="reportPdfRes.drugsResList.length > 36" class="container position-relative page"
                      style="background-color: white">
                      <div class="row header mt-1 py-4">
                        <div>
                          <div class="col-3">
                            <img src="../../../assets/logo.png" alt="" style="width: 140px" />
                          </div>
                        </div>
                        <div></div>
                        <div class="col">
                          <div class="patient-detailes ml-auto float-right">
                            <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
                              <li>
                                Patient Id <br />
                                <span>{{ report.patient?.ecrfId }}</span>
                              </li>
                              <li class="ml-4">
                                Cancer Site<br />
                                <span>{{ report.cancerType }}</span>
                              </li>
                              <li class="ml-4">
                                SAMPLE ID<br />
                                <span>{{ report.labDetails?.sampleId }}</span>
                              </li>
                              <li class="ml-4">
                                Registration Date<br />
                                <span>{{
                                  report.labDetails?.sampleCollected | date : "dd/MM/yyyy"
                                  }}</span>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <!--  -->
                        <div *ngIf="report.clientType == '4basecare_client'">
                          <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
                            <div class="logo">
                              <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
                            </div>
                          </div>
                          <div *ngIf="report.clientName == 'path_labs'" class="col-3">
                            <div class="logo">
                              <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <!--  -->
                      <div class="mar_top"></div>
                      <!--  -->

                      <h6 class="py-2 h6_bg" [ngClass]="'skyBlue'">
                        Alterations With Clinically Relevant Therapies
                      </h6>
                      <!--  -->
                      <div class="row">
                        <div class="col-12">
                          <table class="page_1 cust_table w-100 mt-3 first_2 2nd_table">
                            <thead>
                              <tr>
                                <th>Gene (Alterations) - Drug association</th>
                                <th>Summary</th>
                              </tr>
                            </thead>
                            <!-- SNV -->

                            <tbody>
                              <tr *ngFor=" let drugsRes of reportPdfRes.drugsResList | slice : 36 : 40; let i = index ">
                                <td>
                                  <ul class="ml-0 pl-2">
                                    <li>
                                      <i>{{ drugsRes.gene.replace("Fusion","") }}</i><span *ngIf="drugsRes.gene.includes('Fusion')">Fusion</span> {{ drugsRes.pposition?.replace(' ','')}} :
                                      <br />
                                      <span *ngFor="let drug of drugsRes.drugs; let i = index">
                                        {{ drug.name
                                        }}<span *ngIf="drugsRes.drugs.length - 1 != i">,</span>
                                      </span>
                                      - {{ drugsRes.association }}
                                      <p>{{ drugsRes.cancerType }}</p>
                                    </li>
                                  </ul>
                                </td>
                                <td>
                                  <div [innerHtml]="drugsRes.summary"></div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <!--  -->

                      <!--  -->
                      <div class="row footer">
                        <div class="col-12">
                          <div class="pb-2 pl-4 text">
                            <div class="d-block">
                              <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
                            </div>
                            <div class="footer_body" [ngClass]="'gene-bg_blue'">
                              <div class="footer_list">
                                <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
                                <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
                                <span>Gin - Q</span><span>Gly - G</span><span>His - H</span>
                                <span>Ile - I</span>
                              </div>
                              <div class="footer_list">
                                <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
                                <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
                                <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
                                <span>Val - V</span>
                              </div>
                            </div>
                          </div>
                          <div class="col-12 pt-1 ">
                            <p style="font-size: 8px!important; line-height: 14px!important;">
                              {{footerDiscription}}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

          <!-- page 4 -->
          <div id="page4" *ngIf="prognasticCount>0" class="container position-relative page" style="background-color: white">
            <div class="row header mt-1 py-4">
              <div>
                <div class="col-3">
                  <img src="../../../assets/logo.png" alt="" style="width: 140px" />
                </div>
              </div>
              <div></div>
              <div class="col">
                <div class="patient-detailes ml-auto float-right">
                  <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
                    <li>
                      Patient Id <br />
                      <span>{{ report.patient?.ecrfId }}</span>
                    </li>
                    <li class="ml-4">
                      Cancer Site<br />
                      <span>{{ report.cancerType }}</span>
                    </li>
                    <li class="ml-4">
                      SAMPLE ID<br />
                      <span>{{ report.labDetails?.sampleId }}</span>
                    </li>
                    <li class="ml-4">
                      Registration Date<br />
                      <span>{{
                        report.labDetails?.sampleCollected | date : "dd/MM/yyyy"
                        }}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <!--  -->
              <div *ngIf="report.clientType == '4basecare_client'">
                <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
                  </div>
                </div>
                <div *ngIf="report.clientName == 'path_labs'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
                  </div>
                </div>
              </div>
            </div>
            <!--  -->
            <div class="mar_top"></div>
            <!--  -->
            <div *ngIf="prognasticCount>0">
              <div>
                <h6 class="py-2 h6_bg"> Alterations associated with prognostic significance</h6>

              </div>

              <div class="row">
                <div class="col-12">
                  <table class="page_1 cust_table w-100 mt-3 varient_details prognastictable" [ngClass]="'table_variant-blue'">
                    <thead>
                      <tr>
                        <th>Gene (Alterations)</th>
                        <th>Prognostic Significance</th>
                        <th>Summary</th>

                      </tr>
                    </thead>
                    <ng-container *ngFor=" let prog of reportPdfRes.prognosticAlterations ">
                      <tr>
                        <td style="padding-left: 3px !important">
                          <i>{{ prog.gene }}&nbsp;</i> <span [innerHtml]="prog.pOrcDot"></span>
                        </td>
                        <td style="padding-left: 3px !important">
                          {{prog.prognosticSignificance}}
                        </td>
                        <td style="padding-left: 3px !important">
                          <span [innerHtml]="prog.description"></span>
                        </td>

                      </tr>
                    </ng-container>



                  </table>
                </div>
              </div>
            </div>


            <!--  -->
            <div class="row footer">
              <div class="col-12">
                <div class="pb-2 pl-4 text">
                  <div class="d-block">
                    <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
                  </div>
                  <div class="footer_body" [ngClass]="'gene-bg_blue'">
                    <div class="footer_list">
                      <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
                      <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
                      <span>Gin - Q</span><span>Gly - G</span><span>His - H</span>
                      <span>Ile - I</span>
                    </div>
                    <div class="footer_list">
                      <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
                      <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
                      <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
                      <span>Val - V</span>
                    </div>
                  </div>
                </div>
                <div class="col-12 pt-1 ">
                  <p style="font-size: 8px!important; line-height: 14px!important;">
                    {{footerDiscription}}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <!-- Page 5 -->
          <div id="page5" class="container position-relative page" style="background-color: white">
            <div class="row header mt-1 py-4">
              <div>
                <div class="col-3">
                  <img src="../../../assets/logo.png" alt="" style="width: 140px" />
                </div>
              </div>
              <div></div>
              <div class="col">
                <div class="patient-detailes ml-auto float-right">
                  <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
                    <li>
                      Patient Id <br />
                      <span>{{ report.patient?.ecrfId }}</span>
                    </li>
                    <li class="ml-4">
                      Cancer Site<br />
                      <span>{{ report.cancerType }}</span>
                    </li>
                    <li class="ml-4">
                      SAMPLE ID<br />
                      <span>{{ report.labDetails?.sampleId }}</span>
                    </li>
                    <li class="ml-4">
                      Registration Date<br />
                      <span>{{
                        report.labDetails?.sampleCollected | date : "dd/MM/yyyy"
                        }}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <!--  -->
              <div *ngIf="report.clientType == '4basecare_client'">
                <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
                  </div>
                </div>
                <div *ngIf="report.clientName == 'path_labs'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
                  </div>
                </div>
              </div>
            </div>
            <!--  -->
            <div class="mar_top"></div>
            <!--  -->

            <h6 class="py-2 h6_bg" [ngClass]="'skyBlue'">References</h6>
            <!--  -->
            <div class="row">
              <div class="col">
                <div class="disc">
                  <div class="ref_data mt-4">
                    <ul class="break-word" style="text-align: justify">
                      <li *ngFor="let referenceArticle of reportPdfRes.referenceArticles" style="
                                          text-align: justify;
                                          /* white-space:pre-wrap; */
                                          word-break: break-word;
                                        ">
                        {{ referenceArticle }}
                      </li>
                    </ul>
                  </div>
                </div>

                <!-- <div class="img_signs">
                                <img src="../../../assets/signatures.png" alt="" class="img-fluid">
                              </div> -->
                <!--  -->

                <!--  -->
                <!-- <div class="mt-4">
                                <h6 class="mt-3 mb-4 py-2 h6_bg" >
                                  GENES EVALUATED </h6>
                                 <p><span class="font-weight-normal">taR</span><b>GT</b> <span class="clr-cyan pl-2">First</span> detects mutations (SNVs and Short Indels), Copy Number Variations (CNVs), gene fusions and splice variants in the 53 genes :</p>
                              </div> -->

                <!-- <div class="row mx-0">
                                <div class="col-7 px-0">
                                  <div class="genes_info">
                                    <div class="title py-2">
                                      <h6 class="mb-0 d-inline-block border-bottom  py-2"><b>SNVs/InDels</b> Coverage in <span class="font-weight-normal">TAR</span><b>GT</b> <span class="clr-cyan pl-2">First</span></h6></div>
                                 <div class="gene_body">
                                  <div class="gene_list"><span>ABL1</span><span>ALK</span><span>AR</span><span>ATM</span><span>BARD1</span><span>BRAF</span><span>BRCA1</span><span>BRCA2</span><span>BRIP1</span><span>CDK12</span></div>
                                  <div class="gene_list"><span>CDK4</span><span>CDK6</span><span>CDKN2A</span><span>CHEK1</span><span>CHEK2</span><span>EGFR</span><span>EPCAM</span><span>ERBB2</span><span>ERBB3</span><span>EZH2</span></div>
                                  <div class="gene_list"><span>FANCL</span><span>FGFR3</span><span>GAPDH</span><span>IDH1</span><span>IDH2</span><span>JAK2</span><span>KIT</span><span>KRAS</span><span>MAP2K1</span><span>MAP2K2</span></div>
                                  <div class="gene_list"><span>MDM2</span><span>MET</span><span>MLH1</span><span>MLH3</span><span>MSH2</span><span>MSH6</span><span>NRAS</span><span>PALB2</span><span>PDGFRA</span><span>PDGFRB</span></div>
                                  <div class="gene_list"><span>PIK3CA</span><span>PMS1</span><span>PMS2</span><span>PPP2R2A</span><span>RAD51B</span><span>RAD51C</span><span>RAD51D</span><span>RAD54L</span><span>RET</span><span>ROS1</span></div>
                                  <div class="gene_list"><span>MDM2</span><span>MET</span><span>MLH1</span></div>
                                 </div>
                                    </div>
                                </div>
                                <div class="col">
                                  <div class="title py-2">
                                    <h6 class=" mb-0 d-inline-block border-bottom  py-2"><b>Gene Fusions </b> Coverage in <span class="font-weight-normal">TAR</span><b>GT</b> <span class="clr-cyan pl-2">First</span></h6></div>
                                </div>


                              </div> -->
              </div>
            </div>
            <div class="img_signs above-footer">
              <!-- <div class="col" *ngIf="isReportDownload">
                <img [src]="imgsings" alt="" class="img-fluid w-90" />
              </div> -->
              <div class="row" id="imgsings" >
                <div class="col">

                  <p style="
                        text-align: center;

                        font-size: 15px !important;
                      ">(Electronically signed by)
                  </p>
                  <p style="
                          text-align: center;
                          font-weight: bolder;
                          font-size: 15px !important;
                        ">
                    Vidya H Veldore, PhD<br />Vice President - Clinical Operations
                  </p>
                </div>
                <div class="col">
                  <p style="text-align: center; font-size: 15px !important;">
                    (Electronically signed by)</p>
                    <p style="
                    text-align: center;
                    font-weight: bolder;
                    font-size: 15px !important;
                  ">
                  Dr. Jinumary Aji John<br />Head - Clinical Reporting </p>
                </div>
                <div class="col">
                  <p style="
                    text-align: center;

                    font-size: 15px !important;
                  ">(Electronically signed by)
                  </p>
                  <p style="
                          text-align: center;
                          font-weight: bolder;
                          font-size: 15px !important;
                        ">
                    Vyomesh Javle<br />Head - Clinical Bioinformatics
                  </p>
                </div>
              </div>
            </div>
                   <!--  -->
                   <div class="row footer">
                    <div class="col-12">
                      <div class="pb-2 pl-4 text">
                        <div class="d-block">
                          <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
                        </div>
                        <div class="footer_body" [ngClass]="'gene-bg_blue'">
                          <div class="footer_list">
                            <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
                            <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
                            <span>Gin - Q</span><span>Gly - G</span><span>His - H</span>
                            <span>Ile - I</span>
                          </div>
                          <div class="footer_list">
                            <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
                            <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
                            <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
                            <span>Val - V</span>
                          </div>
                        </div>
                      </div>
                      <div class="col-12 pt-1 ">
                        <p style="font-size: 8px!important; line-height: 14px!important;">
                          {{footerDiscription}}
                        </p>
                      </div>
                    </div>
                  </div>
          </div>

          <!-- Page 6 -->
          <div id="page6" class="container position-relative page" style="background-color: white">
            <div class="row header mt-1 py-4">
              <div>
                <div class="col-3">
                  <img src="../../../assets/logo.png" alt="" style="width: 140px" />
                </div>
              </div>
              <div></div>
              <div class="col">
                <div class="patient-detailes ml-auto float-right">
                  <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
                    <li>
                      Patient Id <br />
                      <span>{{ report.patient?.ecrfId }}</span>
                    </li>
                    <li class="ml-4">
                      Cancer Site<br />
                      <span>{{ report.cancerType }}</span>
                    </li>
                    <li class="ml-4">
                      SAMPLE ID<br />
                      <span>{{ report.labDetails?.sampleId }}</span>
                    </li>
                    <li class="ml-4">
                      Registration Date<br />
                      <span>{{
                        report.labDetails?.sampleCollected | date : "dd/MM/yyyy"
                        }}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <!--  -->
              <div *ngIf="report.clientType == '4basecare_client'">
                <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
                  </div>
                </div>
                <div *ngIf="report.clientName == 'path_labs'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
                  </div>
                </div>
              </div>
            </div>
            <!--  -->
            <div class="mar_top"></div>
            <!--  -->

            <div class="">
              <h6 class="py-2 h6_bg" [ngClass]="'skyBlue'">TEST DESCRIPTION</h6>
              <p class="mb-2" style="text-align:justify">
                <span class="font-weight-normal">TAR</span><b>GT</b>
                <span class="clr-cyan pl-2" [ngClass]="'txt-skyblue'">First Solid</span>
                is a Next Generation Sequencing based test which identifies
                genetic alterations in a comprehensive panel of well curated 72  genes which are having an impact response to approved
                therapy for a particular cancer type. Some of the alterations
                detected may have bearing on prognosis and/or therapeutic options
                and may provide relevant information that allows
                oncologists/clinicians to consider various lines of
                targeted treatment for the patient.
              </p>
            </div>

            <!--  -->
            <div class="row">
              <div class="col">
                <div class="">
                  <h6 class="py-2 h6_bg" [ngClass]="'skyBlue'">GENES EVALUATED</h6>
                  <p>
                    <span class="font-weight-normal">TAR</span><b>GT</b>
                    <span class="clr-cyan pl-2" [ngClass]="'txt-skyblue'">First Solid</span>
                    detects mutations (SNVs and Short InDels), Copy Number Variations (CNVs),
                    Gene Fusions and splice variants in the 72 genes:
                    <!-- detects mutations (SNVs and Short Indels), Copy Number Variations
                                  (CNVs), gene fusions and splice variants in the 53 genes : -->
                  </p>
                </div>
                <!--  -->
                <div class="row mx-0">
                  <div class="col-12 px-0">
                    <div class="genes_info">
                      <div class="title py-2">
                        <h6 class="mb-0 d-inline-block border-bottom py-2">
                          <b>SNVs, SHORT INDELS and CNVs
                          </b> Covered in
                          <span class="font-weight-normal">TAR</span><b>GT</b>
                          <span class="clr-cyan pl-2" [ngClass]="'txt-skyblue'">First Solid</span>
                        </h6>
                      </div>
                      <div class="gene_body" [ngClass]="'gene-bg_blue'">
                        <i>
                          <div class="gene_list">
                            <span>ABL1</span><span>ALK</span><span>APC</span>
                            <span>AR</span><span>ATM</span><span>BARD1</span>
                            <span>BMPR1A</span><span>BRAF</span><span>BRCA1</span>
                            <span>BRCA2</span>
                            <!-- <span>ABL1</span><span>ALK</span><span>AR</span
                                          ><span>ATM</span><span>BARD1</span><span>BRAF</span
                                          ><span>BRCA1</span><span>BRCA2</span><span>BRIP1</span
                                          ><span>CDK12</span> -->
                          </div>
                          <div class="gene_list bg-white">
                            <span>BRIP1</span><span>CDK12</span><span>CDK4</span>
                            <span>CDK6</span><span>CDKN2A</span><span>CHEK1</span>
                            <span>CHEK2</span><span>CTNNB1</span><span>EGFR</span>
                            <span>EPCAM</span>
                            <!-- <span>CDK4</span><span>CDK6</span><span>CDKN2A</span
                                          ><span>CHEK1</span><span>CHEK2</span><span>EGFR</span
                                          ><span>EPCAM</span><span>ERBB2</span><span>ERBB3</span
                                          ><span>EZH2</span> -->
                          </div>
                          <div class="gene_list">
                            <span>ERBB2</span><span>ERBB3</span><span>EZH2</span>
                            <span>FANCL</span><span>FGFR1</span><span>FGFR2</span>
                            <span>FGFR3</span><span>GAPDH</span><span>IDH1</span>
                            <span>IDH2</span>
                            <!-- <span>FANCL</span><span>FGFR3</span><span>GAPDH</span
                                          ><span>IDH1</span><span>IDH2</span><span>JAK2</span
                                          ><span>KIT</span><span>KRAS</span><span>MAP2K1</span
                                          ><span>MAP2K2</span> -->
                          </div>
                          <div class="gene_list">
                            <span>JAK2</span><span>KIT</span><span>KRAS</span>
                            <span>MAP2K1</span><span>MAP2K2</span><span>MDM2</span>
                            <span>MET</span><span>MLH1</span><span>MLH3</span>
                            <span>MSH2</span>
                            <!-- <span>MDM2</span><span>MET</span><span>MLH1</span
                                          ><span>MLH3</span><span>MSH2</span><span>MSH6</span
                                          ><span>NRAS</span><span>PALB2</span><span>PDGFRA</span
                                          ><span>PDGFRB</span> -->
                          </div>
                          <div class="gene_list">
                            <span>MSH6</span><span>MUTYH</span><span>NRAS</span>
                            <span>PALB2</span><span>PDGFRA</span><span>PDGFRB</span>
                            <span>PIK3CA</span><span>PMS1</span><span>PMS2</span>
                            <span>POLD1</span>
                            <!-- <span>PIK3CA</span><span>PMS1</span><span>PMS2</span
                                          ><span>PPP2R2A</span><span>RAD51B</span><span>RAD51C</span
                                          ><span>RAD51D</span><span>RAD54L</span><span>RET</span
                                          ><span>ROS1</span> -->
                          </div>
                          <div class="gene_list">
                            <span>POLE</span><span>POLH</span><span>PTEN</span>
                            <span>RAD50</span><span>RAD51</span><span>RAD51B</span>
                            <span>RAD51C</span><span>RAD51D</span><span>RAD54L</span>
                            <span>RB1</span>
                            <!-- <span>STK11</span><span>TSC1</span><span>TSC2</span> -->
                          </div>
                          <div class="gene_list">
                            <span>RET</span><span>ROS1</span><span>SMAD4</span>
                            <span>STK11</span><span>TP53</span><span>TSC1</span>
                            <span>TSC2</span>
                            <!-- <span>STK11</span><span>TSC1</span><span>TSC2</span> -->
                          </div>
                        </i>
                      </div>

                      <!-- <div class="title py-2 mt-4">
                                      <h6 class="mb-0 d-inline-block border-bottom py-2">
                                        <b>CNAs</b> Covered in
                                        <span class="font-weight-normal">TAR</span><b>GT</b>
                                        <span class="clr-cyan pl-2" [ngClass]="'txt-skyblue'"
                                          >First</span
                                        >
                                      </h6>
                                    </div> -->
                      <!-- <div class="gene_body" [ngClass]="'gene-bg_blue'">
                                      <i>
                                        <div class="gene_list">
                                          <span>ABL1</span><span>AR</span><span>ATM</span
                                          ><span>BARD1</span><span>BRCA1</span><span>BRCA2</span
                                          ><span>BRIP1</span><span>CDK12</span><span>CDK4</span
                                          ><span>CDK6</span>
                                        </div>
                                        <div class="gene_list">
                                          <span>CDKN2A</span><span>CHEK1</span><span>CHEK2</span
                                          ><span>EGFR</span><span>EPCAM</span><span>ERBB2</span
                                          ><span>ERBB3</span><span>EZH2</span><span>FANCL</span
                                          ><span>FGFR3</span>
                                        </div>
                                        <div class="gene_list">
                                          <span>GAPDH</span><span>IDH2</span><span>JAK2</span
                                          ><span>KIT</span><span>MDM2</span><span>NRAS</span
                                          ><span>PALB2</span><span>PDGFRA</span><span>PDGFRB</span
                                          ><span>PPP2R2A</span>
                                        </div>
                                        <div class="gene_list">
                                          <span>RAD51B</span><span>RAD51C</span><span>RAD51D</span
                                          ><span>RAD54L</span><span>STK11</span><span>TSC1</span
                                          ><span>TSC2</span>
                                        </div>
                                      </i>
                                    </div> -->
                    </div>
                  </div>
                  <!-- cnv_genes -->

            <div class="col-12 px-0"  *ngIf="isKokilaben">
              <div class="genes_info">
                <div class="title py-2">
                  <h6 class="mb-0 d-inline-block border-bottom py-2">
                    <b> CNVs
                    </b> Covered in
                    <span class="font-weight-normal">TAR</span><b>GT</b>
                    <span class="clr-cyan pl-2" [ngClass]="'txt-skyblue'"
                      >First Solid</span
                    >
                  </h6>
                </div>
                <div class="gene_body" [ngClass]="'gene-bg_blue'">
                  <i>
                    <div class="gene_list">
                      <span>ABL1</span><span>ALK</span><span>APC</span>
                      <span>AR</span><span>ATM</span><span>BARD1</span>
                      <span>BMPR1A</span><span>BRAF</span><span>BRCA1</span>
                      <span>BRCA2</span>
                    </div>
                    <div class="gene_list bg-white">
                      <span>BRIP1</span><span>CDK12</span><span>CDK4</span>
                      <span>CDK6</span><span>CDKN2A</span><span>CHEK1</span>
                      <span>CHEK2</span><span>CTNNB1</span><span>EGFR</span>
                      <span>EPCAM</span>
                    </div>
                    <div class="gene_list">
                      <span>ERBB2</span><span>ERBB3</span><span>EZH2</span>
                      <span>FANCL</span><span>FGFR1</span><span>FGFR2</span>
                      <span>FGFR3</span><span>GAPDH</span><span>IDH2</span>
                      <span>JAK2</span>
                    </div>
                    <div class="gene_list">
                     <span>KIT</span><span>MDM2</span>
                      <span>MET</span><span>MLH1</span><span>MUTYH</span>
                      <span>NRAS</span><span>PALB2</span><span>PDGFRA</span>
                      <span>PDGFRAB</span><span>POLD1</span>
                    </div>

                    <div class="gene_list">
                      <span>POLE</span><span>POLH</span><span>PTEN</span>
                      <span>RAD50</span><span>RAD51</span><span>RAD51B</span>
                      <span>RAD51C</span><span>RAD51D</span><span>RAD54L</span>
                      <span>RB1</span>
                    </div>
                    <div class="gene_list">
                      <span>RET</span><span>ROS1</span><span>SMAD4</span>
                      <span>STK11</span><span>TP53</span><span>TSC1</span>
                      <span>TSC2</span>
                    </div>
                  </i>
                </div>


              </div>
            </div>




                  <div class="col-12 px-0">
                    <div class="genes_info">
                      <div class="title py-2">
                        <h6 class="mb-0 d-inline-block border-bottom py-2">
                          <b>Gene Fusions/Splicing Variations</b> Covered in
                          <span class="font-weight-normal">TAR</span><b>GT</b>
                          <span class="clr-cyan pl-2" [ngClass]="'txt-skyblue'">First Solid</span>
                        </h6>
                      </div>
                      <div class="gene_body" [ngClass]="'gene-bg_blue'">
                        <i>
                          <div class="gene_list">
                          <span>ALK</span><span>FGFR2</span><span>FGFR3</span>
                          <span>MET</span><span>NRG1</span><span>NRG2</span>
                          <span>NTRK1</span><span>NTRK2</span> <span>NTRK3</span><span>RET</span>

                          </div>
                          <div class="gene_list">
                            <span>ROS1</span>
                          </div>
                        </i>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
            <h6 class="py-2 h6_bg mt-3" [ngClass]="'skyBlue'">TEST METHODOLOGY</h6>
            <!--  -->
            <div class="row mt-3">
              <div class="col">
                <h6 class="font-weight-bold">
                  Sample preparation and Library preparation :
                </h6>
                <!-- <p>
                                DNA isolated from FFPE, or any other fresh tumor tissue source was
                                used to perform targeted gene capture using a custom capture kit.
                                The libraries were sequenced to mean >350X coverage on Illumina
                                sequencing platform.
                              </p> -->
                <p style="text-align: justify;">
                  DNA isolated from FFPE, or any other fresh tumor tissue source was used to perform targeted gene capture using
                  a custom capture kit. The libraries were sequenced to mean
                  >350X coverage on Illumina sequencing platform.
                </p>
              </div>
            </div>
            <!--  -->
            <div class="row mt-4">
              <div class="col">
                <h6 class="font-weight-bold">
                  Bioinformatics Analysis and Reporting :
                </h6>
                <!-- <p>
                                The sequences obtained are aligned to human reference genome
                                (GRCh37/hg19) and variant analysis was performed using set of
                                Bioinformatics Pipeline. Only non-synonymous and splice site
                                variants found in the panel consisting of specific set of genes were
                                used for clinical interpretation. Silent variations that do not
                                result in any change in amino acid in the coding region are not
                                reported. Clinically relevant mutations were annotated using
                                published variants in literature and a set of databases – ClinVar,
                                COSMIC and dbSNP. Common variants are filtered based on allele
                                frequency in 1000 Genome Phase 3, ExAC, dbSNP, gnomAD, etc. In the
                                absence of a clinically significant reported known variation(s),
                                pathogenicity will be predicted based on <i>in-silico</i> gene
                                prioritization tools: CADD, SIFT, PolyPhen-2, Condel and Mutation
                                taster and prioritized for clinical correlation. The identified
                                pathogenic variant will be correlated with observed phenotypic
                                features of the patient and interpreted according to American
                                College of Medical Genetics (ACMG) guidelines.
                              </p>
                              <p class="mb-2 d-block">
                                Somatic variants are classified into three tiers based on their level
                                of clinical significance in cancer diagnosis, prognosis, and/or
                                therapeutics as per international guidelines: ACMG, ASCO, AMP, CAP,
                                NCCN and ESMO
                              </p> -->
                <p style="text-align: justify;">
                  The sequences obtained are aligned to human reference genome (GRCh37/hg19) and variant analysis was performed
                  using set of Bioinformatics Pipeline. Only non-synonymous and splice site variants found in the panel
                  consisting of specific set of genes were used for clinical interpretation. Silent variations that do not
                  result in any change
                  in amino acid in the coding region are not reported. Clinically relevant mutations were annotated using
                  published variants in literature and a set of databases – ClinVar, COSMIC
                  and dbSNP. Common variants are filtered based on allele frequency in 1000 Genome Phase 3, ExAC, dbSNP, gnomAD,
                  etc. In the absence of a clinically significant reported
                  known variation(s), pathogenicity will be predicted based on <i>in-silico</i> gene prioritization tools: CADD, SIFT,
                  PolyPhen-2, Condel and Mutation taster and prioritized for clinical
                  correlation. The identified pathogenic variant will be correlated with observed phenotypic features of the patient and interpreted according to ACMG/AMP guidelines.
                </p>
                <p class="mb-2 d-block" style="text-align: justify;">
                  Somatic variants are classified into three tiers based on their level of clinical significance in cancer
                  diagnosis, prognosis, and/or therapeutics as per international guidelines:
                  ACMG, ASCO, AMP, CAP, NCCN and ESMO.
                </p>
              </div>
            </div>
            <!--  -->
            <div class="row footer">
              <div class="col-12">
                <div class="pb-2 pl-4 text">
                  <div class="d-block">
                    <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
                  </div>
                  <div class="footer_body" [ngClass]="'gene-bg_blue'">
                    <div class="footer_list">
                      <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
                      <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
                      <span>Gin - Q</span><span>Gly - G</span><span>His - H</span>
                      <span>Ile - I</span>
                    </div>
                    <div class="footer_list">
                      <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
                      <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
                      <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
                      <span>Val - V</span>
                    </div>
                  </div>
                </div>
                <div class="col-12 pt-1 ">
                  <p style="font-size: 8px!important; line-height: 14px!important;">
                    {{footerDiscription}}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <!-- Page 7 -->
          <div id="page7" class="container position-relative page" style="background-color: white">
            <div class="row header mt-1 py-4">
              <div>
                <div class="col-3">
                  <img src="../../../assets/logo.png" alt="" style="width: 140px" />
                </div>
              </div>
              <div></div>
              <div class="col">
                <div class="patient-detailes ml-auto float-right">
                  <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
                    <li>
                      Patient Id <br />
                      <span>{{ report.patient?.ecrfId }}</span>
                    </li>
                    <li class="ml-4">
                      Cancer Site<br />
                      <span>{{ report.cancerType }}</span>
                    </li>
                    <li class="ml-4">
                      SAMPLE ID<br />
                      <span>{{ report.labDetails?.sampleId }}</span>
                    </li>
                    <li class="ml-4">
                      Registration Date<br />
                      <span>{{
                        report.labDetails?.sampleCollected | date : "dd/MM/yyyy"
                        }}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <!--  -->
              <div *ngIf="report.clientType == '4basecare_client'">
                <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
                  </div>
                </div>
                <div *ngIf="report.clientName == 'path_labs'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
                  </div>
                </div>
              </div>
            </div>
            <!--  -->
            <div class="mar_top"></div>
            <!--  -->


            <h6 class="py-2 h6_bg mt-2" [ngClass]="'skyBlue'">
              LIMITATIONS AND DISCLAIMER
            </h6>

            <div class="row">
              <div class="col">
                <div class="disc">
                  <ul class="ml-0 pl-4" style="text-align: justify;">
                    <li>
                      TARGT FIRST SOLID test has been developed, validated and performed by 4baseCare Oncosolutions Pvt. Ltd and
                      has not been cleared or approved by the FDA.
                    </li>
                    <li>
                      DNA studies do not constitute a definitive test for the selected condition(s) in all individuals. It
                      should be realized that there are possible sources of error. Errors can result
                      from trace contamination, rare technical errors, rare genetic variants that interfere with analysis,
                      recent scientific developments, and alternative classification systems. This
                      test should be one of the many aspects used by the healthcare provider to help with a diagnosis and
                      treatment plan.
                    </li>
                    <li>
                      We are using the canonical transcript for clinical reporting which is usually the longest coding
                      transcript with strong/multiple supporting evidence. However, in rare cases,
                      clinically relevant variants annotated in alternate complete coding transcripts could also be reported.
                    </li>
                    <li>
                      The contents of this test should be carefully assessed by the treating physician and further interpreted
                      along with clinical, histopathological findings, contraindications and
                      guidelines before deciding the course of therapy.
                    </li>
                    <li>
                      The CNVs detected must be confirmed by an alternate method, such as IHC, for further clinical management decisions.                    </li>
                    <!-- <li>
                      The sensitivity of this assay to detect large deletions/duplications of more than 10 bp or copy number
                      variations (CNV) is 70-75%. The CNVs detected have to be confirmed
                      by alternate method.
                    </li> -->
                    <li>
                      Most recent block is recommended for testing as the mutation profile may change in response to treatment
                      and hence differ at different sampling points.
                    </li>
                    <li>
                      The identified pathogenic variant will be correlated with observed phenotypic features of the patient and interpreted according to AMP guidelines.
                    </li>
                    <li>
                      Certain genes may not be covered completely, and few mutations could be missed. A negative result cannot
                      rule out the possibility that the tested tumor sample carries
                      mutations not previously associated with cancer and hence not included in the panel.
                    </li>
                    <li>
                      Our limit of detection for TARGT FIRST SOLID is 5% for SNVs, 10% for InDels and CNV gain &#8805; 6. In addition
                      to this, sequencing quality and coverage is dependent on many factors
                      such as homopolymers, GC-rich regions, intrinsic quality of FFPE DNA and that might impact the variant
                      detection.
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <!--  -->
            <div class="row footer">
              <div class="col-12">
                <div class="pb-2 pl-4 text">
                  <div class="d-block">
                    <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
                  </div>
                  <div class="footer_body" [ngClass]="'gene-bg_blue'">
                    <div class="footer_list">
                      <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
                      <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
                      <span>Gin - Q</span><span>Gly - G</span><span>His - H</span>
                      <span>Ile - I</span>
                    </div>
                    <div class="footer_list">
                      <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
                      <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
                      <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
                      <span>Val - V</span>
                    </div>
                  </div>
                </div>
                <div class="col-12 pt-1 ">
                  <p style="font-size: 8px!important; line-height: 14px!important;">
                    {{footerDiscription}}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- For 4basecare Template 2-->
        <div *ngIf="is4basecare && temp=='temp2'">
          <div id="page" class="container position-relative page" style="overflow-y: clip; background-color: white">
            <div class="row header">
              <div class="w-100 overflow-hidden">
                <div class="col">
                  <div class="cover_page">
                    <img src="../../../assets/tarGT_FirstSolid-Cover.png" alt="" class="img-fluid w-100" />
                  </div>
                </div>
              </div>
              <div></div>
            </div>
          </div>
          <!-- Page 1 -->
          <div id="page1" class="container position-relative page" style="background-color: white">
            <div class="row header mt-1 py-4">
              <div>
                <div class="col-3 pl-0">
                  <div class="logo">
                    <img src="../../../assets/logo.png" alt="" style="width: 140px" />
                  </div>
                </div>
              </div>
              <div></div>
              <div class="col">
                <div class="patient-detailes ml-auto float-right">
                  <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
                    <li>
                      Patient Id <br />
                      <span>{{ report.patient?.ecrfId }}</span>
                    </li>
                    <li class="ml-4">
                      Cancer Site<br />
                      <span>{{ report.cancerType }}</span>
                    </li>
                    <li class="ml-4">
                      SAMPLE ID<br />
                      <span>{{ report.labDetails?.sampleId }}</span>
                    </li>
                    <li class="ml-4">
                      Registration Date<br />
                      <span>{{
                        report.labDetails?.sampleCollected | date : "dd/MM/yyyy"
                        }}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <div *ngIf="report.clientType == '4basecare_client'">
                <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
                  </div>
                </div>
                <div *ngIf="report.clientName == 'path_labs'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
                  </div>
                </div>
              </div>
            </div>
            <!--  -->
            <div class="mar_top"></div>
            <!--  -->
            <div class="row">
              <div class="col-7">
                <div class="">
                  <div class="w-50" style="min-height: 80px">
                    <p class="py-1 font-weight-bold" [ngClass]="'txt-skyblue'">
                      SCOPE OF THE TEST
                    </p>
                    <p>SNVs, InDels, CNAs, Gene Fusions status</p>
                  </div>
                  <img src="../../../assets/BorderLine.png" class="mr-2 w-100" />
                </div>
              </div>
              <div class="col">
                <div class="">
                  <div class="w-100">
                    <!-- <p style="color: #208dcb;" class="py-1">
                                                    <span style="color: #000 !important"></span>
                                                  </p> -->
                    <div class="w-100" style="min-height: 80px">
                      <p [ngClass]="'txt-skyblue'" class="py-1 font-weight-bold" *ngIf="report.clinicalSummary != null">
                        <span *ngIf="report.clinicalSummary != ' '">CLINICAL INDICATION</span>
                      </p>

                      <p *ngIf="report.clinicalSummary != null">
                        <span style="color: #000 !important" *ngIf="report.clinicalSummary != ' '">{{ report.clinicalSummary
                          }}</span>
                      </p>
                    </div>
                    <!-- </div> -->
                    <img src="../../../assets/BorderLine.png" class="mr-2 w-100" />

                    <!-- </div> -->
                  </div>
                </div>
              </div>
            </div>
            <!--  -->
            <div class="row pt-2">
              <div class="col-7">
                <h6 class="text-uppercase py-2 font-weight-bold" [ngClass]="'txt-skyblue'">
                  Report Details
                </h6>
                <ul class="list-unstyled">
                  <li class="pb-1">
                    <span class="clr-cyan" [ngClass]="'txt-skyblue'">Name : </span>
                    <span class="text-black">{{
                      report.patient?.name | uppercase
                      }}</span>
                  </li>
                  <li class="pb-1">
                    <span class="clr-cyan" [ngClass]="'txt-skyblue'">Gender : </span>
                    <span class="text-black">{{
                      report.patient?.gender | titlecase
                      }}</span>
                  </li>
                  <li class="pb-1">
                    <span class="clr-cyan" [ngClass]="'txt-skyblue'">Age/DOB : </span>
                    <span class="text-black">{{
                      report.patient?.dateOfBirth
                      ? (report.patient?.dateOfBirth | date : "dd/MM/yyyy")
                      : report.patient?.age + " Years"
                      }}</span>
                  </li>
                  <li class="pb-1">
                    <span class="clr-cyan" [ngClass]="'txt-skyblue'">Reporting Date :
                    </span>
                    <span class="text-black">{{ date | date : "dd/MM/yyyy" }}</span>
                  </li>
                </ul>
              </div>
              <div class="col">
                <ul class="list-unstyled pt-4 mt-3">
                  <li *ngIf="report.cancerSubType != null" class="pb-1">
                    <span class="clr-cyan" [ngClass]="'txt-skyblue'">Cancer Celltype :
                    </span>
                    <span class="text-black">{{ report.cancerSubType }}</span>
                  </li>
                  <li class="pb-1">
                    <span class="clr-cyan" [ngClass]="'txt-skyblue'">Sample Source :
                    </span>
                    <span class="text-black">{{ sampleSource }}</span>
                  </li>
                  <li class="pb-1">
                    <span class="clr-cyan" [ngClass]="'txt-skyblue'">Consulting Clinician :
                    </span>
                    <span class="text-black">{{referringClinician}}</span>
                  </li>
                  <li class="pb-1">
                    <span class="clr-cyan" [ngClass]="'txt-skyblue'">Hospital :
                    </span>
                    <span class="text-black">{{ labHospital }}</span>
                  </li>
                </ul>
              </div>
            </div>
            <!--  -->
            <div class="row mt-2 py-2">
              <div class="col">
                <h6 class="mb-0 text-uppercase f-weight_700">Results</h6>
              </div>
            </div>
            <h6 class="py-2 h6_bg" [ngClass]="'skyBlue'">
              Genomic findings from tumor profiling
            </h6>

            <!--  -->
            <div class="row">
              <div style="width: 100%;" *ngIf="isReportDownload">
                <img [src]="firstPageTable" alt="" width="100%" class="img-fluid" />
              </div>
              <div class="col-12" id="firstPageTable" *ngIf="!isReportDownload">
                <table class="page_1 cust_table w-100 mt-3 first_1" [ngClass]="'table_skyblue'">
                  <tr>
                    <th>Genomic Alteration</th>
                    <th>Relevant Therapies (in Same Cancer Type)</th>
                    <th>Relevant Therapies (in Different Cancer)</th>
                  </tr>

                  <tr *ngIf="tableCount== 0 && prognasticCount>0 ">
                    <td colspan="3" class="text-center mt-2">
                      Clinically relevant genomic alterations associated with therapeutic significance were not detected.
                    </td>
                  </tr>
                  <tr *ngIf="tableCount== 0 && prognasticCount == 0">
                    <td colspan="3" class="text-center mt-2">
                      Clinically relevant genomic alterations associated with both therapeutic and prognostic significance were not detected.
                    </td>
                  </tr>

                  <ng-container>
                    <tr *ngFor="
                                                                let snvHotspotTrail of reportPdfRes?.allHotspotTrailList  | slice : 0 : 5 ; let i=index
                                                              ">
                      <td>
                        <i>{{ snvHotspotTrail.gene }}</i>
                        &nbsp;                  <span *ngIf="!snvHotspotTrail.gene.includes('Wild type')">

                        <span *ngIf="snvHotspotTrail?.multiVariant==null">
                          <span *ngIf="snvHotspotTrail.exonNumber != null || snvHotspotTrail.pposition != null && snvHotspotTrail.pposition != ''">

                            <span *ngIf="snvHotspotTrail.exonNumber != null">{{snvHotspotTrail.exonNumber}}</span>&nbsp;
                            <span *ngIf="snvHotspotTrail.pposition != null && snvHotspotTrail.pposition != ''">({{snvHotspotTrail.pposition?.replace(' ','')}})</span></span>
                            <p *ngIf="snvHotspotTrail.mutantBurdenPercent != null && !snvHotspotTrail.gene.includes('Wild type')">
                              Allelic burden: {{ snvHotspotTrail.mutantBurdenPercent }}%
                            </p>
                            <span *ngIf="snvHotspotTrail.copyNumber!=null && snvHotspotTrail.copyNumber.length>0"> {{ snvHotspotTrail.copyNumber }}
                          </span>
                        </span>
                        <span *ngIf="snvHotspotTrail?.multiVariant!=null"><br/>
                        <span  style="font-size: 14px !important" [innerHtml]="snvHotspotTrail?.multiVariant"></span>
                      </span></span>
                      </td>
                      <td>
                        <table class="table first_table w-100">
                          <thead class="border-bottom cust_clr">
                            <tr>
                              <th class="w-50">
                                <span class="font-weight-normal"><i>Therapy</i></span>
                              </th>
                              <th class="w-33">
                                <span class="font-weight-normal"><i>Clinical Relevance</i></span>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngIf="snvHotspotTrail.sameCancerType == null">
                              <td class="w-50">NA</td>
                              <td class="w-50">NA</td>
                            </tr>

                            <ng-container *ngIf="snvHotspotTrail.sameCancerType != null">
                              <tr *ngFor="
                                                                          let item of snvHotspotTrail.sameTypeRelevantData
                                                                        ">
                                <td>{{ item.therapy }}</td>
                                <td>{{ item.association }}</td>
                                <!-- <td>{{item.cancerType}}</td> -->
                              </tr>
                            </ng-container>
                          </tbody>
                        </table>
                      </td>
                      <td>
                        <table class="table first_table w-100">
                          <thead class="border-bottom cust_clr">
                            <tr>
                              <th class="w-33">
                                <span class="font-weight-normal"><i>Therapy</i></span>
                              </th>
                              <th class="w-33">
                                <span class="font-weight-normal"><i>Clinical Relevance</i></span>
                              </th>
                              <th class="w-33">
                                <span class="font-weight-normal"><i>Cancer Type</i></span>
                              </th>
                            </tr>
                          </thead>
                          <tbody>

                            <ng-container *ngIf="snvHotspotTrail.otherCancerType != null">
                              <tr *ngFor="
                                                                          let item of snvHotspotTrail.otherTypeRelevantData
                                                                        ">
                                <td>{{ item.therapy }}</td>
                                <td>{{ item.association }}</td>
                                <td>{{ item.cancerType }}</td>
                              </tr>
                            </ng-container>
                            <tr *ngIf="snvHotspotTrail.otherCancerType == null">
                              <td class="w-33">NA</td>
                              <td class="w-33">NA</td>
                              <td class="w-33">NA</td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>


                  </ng-container>
                </table>
                <p *ngIf="
                snvHotSpot.length != 0 ||
                cnaHotSpot.length != 0 ||
                                    fusionHotSpot.length !=0
                                  " style="font-size: 13px !important" class="mt-0">
                  <i> *NA: Not Applicable</i>
                </p>
              </div>
            </div>
            <!--  -->
            <div *ngIf="(tableCount>0) &&
              prognasticCount==0">
              <h6 class="py-2 h6_bg"> Prognostic Biomarkers</h6>
            </div>
            <div class="row" *ngIf="(tableCount>0 )&&
              prognasticCount==0">
              <div class="col-12">
                <table class="page_1 cust_table w-100 mt-3 varient_details prognasticbiomarkertable"
                  [ngClass]="'table_variant-blue'">
                  <thead>
                    <tr>
                      <th>Genomic Alteration</th>
                      <th>Prognostic Significance</th>

                    </tr>
                  </thead>
                  <ng-container>
                    <tr>
                      <td colspan="2" style="text-align:center">
                        Clinically relevant genomic alterations associated with prognostic significance were not detected.
                      </td>
                    </tr>
                  </ng-container>
                </table>
              </div>
            </div>
            <div *ngIf="prognasticCount>0">
              <h6 class="py-2 h6_bg"> Prognostic Biomarkers</h6>
            </div>

            <div class="row" *ngIf="prognasticCount>0">
              <div class="col-12">
                <table class="page_1 cust_table w-100 mt-3 varient_details prognasticbiomarkertable"
                  [ngClass]="'table_variant-blue'">
                  <thead>
                    <tr>
                      <th>Genomic Alteration</th>
                      <th>Prognostic Significance</th>

                    </tr>
                  </thead>
                  <ng-container *ngFor="let prog of reportPdfRes.prognosticBiomarkers">
                    <tr>
                      <td style="padding-left: 3px !important">
                        <i>{{ prog.gene }}&nbsp; </i>
                        <span [innerHtml]="prog.geneDetails"></span>
                      </td>
                      <td style="padding-left: 3px !important;">{{prog.statement}}
                      </td>
                    </tr>
                  </ng-container>


                </table>
              </div>
            </div>
            <!--  -->



            <!--  -->
            <div class="row footer">
              <div class="col-12">
                <div class="pb-2 pl-4 text">
                  <div class="d-block">
                    <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
                  </div>
                  <div class="footer_body" [ngClass]="'gene-bg_blue'">
                    <div class="footer_list">
                      <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
                      <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
                      <span>Gin - Q</span><span>Gly - G</span><span>His - H</span>
                      <span>Ile - I</span>
                    </div>
                    <div class="footer_list">
                      <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
                      <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
                      <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
                      <span>Val - V</span>
                    </div>
                  </div>
                </div>
                <div class="col-12 pt-1 ">
                  <p style="font-size: 8px!important; line-height: 14px!important;">
                    {{footerDiscription}}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <!-- page 2 -->
          <div id="page2" *ngIf="
          geneMutationlist != null ||
                                    otherCountSNV == true ||
                                    reportPdfRes.allVariantsList.length > 0
                                    " class="container position-relative page" style="background-color: white">
            <div class="row header mt-1 py-4">
              <div>
                <div class="col-3">
                  <img src="../../../assets/logo.png" alt="" style="width: 140px" />
                </div>
              </div>
              <div></div>
              <div class="col">
                <div class="patient-detailes ml-auto float-right">
                  <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
                    <li>
                      Patient Id <br />
                      <span>{{ report.patient?.ecrfId }} </span>
                    </li>
                    <li class="ml-4">
                      Cancer Site<br />
                      <span>{{ report.cancerType }}</span>
                    </li>
                    <li class="ml-4">
                      SAMPLE ID<br />
                      <span>{{ report.labDetails?.sampleId }}</span>
                    </li>
                    <li class="ml-4">
                      Registration Date<br />
                      <span>{{
                        report.labDetails?.sampleCollected | date : "dd/MM/yyyy"
                        }}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <!--  -->
              <div *ngIf="report.clientType == '4basecare_client'">
                <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
                  </div>
                </div>
                <div *ngIf="report.clientName == 'path_labs'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
                  </div>
                </div>
              </div>
            </div>

            <!-- gene Data -->
            <div *ngIf="geneMutationlist != null && isStatusDisplay">
              <div class="mt-3">
                <h6 class="py-2 h6_bg" [ngClass]="'skyBlue'">
                  Status of variants in cancer related Biomarkers
                </h6>
              </div>

              <table class="staus_gene bg_skyblue" *ngIf="geneMutationlist != null" [ngClass]="{
                                                      biomarker_table:
                                                        geneMutationlist.length >= 4 &&
                                                        geneMutationlist.length < 8
                                                    }">
                <tr style="background: #daedf7">
                  <td style="font-size: 18px !important; padding-left: 10px !important">
                    Gene
                  </td>
                  <td *ngFor="
                                                          let geneMutation of geneMutationlist
                                                            | slice : 0 : 8
                                                        ">
                    <i>{{ geneMutation.gene }}</i>
                  </td>
                </tr>
                <tr>
                  <td style="font-size: 18px !important; padding-left: 10px !important">
                    <b>Status</b>
                  </td>
                  <td *ngFor="
                                                          let geneMutation of geneMutationlist
                                                            | slice : 0 : 8
                                                        ">
                    <span *ngIf="
                                                            geneMutation.status == 'Pathogenic' ||
                                                            geneMutation.status == 'Likely Pathogenic' ||
                                                            geneMutation.status == 'Detected'
                                                          " style="color: red; font-weight: 600px"><b>{{ geneMutation.status
                        }}</b></span>
                    <span *ngIf="geneMutation.status == 'VUS'" style="color: black; font-weight: 600px"><b>{{
                        geneMutation.status }}</b></span>
                    <span *ngIf="
                                                            geneMutation.status == 'Not Detected' ||
                                                            geneMutation.status == 'Benige'
                                                          ">{{ geneMutation.status }}</span>
                  </td>
                </tr>
<br/>
              </table>
              <table class="staus_gene" [ngClass]="'bg_skyblue'" *ngIf="
                                                      geneMutationlist != null &&
                                                      geneMutationlist.length > 8
                                                    ">
                <tr style="background: #daedf7">
                  <td style="font-size: 18px !important; padding-left: 10px !important">
                    Gene
                  </td>
                  <td *ngFor="
                                                          let geneMutation of geneMutationlist
                                                            | slice : 8 : 17
                                                        ">
                    <i>{{ geneMutation.gene }}</i>
                  </td>
                </tr>
                <tr>
                  <td style="font-size: 18px !important; padding-left: 10px !important">
                    <b>Status</b>
                  </td>
                  <td *ngFor="
                                                          let geneMutation of geneMutationlist
                                                            | slice : 8 : 17
                                                        ">
                    <span *ngIf="
                                                            geneMutation.status == 'Pathogenic' ||
                                                            geneMutation.status == 'Likely Pathogenic' ||
                                                            geneMutation.status == 'Detected'
                                                          " style="color: red; font-weight: 600px"><b>{{ geneMutation.status
                        }}</b></span>
                    <span *ngIf="geneMutation.status == 'VUS'" style="color: black; font-weight: 600px"><b>{{
                        geneMutation.status }}</b></span>
                    <span *ngIf="
                                                            geneMutation.status == 'Not Detected' ||
                                                            geneMutation.status == 'Benige'
                                                          ">{{ geneMutation.status }}</span>
                  </td>
                  </tr>
              </table>
              <br />
              <table
                class="staus_gene"
                [ngClass]="'bg_skyblue'"
                *ngIf="
                  geneMutationlist != null &&
                  geneMutationlist.length > 17
                "
              >
                <tr style="background: #daedf7">
                  <td
                    style="font-size: 18px !important; padding-left: 10px !important"
                  >
                    Gene
                  </td>
                  <td
                    *ngFor="
                      let geneMutation of geneMutationlist
                        | slice : 17 : 25
                    "
                  >
                    <i>{{ geneMutation.gene }}</i>
                  </td>
                </tr>
                <tr>
                  <td
                    style="font-size: 18px !important; padding-left: 10px !important"
                  >
                    <b>Status</b>
                  </td>
                  <td
                    *ngFor="
                      let geneMutation of geneMutationlist
                        | slice : 17 : 25
                    "
                  >
                    <span
                      *ngIf="
                        geneMutation.status == 'Pathogenic' ||
                        geneMutation.status == 'Likely Pathogenic' ||
                        geneMutation.status == 'Detected'
                      "
                      style="color: red; font-weight: 600px"
                      ><b>{{ geneMutation.status }}</b></span
                    >
                    <span
                      *ngIf="geneMutation.status == 'VUS'"
                      style="color: black; font-weight: 600px"
                      ><b>{{ geneMutation.status }}</b></span
                    >
                    <span
                      *ngIf="
                        geneMutation.status == 'Not Detected' ||
                        geneMutation.status == 'Benige'
                      "
                      >{{ geneMutation.status }}</span
                    >
                  </td>
                </tr>
<br/>
              </table>
              <table class="staus_gene" [ngClass]="'bg_skyblue'" *ngIf="
                    geneMutationlist != null &&
                    geneMutationlist.length > 25
                  ">
                <tr style="background: #daedf7">
                  <td style="font-size: 18px !important; padding-left: 10px !important">
                    Gene
                  </td>
                  <td *ngFor="
                        let geneMutation of geneMutationlist
                          | slice : 25 : 33
                      ">
                    <i>{{ geneMutation.gene }}</i>
                  </td>
                </tr>
                <tr>
                  <td style="font-size: 18px !important; padding-left: 10px !important">
                    <b>Status</b>
                  </td>
                  <td *ngFor="
                        let geneMutation of geneMutationlist
                          | slice : 25 : 33
                      ">
                    <span *ngIf="
                          geneMutation.status == 'Pathogenic' ||
                          geneMutation.status == 'Likely Pathogenic' ||
                          geneMutation.status == 'Detected'
                        " style="color: red; font-weight: 600px"><b>{{ geneMutation.status }}</b></span>
                    <span *ngIf="geneMutation.status == 'VUS'" style="color: black; font-weight: 600px"><b>{{
                        geneMutation.status }}</b></span>
                    <span *ngIf="
                          geneMutation.status == 'Not Detected' ||
                          geneMutation.status == 'Benige'
                        ">{{ geneMutation.status }}</span>
                  </td>
                </tr>
<br/>
              </table>
          <table class="staus_gene" [ngClass]="'bg_skyblue'" *ngIf="
                geneMutationlist != null &&
                geneMutationlist.length > 33
              ">
            <tr style="background: #daedf7">
              <td style="font-size: 18px !important; padding-left: 10px !important">
                Gene
              </td>
              <td *ngFor="
                    let geneMutation of geneMutationlist
                      | slice : 33 : 41
                  ">
                <i>{{ geneMutation.gene }}</i>
              </td>
            </tr>
            <tr>
              <td style="font-size: 18px !important; padding-left: 10px !important">
                <b>Status</b>
              </td>
              <td *ngFor="
                    let geneMutation of geneMutationlist
                      | slice : 33 : 41
                  ">
                <span *ngIf="
                      geneMutation.status == 'Pathogenic' ||
                      geneMutation.status == 'Likely Pathogenic' ||
                      geneMutation.status == 'Detected'
                    " style="color: red; font-weight: 600px"><b>{{ geneMutation.status }}</b></span>
                <span *ngIf="geneMutation.status == 'VUS'" style="color: black; font-weight: 600px"><b>{{
                    geneMutation.status }}</b></span>
                <span *ngIf="
                      geneMutation.status == 'Not Detected' ||
                      geneMutation.status == 'Benige'
                    ">{{ geneMutation.status }}</span>
              </td>
            </tr>
            <br/>
          </table>
              <h6 *ngIf="
                      (fusionGeneMutationList != null &&
                      fusionGeneMutationList.length > 0) " class="font-weight-bold clr-cyan text-uppercase"
                [ngClass]="'txt-skyblue'">
                Gene Fusion
              </h6>
              <table class="staus_gene" *ngIf="
                                                      (fusionGeneMutationList != null &&
                                                      fusionGeneMutationList.length > 0)
                                                    " [ngClass]="'bg_skyblue'">
                <tr style="background: #daedf7">
                  <td style="font-size: 18px !important; padding-left: 10px !important">
                    Gene
                  </td>
                  <td *ngFor="
                                                          let geneMutation of fusionGeneMutationList
                                                            | slice : 0 : 8
                                                        ">
                    <i>{{ geneMutation.gene }}</i>
                  </td>
                </tr>
                <tr>
                  <td style="font-size: 18px !important; padding-left: 10px !important">
                    <b>Status</b>
                  </td>
                  <td *ngFor="
                                                          let geneMutation of fusionGeneMutationList
                                                            | slice : 0 : 8
                                                        ">
                    <span *ngIf="
                                                            geneMutation.status == 'Pathogenic' ||
                                                            geneMutation.status == 'Likely Pathogenic' ||
                                                            geneMutation.status == 'Detected'
                                                          " style="color: red; font-weight: 600px"><b>{{ geneMutation.status
                        }}</b></span>
                    <span *ngIf="geneMutation.status == 'VUS'" style="color: black; font-weight: 600px"><b>{{
                        geneMutation.status }}</b></span>
                    <span *ngIf="
                                                            geneMutation.status == 'Not Detected' ||
                                                            geneMutation.status == 'Benige'
                                                          ">{{ geneMutation.status }}</span>
                  </td>
                </tr>
<br/>
              </table>
              <table class="staus_gene" *ngIf="
                                                      (fusionGeneMutationList != null &&
                                                      fusionGeneMutationList.length > 8)
                                                    " [ngClass]="'bg_skyblue'">
                <tr style="background: #daedf7">
                  <td style="font-size: 18px !important; padding-left: 10px !important">
                    Gene
                  </td>
                  <td *ngFor="
                                                          let geneMutation of fusionGeneMutationList
                                                            | slice : 8 : 17
                                                        ">
                    <i>{{ geneMutation.gene }}</i>
                  </td>
                </tr>
                <tr>
                  <td style="font-size: 18px !important; padding-left: 10px !important">
                    <b>Status</b>
                  </td>
                  <td *ngFor="
                                                          let geneMutation of fusionGeneMutationList
                                                            | slice : 8 : 17
                                                        ">
                    <span *ngIf="
                                                            geneMutation.status == 'Pathogenic' ||
                                                            geneMutation.status == 'Likely Pathogenic' ||
                                                            geneMutation.status == 'Detected'
                                                          " style="color: red; font-weight: 600px"><b>{{ geneMutation.status
                        }}</b></span>
                    <span *ngIf="geneMutation.status == 'VUS'" style="color: black; font-weight: 600px"><b>{{
                        geneMutation.status }}</b></span>
                    <span *ngIf="
                                                            geneMutation.status == 'Not Detected' ||
                                                            geneMutation.status == 'Benige'
                                                          ">{{ geneMutation.status }}</span>
                  </td>
                </tr>
              </table>
            </div>
            <!--  -->
            <div *ngIf="
                          report.disclaimerNote != ' ' &&
                          report.disclaimerNote != null
                        ">

              <h6 style="color: #208dcb"><b>Note: </b></h6>
              <div style="font-size: 14px !important;text-align: justify" [innerHtml]="report.disclaimerNote"></div>
            </div>
            <div class="row mt-3" *ngIf="
                                        otherCountSNV == true ||
                                        reportPdfRes.allVariantsList.length != 0
                                      ">
              <div class="col">
                <h6 class="font-weight-bold clr-cyan text-uppercase" [ngClass]="'txt-skyblue'">
                  Variant Details:
                </h6>
              </div>
            </div>

            <div class="row" *ngIf="
                                        otherCountSNV == true ||
                                        reportPdfRes.allVariantsList.length != 0
                                      ">
              <div class="col-12">
                <table class="page_1 cust_table w-100 mt-3 varient_details" [ngClass]="'table_variant-blue'">
                  <thead>
                    <tr>
                      <th>Gene</th>
                      <th>Variant Location</th>
                      <th>Variant Consequence</th>
                      <th>Clinical Significance</th>
                      <th>Variant Type</th>
                      <th>Reference</th>
                    </tr>
                  </thead>

                  <tr *ngFor="let variant of reportPdfRes.allVariantsList">
                    <td *ngIf="variant.significance != 'NA' && variant.significance != 'Not Classified'" style="padding-left: 3px !important">
                      <i>{{ variant.gene }}</i>
                    </td>
                    <td *ngIf="variant.significance != 'NA' && variant.significance != 'Not Classified'" style="padding-left: 3px !important">
                      <span *ngIf="variant?.variantType=='snv'">
                        {{ variant?.genomicAlteration }},<br />
                        {{ variant?.ensembleValue }},<br />
                        {{ variant?.exonNumber }}
                      </span>
                      <span *ngIf="variant?.variantType=='cna'">
                        chr{{ variant?.sample?.split(":")[0] }}:g.{{
                          variant.sample
                          .split(":")[1]
                          .replace("-", "_")
                          .split("|")[0]
                          }}
                      </span>
                      <span *ngIf="variant.variantType=='fusion'">
                        {{ variant?.leftBreakPoint }}<br />{{variant?.rightBreakPoint}}
                      </span>
                    </td>
                    <td *ngIf="variant?.significance != 'NA' && variant?.significance != 'Not Classified'" style="padding-left: 3px !important">
                      <span *ngIf="variant.variantType=='snv'">
                        <span *ngIf="variant?.selectedAAChangeknownGene">{{
                          variant?.selectedAAChangeknownGene|splitComma }}</span>, {{variant?.mutantBurdenPercent * 0.01 | percent}}
                        </span>
                      <span *ngIf="variant.variantType=='cna'">NA</span>
                      <span *ngIf="variant.variantType=='fusion'">NA</span>
                    </td>
                    <td *ngIf="variant?.significance != 'NA' && variant?.significance != 'Not Classified'" style="padding-left: 3px !important">
                      {{ variant?.significance }}
                    </td>
                    <td *ngIf="variant?.significance != 'NA' && variant?.significance != 'Not Classified'" style="padding-left: 3px !important">
                      <span *ngIf="variant.variantType=='snv'">
                        {{ variant?.exonicFuncknownGene }}
                      </span>
                      <span *ngIf="variant.variantType=='cna'">
                        Copy Number
                        {{ variant?.sample.split(":")[2] | titlecase }}
                        ({{ variant?.sample.split("|")[1].split(":")[0] }})
                      </span>
                      <span *ngIf="variant.variantType=='fusion'">Gene Fusions</span>
                    </td>
                    <td *ngIf="variant?.significance != 'NA' && variant?.significance != 'Not Classified'" style="padding-left: 3px !important">
                      {{ variant?.reference }}
                    </td>
                  </tr>
                </table>
                <p *ngIf="reportPdfRes.allVariantsList.length > 0 " style="font-size: 13px !important" class="mt-0">
                  <i> *NA: Not Applicable</i>
                </p>
              </div>
            </div>
            <!--  -->
            <div class="row mt-5" *ngIf="
                  !variantFlag &&
                  report.addNotes != null && report.addNotes != '<div></div>'
                ">
          <div class="col">
            <h6 class="py-2 h6_bg">Additional Notes</h6>
            <div class="py-2" [innerHtml]="report.addNotes" style="text-align: justify; font-size: 14px !important"></div>
          </div>
        </div>

            <!--  -->
            <div class="row footer">
              <div class="col-12">
                <div class="pb-2 pl-4 text">
                  <div class="d-block">
                    <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
                  </div>
                  <div class="footer_body" [ngClass]="'gene-bg_blue'">
                    <div class="footer_list">
                      <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
                      <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
                      <span>Gin - Q</span><span>Gly - G</span><span>His - H</span>
                      <span>Ile - I</span>
                    </div>
                    <div class="footer_list">
                      <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
                      <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
                      <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
                      <span>Val - V</span>
                    </div>
                  </div>
                </div>
                <div class="col-12 pt-1 ">
                  <p style="font-size: 8px!important; line-height: 14px!important;">
                    {{footerDiscription}}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <!-- page 3 -->
          <!-- page 3 -->
          <div id="page3" *ngIf="reportPdfRes.drugsResList.length > 0" class="container position-relative page"
            style="background-color: white">
            <div class="row header mt-1 py-4">
              <div>
                <div class="col-3">
                  <img src="../../../assets/logo.png" alt="" style="width: 140px" />
                </div>
              </div>
              <div></div>
              <div class="col">
                <div class="patient-detailes ml-auto float-right">
                  <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
                    <li>
                      Patient Id <br />
                      <span>{{ report.patient?.ecrfId }}</span>
                    </li>
                    <li class="ml-4">
                      Cancer Site<br />
                      <span>{{ report.cancerType }}</span>
                    </li>
                    <li class="ml-4">
                      SAMPLE ID<br />
                      <span>{{ report.labDetails?.sampleId }}</span>
                    </li>
                    <li class="ml-4">
                      Registration Date<br />
                      <span>{{
                        report.labDetails?.sampleCollected | date : "dd/MM/yyyy"
                        }}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <!--  -->
              <div *ngIf="report.clientType == '4basecare_client'">
                <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
                  </div>
                </div>
                <div *ngIf="report.clientName == 'path_labs'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
                  </div>
                </div>
              </div>
            </div>
            <!--  -->
            <div class="mar_top"></div>
            <!--  -->

            <h6 class="py-2 h6_bg" [ngClass]="'skyBlue'">
              Alterations With Clinically Relevant Therapies
            </h6>
            <!--  -->
            <div class="row">
              <div class="col-12">
                <table class="page_1 cust_table w-100 mt-3 first_2 custom-table" [ngClass]="'bg_cyan'">
                  <thead>
                    <tr>
                      <th>Gene (Alterations) - Drug association</th>
                      <th>Summary</th>
                    </tr>
                  </thead>
                  <!-- SNV -->

                  <tbody>
                    <tr *ngFor="
                                                                let drugsRes of reportPdfRes.drugsResList | slice : 0 : 4;
                                                                let i = index
                                                              ">
                      <td>
                        <ul class="ml-0 pl-2">
                          <li>
                            <i>{{ drugsRes.gene.replace("Fusion","") }}</i><span *ngIf="drugsRes.gene.includes('Fusion')">Fusion</span> {{ drugsRes.pposition?.replace(' ','') }} :
                            <br />
                            <span *ngFor="let drug of drugsRes.drugs; let i = index">
                              {{ drug.name
                              }}<span *ngIf="drugsRes.drugs.length - 1 != i">,</span>
                            </span>
                            - {{ drugsRes.association }}
                            <p>{{ drugsRes.cancerType }}</p>
                          </li>
                        </ul>
                      </td>
                      <td>
                        <div [innerHtml]="drugsRes.summary"></div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div class="row footer">
              <div class="col-12">
                <div class="pb-2 pl-4 text">
                  <div class="d-block">
                    <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
                  </div>
                  <div class="footer_body" [ngClass]="'gene-bg_blue'">
                    <div class="footer_list">
                      <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
                      <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
                      <span>Gin - Q</span><span>Gly - G</span><span>His - H</span>
                      <span>Ile - I</span>
                    </div>
                    <div class="footer_list">
                      <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
                      <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
                      <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
                      <span>Val - V</span>
                    </div>
                  </div>
                </div>
                <div class="col-12 pt-1 ">
                  <p style="font-size: 8px!important; line-height: 14px!important;">
                    {{footerDiscription}}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <!-- page 3_1 -->
          <div id="page3_1" *ngIf="reportPdfRes.drugsResList.length > 4" class="container position-relative page"
            style="background-color: white">
            <div class="row header mt-1 py-4">
              <div>
                <div class="col-3">
                  <img src="../../../assets/logo.png" alt="" style="width: 140px" />
                </div>
              </div>
              <div></div>
              <div class="col">
                <div class="patient-detailes ml-auto float-right">
                  <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
                    <li>
                      Patient Id <br />
                      <span>{{ report.patient?.ecrfId }}</span>
                    </li>
                    <li class="ml-4">
                      Cancer Site<br />
                      <span>{{ report.cancerType }}</span>
                    </li>
                    <li class="ml-4">
                      SAMPLE ID<br />
                      <span>{{ report.labDetails?.sampleId }}</span>
                    </li>
                    <li class="ml-4">
                      Registration Date<br />
                      <span>{{
                        report.labDetails?.sampleCollected | date : "dd/MM/yyyy"
                        }}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <!--  -->
              <div *ngIf="report.clientType == '4basecare_client'">
                <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
                  </div>
                </div>
                <div *ngIf="report.clientName == 'path_labs'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
                  </div>
                </div>
              </div>
            </div>
            <!--  -->
            <div class="mar_top"></div>
            <!--  -->

            <h6 class="py-2 h6_bg" [ngClass]="'skyBlue'">
              Alterations With Clinically Relevant Therapies
            </h6>
            <!--  -->
            <div class="row">
              <div class="col-12">
                <table class="page_1 cust_table w-100 mt-3 first_2 2nd_table">
                  <thead>
                    <tr>
                      <th>Gene (Alterations) - Drug association</th>
                      <th>Summary</th>
                    </tr>
                  </thead>
                  <!-- SNV -->

                  <tbody>
                    <tr *ngFor="
                                                                          let drugsRes of reportPdfRes.drugsResList | slice : 4 : 8;
                                                                          let i = index
                                                                        ">
                      <td>
                        <ul class="ml-0 pl-2">
                          <li>
                            <i>{{ drugsRes.gene.replace("Fusion","") }}</i><span *ngIf="drugsRes.gene.includes('Fusion')">Fusion</span> {{ drugsRes.pposition?.replace(' ','') }} :
                            <br />
                            <span *ngFor="let drug of drugsRes.drugs; let i = index">
                              {{ drug.name
                              }}<span *ngIf="drugsRes.drugs.length - 1 != i">,</span>
                            </span>
                            - {{ drugsRes.association }}
                            <p>{{ drugsRes.cancerType }}</p>
                          </li>
                        </ul>
                      </td>
                      <td>
                        <div [innerHtml]="drugsRes.summary"></div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <!--  -->

            <!--  -->
            <div class="row footer">
              <div class="col-12">
                <div class="pb-2 pl-4 text">
                  <div class="d-block">
                    <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
                  </div>
                  <div class="footer_body" [ngClass]="'gene-bg_blue'">
                    <div class="footer_list">
                      <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
                      <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
                      <span>Gin - Q</span><span>Gly - G</span><span>His - H</span>
                      <span>Ile - I</span>
                    </div>
                    <div class="footer_list">
                      <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
                      <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
                      <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
                      <span>Val - V</span>
                    </div>
                  </div>
                </div>
                <div class="col-12 pt-1 ">
                  <p style="font-size: 8px!important; line-height: 14px!important;">
                    {{footerDiscription}}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <!-- page 3_2 -->
          <div id="page3_2" *ngIf="reportPdfRes.drugsResList.length > 8" class="container position-relative page"
            style="background-color: white">
            <div class="row header mt-1 py-4">
              <div>
                <div class="col-3">
                  <img src="../../../assets/logo.png" alt="" style="width: 140px" />
                </div>
              </div>
              <div></div>
              <div class="col">
                <div class="patient-detailes ml-auto float-right">
                  <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
                    <li>
                      Patient Id <br />
                      <span>{{ report.patient?.ecrfId }}</span>
                    </li>
                    <li class="ml-4">
                      Cancer Site<br />
                      <span>{{ report.cancerType }}</span>
                    </li>
                    <li class="ml-4">
                      SAMPLE ID<br />
                      <span>{{ report.labDetails?.sampleId }}</span>
                    </li>
                    <li class="ml-4">
                      Registration Date<br />
                      <span>{{
                        report.labDetails?.sampleCollected | date : "dd/MM/yyyy"
                        }}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <!--  -->
              <div *ngIf="report.clientType == '4basecare_client'">
                <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
                  </div>
                </div>
                <div *ngIf="report.clientName == 'path_labs'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
                  </div>
                </div>
              </div>
            </div>
            <!--  -->
            <div class="mar_top"></div>
            <!--  -->

            <h6 class="py-2 h6_bg" [ngClass]="'skyBlue'">
              Alterations With Clinically Relevant Therapies
            </h6>
            <!--  -->
            <div class="row">
              <div class="col-12">
                <table class="page_1 cust_table w-100 mt-3 first_2 2nd_table">
                  <thead>
                    <tr>
                      <th>Gene (Alterations) - Drug association</th>
                      <th>Summary</th>
                    </tr>
                  </thead>
                  <!-- SNV -->

                  <tbody>
                    <tr *ngFor="
                                                                                              let drugsRes of reportPdfRes.drugsResList | slice : 8 : 12;
                                                                                              let i = index
                                                                                            ">
                      <td>
                        <ul class="ml-0 pl-2">
                          <li>
                            <i>{{ drugsRes.gene.replace("Fusion","") }}</i><span *ngIf="drugsRes.gene.includes('Fusion')">Fusion</span> {{ drugsRes.pposition?.replace(' ','')}} :
                            <br />
                            <span *ngFor="let drug of drugsRes.drugs; let i = index">
                              {{ drug.name
                              }}<span *ngIf="drugsRes.drugs.length - 1 != i">,</span>
                            </span>
                            - {{ drugsRes.association }}
                            <p>{{ drugsRes.cancerType }}</p>
                          </li>
                        </ul>
                      </td>
                      <td>
                        <div [innerHtml]="drugsRes.summary"></div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <!--  -->

            <!--  -->
            <div class="row footer">
              <div class="col-12">
                <div class="pb-2 pl-4 text">
                  <div class="d-block">
                    <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
                  </div>
                  <div class="footer_body" [ngClass]="'gene-bg_blue'">
                    <div class="footer_list">
                      <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
                      <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
                      <span>Gin - Q</span><span>Gly - G</span><span>His - H</span>
                      <span>Ile - I</span>
                    </div>
                    <div class="footer_list">
                      <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
                      <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
                      <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
                      <span>Val - V</span>
                    </div>
                  </div>
                </div>
                <div class="col-12 pt-1 ">
                  <p style="font-size: 8px!important; line-height: 14px!important;">
                    {{footerDiscription}}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <!-- page 3_3 -->
          <div id="page3_3" *ngIf="reportPdfRes.drugsResList.length > 12" class="container position-relative page"
            style="background-color: white">
            <div class="row header mt-1 py-4">
              <div>
                <div class="col-3">
                  <img src="../../../assets/logo.png" alt="" style="width: 140px" />
                </div>
              </div>
              <div></div>
              <div class="col">
                <div class="patient-detailes ml-auto float-right">
                  <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
                    <li>
                      Patient Id <br />
                      <span>{{ report.patient?.ecrfId }}</span>
                    </li>
                    <li class="ml-4">
                      Cancer Site<br />
                      <span>{{ report.cancerType }}</span>
                    </li>
                    <li class="ml-4">
                      SAMPLE ID<br />
                      <span>{{ report.labDetails?.sampleId }}</span>
                    </li>
                    <li class="ml-4">
                      Registration Date<br />
                      <span>{{
                        report.labDetails?.sampleCollected | date : "dd/MM/yyyy"
                        }}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <!--  -->
              <div *ngIf="report.clientType == '4basecare_client'">
                <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
                  </div>
                </div>
                <div *ngIf="report.clientName == 'path_labs'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
                  </div>
                </div>
              </div>
            </div>
            <!--  -->
            <div class="mar_top"></div>
            <!--  -->

            <h6 class="py-2 h6_bg" [ngClass]="'skyBlue'">
              Alterations With Clinically Relevant Therapies
            </h6>
            <!--  -->
            <div class="row">
              <div class="col-12">
                <table class="page_1 cust_table w-100 mt-3 first_2 2nd_table">
                  <thead>
                    <tr>
                      <th>Gene (Alterations) - Drug association</th>
                      <th>Summary</th>
                    </tr>
                  </thead>
                  <!-- SNV -->

                  <tbody>
                    <tr *ngFor="
                                                                                                                    let drugsRes of reportPdfRes.drugsResList | slice : 12 : 16;
                                                                                                                    let i = index
                                                                                                                  ">
                      <td>
                        <ul class="ml-0 pl-2">
                          <li>
                            <i>{{ drugsRes.gene.replace("Fusion","") }}</i><span *ngIf="drugsRes.gene.includes('Fusion')">Fusion</span> {{ drugsRes.pposition?.replace(' ','')}} :
                            <br />
                            <span *ngFor="let drug of drugsRes.drugs; let i = index">
                              {{ drug.name
                              }}<span *ngIf="drugsRes.drugs.length - 1 != i">,</span>
                            </span>
                            - {{ drugsRes.association }}
                            <p>{{ drugsRes.cancerType }}</p>
                          </li>
                        </ul>
                      </td>
                      <td>
                        <div [innerHtml]="drugsRes.summary"></div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <!--  -->

            <!--  -->
            <div class="row footer">
              <div class="col-12">
                <div class="pb-2 pl-4 text">
                  <div class="d-block">
                    <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
                  </div>
                  <div class="footer_body" [ngClass]="'gene-bg_blue'">
                    <div class="footer_list">
                      <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
                      <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
                      <span>Gin - Q</span><span>Gly - G</span><span>His - H</span>
                      <span>Ile - I</span>
                    </div>
                    <div class="footer_list">
                      <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
                      <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
                      <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
                      <span>Val - V</span>
                    </div>
                  </div>
                </div>
                <div class="col-12 pt-1 ">
                  <p style="font-size: 8px!important; line-height: 14px!important;">
                    {{footerDiscription}}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <!-- page 3_4 -->
          <div id="page3_4" *ngIf="reportPdfRes.drugsResList.length > 16" class="container position-relative page"
            style="background-color: white">
            <div class="row header mt-1 py-4">
              <div>
                <div class="col-3">
                  <img src="../../../assets/logo.png" alt="" style="width: 140px" />
                </div>
              </div>
              <div></div>
              <div class="col">
                <div class="patient-detailes ml-auto float-right">
                  <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
                    <li>
                      Patient Id <br />
                      <span>{{ report.patient?.ecrfId }}</span>
                    </li>
                    <li class="ml-4">
                      Cancer Site<br />
                      <span>{{ report.cancerType }}</span>
                    </li>
                    <li class="ml-4">
                      SAMPLE ID<br />
                      <span>{{ report.labDetails?.sampleId }}</span>
                    </li>
                    <li class="ml-4">
                      Registration Date<br />
                      <span>{{
                        report.labDetails?.sampleCollected | date : "dd/MM/yyyy"
                        }}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <!--  -->
              <div *ngIf="report.clientType == '4basecare_client'">
                <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
                  </div>
                </div>
                <div *ngIf="report.clientName == 'path_labs'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
                  </div>
                </div>
              </div>
            </div>
            <!--  -->
            <div class="mar_top"></div>
            <!--  -->

            <h6 class="py-2 h6_bg" [ngClass]="'skyBlue'">
              Alterations With Clinically Relevant Therapies
            </h6>
            <!--  -->
            <div class="row">
              <div class="col-12">
                <table class="page_1 cust_table w-100 mt-3 first_2 2nd_table">
                  <thead>
                    <tr>
                      <th>Gene (Alterations) - Drug association</th>
                      <th>Summary</th>
                    </tr>
                  </thead>
                  <!-- SNV -->

                  <tbody>
                    <tr *ngFor="
                                                                                                          let drugsRes of reportPdfRes.drugsResList | slice : 16 : 20;
                                                                                                          let i = index
                                                                                                        ">
                      <td>
                        <ul class="ml-0 pl-2">
                          <li>
                            <i>{{ drugsRes.gene.replace("Fusion","") }}</i><span *ngIf="drugsRes.gene.includes('Fusion')">Fusion</span> {{ drugsRes.pposition?.replace(' ','')}} :
                            <br />
                            <span *ngFor="let drug of drugsRes.drugs; let i = index">
                              {{ drug.name
                              }}<span *ngIf="drugsRes.drugs.length - 1 != i">,</span>
                            </span>
                            - {{ drugsRes.association }}
                            <p>{{ drugsRes.cancerType }}</p>
                          </li>
                        </ul>
                      </td>
                      <td>
                        <div [innerHtml]="drugsRes.summary"></div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <!--  -->

            <!--  -->
            <div class="row footer">
              <div class="col-12">
                <div class="pb-2 pl-4 text">
                  <div class="d-block">
                    <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
                  </div>
                  <div class="footer_body" [ngClass]="'gene-bg_blue'">
                    <div class="footer_list">
                      <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
                      <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
                      <span>Gin - Q</span><span>Gly - G</span><span>His - H</span>
                      <span>Ile - I</span>
                    </div>
                    <div class="footer_list">
                      <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
                      <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
                      <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
                      <span>Val - V</span>
                    </div>
                  </div>
                </div>
                <div class="col-12 pt-1 ">
                  <p style="font-size: 8px!important; line-height: 14px!important;">
                    {{footerDiscription}}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <!-- page 3_5 -->
          <div id="page3_5" *ngIf="reportPdfRes.drugsResList.length > 20" class="container position-relative page"
            style="background-color: white">
            <div class="row header mt-1 py-4">
              <div>
                <div class="col-3">
                  <img src="../../../assets/logo.png" alt="" style="width: 140px" />
                </div>
              </div>
              <div></div>
              <div class="col">
                <div class="patient-detailes ml-auto float-right">
                  <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
                    <li>
                      Patient Id <br />
                      <span>{{ report.patient?.ecrfId }}</span>
                    </li>
                    <li class="ml-4">
                      Cancer Site<br />
                      <span>{{ report.cancerType }}</span>
                    </li>
                    <li class="ml-4">
                      SAMPLE ID<br />
                      <span>{{ report.labDetails?.sampleId }}</span>
                    </li>
                    <li class="ml-4">
                      Registration Date<br />
                      <span>{{
                        report.labDetails?.sampleCollected | date : "dd/MM/yyyy"
                        }}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <!--  -->
              <div *ngIf="report.clientType == '4basecare_client'">
                <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
                  </div>
                </div>
                <div *ngIf="report.clientName == 'path_labs'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
                  </div>
                </div>
              </div>
            </div>
            <!--  -->
            <div class="mar_top"></div>
            <!--  -->

            <h6 class="py-2 h6_bg" [ngClass]="'skyBlue'">
              Alterations With Clinically Relevant Therapies
            </h6>
            <!--  -->
            <div class="row">
              <div class="col-12">
                <table class="page_1 cust_table w-100 mt-3 first_2 2nd_table">
                  <thead>
                    <tr>
                      <th>Gene (Alterations) - Drug association</th>
                      <th>Summary</th>
                    </tr>
                  </thead>
                  <!-- SNV -->

                  <tbody>
                    <tr
                      *ngFor="
                      let drugsRes of reportPdfRes.drugsResList | slice : 20 : 24; let i = index ">
                      <td>
                        <ul class="ml-0 pl-2">
                          <li>
                            <i>{{ drugsRes.gene.replace("Fusion","") }}</i><span *ngIf="drugsRes.gene.includes('Fusion')">Fusion</span> {{ drugsRes.pposition?.replace(' ','')}} :
                            <br />
                            <span *ngFor="let drug of drugsRes.drugs; let i = index">
                              {{ drug.name
                              }}<span *ngIf="drugsRes.drugs.length - 1 != i">,</span>
                            </span>
                            - {{ drugsRes.association }}
                            <p>{{ drugsRes.cancerType }}</p>
                          </li>
                        </ul>
                      </td>
                      <td>
                        <div [innerHtml]="drugsRes.summary"></div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <!--  -->

            <!--  -->
            <div class="row footer">
              <div class="col-12">
                <div class="pb-2 pl-4 text">
                  <div class="d-block">
                    <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
                  </div>
                  <div class="footer_body" [ngClass]="'gene-bg_blue'">
                    <div class="footer_list">
                      <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
                      <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
                      <span>Gin - Q</span><span>Gly - G</span><span>His - H</span>
                      <span>Ile - I</span>
                    </div>
                    <div class="footer_list">
                      <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
                      <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
                      <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
                      <span>Val - V</span>
                    </div>
                  </div>
                </div>
                <div class="col-12 pt-1 ">
                  <p style="font-size: 8px!important; line-height: 14px!important;">
                    {{footerDiscription}}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <!-- page 3_6 -->
          <div id="page3_6" *ngIf="reportPdfRes.drugsResList.length > 24" class="container position-relative page"
            style="background-color: white">
            <div class="row header mt-1 py-4">
              <div>
                <div class="col-3">
                  <img src="../../../assets/logo.png" alt="" style="width: 140px" />
                </div>
              </div>
              <div></div>
              <div class="col">
                <div class="patient-detailes ml-auto float-right">
                  <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
                    <li>
                      Patient Id <br />
                      <span>{{ report.patient?.ecrfId }}</span>
                    </li>
                    <li class="ml-4">
                      Cancer Site<br />
                      <span>{{ report.cancerType }}</span>
                    </li>
                    <li class="ml-4">
                      SAMPLE ID<br />
                      <span>{{ report.labDetails?.sampleId }}</span>
                    </li>
                    <li class="ml-4">
                      Registration Date<br />
                      <span>{{
                        report.labDetails?.sampleCollected | date : "dd/MM/yyyy"
                        }}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <!--  -->
              <div *ngIf="report.clientType == '4basecare_client'">
                <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
                  </div>
                </div>
                <div *ngIf="report.clientName == 'path_labs'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
                  </div>
                </div>
              </div>
            </div>
            <!--  -->
            <div class="mar_top"></div>
            <!--  -->

            <h6 class="py-2 h6_bg" [ngClass]="'skyBlue'">
              Alterations With Clinically Relevant Therapies
            </h6>
            <!--  -->
            <div class="row">
              <div class="col-12">
                <table class="page_1 cust_table w-100 mt-3 first_2 2nd_table">
                  <thead>
                    <tr>
                      <th>Gene (Alterations) - Drug association</th>
                      <th>Summary</th>
                    </tr>
                  </thead>
                  <!-- SNV -->

                  <tbody>
                    <tr
                      *ngFor=" let drugsRes of reportPdfRes.drugsResList | slice : 24 : 28; let i = index ">
                      <td>
                        <ul class="ml-0 pl-2">
                          <li>
                            <i>{{ drugsRes.gene.replace("Fusion","") }}</i><span *ngIf="drugsRes.gene.includes('Fusion')">Fusion</span> {{ drugsRes.pposition?.replace(' ','')}} :
                            <br />
                            <span *ngFor="let drug of drugsRes.drugs; let i = index">
                              {{ drug.name
                              }}<span *ngIf="drugsRes.drugs.length - 1 != i">,</span>
                            </span>
                            - {{ drugsRes.association }}
                            <p>{{ drugsRes.cancerType }}</p>
                          </li>
                        </ul>
                      </td>
                      <td>
                        <div [innerHtml]="drugsRes.summary"></div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <!--  -->

            <!--  -->
            <div class="row footer">
              <div class="col-12">
                <div class="pb-2 pl-4 text">
                  <div class="d-block">
                    <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
                  </div>
                  <div class="footer_body" [ngClass]="'gene-bg_blue'">
                    <div class="footer_list">
                      <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
                      <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
                      <span>Gin - Q</span><span>Gly - G</span><span>His - H</span>
                      <span>Ile - I</span>
                    </div>
                    <div class="footer_list">
                      <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
                      <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
                      <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
                      <span>Val - V</span>
                    </div>
                  </div>
                </div>
                <div class="col-12 pt-1 ">
                  <p style="font-size: 8px!important; line-height: 14px!important;">
                    {{footerDiscription}}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <!-- page 3_7 -->
          <div id="page3_7" *ngIf="reportPdfRes.drugsResList.length > 28" class="container position-relative page"
            style="background-color: white">
            <div class="row header mt-1 py-4">
              <div>
                <div class="col-3">
                  <img src="../../../assets/logo.png" alt="" style="width: 140px" />
                </div>
              </div>
              <div></div>
              <div class="col">
                <div class="patient-detailes ml-auto float-right">
                  <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
                    <li>
                      Patient Id <br />
                      <span>{{ report.patient?.ecrfId }}</span>
                    </li>
                    <li class="ml-4">
                      Cancer Site<br />
                      <span>{{ report.cancerType }}</span>
                    </li>
                    <li class="ml-4">
                      SAMPLE ID<br />
                      <span>{{ report.labDetails?.sampleId }}</span>
                    </li>
                    <li class="ml-4">
                      Registration Date<br />
                      <span>{{
                        report.labDetails?.sampleCollected | date : "dd/MM/yyyy"
                        }}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <!--  -->
              <div *ngIf="report.clientType == '4basecare_client'">
                <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
                  </div>
                </div>
                <div *ngIf="report.clientName == 'path_labs'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
                  </div>
                </div>
              </div>
            </div>
            <!--  -->
            <div class="mar_top"></div>
            <!--  -->

            <h6 class="py-2 h6_bg" [ngClass]="'skyBlue'">
              Alterations With Clinically Relevant Therapies
            </h6>
            <!--  -->
            <div class="row">
              <div class="col-12">
                <table class="page_1 cust_table w-100 mt-3 first_2 2nd_table">
                  <thead>
                    <tr>
                      <th>Gene (Alterations) - Drug association</th>
                      <th>Summary</th>
                    </tr>
                  </thead>
                  <!-- SNV -->

                  <tbody>
                    <tr
                      *ngFor="
                                                                                                                                                                          let drugsRes of reportPdfRes.drugsResList | slice : 28 : 32;
                                                                                                                                                                          let i = index
                                                                                                                                                                        ">
                      <td>
                        <ul class="ml-0 pl-2">
                          <li>
                            <i>{{ drugsRes.gene.replace("Fusion","") }}</i><span *ngIf="drugsRes.gene.includes('Fusion')">Fusion</span> {{ drugsRes.pposition?.replace(' ','')}} :
                            <br />
                            <span *ngFor="let drug of drugsRes.drugs; let i = index">
                              {{ drug.name
                              }}<span *ngIf="drugsRes.drugs.length - 1 != i">,</span>
                            </span>
                            - {{ drugsRes.association }}
                            <p>{{ drugsRes.cancerType }}</p>
                          </li>
                        </ul>
                      </td>
                      <td>
                        <div [innerHtml]="drugsRes.summary"></div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <!--  -->

            <!--  -->
            <div class="row footer">
              <div class="col-12">
                <div class="pb-2 pl-4 text">
                  <div class="d-block">
                    <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
                  </div>
                  <div class="footer_body" [ngClass]="'gene-bg_blue'">
                    <div class="footer_list">
                      <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
                      <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
                      <span>Gin - Q</span><span>Gly - G</span><span>His - H</span>
                      <span>Ile - I</span>
                    </div>
                    <div class="footer_list">
                      <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
                      <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
                      <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
                      <span>Val - V</span>
                    </div>
                  </div>
                </div>
                <div class="col-12 pt-1 ">
                  <p style="font-size: 8px!important; line-height: 14px!important;">
                    {{footerDiscription}}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <!-- page 3_8 -->
          <div id="page3_8" *ngIf="reportPdfRes.drugsResList.length > 32" class="container position-relative page"
            style="background-color: white">
            <div class="row header mt-1 py-4">
              <div>
                <div class="col-3">
                  <img src="../../../assets/logo.png" alt="" style="width: 140px" />
                </div>
              </div>
              <div></div>
              <div class="col">
                <div class="patient-detailes ml-auto float-right">
                  <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
                    <li>
                      Patient Id <br />
                      <span>{{ report.patient?.ecrfId }}</span>
                    </li>
                    <li class="ml-4">
                      Cancer Site<br />
                      <span>{{ report.cancerType }}</span>
                    </li>
                    <li class="ml-4">
                      SAMPLE ID<br />
                      <span>{{ report.labDetails?.sampleId }}</span>
                    </li>
                    <li class="ml-4">
                      Registration Date<br />
                      <span>{{
                        report.labDetails?.sampleCollected | date : "dd/MM/yyyy"
                        }}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <!--  -->
              <div *ngIf="report.clientType == '4basecare_client'">
                <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
                  </div>
                </div>
                <div *ngIf="report.clientName == 'path_labs'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
                  </div>
                </div>
              </div>
            </div>
            <!--  -->
            <div class="mar_top"></div>
            <!--  -->

            <h6 class="py-2 h6_bg" [ngClass]="'skyBlue'">
              Alterations With Clinically Relevant Therapies
            </h6>
            <!--  -->
            <div class="row">
              <div class="col-12">
                <table class="page_1 cust_table w-100 mt-3 first_2 2nd_table">
                  <thead>
                    <tr>
                      <th>Gene (Alterations) - Drug association</th>
                      <th>Summary</th>
                    </tr>
                  </thead>
                  <!-- SNV -->

                  <tbody>
                    <tr *ngFor=" let drugsRes of reportPdfRes.drugsResList | slice : 32 : 36; let i = index ">
                      <td>
                        <ul class="ml-0 pl-2">
                          <li>
                            <i>{{ drugsRes.gene.replace("Fusion","") }}</i><span *ngIf="drugsRes.gene.includes('Fusion')">Fusion</span> {{ drugsRes.pposition?.replace(' ','')}} :
                            <br />
                            <span *ngFor="let drug of drugsRes.drugs; let i = index">
                              {{ drug.name
                              }}<span *ngIf="drugsRes.drugs.length - 1 != i">,</span>
                            </span>
                            - {{ drugsRes.association }}
                            <p>{{ drugsRes.cancerType }}</p>
                          </li>
                        </ul>
                      </td>
                      <td>
                        <div [innerHtml]="drugsRes.summary"></div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <!--  -->

            <!--  -->
            <div class="row footer">
              <div class="col-12">
                <div class="pb-2 pl-4 text">
                  <div class="d-block">
                    <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
                  </div>
                  <div class="footer_body" [ngClass]="'gene-bg_blue'">
                    <div class="footer_list">
                      <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
                      <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
                      <span>Gin - Q</span><span>Gly - G</span><span>His - H</span>
                      <span>Ile - I</span>
                    </div>
                    <div class="footer_list">
                      <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
                      <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
                      <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
                      <span>Val - V</span>
                    </div>
                  </div>
                </div>
                <div class="col-12 pt-1 ">
                  <p style="font-size: 8px!important; line-height: 14px!important;">
                    {{footerDiscription}}
                  </p>
                </div>
              </div>
            </div>
          </div>

                    <!-- page 3_9 -->
                    <div id="page3_9" *ngIf="reportPdfRes.drugsResList.length > 36" class="container position-relative page"
                      style="background-color: white">
                      <div class="row header mt-1 py-4">
                        <div>
                          <div class="col-3">
                            <img src="../../../assets/logo.png" alt="" style="width: 140px" />
                          </div>
                        </div>
                        <div></div>
                        <div class="col">
                          <div class="patient-detailes ml-auto float-right">
                            <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
                              <li>
                                Patient Id <br />
                                <span>{{ report.patient?.ecrfId }}</span>
                              </li>
                              <li class="ml-4">
                                Cancer Site<br />
                                <span>{{ report.cancerType }}</span>
                              </li>
                              <li class="ml-4">
                                SAMPLE ID<br />
                                <span>{{ report.labDetails?.sampleId }}</span>
                              </li>
                              <li class="ml-4">
                                Registration Date<br />
                                <span>{{
                                  report.labDetails?.sampleCollected | date : "dd/MM/yyyy"
                                  }}</span>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <!--  -->
                        <div *ngIf="report.clientType == '4basecare_client'">
                          <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
                            <div class="logo">
                              <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
                            </div>
                          </div>
                          <div *ngIf="report.clientName == 'path_labs'" class="col-3">
                            <div class="logo">
                              <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <!--  -->
                      <div class="mar_top"></div>
                      <!--  -->

                      <h6 class="py-2 h6_bg" [ngClass]="'skyBlue'">
                        Alterations With Clinically Relevant Therapies
                      </h6>
                      <!--  -->
                      <div class="row">
                        <div class="col-12">
                          <table class="page_1 cust_table w-100 mt-3 first_2 2nd_table">
                            <thead>
                              <tr>
                                <th>Gene (Alterations) - Drug association</th>
                                <th>Summary</th>
                              </tr>
                            </thead>
                            <!-- SNV -->

                            <tbody>
                              <tr *ngFor=" let drugsRes of reportPdfRes.drugsResList | slice : 36 : 40; let i = index ">
                                <td>
                                  <ul class="ml-0 pl-2">
                                    <li>
                                      <i>{{ drugsRes.gene.replace("Fusion","") }}</i><span *ngIf="drugsRes.gene.includes('Fusion')">Fusion</span> {{ drugsRes.pposition?.replace(' ','')}} :
                                      <br />
                                      <span *ngFor="let drug of drugsRes.drugs; let i = index">
                                        {{ drug.name
                                        }}<span *ngIf="drugsRes.drugs.length - 1 != i">,</span>
                                      </span>
                                      - {{ drugsRes.association }}
                                      <p>{{ drugsRes.cancerType }}</p>
                                    </li>
                                  </ul>
                                </td>
                                <td>
                                  <div [innerHtml]="drugsRes.summary"></div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <!--  -->

                      <!--  -->
                      <div class="row footer">
                        <div class="col-12">
                          <div class="pb-2 pl-4 text">
                            <div class="d-block">
                              <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
                            </div>
                            <div class="footer_body" [ngClass]="'gene-bg_blue'">
                              <div class="footer_list">
                                <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
                                <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
                                <span>Gin - Q</span><span>Gly - G</span><span>His - H</span>
                                <span>Ile - I</span>
                              </div>
                              <div class="footer_list">
                                <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
                                <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
                                <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
                                <span>Val - V</span>
                              </div>
                            </div>
                          </div>
                          <div class="col-12 pt-1 ">
                            <p style="font-size: 8px!important; line-height: 14px!important;">
                              {{footerDiscription}}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

          <!-- page 4 -->
          <div id="page4" *ngIf="prognasticCount>0" class="container position-relative page" style="background-color: white">
            <div class="row header mt-1 py-4">
              <div>
                <div class="col-3">
                  <img src="../../../assets/logo.png" alt="" style="width: 140px" />
                </div>
              </div>
              <div></div>
              <div class="col">
                <div class="patient-detailes ml-auto float-right">
                  <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
                    <li>
                      Patient Id <br />
                      <span>{{ report.patient?.ecrfId }}</span>
                    </li>
                    <li class="ml-4">
                      Cancer Site<br />
                      <span>{{ report.cancerType }}</span>
                    </li>
                    <li class="ml-4">
                      SAMPLE ID<br />
                      <span>{{ report.labDetails?.sampleId }}</span>
                    </li>
                    <li class="ml-4">
                      Registration Date<br />
                      <span>{{
                        report.labDetails?.sampleCollected | date : "dd/MM/yyyy"
                        }}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <!--  -->
              <div *ngIf="report.clientType == '4basecare_client'">
                <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
                  </div>
                </div>
                <div *ngIf="report.clientName == 'path_labs'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
                  </div>
                </div>
              </div>
            </div>
            <!--  -->
            <div class="mar_top"></div>
            <!--  -->
            <div *ngIf="prognasticCount>0">
              <div>
                <h6 class="py-2 h6_bg"> Alterations associated with prognostic significance</h6>

              </div>

              <div class="row">
                <div class="col-12">
                  <table class="page_1 cust_table w-100 mt-3 varient_details prognastictable" [ngClass]="'table_variant-blue'">
                    <thead>
                      <tr>
                        <th>Gene (Alterations)</th>
                        <th>Prognostic Significance</th>
                        <th>Summary</th>

                      </tr>
                    </thead>

                    <ng-container *ngFor=" let prog of reportPdfRes.prognosticAlterations ">
                      <tr>
                        <td style="padding-left: 3px !important">
                          <i>{{ prog.gene }}&nbsp;</i> <span [innerHtml]="prog.pOrcDot"></span>
                        </td>
                        <td style="padding-left: 3px !important">
                          {{prog.prognosticSignificance}}
                        </td>
                        <td style="padding-left: 3px !important">
                          <span [innerHtml]="prog.description"></span>
                        </td>

                      </tr>
                    </ng-container>


                  </table>
                </div>
              </div>
            </div>


            <!--  -->
            <div class="row footer">
              <div class="col-12">
                <div class="pb-2 pl-4 text">
                  <div class="d-block">
                    <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
                  </div>
                  <div class="footer_body" [ngClass]="'gene-bg_blue'">
                    <div class="footer_list">
                      <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
                      <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
                      <span>Gin - Q</span><span>Gly - G</span><span>His - H</span>
                      <span>Ile - I</span>
                    </div>
                    <div class="footer_list">
                      <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
                      <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
                      <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
                      <span>Val - V</span>
                    </div>
                  </div>
                </div>
                <div class="col-12 pt-1 ">
                  <p style="font-size: 8px!important; line-height: 14px!important;">
                    {{footerDiscription}}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <!-- Page 5 -->
          <div id="page5" class="container position-relative page" style="background-color: white">
            <div class="row header mt-1 py-4">
              <div>
                <div class="col-3">
                  <img src="../../../assets/logo.png" alt="" style="width: 140px" />
                </div>
              </div>
              <div></div>
              <div class="col">
                <div class="patient-detailes ml-auto float-right">
                  <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
                    <li>
                      Patient Id <br />
                      <span>{{ report.patient?.ecrfId }}</span>
                    </li>
                    <li class="ml-4">
                      Cancer Site<br />
                      <span>{{ report.cancerType }}</span>
                    </li>
                    <li class="ml-4">
                      SAMPLE ID<br />
                      <span>{{ report.labDetails?.sampleId }}</span>
                    </li>
                    <li class="ml-4">
                      Registration Date<br />
                      <span>{{
                        report.labDetails?.sampleCollected | date : "dd/MM/yyyy"
                        }}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <!--  -->
              <div *ngIf="report.clientType == '4basecare_client'">
                <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
                  </div>
                </div>
                <div *ngIf="report.clientName == 'path_labs'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
                  </div>
                </div>
              </div>
            </div>
            <!--  -->
            <div class="mar_top"></div>
            <!--  -->

            <h6 class="py-2 h6_bg" [ngClass]="'skyBlue'">References</h6>
            <!--  -->
            <div class="row">
              <div class="col">
                <div class="disc">
                  <div class="ref_data mt-4">
                    <ul class="break-word" style="text-align: justify">
                      <li *ngFor="let referenceArticle of reportPdfRes.referenceArticles" style="
                                                  text-align: justify;
                                                  /* white-space:pre-wrap; */
                                                  word-break: break-word;
                                                ">
                        {{ referenceArticle }}
                      </li>
                    </ul>
                  </div>
                </div>

              </div>
            </div>
            <div class="img_signs above-footer">
              <!-- <div class="col" *ngIf="isReportDownload">
                <img [src]="imgsings" alt="" class="img-fluid w-90" />
              </div> -->
              <div class="row" id="imgsings" >
                <div class="col">

                  <p style="
                        text-align: center;

                        font-size: 15px !important;
                      ">(Electronically signed by)
                  </p>
                  <p style="
                          text-align: center;
                          font-weight: bolder;
                          font-size: 15px !important;
                        ">
                    Vidya H Veldore, PhD<br />Vice President - Clinical Operations
                  </p>
                </div>
                <div class="col">
                  <p style="text-align: center; font-size: 15px !important;">
                    (Electronically signed by)</p>
                    <p style="
                    text-align: center;
                    font-weight: bolder;
                    font-size: 15px !important;
                  ">
                  Dr. Jinumary Aji John<br />Head - Clinical Reporting </p>
                </div>
                <div class="col">
                  <p style="
                    text-align: center;

                    font-size: 15px !important;
                  ">(Electronically signed by)
                  </p>
                  <p style="
                          text-align: center;
                          font-weight: bolder;
                          font-size: 15px !important;
                        ">
                    Vyomesh Javle<br />Head - Clinical Bioinformatics
                  </p>
                </div>
              </div>
            </div>
                    <!--  -->
                    <div class="row footer">
                      <div class="col-12">
                        <div class="pb-2 pl-4 text">
                          <div class="d-block">
                            <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
                          </div>
                          <div class="footer_body" [ngClass]="'gene-bg_blue'">
                            <div class="footer_list">
                              <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
                              <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
                              <span>Gin - Q</span><span>Gly - G</span><span>His - H</span>
                              <span>Ile - I</span>
                            </div>
                            <div class="footer_list">
                              <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
                              <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
                              <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
                              <span>Val - V</span>
                            </div>
                          </div>
                        </div>
                        <div class="col-12 pt-1 ">
                          <p style="font-size: 8px!important; line-height: 14px!important;">
                            {{footerDiscription}}
                          </p>
                        </div>
                      </div>
                    </div>
          </div>

          <!-- Page 6 -->
          <div id="page6" class="container position-relative page" style="background-color: white">
            <div class="row header mt-1 py-4">
              <div>
                <div class="col-3">
                  <img src="../../../assets/logo.png" alt="" style="width: 140px" />
                </div>
              </div>
              <div></div>
              <div class="col">
                <div class="patient-detailes ml-auto float-right">
                  <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
                    <li>
                      Patient Id <br />
                      <span>{{ report.patient?.ecrfId }}</span>
                    </li>
                    <li class="ml-4">
                      Cancer Site<br />
                      <span>{{ report.cancerType }}</span>
                    </li>
                    <li class="ml-4">
                      SAMPLE ID<br />
                      <span>{{ report.labDetails?.sampleId }}</span>
                    </li>
                    <li class="ml-4">
                      Registration Date<br />
                      <span>{{
                        report.labDetails?.sampleCollected | date : "dd/MM/yyyy"
                        }}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <!--  -->
              <div *ngIf="report.clientType == '4basecare_client'">
                <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
                  </div>
                </div>
                <div *ngIf="report.clientName == 'path_labs'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
                  </div>
                </div>
              </div>
            </div>
            <!--  -->
            <div class="mar_top"></div>
            <!--  -->

            <div class="">
              <h6 class="py-2 h6_bg" [ngClass]="'skyBlue'">TEST DESCRIPTION</h6>
              <p class="mb-2" style="text-align:justify">
                <span class="font-weight-normal">TAR</span><b>GT</b>
                <span class="clr-cyan pl-2" [ngClass]="'txt-skyblue'">First Solid</span>
                is a Next Generation Sequencing based test which identifies
                genetic alterations in a comprehensive panel of well curated 72  genes which are having an impact response to approved
                therapy for a particular cancer type. Some of the alterations
                detected may have bearing on prognosis and/or therapeutic options
                and may provide relevant information that allows
                oncologists/clinicians to consider various lines of
                targeted treatment for the patient.
              </p>
            </div>

            <!--  -->
            <div class="row">
              <div class="col">
                <div class="">
                  <h6 class="py-2 h6_bg" [ngClass]="'skyBlue'">GENES EVALUATED</h6>
                  <p>
                    <span class="font-weight-normal">TAR</span><b>GT</b>
                    <span class="clr-cyan pl-2" [ngClass]="'txt-skyblue'">First Solid</span>
                    detects mutations (SNVs and Short InDels), Copy Number Variations (CNVs),
                    Gene Fusions and splice variants in the 72 genes:
                    <!-- detects mutations (SNVs and Short Indels), Copy Number Variations
                                          (CNVs), gene fusions and splice variants in the 53 genes : -->
                  </p>
                </div>
                <!--  -->
                <div class="row mx-0">
                  <div class="col-12 px-0">
                    <div class="genes_info">
                      <div class="title py-2">
                        <h6 class="mb-0 d-inline-block border-bottom py-2">
                          <b>SNVs, SHORT INDELS and CNVs</b> Covered in
                          <span class="font-weight-normal">TAR</span><b>GT</b>
                          <span class="clr-cyan pl-2" [ngClass]="'txt-skyblue'">First Solid</span>
                        </h6>
                      </div>
                      <div class="gene_body" [ngClass]="'gene-bg_blue'">
                        <i>
                          <div class="gene_list">
                            <span>ABL1</span><span>ALK</span><span>APC</span>
                            <span>AR</span><span>ATM</span><span>BARD1</span>
                            <span>BMPR1A</span><span>BRAF</span><span>BRCA1</span>
                            <span>BRCA2</span>
                            <!-- <span>ABL1</span><span>ALK</span><span>AR</span
                                                  ><span>ATM</span><span>BARD1</span><span>BRAF</span
                                                  ><span>BRCA1</span><span>BRCA2</span><span>BRIP1</span
                                                  ><span>CDK12</span> -->
                          </div>
                          <div class="gene_list bg-white">
                            <span>BRIP1</span><span>CDK12</span><span>CDK4</span>
                            <span>CDK6</span><span>CDKN2A</span><span>CHEK1</span>
                            <span>CHEK2</span><span>CTNNB1</span><span>EGFR</span>
                            <span>EPCAM</span>
                            <!-- <span>CDK4</span><span>CDK6</span><span>CDKN2A</span
                                                  ><span>CHEK1</span><span>CHEK2</span><span>EGFR</span
                                                  ><span>EPCAM</span><span>ERBB2</span><span>ERBB3</span
                                                  ><span>EZH2</span> -->
                          </div>
                          <div class="gene_list">
                            <span>ERBB2</span><span>ERBB3</span><span>EZH2</span>
                            <span>FANCL</span><span>FGFR1</span><span>FGFR2</span>
                            <span>FGFR3</span><span>GAPDH</span><span>IDH1</span>
                            <span>IDH2</span>
                            <!-- <span>FANCL</span><span>FGFR3</span><span>GAPDH</span
                                                  ><span>IDH1</span><span>IDH2</span><span>JAK2</span
                                                  ><span>KIT</span><span>KRAS</span><span>MAP2K1</span
                                                  ><span>MAP2K2</span> -->
                          </div>
                          <div class="gene_list">
                            <span>JAK2</span><span>KIT</span><span>KRAS</span>
                            <span>MAP2K1</span><span>MAP2K2</span><span>MDM2</span>
                            <span>MET</span><span>MLH1</span><span>MLH3</span>
                            <span>MSH2</span>
                            <!-- <span>MDM2</span><span>MET</span><span>MLH1</span
                                                  ><span>MLH3</span><span>MSH2</span><span>MSH6</span
                                                  ><span>NRAS</span><span>PALB2</span><span>PDGFRA</span
                                                  ><span>PDGFRB</span> -->
                          </div>
                          <div class="gene_list">
                            <span>MSH6</span><span>MUTYH</span><span>NRAS</span>
                            <span>PALB2</span><span>PDGFRA</span><span>PDGFRB</span>
                            <span>PIK3CA</span><span>PMS1</span><span>PMS2</span>
                            <span>POLD1</span>
                            <!-- <span>PIK3CA</span><span>PMS1</span><span>PMS2</span
                                                  ><span>PPP2R2A</span><span>RAD51B</span><span>RAD51C</span
                                                  ><span>RAD51D</span><span>RAD54L</span><span>RET</span
                                                  ><span>ROS1</span> -->
                          </div>
                          <div class="gene_list">
                            <span>POLE</span><span>POLH</span><span>PTEN</span>
                            <span>RAD50</span><span>RAD51</span><span>RAD51B</span>
                            <span>RAD51C</span><span>RAD51D</span><span>RAD54L</span>
                            <span>RB1</span>
                            <!-- <span>STK11</span><span>TSC1</span><span>TSC2</span> -->
                          </div>
                          <div class="gene_list">
                            <span>RET</span><span>ROS1</span><span>SMAD4</span>
                            <span>STK11</span><span>TP53</span><span>TSC1</span>
                            <span>TSC2</span>
                            <!-- <span>STK11</span><span>TSC1</span><span>TSC2</span> -->
                          </div>
                        </i>
                      </div>

                      <!-- <div class="title py-2 mt-4">
                                              <h6 class="mb-0 d-inline-block border-bottom py-2">
                                                <b>CNAs</b> Covered in
                                                <span class="font-weight-normal">TAR</span><b>GT</b>
                                                <span class="clr-cyan pl-2" [ngClass]="'txt-skyblue'"
                                                  >First</span
                                                >
                                              </h6>
                                            </div> -->
                      <!-- <div class="gene_body" [ngClass]="'gene-bg_blue'">
                                              <i>
                                                <div class="gene_list">
                                                  <span>ABL1</span><span>AR</span><span>ATM</span
                                                  ><span>BARD1</span><span>BRCA1</span><span>BRCA2</span
                                                  ><span>BRIP1</span><span>CDK12</span><span>CDK4</span
                                                  ><span>CDK6</span>
                                                </div>
                                                <div class="gene_list">
                                                  <span>CDKN2A</span><span>CHEK1</span><span>CHEK2</span
                                                  ><span>EGFR</span><span>EPCAM</span><span>ERBB2</span
                                                  ><span>ERBB3</span><span>EZH2</span><span>FANCL</span
                                                  ><span>FGFR3</span>
                                                </div>
                                                <div class="gene_list">
                                                  <span>GAPDH</span><span>IDH2</span><span>JAK2</span
                                                  ><span>KIT</span><span>MDM2</span><span>NRAS</span
                                                  ><span>PALB2</span><span>PDGFRA</span><span>PDGFRB</span
                                                  ><span>PPP2R2A</span>
                                                </div>
                                                <div class="gene_list">
                                                  <span>RAD51B</span><span>RAD51C</span><span>RAD51D</span
                                                  ><span>RAD54L</span><span>STK11</span><span>TSC1</span
                                                  ><span>TSC2</span>
                                                </div>
                                              </i>
                                            </div> -->
                    </div>
                  </div>
                  <!-- cnv_genes -->

            <div class="col-12 px-0" *ngIf="isKokilaben">
              <div class="genes_info">
                <div class="title py-2">
                  <h6 class="mb-0 d-inline-block border-bottom py-2">
                    <b> CNVs
                    </b> Covered in
                    <span class="font-weight-normal">TAR</span><b>GT</b>
                    <span class="clr-cyan pl-2" [ngClass]="'txt-skyblue'"
                      >First Solid</span
                    >
                  </h6>
                </div>
                <div class="gene_body" [ngClass]="'gene-bg_blue'">
                  <i>
                    <div class="gene_list">
                      <span>ABL1</span><span>ALK</span><span>APC</span>
                      <span>AR</span><span>ATM</span><span>BARD1</span>
                      <span>BMPR1A</span><span>BRAF</span><span>BRCA1</span>
                      <span>BRCA2</span>
                    </div>
                    <div class="gene_list bg-white">
                      <span>BRIP1</span><span>CDK12</span><span>CDK4</span>
                      <span>CDK6</span><span>CDKN2A</span><span>CHEK1</span>
                      <span>CHEK2</span><span>CTNNB1</span><span>EGFR</span>
                      <span>EPCAM</span>
                    </div>
                    <div class="gene_list">
                      <span>ERBB2</span><span>ERBB3</span><span>EZH2</span>
                      <span>FANCL</span><span>FGFR1</span><span>FGFR2</span>
                      <span>FGFR3</span><span>GAPDH</span><span>IDH2</span>
                      <span>JAK2</span>
                    </div>
                    <div class="gene_list">
                     <span>KIT</span><span>MDM2</span>
                      <span>MET</span><span>MLH1</span><span>MUTYH</span>
                      <span>NRAS</span><span>PALB2</span><span>PDGFRA</span>
                      <span>PDGFRAB</span><span>POLD1</span>
                    </div>

                    <div class="gene_list">
                      <span>POLE</span><span>POLH</span><span>PTEN</span>
                      <span>RAD50</span><span>RAD51</span><span>RAD51B</span>
                      <span>RAD51C</span><span>RAD51D</span><span>RAD54L</span>
                      <span>RB1</span>
                    </div>
                    <div class="gene_list">
                      <span>RET</span><span>ROS1</span><span>SMAD4</span>
                      <span>STK11</span><span>TP53</span><span>TSC1</span>
                      <span>TSC2</span>
                    </div>
                  </i>
                </div>


              </div>
            </div>




                  <div class="col-12 px-0">
                    <div class="genes_info">
                      <div class="title py-2">
                        <h6 class="mb-0 d-inline-block border-bottom py-2">
                          <b>Gene Fusions/Splicing Variations</b> Covered in
                          <span class="font-weight-normal">TAR</span><b>GT</b>
                          <span class="clr-cyan pl-2" [ngClass]="'txt-skyblue'">First Solid</span>
                        </h6>
                      </div>
                      <div class="gene_body" [ngClass]="'gene-bg_blue'">
                        <i>
                          <div class="gene_list">
                          <span>ALK</span><span>FGFR2</span><span>FGFR3</span>
                          <span>MET</span><span>NRG1</span><span>NRG2</span>
                          <span>NTRK1</span><span>NTRK2</span> <span>NTRK3</span><span>RET</span>

                          </div>
                          <div class="gene_list">
                            <span>ROS1</span>
                          </div>
                        </i>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
            <h6 class="py-2 h6_bg mt-3" [ngClass]="'skyBlue'">TEST METHODOLOGY</h6>
            <!--  -->
            <div class="row mt-3">
              <div class="col">
                <h6 class="font-weight-bold">
                  Sample preparation and Library preparation :
                </h6>
                <!-- <p>
                                        DNA isolated from FFPE, or any other fresh tumor tissue source was
                                        used to perform targeted gene capture using a custom capture kit.
                                        The libraries were sequenced to mean >350X coverage on Illumina
                                        sequencing platform.
                                      </p> -->
                <p style="text-align: justify;">
                  DNA isolated from FFPE, or any other fresh tumor tissue source was used to perform targeted gene capture using
                  a custom capture kit. The libraries were sequenced to mean
                  >350X coverage on Illumina sequencing platform.
                </p>
              </div>
            </div>
            <!--  -->
            <div class="row mt-4">
              <div class="col">
                <h6 class="font-weight-bold">
                  Bioinformatics Analysis and Reporting :
                </h6>
                <!-- <p>
                                        The sequences obtained are aligned to human reference genome
                                        (GRCh37/hg19) and variant analysis was performed using set of
                                        Bioinformatics Pipeline. Only non-synonymous and splice site
                                        variants found in the panel consisting of specific set of genes were
                                        used for clinical interpretation. Silent variations that do not
                                        result in any change in amino acid in the coding region are not
                                        reported. Clinically relevant mutations were annotated using
                                        published variants in literature and a set of databases – ClinVar,
                                        COSMIC and dbSNP. Common variants are filtered based on allele
                                        frequency in 1000 Genome Phase 3, ExAC, dbSNP, gnomAD, etc. In the
                                        absence of a clinically significant reported known variation(s),
                                        pathogenicity will be predicted based on <i>in-silico</i> gene
                                        prioritization tools: CADD, SIFT, PolyPhen-2, Condel and Mutation
                                        taster and prioritized for clinical correlation. The identified
                                        pathogenic variant will be correlated with observed phenotypic
                                        features of the patient and interpreted according to American
                                        College of Medical Genetics (ACMG) guidelines.
                                      </p>
                                      <p class="mb-2 d-block">
                                        Somatic variants are classified into three tiers based on their level
                                        of clinical significance in cancer diagnosis, prognosis, and/or
                                        therapeutics as per international guidelines: ACMG, ASCO, AMP, CAP,
                                        NCCN and ESMO
                                      </p> -->
                <p style="text-align: justify;">
                  The sequences obtained are aligned to human reference genome (GRCh37/hg19) and variant analysis was performed
                  using set of Bioinformatics Pipeline. Only non-synonymous and splice site variants found in the panel
                  consisting of specific set of genes were used for clinical interpretation. Silent variations that do not
                  result in any change
                  in amino acid in the coding region are not reported. Clinically relevant mutations were annotated using
                  published variants in literature and a set of databases – ClinVar, COSMIC
                  and dbSNP. Common variants are filtered based on allele frequency in 1000 Genome Phase 3, ExAC, dbSNP, gnomAD,
                  etc. In the absence of a clinically significant reported
                  known variation(s), pathogenicity will be predicted based on <i>in-silico</i> gene prioritization tools: CADD, SIFT,
                  PolyPhen-2, Condel and Mutation taster and prioritized for clinical
                  correlation. The identified pathogenic variant will be correlated with observed phenotypic features of the patient and interpreted according to ACMG/AMP guidelines.
                </p>
                <p class="mb-2 d-block" style="text-align: justify;">
                  Somatic variants are classified into three tiers based on their level of clinical significance in cancer
                  diagnosis, prognosis, and/or therapeutics as per international guidelines:
                  ACMG, ASCO, AMP, CAP, NCCN and ESMO.
                </p>
              </div>
            </div>
            <!--  -->
                  <div class="row footer">
        <div class="col-12">
          <div class="pb-2 pl-4 text">
            <div class="d-block">
              <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
            </div>
            <div class="footer_body" [ngClass]="'gene-bg_blue'">
              <div class="footer_list">
                <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
                <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
                <span>Gin - Q</span><span>Gly - G</span><span>His - H</span>
                <span>Ile - I</span>
              </div>
              <div class="footer_list">
                <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
                <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
                <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
                <span>Val - V</span>
              </div>
            </div>
          </div>
          <div class="col-12 pt-1 ">
            <p style="font-size: 8px!important; line-height: 14px!important;">
              {{footerDiscription}}
            </p>
          </div>
        </div>
      </div>
          </div>

          <!-- Page 7 -->
          <div id="page7" class="container position-relative page" style="background-color: white">
            <div class="row header mt-1 py-4">
              <div>
                <div class="col-3">
                  <img src="../../../assets/logo.png" alt="" style="width: 140px" />
                </div>
              </div>
              <div></div>
              <div class="col">
                <div class="patient-detailes ml-auto float-right">
                  <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
                    <li>
                      Patient Id <br />
                      <span>{{ report.patient?.ecrfId }}</span>
                    </li>
                    <li class="ml-4">
                      Cancer Site<br />
                      <span>{{ report.cancerType }}</span>
                    </li>
                    <li class="ml-4">
                      SAMPLE ID<br />
                      <span>{{ report.labDetails?.sampleId }}</span>
                    </li>
                    <li class="ml-4">
                      Registration Date<br />
                      <span>{{
                        report.labDetails?.sampleCollected | date : "dd/MM/yyyy"
                        }}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <!--  -->
              <div *ngIf="report.clientType == '4basecare_client'">
                <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
                  </div>
                </div>
                <div *ngIf="report.clientName == 'path_labs'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
                  </div>
                </div>
              </div>
            </div>
            <!--  -->
            <div class="mar_top"></div>
            <!--  -->


            <h6 class="py-2 h6_bg mt-2" [ngClass]="'skyBlue'">
              LIMITATIONS AND DISCLAIMER
            </h6>

            <div class="row">
              <div class="col">
                <div class="disc">
                  <ul class="ml-0 pl-4" style="text-align: justify;">
                    <li>
                      TARGT FIRST SOLID test has been developed, validated and performed by 4baseCare Oncosolutions Pvt. Ltd and
                      has not been cleared or approved by the FDA.
                    </li>
                    <li>
                      DNA studies do not constitute a definitive test for the selected condition(s) in all individuals. It
                      should be realized that there are possible sources of error. Errors can result
                      from trace contamination, rare technical errors, rare genetic variants that interfere with analysis,
                      recent scientific developments, and alternative classification systems. This
                      test should be one of the many aspects used by the healthcare provider to help with a diagnosis and
                      treatment plan.
                    </li>
                    <li>
                      We are using the canonical transcript for clinical reporting which is usually the longest coding
                      transcript with strong/multiple supporting evidence. However, in rare cases,
                      clinically relevant variants annotated in alternate complete coding transcripts could also be reported.
                    </li>
                    <li>
                      The contents of this test should be carefully assessed by the treating physician and further interpreted
                      along with clinical, histopathological findings, contraindications and
                      guidelines before deciding the course of therapy.
                    </li>
                    <li>
                      The CNVs detected must be confirmed by an alternate method, such as IHC, for further clinical management decisions.
                    </li>
                    <!-- <li>
                      The sensitivity of this assay to detect large deletions/duplications of more than 10 bp or copy number
                      variations (CNV) is 70-75%. The CNVs detected have to be confirmed
                      by alternate method.
                    </li> -->
                    <li>
                      Most recent block is recommended for testing as the mutation profile may change in response to treatment
                      and hence differ at different sampling points.
                    </li>
                    <li>
                      The identified pathogenic variant will be correlated with observed phenotypic features of the patient and interpreted according to AMP guidelines.
                    </li>
                    <li>
                      Certain genes may not be covered completely, and few mutations could be missed. A negative result cannot
                      rule out the possibility that the tested tumor sample carries
                      mutations not previously associated with cancer and hence not included in the panel.
                    </li>
                    <li>
                      Our limit of detection for TARGT FIRST SOLID is 5% for SNVs, 10% for InDels and CNV gain &#8805; 6. In addition
                      to this, sequencing quality and coverage is dependent on many factors
                      such as homopolymers, GC-rich regions, intrinsic quality of FFPE DNA and that might impact the variant
                      detection.
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <!--  -->
                  <div class="row footer">
        <div class="col-12">
          <div class="pb-2 pl-4 text">
            <div class="d-block">
              <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
            </div>
            <div class="footer_body" [ngClass]="'gene-bg_blue'">
              <div class="footer_list">
                <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
                <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
                <span>Gin - Q</span><span>Gly - G</span><span>His - H</span>
                <span>Ile - I</span>
              </div>
              <div class="footer_list">
                <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
                <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
                <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
                <span>Val - V</span>
              </div>
            </div>
          </div>
          <div class="col-12 pt-1 ">
            <p style="font-size: 8px!important; line-height: 14px!important;">
              {{footerDiscription}}
            </p>
          </div>
        </div>
      </div>
          </div>
        </div>


        <!-- For 4basecare Template 3-->
        <div *ngIf="is4basecare && temp=='temp3'">
          <div id="page" class="container position-relative page" style="overflow-y: clip; background-color: white">
            <div class="row header">
              <div class="w-100 overflow-hidden">
                <div class="col">
                  <div class="cover_page">
                    <img src="../../../assets/tarGT_FirstSolid-Cover.png" alt="" class="img-fluid w-100" />
                  </div>
                </div>
              </div>
              <div></div>
            </div>
          </div>
          <!-- Page 1 -->
          <div id="page1" class="container position-relative page" style="background-color: white">
            <div class="row header mt-1 py-4">
              <div>
                <div class="col-3 pl-0">
                  <div class="logo">
                    <img src="../../../assets/logo.png" alt="" style="width: 140px" />
                  </div>
                </div>
              </div>
              <div></div>
              <div class="col">
                <div class="patient-detailes ml-auto float-right">
                  <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
                    <li>
                      Patient Id <br />
                      <span>{{ report.patient?.ecrfId }}</span>
                    </li>
                    <li class="ml-4">
                      Cancer Site<br />
                      <span>{{ report.cancerType }}</span>
                    </li>
                    <li class="ml-4">
                      SAMPLE ID<br />
                      <span>{{ report.labDetails?.sampleId }}</span>
                    </li>
                    <li class="ml-4">
                      Registration Date<br />
                      <span>{{
                        report.labDetails?.sampleCollected | date : "dd/MM/yyyy"
                        }}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <div *ngIf="report.clientType == '4basecare_client'">
                <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
                  </div>
                </div>
                <div *ngIf="report.clientName == 'path_labs'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
                  </div>
                </div>
              </div>
            </div>
            <!--  -->
            <div class="mar_top"></div>
            <!--  -->
            <div class="row">
              <div class="col-7">
                <div class="">
                  <div class="w-50" style="min-height: 80px">
                    <p class="py-1 font-weight-bold" [ngClass]="'txt-skyblue'">
                      SCOPE OF THE TEST
                    </p>
                    <p>SNVs, InDels, CNAs, Gene Fusions status</p>
                  </div>
                  <img src="../../../assets/BorderLine.png" class="mr-2 w-100" />
                </div>
              </div>
              <div class="col">
                <div class="">
                  <div class="w-100">
                    <!-- <p style="color: #208dcb;" class="py-1">
                                                                    <span style="color: #000 !important"></span>
                                                                  </p> -->
                    <div class="w-100" style="min-height: 80px">
                      <p [ngClass]="'txt-skyblue'" class="py-1 font-weight-bold" *ngIf="report.clinicalSummary != null">
                        <span *ngIf="report.clinicalSummary != ' '">CLINICAL INDICATION</span>
                      </p>

                      <p *ngIf="report.clinicalSummary != null">
                        <span style="color: #000 !important" *ngIf="report.clinicalSummary != ' '">{{ report.clinicalSummary
                          }}</span>
                      </p>
                    </div>
                    <!-- </div> -->
                    <img src="../../../assets/BorderLine.png" class="mr-2 w-100" />

                    <!-- </div> -->
                  </div>
                </div>
              </div>
            </div>
            <!--  -->
            <div class="row pt-2">
              <div class="col-7">
                <h6 class="text-uppercase py-2 font-weight-bold" [ngClass]="'txt-skyblue'">
                  Report Details
                </h6>
                <ul class="list-unstyled">
                  <li class="pb-1">
                    <span class="clr-cyan" [ngClass]="'txt-skyblue'">Name : </span>
                    <span class="text-black">{{
                      report.patient?.name | uppercase
                      }}</span>
                  </li>
                  <li class="pb-1">
                    <span class="clr-cyan" [ngClass]="'txt-skyblue'">Gender : </span>
                    <span class="text-black">{{
                      report.patient?.gender | titlecase
                      }}</span>
                  </li>
                  <li class="pb-1">
                    <span class="clr-cyan" [ngClass]="'txt-skyblue'">Age/DOB : </span>
                    <span class="text-black">{{
                      report.patient?.dateOfBirth
                      ? (report.patient?.dateOfBirth | date : "dd/MM/yyyy")
                      : report.patient?.age + " Years"
                      }}</span>
                  </li>
                  <li class="pb-1">
                    <span class="clr-cyan" [ngClass]="'txt-skyblue'">Reporting Date :
                    </span>
                    <span class="text-black">{{ date | date : "dd/MM/yyyy" }}</span>
                  </li>
                </ul>
              </div>
              <div class="col">
                <ul class="list-unstyled pt-4 mt-3">
                  <li *ngIf="report.cancerSubType != null" class="pb-1">
                    <span class="clr-cyan" [ngClass]="'txt-skyblue'">Cancer Celltype :
                    </span>
                    <span class="text-black">{{ report.cancerSubType }}</span>
                  </li>
                  <li class="pb-1">
                    <span class="clr-cyan" [ngClass]="'txt-skyblue'">Sample Source :
                    </span>
                    <span class="text-black">{{ sampleSource }}</span>
                  </li>
                  <li class="pb-1">
                    <span class="clr-cyan" [ngClass]="'txt-skyblue'">Consulting Clinician :
                    </span>
                    <span class="text-black">{{referringClinician}}</span>
                  </li>
                  <li class="pb-1">
                    <span class="clr-cyan" [ngClass]="'txt-skyblue'">Hospital :
                    </span>
                    <span class="text-black">{{ labHospital }}</span>
                  </li>
                </ul>
              </div>
            </div>
            <!--  -->
            <div class="row mt-2 py-2">
              <div class="col">
                <h6 class="mb-0 text-uppercase f-weight_700">Results</h6>
              </div>
            </div>
            <h6 class="py-2 h6_bg" [ngClass]="'skyBlue'">
              Genomic findings from tumor profiling
            </h6>

            <!--  -->
            <div class="row">
              <div style="width: 100%;" *ngIf="isReportDownload">
                <img [src]="firstPageTable" alt="" width="100%" class="img-fluid" />
              </div>
              <div class="col-12" id="firstPageTable" *ngIf="!isReportDownload">
                <table class="page_1 cust_table w-100 mt-3 first_1" [ngClass]="'table_skyblue'">
                  <tr>
                    <th>Genomic Alteration</th>
                    <th>Relevant Therapies (in Same Cancer Type)</th>
                    <th>Relevant Therapies (in Different Cancer)</th>
                  </tr>

                  <tr *ngIf="tableCount== 0 && prognasticCount>0 ">
                    <td colspan="3" class="text-center mt-2">
                      Clinically relevant genomic alterations associated with therapeutic significance were not detected.
                    </td>
                  </tr>
                  <tr *ngIf="tableCount== 0 && prognasticCount == 0">
                    <td colspan="3" class="text-center mt-2">
                      Clinically relevant genomic alterations associated with both therapeutic and prognostic significance were not detected.
                    </td>
                  </tr>

                  <!-- SNV -->
                  <ng-container>
                    <tr *ngFor="
                                                                let snvHotspotTrail of reportPdfRes?.allHotspotTrailList  | slice : 0 : 3 ; let i=index
                                                              ">
                      <td>
                        <i>{{ snvHotspotTrail.gene }}</i>
                        &nbsp;                  <span *ngIf="!snvHotspotTrail.gene.includes('Wild type')">

                        <span *ngIf="snvHotspotTrail?.multiVariant==null">
                          <span *ngIf="snvHotspotTrail.exonNumber != null || snvHotspotTrail.pposition != null && snvHotspotTrail.pposition != ''">

                            <span *ngIf="snvHotspotTrail.exonNumber != null">{{snvHotspotTrail.exonNumber}}</span>&nbsp;
                            <span *ngIf="snvHotspotTrail.pposition != null && snvHotspotTrail.pposition != ''">({{snvHotspotTrail.pposition?.replace(' ','')}})</span></span>
                            <p *ngIf="snvHotspotTrail.mutantBurdenPercent != null && !snvHotspotTrail.gene.includes('Wild type')">
                              Allelic burden: {{ snvHotspotTrail.mutantBurdenPercent }}%
                            </p>
                            <span *ngIf="snvHotspotTrail.copyNumber!=null && snvHotspotTrail.copyNumber.length>0"> {{ snvHotspotTrail.copyNumber }}
                          </span>
                        </span>
                        <span *ngIf="snvHotspotTrail?.multiVariant!=null"><br/>
                          <span  style="font-size: 14px !important" [innerHtml]="snvHotspotTrail?.multiVariant"></span>
                        </span></span>
                      </td>
                      <td>
                        <table class="table first_table w-100">
                          <thead class="border-bottom cust_clr">
                            <tr>
                              <th class="w-50">
                                <span class="font-weight-normal"><i>Therapy</i></span>
                              </th>
                              <th class="w-33">
                                <span class="font-weight-normal"><i>Clinical Relevance</i></span>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngIf="snvHotspotTrail.sameCancerType == null">
                              <td class="w-50">NA</td>
                              <td class="w-50">NA</td>
                            </tr>

                            <ng-container *ngIf="snvHotspotTrail.sameCancerType != null">
                              <tr *ngFor="
                                                                          let item of snvHotspotTrail.sameTypeRelevantData
                                                                        ">
                                <td>{{ item.therapy }}</td>
                                <td>{{ item.association }}</td>
                                <!-- <td>{{item.cancerType}}</td> -->
                              </tr>
                            </ng-container>
                          </tbody>
                        </table>
                      </td>
                      <td>
                        <table class="table first_table w-100">
                          <thead class="border-bottom cust_clr">
                            <tr>
                              <th class="w-33">
                                <span class="font-weight-normal"><i>Therapy</i></span>
                              </th>
                              <th class="w-33">
                                <span class="font-weight-normal"><i>Clinical Relevance</i></span>
                              </th>
                              <th class="w-33">
                                <span class="font-weight-normal"><i>Cancer Type</i></span>
                              </th>
                            </tr>
                          </thead>
                          <tbody>

                            <ng-container *ngIf="snvHotspotTrail.otherCancerType != null">
                              <tr *ngFor="let item of snvHotspotTrail.otherTypeRelevantData
                                                                        ">
                                <td>{{ item.therapy }}</td>
                                <td>{{ item.association }}</td>
                                <td>{{ item.cancerType }}</td>
                              </tr>
                            </ng-container>
                            <tr *ngIf="snvHotspotTrail.otherCancerType == null">
                              <td class="w-33">NA</td>
                              <td class="w-33">NA</td>
                              <td class="w-33">NA</td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>


                  </ng-container>

                </table>
                <p *ngIf="
                        snvHotSpot.length != 0 ||
                        cnaHotSpot.length != 0 ||
                                            fusionHotSpot.length !=0
                                          " style="font-size: 13px !important" class="mt-0">
                  <i> *NA: Not Applicable</i>
                </p>
              </div>
            </div>
            <!--  -->

            <!--  -->



            <!--  -->
            <div class="row footer">
              <div class="col-12">
                <div class="pb-2 pl-4 text">
                  <div class="d-block">
                    <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
                  </div>
                  <div class="footer_body" [ngClass]="'gene-bg_blue'">
                    <div class="footer_list">
                      <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
                      <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
                      <span>Gin - Q</span><span>Gly - G</span><span>His - H</span>
                      <span>Ile - I</span>
                    </div>
                    <div class="footer_list">
                      <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
                      <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
                      <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
                      <span>Val - V</span>
                    </div>
                  </div>
                </div>
                <div class="col-12 pt-1 ">
                  <p style="font-size: 8px!important; line-height: 14px!important;">
                    {{footerDiscription}}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <!-- page 1_1 -->
          <div id="page1_1" *ngIf="reportPdfRes?.allHotspotTrailList.length>6" class="container position-relative page"
            style="background-color: white">
            <div class="row header mt-1 py-4">
              <div>
                <div class="col-3">
                  <img src="../../../assets/logo.png" alt="" style="width: 140px" />
                </div>
              </div>
              <div></div>
              <div class="col">
                <div class="patient-detailes ml-auto float-right">
                  <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
                    <li>
                      Patient Id <br />
                      <span>{{ report.patient?.ecrfId }} </span>
                    </li>
                    <li class="ml-4">
                      Cancer Site<br />
                      <span>{{ report.cancerType }}</span>
                    </li>
                    <li class="ml-4">
                      SAMPLE ID<br />
                      <span>{{ report.labDetails?.sampleId }}</span>
                    </li>
                    <li class="ml-4">
                      Registration Date<br />
                      <span>{{
                        report.labDetails?.sampleCollected | date : "dd/MM/yyyy"
                        }}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <!--  -->
              <div *ngIf="report.clientType == '4basecare_client'">
                <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
                  </div>
                </div>
                <div *ngIf="report.clientName == 'path_labs'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div style="width: 100%;" *ngIf="isReportDownload">
                <img [src]="firstPageTable1" alt="" width="100%" class="img-fluid" />
              </div>
              <div class="col-12" id="firstPageTable1" *ngIf="!isReportDownload">
                <table class="page_1 cust_table w-100 mt-3 first_1" [ngClass]="'table_skyblue'">
                  <tr>
                    <th>Genomic Alteration</th>
                    <th>Relevant Therapies (in Same Cancer Type)</th>
                    <th>Relevant Therapies (in Different Cancer)</th>
                  </tr>

                  <tr *ngIf="tableCount== 0 && prognasticCount>0 ">
                    <td colspan="3" class="text-center mt-2">
                      Clinically relevant genomic alterations associated with therapeutic significance were not detected.
                    </td>
                  </tr>
                  <tr *ngIf="tableCount== 0 && prognasticCount == 0">
                    <td colspan="3" class="text-center mt-2">
                      Clinically relevant genomic alterations associated with both therapeutic and prognostic significance were
                      not detected.
                    </td>
                  </tr>

                  <!-- SNV -->
                  <ng-container>
                    <tr *ngFor="
                                                                let snvHotspotTrail of reportPdfRes?.allHotspotTrailList  | slice : 3 : 8 ; let i=index
                                                              ">
                      <td>
                        <i>{{ snvHotspotTrail.gene }}</i>
                        &nbsp;<span *ngIf="!snvHotspotTrail.gene.includes('Wild type')">
                        <span *ngIf="snvHotspotTrail?.multiVariant==null">
                          <span *ngIf="snvHotspotTrail.exonNumber != null || snvHotspotTrail.pposition != null && snvHotspotTrail.pposition != ''">

                            <span *ngIf="snvHotspotTrail.exonNumber != null">{{snvHotspotTrail.exonNumber}}</span>&nbsp;
                            <span *ngIf="snvHotspotTrail.pposition != null && snvHotspotTrail.pposition != ''">({{snvHotspotTrail.pposition?.replace(' ','')}})</span></span>
                            <p *ngIf="snvHotspotTrail.mutantBurdenPercent != null && !snvHotspotTrail.gene.includes('Wild type')">
                              Allelic burden: {{ snvHotspotTrail.mutantBurdenPercent }}%
                            </p>
                            <span *ngIf="snvHotspotTrail.copyNumber!=null && snvHotspotTrail.copyNumber.length>0"> {{ snvHotspotTrail.copyNumber }}
                          </span>
                        </span>
                        <span *ngIf="snvHotspotTrail?.multiVariant!=null"><br/>
                        <span  style="font-size: 14px !important" [innerHtml]="snvHotspotTrail?.multiVariant"></span>
                      </span></span>
                      </td>
                      <td>
                        <table class="table first_table w-100">
                          <thead class="border-bottom cust_clr">
                            <tr>
                              <th class="w-50">
                                <span class="font-weight-normal"><i>Therapy</i></span>
                              </th>
                              <th class="w-33">
                                <span class="font-weight-normal"><i>Clinical Relevance</i></span>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngIf="snvHotspotTrail.sameCancerType == null">
                              <td class="w-50">NA</td>
                              <td class="w-50">NA</td>
                            </tr>

                            <ng-container *ngIf="snvHotspotTrail.sameCancerType != null">
                              <tr *ngFor="
                                                                          let item of snvHotspotTrail.sameTypeRelevantData
                                                                        ">
                                <td>{{ item.therapy }}</td>
                                <td>{{ item.association }}</td>
                                <!-- <td>{{item.cancerType}}</td> -->
                              </tr>
                            </ng-container>
                          </tbody>
                        </table>
                      </td>
                      <td>
                        <table class="table first_table w-100">
                          <thead class="border-bottom cust_clr">
                            <tr>
                              <th class="w-33">
                                <span class="font-weight-normal"><i>Therapy</i></span>
                              </th>
                              <th class="w-33">
                                <span class="font-weight-normal"><i>Clinical Relevance</i></span>
                              </th>
                              <th class="w-33">
                                <span class="font-weight-normal"><i>Cancer Type</i></span>
                              </th>
                            </tr>
                          </thead>
                          <tbody>

                            <ng-container *ngIf="snvHotspotTrail.otherCancerType != null">
                              <tr *ngFor="
                                                                          let item of snvHotspotTrail.otherTypeRelevantData
                                                                        ">
                                <td>{{ item.therapy }}</td>
                                <td>{{ item.association }}</td>
                                <td>{{ item.cancerType }}</td>
                              </tr>
                            </ng-container>
                            <tr *ngIf="snvHotspotTrail.otherCancerType == null">
                              <td class="w-33">NA</td>
                              <td class="w-33">NA</td>
                              <td class="w-33">NA</td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>


                  </ng-container>
                </table>
                <p *ngIf="
                                        snvHotSpot.length != 0 ||
                                        cnaHotSpot.length != 0 ||
                                                            fusionHotSpot.length !=0
                                                          " style="font-size: 13px !important" class="mt-0">
                  <i> *NA: Not Applicable</i>
                </p>
              </div>
            </div>





            <!--  -->
            <div class="row footer">
              <div class="col-12">
                <div class="pb-2 pl-4 text">
                  <div class="d-block">
                    <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
                  </div>
                  <div class="footer_body" [ngClass]="'gene-bg_blue'">
                    <div class="footer_list">
                      <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
                      <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
                      <span>Gin - Q</span><span>Gly - G</span><span>His - H</span>
                      <span>Ile - I</span>
                    </div>
                    <div class="footer_list">
                      <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
                      <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
                      <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
                      <span>Val - V</span>
                    </div>
                  </div>
                </div>
                <div class="col-12 pt-1 ">
                  <p style="font-size: 8px!important; line-height: 14px!important;">
                    {{footerDiscription}}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <!-- page 1_2 -->
          <div id="page1_2" *ngIf="
                  prognasticCount==0 || prognasticCount>0 ||
                  geneMutationlist != null ||  report.disclaimer != '<p></p>' &&
                  (report.disclaimer != '' &&
                  report.disclaimer != null &&
                  (otherCountSNV == false &&
                  reportPdfRes.allVariantsList.length == 0)
                  )" class="container position-relative page"
            style="background-color: white">
            <div class="row header mt-1 py-4">
              <div>
                <div class="col-3">
                  <img src="../../../assets/logo.png" alt="" style="width: 140px" />
                </div>
              </div>
              <div></div>
              <div class="col">
                <div class="patient-detailes ml-auto float-right">
                  <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
                    <li>
                      Patient Id <br />
                      <span>{{ report.patient?.ecrfId }} </span>
                    </li>
                    <li class="ml-4">
                      Cancer Site<br />
                      <span>{{ report.cancerType }}</span>
                    </li>
                    <li class="ml-4">
                      SAMPLE ID<br />
                      <span>{{ report.labDetails?.sampleId }}</span>
                    </li>
                    <li class="ml-4">
                      Registration Date<br />
                      <span>{{
                        report.labDetails?.sampleCollected | date : "dd/MM/yyyy"
                        }}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <!--  -->
              <div *ngIf="report.clientType == '4basecare_client'">
                <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
                  </div>
                </div>
                <div *ngIf="report.clientName == 'path_labs'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
                  </div>
                </div>
              </div>
            </div>
            <div class="row" *ngIf=" (reportPdfRes.allHotspotTrailList.length>3 && reportPdfRes.allHotspotTrailList.length<=6) || reportPdfRes?.allHotspotTrailList.length>8">
              <div style="width: 100%;" *ngIf="isReportDownload">
                <img [src]="firstPageTable2" alt="" width="100%" class="img-fluid" />
              </div>
              <div class="col-12" id="firstPageTable2" *ngIf="!isReportDownload">
                <table class="page_1 cust_table w-100 mt-3 first_1" [ngClass]="'table_skyblue'">
                  <tr>
                    <th>Genomic Alteration</th>
                    <th>Relevant Therapies (in Same Cancer Type)</th>
                    <th>Relevant Therapies (in Different Cancer)</th>
                  </tr>

                  <tr *ngIf="tableCount== 0 && prognasticCount>0 ">
                    <td colspan="3" class="text-center mt-2">
                      Clinically relevant genomic alterations associated with therapeutic significance were not detected.
                    </td>
                  </tr>
                  <tr *ngIf="tableCount== 0 && prognasticCount == 0">
                    <td colspan="3" class="text-center mt-2">
                      Clinically relevant genomic alterations associated with both therapeutic and prognostic significance were
                      not detected.
                    </td>
                  </tr>

                  <!-- SNV -->
                  <ng-container *ngIf="reportPdfRes?.allHotspotTrailList.length<=6">
                    <tr *ngFor="
                                                                let snvHotspotTrail of reportPdfRes?.allHotspotTrailList  | slice : 3 : 6 ; let i=index
                                                              ">
                      <td>
                        <i>{{ snvHotspotTrail.gene }}</i>
                        &nbsp;<span *ngIf="!snvHotspotTrail.gene.includes('Wild type')">
                        <span *ngIf="snvHotspotTrail?.multiVariant==null">
                          <span *ngIf="snvHotspotTrail.exonNumber != null || snvHotspotTrail.pposition != null && snvHotspotTrail.pposition != ''">

                            <span *ngIf="snvHotspotTrail.exonNumber != null">{{snvHotspotTrail.exonNumber}}</span>&nbsp;
                            <span *ngIf="snvHotspotTrail.pposition != null && snvHotspotTrail.pposition != ''">({{snvHotspotTrail.pposition?.replace(' ','')}})</span></span>
                            <p *ngIf="snvHotspotTrail.mutantBurdenPercent != null && !snvHotspotTrail.gene.includes('Wild type')">
                              Allelic burden: {{ snvHotspotTrail.mutantBurdenPercent }}%
                            </p>
                            <span *ngIf="snvHotspotTrail.copyNumber!=null && snvHotspotTrail.copyNumber.length>0"> {{ snvHotspotTrail.copyNumber }}
                          </span>
                        </span>
                        <span *ngIf="snvHotspotTrail?.multiVariant!=null"><br/>
                        <span  style="font-size: 14px !important" [innerHtml]="snvHotspotTrail?.multiVariant"></span>
                      </span></span>
                      </td>
                      <td>
                        <table class="table first_table w-100">
                          <thead class="border-bottom cust_clr">
                            <tr>
                              <th class="w-50">
                                <span class="font-weight-normal"><i>Therapy</i></span>
                              </th>
                              <th class="w-33">
                                <span class="font-weight-normal"><i>Clinical Relevance</i></span>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngIf="snvHotspotTrail.sameCancerType == null">
                              <td class="w-50">NA</td>
                              <td class="w-50">NA</td>
                            </tr>

                            <ng-container *ngIf="snvHotspotTrail.sameCancerType != null">
                              <tr *ngFor="
                                                                          let item of snvHotspotTrail.sameTypeRelevantData
                                                                        ">
                                <td>{{ item.therapy }}</td>
                                <td>{{ item.association }}</td>
                                <!-- <td>{{item.cancerType}}</td> -->
                              </tr>
                            </ng-container>
                          </tbody>
                        </table>
                      </td>
                      <td>
                        <table class="table first_table w-100">
                          <thead class="border-bottom cust_clr">
                            <tr>
                              <th class="w-33">
                                <span class="font-weight-normal"><i>Therapy</i></span>
                              </th>
                              <th class="w-33">
                                <span class="font-weight-normal"><i>Clinical Relevance</i></span>
                              </th>
                              <th class="w-33">
                                <span class="font-weight-normal"><i>Cancer Type</i></span>
                              </th>
                            </tr>
                          </thead>
                          <tbody>

                            <ng-container *ngIf="snvHotspotTrail.otherCancerType != null">
                              <tr *ngFor="
                                                                          let item of snvHotspotTrail.otherTypeRelevantData
                                                                        ">
                                <td>{{ item.therapy }}</td>
                                <td>{{ item.association }}</td>
                                <td>{{ item.cancerType }}</td>
                              </tr>
                            </ng-container>
                            <tr *ngIf="snvHotspotTrail.otherCancerType == null">
                              <td class="w-33">NA</td>
                              <td class="w-33">NA</td>
                              <td class="w-33">NA</td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>


                  </ng-container>
                  <ng-container *ngIf="reportPdfRes?.allHotspotTrailList.length>8">
                    <tr *ngFor="
                                                                let snvHotspotTrail of reportPdfRes?.allHotspotTrailList  | slice : 8 : 12 ; let i=index
                                                              ">
                      <td>
                        <i>{{ snvHotspotTrail.gene }}</i>
                        &nbsp;  <span *ngIf="!snvHotspotTrail.gene.includes('Wild type')">                <span *ngIf="snvHotspotTrail.exonNumber != null || snvHotspotTrail.pposition != null && snvHotspotTrail.pposition != ''">

                        <span *ngIf="snvHotspotTrail.exonNumber != null">{{snvHotspotTrail.exonNumber}}</span>&nbsp;
                        <span
                          *ngIf="snvHotspotTrail.pposition != null && snvHotspotTrail.pposition != ''">({{snvHotspotTrail.pposition?.replace(' ','')}})</span></span>
                        <p *ngIf="snvHotspotTrail.mutantBurdenPercent != null && !snvHotspotTrail.gene.includes('Wild type')">
                          Allelic burden: {{ snvHotspotTrail.mutantBurdenPercent }}%
                        </p>
                        <span *ngIf="snvHotspotTrail.copyNumber!=null && snvHotspotTrail.copyNumber.length>0"> {{ snvHotspotTrail.copyNumber }}
                        </span></span>
                      </td>
                      <td>
                        <table class="table first_table w-100">
                          <thead class="border-bottom cust_clr">
                            <tr>
                              <th class="w-50">
                                <span class="font-weight-normal"><i>Therapy</i></span>
                              </th>
                              <th class="w-33">
                                <span class="font-weight-normal"><i>Clinical Relevance</i></span>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngIf="snvHotspotTrail.sameCancerType == null">
                              <td class="w-50">NA</td>
                              <td class="w-50">NA</td>
                            </tr>

                            <ng-container *ngIf="snvHotspotTrail.sameCancerType != null">
                              <tr *ngFor="
                                                                          let item of snvHotspotTrail.sameTypeRelevantData
                                                                        ">
                                <td>{{ item.therapy }}</td>
                                <td>{{ item.association }}</td>
                                <!-- <td>{{item.cancerType}}</td> -->
                              </tr>
                            </ng-container>
                          </tbody>
                        </table>
                      </td>
                      <td>
                        <table class="table first_table w-100">
                          <thead class="border-bottom cust_clr">
                            <tr>
                              <th class="w-33">
                                <span class="font-weight-normal"><i>Therapy</i></span>
                              </th>
                              <th class="w-33">
                                <span class="font-weight-normal"><i>Clinical Relevance</i></span>
                              </th>
                              <th class="w-33">
                                <span class="font-weight-normal"><i>Cancer Type</i></span>
                              </th>
                            </tr>
                          </thead>
                          <tbody>

                            <ng-container *ngIf="snvHotspotTrail.otherCancerType != null">
                              <tr *ngFor="
                                                                          let item of snvHotspotTrail.otherTypeRelevantData
                                                                        ">
                                <td>{{ item.therapy }}</td>
                                <td>{{ item.association }}</td>
                                <td>{{ item.cancerType }}</td>
                              </tr>
                            </ng-container>
                            <tr *ngIf="snvHotspotTrail.otherCancerType == null">
                              <td class="w-33">NA</td>
                              <td class="w-33">NA</td>
                              <td class="w-33">NA</td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>


                  </ng-container>
                </table>
                <p *ngIf="
                                        snvHotSpot.length != 0 ||
                                        cnaHotSpot.length != 0 ||
                                                            fusionHotSpot.length !=0
                                                          " style="font-size: 13px !important" class="mt-0">
                  <i> *NA: Not Applicable</i>
                </p>
              </div>
            </div>
            <div *ngIf="(tableCount>0) &&
              prognasticCount==0">
              <h6 class="py-2 h6_bg"> Prognostic Biomarkers</h6>
            </div>
            <div class="row" *ngIf="(tableCount>0 )&&
              prognasticCount==0">
              <div class="col-12">
                <table class="page_1 cust_table w-100 mt-3 varient_details prognasticbiomarkertable"
                  [ngClass]="'table_variant-blue'">
                  <thead>
                    <tr>
                      <th>Genomic Alteration</th>
                      <th>Prognostic Significance</th>
                    </tr>
                  </thead>
                  <ng-container>
                    <tr>
                      <td colspan="2" style="text-align:center">
                        Clinically relevant genomic alterations associated with prognostic significance were not detected.
                      </td>
                    </tr>
                  </ng-container>
                </table>
              </div>
            </div>
            <div *ngIf="prognasticCount>0">
              <h6 class="py-2 h6_bg"> Prognostic Biomarkers</h6>
            </div>

            <div class="row" *ngIf="prognasticCount>0">
              <div class="col-12">
                <table class="page_1 cust_table w-100 mt-3 varient_details prognasticbiomarkertable"
                  [ngClass]="'table_variant-blue'">
                  <thead>
                    <tr>
                      <th>Genomic Alteration</th>
                      <th>Prognostic Significance</th>

                    </tr>
                  </thead>
                  <ng-container *ngFor="let prog of reportPdfRes.prognosticBiomarkers">
                    <tr>
                      <td style="padding-left: 3px !important">
                        <i>{{ prog.gene }}&nbsp; </i>
                        <span [innerHtml]="prog.geneDetails"></span>
                      </td>
                      <td style="padding-left: 3px !important;">{{prog.statement}}
                      </td>
                    </tr>
                  </ng-container>


                </table>
              </div>
            </div>

            <!-- gene Data -->
            <div *ngIf="geneMutationlist != null && isStatusDisplay">
              <div class="mt-3">
                <h6 class="py-2 h6_bg" [ngClass]="'skyBlue'">
                  Status of variants in cancer related Biomarkers
                </h6>
              </div>

              <table class="staus_gene bg_skyblue" *ngIf="geneMutationlist != null" [ngClass]="{
                                                                      biomarker_table:
                                                                        geneMutationlist.length >= 4 &&
                                                                        geneMutationlist.length < 8
                                                                    }">
                <tr style="background: #daedf7">
                  <td style="font-size: 18px !important; padding-left: 10px !important">
                    Gene
                  </td>
                  <td *ngFor="
                                                                          let geneMutation of geneMutationlist
                                                                            | slice : 0 : 8
                                                                        ">
                    <i>{{ geneMutation.gene }}</i>
                  </td>
                </tr>
                <tr>
                  <td style="font-size: 18px !important; padding-left: 10px !important">
                    <b>Status</b>
                  </td>
                  <td *ngFor="
                                                                          let geneMutation of geneMutationlist
                                                                            | slice : 0 : 8
                                                                        ">
                    <span *ngIf="
                                                                            geneMutation.status == 'Pathogenic' ||
                                                                            geneMutation.status == 'Likely Pathogenic' ||
                                                                            geneMutation.status == 'Detected'
                                                                          " style="color: red; font-weight: 600px"><b>{{
                        geneMutation.status }}</b></span>
                    <span *ngIf="geneMutation.status == 'VUS'" style="color: black; font-weight: 600px"><b>{{
                        geneMutation.status }}</b></span>
                    <span *ngIf="
                                                                            geneMutation.status == 'Not Detected' ||
                                                                            geneMutation.status == 'Benige'
                                                                          ">{{ geneMutation.status }}</span>
                  </td>
                </tr>
<br/>
              </table>
              <table class="staus_gene" [ngClass]="'bg_skyblue'" *ngIf="
                                                                      geneMutationlist != null &&
                                                                      geneMutationlist.length > 8
                                                                    ">
                <tr style="background: #daedf7">
                  <td style="font-size: 18px !important; padding-left: 10px !important">
                    Gene
                  </td>
                  <td *ngFor="
                                                                          let geneMutation of geneMutationlist
                                                                            | slice : 8 : 17
                                                                        ">
                    <i>{{ geneMutation.gene }}</i>
                  </td>
                </tr>
                <tr>
                  <td style="font-size: 18px !important; padding-left: 10px !important">
                    <b>Status</b>
                  </td>
                  <td *ngFor="
                                                                          let geneMutation of geneMutationlist
                                                                            | slice : 8 : 17
                                                                        ">
                    <span *ngIf="
                                                                            geneMutation.status == 'Pathogenic' ||
                                                                            geneMutation.status == 'Likely Pathogenic' ||
                                                                            geneMutation.status == 'Detected'
                                                                          " style="color: red; font-weight: 600px"><b>{{
                        geneMutation.status }}</b></span>
                    <span *ngIf="geneMutation.status == 'VUS'" style="color: black; font-weight: 600px"><b>{{
                        geneMutation.status }}</b></span>
                    <span *ngIf="
                                                                            geneMutation.status == 'Not Detected' ||
                                                                            geneMutation.status == 'Benige'
                                                                          ">{{ geneMutation.status }}</span>
                  </td>
                </tr>
<br/>
              </table>
              <table
                class="staus_gene"
                [ngClass]="'bg_skyblue'"
                *ngIf="
                  geneMutationlist != null &&
                  geneMutationlist.length > 17
                "
              >
                <tr style="background: #daedf7">
                  <td
                    style="font-size: 18px !important; padding-left: 10px !important"
                  >
                    Gene
                  </td>
                  <td
                    *ngFor="
                      let geneMutation of geneMutationlist
                        | slice : 17 : 25
                    "
                  >
                    <i>{{ geneMutation.gene }}</i>
                  </td>
                </tr>
                <tr>
                  <td
                    style="font-size: 18px !important; padding-left: 10px !important"
                  >
                    <b>Status</b>
                  </td>
                  <td
                    *ngFor="
                      let geneMutation of geneMutationlist
                        | slice : 17 : 25
                    "
                  >
                    <span
                      *ngIf="
                        geneMutation.status == 'Pathogenic' ||
                        geneMutation.status == 'Likely Pathogenic' ||
                        geneMutation.status == 'Detected'
                      "
                      style="color: red; font-weight: 600px"
                      ><b>{{ geneMutation.status }}</b></span
                    >
                    <span
                      *ngIf="geneMutation.status == 'VUS'"
                      style="color: black; font-weight: 600px"
                      ><b>{{ geneMutation.status }}</b></span
                    >
                    <span
                      *ngIf="
                        geneMutation.status == 'Not Detected' ||
                        geneMutation.status == 'Benige'
                      "
                      >{{ geneMutation.status }}</span
                    >
                  </td>
                </tr>
<br/>
              </table>
              <table class="staus_gene" [ngClass]="'bg_skyblue'" *ngIf="
                    geneMutationlist != null &&
                    geneMutationlist.length > 25
                  ">
                <tr style="background: #daedf7">
                  <td style="font-size: 18px !important; padding-left: 10px !important">
                    Gene
                  </td>
                  <td *ngFor="
                        let geneMutation of geneMutationlist
                          | slice : 25 : 33
                      ">
                    <i>{{ geneMutation.gene }}</i>
                  </td>
                </tr>
                <tr>
                  <td style="font-size: 18px !important; padding-left: 10px !important">
                    <b>Status</b>
                  </td>
                  <td *ngFor="
                        let geneMutation of geneMutationlist
                          | slice : 25 : 33
                      ">
                    <span *ngIf="
                          geneMutation.status == 'Pathogenic' ||
                          geneMutation.status == 'Likely Pathogenic' ||
                          geneMutation.status == 'Detected'
                        " style="color: red; font-weight: 600px"><b>{{ geneMutation.status }}</b></span>
                    <span *ngIf="geneMutation.status == 'VUS'" style="color: black; font-weight: 600px"><b>{{
                        geneMutation.status }}</b></span>
                    <span *ngIf="
                          geneMutation.status == 'Not Detected' ||
                          geneMutation.status == 'Benige'
                        ">{{ geneMutation.status }}</span>
                  </td>
                </tr>
<br/>
              </table>
          <table class="staus_gene" [ngClass]="'bg_skyblue'" *ngIf="
                geneMutationlist != null &&
                geneMutationlist.length > 33
              ">
            <tr style="background: #daedf7">
              <td style="font-size: 18px !important; padding-left: 10px !important">
                Gene
              </td>
              <td *ngFor="
                    let geneMutation of geneMutationlist
                      | slice : 33 : 41
                  ">
                <i>{{ geneMutation.gene }}</i>
              </td>
            </tr>
            <tr>
              <td style="font-size: 18px !important; padding-left: 10px !important">
                <b>Status</b>
              </td>
              <td *ngFor="
                    let geneMutation of geneMutationlist
                      | slice : 33 : 41
                  ">
                <span *ngIf="
                      geneMutation.status == 'Pathogenic' ||
                      geneMutation.status == 'Likely Pathogenic' ||
                      geneMutation.status == 'Detected'
                    " style="color: red; font-weight: 600px"><b>{{ geneMutation.status }}</b></span>
                <span *ngIf="geneMutation.status == 'VUS'" style="color: black; font-weight: 600px"><b>{{
                    geneMutation.status }}</b></span>
                <span *ngIf="
                      geneMutation.status == 'Not Detected' ||
                      geneMutation.status == 'Benige'
                    ">{{ geneMutation.status }}</span>
              </td>
            </tr>
            <br/>
          </table>
              <h6 *ngIf="
                                      (fusionGeneMutationList != null &&
                                      fusionGeneMutationList.length > 0) "
                class="font-weight-bold clr-cyan text-uppercase" [ngClass]="'txt-skyblue'">
                Gene Fusion
              </h6>
              <table class="staus_gene" *ngIf="
                                                                      (fusionGeneMutationList != null &&
                                                                      fusionGeneMutationList.length > 0)
                                                                    " [ngClass]="'bg_skyblue'">
                <tr style="background: #daedf7">
                  <td style="font-size: 18px !important; padding-left: 10px !important">
                    Gene
                  </td>
                  <td *ngFor="
                                                                          let geneMutation of fusionGeneMutationList
                                                                            | slice : 0 : 8
                                                                        ">
                    <i>{{ geneMutation.gene }}</i>
                  </td>
                </tr>
                <tr>
                  <td style="font-size: 18px !important; padding-left: 10px !important">
                    <b>Status</b>
                  </td>
                  <td *ngFor="
                                                                          let geneMutation of fusionGeneMutationList
                                                                            | slice : 0 : 8
                                                                        ">
                    <span *ngIf="
                                                                            geneMutation.status == 'Pathogenic' ||
                                                                            geneMutation.status == 'Likely Pathogenic' ||
                                                                            geneMutation.status == 'Detected'
                                                                          " style="color: red; font-weight: 600px"><b>{{
                        geneMutation.status }}</b></span>
                    <span *ngIf="geneMutation.status == 'VUS'" style="color: black; font-weight: 600px"><b>{{
                        geneMutation.status }}</b></span>
                    <span *ngIf="
                                                                            geneMutation.status == 'Not Detected' ||
                                                                            geneMutation.status == 'Benige'
                                                                          ">{{ geneMutation.status }}</span>
                  </td>
                </tr>
<br/>
              </table>
              <table class="staus_gene" *ngIf="
                                                                      (fusionGeneMutationList != null &&
                                                                      fusionGeneMutationList.length > 8)
                                                                    " [ngClass]="'bg_skyblue'">
                <tr style="background: #daedf7">
                  <td style="font-size: 18px !important; padding-left: 10px !important">
                    Gene
                  </td>
                  <td *ngFor="
                                                                          let geneMutation of fusionGeneMutationList
                                                                            | slice : 8 : 17
                                                                        ">
                    <i>{{ geneMutation.gene }}</i>
                  </td>
                </tr>
                <tr>
                  <td style="font-size: 18px !important; padding-left: 10px !important">
                    <b>Status</b>
                  </td>
                  <td *ngFor="
                                                                          let geneMutation of fusionGeneMutationList
                                                                            | slice : 8 : 17
                                                                        ">
                    <span *ngIf="
                                                                            geneMutation.status == 'Pathogenic' ||
                                                                            geneMutation.status == 'Likely Pathogenic' ||
                                                                            geneMutation.status == 'Detected'
                                                                          " style="color: red; font-weight: 600px"><b>{{
                        geneMutation.status }}</b></span>
                    <span *ngIf="geneMutation.status == 'VUS'" style="color: black; font-weight: 600px"><b>{{
                        geneMutation.status }}</b></span>
                    <span *ngIf="
                                                                            geneMutation.status == 'Not Detected' ||
                                                                            geneMutation.status == 'Benige'
                                                                          ">{{ geneMutation.status }}</span>
                  </td>
                </tr>
              </table>
            </div>

            <div *ngIf="
                          report.disclaimerNote != ' ' &&
                          report.disclaimerNote != null
                        ">

              <h6 style="color: #208dcb"><b>Note: </b></h6>
              <div style="font-size: 14px !important;text-align: justify" [innerHtml]="report.disclaimerNote"></div>
            </div>
            <!--  -->




            <!--  -->
            <div class="row footer">
              <div class="col-12">
                <div class="pb-2 pl-4 text">
                  <div class="d-block">
                    <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
                  </div>
                  <div class="footer_body" [ngClass]="'gene-bg_blue'">
                    <div class="footer_list">
                      <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
                      <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
                      <span>Gin - Q</span><span>Gly - G</span><span>His - H</span>
                      <span>Ile - I</span>
                    </div>
                    <div class="footer_list">
                      <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
                      <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
                      <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
                      <span>Val - V</span>
                    </div>
                  </div>
                </div>
                <div class="col-12 pt-1 ">
                  <p style="font-size: 8px!important; line-height: 14px!important;">
                    {{footerDiscription}}
                  </p>
                </div>
              </div>
            </div>
          </div>



          <!-- page 2 -->
          <div id="page2" *ngIf=" reportPdfRes.allVariantsList.length>0 || (reportPdfRes.allVariantsList.length<13 && report.addNotes != null &&
          report.addNotes != '<div></div>')" class="container position-relative page" style="background-color: white">
            <div class="row header mt-1 py-4">
              <div>
                <div class="col-3">
                  <img src="../../../assets/logo.png" alt="" style="width: 140px" />
                </div>
              </div>
              <div></div>
              <div class="col">
                <div class="patient-detailes ml-auto float-right">
                  <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
                    <li>
                      Patient Id <br />
                      <span>{{ report.patient?.ecrfId }} </span>
                    </li>
                    <li class="ml-4">
                      Cancer Site<br />
                      <span>{{ report.cancerType }}</span>
                    </li>
                    <li class="ml-4">
                      SAMPLE ID<br />
                      <span>{{ report.labDetails?.sampleId }}</span>
                    </li>
                    <li class="ml-4">
                      Registration Date<br />
                      <span>{{
                        report.labDetails?.sampleCollected | date : "dd/MM/yyyy"
                        }}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <!--  -->
              <div *ngIf="report.clientType == '4basecare_client'">
                <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
                  </div>
                </div>
                <div *ngIf="report.clientName == 'path_labs'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
                  </div>
                </div>
              </div>
            </div>

            <!--  -->

            <div class="row mt-3" *ngIf="
                                                        otherCountSNV == true ||
                                                        reportPdfRes.allVariantsList.length != 0
                                                      ">
              <div class="col">
                <h6 class="font-weight-bold clr-cyan text-uppercase" [ngClass]="'txt-skyblue'">
                  Variant Details:
                </h6>
              </div>
            </div>

            <div class="row" *ngIf="
                                                        otherCountSNV == true ||
                                                        reportPdfRes.allVariantsList.length != 0
                                                      ">
              <div class="col-12">
                <table class="page_1 cust_table w-100 mt-3 varient_details" [ngClass]="'table_variant-blue'">
                  <thead>
                    <tr>
                      <th>Gene</th>
                      <th>Variant Location</th>
                      <th>Variant Consequence</th>
                      <th>Clinical Significance</th>
                      <th>Variant Type</th>
                      <th>Reference</th>
                    </tr>
                  </thead>

                  <tr *ngFor="let variant of reportPdfRes.allVariantsList | slice : 0 : 15;
                  let i = index">
                    <td *ngIf="variant.significance != 'NA' && variant.significance != 'Not Classified'" style="padding-left: 3px !important">
                      <i>{{ variant.gene }}</i>
                    </td>
                    <td *ngIf="variant.significance != 'NA' && variant.significance != 'Not Classified'" style="padding-left: 3px !important">
                      <span *ngIf="variant?.variantType=='snv'">
                        {{ variant?.genomicAlteration }},<br />
                        {{ variant?.ensembleValue }},<br />
                        {{ variant?.exonNumber }}
                      </span>
                      <span *ngIf="variant?.variantType=='cna'">
                        chr{{ variant?.sample?.split(":")[0] }}:g.{{
                          variant.sample
                          .split(":")[1]
                          .replace("-", "_")
                          .split("|")[0]
                          }}
                      </span>
                      <span *ngIf="variant.variantType=='fusion'">
                        {{ variant?.leftBreakPoint }}<br />{{variant?.rightBreakPoint}}
                      </span>
                    </td>
                    <td *ngIf="variant?.significance != 'NA' && variant?.significance != 'Not Classified'" style="padding-left: 3px !important">
                      <span *ngIf="variant.variantType=='snv'">
                        <span *ngIf="variant?.selectedAAChangeknownGene">{{
                          variant?.selectedAAChangeknownGene|splitComma }}</span>, {{variant?.mutantBurdenPercent * 0.01 | percent}}
                        </span>
                      <span *ngIf="variant.variantType=='cna'">NA</span>
                      <span *ngIf="variant.variantType=='fusion'">NA</span>
                    </td>
                    <td *ngIf="variant?.significance != 'NA' && variant?.significance != 'Not Classified'" style="padding-left: 3px !important">
                      {{ variant?.significance }}
                    </td>
                    <td *ngIf="variant?.significance != 'NA' && variant?.significance != 'Not Classified'" style="padding-left: 3px !important">
                      <span *ngIf="variant.variantType=='snv'">
                        {{ variant?.exonicFuncknownGene }}
                      </span>
                      <span *ngIf="variant.variantType=='cna'">
                        Copy Number
                        {{ variant?.sample.split(":")[2] | titlecase }}
                        ({{ variant?.sample.split("|")[1].split(":")[0] }})
                      </span>
                      <span *ngIf="variant.variantType=='fusion'">Gene Fusions</span>
                    </td>
                    <td *ngIf="variant?.significance != 'NA' && variant?.significance != 'Not Classified'" style="padding-left: 3px !important">
                      {{ variant?.reference }}
                    </td>
                  </tr>
                </table>
                <p *ngIf="reportPdfRes.allVariantsList.length<13 " style="font-size: 13px !important" class="mt-0">
                  <i> *NA: Not Applicable</i>
                </p>
              </div>
            </div>
            <!--  -->
            <div class="row mt-5" *ngIf="reportPdfRes.allVariantsList.length<13 &&
            !variantFlag &&
            report.addNotes != null && report.addNotes != '<div></div>'
          ">
    <div class="col">
      <h6 class="py-2 h6_bg">Additional Notes</h6>
      <div class="py-2" [innerHtml]="report.addNotes" style="text-align: justify; font-size: 14px !important"></div>
    </div>
  </div>

            <!--  -->
            <div class="row footer">
              <div class="col-12">
                <div class="pb-2 pl-4 text">
                  <div class="d-block">
                    <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
                  </div>
                  <div class="footer_body" [ngClass]="'gene-bg_blue'">
                    <div class="footer_list">
                      <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
                      <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
                      <span>Gin - Q</span><span>Gly - G</span><span>His - H</span>
                      <span>Ile - I</span>
                    </div>
                    <div class="footer_list">
                      <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
                      <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
                      <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
                      <span>Val - V</span>
                    </div>
                  </div>
                </div>
                <div class="col-12 pt-1 ">
                  <p style="font-size: 8px!important; line-height: 14px!important;">
                    {{footerDiscription}}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <!-- page2_1 -->
          <div id="page2_1" *ngIf=" reportPdfRes.allVariantsList.length>15 || (reportPdfRes.allVariantsList.length>=13 && report.addNotes != null &&
            report.addNotes != '<div></div>')" class="container position-relative page" style="background-color: white">
            <div class="row header mt-1 py-4">
              <div>
                <div class="col-3">
                  <img src="../../../assets/logo.png" alt="" style="width: 140px" />
                </div>
              </div>
              <div></div>
              <div class="col">
                <div class="patient-detailes ml-auto float-right">
                  <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
                    <li>
                      Patient Id <br />
                      <span>{{ report.patient?.ecrfId }} </span>
                    </li>
                    <li class="ml-4">
                      Cancer Site<br />
                      <span>{{ report.cancerType }}</span>
                    </li>
                    <li class="ml-4">
                      SAMPLE ID<br />
                      <span>{{ report.labDetails?.sampleId }}</span>
                    </li>
                    <li class="ml-4">
                      Registration Date<br />
                      <span>{{
                        report.labDetails?.sampleCollected | date : "dd/MM/yyyy"
                        }}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <!--  -->
              <div *ngIf="report.clientType == '4basecare_client'">
                <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
                  </div>
                </div>
                <div *ngIf="report.clientName == 'path_labs'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
                  </div>
                </div>
              </div>
            </div>

            <!--  -->

            <div class="row mt-3" *ngIf="
                                                        reportPdfRes.allVariantsList.length>15
                                                      ">
              <div class="col">
                <h6 class="font-weight-bold clr-cyan text-uppercase" [ngClass]="'txt-skyblue'">
                  Variant Details:
                </h6>
              </div>
            </div>

            <div class="row" *ngIf="
                                                        reportPdfRes.allVariantsList.length>15
                                                      ">
              <div class="col-12">
                <table class="page_1 cust_table w-100 mt-3 varient_details" [ngClass]="'table_variant-blue'">
                  <thead>
                    <tr>
                      <th>Gene</th>
                      <th>Variant Location</th>
                      <th>Variant Consequence</th>
                      <th>Clinical Significance</th>
                      <th>Variant Type</th>
                      <th>Reference</th>
                    </tr>
                  </thead>

                  <tr *ngFor="let variant of reportPdfRes.allVariantsList | slice : 15 : 30;
                  let i = index">
                    <td *ngIf="variant.significance != 'NA' && variant.significance != 'Not Classified'" style="padding-left: 3px !important">
                      <i>{{ variant.gene }}</i>
                    </td>
                    <td *ngIf="variant.significance != 'NA' && variant.significance != 'Not Classified'" style="padding-left: 3px !important">
                      <span *ngIf="variant?.variantType=='snv'">
                        {{ variant?.genomicAlteration }},<br />
                        {{ variant?.ensembleValue }},<br />
                        {{ variant?.exonNumber }}
                      </span>
                      <span *ngIf="variant?.variantType=='cna'">
                        chr{{ variant?.sample?.split(":")[0] }}:g.{{
                          variant.sample
                          .split(":")[1]
                          .replace("-", "_")
                          .split("|")[0]
                          }}
                      </span>
                      <span *ngIf="variant.variantType=='fusion'">
                        {{ variant?.leftBreakPoint }}<br />{{variant?.rightBreakPoint}}
                      </span>
                    </td>
                    <td *ngIf="variant?.significance != 'NA' && variant?.significance != 'Not Classified'" style="padding-left: 3px !important">
                      <span *ngIf="variant.variantType=='snv'">
                        <span *ngIf="variant?.selectedAAChangeknownGene">{{
                          variant?.selectedAAChangeknownGene|splitComma }}</span>, {{variant?.mutantBurdenPercent * 0.01 | percent}}
                        </span>
                      <span *ngIf="variant.variantType=='cna'">NA</span>
                      <span *ngIf="variant.variantType=='fusion'">NA</span>
                    </td>
                    <td *ngIf="variant?.significance != 'NA' && variant?.significance != 'Not Classified'" style="padding-left: 3px !important">
                      {{ variant?.significance }}
                    </td>
                    <td *ngIf="variant?.significance != 'NA' && variant?.significance != 'Not Classified'" style="padding-left: 3px !important">
                      <span *ngIf="variant.variantType=='snv'">
                        {{ variant?.exonicFuncknownGene }}
                      </span>
                      <span *ngIf="variant.variantType=='cna'">
                        Copy Number
                        {{ variant?.sample.split(":")[2] | titlecase }}
                        ({{ variant?.sample.split("|")[1].split(":")[0] }})
                      </span>
                      <span *ngIf="variant.variantType=='fusion'">Gene Fusions</span>
                    </td>
                    <td *ngIf="variant?.significance != 'NA' && variant?.significance != 'Not Classified'" style="padding-left: 3px !important">
                      {{ variant?.reference }}
                    </td>
                  </tr>
                </table>
                <p *ngIf="reportPdfRes.allVariantsList.length > 0 " style="font-size: 13px !important" class="mt-0">
                  <i> *NA: Not Applicable</i>
                </p>
              </div>
            </div>
            <!--  -->
            <div class="row mt-5" *ngIf="
            !variantFlag &&
            report.addNotes != null && report.addNotes != '<div></div>'
          ">
    <div class="col">
      <h6 class="py-2 h6_bg">Additional Notes</h6>
      <div class="py-2" [innerHtml]="report.addNotes" style="text-align: justify; font-size: 14px !important"></div>
    </div>
  </div>

            <!--  -->
            <div class="row footer">
              <div class="col-12">
                <div class="pb-2 pl-4 text">
                  <div class="d-block">
                    <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
                  </div>
                  <div class="footer_body" [ngClass]="'gene-bg_blue'">
                    <div class="footer_list">
                      <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
                      <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
                      <span>Gin - Q</span><span>Gly - G</span><span>His - H</span>
                      <span>Ile - I</span>
                    </div>
                    <div class="footer_list">
                      <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
                      <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
                      <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
                      <span>Val - V</span>
                    </div>
                  </div>
                </div>
                <div class="col-12 pt-1 ">
                  <p style="font-size: 8px!important; line-height: 14px!important;">
                    {{footerDiscription}}
                  </p>
                </div>
              </div>
            </div>
          </div>


          <!-- page 3 -->
          <div id="page3" *ngIf="reportPdfRes.drugsResList.length > 0" class="container position-relative page"
            style="background-color: white">
            <div class="row header mt-1 py-4">
              <div>
                <div class="col-3">
                  <img src="../../../assets/logo.png" alt="" style="width: 140px" />
                </div>
              </div>
              <div></div>
              <div class="col">
                <div class="patient-detailes ml-auto float-right">
                  <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
                    <li>
                      Patient Id <br />
                      <span>{{ report.patient?.ecrfId }}</span>
                    </li>
                    <li class="ml-4">
                      Cancer Site<br />
                      <span>{{ report.cancerType }}</span>
                    </li>
                    <li class="ml-4">
                      SAMPLE ID<br />
                      <span>{{ report.labDetails?.sampleId }}</span>
                    </li>
                    <li class="ml-4">
                      Registration Date<br />
                      <span>{{
                        report.labDetails?.sampleCollected | date : "dd/MM/yyyy"
                        }}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <!--  -->
              <div *ngIf="report.clientType == '4basecare_client'">
                <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
                  </div>
                </div>
                <div *ngIf="report.clientName == 'path_labs'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
                  </div>
                </div>
              </div>
            </div>
            <!--  -->
            <div class="mar_top"></div>
            <!--  -->

            <h6 class="py-2 h6_bg" [ngClass]="'skyBlue'">
              Alterations With Clinically Relevant Therapies
            </h6>
            <!--  -->
            <div class="row">
              <div class="col-12">
                <table class="page_1 cust_table w-100 mt-3 first_2 custom-table" [ngClass]="'bg_cyan'">
                  <thead>
                    <tr>
                      <th>Gene (Alterations) - Drug association</th>
                      <th>Summary</th>
                    </tr>
                  </thead>
                  <!-- SNV -->

                  <tbody>
                    <tr *ngFor="
                                                                let drugsRes of reportPdfRes.drugsResList | slice : 0 : 4;
                                                                let i = index
                                                              ">
                      <td>
                        <ul class="ml-0 pl-2">
                          <li>
                            <i>{{ drugsRes.gene.replace("Fusion","") }}</i><span *ngIf="drugsRes.gene.includes('Fusion')">Fusion</span> {{ drugsRes.pposition?.replace(' ','') }} :
                            <br />
                            <span *ngFor="let drug of drugsRes.drugs; let i = index">
                              {{ drug.name
                              }}<span *ngIf="drugsRes.drugs.length - 1 != i">,</span>
                            </span>
                            - {{ drugsRes.association }}
                            <p>{{ drugsRes.cancerType }}</p>
                          </li>
                        </ul>
                      </td>
                      <td>
                        <div [innerHtml]="drugsRes.summary"></div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div class="row footer">
              <div class="col-12">
                <div class="pb-2 pl-4 text">
                  <div class="d-block">
                    <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
                  </div>
                  <div class="footer_body" [ngClass]="'gene-bg_blue'">
                    <div class="footer_list">
                      <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
                      <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
                      <span>Gin - Q</span><span>Gly - G</span><span>His - H</span>
                      <span>Ile - I</span>
                    </div>
                    <div class="footer_list">
                      <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
                      <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
                      <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
                      <span>Val - V</span>
                    </div>
                  </div>
                </div>
                <div class="col-12 pt-1 ">
                  <p style="font-size: 8px!important; line-height: 14px!important;">
                    {{footerDiscription}}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <!-- page 3_1 -->
          <div id="page3_1" *ngIf="reportPdfRes.drugsResList.length > 4" class="container position-relative page"
            style="background-color: white">
            <div class="row header mt-1 py-4">
              <div>
                <div class="col-3">
                  <img src="../../../assets/logo.png" alt="" style="width: 140px" />
                </div>
              </div>
              <div></div>
              <div class="col">
                <div class="patient-detailes ml-auto float-right">
                  <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
                    <li>
                      Patient Id <br />
                      <span>{{ report.patient?.ecrfId }}</span>
                    </li>
                    <li class="ml-4">
                      Cancer Site<br />
                      <span>{{ report.cancerType }}</span>
                    </li>
                    <li class="ml-4">
                      SAMPLE ID<br />
                      <span>{{ report.labDetails?.sampleId }}</span>
                    </li>
                    <li class="ml-4">
                      Registration Date<br />
                      <span>{{
                        report.labDetails?.sampleCollected | date : "dd/MM/yyyy"
                        }}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <!--  -->
              <div *ngIf="report.clientType == '4basecare_client'">
                <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
                  </div>
                </div>
                <div *ngIf="report.clientName == 'path_labs'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
                  </div>
                </div>
              </div>
            </div>
            <!--  -->
            <div class="mar_top"></div>
            <!--  -->

            <h6 class="py-2 h6_bg" [ngClass]="'skyBlue'">
              Alterations With Clinically Relevant Therapies
            </h6>
            <!--  -->
            <div class="row">
              <div class="col-12">
                <table class="page_1 cust_table w-100 mt-3 first_2 2nd_table">
                  <thead>
                    <tr>
                      <th>Gene (Alterations) - Drug association</th>
                      <th>Summary</th>
                    </tr>
                  </thead>
                  <!-- SNV -->

                  <tbody>
                    <tr *ngFor="
                                                                          let drugsRes of reportPdfRes.drugsResList | slice : 4 : 8;
                                                                          let i = index
                                                                        ">
                      <td>
                        <ul class="ml-0 pl-2">
                          <li>
                            <i>{{ drugsRes.gene.replace("Fusion","") }}</i><span *ngIf="drugsRes.gene.includes('Fusion')">Fusion</span> {{ drugsRes.pposition?.replace(' ','') }} :
                            <br />
                            <span *ngFor="let drug of drugsRes.drugs; let i = index">
                              {{ drug.name
                              }}<span *ngIf="drugsRes.drugs.length - 1 != i">,</span>
                            </span>
                            - {{ drugsRes.association }}
                            <p>{{ drugsRes.cancerType }}</p>
                          </li>
                        </ul>
                      </td>
                      <td>
                        <div [innerHtml]="drugsRes.summary"></div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <!--  -->

            <!--  -->
            <div class="row footer">
              <div class="col-12">
                <div class="pb-2 pl-4 text">
                  <div class="d-block">
                    <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
                  </div>
                  <div class="footer_body" [ngClass]="'gene-bg_blue'">
                    <div class="footer_list">
                      <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
                      <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
                      <span>Gin - Q</span><span>Gly - G</span><span>His - H</span>
                      <span>Ile - I</span>
                    </div>
                    <div class="footer_list">
                      <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
                      <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
                      <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
                      <span>Val - V</span>
                    </div>
                  </div>
                </div>
                <div class="col-12 pt-1 ">
                  <p style="font-size: 8px!important; line-height: 14px!important;">
                    {{footerDiscription}}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <!-- page 3_2 -->
          <div id="page3_2" *ngIf="reportPdfRes.drugsResList.length > 8" class="container position-relative page"
            style="background-color: white">
            <div class="row header mt-1 py-4">
              <div>
                <div class="col-3">
                  <img src="../../../assets/logo.png" alt="" style="width: 140px" />
                </div>
              </div>
              <div></div>
              <div class="col">
                <div class="patient-detailes ml-auto float-right">
                  <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
                    <li>
                      Patient Id <br />
                      <span>{{ report.patient?.ecrfId }}</span>
                    </li>
                    <li class="ml-4">
                      Cancer Site<br />
                      <span>{{ report.cancerType }}</span>
                    </li>
                    <li class="ml-4">
                      SAMPLE ID<br />
                      <span>{{ report.labDetails?.sampleId }}</span>
                    </li>
                    <li class="ml-4">
                      Registration Date<br />
                      <span>{{
                        report.labDetails?.sampleCollected | date : "dd/MM/yyyy"
                        }}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <!--  -->
              <div *ngIf="report.clientType == '4basecare_client'">
                <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
                  </div>
                </div>
                <div *ngIf="report.clientName == 'path_labs'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
                  </div>
                </div>
              </div>
            </div>
            <!--  -->
            <div class="mar_top"></div>
            <!--  -->

            <h6 class="py-2 h6_bg" [ngClass]="'skyBlue'">
              Alterations With Clinically Relevant Therapies
            </h6>
            <!--  -->
            <div class="row">
              <div class="col-12">
                <table class="page_1 cust_table w-100 mt-3 first_2 2nd_table">
                  <thead>
                    <tr>
                      <th>Gene (Alterations) - Drug association</th>
                      <th>Summary</th>
                    </tr>
                  </thead>
                  <!-- SNV -->

                  <tbody>
                    <tr *ngFor="
                                                                                              let drugsRes of reportPdfRes.drugsResList | slice : 8 : 12;
                                                                                              let i = index
                                                                                            ">
                      <td>
                        <ul class="ml-0 pl-2">
                          <li>
                            <i>{{ drugsRes.gene.replace("Fusion","") }}</i><span *ngIf="drugsRes.gene.includes('Fusion')">Fusion</span> {{ drugsRes.pposition?.replace(' ','')}} :
                            <br />
                            <span *ngFor="let drug of drugsRes.drugs; let i = index">
                              {{ drug.name
                              }}<span *ngIf="drugsRes.drugs.length - 1 != i">,</span>
                            </span>
                            - {{ drugsRes.association }}
                            <p>{{ drugsRes.cancerType }}</p>
                          </li>
                        </ul>
                      </td>
                      <td>
                        <div [innerHtml]="drugsRes.summary"></div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <!--  -->

            <!--  -->
            <div class="row footer">
              <div class="col-12">
                <div class="pb-2 pl-4 text">
                  <div class="d-block">
                    <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
                  </div>
                  <div class="footer_body" [ngClass]="'gene-bg_blue'">
                    <div class="footer_list">
                      <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
                      <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
                      <span>Gin - Q</span><span>Gly - G</span><span>His - H</span>
                      <span>Ile - I</span>
                    </div>
                    <div class="footer_list">
                      <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
                      <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
                      <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
                      <span>Val - V</span>
                    </div>
                  </div>
                </div>
                <div class="col-12 pt-1 ">
                  <p style="font-size: 8px!important; line-height: 14px!important;">
                    {{footerDiscription}}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <!-- page 3_3 -->
          <div id="page3_3" *ngIf="reportPdfRes.drugsResList.length > 12" class="container position-relative page"
            style="background-color: white">
            <div class="row header mt-1 py-4">
              <div>
                <div class="col-3">
                  <img src="../../../assets/logo.png" alt="" style="width: 140px" />
                </div>
              </div>
              <div></div>
              <div class="col">
                <div class="patient-detailes ml-auto float-right">
                  <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
                    <li>
                      Patient Id <br />
                      <span>{{ report.patient?.ecrfId }}</span>
                    </li>
                    <li class="ml-4">
                      Cancer Site<br />
                      <span>{{ report.cancerType }}</span>
                    </li>
                    <li class="ml-4">
                      SAMPLE ID<br />
                      <span>{{ report.labDetails?.sampleId }}</span>
                    </li>
                    <li class="ml-4">
                      Registration Date<br />
                      <span>{{
                        report.labDetails?.sampleCollected | date : "dd/MM/yyyy"
                        }}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <!--  -->
              <div *ngIf="report.clientType == '4basecare_client'">
                <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
                  </div>
                </div>
                <div *ngIf="report.clientName == 'path_labs'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
                  </div>
                </div>
              </div>
            </div>
            <!--  -->
            <div class="mar_top"></div>
            <!--  -->

            <h6 class="py-2 h6_bg" [ngClass]="'skyBlue'">
              Alterations With Clinically Relevant Therapies
            </h6>
            <!--  -->
            <div class="row">
              <div class="col-12">
                <table class="page_1 cust_table w-100 mt-3 first_2 2nd_table">
                  <thead>
                    <tr>
                      <th>Gene (Alterations) - Drug association</th>
                      <th>Summary</th>
                    </tr>
                  </thead>
                  <!-- SNV -->

                  <tbody>
                    <tr *ngFor="
                                                                                                                    let drugsRes of reportPdfRes.drugsResList | slice : 12 : 16;
                                                                                                                    let i = index
                                                                                                                  ">
                      <td>
                        <ul class="ml-0 pl-2">
                          <li>
                            <i>{{ drugsRes.gene.replace("Fusion","") }}</i><span *ngIf="drugsRes.gene.includes('Fusion')">Fusion</span> {{ drugsRes.pposition?.replace(' ','')}} :
                            <br />
                            <span *ngFor="let drug of drugsRes.drugs; let i = index">
                              {{ drug.name
                              }}<span *ngIf="drugsRes.drugs.length - 1 != i">,</span>
                            </span>
                            - {{ drugsRes.association }}
                            <p>{{ drugsRes.cancerType }}</p>
                          </li>
                        </ul>
                      </td>
                      <td>
                        <div [innerHtml]="drugsRes.summary"></div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <!--  -->

            <!--  -->
            <div class="row footer">
              <div class="col-12">
                <div class="pb-2 pl-4 text">
                  <div class="d-block">
                    <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
                  </div>
                  <div class="footer_body" [ngClass]="'gene-bg_blue'">
                    <div class="footer_list">
                      <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
                      <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
                      <span>Gin - Q</span><span>Gly - G</span><span>His - H</span>
                      <span>Ile - I</span>
                    </div>
                    <div class="footer_list">
                      <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
                      <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
                      <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
                      <span>Val - V</span>
                    </div>
                  </div>
                </div>
                <div class="col-12 pt-1 ">
                  <p style="font-size: 8px!important; line-height: 14px!important;">
                    {{footerDiscription}}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <!-- page 3_4 -->
          <div id="page3_4" *ngIf="reportPdfRes.drugsResList.length > 16" class="container position-relative page"
            style="background-color: white">
            <div class="row header mt-1 py-4">
              <div>
                <div class="col-3">
                  <img src="../../../assets/logo.png" alt="" style="width: 140px" />
                </div>
              </div>
              <div></div>
              <div class="col">
                <div class="patient-detailes ml-auto float-right">
                  <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
                    <li>
                      Patient Id <br />
                      <span>{{ report.patient?.ecrfId }}</span>
                    </li>
                    <li class="ml-4">
                      Cancer Site<br />
                      <span>{{ report.cancerType }}</span>
                    </li>
                    <li class="ml-4">
                      SAMPLE ID<br />
                      <span>{{ report.labDetails?.sampleId }}</span>
                    </li>
                    <li class="ml-4">
                      Registration Date<br />
                      <span>{{
                        report.labDetails?.sampleCollected | date : "dd/MM/yyyy"
                        }}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <!--  -->
              <div *ngIf="report.clientType == '4basecare_client'">
                <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
                  </div>
                </div>
                <div *ngIf="report.clientName == 'path_labs'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
                  </div>
                </div>
              </div>
            </div>
            <!--  -->
            <div class="mar_top"></div>
            <!--  -->

            <h6 class="py-2 h6_bg" [ngClass]="'skyBlue'">
              Alterations With Clinically Relevant Therapies
            </h6>
            <!--  -->
            <div class="row">
              <div class="col-12">
                <table class="page_1 cust_table w-100 mt-3 first_2 2nd_table">
                  <thead>
                    <tr>
                      <th>Gene (Alterations) - Drug association</th>
                      <th>Summary</th>
                    </tr>
                  </thead>
                  <!-- SNV -->

                  <tbody>
                    <tr *ngFor="
                                                                                                          let drugsRes of reportPdfRes.drugsResList | slice : 16 : 20;
                                                                                                          let i = index
                                                                                                        ">
                      <td>
                        <ul class="ml-0 pl-2">
                          <li>
                            <i>{{ drugsRes.gene.replace("Fusion","") }}</i><span *ngIf="drugsRes.gene.includes('Fusion')">Fusion</span> {{ drugsRes.pposition?.replace(' ','')}} :
                            <br />
                            <span *ngFor="let drug of drugsRes.drugs; let i = index">
                              {{ drug.name
                              }}<span *ngIf="drugsRes.drugs.length - 1 != i">,</span>
                            </span>
                            - {{ drugsRes.association }}
                            <p>{{ drugsRes.cancerType }}</p>
                          </li>
                        </ul>
                      </td>
                      <td>
                        <div [innerHtml]="drugsRes.summary"></div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <!--  -->

            <!--  -->
            <div class="row footer">
              <div class="col-12">
                <div class="pb-2 pl-4 text">
                  <div class="d-block">
                    <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
                  </div>
                  <div class="footer_body" [ngClass]="'gene-bg_blue'">
                    <div class="footer_list">
                      <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
                      <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
                      <span>Gin - Q</span><span>Gly - G</span><span>His - H</span>
                      <span>Ile - I</span>
                    </div>
                    <div class="footer_list">
                      <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
                      <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
                      <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
                      <span>Val - V</span>
                    </div>
                  </div>
                </div>
                <div class="col-12 pt-1 ">
                  <p style="font-size: 8px!important; line-height: 14px!important;">
                    {{footerDiscription}}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <!-- page 3_5 -->
          <div id="page3_5" *ngIf="reportPdfRes.drugsResList.length > 20" class="container position-relative page"
            style="background-color: white">
            <div class="row header mt-1 py-4">
              <div>
                <div class="col-3">
                  <img src="../../../assets/logo.png" alt="" style="width: 140px" />
                </div>
              </div>
              <div></div>
              <div class="col">
                <div class="patient-detailes ml-auto float-right">
                  <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
                    <li>
                      Patient Id <br />
                      <span>{{ report.patient?.ecrfId }}</span>
                    </li>
                    <li class="ml-4">
                      Cancer Site<br />
                      <span>{{ report.cancerType }}</span>
                    </li>
                    <li class="ml-4">
                      SAMPLE ID<br />
                      <span>{{ report.labDetails?.sampleId }}</span>
                    </li>
                    <li class="ml-4">
                      Registration Date<br />
                      <span>{{
                        report.labDetails?.sampleCollected | date : "dd/MM/yyyy"
                        }}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <!--  -->
              <div *ngIf="report.clientType == '4basecare_client'">
                <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
                  </div>
                </div>
                <div *ngIf="report.clientName == 'path_labs'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
                  </div>
                </div>
              </div>
            </div>
            <!--  -->
            <div class="mar_top"></div>
            <!--  -->

            <h6 class="py-2 h6_bg" [ngClass]="'skyBlue'">
              Alterations With Clinically Relevant Therapies
            </h6>
            <!--  -->
            <div class="row">
              <div class="col-12">
                <table class="page_1 cust_table w-100 mt-3 first_2 2nd_table">
                  <thead>
                    <tr>
                      <th>Gene (Alterations) - Drug association</th>
                      <th>Summary</th>
                    </tr>
                  </thead>
                  <!-- SNV -->

                  <tbody>
                    <tr
                      *ngFor="
                      let drugsRes of reportPdfRes.drugsResList | slice : 20 : 24; let i = index ">
                      <td>
                        <ul class="ml-0 pl-2">
                          <li>
                            <i>{{ drugsRes.gene.replace("Fusion","") }}</i><span *ngIf="drugsRes.gene.includes('Fusion')">Fusion</span> {{ drugsRes.pposition?.replace(' ','')}} :
                            <br />
                            <span *ngFor="let drug of drugsRes.drugs; let i = index">
                              {{ drug.name
                              }}<span *ngIf="drugsRes.drugs.length - 1 != i">,</span>
                            </span>
                            - {{ drugsRes.association }}
                            <p>{{ drugsRes.cancerType }}</p>
                          </li>
                        </ul>
                      </td>
                      <td>
                        <div [innerHtml]="drugsRes.summary"></div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <!--  -->

            <!--  -->
            <div class="row footer">
              <div class="col-12">
                <div class="pb-2 pl-4 text">
                  <div class="d-block">
                    <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
                  </div>
                  <div class="footer_body" [ngClass]="'gene-bg_blue'">
                    <div class="footer_list">
                      <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
                      <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
                      <span>Gin - Q</span><span>Gly - G</span><span>His - H</span>
                      <span>Ile - I</span>
                    </div>
                    <div class="footer_list">
                      <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
                      <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
                      <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
                      <span>Val - V</span>
                    </div>
                  </div>
                </div>
                <div class="col-12 pt-1 ">
                  <p style="font-size: 8px!important; line-height: 14px!important;">
                    {{footerDiscription}}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <!-- page 3_6 -->
          <div id="page3_6" *ngIf="reportPdfRes.drugsResList.length > 24" class="container position-relative page"
            style="background-color: white">
            <div class="row header mt-1 py-4">
              <div>
                <div class="col-3">
                  <img src="../../../assets/logo.png" alt="" style="width: 140px" />
                </div>
              </div>
              <div></div>
              <div class="col">
                <div class="patient-detailes ml-auto float-right">
                  <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
                    <li>
                      Patient Id <br />
                      <span>{{ report.patient?.ecrfId }}</span>
                    </li>
                    <li class="ml-4">
                      Cancer Site<br />
                      <span>{{ report.cancerType }}</span>
                    </li>
                    <li class="ml-4">
                      SAMPLE ID<br />
                      <span>{{ report.labDetails?.sampleId }}</span>
                    </li>
                    <li class="ml-4">
                      Registration Date<br />
                      <span>{{
                        report.labDetails?.sampleCollected | date : "dd/MM/yyyy"
                        }}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <!--  -->
              <div *ngIf="report.clientType == '4basecare_client'">
                <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
                  </div>
                </div>
                <div *ngIf="report.clientName == 'path_labs'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
                  </div>
                </div>
              </div>
            </div>
            <!--  -->
            <div class="mar_top"></div>
            <!--  -->

            <h6 class="py-2 h6_bg" [ngClass]="'skyBlue'">
              Alterations With Clinically Relevant Therapies
            </h6>
            <!--  -->
            <div class="row">
              <div class="col-12">
                <table class="page_1 cust_table w-100 mt-3 first_2 2nd_table">
                  <thead>
                    <tr>
                      <th>Gene (Alterations) - Drug association</th>
                      <th>Summary</th>
                    </tr>
                  </thead>
                  <!-- SNV -->

                  <tbody>
                    <tr
                      *ngFor=" let drugsRes of reportPdfRes.drugsResList | slice : 24 : 28; let i = index ">
                      <td>
                        <ul class="ml-0 pl-2">
                          <li>
                            <i>{{ drugsRes.gene.replace("Fusion","") }}</i><span *ngIf="drugsRes.gene.includes('Fusion')">Fusion</span> {{ drugsRes.pposition?.replace(' ','')}} :
                            <br />
                            <span *ngFor="let drug of drugsRes.drugs; let i = index">
                              {{ drug.name
                              }}<span *ngIf="drugsRes.drugs.length - 1 != i">,</span>
                            </span>
                            - {{ drugsRes.association }}
                            <p>{{ drugsRes.cancerType }}</p>
                          </li>
                        </ul>
                      </td>
                      <td>
                        <div [innerHtml]="drugsRes.summary"></div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <!--  -->

            <!--  -->
            <div class="row footer">
              <div class="col-12">
                <div class="pb-2 pl-4 text">
                  <div class="d-block">
                    <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
                  </div>
                  <div class="footer_body" [ngClass]="'gene-bg_blue'">
                    <div class="footer_list">
                      <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
                      <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
                      <span>Gin - Q</span><span>Gly - G</span><span>His - H</span>
                      <span>Ile - I</span>
                    </div>
                    <div class="footer_list">
                      <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
                      <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
                      <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
                      <span>Val - V</span>
                    </div>
                  </div>
                </div>
                <div class="col-12 pt-1 ">
                  <p style="font-size: 8px!important; line-height: 14px!important;">
                    {{footerDiscription}}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <!-- page 3_7 -->
          <div id="page3_7" *ngIf="reportPdfRes.drugsResList.length > 28" class="container position-relative page"
            style="background-color: white">
            <div class="row header mt-1 py-4">
              <div>
                <div class="col-3">
                  <img src="../../../assets/logo.png" alt="" style="width: 140px" />
                </div>
              </div>
              <div></div>
              <div class="col">
                <div class="patient-detailes ml-auto float-right">
                  <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
                    <li>
                      Patient Id <br />
                      <span>{{ report.patient?.ecrfId }}</span>
                    </li>
                    <li class="ml-4">
                      Cancer Site<br />
                      <span>{{ report.cancerType }}</span>
                    </li>
                    <li class="ml-4">
                      SAMPLE ID<br />
                      <span>{{ report.labDetails?.sampleId }}</span>
                    </li>
                    <li class="ml-4">
                      Registration Date<br />
                      <span>{{
                        report.labDetails?.sampleCollected | date : "dd/MM/yyyy"
                        }}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <!--  -->
              <div *ngIf="report.clientType == '4basecare_client'">
                <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
                  </div>
                </div>
                <div *ngIf="report.clientName == 'path_labs'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
                  </div>
                </div>
              </div>
            </div>
            <!--  -->
            <div class="mar_top"></div>
            <!--  -->

            <h6 class="py-2 h6_bg" [ngClass]="'skyBlue'">
              Alterations With Clinically Relevant Therapies
            </h6>
            <!--  -->
            <div class="row">
              <div class="col-12">
                <table class="page_1 cust_table w-100 mt-3 first_2 2nd_table">
                  <thead>
                    <tr>
                      <th>Gene (Alterations) - Drug association</th>
                      <th>Summary</th>
                    </tr>
                  </thead>
                  <!-- SNV -->

                  <tbody>
                    <tr
                      *ngFor="
                                                                                                                                                                          let drugsRes of reportPdfRes.drugsResList | slice : 28 : 32;
                                                                                                                                                                          let i = index
                                                                                                                                                                        ">
                      <td>
                        <ul class="ml-0 pl-2">
                          <li>
                            <i>{{ drugsRes.gene.replace("Fusion","") }}</i><span *ngIf="drugsRes.gene.includes('Fusion')">Fusion</span> {{ drugsRes.pposition?.replace(' ','')}} :
                            <br />
                            <span *ngFor="let drug of drugsRes.drugs; let i = index">
                              {{ drug.name
                              }}<span *ngIf="drugsRes.drugs.length - 1 != i">,</span>
                            </span>
                            - {{ drugsRes.association }}
                            <p>{{ drugsRes.cancerType }}</p>
                          </li>
                        </ul>
                      </td>
                      <td>
                        <div [innerHtml]="drugsRes.summary"></div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <!--  -->

            <!--  -->
            <div class="row footer">
              <div class="col-12">
                <div class="pb-2 pl-4 text">
                  <div class="d-block">
                    <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
                  </div>
                  <div class="footer_body" [ngClass]="'gene-bg_blue'">
                    <div class="footer_list">
                      <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
                      <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
                      <span>Gin - Q</span><span>Gly - G</span><span>His - H</span>
                      <span>Ile - I</span>
                    </div>
                    <div class="footer_list">
                      <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
                      <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
                      <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
                      <span>Val - V</span>
                    </div>
                  </div>
                </div>
                <div class="col-12 pt-1 ">
                  <p style="font-size: 8px!important; line-height: 14px!important;">
                    {{footerDiscription}}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <!-- page 3_8 -->
          <div id="page3_8" *ngIf="reportPdfRes.drugsResList.length > 32" class="container position-relative page"
            style="background-color: white">
            <div class="row header mt-1 py-4">
              <div>
                <div class="col-3">
                  <img src="../../../assets/logo.png" alt="" style="width: 140px" />
                </div>
              </div>
              <div></div>
              <div class="col">
                <div class="patient-detailes ml-auto float-right">
                  <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
                    <li>
                      Patient Id <br />
                      <span>{{ report.patient?.ecrfId }}</span>
                    </li>
                    <li class="ml-4">
                      Cancer Site<br />
                      <span>{{ report.cancerType }}</span>
                    </li>
                    <li class="ml-4">
                      SAMPLE ID<br />
                      <span>{{ report.labDetails?.sampleId }}</span>
                    </li>
                    <li class="ml-4">
                      Registration Date<br />
                      <span>{{
                        report.labDetails?.sampleCollected | date : "dd/MM/yyyy"
                        }}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <!--  -->
              <div *ngIf="report.clientType == '4basecare_client'">
                <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
                  </div>
                </div>
                <div *ngIf="report.clientName == 'path_labs'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
                  </div>
                </div>
              </div>
            </div>
            <!--  -->
            <div class="mar_top"></div>
            <!--  -->

            <h6 class="py-2 h6_bg" [ngClass]="'skyBlue'">
              Alterations With Clinically Relevant Therapies
            </h6>
            <!--  -->
            <div class="row">
              <div class="col-12">
                <table class="page_1 cust_table w-100 mt-3 first_2 2nd_table">
                  <thead>
                    <tr>
                      <th>Gene (Alterations) - Drug association</th>
                      <th>Summary</th>
                    </tr>
                  </thead>
                  <!-- SNV -->

                  <tbody>
                    <tr *ngFor=" let drugsRes of reportPdfRes.drugsResList | slice : 32 : 36; let i = index ">
                      <td>
                        <ul class="ml-0 pl-2">
                          <li>
                            <i>{{ drugsRes.gene.replace("Fusion","") }}</i><span *ngIf="drugsRes.gene.includes('Fusion')">Fusion</span> {{ drugsRes.pposition?.replace(' ','')}} :
                            <br />
                            <span *ngFor="let drug of drugsRes.drugs; let i = index">
                              {{ drug.name
                              }}<span *ngIf="drugsRes.drugs.length - 1 != i">,</span>
                            </span>
                            - {{ drugsRes.association }}
                            <p>{{ drugsRes.cancerType }}</p>
                          </li>
                        </ul>
                      </td>
                      <td>
                        <div [innerHtml]="drugsRes.summary"></div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <!--  -->

            <!--  -->
            <div class="row footer">
              <div class="col-12">
                <div class="pb-2 pl-4 text">
                  <div class="d-block">
                    <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
                  </div>
                  <div class="footer_body" [ngClass]="'gene-bg_blue'">
                    <div class="footer_list">
                      <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
                      <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
                      <span>Gin - Q</span><span>Gly - G</span><span>His - H</span>
                      <span>Ile - I</span>
                    </div>
                    <div class="footer_list">
                      <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
                      <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
                      <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
                      <span>Val - V</span>
                    </div>
                  </div>
                </div>
                <div class="col-12 pt-1 ">
                  <p style="font-size: 8px!important; line-height: 14px!important;">
                    {{footerDiscription}}
                  </p>
                </div>
              </div>
            </div>
          </div>

                    <!-- page 3_9 -->
                    <div id="page3_9" *ngIf="reportPdfRes.drugsResList.length > 36" class="container position-relative page"
                      style="background-color: white">
                      <div class="row header mt-1 py-4">
                        <div>
                          <div class="col-3">
                            <img src="../../../assets/logo.png" alt="" style="width: 140px" />
                          </div>
                        </div>
                        <div></div>
                        <div class="col">
                          <div class="patient-detailes ml-auto float-right">
                            <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
                              <li>
                                Patient Id <br />
                                <span>{{ report.patient?.ecrfId }}</span>
                              </li>
                              <li class="ml-4">
                                Cancer Site<br />
                                <span>{{ report.cancerType }}</span>
                              </li>
                              <li class="ml-4">
                                SAMPLE ID<br />
                                <span>{{ report.labDetails?.sampleId }}</span>
                              </li>
                              <li class="ml-4">
                                Registration Date<br />
                                <span>{{
                                  report.labDetails?.sampleCollected | date : "dd/MM/yyyy"
                                  }}</span>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <!--  -->
                        <div *ngIf="report.clientType == '4basecare_client'">
                          <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
                            <div class="logo">
                              <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
                            </div>
                          </div>
                          <div *ngIf="report.clientName == 'path_labs'" class="col-3">
                            <div class="logo">
                              <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <!--  -->
                      <div class="mar_top"></div>
                      <!--  -->

                      <h6 class="py-2 h6_bg" [ngClass]="'skyBlue'">
                        Alterations With Clinically Relevant Therapies
                      </h6>
                      <!--  -->
                      <div class="row">
                        <div class="col-12">
                          <table class="page_1 cust_table w-100 mt-3 first_2 2nd_table">
                            <thead>
                              <tr>
                                <th>Gene (Alterations) - Drug association</th>
                                <th>Summary</th>
                              </tr>
                            </thead>
                            <!-- SNV -->

                            <tbody>
                              <tr *ngFor=" let drugsRes of reportPdfRes.drugsResList | slice : 36 : 40; let i = index ">
                                <td>
                                  <ul class="ml-0 pl-2">
                                    <li>
                                      <i>{{ drugsRes.gene.replace("Fusion","") }}</i><span *ngIf="drugsRes.gene.includes('Fusion')">Fusion</span> {{ drugsRes.pposition?.replace(' ','')}} :
                                      <br />
                                      <span *ngFor="let drug of drugsRes.drugs; let i = index">
                                        {{ drug.name
                                        }}<span *ngIf="drugsRes.drugs.length - 1 != i">,</span>
                                      </span>
                                      - {{ drugsRes.association }}
                                      <p>{{ drugsRes.cancerType }}</p>
                                    </li>
                                  </ul>
                                </td>
                                <td>
                                  <div [innerHtml]="drugsRes.summary"></div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <!--  -->

                      <!--  -->
                      <div class="row footer">
                        <div class="col-12">
                          <div class="pb-2 pl-4 text">
                            <div class="d-block">
                              <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
                            </div>
                            <div class="footer_body" [ngClass]="'gene-bg_blue'">
                              <div class="footer_list">
                                <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
                                <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
                                <span>Gin - Q</span><span>Gly - G</span><span>His - H</span>
                                <span>Ile - I</span>
                              </div>
                              <div class="footer_list">
                                <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
                                <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
                                <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
                                <span>Val - V</span>
                              </div>
                            </div>
                          </div>
                          <div class="col-12 pt-1 ">
                            <p style="font-size: 8px!important; line-height: 14px!important;">
                              {{footerDiscription}}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

          <!-- page 4 -->
          <div id="page4" *ngIf="prognasticCount>0" class="container position-relative page" style="background-color: white">
            <div class="row header mt-1 py-4">
              <div>
                <div class="col-3">
                  <img src="../../../assets/logo.png" alt="" style="width: 140px" />
                </div>
              </div>
              <div></div>
              <div class="col">
                <div class="patient-detailes ml-auto float-right">
                  <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
                    <li>
                      Patient Id <br />
                      <span>{{ report.patient?.ecrfId }}</span>
                    </li>
                    <li class="ml-4">
                      Cancer Site<br />
                      <span>{{ report.cancerType }}</span>
                    </li>
                    <li class="ml-4">
                      SAMPLE ID<br />
                      <span>{{ report.labDetails?.sampleId }}</span>
                    </li>
                    <li class="ml-4">
                      Registration Date<br />
                      <span>{{
                        report.labDetails?.sampleCollected | date : "dd/MM/yyyy"
                        }}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <!--  -->
              <div *ngIf="report.clientType == '4basecare_client'">
                <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
                  </div>
                </div>
                <div *ngIf="report.clientName == 'path_labs'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
                  </div>
                </div>
              </div>
            </div>
            <!--  -->
            <div class="mar_top"></div>
            <!--  -->
            <div *ngIf="prognasticCount>0">
              <div>
                <h6 class="py-2 h6_bg"> Alterations associated with prognostic significance</h6>

              </div>

              <div class="row">
                <div class="col-12">
                  <table class="page_1 cust_table w-100 mt-3 varient_details prognastictable" [ngClass]="'table_variant-blue'">
                    <thead>
                      <tr>
                        <th>Gene (Alterations)</th>
                        <th>Prognostic Significance</th>
                        <th>Summary</th>

                      </tr>
                    </thead>

                    <ng-container *ngFor=" let prog of reportPdfRes.prognosticAlterations ">
                      <tr>
                        <td style="padding-left: 3px !important">
                          <i>{{ prog.gene }}&nbsp;</i> <span [innerHtml]="prog.pOrcDot"></span>
                        </td>
                        <td style="padding-left: 3px !important">
                          {{prog.prognosticSignificance}}
                        </td>
                        <td style="padding-left: 3px !important">
                          <span [innerHtml]="prog.description"></span>
                        </td>

                      </tr>
                    </ng-container>



                  </table>
                </div>
              </div>
            </div>


            <!--  -->
            <div class="row footer">
              <div class="col-12">
                <div class="pb-2 pl-4 text">
                  <div class="d-block">
                    <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
                  </div>
                  <div class="footer_body" [ngClass]="'gene-bg_blue'">
                    <div class="footer_list">
                      <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
                      <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
                      <span>Gin - Q</span><span>Gly - G</span><span>His - H</span>
                      <span>Ile - I</span>
                    </div>
                    <div class="footer_list">
                      <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
                      <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
                      <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
                      <span>Val - V</span>
                    </div>
                  </div>
                </div>
                <div class="col-12 pt-1 ">
                  <p style="font-size: 8px!important; line-height: 14px!important;">
                    {{footerDiscription}}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <!-- Page 5 -->
          <div id="page5" class="container position-relative page" style="background-color: white">
            <div class="row header mt-1 py-4">
              <div>
                <div class="col-3">
                  <img src="../../../assets/logo.png" alt="" style="width: 140px" />
                </div>
              </div>
              <div></div>
              <div class="col">
                <div class="patient-detailes ml-auto float-right">
                  <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
                    <li>
                      Patient Id <br />
                      <span>{{ report.patient?.ecrfId }}</span>
                    </li>
                    <li class="ml-4">
                      Cancer Site<br />
                      <span>{{ report.cancerType }}</span>
                    </li>
                    <li class="ml-4">
                      SAMPLE ID<br />
                      <span>{{ report.labDetails?.sampleId }}</span>
                    </li>
                    <li class="ml-4">
                      Registration Date<br />
                      <span>{{
                        report.labDetails?.sampleCollected | date : "dd/MM/yyyy"
                        }}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <!--  -->
              <div *ngIf="report.clientType == '4basecare_client'">
                <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
                  </div>
                </div>
                <div *ngIf="report.clientName == 'path_labs'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
                  </div>
                </div>
              </div>
            </div>
            <!--  -->
            <div class="mar_top"></div>
            <!--  -->

            <h6 class="py-2 h6_bg" [ngClass]="'skyBlue'">References</h6>
            <!--  -->
            <div class="row">
              <div class="col">
                <div class="disc">
                  <div class="ref_data mt-4">
                    <ul class="break-word" style="text-align: justify">
                      <li *ngFor="let referenceArticle of reportPdfRes.referenceArticles" style="
                                                                  text-align: justify;
                                                                  /* white-space:pre-wrap; */
                                                                  word-break: break-word;
                                                                ">
                        {{ referenceArticle }}
                      </li>
                    </ul>
                  </div>
                </div>

              </div>
            </div>
            <div class="img_signs above-footer">
              <!-- <div class="col" *ngIf="isReportDownload">
                <img [src]="imgsings" alt="" class="img-fluid w-90" />
              </div> -->
              <div class="row" id="imgsings">
                <div class="col">

                  <p style="
                        text-align: center;

                        font-size: 15px !important;
                      ">(Electronically signed by)
                  </p>
                  <p style="
                          text-align: center;
                          font-weight: bolder;
                          font-size: 15px !important;
                        ">
                    Vidya H Veldore, PhD<br />Vice President - Clinical Operations
                  </p>
                </div>
                <div class="col">
                  <p style="text-align: center; font-size: 15px !important;">
                    (Electronically signed by)</p>
                    <p style="
                    text-align: center;
                    font-weight: bolder;
                    font-size: 15px !important;
                  ">
                  Dr. Jinumary Aji John<br />Head - Clinical Reporting </p>
                </div>
                <div class="col">
                  <p style="
                    text-align: center;

                    font-size: 15px !important;
                  ">(Electronically signed by)
                  </p>
                  <p style="
                          text-align: center;
                          font-weight: bolder;
                          font-size: 15px !important;
                        ">
                    Vyomesh Javle<br />Head - Clinical Bioinformatics
                  </p>
                </div>
              </div>
            </div>
                    <!--  -->
                    <div class="row footer">
                      <div class="col-12">
                        <div class="pb-2 pl-4 text">
                          <div class="d-block">
                            <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
                          </div>
                          <div class="footer_body" [ngClass]="'gene-bg_blue'">
                            <div class="footer_list">
                              <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
                              <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
                              <span>Gin - Q</span><span>Gly - G</span><span>His - H</span>
                              <span>Ile - I</span>
                            </div>
                            <div class="footer_list">
                              <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
                              <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
                              <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
                              <span>Val - V</span>
                            </div>
                          </div>
                        </div>
                        <div class="col-12 pt-1 ">
                          <p style="font-size: 8px!important; line-height: 14px!important;">
                            {{footerDiscription}}
                          </p>
                        </div>
                      </div>
                    </div>
          </div>

          <!-- Page 6 -->
          <div id="page6" class="container position-relative page" style="background-color: white">
            <div class="row header mt-1 py-4">
              <div>
                <div class="col-3">
                  <img src="../../../assets/logo.png" alt="" style="width: 140px" />
                </div>
              </div>
              <div></div>
              <div class="col">
                <div class="patient-detailes ml-auto float-right">
                  <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
                    <li>
                      Patient Id <br />
                      <span>{{ report.patient?.ecrfId }}</span>
                    </li>
                    <li class="ml-4">
                      Cancer Site<br />
                      <span>{{ report.cancerType }}</span>
                    </li>
                    <li class="ml-4">
                      SAMPLE ID<br />
                      <span>{{ report.labDetails?.sampleId }}</span>
                    </li>
                    <li class="ml-4">
                      Registration Date<br />
                      <span>{{
                        report.labDetails?.sampleCollected | date : "dd/MM/yyyy"
                        }}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <!--  -->
              <div *ngIf="report.clientType == '4basecare_client'">
                <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
                  </div>
                </div>
                <div *ngIf="report.clientName == 'path_labs'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
                  </div>
                </div>
              </div>
            </div>
            <!--  -->
            <div class="mar_top"></div>
            <!--  -->

            <div class="">
              <h6 class="py-2 h6_bg" [ngClass]="'skyBlue'">TEST DESCRIPTION</h6>
              <p class="mb-2" style="text-align:justify">
                <span class="font-weight-normal">TAR</span><b>GT</b>
                <span class="clr-cyan pl-2" [ngClass]="'txt-skyblue'">First Solid</span>
                is a Next Generation Sequencing based test which identifies
                genetic alterations in a comprehensive panel of well curated 72  genes which are having an impact response to approved
                therapy for a particular cancer type. Some of the alterations
                detected may have bearing on prognosis and/or therapeutic options
                and may provide relevant information that allows
                oncologists/clinicians to consider various lines of
                targeted treatment for the patient.
              </p>
            </div>

            <!--  -->
            <div class="row">
              <div class="col">
                <div class="">
                  <h6 class="py-2 h6_bg" [ngClass]="'skyBlue'">GENES EVALUATED</h6>
                  <p>
                    <span class="font-weight-normal">TAR</span><b>GT</b>
                    <span class="clr-cyan pl-2" [ngClass]="'txt-skyblue'">First Solid</span>
                    detects mutations (SNVs and Short InDels), Copy Number Variations (CNVs),
                    Gene Fusions and splice variants in the 72 genes:
                    <!-- detects mutations (SNVs and Short Indels), Copy Number Variations
                                                          (CNVs), gene fusions and splice variants in the 53 genes : -->
                  </p>
                </div>
                <!--  -->
                <div class="row mx-0">
                  <div class="col-12 px-0">
                    <div class="genes_info">
                      <div class="title py-2">
                        <h6 class="mb-0 d-inline-block border-bottom py-2">
                          <b>SNVs, SHORT INDELS and CNVs
                          </b> Covered in
                          <span class="font-weight-normal">TAR</span><b>GT</b>
                          <span class="clr-cyan pl-2" [ngClass]="'txt-skyblue'">First Solid</span>
                        </h6>
                      </div>
                      <div class="gene_body" [ngClass]="'gene-bg_blue'">
                        <i>
                          <div class="gene_list">
                            <span>ABL1</span><span>ALK</span><span>APC</span>
                            <span>AR</span><span>ATM</span><span>BARD1</span>
                            <span>BMPR1A</span><span>BRAF</span><span>BRCA1</span>
                            <span>BRCA2</span>
                            <!-- <span>ABL1</span><span>ALK</span><span>AR</span
                                                                  ><span>ATM</span><span>BARD1</span><span>BRAF</span
                                                                  ><span>BRCA1</span><span>BRCA2</span><span>BRIP1</span
                                                                  ><span>CDK12</span> -->
                          </div>
                          <div class="gene_list bg-white">
                            <span>BRIP1</span><span>CDK12</span><span>CDK4</span>
                            <span>CDK6</span><span>CDKN2A</span><span>CHEK1</span>
                            <span>CHEK2</span><span>CTNNB1</span><span>EGFR</span>
                            <span>EPCAM</span>
                            <!-- <span>CDK4</span><span>CDK6</span><span>CDKN2A</span
                                                                  ><span>CHEK1</span><span>CHEK2</span><span>EGFR</span
                                                                  ><span>EPCAM</span><span>ERBB2</span><span>ERBB3</span
                                                                  ><span>EZH2</span> -->
                          </div>
                          <div class="gene_list">
                            <span>ERBB2</span><span>ERBB3</span><span>EZH2</span>
                            <span>FANCL</span><span>FGFR1</span><span>FGFR2</span>
                            <span>FGFR3</span><span>GAPDH</span><span>IDH1</span>
                            <span>IDH2</span>
                            <!-- <span>FANCL</span><span>FGFR3</span><span>GAPDH</span
                                                                  ><span>IDH1</span><span>IDH2</span><span>JAK2</span
                                                                  ><span>KIT</span><span>KRAS</span><span>MAP2K1</span
                                                                  ><span>MAP2K2</span> -->
                          </div>
                          <div class="gene_list">
                            <span>JAK2</span><span>KIT</span><span>KRAS</span>
                            <span>MAP2K1</span><span>MAP2K2</span><span>MDM2</span>
                            <span>MET</span><span>MLH1</span><span>MLH3</span>
                            <span>MSH2</span>
                            <!-- <span>MDM2</span><span>MET</span><span>MLH1</span
                                                                  ><span>MLH3</span><span>MSH2</span><span>MSH6</span
                                                                  ><span>NRAS</span><span>PALB2</span><span>PDGFRA</span
                                                                  ><span>PDGFRB</span> -->
                          </div>
                          <div class="gene_list">
                            <span>MSH6</span><span>MUTYH</span><span>NRAS</span>
                            <span>PALB2</span><span>PDGFRA</span><span>PDGFRB</span>
                            <span>PIK3CA</span><span>PMS1</span><span>PMS2</span>
                            <span>POLD1</span>
                            <!-- <span>PIK3CA</span><span>PMS1</span><span>PMS2</span
                                                                  ><span>PPP2R2A</span><span>RAD51B</span><span>RAD51C</span
                                                                  ><span>RAD51D</span><span>RAD54L</span><span>RET</span
                                                                  ><span>ROS1</span> -->
                          </div>
                          <div class="gene_list">
                            <span>POLE</span><span>POLH</span><span>PTEN</span>
                            <span>RAD50</span><span>RAD51</span><span>RAD51B</span>
                            <span>RAD51C</span><span>RAD51D</span><span>RAD54L</span>
                            <span>RB1</span>
                            <!-- <span>STK11</span><span>TSC1</span><span>TSC2</span> -->
                          </div>
                          <div class="gene_list">
                            <span>RET</span><span>ROS1</span><span>SMAD4</span>
                            <span>STK11</span><span>TP53</span><span>TSC1</span>
                            <span>TSC2</span>
                            <!-- <span>STK11</span><span>TSC1</span><span>TSC2</span> -->
                          </div>
                        </i>
                      </div>

                      <!-- <div class="title py-2 mt-4">
                                                              <h6 class="mb-0 d-inline-block border-bottom py-2">
                                                                <b>CNAs</b> Covered in
                                                                <span class="font-weight-normal">TAR</span><b>GT</b>
                                                                <span class="clr-cyan pl-2" [ngClass]="'txt-skyblue'"
                                                                  >First</span
                                                                >
                                                              </h6>
                                                            </div> -->
                      <!-- <div class="gene_body" [ngClass]="'gene-bg_blue'">
                                                              <i>
                                                                <div class="gene_list">
                                                                  <span>ABL1</span><span>AR</span><span>ATM</span
                                                                  ><span>BARD1</span><span>BRCA1</span><span>BRCA2</span
                                                                  ><span>BRIP1</span><span>CDK12</span><span>CDK4</span
                                                                  ><span>CDK6</span>
                                                                </div>
                                                                <div class="gene_list">
                                                                  <span>CDKN2A</span><span>CHEK1</span><span>CHEK2</span
                                                                  ><span>EGFR</span><span>EPCAM</span><span>ERBB2</span
                                                                  ><span>ERBB3</span><span>EZH2</span><span>FANCL</span
                                                                  ><span>FGFR3</span>
                                                                </div>
                                                                <div class="gene_list">
                                                                  <span>GAPDH</span><span>IDH2</span><span>JAK2</span
                                                                  ><span>KIT</span><span>MDM2</span><span>NRAS</span
                                                                  ><span>PALB2</span><span>PDGFRA</span><span>PDGFRB</span
                                                                  ><span>PPP2R2A</span>
                                                                </div>
                                                                <div class="gene_list">
                                                                  <span>RAD51B</span><span>RAD51C</span><span>RAD51D</span
                                                                  ><span>RAD54L</span><span>STK11</span><span>TSC1</span
                                                                  ><span>TSC2</span>
                                                                </div>
                                                              </i>
                                                            </div> -->
                    </div>
                  </div>
                  <!-- cnv_genes -->

            <div class="col-12 px-0" *ngIf="isKokilaben">
              <div class="genes_info">
                <div class="title py-2">
                  <h6 class="mb-0 d-inline-block border-bottom py-2">
                    <b> CNVs
                    </b> Covered in
                    <span class="font-weight-normal">TAR</span><b>GT</b>
                    <span class="clr-cyan pl-2" [ngClass]="'txt-skyblue'"
                      >First Solid</span
                    >
                  </h6>
                </div>
                <div class="gene_body" [ngClass]="'gene-bg_blue'">
                  <i>
                    <div class="gene_list">
                      <span>ABL1</span><span>ALK</span><span>APC</span>
                      <span>AR</span><span>ATM</span><span>BARD1</span>
                      <span>BMPR1A</span><span>BRAF</span><span>BRCA1</span>
                      <span>BRCA2</span>
                    </div>
                    <div class="gene_list bg-white">
                      <span>BRIP1</span><span>CDK12</span><span>CDK4</span>
                      <span>CDK6</span><span>CDKN2A</span><span>CHEK1</span>
                      <span>CHEK2</span><span>CTNNB1</span><span>EGFR</span>
                      <span>EPCAM</span>
                    </div>
                    <div class="gene_list">
                      <span>ERBB2</span><span>ERBB3</span><span>EZH2</span>
                      <span>FANCL</span><span>FGFR1</span><span>FGFR2</span>
                      <span>FGFR3</span><span>GAPDH</span><span>IDH2</span>
                      <span>JAK2</span>
                    </div>
                    <div class="gene_list">
                     <span>KIT</span><span>MDM2</span>
                      <span>MET</span><span>MLH1</span><span>MUTYH</span>
                      <span>NRAS</span><span>PALB2</span><span>PDGFRA</span>
                      <span>PDGFRAB</span><span>POLD1</span>
                    </div>

                    <div class="gene_list">
                      <span>POLE</span><span>POLH</span><span>PTEN</span>
                      <span>RAD50</span><span>RAD51</span><span>RAD51B</span>
                      <span>RAD51C</span><span>RAD51D</span><span>RAD54L</span>
                      <span>RB1</span>
                    </div>
                    <div class="gene_list">
                      <span>RET</span><span>ROS1</span><span>SMAD4</span>
                      <span>STK11</span><span>TP53</span><span>TSC1</span>
                      <span>TSC2</span>
                    </div>
                  </i>
                </div>


              </div>
            </div>




                  <div class="col-12 px-0">
                    <div class="genes_info">
                      <div class="title py-2">
                        <h6 class="mb-0 d-inline-block border-bottom py-2">
                          <b>Gene Fusions/Splicing Variations</b> Covered in
                          <span class="font-weight-normal">TAR</span><b>GT</b>
                          <span class="clr-cyan pl-2" [ngClass]="'txt-skyblue'">First Solid</span>
                        </h6>
                      </div>
                      <div class="gene_body" [ngClass]="'gene-bg_blue'">
                        <i>
                          <div class="gene_list">
                          <span>ALK</span><span>FGFR2</span><span>FGFR3</span>
                          <span>MET</span><span>NRG1</span><span>NRG2</span>
                          <span>NTRK1</span><span>NTRK2</span> <span>NTRK3</span><span>RET</span>

                          </div>
                          <div class="gene_list">
                            <span>ROS1</span>
                          </div>
                        </i>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
            <h6 class="py-2 h6_bg mt-3" [ngClass]="'skyBlue'">TEST METHODOLOGY</h6>
            <!--  -->
            <div class="row mt-3">
              <div class="col">
                <h6 class="font-weight-bold">
                  Sample preparation and Library preparation :
                </h6>
                <!-- <p>
                                                        DNA isolated from FFPE, or any other fresh tumor tissue source was
                                                        used to perform targeted gene capture using a custom capture kit.
                                                        The libraries were sequenced to mean >350X coverage on Illumina
                                                        sequencing platform.
                                                      </p> -->
                <p style="text-align: justify;">
                  DNA isolated from FFPE, or any other fresh tumor tissue source was used to perform targeted gene capture using
                  a custom capture kit. The libraries were sequenced to mean
                  >350X coverage on Illumina sequencing platform.
                </p>
              </div>
            </div>
            <!--  -->
            <div class="row mt-4">
              <div class="col">
                <h6 class="font-weight-bold">
                  Bioinformatics Analysis and Reporting :
                </h6>
                <!-- <p>
                                                        The sequences obtained are aligned to human reference genome
                                                        (GRCh37/hg19) and variant analysis was performed using set of
                                                        Bioinformatics Pipeline. Only non-synonymous and splice site
                                                        variants found in the panel consisting of specific set of genes were
                                                        used for clinical interpretation. Silent variations that do not
                                                        result in any change in amino acid in the coding region are not
                                                        reported. Clinically relevant mutations were annotated using
                                                        published variants in literature and a set of databases – ClinVar,
                                                        COSMIC and dbSNP. Common variants are filtered based on allele
                                                        frequency in 1000 Genome Phase 3, ExAC, dbSNP, gnomAD, etc. In the
                                                        absence of a clinically significant reported known variation(s),
                                                        pathogenicity will be predicted based on <i>in-silico</i> gene
                                                        prioritization tools: CADD, SIFT, PolyPhen-2, Condel and Mutation
                                                        taster and prioritized for clinical correlation. The identified
                                                        pathogenic variant will be correlated with observed phenotypic
                                                        features of the patient and interpreted according to American
                                                        College of Medical Genetics (ACMG) guidelines.
                                                      </p>
                                                      <p class="mb-2 d-block">
                                                        Somatic variants are classified into three tiers based on their level
                                                        of clinical significance in cancer diagnosis, prognosis, and/or
                                                        therapeutics as per international guidelines: ACMG, ASCO, AMP, CAP,
                                                        NCCN and ESMO
                                                      </p> -->
                <p style="text-align: justify;">
                  The sequences obtained are aligned to human reference genome (GRCh37/hg19) and variant analysis was performed
                  using set of Bioinformatics Pipeline. Only non-synonymous and splice site variants found in the panel
                  consisting of specific set of genes were used for clinical interpretation. Silent variations that do not
                  result in any change
                  in amino acid in the coding region are not reported. Clinically relevant mutations were annotated using
                  published variants in literature and a set of databases – ClinVar, COSMIC
                  and dbSNP. Common variants are filtered based on allele frequency in 1000 Genome Phase 3, ExAC, dbSNP, gnomAD,
                  etc. In the absence of a clinically significant reported
                  known variation(s), pathogenicity will be predicted based on <i>in-silico</i> gene prioritization tools: CADD, SIFT,
                  PolyPhen-2, Condel and Mutation taster and prioritized for clinical
                  correlation. The identified pathogenic variant will be correlated with observed phenotypic features of the patient and interpreted according to ACMG/AMP guidelines.
                </p>
                <p class="mb-2 d-block" style="text-align: justify;">
                  Somatic variants are classified into three tiers based on their level of clinical significance in cancer
                  diagnosis, prognosis, and/or therapeutics as per international guidelines:
                  ACMG, ASCO, AMP, CAP, NCCN and ESMO.
                </p>
              </div>
            </div>
            <!--  -->
            <div class="row footer">
              <div class="col-12">
                <div class="pb-2 pl-4 text">
                  <div class="d-block">
                    <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
                  </div>
                  <div class="footer_body" [ngClass]="'gene-bg_blue'">
                    <div class="footer_list">
                      <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
                      <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
                      <span>Gin - Q</span><span>Gly - G</span><span>His - H</span>
                      <span>Ile - I</span>
                    </div>
                    <div class="footer_list">
                      <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
                      <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
                      <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
                      <span>Val - V</span>
                    </div>
                  </div>
                </div>
                <div class="col-12 pt-1 ">
                  <p style="font-size: 8px!important; line-height: 14px!important;">
                    {{footerDiscription}}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <!-- Page 7 -->
          <div id="page7" class="container position-relative page" style="background-color: white">
            <div class="row header mt-1 py-4">
              <div>
                <div class="col-3">
                  <img src="../../../assets/logo.png" alt="" style="width: 140px" />
                </div>
              </div>
              <div></div>
              <div class="col">
                <div class="patient-detailes ml-auto float-right">
                  <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
                    <li>
                      Patient Id <br />
                      <span>{{ report.patient?.ecrfId }}</span>
                    </li>
                    <li class="ml-4">
                      Cancer Site<br />
                      <span>{{ report.cancerType }}</span>
                    </li>
                    <li class="ml-4">
                      SAMPLE ID<br />
                      <span>{{ report.labDetails?.sampleId }}</span>
                    </li>
                    <li class="ml-4">
                      Registration Date<br />
                      <span>{{
                        report.labDetails?.sampleCollected | date : "dd/MM/yyyy"
                        }}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <!--  -->
              <div *ngIf="report.clientType == '4basecare_client'">
                <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
                  </div>
                </div>
                <div *ngIf="report.clientName == 'path_labs'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
                  </div>
                </div>
              </div>
            </div>
            <!--  -->
            <div class="mar_top"></div>
            <!--  -->


            <h6 class="py-2 h6_bg mt-2" [ngClass]="'skyBlue'">
              LIMITATIONS AND DISCLAIMER
            </h6>

            <div class="row">
              <div class="col">
                <div class="disc">
                  <ul class="ml-0 pl-4" style="text-align: justify;">
                    <li>
                      TARGT FIRST SOLID test has been developed, validated and performed by 4baseCare Oncosolutions Pvt. Ltd and
                      has not been cleared or approved by the FDA.
                    </li>
                    <li>
                      DNA studies do not constitute a definitive test for the selected condition(s) in all individuals. It
                      should be realized that there are possible sources of error. Errors can result
                      from trace contamination, rare technical errors, rare genetic variants that interfere with analysis,
                      recent scientific developments, and alternative classification systems. This
                      test should be one of the many aspects used by the healthcare provider to help with a diagnosis and
                      treatment plan.
                    </li>
                    <li>
                      We are using the canonical transcript for clinical reporting which is usually the longest coding
                      transcript with strong/multiple supporting evidence. However, in rare cases,
                      clinically relevant variants annotated in alternate complete coding transcripts could also be reported.
                    </li>
                    <li>
                      The contents of this test should be carefully assessed by the treating physician and further interpreted
                      along with clinical, histopathological findings, contraindications and
                      guidelines before deciding the course of therapy.
                    </li>
                    <li>
                      The CNVs detected must be confirmed by an alternate method, such as IHC, for further clinical management decisions.
                    </li>
                    <!-- <li>
                      The sensitivity of this assay to detect large deletions/duplications of more than 10 bp or copy number
                      variations (CNV) is 70-75%. The CNVs detected have to be confirmed
                      by alternate method.
                    </li> -->
                    <li>
                      Most recent block is recommended for testing as the mutation profile may change in response to treatment
                      and hence differ at different sampling points.
                    </li>
                    <li>
                      The identified pathogenic variant will be correlated with observed phenotypic features of the patient and interpreted according to AMP guidelines.
                    </li>
                    <li>
                      Certain genes may not be covered completely, and few mutations could be missed. A negative result cannot
                      rule out the possibility that the tested tumor sample carries
                      mutations not previously associated with cancer and hence not included in the panel.
                    </li>
                    <li>
                      Our limit of detection for TARGT FIRST SOLID is 5% for SNVs, 10% for InDels and CNV gain &#8805; 6. In addition
                      to this, sequencing quality and coverage is dependent on many factors
                      such as homopolymers, GC-rich regions, intrinsic quality of FFPE DNA and that might impact the variant
                      detection.
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <!--  -->
            <div class="row footer">
              <div class="col-12">
                <div class="pb-2 pl-4 text">
                  <div class="d-block">
                    <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
                  </div>
                  <div class="footer_body" [ngClass]="'gene-bg_blue'">
                    <div class="footer_list">
                      <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
                      <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
                      <span>Gin - Q</span><span>Gly - G</span><span>His - H</span>
                      <span>Ile - I</span>
                    </div>
                    <div class="footer_list">
                      <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
                      <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
                      <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
                      <span>Val - V</span>
                    </div>
                  </div>
                </div>
                <div class="col-12 pt-1 ">
                  <p style="font-size: 8px!important; line-height: 14px!important;">
                    {{footerDiscription}}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>





        <!-- TMH -->
        <!-- For TMH Template 1-->
        <div *ngIf="!is4basecare && isTMH && temp=='temp1'">

          <!-- Page 1 -->
          <div id="page1" class="container position-relative page" style="background-color: white">

            <div class="d-flex justify-content-between logo_panel py-3">
              <div class="logo_part">
                <img src="../../../assets/tmc/tmc_log1.png" alt="" class="logo_first" />
              </div>
              <div class="logo_part text-center">
                <img src="../../../assets/tmc/tmc_logo2.png" alt="" class="logo_two" />
                <p style="font-size: 10px">
                  An Initiative of Bihar Govt. & Doctors For You
                </p>
                <p class="text-uppercase">MEDICAL GENOMICS & MOLECULAR ONCOLOGY</p>
              </div>
              <div class="logo_part">
                <img src="../../../assets/tmc/tmc_logo3.jpg" alt="" class="logo_three" />
              </div>
            </div>
            <!--  -->
            <div class="mar_top"></div>
            <!--  -->
            <div class="row">
              <div class="col-7">
                <div class="">
                  <div class="w-50" style="min-height: 80px">
                    <p class="py-1 font-weight-bold" [ngClass]="'txt-skyblue'">
                      SCOPE OF THE TEST
                    </p>
                    <p>SNVs, InDels, CNAs, Gene Fusions status</p>
                  </div>
                  <img src="../../../assets/BorderLine.png" class="mr-2 w-100" />
                </div>
              </div>
              <div class="col">
                <div class="">
                  <div class="w-100">
                    <!-- <p style="color: #208dcb;" class="py-1">
                                                            <span style="color: #000 !important"></span>
                                                          </p> -->
                    <div class="w-100" style="min-height: 80px">
                      <p [ngClass]="'txt-skyblue'" class="py-1 font-weight-bold" *ngIf="report.clinicalSummary != null">
                        <span *ngIf="report.clinicalSummary != ' '">CLINICAL INDICATION</span>
                      </p>

                      <p *ngIf="report.clinicalSummary != null">
                        <span style="color: #000 !important" *ngIf="report.clinicalSummary != ' '">{{ report.clinicalSummary
                          }}</span>
                      </p>
                    </div>
                    <!-- </div> -->
                    <img src="../../../assets/BorderLine.png" class="mr-2 w-100" />

                    <!-- </div> -->
                  </div>
                </div>
              </div>
            </div>
            <!--  -->
            <div class="row pt-2">
              <div class="col-7">
                <h6 class="text-uppercase py-2 font-weight-bold">Report Details</h6>
                <ul class="list-unstyled">
                  <li class="pb-1">
                    <span class="clr-cyan">Patient Name : </span>
                    <span>{{ report.patient?.name }}</span>
                  </li>
                  <li class="pb-1">
                    <span class="clr-cyan" [ngClass]="'txt-skyblue'">Gender : </span>
                    <span class="text-black">{{
                      report.patient?.gender | titlecase
                    }}</span>
                  </li>
                  <li class="pb-1">
                    <span class="clr-cyan" [ngClass]="'txt-skyblue'">Age/DOB : </span>
                    <span class="text-black">{{
                      report.patient?.dateOfBirth
                        ? (report.patient?.dateOfBirth | date : "dd/MM/yyyy")
                        : report.patient?.age + " Years"
                    }}</span>
                  </li>
                  <li class="pb-1">
                    <span class="clr-cyan">Case No : </span
                    ><span>{{ report?.tmhReportInfo?.caseNo }}</span>
                  </li>
                  <li class="pb-1">
                    <span class="clr-cyan">Path No : </span
                    ><span>{{ report?.tmhReportInfo?.pathNo }}</span>
                  </li>
                  <li class="pb-1">
                    <span class="clr-cyan">Referred By : </span
                    ><span>{{ referringClinician }}</span>
                  </li>
                </ul>
              </div>
              <div class="col">
                <ul class="list-unstyled mt-4 pt-4">
                  <li class="pb-1">
                    <span class="clr-cyan">Patient ID : </span
                    ><span>{{ report.patient?.organisationPatientId }}</span>
                  </li>
                  <li class="pb-1">
                    <span class="clr-cyan">Category : </span
                    ><span>{{ report?.tmhReportInfo?.category }}</span>
                  </li>
                  <li class="pb-1">
                    <span class="clr-cyan">Collected on : </span
                    ><span>{{
                      report.labDetails?.sampleCollected | date : "dd/MM/yyyy"
                    }}</span>
                  </li>
                  <li class="pb-1">
                    <span class="clr-cyan">Reported on : </span
                    ><span>{{ date | date : "dd/MM/yyyy" }}</span>
                  </li>
                  <li class="pb-1">
                    <span class="clr-cyan">Hospital Name : </span
                    ><span>{{ labHospital }}</span>
                  </li>
                </ul>
              </div>
            </div>
            <!--  -->
            <h6 class="py-2 h6_bg">CLINICAL HISTORY/SAMPLE DETAILS</h6>
            <div class="row mt-2 py-2">
              <div class="col-5">
                <h6 class="mb-0 font-weight-bold">Clinical Evaluations</h6>
                <span>{{ report?.cancerIdentifier }}</span>
              </div>
              <div class="col-5">
                <h6 class="mb-0 font-weight-bold">Sample Details</h6>
                <span *ngIf="report.labDetails?.sampleSource">{{
                  report.labDetails?.sampleSource
                  }}</span>
                <span *ngIf="!report.labDetails?.sampleSource">FFPE Block</span>
              </div>
              <div class="col">
                <h6 class="mb-0 font-weight-bold">Tumor content</h6>
                <span>{{ report?.tmhReportInfo?.tumorContent }}</span>
              </div>
              <div class="w-100">
                <div class="col pt-4">
                  <p>(Confirmed by - Consultant Pathologist, HBCHRC, Muzaffarpur)</p>
                </div>
              </div>
            </div>
            <!--  -->
            <div class="row mt-2 py-2">
              <div class="col">
                <h6 class="mb-0 text-uppercase f-weight_700">Results</h6>
              </div>
            </div>
            <h6 class="py-2 h6_bg" [ngClass]="'skyBlue'">
              Genomic findings from tumor profiling
            </h6>

            <!--  -->
            <div class="row">
              <div style="width: 100%;" *ngIf="isReportDownload">
                <img [src]="firstPageTable" alt="" width="100%" class="img-fluid" />
              </div>
              <div class="col-12" id="firstPageTable" *ngIf="!isReportDownload">
                <table class="page_1 cust_table w-100 mt-3 first_1" [ngClass]="'table_skyblue'">
                  <tr>
                    <th>Genomic Alteration</th>
                    <th>Relevant Therapies (in Same Cancer Type)</th>
                    <th>Relevant Therapies (in Different Cancer)</th>
                  </tr>
                  <tr *ngIf="tableCount== 0 && prognasticCount>0 ">
                    <td colspan="3" class="text-center mt-2">
                      Clinically relevant genomic alterations associated with therapeutic significance were not detected.
                    </td>
                  </tr>
                  <tr *ngIf="tableCount== 0 && prognasticCount == 0">
                    <td colspan="3" class="text-center mt-2">
                      Clinically relevant genomic alterations associated with both therapeutic and prognostic significance were
                      not detected.
                    </td>
                  </tr>



                  <ng-container>
                    <tr *ngFor="
                                                                let snvHotspotTrail of reportPdfRes?.allHotspotTrailList  | slice : 0 : 5 ; let i=index
                                                              ">
                      <td>
                        <i>{{ snvHotspotTrail.gene }}</i>
                        &nbsp;<span *ngIf="!snvHotspotTrail.gene.includes('Wild type')">
                        <span *ngIf="snvHotspotTrail?.multiVariant==null">
                          <span *ngIf="snvHotspotTrail.exonNumber != null || snvHotspotTrail.pposition != null && snvHotspotTrail.pposition != ''">

                            <span *ngIf="snvHotspotTrail.exonNumber != null">{{snvHotspotTrail.exonNumber}}</span>&nbsp;
                            <span *ngIf="snvHotspotTrail.pposition != null && snvHotspotTrail.pposition != ''">({{snvHotspotTrail.pposition?.replace(' ','')}})</span></span>
                            <p *ngIf="snvHotspotTrail.mutantBurdenPercent != null && !snvHotspotTrail.gene.includes('Wild type')">
                              Allelic burden: {{ snvHotspotTrail.mutantBurdenPercent }}%
                            </p>
                            <span *ngIf="snvHotspotTrail.copyNumber!=null && snvHotspotTrail.copyNumber.length>0"> {{ snvHotspotTrail.copyNumber }}
                          </span>
                        </span>
                        <span *ngIf="snvHotspotTrail?.multiVariant!=null"><br/>
                          <span  style="font-size: 14px !important" [innerHtml]="snvHotspotTrail?.multiVariant"></span>
                        </span></span>
                      </td>
                      <td>
                        <table class="table first_table w-100">
                          <thead class="border-bottom cust_clr">
                            <tr>
                              <th class="w-50">
                                <span class="font-weight-normal"><i>Therapy</i></span>
                              </th>
                              <th class="w-33">
                                <span class="font-weight-normal"><i>Clinical Relevance</i></span>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngIf="snvHotspotTrail.sameCancerType == null">
                              <td class="w-50">NA</td>
                              <td class="w-50">NA</td>
                            </tr>

                            <ng-container *ngIf="snvHotspotTrail.sameCancerType != null">
                              <tr *ngFor="
                                                                          let item of snvHotspotTrail.sameTypeRelevantData
                                                                        ">
                                <td>{{ item.therapy }}</td>
                                <td>{{ item.association }}</td>
                                <!-- <td>{{item.cancerType}}</td> -->
                              </tr>
                            </ng-container>
                          </tbody>
                        </table>
                      </td>
                      <td>
                        <table class="table first_table w-100">
                          <thead class="border-bottom cust_clr">
                            <tr>
                              <th class="w-33">
                                <span class="font-weight-normal"><i>Therapy</i></span>
                              </th>
                              <th class="w-33">
                                <span class="font-weight-normal"><i>Clinical Relevance</i></span>
                              </th>
                              <th class="w-33">
                                <span class="font-weight-normal"><i>Cancer Type</i></span>
                              </th>
                            </tr>
                          </thead>
                          <tbody>

                            <ng-container *ngIf="snvHotspotTrail.otherCancerType != null">
                              <tr *ngFor="
                                                                          let item of snvHotspotTrail.otherTypeRelevantData
                                                                        ">
                                <td>{{ item.therapy }}</td>
                                <td>{{ item.association }}</td>
                                <td>{{ item.cancerType }}</td>
                              </tr>
                            </ng-container>
                            <tr *ngIf="snvHotspotTrail.otherCancerType == null">
                              <td class="w-33">NA</td>
                              <td class="w-33">NA</td>
                              <td class="w-33">NA</td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>


                  </ng-container>
                </table>
                <p *ngIf="
                                snvHotSpot.length != 0 ||
                                cnaHotSpot.length != 0 ||
                                                    fusionHotSpot.length !=0
                                                  " style="font-size: 13px !important" class="mt-0">
                  <i> *NA: Not Applicable</i>
                </p>
              </div>
            </div>
            <!--  -->
            <div *ngIf="(tableCount>0) &&
                              prognasticCount==0">
              <h6 class="py-2 h6_bg"> Prognostic Biomarkers</h6>
            </div>
            <div class="row" *ngIf="(tableCount>0 )&&
                              prognasticCount==0">
              <div class="col-12">
                <table class="page_1 cust_table w-100 mt-3 varient_details prognasticbiomarkertable"
                  [ngClass]="'table_variant-blue'">
                  <thead>
                    <tr>
                      <th>Genomic Alteration</th>
                      <th>Prognostic Significance</th>

                    </tr>
                  </thead>
                  <ng-container>
                    <tr>
                      <td colspan="2" style="text-align:center">
                        Clinically relevant genomic alterations associated with prognostic significance were not detected.
                      </td>
                    </tr>
                  </ng-container>
                </table>
              </div>
            </div>
            <div *ngIf="prognasticCount>0">
              <h6 class="py-2 h6_bg"> Prognostic Biomarkers</h6>
            </div>

            <div class="row" *ngIf="prognasticCount>0">
              <div class="col-12">
                <table class="page_1 cust_table w-100 mt-3 varient_details prognasticbiomarkertable"
                  [ngClass]="'table_variant-blue'">
                  <thead>
                    <tr>
                      <th>Genomic Alteration</th>
                      <th>Prognostic Significance</th>

                    </tr>
                  </thead>
                  <ng-container *ngFor="let prog of reportPdfRes.prognosticBiomarkers">
                    <tr>
                      <td style="padding-left: 3px !important">
                        <i>{{ prog.gene }}&nbsp; </i>
                        <span [innerHtml]="prog.geneDetails"></span>
                      </td>
                      <td style="padding-left: 3px !important;">{{prog.statement}}
                      </td>
                    </tr>
                  </ng-container>


                </table>
              </div>
            </div>
            <!--  -->

            <!-- gene Data -->
            <!-- <div *ngIf="geneMutationlist != null && isStatusDisplay">
              <div class="mt-3">
                <h6 class="py-2 h6_bg" [ngClass]="'skyBlue'">
                  Status of variants in cancer related Biomarkers
                </h6>
              </div>

              <table class="staus_gene bg_skyblue" *ngIf="geneMutationlist != null" [ngClass]="{
                                                  biomarker_table:
                                                    geneMutationlist.length >= 4 &&
                                                    geneMutationlist.length < 8
                                                }">
                <tr style="background: #daedf7">
                  <td style="font-size: 18px !important; padding-left: 10px !important">
                    Gene
                  </td>
                  <td *ngFor="
                                                      let geneMutation of geneMutationlist
                                                        | slice : 0 : 8
                                                    ">
                    <i>{{ geneMutation.gene }}</i>
                  </td>
                </tr>
                <tr>
                  <td style="font-size: 18px !important; padding-left: 10px !important">
                    <b>Status</b>
                  </td>
                  <td *ngFor="
                                                      let geneMutation of geneMutationlist
                                                        | slice : 0 : 8
                                                    ">
                    <span *ngIf="
                                                        geneMutation.status == 'Pathogenic' ||
                                                        geneMutation.status == 'Likely Pathogenic' ||
                                                        geneMutation.status == 'Detected'
                                                      " style="color: red; font-weight: 600px"><b>{{ geneMutation.status
                        }}</b></span>
                    <span *ngIf="geneMutation.status == 'VUS'" style="color: black; font-weight: 600px"><b>{{
                        geneMutation.status }}</b></span>
                    <span *ngIf="
                                                        geneMutation.status == 'Not Detected' ||
                                                        geneMutation.status == 'Benige'
                                                      ">{{ geneMutation.status }}</span>
                  </td>
                </tr>
<br/>
              </table>
              <table class="staus_gene" [ngClass]="'bg_skyblue'" *ngIf="
                                                  geneMutationlist != null &&
                                                  geneMutationlist.length > 8
                                                ">
                <tr style="background: #daedf7">
                  <td style="font-size: 18px !important; padding-left: 10px !important">
                    Gene
                  </td>
                  <td *ngFor="
                                                      let geneMutation of geneMutationlist
                                                        | slice : 8 : 17
                                                    ">
                    <i>{{ geneMutation.gene }}</i>
                  </td>
                </tr>
                <tr>
                  <td style="font-size: 18px !important; padding-left: 10px !important">
                    <b>Status</b>
                  </td>
                  <td *ngFor="
                                                      let geneMutation of geneMutationlist
                                                        | slice : 8 : 17
                                                    ">
                    <span *ngIf="
                                                        geneMutation.status == 'Pathogenic' ||
                                                        geneMutation.status == 'Likely Pathogenic' ||
                                                        geneMutation.status == 'Detected'
                                                      " style="color: red; font-weight: 600px"><b>{{ geneMutation.status
                        }}</b></span>
                    <span *ngIf="geneMutation.status == 'VUS'" style="color: black; font-weight: 600px"><b>{{
                        geneMutation.status }}</b></span>
                    <span *ngIf="
                                                        geneMutation.status == 'Not Detected' ||
                                                        geneMutation.status == 'Benige'
                                                      ">{{ geneMutation.status }}</span>
                  </td>
                </tr>
<br/>
              </table>
              <table
                class="staus_gene"
                [ngClass]="'bg_skyblue'"
                *ngIf="
                  geneMutationlist != null &&
                  geneMutationlist.length > 17
                "
              >
                <tr style="background: #daedf7">
                  <td
                    style="font-size: 18px !important; padding-left: 10px !important"
                  >
                    Gene
                  </td>
                  <td
                    *ngFor="
                      let geneMutation of geneMutationlist
                        | slice : 17 : 25
                    "
                  >
                    <i>{{ geneMutation.gene }}</i>
                  </td>
                </tr>
                <tr>
                  <td
                    style="font-size: 18px !important; padding-left: 10px !important"
                  >
                    <b>Status</b>
                  </td>
                  <td
                    *ngFor="
                      let geneMutation of geneMutationlist
                        | slice : 17 : 25
                    "
                  >
                    <span
                      *ngIf="
                        geneMutation.status == 'Pathogenic' ||
                        geneMutation.status == 'Likely Pathogenic' ||
                        geneMutation.status == 'Detected'
                      "
                      style="color: red; font-weight: 600px"
                      ><b>{{ geneMutation.status }}</b></span
                    >
                    <span
                      *ngIf="geneMutation.status == 'VUS'"
                      style="color: black; font-weight: 600px"
                      ><b>{{ geneMutation.status }}</b></span
                    >
                    <span
                      *ngIf="
                        geneMutation.status == 'Not Detected' ||
                        geneMutation.status == 'Benige'
                      "
                      >{{ geneMutation.status }}</span
                    >
                  </td>
                </tr>
<br/>
              </table>
          <table class="staus_gene" [ngClass]="'bg_skyblue'" *ngIf="
                geneMutationlist != null &&
                geneMutationlist.length > 33
              ">
            <tr style="background: #daedf7">
              <td style="font-size: 18px !important; padding-left: 10px !important">
                Gene
              </td>
              <td *ngFor="
                    let geneMutation of geneMutationlist
                      | slice : 33 : 41
                  ">
                <i>{{ geneMutation.gene }}</i>
              </td>
            </tr>
            <tr>
              <td style="font-size: 18px !important; padding-left: 10px !important">
                <b>Status</b>
              </td>
              <td *ngFor="
                    let geneMutation of geneMutationlist
                      | slice : 33 : 41
                  ">
                <span *ngIf="
                      geneMutation.status == 'Pathogenic' ||
                      geneMutation.status == 'Likely Pathogenic' ||
                      geneMutation.status == 'Detected'
                    " style="color: red; font-weight: 600px"><b>{{ geneMutation.status }}</b></span>
                <span *ngIf="geneMutation.status == 'VUS'" style="color: black; font-weight: 600px"><b>{{
                    geneMutation.status }}</b></span>
                <span *ngIf="
                      geneMutation.status == 'Not Detected' ||
                      geneMutation.status == 'Benige'
                    ">{{ geneMutation.status }}</span>
              </td>
            </tr>
            <br/>
          </table>
              <h6 *ngIf="
                                                  (fusionGeneMutationList != null &&
                                                  fusionGeneMutationList.length > 0)
                                                " class="font-weight-bold clr-cyan text-uppercase" [ngClass]="'txt-skyblue'">
                Gene Fusion
              </h6>
              <table class="staus_gene" *ngIf="
                                                  (fusionGeneMutationList != null &&
                                                  fusionGeneMutationList.length > 0)
                                                " [ngClass]="'bg_skyblue'">
                <tr style="background: #daedf7">
                  <td style="font-size: 18px !important; padding-left: 10px !important">
                    Gene
                  </td>
                  <td *ngFor="
                                                      let geneMutation of fusionGeneMutationList
                                                        | slice : 0 : 8
                                                    ">
                    <i>{{ geneMutation.gene }}</i>
                  </td>
                </tr>
                <tr>
                  <td style="font-size: 18px !important; padding-left: 10px !important">
                    <b>Status</b>
                  </td>
                  <td *ngFor="
                                                      let geneMutation of fusionGeneMutationList
                                                        | slice : 0 : 8
                                                    ">
                    <span *ngIf="
                                                        geneMutation.status == 'Pathogenic' ||
                                                        geneMutation.status == 'Likely Pathogenic' ||
                                                        geneMutation.status == 'Detected'
                                                      " style="color: red; font-weight: 600px"><b>{{ geneMutation.status
                        }}</b></span>
                    <span *ngIf="geneMutation.status == 'VUS'" style="color: black; font-weight: 600px"><b>{{
                        geneMutation.status }}</b></span>
                    <span *ngIf="
                                                        geneMutation.status == 'Not Detected' ||
                                                        geneMutation.status == 'Benige'
                                                      ">{{ geneMutation.status }}</span>
                  </td>
                </tr>
<br/>
              </table>
              <table class="staus_gene" *ngIf="
                                                  (fusionGeneMutationList != null &&
                                                  fusionGeneMutationList.length > 8)
                                                " [ngClass]="'bg_skyblue'">
                <tr style="background: #daedf7">
                  <td style="font-size: 18px !important; padding-left: 10px !important">
                    Gene
                  </td>
                  <td *ngFor="
                                                      let geneMutation of fusionGeneMutationList
                                                        | slice : 8 : 17
                                                    ">
                    <i>{{ geneMutation.gene }}</i>
                  </td>
                </tr>
                <tr>
                  <td style="font-size: 18px !important; padding-left: 10px !important">
                    <b>Status</b>
                  </td>
                  <td *ngFor="
                                                      let geneMutation of fusionGeneMutationList
                                                        | slice : 8 : 17
                                                    ">
                    <span *ngIf="
                                                        geneMutation.status == 'Pathogenic' ||
                                                        geneMutation.status == 'Likely Pathogenic' ||
                                                        geneMutation.status == 'Detected'
                                                      " style="color: red; font-weight: 600px"><b>{{ geneMutation.status
                        }}</b></span>
                    <span *ngIf="geneMutation.status == 'VUS'" style="color: black; font-weight: 600px"><b>{{
                        geneMutation.status }}</b></span>
                    <span *ngIf="
                                                        geneMutation.status == 'Not Detected' ||
                                                        geneMutation.status == 'Benige'
                                                      ">{{ geneMutation.status }}</span>
                  </td>
                </tr>
              </table>
              </div> -->
              <div class="row mt-5" *ngIf="
                  !variantFlag &&
                  report.addNotes != null && report.addNotes != '<div></div>'
                ">
          <div class="col">
            <h6 class="py-2 h6_bg">Additional Notes</h6>
            <div class="py-2" [innerHtml]="report.addNotes" style="text-align: justify; font-size: 14px !important"></div>
          </div>
        </div>
            <!--  -->
            <div class="row footer d-flex justify-content-between align-items-center">
              <div class="col">
                <div class="py-2">
                  <p>
                    <span class="font-weight-bold">Genomics Bihar,</span>
                  </p>
                  <p>Homi Bhabha cancer hospital & Research centre</p>
                  <p>Inside Srikrishna Medical College & Hospital</p>
                  <p>Umanagar, Muzaffarpur-842004.</p>
                  <p>Contact: ngs.mfp@gmail.com</p>
                </div>
              </div>
              <div class="col">
                <p class="text-right">
                  <img
                    src="../../../assets/tmc/tmc_footer.png"
                    alt="footer_image"
                    class="footer_img"
                  />
                </p>
              </div>
            </div>
          </div>

          <!-- page 2 -->
          <div id="page2" *ngIf="
                                                      otherCountSNV == true ||
                                                      reportPdfRes.allVariantsList.length > 0
                                                      " class="container position-relative page" style="background-color: white">
            <div class="d-flex justify-content-between logo_panel py-3">

              <div class="logo_part">
                <img src="../../../assets/tmc/tmc_log1.png" alt="" class="logo_first" />
              </div>
              <div class="logo_part text-center">
                <img src="../../../assets/tmc/tmc_logo2.png" alt="" class="logo_two" />
                <p style="font-size: 10px">
                  An Initiative of Bihar Govt. & Doctors For You
                </p>
                <p class="text-uppercase">MEDICAL GENOMICS & MOLECULAR ONCOLOGY</p>
              </div>
              <div class="logo_part">
                <img src="../../../assets/tmc/tmc_logo3.jpg" alt="" class="logo_three" />
              </div>
            </div>
            <div class="row header mt-1">

              <div></div>
              <div class="col">
                <div class="patient-detailes ml-auto float-right">
                  <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
                    <li>
                      Patient Id <br />
                      <span>{{ report.patient?.organisationPatientId }}</span>
                    </li>
                    <li class="ml-4">
                      Patient Name<br />
                      <span>{{ report.patient?.name }}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <!--  -->
              <div *ngIf="report.clientType == '4basecare_client'">
                <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
                  </div>
                </div>
                <div *ngIf="report.clientName == 'path_labs'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
                  </div>
                </div>
              </div>
            </div>


            <!--  -->

            <div class="row mt-3" *ngIf="
                                                          otherCountSNV == true ||
                                                          reportPdfRes.allVariantsList.length != 0
                                                        ">
              <div class="col">
                <h6 class="font-weight-bold clr-cyan text-uppercase" [ngClass]="'txt-skyblue'">
                  Variant Details:
                </h6>
              </div>
            </div>

            <div class="row" *ngIf="
                                                          otherCountSNV == true ||
                                                          reportPdfRes.allVariantsList.length != 0
                                                        ">
              <div class="col-12">
                <table class="page_1 cust_table w-100 mt-3 varient_details" [ngClass]="'table_variant-blue'">
                  <thead>
                    <tr>
                      <th>Gene</th>
                      <th>Variant Location</th>
                      <th>Variant Consequence</th>
                      <th>Clinical Significance</th>
                      <th>Variant Type</th>
                      <th>Reference</th>
                    </tr>
                  </thead>

                  <tr *ngFor="let variant of reportPdfRes.allVariantsList">
                    <td *ngIf="variant.significance != 'NA' && variant.significance != 'Not Classified'" style="padding-left: 3px !important">
                      <i>{{ variant.gene }}</i>
                    </td>
                    <td *ngIf="variant.significance != 'NA' && variant.significance != 'Not Classified'" style="padding-left: 3px !important">
                      <span *ngIf="variant?.variantType=='snv'">
                        {{ variant?.genomicAlteration }},<br />
                        {{ variant?.ensembleValue }},<br />
                        {{ variant?.exonNumber }}
                      </span>
                      <span *ngIf="variant?.variantType=='cna'">
                        chr{{ variant?.sample?.split(":")[0] }}:g.{{
                          variant.sample
                          .split(":")[1]
                          .replace("-", "_")
                          .split("|")[0]
                          }}
                      </span>
                      <span *ngIf="variant.variantType=='fusion'">
                        {{ variant?.leftBreakPoint }}<br />{{variant?.rightBreakPoint}}
                      </span>
                    </td>
                    <td *ngIf="variant?.significance != 'NA' && variant?.significance != 'Not Classified'" style="padding-left: 3px !important">
                      <span *ngIf="variant.variantType=='snv'">
                        <span *ngIf="variant?.selectedAAChangeknownGene">{{
                          variant?.selectedAAChangeknownGene|splitComma }}</span>, {{variant?.mutantBurdenPercent * 0.01 | percent}}
                        </span>
                      <span *ngIf="variant.variantType=='cna'">NA</span>
                      <span *ngIf="variant.variantType=='fusion'">NA</span>
                    </td>
                    <td *ngIf="variant?.significance != 'NA' && variant?.significance != 'Not Classified'" style="padding-left: 3px !important">
                      {{ variant?.significance }}
                    </td>
                    <td *ngIf="variant?.significance != 'NA' && variant?.significance != 'Not Classified'" style="padding-left: 3px !important">
                      <span *ngIf="variant.variantType=='snv'">
                        {{ variant?.exonicFuncknownGene }}
                      </span>
                      <span *ngIf="variant.variantType=='cna'">
                        Copy Number
                        {{ variant?.sample.split(":")[2] | titlecase }}
                        ({{ variant?.sample.split("|")[1].split(":")[0] }})
                      </span>
                      <span *ngIf="variant.variantType=='fusion'">Gene Fusions</span>
                    </td>
                    <td *ngIf="variant?.significance != 'NA' && variant?.significance != 'Not Classified'" style="padding-left: 3px !important">
                      {{ variant?.reference }}
                    </td>
                  </tr>
                </table>
                <p *ngIf="reportPdfRes.allVariantsList.length > 0 " style="font-size: 13px !important" class="mt-0">
                  <i> *NA: Not Applicable</i>
                </p>
              </div>
            </div>



            <!--  -->
            <div class="row mt-5" *ngIf="
                  !variantFlag &&
                  report.addNotes != null && report.addNotes != '<div></div>'
                ">
          <div class="col">
            <h6 class="py-2 h6_bg">Additional Notes</h6>
            <div class="py-2" [innerHtml]="report.addNotes" style="text-align: justify; font-size: 14px !important"></div>
          </div>
        </div>

            <!--  -->
            <div class="row footer d-flex justify-content-between align-items-center">
              <div class="col">
                <div class="py-2">
                  <p>
                    <span class="font-weight-bold">Genomics Bihar,</span>
                  </p>
                  <p>Homi Bhabha cancer hospital & Research centre</p>
                  <p>Inside Srikrishna Medical College & Hospital</p>
                  <p>Umanagar, Muzaffarpur-842004.</p>
                  <p>Contact: ngs.mfp@gmail.com</p>
                </div>
              </div>
              <div class="col">
                <p class="text-right">
                  <img src="../../../assets/tmc/tmc_footer.png" alt="footer_image" class="footer_img" />
                </p>
              </div>
            </div>
          </div>

          <!-- page 3 -->
      <!-- <div id="page3" *ngIf="reportPdfRes.drugsResList.length > 0" class="container position-relative page"
        style="background-color: white">
        <div class="d-flex justify-content-between logo_panel py-3">
          <div class="logo_part">
            <img src="../../../assets/tmc/tmc_log1.png" alt="" class="logo_first" />
          </div>
          <div class="logo_part text-center">
            <img src="../../../assets/tmc/tmc_logo2.png" alt="" class="logo_two" />
            <p style="font-size: 10px">
              An Initiative of Bihar Govt. & Doctors For You
            </p>
            <p class="text-uppercase">MEDICAL GENOMICS & MOLECULAR ONCOLOGY</p>
          </div>
          <div class="logo_part">
            <img src="../../../assets/tmc/tmc_logo3.jpg" alt="" class="logo_three" />
          </div>
        </div>
        <div class="row header mt-1">
          <div>

          </div>
          <div></div>
          <div class="col">
            <div class="patient-detailes ml-auto float-right">
              <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
                <li>
                  Patient Id <br />
                  <span>{{ report.patient?.organisationPatientId }}</span>
                </li>
                <li class="ml-4">
                  Patient Name<br />
                  <span>{{ report.patient?.name }}</span>
                </li>
              </ul>
            </div>
          </div>
          <div *ngIf="report.clientType == '4basecare_client'">
            <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
              <div class="logo">
                <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
              </div>
            </div>
            <div *ngIf="report.clientName == 'path_labs'" class="col-3">
              <div class="logo">
                <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
              </div>
            </div>
          </div>
        </div>
        <div class="mar_top"></div>

        <h6 class="py-2 h6_bg" [ngClass]="'skyBlue'">
          Alterations With Clinically Relevant Therapies
        </h6>
        <div class="row">
          <div class="col-12">
            <table class="page_1 cust_table w-100 mt-3 first_2 custom-table" [ngClass]="'bg_cyan'">
              <thead>
                <tr>
                  <th>Gene (Alterations) - Drug association</th>
                  <th>Summary</th>
                </tr>
              </thead>

              <tbody>
                <tr *ngFor="
                                                                  let drugsRes of reportPdfRes.drugsResList | slice : 0 : 4;
                                                                  let i = index
                                                                ">
                  <td>
                    <ul class="ml-0 pl-2">
                      <li>
                        <i>{{ drugsRes.gene.replace("Fusion","") }}</i><span *ngIf="drugsRes.gene.includes('Fusion')">Fusion</span> {{ drugsRes.pposition?.replace(' ','') }} :
                        <br />
                        <span *ngFor="let drug of drugsRes.drugs; let i = index">
                          {{ drug.name
                          }}<span *ngIf="drugsRes.drugs.length - 1 != i">,</span>
                        </span>
                        - {{ drugsRes.association }}
                        <p>{{ drugsRes.cancerType }}</p>
                      </li>
                    </ul>
                  </td>
                  <td>
                    <div [innerHtml]="drugsRes.summary"></div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div class="row footer d-flex justify-content-between align-items-center">
          <div class="col">
            <div class="py-2">
              <p>
                <span class="font-weight-bold">Genomics Bihar,</span>
              </p>
              <p>Homi Bhabha cancer hospital & Research centre</p>
              <p>Inside Srikrishna Medical College & Hospital</p>
              <p>Umanagar, Muzaffarpur-842004.</p>
              <p>Contact: ngs.mfp@gmail.com</p>
            </div>
          </div>
          <div class="col">
            <p class="text-right">
              <img src="../../../assets/tmc/tmc_footer.png" alt="footer_image" class="footer_img" />
            </p>
          </div>
        </div>
      </div> -->

      <!-- page 3_1 -->


      <!-- page 3_2 -->


      <!-- page 3_3 -->


      <!-- page 3_4 -->


      <!-- page 3_5 -->


      <!-- page 3_6 -->


      <!-- page 3_7 -->


      <!-- page 3_8 -->


      <!-- page 3_9 -->


          <!-- page 4 -->
          <!-- <div id="page4" *ngIf="prognasticCount>0" class="container position-relative page" style="background-color: white">
              <div class="d-flex justify-content-between logo_panel py-3">
                <div class="logo_part">
                  <img src="../../../assets/tmc/tmc_log1.png" alt="" class="logo_first" />
                </div>
                <div class="logo_part text-center">
                  <img src="../../../assets/tmc/tmc_logo2.png" alt="" class="logo_two" />
                  <p style="font-size: 10px">
                    An Initiative of Bihar Govt. & Doctors For You
                  </p>
                  <p class="text-uppercase">MEDICAL GENOMICS & MOLECULAR ONCOLOGY</p>
                </div>
                <div class="logo_part">
                  <img src="../../../assets/tmc/tmc_logo3.jpg" alt="" class="logo_three" />
                </div>
              </div>
              <div class="row header mt-1">

              <div></div>
              <div class="col">
                <div class="patient-detailes ml-auto float-right">
                  <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
                    <li>
                      Patient Id <br />
                      <span>{{ report.patient?.organisationPatientId }}</span>
                    </li>
                    <li class="ml-4">
                      Patient Name<br />
                      <span>{{ report.patient?.name }}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <div *ngIf="report.clientType == '4basecare_client'">
                <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
                  </div>
                </div>
                <div *ngIf="report.clientName == 'path_labs'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
                  </div>
                </div>
              </div>
            </div>
            <div class="mar_top"></div>
            <div *ngIf="prognasticCount>0">
              <div>
                <h6 class="py-2 h6_bg"> Alterations associated with prognostic significance</h6>

              </div>

              <div class="row">
                <div class="col-12">
                  <table class="page_1 cust_table w-100 mt-3 varient_details prognastictable" [ngClass]="'table_variant-blue'">
                    <thead>
                      <tr>
                        <th>Gene (Alterations)</th>
                        <th>Prognostic Significance</th>
                        <th>Summary</th>

                      </tr>
                    </thead>

                    <ng-container *ngFor="
                                                                let selectedReportedVariant of reportPdfRes.snvReportedVariantList
                                                              ">
                      <tr *ngIf="selectedReportedVariant.prognasticSignificance!=null">
                        <td style="padding-left: 3px !important">
                          <i>{{ selectedReportedVariant.refGeneX }}</i>
                          <span *ngIf="getpposition(selectedReportedVariant.selectedAAChangeknownGene, selectedReportedVariant.exonicFuncknownGene)!=null">
                            ({{getpposition(selectedReportedVariant.selectedAAChangeknownGene?.replace(' ',''), selectedReportedVariant.exonicFuncknownGene)}})</span>
                        </td>
                        <td style="padding-left: 3px !important">
                          {{selectedReportedVariant.prognasticSignificance}}
                        </td>
                        <td style="padding-left: 3px !important">
                          <span [innerHtml]="selectedReportedVariant.prognasticDescription"></span>
                        </td>

                      </tr>
                    </ng-container>
                    <ng-container  *ngFor="
                  let selectedReportedVariant of reportPdfRes.cnaReportedVariantList
                ">
                    <tr
                    *ngIf="selectedReportedVariant.prognasticSignificance!=null"
                  >
                    <td
                      style="padding-left: 3px !important"
                    >
                    <i>{{ selectedReportedVariant.gene }} </i>
                    <span *ngIf="selectedReportedVariant.sample!=null
                    ">&nbsp;Copy Number
                      {{ selectedReportedVariant.sample.split(":")[2] | titlecase }}
                      ({{ selectedReportedVariant.sample.split("|")[1].split(":")[0] }})
                    </span>

                    </td>
                    <td
                      style="padding-left: 3px !important"
                    >
                    {{selectedReportedVariant.prognasticSignificance}}
                    </td>
                    <td
                      style="padding-left: 3px !important"
                    >
                    <span [innerHtml]="selectedReportedVariant.prognasticDescription"></span>
                    </td>

                  </tr>
                  </ng-container>


                  </table>
                </div>
              </div>
            </div>


            <div class="row footer d-flex justify-content-between align-items-center">
              <div class="col">
                <div class="py-2">
                  <p>
                    <span class="font-weight-bold">Genomics Bihar,</span>
                  </p>
                  <p>Homi Bhabha cancer hospital & Research centre</p>
                  <p>Inside Srikrishna Medical College & Hospital</p>
                  <p>Umanagar, Muzaffarpur-842004.</p>
                  <p>Contact: ngs.mfp@gmail.com</p>
                </div>
              </div>
              <div class="col">
                <p class="text-right">
                  <img
                    src="../../../assets/tmc/tmc_footer.png"
                    alt="footer_image"
                    class="footer_img"
                  />
                </p>
              </div>
            </div>
          </div> -->

          <!-- Page 5 -->
          <div id="page5" class="container position-relative page" style="background-color: white">
              <div class="d-flex justify-content-between logo_panel py-3">
                <div class="logo_part">
                  <img src="../../../assets/tmc/tmc_log1.png" alt="" class="logo_first" />
                </div>
                <div class="logo_part text-center">
                  <img src="../../../assets/tmc/tmc_logo2.png" alt="" class="logo_two" />
                  <p style="font-size: 10px">
                    An Initiative of Bihar Govt. & Doctors For You
                  </p>
                  <p class="text-uppercase">MEDICAL GENOMICS & MOLECULAR ONCOLOGY</p>
                </div>
                <div class="logo_part">
                  <img src="../../../assets/tmc/tmc_logo3.jpg" alt="" class="logo_three" />
                </div>
              </div>
              <div class="row header mt-1">
              <div></div>
              <div class="col">
                <div class="patient-detailes ml-auto float-right">
                  <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
                    <li>
                      Patient Id <br />
                      <span>{{ report.patient?.organisationPatientId }}</span>
                    </li>
                    <li class="ml-4">
                      Patient Name<br />
                      <span>{{ report.patient?.name }}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <!--  -->
              <div *ngIf="report.clientType == '4basecare_client'">
                <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
                  </div>
                </div>
                <div *ngIf="report.clientName == 'path_labs'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
                  </div>
                </div>
              </div>
            </div>
            <!--  -->
            <div class="mar_top"></div>
            <!--  -->

            <h6 class="py-2 h6_bg" [ngClass]="'skyBlue'">References</h6>
            <!--  -->
            <div class="row">
              <div class="col">
                <div class="disc">
                  <div class="ref_data mt-4">
                    <ul class="break-word" style="text-align: justify">
                      <li *ngFor="let referenceArticle of reportPdfRes.referenceArticles" style="
                                                          text-align: justify;
                                                          /* white-space:pre-wrap; */
                                                          word-break: break-word;
                                                        ">
                        {{ referenceArticle }}
                      </li>
                    </ul>
                  </div>
                </div>

                <!-- <div class="img_signs">
                                                <img src="../../../assets/signatures.png" alt="" class="img-fluid">
                                              </div> -->
                <!--  -->

                <!--  -->
                <!-- <div class="mt-4">
                                                <h6 class="mt-3 mb-4 py-2 h6_bg" >
                                                  GENES EVALUATED </h6>
                                                 <p><span class="font-weight-normal">taR</span><b>GT</b> <span class="clr-cyan pl-2">First</span> detects mutations (SNVs and Short Indels), Copy Number Variations (CNVs), gene fusions and splice variants in the 53 genes :</p>
                                              </div> -->

                <!-- <div class="row mx-0">
                                                <div class="col-7 px-0">
                                                  <div class="genes_info">
                                                    <div class="title py-2">
                                                      <h6 class="mb-0 d-inline-block border-bottom  py-2"><b>SNVs/InDels</b> Coverage in <span class="font-weight-normal">TAR</span><b>GT</b> <span class="clr-cyan pl-2">First</span></h6></div>
                                                 <div class="gene_body">
                                                  <div class="gene_list"><span>ABL1</span><span>ALK</span><span>AR</span><span>ATM</span><span>BARD1</span><span>BRAF</span><span>BRCA1</span><span>BRCA2</span><span>BRIP1</span><span>CDK12</span></div>
                                                  <div class="gene_list"><span>CDK4</span><span>CDK6</span><span>CDKN2A</span><span>CHEK1</span><span>CHEK2</span><span>EGFR</span><span>EPCAM</span><span>ERBB2</span><span>ERBB3</span><span>EZH2</span></div>
                                                  <div class="gene_list"><span>FANCL</span><span>FGFR3</span><span>GAPDH</span><span>IDH1</span><span>IDH2</span><span>JAK2</span><span>KIT</span><span>KRAS</span><span>MAP2K1</span><span>MAP2K2</span></div>
                                                  <div class="gene_list"><span>MDM2</span><span>MET</span><span>MLH1</span><span>MLH3</span><span>MSH2</span><span>MSH6</span><span>NRAS</span><span>PALB2</span><span>PDGFRA</span><span>PDGFRB</span></div>
                                                  <div class="gene_list"><span>PIK3CA</span><span>PMS1</span><span>PMS2</span><span>PPP2R2A</span><span>RAD51B</span><span>RAD51C</span><span>RAD51D</span><span>RAD54L</span><span>RET</span><span>ROS1</span></div>
                                                  <div class="gene_list"><span>MDM2</span><span>MET</span><span>MLH1</span></div>
                                                 </div>
                                                    </div>
                                                </div>
                                                <div class="col">
                                                  <div class="title py-2">
                                                    <h6 class=" mb-0 d-inline-block border-bottom  py-2"><b>Gene Fusions </b> Coverage in <span class="font-weight-normal">TAR</span><b>GT</b> <span class="clr-cyan pl-2">First</span></h6></div>
                                                </div>


                                              </div> -->
              </div>
            </div>

            <div class="row pr-2 above-footer">
              <div class="col pl-5">
                <img
                  src="../../../assets/tmc/basusigns1.png"
                  alt=""
                  class="mb-2 sign_width"
                />
                <div class="sign_text">
                  <p>Dr. Moitri Basu, Ph.D.</p>
                  <p>Scientific Officer</p>
                  <p>Homi Bhabha cancer hospital & Research centre, <br/>Muzaffarpur, A unit of Tata Memorial centre.</p>
                </div>
              </div>
            </div>
            <!--  -->
            <div class="row footer d-flex justify-content-between align-items-center">
              <div class="col">
                <div class="py-2">
                  <p>
                    <span class="font-weight-bold">Genomics Bihar,</span>
                  </p>
                  <p>Homi Bhabha cancer hospital & Research centre</p>
                  <p>Inside Srikrishna Medical College & Hospital</p>
                  <p>Umanagar, Muzaffarpur-842004.</p>
                  <p>Contact: ngs.mfp@gmail.com</p>
                </div>
              </div>
              <div class="col">
                <p class="text-right">
                  <img
                    src="../../../assets/tmc/tmc_footer.png"
                    alt="footer_image"
                    class="footer_img"
                  />
                </p>
              </div>
            </div>
          </div>

          <!-- Page 6 -->
          <div id="page6" class="container position-relative page" style="background-color: white">
              <div class="d-flex justify-content-between logo_panel py-3">
                <div class="logo_part">
                  <img src="../../../assets/tmc/tmc_log1.png" alt="" class="logo_first" />
                </div>
                <div class="logo_part text-center">
                  <img src="../../../assets/tmc/tmc_logo2.png" alt="" class="logo_two" />
                  <p style="font-size: 10px">
                    An Initiative of Bihar Govt. & Doctors For You
                  </p>
                  <p class="text-uppercase">MEDICAL GENOMICS & MOLECULAR ONCOLOGY</p>
                </div>
                <div class="logo_part">
                  <img src="../../../assets/tmc/tmc_logo3.jpg" alt="" class="logo_three" />
                </div>
              </div>
              <div class="row header mt-1">
              <div></div>
              <div class="col">
                <div class="patient-detailes ml-auto float-right">
                  <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
                    <li>
                      Patient Id <br />
                      <span>{{ report.patient?.organisationPatientId }}</span>
                    </li>
                    <li class="ml-4">
                      Patient Name<br />
                      <span>{{ report.patient?.name }}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <!--  -->
              <div *ngIf="report.clientType == '4basecare_client'">
                <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
                  </div>
                </div>
                <div *ngIf="report.clientName == 'path_labs'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
                  </div>
                </div>
              </div>
            </div>
            <!--  -->
            <div class="mar_top"></div>
            <!--  -->

            <div class="">
              <h6 class="py-2 h6_bg" [ngClass]="'skyBlue'">TEST DESCRIPTION</h6>
              <p class="mb-2" style="text-align:justify">
                <span class="font-weight-normal">TAR</span><b>GT</b>
                <span class="clr-cyan pl-2" [ngClass]="'txt-skyblue'">First Solid</span>
                is a Next Generation Sequencing based test which identifies
                genetic alterations in a comprehensive panel of well curated 72  genes which are having an impact response to approved
                therapy for a particular cancer type. Some of the alterations
                detected may have bearing on prognosis and/or therapeutic options
                and may provide relevant information that allows
                oncologists/clinicians to consider various lines of
                targeted treatment for the patient.
              </p>
            </div>

            <!--  -->
            <div class="row">
              <div class="col">
                <div class="">
                  <h6 class="py-2 h6_bg" [ngClass]="'skyBlue'">GENES EVALUATED</h6>
                  <p>
                    <span class="font-weight-normal">TAR</span><b>GT</b>
                    <span class="clr-cyan pl-2" [ngClass]="'txt-skyblue'">First Solid</span>
                    detects mutations (SNVs and Short InDels), Copy Number Variations (CNVs),
                    Gene Fusions and splice variants in the 72 genes:
                    <!-- detects mutations (SNVs and Short Indels), Copy Number Variations
                                                  (CNVs), gene fusions and splice variants in the 53 genes : -->
                  </p>
                </div>
                <!--  -->
                <div class="row mx-0">
                  <div class="col-12 px-0">
                    <div class="genes_info">
                      <div class="title py-2">
                        <h6 class="mb-0 d-inline-block border-bottom py-2">
                          <b>SNVs, SHORT INDELS and CNVs
                          </b> Covered in
                          <span class="font-weight-normal">TAR</span><b>GT</b>
                          <span class="clr-cyan pl-2" [ngClass]="'txt-skyblue'">First Solid</span>
                        </h6>
                      </div>
                      <div class="gene_body" [ngClass]="'gene-bg_blue'">
                        <i>
                          <div class="gene_list">
                            <span>ABL1</span><span>ALK</span><span>APC</span>
                            <span>AR</span><span>ATM</span><span>BARD1</span>
                            <span>BMPR1A</span><span>BRAF</span><span>BRCA1</span>
                            <span>BRCA2</span>
                            <!-- <span>ABL1</span><span>ALK</span><span>AR</span
                                                          ><span>ATM</span><span>BARD1</span><span>BRAF</span
                                                          ><span>BRCA1</span><span>BRCA2</span><span>BRIP1</span
                                                          ><span>CDK12</span> -->
                          </div>
                          <div class="gene_list bg-white">
                            <span>BRIP1</span><span>CDK12</span><span>CDK4</span>
                            <span>CDK6</span><span>CDKN2A</span><span>CHEK1</span>
                            <span>CHEK2</span><span>CTNNB1</span><span>EGFR</span>
                            <span>EPCAM</span>
                            <!-- <span>CDK4</span><span>CDK6</span><span>CDKN2A</span
                                                          ><span>CHEK1</span><span>CHEK2</span><span>EGFR</span
                                                          ><span>EPCAM</span><span>ERBB2</span><span>ERBB3</span
                                                          ><span>EZH2</span> -->
                          </div>
                          <div class="gene_list">
                            <span>ERBB2</span><span>ERBB3</span><span>EZH2</span>
                            <span>FANCL</span><span>FGFR1</span><span>FGFR2</span>
                            <span>FGFR3</span><span>GAPDH</span><span>IDH1</span>
                            <span>IDH2</span>
                            <!-- <span>FANCL</span><span>FGFR3</span><span>GAPDH</span
                                                          ><span>IDH1</span><span>IDH2</span><span>JAK2</span
                                                          ><span>KIT</span><span>KRAS</span><span>MAP2K1</span
                                                          ><span>MAP2K2</span> -->
                          </div>
                          <div class="gene_list">
                            <span>JAK2</span><span>KIT</span><span>KRAS</span>
                            <span>MAP2K1</span><span>MAP2K2</span><span>MDM2</span>
                            <span>MET</span><span>MLH1</span><span>MLH3</span>
                            <span>MSH2</span>
                            <!-- <span>MDM2</span><span>MET</span><span>MLH1</span
                                                          ><span>MLH3</span><span>MSH2</span><span>MSH6</span
                                                          ><span>NRAS</span><span>PALB2</span><span>PDGFRA</span
                                                          ><span>PDGFRB</span> -->
                          </div>
                          <div class="gene_list">
                            <span>MSH6</span><span>MUTYH</span><span>NRAS</span>
                            <span>PALB2</span><span>PDGFRA</span><span>PDGFRB</span>
                            <span>PIK3CA</span><span>PMS1</span><span>PMS2</span>
                            <span>POLD1</span>
                            <!-- <span>PIK3CA</span><span>PMS1</span><span>PMS2</span
                                                          ><span>PPP2R2A</span><span>RAD51B</span><span>RAD51C</span
                                                          ><span>RAD51D</span><span>RAD54L</span><span>RET</span
                                                          ><span>ROS1</span> -->
                          </div>
                          <div class="gene_list">
                            <span>POLE</span><span>POLH</span><span>PTEN</span>
                            <span>RAD50</span><span>RAD51</span><span>RAD51B</span>
                            <span>RAD51C</span><span>RAD51D</span><span>RAD54L</span>
                            <span>RB1</span>
                            <!-- <span>STK11</span><span>TSC1</span><span>TSC2</span> -->
                          </div>
                          <div class="gene_list">
                            <span>RET</span><span>ROS1</span><span>SMAD4</span>
                            <span>STK11</span><span>TP53</span><span>TSC1</span>
                            <span>TSC2</span>
                            <!-- <span>STK11</span><span>TSC1</span><span>TSC2</span> -->
                          </div>
                        </i>
                      </div>

                      <!-- <div class="title py-2 mt-4">
                                                      <h6 class="mb-0 d-inline-block border-bottom py-2">
                                                        <b>CNAs</b> Covered in
                                                        <span class="font-weight-normal">TAR</span><b>GT</b>
                                                        <span class="clr-cyan pl-2" [ngClass]="'txt-skyblue'"
                                                          >First</span
                                                        >
                                                      </h6>
                                                    </div> -->
                      <!-- <div class="gene_body" [ngClass]="'gene-bg_blue'">
                                                      <i>
                                                        <div class="gene_list">
                                                          <span>ABL1</span><span>AR</span><span>ATM</span
                                                          ><span>BARD1</span><span>BRCA1</span><span>BRCA2</span
                                                          ><span>BRIP1</span><span>CDK12</span><span>CDK4</span
                                                          ><span>CDK6</span>
                                                        </div>
                                                        <div class="gene_list">
                                                          <span>CDKN2A</span><span>CHEK1</span><span>CHEK2</span
                                                          ><span>EGFR</span><span>EPCAM</span><span>ERBB2</span
                                                          ><span>ERBB3</span><span>EZH2</span><span>FANCL</span
                                                          ><span>FGFR3</span>
                                                        </div>
                                                        <div class="gene_list">
                                                          <span>GAPDH</span><span>IDH2</span><span>JAK2</span
                                                          ><span>KIT</span><span>MDM2</span><span>NRAS</span
                                                          ><span>PALB2</span><span>PDGFRA</span><span>PDGFRB</span
                                                          ><span>PPP2R2A</span>
                                                        </div>
                                                        <div class="gene_list">
                                                          <span>RAD51B</span><span>RAD51C</span><span>RAD51D</span
                                                          ><span>RAD54L</span><span>STK11</span><span>TSC1</span
                                                          ><span>TSC2</span>
                                                        </div>
                                                      </i>
                                                    </div> -->
                    </div>
                  </div>
                  <!-- cnv_genes -->

                  <!-- <div class="col-12 px-0">
                    <div class="genes_info">
                      <div class="title py-2">
                        <h6 class="mb-0 d-inline-block border-bottom py-2">
                          <b> CNVs
                          </b> Covered in
                          <span class="font-weight-normal">TAR</span><b>GT</b>
                          <span class="clr-cyan pl-2" [ngClass]="'txt-skyblue'"
                            >First Solid</span
                          >
                        </h6>
                      </div>
                      <div class="gene_body" [ngClass]="'gene-bg_blue'">
                        <i>
                          <div class="gene_list">
                            <span>ABL1</span><span>ALK</span><span>APC</span>
                            <span>AR</span><span>ATM</span><span>BARD1</span>
                            <span>BMPR1A</span><span>BRAF</span><span>BRCA1</span>
                            <span>BRCA2</span>
                          </div>
                          <div class="gene_list bg-white">
                            <span>BRIP1</span><span>CDK12</span><span>CDK4</span>
                            <span>CDK6</span><span>CDKN2A</span><span>CHEK1</span>
                            <span>CHEK2</span><span>CTNNB1</span><span>EGFR</span>
                            <span>EPCAM</span>
                          </div>
                          <div class="gene_list">
                            <span>ERBB2</span><span>ERBB3</span><span>EZH2</span>
                            <span>FANCL</span><span>FGFR1</span><span>FGFR2</span>
                            <span>FGFR3</span><span>GAPDH</span><span>IDH2</span>
                            <span>JAK2</span>
                          </div>
                          <div class="gene_list">
                           <span>KIT</span><span>MDM2</span>
                            <span>MET</span><span>MLH1</span><span>MUTYH</span>
                            <span>NRAS</span><span>PALB2</span><span>PDGFRA</span>
                            <span>PDGFRAB</span><span>POLD1</span>
                          </div>

                          <div class="gene_list">
                            <span>POLE</span><span>POLH</span><span>PTEN</span>
                            <span>RAD50</span><span>RAD51</span><span>RAD51B</span>
                            <span>RAD51C</span><span>RAD51D</span><span>RAD54L</span>
                            <span>RB1</span>
                          </div>
                          <div class="gene_list">
                            <span>RET</span><span>ROS1</span><span>SMAD4</span>
                            <span>STK11</span><span>TP53</span><span>TSC1</span>
                            <span>TSC2</span>
                          </div>
                        </i>
                      </div>


                    </div>
                  </div> -->


                  <div class="col-12 px-0">
                    <div class="genes_info">
                      <div class="title py-2">
                        <h6 class="mb-0 d-inline-block border-bottom py-2">
                          <b>Gene Fusions/Splicing Variations</b> Covered in
                          <span class="font-weight-normal">TAR</span><b>GT</b>
                          <span class="clr-cyan pl-2" [ngClass]="'txt-skyblue'">First Solid</span>
                        </h6>
                      </div>
                      <div class="gene_body" [ngClass]="'gene-bg_blue'">
                        <i>
                          <div class="gene_list">
                          <span>ALK</span><span>FGFR2</span><span>FGFR3</span>
                          <span>MET</span><span>NRG1</span><span>NRG2</span>
                          <span>NTRK1</span><span>NTRK2</span> <span>NTRK3</span><span>RET</span>

                          </div>
                          <div class="gene_list">
                            <span>ROS1</span>
                          </div>
                        </i>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>

            <!--  -->
            <div class="row footer d-flex justify-content-between align-items-center">
              <div class="col">
                <div class="py-2">
                  <p>
                    <span class="font-weight-bold">Genomics Bihar,</span>
                  </p>
                  <p>Homi Bhabha cancer hospital & Research centre</p>
                  <p>Inside Srikrishna Medical College & Hospital</p>
                  <p>Umanagar, Muzaffarpur-842004.</p>
                  <p>Contact: ngs.mfp@gmail.com</p>
                </div>
              </div>
              <div class="col">
                <p class="text-right">
                  <img
                    src="../../../assets/tmc/tmc_footer.png"
                    alt="footer_image"
                    class="footer_img"
                  />
                </p>
              </div>
            </div>
          </div>

          <!-- Page 7 -->
          <div id="page7" class="container position-relative page" style="background-color: white">
              <div class="d-flex justify-content-between logo_panel py-3">
                <div class="logo_part">
                  <img src="../../../assets/tmc/tmc_log1.png" alt="" class="logo_first" />
                </div>
                <div class="logo_part text-center">
                  <img src="../../../assets/tmc/tmc_logo2.png" alt="" class="logo_two" />
                  <p style="font-size: 10px">
                    An Initiative of Bihar Govt. & Doctors For You
                  </p>
                  <p class="text-uppercase">MEDICAL GENOMICS & MOLECULAR ONCOLOGY</p>
                </div>
                <div class="logo_part">
                  <img src="../../../assets/tmc/tmc_logo3.jpg" alt="" class="logo_three" />
                </div>
              </div>
              <div class="row header mt-1">
              <div></div>
              <div class="col">
                <div class="patient-detailes ml-auto float-right">
                  <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
                    <li>
                      Patient Id <br />
                      <span>{{ report.patient?.organisationPatientId }}</span>
                    </li>
                    <li class="ml-4">
                      Patient Name<br />
                      <span>{{ report.patient?.name }}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <!--  -->
              <div *ngIf="report.clientType == '4basecare_client'">
                <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
                  </div>
                </div>
                <div *ngIf="report.clientName == 'path_labs'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
                  </div>
                </div>
              </div>
            </div>
            <!--  -->
            <div class="mar_top"></div>
            <!--  -->
            <h6 class="py-2 h6_bg mt-3" [ngClass]="'skyBlue'">TEST METHODOLOGY</h6>
            <!--  -->
            <div class="row mt-3">
              <div class="col">
                <h6 class="font-weight-bold">
                  Sample preparation and Library preparation :
                </h6>
                <!-- <p>
                                                DNA isolated from FFPE, or any other fresh tumor tissue source was
                                                used to perform targeted gene capture using a custom capture kit.
                                                The libraries were sequenced to mean >350X coverage on Illumina
                                                sequencing platform.
                                              </p> -->
                <p style="text-align: justify;">
                  DNA isolated from FFPE, or any other fresh tumor tissue source was used to perform targeted gene capture using
                  a custom capture kit. The libraries were sequenced to mean
                  >350X coverage on Illumina sequencing platform.
                </p>
              </div>
            </div>
            <!--  -->
            <div class="row mt-4">
              <div class="col">
                <h6 class="font-weight-bold">
                  Bioinformatics Analysis and Reporting :
                </h6>
                <!-- <p>
                                                The sequences obtained are aligned to human reference genome
                                                (GRCh37/hg19) and variant analysis was performed using set of
                                                Bioinformatics Pipeline. Only non-synonymous and splice site
                                                variants found in the panel consisting of specific set of genes were
                                                used for clinical interpretation. Silent variations that do not
                                                result in any change in amino acid in the coding region are not
                                                reported. Clinically relevant mutations were annotated using
                                                published variants in literature and a set of databases – ClinVar,
                                                COSMIC and dbSNP. Common variants are filtered based on allele
                                                frequency in 1000 Genome Phase 3, ExAC, dbSNP, gnomAD, etc. In the
                                                absence of a clinically significant reported known variation(s),
                                                pathogenicity will be predicted based on <i>in-silico</i> gene
                                                prioritization tools: CADD, SIFT, PolyPhen-2, Condel and Mutation
                                                taster and prioritized for clinical correlation. The identified
                                                pathogenic variant will be correlated with observed phenotypic
                                                features of the patient and interpreted according to American
                                                College of Medical Genetics (ACMG) guidelines.
                                              </p>
                                              <p class="mb-2 d-block">
                                                Somatic variants are classified into three tiers based on their level
                                                of clinical significance in cancer diagnosis, prognosis, and/or
                                                therapeutics as per international guidelines: ACMG, ASCO, AMP, CAP,
                                                NCCN and ESMO
                                              </p> -->
                <p style="text-align: justify;">
                  The sequences obtained are aligned to human reference genome (GRCh37/hg19) and variant analysis was performed
                  using set of Bioinformatics Pipeline. Only non-synonymous and splice site variants found in the panel
                  consisting of specific set of genes were used for clinical interpretation. Silent variations that do not
                  result in any change
                  in amino acid in the coding region are not reported. Clinically relevant mutations were annotated using
                  published variants in literature and a set of databases – ClinVar, COSMIC
                  and dbSNP. Common variants are filtered based on allele frequency in 1000 Genome Phase 3, ExAC, dbSNP, gnomAD,
                  etc. In the absence of a clinically significant reported
                  known variation(s), pathogenicity will be predicted based on <i>in-silico</i> gene prioritization tools: CADD, SIFT,
                  PolyPhen-2, Condel and Mutation taster and prioritized for clinical
                  correlation. The identified pathogenic variant will be correlated with observed phenotypic features of the patient and interpreted according to ACMG/AMP guidelines.
                </p>
                <p class="mb-2 d-block" style="text-align: justify;">
                  Somatic variants are classified into three tiers based on their level of clinical significance in cancer
                  diagnosis, prognosis, and/or therapeutics as per international guidelines:
                  ACMG, ASCO, AMP, CAP, NCCN and ESMO.
                </p>
              </div>
            </div>

            <h6 class="py-2 h6_bg mt-2" [ngClass]="'skyBlue'">
              LIMITATIONS AND DISCLAIMER
            </h6>

            <div class="row">
              <div class="col">
                <div class="disc">
                  <ul class="ml-0 pl-4" style="text-align: justify;">
                    <li>
                      TARGT FIRST SOLID test has been developed, validated and performed by 4baseCare Oncosolutions Pvt. Ltd and
                      has not been cleared or approved by the FDA.
                    </li>
                    <li>
                      DNA studies do not constitute a definitive test for the selected condition(s) in all individuals. It
                      should be realized that there are possible sources of error. Errors can result
                      from trace contamination, rare technical errors, rare genetic variants that interfere with analysis,
                      recent scientific developments, and alternative classification systems. This
                      test should be one of the many aspects used by the healthcare provider to help with a diagnosis and
                      treatment plan.
                    </li>
                    <li>
                      We are using the canonical transcript for clinical reporting which is usually the longest coding
                      transcript with strong/multiple supporting evidence. However, in rare cases,
                      clinically relevant variants annotated in alternate complete coding transcripts could also be reported.
                    </li>
                    <li>
                      The contents of this test should be carefully assessed by the treating physician and further interpreted
                      along with clinical, histopathological findings, contraindications and
                      guidelines before deciding the course of therapy.
                    </li>
                    <li>
                      The CNVs detected must be confirmed by an alternate method, such as IHC, for further clinical management decisions.
                    </li>
                    <!-- <li>
                      The sensitivity of this assay to detect large deletions/duplications of more than 10 bp or copy number
                      variations (CNV) is 70-75%. The CNVs detected have to be confirmed
                      by alternate method.
                    </li> -->
                    <li>
                      Most recent block is recommended for testing as the mutation profile may change in response to treatment
                      and hence differ at different sampling points.
                    </li>
                    <li>
                      The identified pathogenic variant will be correlated with observed phenotypic features of the patient and interpreted according to AMP guidelines.
                    </li>
                    <li>
                      Certain genes may not be covered completely, and few mutations could be missed. A negative result cannot
                      rule out the possibility that the tested tumor sample carries
                      mutations not previously associated with cancer and hence not included in the panel.
                    </li>
                    <li>
                      Our limit of detection for TARGT FIRST SOLID is 5% for SNVs, 10% for InDels and CNV gain &#8805; 6. In addition
                      to this, sequencing quality and coverage is dependent on many factors
                      such as homopolymers, GC-rich regions, intrinsic quality of FFPE DNA and that might impact the variant
                      detection.
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <!--  -->
            <div class="row footer d-flex justify-content-between align-items-center">
              <div class="col">
                <div class="py-2">
                  <p>
                    <span class="font-weight-bold">Genomics Bihar,</span>
                  </p>
                  <p>Homi Bhabha cancer hospital & Research centre</p>
                  <p>Inside Srikrishna Medical College & Hospital</p>
                  <p>Umanagar, Muzaffarpur-842004.</p>
                  <p>Contact: ngs.mfp@gmail.com</p>
                </div>
              </div>
              <div class="col">
                <p class="text-right">
                  <img
                    src="../../../assets/tmc/tmc_footer.png"
                    alt="footer_image"
                    class="footer_img"
                  />
                </p>
              </div>
            </div>
          </div>
        </div>

        <!-- For TMH Template 2-->
        <div *ngIf="!is4basecare && isTMH && temp=='temp2'">

          <!-- Page 1 -->
          <div id="page1" class="container position-relative page" style="background-color: white">
            <div class="d-flex justify-content-between logo_panel py-3">
              <div class="logo_part">
                <img src="../../../assets/tmc/tmc_log1.png" alt="" class="logo_first" />
              </div>
              <div class="logo_part text-center">
                <img src="../../../assets/tmc/tmc_logo2.png" alt="" class="logo_two" />
                <p style="font-size: 10px">
                  An Initiative of Bihar Govt. & Doctors For You
                </p>
                <p class="text-uppercase">MEDICAL GENOMICS & MOLECULAR ONCOLOGY</p>
              </div>
              <div class="logo_part">
                <img src="../../../assets/tmc/tmc_logo3.jpg" alt="" class="logo_three" />
              </div>
            </div>
            <!--  -->
            <div class="mar_top"></div>
            <!--  -->
            <div class="row">
              <div class="col-7">
                <div class="">
                  <div class="w-50" style="min-height: 80px">
                    <p class="py-1 font-weight-bold" [ngClass]="'txt-skyblue'">
                      SCOPE OF THE TEST
                    </p>
                    <p>SNVs, InDels, CNAs, Gene Fusions status</p>
                  </div>
                  <img src="../../../assets/BorderLine.png" class="mr-2 w-100" />
                </div>
              </div>
              <div class="col">
                <div class="">
                  <div class="w-100">
                    <!-- <p style="color: #208dcb;" class="py-1">
                                                            <span style="color: #000 !important"></span>
                                                          </p> -->
                    <div class="w-100" style="min-height: 80px">
                      <p [ngClass]="'txt-skyblue'" class="py-1 font-weight-bold" *ngIf="report.clinicalSummary != null">
                        <span *ngIf="report.clinicalSummary != ' '">CLINICAL INDICATION</span>
                      </p>

                      <p *ngIf="report.clinicalSummary != null">
                        <span style="color: #000 !important" *ngIf="report.clinicalSummary != ' '">{{ report.clinicalSummary
                          }}</span>
                      </p>
                    </div>
                    <!-- </div> -->
                    <img src="../../../assets/BorderLine.png" class="mr-2 w-100" />

                    <!-- </div> -->
                  </div>
                </div>
              </div>
            </div>
            <!--  -->
            <div class="row pt-2">
              <div class="col-7">
                <h6 class="text-uppercase py-2 font-weight-bold">Report Details</h6>
                <ul class="list-unstyled">
                  <li class="pb-1">
                    <span class="clr-cyan">Patient Name : </span>
                    <span>{{ report.patient?.name }}</span>
                  </li>
                  <li class="pb-1">
                    <span class="clr-cyan" [ngClass]="'txt-skyblue'">Gender : </span>
                    <span class="text-black">{{
                      report.patient?.gender | titlecase
                    }}</span>
                  </li>
                  <li class="pb-1">
                    <span class="clr-cyan" [ngClass]="'txt-skyblue'">Age/DOB : </span>
                    <span class="text-black">{{
                      report.patient?.dateOfBirth
                        ? (report.patient?.dateOfBirth | date : "dd/MM/yyyy")
                        : report.patient?.age + " Years"
                    }}</span>
                  </li>
                  <li class="pb-1">
                    <span class="clr-cyan">Case No : </span
                    ><span>{{ report?.tmhReportInfo?.caseNo }}</span>
                  </li>
                  <li class="pb-1">
                    <span class="clr-cyan">Path No : </span
                    ><span>{{ report?.tmhReportInfo?.pathNo }}</span>
                  </li>
                  <li class="pb-1">
                    <span class="clr-cyan">Referred By : </span
                    ><span>{{ referringClinician }}</span>
                  </li>
                </ul>
              </div>
              <div class="col">
                <ul class="list-unstyled mt-4 pt-4">
                  <li class="pb-1">
                    <span class="clr-cyan">Patient ID : </span
                    ><span>{{ report.patient?.organisationPatientId }}</span>
                  </li>
                  <li class="pb-1">
                    <span class="clr-cyan">Category : </span
                    ><span>{{ report?.tmhReportInfo?.category }}</span>
                  </li>
                  <li class="pb-1">
                    <span class="clr-cyan">Collected on : </span
                    ><span>{{
                      report.labDetails?.sampleCollected | date : "dd/MM/yyyy"
                    }}</span>
                  </li>
                  <li class="pb-1">
                    <span class="clr-cyan">Reported on : </span
                    ><span>{{ date | date : "dd/MM/yyyy" }}</span>
                  </li>
                  <li class="pb-1">
                    <span class="clr-cyan">Hospital Name : </span
                    ><span>{{ labHospital }}</span>
                  </li>
                </ul>
              </div>
            </div>
            <!--  -->
            <h6 class="py-2 h6_bg">CLINICAL HISTORY/SAMPLE DETAILS</h6>
            <div class="row mt-2 py-2">
              <div class="col-5">
                <h6 class="mb-0 font-weight-bold">Clinical Evaluations</h6>
                <span>{{ report?.cancerIdentifier }}</span>
              </div>
              <div class="col-5">
                <h6 class="mb-0 font-weight-bold">Sample Details</h6>
                <span *ngIf="report.labDetails?.sampleSource">{{
                  report.labDetails?.sampleSource
                  }}</span>
                <span *ngIf="!report.labDetails?.sampleSource">FFPE Block</span>
              </div>
              <div class="col">
                <h6 class="mb-0 font-weight-bold">Tumor content</h6>
                <span>{{ report?.tmhReportInfo?.tumorContent }}</span>
              </div>
              <div class="w-100">
                <div class="col pt-4">
                  <p>(Confirmed by - Consultant Pathologist, HBCHRC, Muzaffarpur)</p>
                </div>
              </div>
            </div>
            <!--  -->
            <div class="row mt-2 py-2">
              <div class="col">
                <h6 class="mb-0 text-uppercase f-weight_700">Results</h6>
              </div>
            </div>
            <h6 class="py-2 h6_bg" [ngClass]="'skyBlue'">
              Genomic findings from tumor profiling
            </h6>

            <!--  -->
            <div class="row">
              <div style="width: 100%;" *ngIf="isReportDownload">
                <img [src]="firstPageTable" alt="" width="100%" class="img-fluid" />
              </div>
              <div class="col-12" id="firstPageTable" *ngIf="!isReportDownload">
                <table class="page_1 cust_table w-100 mt-3 first_1" [ngClass]="'table_skyblue'">
                  <tr>
                    <th>Genomic Alteration</th>
                    <th>Relevant Therapies (in Same Cancer Type)</th>
                    <th>Relevant Therapies (in Different Cancer)</th>
                  </tr>

                  <tr *ngIf="tableCount== 0 && prognasticCount>0 ">
                    <td colspan="3" class="text-center mt-2">
                      Clinically relevant genomic alterations associated with therapeutic significance were not detected.
                    </td>
                  </tr>
                  <tr *ngIf="tableCount== 0 && prognasticCount == 0">
                    <td colspan="3" class="text-center mt-2">
                      Clinically relevant genomic alterations associated with both therapeutic and prognostic significance were
                      not detected.
                    </td>
                  </tr>

                  <ng-container>
                    <tr *ngFor="
                                                                let snvHotspotTrail of reportPdfRes?.allHotspotTrailList  | slice : 0 : 5 ; let i=index
                                                              ">
                      <td>
                        <i>{{ snvHotspotTrail.gene }}</i>
                        &nbsp;<span *ngIf="!snvHotspotTrail.gene.includes('Wild type')">
                        <span *ngIf="snvHotspotTrail?.multiVariant==null">
                          <span *ngIf="snvHotspotTrail.exonNumber != null || snvHotspotTrail.pposition != null && snvHotspotTrail.pposition != ''">

                            <span *ngIf="snvHotspotTrail.exonNumber != null">{{snvHotspotTrail.exonNumber}}</span>&nbsp;
                            <span *ngIf="snvHotspotTrail.pposition != null && snvHotspotTrail.pposition != ''">({{snvHotspotTrail.pposition?.replace(' ','')}})</span></span>
                            <p *ngIf="snvHotspotTrail.mutantBurdenPercent != null && !snvHotspotTrail.gene.includes('Wild type')">
                              Allelic burden: {{ snvHotspotTrail.mutantBurdenPercent }}%
                            </p>
                            <span *ngIf="snvHotspotTrail.copyNumber!=null && snvHotspotTrail.copyNumber.length>0"> {{ snvHotspotTrail.copyNumber }}
                          </span>
                        </span>
                        <span *ngIf="snvHotspotTrail?.multiVariant!=null"><br/>
                         <span  style="font-size: 14px !important" [innerHtml]="snvHotspotTrail?.multiVariant"></span>
                      </span></span>
                      </td>
                      <td>
                        <table class="table first_table w-100">
                          <thead class="border-bottom cust_clr">
                            <tr>
                              <th class="w-50">
                                <span class="font-weight-normal"><i>Therapy</i></span>
                              </th>
                              <th class="w-33">
                                <span class="font-weight-normal"><i>Clinical Relevance</i></span>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngIf="snvHotspotTrail.sameCancerType == null">
                              <td class="w-50">NA</td>
                              <td class="w-50">NA</td>
                            </tr>

                            <ng-container *ngIf="snvHotspotTrail.sameCancerType != null">
                              <tr *ngFor="
                                                                          let item of snvHotspotTrail.sameTypeRelevantData
                                                                        ">
                                <td>{{ item.therapy }}</td>
                                <td>{{ item.association }}</td>
                                <!-- <td>{{item.cancerType}}</td> -->
                              </tr>
                            </ng-container>
                          </tbody>
                        </table>
                      </td>
                      <td>
                        <table class="table first_table w-100">
                          <thead class="border-bottom cust_clr">
                            <tr>
                              <th class="w-33">
                                <span class="font-weight-normal"><i>Therapy</i></span>
                              </th>
                              <th class="w-33">
                                <span class="font-weight-normal"><i>Clinical Relevance</i></span>
                              </th>
                              <th class="w-33">
                                <span class="font-weight-normal"><i>Cancer Type</i></span>
                              </th>
                            </tr>
                          </thead>
                          <tbody>

                            <ng-container *ngIf="snvHotspotTrail.otherCancerType != null">
                              <tr *ngFor="
                                                                          let item of snvHotspotTrail.otherTypeRelevantData
                                                                        ">
                                <td>{{ item.therapy }}</td>
                                <td>{{ item.association }}</td>
                                <td>{{ item.cancerType }}</td>
                              </tr>
                            </ng-container>
                            <tr *ngIf="snvHotspotTrail.otherCancerType == null">
                              <td class="w-33">NA</td>
                              <td class="w-33">NA</td>
                              <td class="w-33">NA</td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>


                  </ng-container>
                </table>
                <p *ngIf="
                                snvHotSpot.length != 0 ||
                                cnaHotSpot.length != 0 ||
                                                    fusionHotSpot.length !=0
                                                  " style="font-size: 13px !important" class="mt-0">
                  <i> *NA: Not Applicable</i>
                </p>
              </div>
            </div>
            <!--  -->
            <div *ngIf="(tableCount>0) &&
                              prognasticCount==0">
              <h6 class="py-2 h6_bg"> Prognostic Biomarkers</h6>
            </div>
            <div class="row" *ngIf="(tableCount>0 )&&
                              prognasticCount==0">
              <div class="col-12">
                <table class="page_1 cust_table w-100 mt-3 varient_details prognasticbiomarkertable"
                  [ngClass]="'table_variant-blue'">
                  <thead>
                    <tr>
                      <th>Genomic Alteration</th>
                      <th>Prognostic Significance</th>

                    </tr>
                  </thead>
                  <ng-container>
                    <tr>
                      <td colspan="2" style="text-align:center">
                        Clinically relevant genomic alterations associated with prognostic significance were not detected.
                      </td>
                    </tr>
                  </ng-container>
                </table>
              </div>
            </div>
            <div *ngIf="prognasticCount>0">
              <h6 class="py-2 h6_bg"> Prognostic Biomarkers</h6>
            </div>

            <div class="row" *ngIf="prognasticCount>0">
              <div class="col-12">
                <table class="page_1 cust_table w-100 mt-3 varient_details prognasticbiomarkertable"
                  [ngClass]="'table_variant-blue'">
                  <thead>
                    <tr>
                      <th>Genomic Alteration</th>
                      <th>Prognostic Significance</th>

                    </tr>
                  </thead>
                  <ng-container *ngFor="let prog of reportPdfRes.prognosticBiomarkers">
                    <tr>
                      <td style="padding-left: 3px !important">
                        <i>{{ prog.gene }}&nbsp; </i>
                        <span [innerHtml]="prog.geneDetails"></span>
                      </td>
                      <td style="padding-left: 3px !important;">{{prog.statement}}
                      </td>
                    </tr>
                  </ng-container>

                </table>
              </div>
            </div>
            <!--  -->



            <!--  -->
            <div class="row footer d-flex justify-content-between align-items-center">
              <div class="col">
                <div class="py-2">
                  <p>
                    <span class="font-weight-bold">Genomics Bihar,</span>
                  </p>
                  <p>Homi Bhabha cancer hospital & Research centre</p>
                  <p>Inside Srikrishna Medical College & Hospital</p>
                  <p>Umanagar, Muzaffarpur-842004.</p>
                  <p>Contact: ngs.mfp@gmail.com</p>
                </div>
              </div>
              <div class="col">
                <p class="text-right">
                  <img
                    src="../../../assets/tmc/tmc_footer.png"
                    alt="footer_image"
                    class="footer_img"
                  />
                </p>
              </div>
            </div>
          </div>

          <!-- page 2 -->
          <div id="page2" *ngIf="
                                                    reportPdfRes.allVariantsList.length > 0
                                                    " class="container position-relative page" style="background-color: white">
              <div class="d-flex justify-content-between logo_panel py-3">
                <div class="logo_part">
                  <img src="../../../assets/tmc/tmc_log1.png" alt="" class="logo_first" />
                </div>
                <div class="logo_part text-center">
                  <img src="../../../assets/tmc/tmc_logo2.png" alt="" class="logo_two" />
                  <p style="font-size: 10px">
                    An Initiative of Bihar Govt. & Doctors For You
                  </p>
                  <p class="text-uppercase">MEDICAL GENOMICS & MOLECULAR ONCOLOGY</p>
                </div>
                <div class="logo_part">
                  <img src="../../../assets/tmc/tmc_logo3.jpg" alt="" class="logo_three" />
                </div>
              </div>
              <div class="row header mt-1">
              <div></div>
              <div class="col">
                <div class="patient-detailes ml-auto float-right">
                  <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
                    <li>
                      Patient Id <br />
                      <span>{{ report.patient?.organisationPatientId }}</span>
                    </li>
                    <li class="ml-4">
                      Patient Name<br />
                      <span>{{ report.patient?.name }}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <!--  -->
              <div *ngIf="report.clientType == '4basecare_client'">
                <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
                  </div>
                </div>
                <div *ngIf="report.clientName == 'path_labs'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
                  </div>
                </div>
              </div>
            </div>

            <!-- gene Data -->
            <!-- <div *ngIf="geneMutationlist != null && isStatusDisplay">
              <div class="mt-3">
                <h6 class="py-2 h6_bg" [ngClass]="'skyBlue'">
                  Status of variants in cancer related Biomarkers
                </h6>
              </div>

              <table class="staus_gene bg_skyblue" *ngIf="geneMutationlist != null" [ngClass]="{
                                                                      biomarker_table:
                                                                        geneMutationlist.length >= 4 &&
                                                                        geneMutationlist.length < 8
                                                                    }">
                <tr style="background: #daedf7">
                  <td style="font-size: 18px !important; padding-left: 10px !important">
                    Gene
                  </td>
                  <td *ngFor="
                                                                          let geneMutation of geneMutationlist
                                                                            | slice : 0 : 8
                                                                        ">
                    <i>{{ geneMutation.gene }}</i>
                  </td>
                </tr>
                <tr>
                  <td style="font-size: 18px !important; padding-left: 10px !important">
                    <b>Status</b>
                  </td>
                  <td *ngFor="
                                                                          let geneMutation of geneMutationlist
                                                                            | slice : 0 : 8
                                                                        ">
                    <span *ngIf="
                                                                            geneMutation.status == 'Pathogenic' ||
                                                                            geneMutation.status == 'Likely Pathogenic' ||
                                                                            geneMutation.status == 'Detected'
                                                                          " style="color: red; font-weight: 600px"><b>{{
                        geneMutation.status
                        }}</b></span>
                    <span *ngIf="geneMutation.status == 'VUS'" style="color: black; font-weight: 600px"><b>{{
                        geneMutation.status }}</b></span>
                    <span *ngIf="
                                                                            geneMutation.status == 'Not Detected' ||
                                                                            geneMutation.status == 'Benige'
                                                                          ">{{ geneMutation.status }}</span>
                  </td>
                </tr>
<br/>
              </table>

              <table class="staus_gene" [ngClass]="'bg_skyblue'" *ngIf="
                                                                      geneMutationlist != null &&
                                                                      geneMutationlist.length > 8
                                                                    ">
                <tr style="background: #daedf7">
                  <td style="font-size: 18px !important; padding-left: 10px !important">
                    Gene
                  </td>
                  <td *ngFor="
                                                                          let geneMutation of geneMutationlist
                                                                            | slice : 8 : 17
                                                                        ">
                    <i>{{ geneMutation.gene }}</i>
                  </td>
                </tr>
                <tr>
                  <td style="font-size: 18px !important; padding-left: 10px !important">
                    <b>Status</b>
                  </td>
                  <td *ngFor="
                                                                          let geneMutation of geneMutationlist
                                                                            | slice : 8 : 17
                                                                        ">
                    <span *ngIf="
                                                                            geneMutation.status == 'Pathogenic' ||
                                                                            geneMutation.status == 'Likely Pathogenic' ||
                                                                            geneMutation.status == 'Detected'
                                                                          " style="color: red; font-weight: 600px"><b>{{
                        geneMutation.status
                        }}</b></span>
                    <span *ngIf="geneMutation.status == 'VUS'" style="color: black; font-weight: 600px"><b>{{
                        geneMutation.status }}</b></span>
                    <span *ngIf="
                                                                            geneMutation.status == 'Not Detected' ||
                                                                            geneMutation.status == 'Benige'
                                                                          ">{{ geneMutation.status }}</span>
                  </td></tr>
                  <br/>
              </table>
              <table
                class="staus_gene"
                [ngClass]="'bg_skyblue'"
                *ngIf="
                  geneMutationlist != null &&
                  geneMutationlist.length > 17
                "
              >
                <tr style="background: #daedf7">
                  <td
                    style="font-size: 18px !important; padding-left: 10px !important"
                  >
                    Gene
                  </td>
                  <td
                    *ngFor="
                      let geneMutation of geneMutationlist
                        | slice : 17 : 25
                    "
                  >
                    <i>{{ geneMutation.gene }}</i>
                  </td>
                </tr>
                <tr>
                  <td
                    style="font-size: 18px !important; padding-left: 10px !important"
                  >
                    <b>Status</b>
                  </td>
                  <td
                    *ngFor="
                      let geneMutation of geneMutationlist
                        | slice : 17 : 25
                    "
                  >
                    <span
                      *ngIf="
                        geneMutation.status == 'Pathogenic' ||
                        geneMutation.status == 'Likely Pathogenic' ||
                        geneMutation.status == 'Detected'
                      "
                      style="color: red; font-weight: 600px"
                      ><b>{{ geneMutation.status }}</b></span
                    >
                    <span
                      *ngIf="geneMutation.status == 'VUS'"
                      style="color: black; font-weight: 600px"
                      ><b>{{ geneMutation.status }}</b></span
                    >
                    <span
                      *ngIf="
                        geneMutation.status == 'Not Detected' ||
                        geneMutation.status == 'Benige'
                      "
                      >{{ geneMutation.status }}</span
                    >
                  </td>
                </tr>
<br/>
              </table>
              <table class="staus_gene" [ngClass]="'bg_skyblue'" *ngIf="
                    geneMutationlist != null &&
                    geneMutationlist.length > 25
                  ">
                <tr style="background: #daedf7">
                  <td style="font-size: 18px !important; padding-left: 10px !important">
                    Gene
                  </td>
                  <td *ngFor="
                        let geneMutation of geneMutationlist
                          | slice : 25 : 33
                      ">
                    <i>{{ geneMutation.gene }}</i>
                  </td>
                </tr>
                <tr>
                  <td style="font-size: 18px !important; padding-left: 10px !important">
                    <b>Status</b>
                  </td>
                  <td *ngFor="
                        let geneMutation of geneMutationlist
                          | slice : 25 : 33
                      ">
                    <span *ngIf="
                          geneMutation.status == 'Pathogenic' ||
                          geneMutation.status == 'Likely Pathogenic' ||
                          geneMutation.status == 'Detected'
                        " style="color: red; font-weight: 600px"><b>{{ geneMutation.status }}</b></span>
                    <span *ngIf="geneMutation.status == 'VUS'" style="color: black; font-weight: 600px"><b>{{
                        geneMutation.status }}</b></span>
                    <span *ngIf="
                          geneMutation.status == 'Not Detected' ||
                          geneMutation.status == 'Benige'
                        ">{{ geneMutation.status }}</span>
                  </td>
                </tr>
<br/>
              </table>
          <table class="staus_gene" [ngClass]="'bg_skyblue'" *ngIf="
                geneMutationlist != null &&
                geneMutationlist.length > 33
              ">
            <tr style="background: #daedf7">
              <td style="font-size: 18px !important; padding-left: 10px !important">
                Gene
              </td>
              <td *ngFor="
                    let geneMutation of geneMutationlist
                      | slice : 33 : 41
                  ">
                <i>{{ geneMutation.gene }}</i>
              </td>
            </tr>
            <tr>
              <td style="font-size: 18px !important; padding-left: 10px !important">
                <b>Status</b>
              </td>
              <td *ngFor="
                    let geneMutation of geneMutationlist
                      | slice : 33 : 41
                  ">
                <span *ngIf="
                      geneMutation.status == 'Pathogenic' ||
                      geneMutation.status == 'Likely Pathogenic' ||
                      geneMutation.status == 'Detected'
                    " style="color: red; font-weight: 600px"><b>{{ geneMutation.status }}</b></span>
                <span *ngIf="geneMutation.status == 'VUS'" style="color: black; font-weight: 600px"><b>{{
                    geneMutation.status }}</b></span>
                <span *ngIf="
                      geneMutation.status == 'Not Detected' ||
                      geneMutation.status == 'Benige'
                    ">{{ geneMutation.status }}</span>
              </td>
            </tr>
            <br/>
          </table>
              <h6 *ngIf="
                                      (fusionGeneMutationList != null &&
                                      fusionGeneMutationList.length > 0) "
                class="font-weight-bold clr-cyan text-uppercase" [ngClass]="'txt-skyblue'">
                Gene Fusion
              </h6>
              <table class="staus_gene" *ngIf="
                                                                      (fusionGeneMutationList != null &&
                                                                      fusionGeneMutationList.length > 0)
                                                                    " [ngClass]="'bg_skyblue'">
                <tr style="background: #daedf7">
                  <td style="font-size: 18px !important; padding-left: 10px !important">
                    Gene
                  </td>
                  <td *ngFor="
                                                                          let geneMutation of fusionGeneMutationList
                                                                            | slice : 0 : 8
                                                                        ">
                    <i>{{ geneMutation.gene }}</i>
                  </td>
                </tr>
                <tr>
                  <td style="font-size: 18px !important; padding-left: 10px !important">
                    <b>Status</b>
                  </td>
                  <td *ngFor="
                                                                          let geneMutation of fusionGeneMutationList
                                                                            | slice : 0 : 8
                                                                        ">
                    <span *ngIf="
                                                                            geneMutation.status == 'Pathogenic' ||
                                                                            geneMutation.status == 'Likely Pathogenic' ||
                                                                            geneMutation.status == 'Detected'
                                                                          " style="color: red; font-weight: 600px"><b>{{
                        geneMutation.status
                        }}</b></span>
                    <span *ngIf="geneMutation.status == 'VUS'" style="color: black; font-weight: 600px"><b>{{
                        geneMutation.status }}</b></span>
                    <span *ngIf="
                                                                            geneMutation.status == 'Not Detected' ||
                                                                            geneMutation.status == 'Benige'
                                                                          ">{{ geneMutation.status }}</span>
                  </td>
                </tr>
<br/>
              </table>
              <table class="staus_gene" *ngIf="
                                                                      (fusionGeneMutationList != null &&
                                                                      fusionGeneMutationList.length > 8)
                                                                    " [ngClass]="'bg_skyblue'">
                <tr style="background: #daedf7">
                  <td style="font-size: 18px !important; padding-left: 10px !important">
                    Gene
                  </td>
                  <td *ngFor="
                                                                          let geneMutation of fusionGeneMutationList
                                                                            | slice : 8 : 17
                                                                        ">
                    <i>{{ geneMutation.gene }}</i>
                  </td>
                </tr>
                <tr>
                  <td style="font-size: 18px !important; padding-left: 10px !important">
                    <b>Status</b>
                  </td>
                  <td *ngFor="
                                                                          let geneMutation of fusionGeneMutationList
                                                                            | slice : 8 : 17
                                                                        ">
                    <span *ngIf="
                                                                            geneMutation.status == 'Pathogenic' ||
                                                                            geneMutation.status == 'Likely Pathogenic' ||
                                                                            geneMutation.status == 'Detected'
                                                                          " style="color: red; font-weight: 600px"><b>{{
                        geneMutation.status
                        }}</b></span>
                    <span *ngIf="geneMutation.status == 'VUS'" style="color: black; font-weight: 600px"><b>{{
                        geneMutation.status }}</b></span>
                    <span *ngIf="
                                                                            geneMutation.status == 'Not Detected' ||
                                                                            geneMutation.status == 'Benige'
                                                                          ">{{ geneMutation.status }}</span>
                  </td>
                </tr>
              </table>
            </div> -->
            <!--  -->
            <div *ngIf="
            report.disclaimerNote != ' ' &&
            report.disclaimerNote != null
          ">
        <h6 style="color: #208dcb"><b>Note: </b></h6>
        <div style="font-size: 14px !important;text-align: justify" [innerHtml]="report.disclaimerNote"></div>
      </div>
            <div class="row mt-3" *ngIf="
                                                        otherCountSNV == true ||
                                                        reportPdfRes.allVariantsList.length != 0
                                                      ">
              <div class="col">
                <h6 class="font-weight-bold clr-cyan text-uppercase" [ngClass]="'txt-skyblue'">
                  Variant Details:
                </h6>
              </div>
            </div>

            <div class="row" *ngIf="
                                                        otherCountSNV == true ||
                                                        reportPdfRes.allVariantsList.length != 0
                                                      ">
              <div class="col-12">
                <table class="page_1 cust_table w-100 mt-3 varient_details" [ngClass]="'table_variant-blue'">
                  <thead>
                    <tr>
                      <th>Gene</th>
                      <th>Variant Location</th>
                      <th>Variant Consequence</th>
                      <th>Clinical Significance</th>
                      <th>Variant Type</th>
                      <th>Reference</th>
                    </tr>
                  </thead>

                  <tr *ngFor="let variant of reportPdfRes.allVariantsList">
                    <td *ngIf="variant.significance != 'NA' && variant.significance != 'Not Classified'" style="padding-left: 3px !important">
                      <i>{{ variant.gene }}</i>
                    </td>
                    <td *ngIf="variant.significance != 'NA' && variant.significance != 'Not Classified'" style="padding-left: 3px !important">
                      <span *ngIf="variant?.variantType=='snv'">
                        {{ variant?.genomicAlteration }},<br />
                        {{ variant?.ensembleValue }},<br />
                        {{ variant?.exonNumber }}
                      </span>
                      <span *ngIf="variant?.variantType=='cna'">
                        chr{{ variant?.sample?.split(":")[0] }}:g.{{
                          variant.sample
                          .split(":")[1]
                          .replace("-", "_")
                          .split("|")[0]
                          }}
                      </span>
                      <span *ngIf="variant.variantType=='fusion'">
                        {{ variant?.leftBreakPoint }}<br />{{variant?.rightBreakPoint}}
                      </span>
                    </td>
                    <td *ngIf="variant?.significance != 'NA' && variant?.significance != 'Not Classified'" style="padding-left: 3px !important">
                      <span *ngIf="variant.variantType=='snv'">
                        <span *ngIf="variant?.selectedAAChangeknownGene">{{
                          variant?.selectedAAChangeknownGene|splitComma }}</span>, {{variant?.mutantBurdenPercent * 0.01 | percent}}
                        </span>
                      <span *ngIf="variant.variantType=='cna'">NA</span>
                      <span *ngIf="variant.variantType=='fusion'">NA</span>
                    </td>
                    <td *ngIf="variant?.significance != 'NA' && variant?.significance != 'Not Classified'" style="padding-left: 3px !important">
                      {{ variant?.significance }}
                    </td>
                    <td *ngIf="variant?.significance != 'NA' && variant?.significance != 'Not Classified'" style="padding-left: 3px !important">
                      <span *ngIf="variant.variantType=='snv'">
                        {{ variant?.exonicFuncknownGene }}
                      </span>
                      <span *ngIf="variant.variantType=='cna'">
                        Copy Number
                        {{ variant?.sample.split(":")[2] | titlecase }}
                        ({{ variant?.sample.split("|")[1].split(":")[0] }})
                      </span>
                      <span *ngIf="variant.variantType=='fusion'">Gene Fusions</span>
                    </td>
                    <td *ngIf="variant?.significance != 'NA' && variant?.significance != 'Not Classified'" style="padding-left: 3px !important">
                      {{ variant?.reference }}
                    </td>
                  </tr>
                </table>
                <p *ngIf="reportPdfRes.allVariantsList.length > 0 " style="font-size: 13px !important" class="mt-0">
                  <i> *NA: Not Applicable</i>
                </p>
              </div>
            </div>
            <!--  -->
            <div class="row mt-5" *ngIf="
                  !variantFlag &&
                  report.addNotes != null && report.addNotes != '<div></div>'
                ">
          <div class="col">
            <h6 class="py-2 h6_bg">Additional Notes</h6>
            <div class="py-2" [innerHtml]="report.addNotes" style="text-align: justify; font-size: 14px !important"></div>
          </div>
        </div>

            <!--  -->
            <div class="row footer d-flex justify-content-between align-items-center">
              <div class="col">
                <div class="py-2">
                  <p>
                    <span class="font-weight-bold">Genomics Bihar,</span>
                  </p>
                  <p>Homi Bhabha cancer hospital & Research centre</p>
                  <p>Inside Srikrishna Medical College & Hospital</p>
                  <p>Umanagar, Muzaffarpur-842004.</p>
                  <p>Contact: ngs.mfp@gmail.com</p>
                </div>
              </div>
              <div class="col">
                <p class="text-right">
                  <img
                    src="../../../assets/tmc/tmc_footer.png"
                    alt="footer_image"
                    class="footer_img"
                  />
                </p>
              </div>
            </div>
          </div>

          <!-- page 3 -->
      <!-- <div id="page3" *ngIf="reportPdfRes.drugsResList.length > 0" class="container position-relative page"
        style="background-color: white">
        <div class="d-flex justify-content-between logo_panel py-3">
          <div class="logo_part">
            <img src="../../../assets/tmc/tmc_log1.png" alt="" class="logo_first" />
          </div>
          <div class="logo_part text-center">
            <img src="../../../assets/tmc/tmc_logo2.png" alt="" class="logo_two" />
            <p style="font-size: 10px">
              An Initiative of Bihar Govt. & Doctors For You
            </p>
            <p class="text-uppercase">MEDICAL GENOMICS & MOLECULAR ONCOLOGY</p>
          </div>
          <div class="logo_part">
            <img src="../../../assets/tmc/tmc_logo3.jpg" alt="" class="logo_three" />
          </div>
        </div>
        <div class="row header mt-1">
          <div>

          </div>
          <div></div>
          <div class="col">
            <div class="patient-detailes ml-auto float-right">
              <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
                <li>
                  Patient Id <br />
                  <span>{{ report.patient?.organisationPatientId }}</span>
                </li>
                <li class="ml-4">
                  Patient Name<br />
                  <span>{{ report.patient?.name }}</span>
                </li>
              </ul>
            </div>
          </div>
          <div *ngIf="report.clientType == '4basecare_client'">
            <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
              <div class="logo">
                <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
              </div>
            </div>
            <div *ngIf="report.clientName == 'path_labs'" class="col-3">
              <div class="logo">
                <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
              </div>
            </div>
          </div>
        </div>
        <div class="mar_top"></div>

        <h6 class="py-2 h6_bg" [ngClass]="'skyBlue'">
          Alterations With Clinically Relevant Therapies
        </h6>
        <div class="row">
          <div class="col-12">
            <table class="page_1 cust_table w-100 mt-3 first_2 custom-table" [ngClass]="'bg_cyan'">
              <thead>
                <tr>
                  <th>Gene (Alterations) - Drug association</th>
                  <th>Summary</th>
                </tr>
              </thead>

              <tbody>
                <tr *ngFor="
                                                                  let drugsRes of reportPdfRes.drugsResList | slice : 0 : 4;
                                                                  let i = index
                                                                ">
                  <td>
                    <ul class="ml-0 pl-2">
                      <li>
                        <i>{{ drugsRes.gene.replace("Fusion","") }}</i><span *ngIf="drugsRes.gene.includes('Fusion')">Fusion</span> {{ drugsRes.pposition?.replace(' ','') }} :
                        <br />
                        <span *ngFor="let drug of drugsRes.drugs; let i = index">
                          {{ drug.name
                          }}<span *ngIf="drugsRes.drugs.length - 1 != i">,</span>
                        </span>
                        - {{ drugsRes.association }}
                        <p>{{ drugsRes.cancerType }}</p>
                      </li>
                    </ul>
                  </td>
                  <td>
                    <div [innerHtml]="drugsRes.summary"></div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div class="row footer d-flex justify-content-between align-items-center">
          <div class="col">
            <div class="py-2">
              <p>
                <span class="font-weight-bold">Genomics Bihar,</span>
              </p>
              <p>Homi Bhabha cancer hospital & Research centre</p>
              <p>Inside Srikrishna Medical College & Hospital</p>
              <p>Umanagar, Muzaffarpur-842004.</p>
              <p>Contact: ngs.mfp@gmail.com</p>
            </div>
          </div>
          <div class="col">
            <p class="text-right">
              <img src="../../../assets/tmc/tmc_footer.png" alt="footer_image" class="footer_img" />
            </p>
          </div>
        </div>
      </div> -->

      <!-- page 3_1 -->


          <!-- page 4 -->
          <!-- <div id="page4" *ngIf="prognasticCount>0" class="container position-relative page" style="background-color: white">
              <div class="d-flex justify-content-between logo_panel py-3">
                <div class="logo_part">
                  <img src="../../../assets/tmc/tmc_log1.png" alt="" class="logo_first" />
                </div>
                <div class="logo_part text-center">
                  <img src="../../../assets/tmc/tmc_logo2.png" alt="" class="logo_two" />
                  <p style="font-size: 10px">
                    An Initiative of Bihar Govt. & Doctors For You
                  </p>
                  <p class="text-uppercase">MEDICAL GENOMICS & MOLECULAR ONCOLOGY</p>
                </div>
                <div class="logo_part">
                  <img src="../../../assets/tmc/tmc_logo3.jpg" alt="" class="logo_three" />
                </div>
              </div>
              <div class="row header mt-1">
              <div></div>
              <div class="col">
                <div class="patient-detailes ml-auto float-right">
                  <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
                    <li>
                      Patient Id <br />
                      <span>{{ report.patient?.organisationPatientId }}</span>
                    </li>
                    <li class="ml-4">
                      Patient Name<br />
                      <span>{{ report.patient?.name }}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <div *ngIf="report.clientType == '4basecare_client'">
                <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
                  </div>
                </div>
                <div *ngIf="report.clientName == 'path_labs'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
                  </div>
                </div>
              </div>
            </div>
            <div class="mar_top"></div>
            <div *ngIf="prognasticCount>0">
              <div>
                <h6 class="py-2 h6_bg"> Alterations associated with prognostic significance</h6>

              </div>

              <div class="row">
                <div class="col-12">
                  <table class="page_1 cust_table w-100 mt-3 varient_details prognastictable" [ngClass]="'table_variant-blue'">
                    <thead>
                      <tr>
                        <th>Gene (Alterations)</th>
                        <th>Prognostic Significance</th>
                        <th>Summary</th>

                      </tr>
                    </thead>

                    <ng-container *ngFor="
                                                              let selectedReportedVariant of reportPdfRes.snvReportedVariantList
                                                            ">
                      <tr *ngIf="selectedReportedVariant.prognasticSignificance!=null">
                        <td style="padding-left: 3px !important">
                          <i>{{ selectedReportedVariant.refGeneX }}</i>
                          <span *ngIf="getpposition(selectedReportedVariant.selectedAAChangeknownGene, selectedReportedVariant.exonicFuncknownGene)!=null">
                            ({{getpposition(selectedReportedVariant.selectedAAChangeknownGene?.replace(' ',''), selectedReportedVariant.exonicFuncknownGene)}})</span>
                        </td>
                        <td style="padding-left: 3px !important">
                          {{selectedReportedVariant.prognasticSignificance}}
                        </td>
                        <td style="padding-left: 3px !important">
                          <span [innerHtml]="selectedReportedVariant.prognasticDescription"></span>
                        </td>

                      </tr>
                    </ng-container>
                    <ng-container  *ngFor="
                  let selectedReportedVariant of reportPdfRes.cnaReportedVariantList
                ">
                    <tr
                    *ngIf="selectedReportedVariant.prognasticSignificance!=null"
                  >
                    <td
                      style="padding-left: 3px !important"
                    >
                    <i>{{ selectedReportedVariant.gene }} </i>
                    <span *ngIf="selectedReportedVariant.sample!=null
                    ">&nbsp;Copy Number
                      {{ selectedReportedVariant.sample.split(":")[2] | titlecase }}
                      ({{ selectedReportedVariant.sample.split("|")[1].split(":")[0] }})
                    </span>

                    </td>
                    <td
                      style="padding-left: 3px !important"
                    >
                    {{selectedReportedVariant.prognasticSignificance}}
                    </td>
                    <td
                      style="padding-left: 3px !important"
                    >
                    <span [innerHtml]="selectedReportedVariant.prognasticDescription"></span>
                    </td>

                  </tr>
                  </ng-container>


                  </table>
                </div>
              </div>
            </div>


            <div class="row footer d-flex justify-content-between align-items-center">
              <div class="col">
                <div class="py-2">
                  <p>
                    <span class="font-weight-bold">Genomics Bihar,</span>
                  </p>
                  <p>Homi Bhabha cancer hospital & Research centre</p>
                  <p>Inside Srikrishna Medical College & Hospital</p>
                  <p>Umanagar, Muzaffarpur-842004.</p>
                  <p>Contact: ngs.mfp@gmail.com</p>
                </div>
              </div>
              <div class="col">
                <p class="text-right">
                  <img
                    src="../../../assets/tmc/tmc_footer.png"
                    alt="footer_image"
                    class="footer_img"
                  />
                </p>
              </div>
            </div>
          </div> -->

          <!-- Page 5 -->
          <div id="page5" class="container position-relative page" style="background-color: white">
              <div class="d-flex justify-content-between logo_panel py-3">
                <div class="logo_part">
                  <img src="../../../assets/tmc/tmc_log1.png" alt="" class="logo_first" />
                </div>
                <div class="logo_part text-center">
                  <img src="../../../assets/tmc/tmc_logo2.png" alt="" class="logo_two" />
                  <p style="font-size: 10px">
                    An Initiative of Bihar Govt. & Doctors For You
                  </p>
                  <p class="text-uppercase">MEDICAL GENOMICS & MOLECULAR ONCOLOGY</p>
                </div>
                <div class="logo_part">
                  <img src="../../../assets/tmc/tmc_logo3.jpg" alt="" class="logo_three" />
                </div>
              </div>
              <div class="row header mt-1">
              <div></div>
              <div class="col">
                <div class="patient-detailes ml-auto float-right">
                  <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
                    <li>
                      Patient Id <br />
                      <span>{{ report.patient?.organisationPatientId }}</span>
                    </li>
                    <li class="ml-4">
                      Patient Name<br />
                      <span>{{ report.patient?.name }}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <!--  -->
              <div *ngIf="report.clientType == '4basecare_client'">
                <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
                  </div>
                </div>
                <div *ngIf="report.clientName == 'path_labs'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
                  </div>
                </div>
              </div>
            </div>
            <!--  -->
            <div class="mar_top"></div>
            <!--  -->

            <h6 class="py-2 h6_bg" [ngClass]="'skyBlue'">References</h6>
            <!--  -->
            <div class="row">
              <div class="col">
                <div class="disc">
                  <div class="ref_data mt-4">
                    <ul class="break-word" style="text-align: justify">
                      <li *ngFor="let referenceArticle of reportPdfRes.referenceArticles" style="
                                                                  text-align: justify;
                                                                  /* white-space:pre-wrap; */
                                                                  word-break: break-word;
                                                                ">
                        {{ referenceArticle }}
                      </li>
                    </ul>
                  </div>
                </div>

              </div>
            </div>

            <div class="row pr-2 above-footer">
              <div class="col pl-5">
                <img
                  src="../../../assets/tmc/basusigns1.png"
                  alt=""
                  class="mb-2 sign_width"
                />
                <div class="sign_text">
                  <p>Dr. Moitri Basu, Ph.D.</p>
                  <p>Scientific Officer</p>
                  <p>Homi Bhabha cancer hospital & Research centre, <br/>Muzaffarpur, A unit of Tata Memorial centre.</p>
                </div>
            </div>
            </div>
            <!--  -->
            <div class="row footer d-flex justify-content-between align-items-center">
              <div class="col">
                <div class="py-2">
                  <p>
                    <span class="font-weight-bold">Genomics Bihar,</span>
                  </p>
                  <p>Homi Bhabha cancer hospital & Research centre</p>
                  <p>Inside Srikrishna Medical College & Hospital</p>
                  <p>Umanagar, Muzaffarpur-842004.</p>
                  <p>Contact: ngs.mfp@gmail.com</p>
                </div>
              </div>
              <div class="col">
                <p class="text-right">
                  <img
                    src="../../../assets/tmc/tmc_footer.png"
                    alt="footer_image"
                    class="footer_img"
                  />
                </p>
              </div>
            </div>
          </div>

          <!-- Page 6 -->
          <div id="page6" class="container position-relative page" style="background-color: white">
              <div class="d-flex justify-content-between logo_panel py-3">
                <div class="logo_part">
                  <img src="../../../assets/tmc/tmc_log1.png" alt="" class="logo_first" />
                </div>
                <div class="logo_part text-center">
                  <img src="../../../assets/tmc/tmc_logo2.png" alt="" class="logo_two" />
                  <p style="font-size: 10px">
                    An Initiative of Bihar Govt. & Doctors For You
                  </p>
                  <p class="text-uppercase">MEDICAL GENOMICS & MOLECULAR ONCOLOGY</p>
                </div>
                <div class="logo_part">
                  <img src="../../../assets/tmc/tmc_logo3.jpg" alt="" class="logo_three" />
                </div>
              </div>
              <div class="row header mt-1">
              <div></div>
              <div class="col">
                <div class="patient-detailes ml-auto float-right">
                  <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
                    <li>
                      Patient Id <br />
                      <span>{{ report.patient?.organisationPatientId }}</span>
                    </li>
                    <li class="ml-4">
                      Patient Name<br />
                      <span>{{ report.patient?.name }}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <!--  -->
              <div *ngIf="report.clientType == '4basecare_client'">
                <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
                  </div>
                </div>
                <div *ngIf="report.clientName == 'path_labs'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
                  </div>
                </div>
              </div>
            </div>
            <!--  -->
            <div class="mar_top"></div>
            <!--  -->

            <div class="">
              <h6 class="py-2 h6_bg" [ngClass]="'skyBlue'">TEST DESCRIPTION</h6>
              <p class="mb-2" style="text-align:justify">
                <span class="font-weight-normal">TAR</span><b>GT</b>
                <span class="clr-cyan pl-2" [ngClass]="'txt-skyblue'">First Solid</span>
                is a Next Generation Sequencing based test which identifies
                genetic alterations in a comprehensive panel of well curated 72  genes which are having an impact response to approved
                therapy for a particular cancer type. Some of the alterations
                detected may have bearing on prognosis and/or therapeutic options
                and may provide relevant information that allows
                oncologists/clinicians to consider various lines of
                targeted treatment for the patient.
              </p>
            </div>

            <!--  -->
            <div class="row">
              <div class="col">
                <div class="">
                  <h6 class="py-2 h6_bg" [ngClass]="'skyBlue'">GENES EVALUATED</h6>
                  <p>
                    <span class="font-weight-normal">TAR</span><b>GT</b>
                    <span class="clr-cyan pl-2" [ngClass]="'txt-skyblue'">First Solid</span>
                    detects mutations (SNVs and Short InDels), Copy Number Variations (CNVs),
                    Gene Fusions and splice variants in the 72 genes:
                    <!-- detects mutations (SNVs and Short Indels), Copy Number Variations
                                                          (CNVs), gene fusions and splice variants in the 53 genes : -->
                  </p>
                </div>
                <!--  -->
                <div class="row mx-0">
                  <div class="col-12 px-0">
                    <div class="genes_info">
                      <div class="title py-2">
                        <h6 class="mb-0 d-inline-block border-bottom py-2">
                          <b>SNVs, SHORT INDELS and CNVs
                          </b> Covered in
                          <span class="font-weight-normal">TAR</span><b>GT</b>
                          <span class="clr-cyan pl-2" [ngClass]="'txt-skyblue'">First Solid</span>
                        </h6>
                      </div>
                      <div class="gene_body" [ngClass]="'gene-bg_blue'">
                        <i>
                          <div class="gene_list">
                            <span>ABL1</span><span>ALK</span><span>APC</span>
                            <span>AR</span><span>ATM</span><span>BARD1</span>
                            <span>BMPR1A</span><span>BRAF</span><span>BRCA1</span>
                            <span>BRCA2</span>
                            <!-- <span>ABL1</span><span>ALK</span><span>AR</span
                                                                  ><span>ATM</span><span>BARD1</span><span>BRAF</span
                                                                  ><span>BRCA1</span><span>BRCA2</span><span>BRIP1</span
                                                                  ><span>CDK12</span> -->
                          </div>
                          <div class="gene_list bg-white">
                            <span>BRIP1</span><span>CDK12</span><span>CDK4</span>
                            <span>CDK6</span><span>CDKN2A</span><span>CHEK1</span>
                            <span>CHEK2</span><span>CTNNB1</span><span>EGFR</span>
                            <span>EPCAM</span>
                            <!-- <span>CDK4</span><span>CDK6</span><span>CDKN2A</span
                                                                  ><span>CHEK1</span><span>CHEK2</span><span>EGFR</span
                                                                  ><span>EPCAM</span><span>ERBB2</span><span>ERBB3</span
                                                                  ><span>EZH2</span> -->
                          </div>
                          <div class="gene_list">
                            <span>ERBB2</span><span>ERBB3</span><span>EZH2</span>
                            <span>FANCL</span><span>FGFR1</span><span>FGFR2</span>
                            <span>FGFR3</span><span>GAPDH</span><span>IDH1</span>
                            <span>IDH2</span>
                            <!-- <span>FANCL</span><span>FGFR3</span><span>GAPDH</span
                                                                  ><span>IDH1</span><span>IDH2</span><span>JAK2</span
                                                                  ><span>KIT</span><span>KRAS</span><span>MAP2K1</span
                                                                  ><span>MAP2K2</span> -->
                          </div>
                          <div class="gene_list">
                            <span>JAK2</span><span>KIT</span><span>KRAS</span>
                            <span>MAP2K1</span><span>MAP2K2</span><span>MDM2</span>
                            <span>MET</span><span>MLH1</span><span>MLH3</span>
                            <span>MSH2</span>
                            <!-- <span>MDM2</span><span>MET</span><span>MLH1</span
                                                                  ><span>MLH3</span><span>MSH2</span><span>MSH6</span
                                                                  ><span>NRAS</span><span>PALB2</span><span>PDGFRA</span
                                                                  ><span>PDGFRB</span> -->
                          </div>
                          <div class="gene_list">
                            <span>MSH6</span><span>MUTYH</span><span>NRAS</span>
                            <span>PALB2</span><span>PDGFRA</span><span>PDGFRB</span>
                            <span>PIK3CA</span><span>PMS1</span><span>PMS2</span>
                            <span>POLD1</span>
                            <!-- <span>PIK3CA</span><span>PMS1</span><span>PMS2</span
                                                                  ><span>PPP2R2A</span><span>RAD51B</span><span>RAD51C</span
                                                                  ><span>RAD51D</span><span>RAD54L</span><span>RET</span
                                                                  ><span>ROS1</span> -->
                          </div>
                          <div class="gene_list">
                            <span>POLE</span><span>POLH</span><span>PTEN</span>
                            <span>RAD50</span><span>RAD51</span><span>RAD51B</span>
                            <span>RAD51C</span><span>RAD51D</span><span>RAD54L</span>
                            <span>RB1</span>
                            <!-- <span>STK11</span><span>TSC1</span><span>TSC2</span> -->
                          </div>
                          <div class="gene_list">
                            <span>RET</span><span>ROS1</span><span>SMAD4</span>
                            <span>STK11</span><span>TP53</span><span>TSC1</span>
                            <span>TSC2</span>
                            <!-- <span>STK11</span><span>TSC1</span><span>TSC2</span> -->
                          </div>
                        </i>
                      </div>

                      <!-- <div class="title py-2 mt-4">
                                                              <h6 class="mb-0 d-inline-block border-bottom py-2">
                                                                <b>CNAs</b> Covered in
                                                                <span class="font-weight-normal">TAR</span><b>GT</b>
                                                                <span class="clr-cyan pl-2" [ngClass]="'txt-skyblue'"
                                                                  >First</span
                                                                >
                                                              </h6>
                                                            </div> -->
                      <!-- <div class="gene_body" [ngClass]="'gene-bg_blue'">
                                                              <i>
                                                                <div class="gene_list">
                                                                  <span>ABL1</span><span>AR</span><span>ATM</span
                                                                  ><span>BARD1</span><span>BRCA1</span><span>BRCA2</span
                                                                  ><span>BRIP1</span><span>CDK12</span><span>CDK4</span
                                                                  ><span>CDK6</span>
                                                                </div>
                                                                <div class="gene_list">
                                                                  <span>CDKN2A</span><span>CHEK1</span><span>CHEK2</span
                                                                  ><span>EGFR</span><span>EPCAM</span><span>ERBB2</span
                                                                  ><span>ERBB3</span><span>EZH2</span><span>FANCL</span
                                                                  ><span>FGFR3</span>
                                                                </div>
                                                                <div class="gene_list">
                                                                  <span>GAPDH</span><span>IDH2</span><span>JAK2</span
                                                                  ><span>KIT</span><span>MDM2</span><span>NRAS</span
                                                                  ><span>PALB2</span><span>PDGFRA</span><span>PDGFRB</span
                                                                  ><span>PPP2R2A</span>
                                                                </div>
                                                                <div class="gene_list">
                                                                  <span>RAD51B</span><span>RAD51C</span><span>RAD51D</span
                                                                  ><span>RAD54L</span><span>STK11</span><span>TSC1</span
                                                                  ><span>TSC2</span>
                                                                </div>
                                                              </i>
                                                            </div> -->
                    </div>
                  </div>
<!-- cnv_genes -->

                  <!-- <div class="col-12 px-0">
                    <div class="genes_info">
                      <div class="title py-2">
                        <h6 class="mb-0 d-inline-block border-bottom py-2">
                          <b> CNVs
                          </b> Covered in
                          <span class="font-weight-normal">TAR</span><b>GT</b>
                          <span class="clr-cyan pl-2" [ngClass]="'txt-skyblue'"
                            >First Solid</span
                          >
                        </h6>
                      </div>
                      <div class="gene_body" [ngClass]="'gene-bg_blue'">
                        <i>
                          <div class="gene_list">
                            <span>ABL1</span><span>ALK</span><span>APC</span>
                            <span>AR</span><span>ATM</span><span>BARD1</span>
                            <span>BMPR1A</span><span>BRAF</span><span>BRCA1</span>
                            <span>BRCA2</span>
                          </div>
                          <div class="gene_list bg-white">
                            <span>BRIP1</span><span>CDK12</span><span>CDK4</span>
                            <span>CDK6</span><span>CDKN2A</span><span>CHEK1</span>
                            <span>CHEK2</span><span>CTNNB1</span><span>EGFR</span>
                            <span>EPCAM</span>
                          </div>
                          <div class="gene_list">
                            <span>ERBB2</span><span>ERBB3</span><span>EZH2</span>
                            <span>FANCL</span><span>FGFR1</span><span>FGFR2</span>
                            <span>FGFR3</span><span>GAPDH</span><span>IDH2</span>
                            <span>JAK2</span>
                          </div>
                          <div class="gene_list">
                           <span>KIT</span><span>MDM2</span>
                            <span>MET</span><span>MLH1</span><span>MUTYH</span>
                            <span>NRAS</span><span>PALB2</span><span>PDGFRA</span>
                            <span>PDGFRAB</span><span>POLD1</span>
                          </div>

                          <div class="gene_list">
                            <span>POLE</span><span>POLH</span><span>PTEN</span>
                            <span>RAD50</span><span>RAD51</span><span>RAD51B</span>
                            <span>RAD51C</span><span>RAD51D</span><span>RAD54L</span>
                            <span>RB1</span>
                          </div>
                          <div class="gene_list">
                            <span>RET</span><span>ROS1</span><span>SMAD4</span>
                            <span>STK11</span><span>TP53</span><span>TSC1</span>
                            <span>TSC2</span>
                          </div>
                        </i>
                      </div>


                    </div>
                  </div> -->

                  <div class="col-12 px-0">
                    <div class="genes_info">
                      <div class="title py-2">
                        <h6 class="mb-0 d-inline-block border-bottom py-2">
                          <b>Gene Fusions/Splicing Variations</b> Covered in
                          <span class="font-weight-normal">TAR</span><b>GT</b>
                          <span class="clr-cyan pl-2" [ngClass]="'txt-skyblue'">First Solid</span>
                        </h6>
                      </div>
                      <div class="gene_body" [ngClass]="'gene-bg_blue'">
                        <i>
                          <div class="gene_list">
                          <span>ALK</span><span>FGFR2</span><span>FGFR3</span>
                          <span>MET</span><span>NRG1</span><span>NRG2</span>
                          <span>NTRK1</span><span>NTRK2</span> <span>NTRK3</span><span>RET</span>

                          </div>
                          <div class="gene_list">
                            <span>ROS1</span>
                          </div>
                        </i>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>

            <!--  -->
            <div class="row footer d-flex justify-content-between align-items-center">
              <div class="col">
                <div class="py-2">
                  <p>
                    <span class="font-weight-bold">Genomics Bihar,</span>
                  </p>
                  <p>Homi Bhabha cancer hospital & Research centre</p>
                  <p>Inside Srikrishna Medical College & Hospital</p>
                  <p>Umanagar, Muzaffarpur-842004.</p>
                  <p>Contact: ngs.mfp@gmail.com</p>
                </div>
              </div>
              <div class="col">
                <p class="text-right">
                  <img
                    src="../../../assets/tmc/tmc_footer.png"
                    alt="footer_image"
                    class="footer_img"
                  />
                </p>
              </div>
            </div>
          </div>

          <!-- Page 7 -->
          <div id="page7" class="container position-relative page" style="background-color: white">
              <div class="d-flex justify-content-between logo_panel py-3">
                <div class="logo_part">
                  <img src="../../../assets/tmc/tmc_log1.png" alt="" class="logo_first" />
                </div>
                <div class="logo_part text-center">
                  <img src="../../../assets/tmc/tmc_logo2.png" alt="" class="logo_two" />
                  <p style="font-size: 10px">
                    An Initiative of Bihar Govt. & Doctors For You
                  </p>
                  <p class="text-uppercase">MEDICAL GENOMICS & MOLECULAR ONCOLOGY</p>
                </div>
                <div class="logo_part">
                  <img src="../../../assets/tmc/tmc_logo3.jpg" alt="" class="logo_three" />
                </div>
              </div>
              <div class="row header mt-1">
              <div></div>
              <div class="col">
                <div class="patient-detailes ml-auto float-right">
                  <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
                    <li>
                      Patient Id <br />
                      <span>{{ report.patient?.organisationPatientId }}</span>
                    </li>
                    <li class="ml-4">
                      Patient Name<br />
                      <span>{{ report.patient?.name }}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <!--  -->
              <div *ngIf="report.clientType == '4basecare_client'">
                <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
                  </div>
                </div>
                <div *ngIf="report.clientName == 'path_labs'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
                  </div>
                </div>
              </div>
            </div>
            <!--  -->
            <div class="mar_top"></div>
            <!--  -->
            <h6 class="py-2 h6_bg mt-3" [ngClass]="'skyBlue'">TEST METHODOLOGY</h6>
            <!--  -->
            <div class="row mt-3">
              <div class="col">
                <h6 class="font-weight-bold">
                  Sample preparation and Library preparation :
                </h6>
                <!-- <p>
                                                DNA isolated from FFPE, or any other fresh tumor tissue source was
                                                used to perform targeted gene capture using a custom capture kit.
                                                The libraries were sequenced to mean >350X coverage on Illumina
                                                sequencing platform.
                                              </p> -->
                <p style="text-align: justify;">
                  DNA isolated from FFPE, or any other fresh tumor tissue source was used to perform targeted gene capture using
                  a custom capture kit. The libraries were sequenced to mean
                  >350X coverage on Illumina sequencing platform.
                </p>
              </div>
            </div>
            <!--  -->
            <div class="row mt-4">
              <div class="col">
                <h6 class="font-weight-bold">
                  Bioinformatics Analysis and Reporting :
                </h6>
                <!-- <p>
                                                The sequences obtained are aligned to human reference genome
                                                (GRCh37/hg19) and variant analysis was performed using set of
                                                Bioinformatics Pipeline. Only non-synonymous and splice site
                                                variants found in the panel consisting of specific set of genes were
                                                used for clinical interpretation. Silent variations that do not
                                                result in any change in amino acid in the coding region are not
                                                reported. Clinically relevant mutations were annotated using
                                                published variants in literature and a set of databases – ClinVar,
                                                COSMIC and dbSNP. Common variants are filtered based on allele
                                                frequency in 1000 Genome Phase 3, ExAC, dbSNP, gnomAD, etc. In the
                                                absence of a clinically significant reported known variation(s),
                                                pathogenicity will be predicted based on <i>in-silico</i> gene
                                                prioritization tools: CADD, SIFT, PolyPhen-2, Condel and Mutation
                                                taster and prioritized for clinical correlation. The identified
                                                pathogenic variant will be correlated with observed phenotypic
                                                features of the patient and interpreted according to American
                                                College of Medical Genetics (ACMG) guidelines.
                                              </p>
                                              <p class="mb-2 d-block">
                                                Somatic variants are classified into three tiers based on their level
                                                of clinical significance in cancer diagnosis, prognosis, and/or
                                                therapeutics as per international guidelines: ACMG, ASCO, AMP, CAP,
                                                NCCN and ESMO
                                              </p> -->
                <p style="text-align: justify;">
                  The sequences obtained are aligned to human reference genome (GRCh37/hg19) and variant analysis was performed
                  using set of Bioinformatics Pipeline. Only non-synonymous and splice site variants found in the panel
                  consisting of specific set of genes were used for clinical interpretation. Silent variations that do not
                  result in any change
                  in amino acid in the coding region are not reported. Clinically relevant mutations were annotated using
                  published variants in literature and a set of databases – ClinVar, COSMIC
                  and dbSNP. Common variants are filtered based on allele frequency in 1000 Genome Phase 3, ExAC, dbSNP, gnomAD,
                  etc. In the absence of a clinically significant reported
                  known variation(s), pathogenicity will be predicted based on <i>in-silico</i> gene prioritization tools: CADD, SIFT,
                  PolyPhen-2, Condel and Mutation taster and prioritized for clinical
                  correlation. The identified pathogenic variant will be correlated with observed phenotypic features of the patient and interpreted according to ACMG/AMP guidelines.
                </p>
                <p class="mb-2 d-block" style="text-align: justify;">
                  Somatic variants are classified into three tiers based on their level of clinical significance in cancer
                  diagnosis, prognosis, and/or therapeutics as per international guidelines:
                  ACMG, ASCO, AMP, CAP, NCCN and ESMO.
                </p>
              </div>
            </div>

            <h6 class="py-2 h6_bg mt-2" [ngClass]="'skyBlue'">
              LIMITATIONS AND DISCLAIMER
            </h6>

            <div class="row">
              <div class="col">
                <div class="disc">
                  <ul class="ml-0 pl-4" style="text-align: justify;">
                    <li>
                      TARGT FIRST SOLID test has been developed, validated and performed by 4baseCare Oncosolutions Pvt. Ltd and
                      has not been cleared or approved by the FDA.
                    </li>
                    <li>
                      DNA studies do not constitute a definitive test for the selected condition(s) in all individuals. It
                      should be realized that there are possible sources of error. Errors can result
                      from trace contamination, rare technical errors, rare genetic variants that interfere with analysis,
                      recent scientific developments, and alternative classification systems. This
                      test should be one of the many aspects used by the healthcare provider to help with a diagnosis and
                      treatment plan.
                    </li>
                    <li>
                      We are using the canonical transcript for clinical reporting which is usually the longest coding
                      transcript with strong/multiple supporting evidence. However, in rare cases,
                      clinically relevant variants annotated in alternate complete coding transcripts could also be reported.
                    </li>
                    <li>
                      The contents of this test should be carefully assessed by the treating physician and further interpreted
                      along with clinical, histopathological findings, contraindications and
                      guidelines before deciding the course of therapy.
                    </li>
                    <li>
                      The CNVs detected must be confirmed by an alternate method, such as IHC, for further clinical management decisions.
                    </li>
                    <!-- <li>
                      The sensitivity of this assay to detect large deletions/duplications of more than 10 bp or copy number
                      variations (CNV) is 70-75%. The CNVs detected have to be confirmed
                      by alternate method.
                    </li> -->
                    <li>
                      Most recent block is recommended for testing as the mutation profile may change in response to treatment
                      and hence differ at different sampling points.
                    </li>
                    <li>
                      The identified pathogenic variant will be correlated with observed phenotypic features of the patient and interpreted according to AMP guidelines.
                    </li>
                    <li>
                      Certain genes may not be covered completely, and few mutations could be missed. A negative result cannot
                      rule out the possibility that the tested tumor sample carries
                      mutations not previously associated with cancer and hence not included in the panel.
                    </li>
                    <li>
                      Our limit of detection for TARGT FIRST SOLID is 5% for SNVs, 10% for InDels and CNV gain &#8805; 6. In addition
                      to this, sequencing quality and coverage is dependent on many factors
                      such as homopolymers, GC-rich regions, intrinsic quality of FFPE DNA and that might impact the variant
                      detection.
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <!--  -->
            <div class="row footer d-flex justify-content-between align-items-center">
              <div class="col">
                <div class="py-2">
                  <p>
                    <span class="font-weight-bold">Genomics Bihar,</span>
                  </p>
                  <p>Homi Bhabha cancer hospital & Research centre</p>
                  <p>Inside Srikrishna Medical College & Hospital</p>
                  <p>Umanagar, Muzaffarpur-842004.</p>
                  <p>Contact: ngs.mfp@gmail.com</p>
                </div>
              </div>
              <div class="col">
                <p class="text-right">
                  <img
                    src="../../../assets/tmc/tmc_footer.png"
                    alt="footer_image"
                    class="footer_img"
                  />
                </p>
              </div>
            </div>
          </div>
        </div>


        <!-- For TMH Template 3-->
        <div *ngIf="!is4basecare && isTMH && temp=='temp3'">

          <!-- Page 1 -->
          <div id="page1" class="container position-relative page" style="background-color: white">
            <div class="d-flex justify-content-between logo_panel py-3">
              <div class="logo_part">
                <img src="../../../assets/tmc/tmc_log1.png" alt="" class="logo_first" />
              </div>
              <div class="logo_part text-center">
                <img src="../../../assets/tmc/tmc_logo2.png" alt="" class="logo_two" />
                <p style="font-size: 10px">
                  An Initiative of Bihar Govt. & Doctors For You
                </p>
                <p class="text-uppercase">MEDICAL GENOMICS & MOLECULAR ONCOLOGY</p>
              </div>
              <div class="logo_part">
                <img src="../../../assets/tmc/tmc_logo3.jpg" alt="" class="logo_three" />
              </div>
            </div>
            <!--  -->
            <div class="mar_top"></div>
            <!--  -->
            <div class="row">
              <div class="col-7">
                <div class="">
                  <div class="w-50" style="min-height: 80px">
                    <p class="py-1 font-weight-bold" [ngClass]="'txt-skyblue'">
                      SCOPE OF THE TEST
                    </p>
                    <p>SNVs, InDels, CNAs, Gene Fusions status</p>
                  </div>
                  <img src="../../../assets/BorderLine.png" class="mr-2 w-100" />
                </div>
              </div>
              <div class="col">
                <div class="">
                  <div class="w-100">
                    <!-- <p style="color: #208dcb;" class="py-1">
                                                            <span style="color: #000 !important"></span>
                                                          </p> -->
                    <div class="w-100" style="min-height: 80px">
                      <p [ngClass]="'txt-skyblue'" class="py-1 font-weight-bold" *ngIf="report.clinicalSummary != null">
                        <span *ngIf="report.clinicalSummary != ' '">CLINICAL INDICATION</span>
                      </p>

                      <p *ngIf="report.clinicalSummary != null">
                        <span style="color: #000 !important" *ngIf="report.clinicalSummary != ' '">{{ report.clinicalSummary
                          }}</span>
                      </p>
                    </div>
                    <!-- </div> -->
                    <img src="../../../assets/BorderLine.png" class="mr-2 w-100" />

                    <!-- </div> -->
                  </div>
                </div>
              </div>
            </div>
            <!--  -->
            <div class="row pt-2">
              <div class="col-7">
                <h6 class="text-uppercase py-2 font-weight-bold">Report Details</h6>
                <ul class="list-unstyled">
                  <li class="pb-1">
                    <span class="clr-cyan">Patient Name : </span>
                    <span>{{ report.patient?.name }}</span>
                  </li>
                  <li class="pb-1">
                    <span class="clr-cyan" [ngClass]="'txt-skyblue'">Gender : </span>
                    <span class="text-black">{{
                      report.patient?.gender | titlecase
                    }}</span>
                  </li>
                  <li class="pb-1">
                    <span class="clr-cyan" [ngClass]="'txt-skyblue'">Age/DOB : </span>
                    <span class="text-black">{{
                      report.patient?.dateOfBirth
                        ? (report.patient?.dateOfBirth | date : "dd/MM/yyyy")
                        : report.patient?.age + " Years"
                    }}</span>
                  </li>
                  <li class="pb-1">
                    <span class="clr-cyan">Case No : </span
                    ><span>{{ report?.tmhReportInfo?.caseNo }}</span>
                  </li>
                  <li class="pb-1">
                    <span class="clr-cyan">Path No : </span
                    ><span>{{ report?.tmhReportInfo?.pathNo }}</span>
                  </li>
                  <li class="pb-1">
                    <span class="clr-cyan">Referred By : </span
                    ><span>{{ referringClinician }}</span>
                  </li>
                </ul>
              </div>
              <div class="col">
                <ul class="list-unstyled mt-4 pt-4">
                  <li class="pb-1">
                    <span class="clr-cyan">Patient ID : </span
                    ><span>{{ report.patient?.organisationPatientId }}</span>
                  </li>
                  <li class="pb-1">
                    <span class="clr-cyan">Category : </span
                    ><span>{{ report?.tmhReportInfo?.category }}</span>
                  </li>
                  <li class="pb-1">
                    <span class="clr-cyan">Collected on : </span
                    ><span>{{
                      report.labDetails?.sampleCollected | date : "dd/MM/yyyy"
                    }}</span>
                  </li>
                  <li class="pb-1">
                    <span class="clr-cyan">Reported on : </span
                    ><span>{{ date | date : "dd/MM/yyyy" }}</span>
                  </li>
                  <li class="pb-1">
                    <span class="clr-cyan">Hospital Name : </span
                    ><span>{{ labHospital }}</span>
                  </li>
                </ul>
              </div>
            </div>
            <!--  -->
            <h6 class="py-2 h6_bg">CLINICAL HISTORY/SAMPLE DETAILS</h6>
            <div class="row mt-2 py-2">
              <div class="col-5">
                <h6 class="mb-0 font-weight-bold">Clinical Evaluations</h6>
                <span>{{ report?.cancerIdentifier }}</span>
              </div>
              <div class="col-5">
                <h6 class="mb-0 font-weight-bold">Sample Details</h6>
                <span *ngIf="report.labDetails?.sampleSource">{{
                  report.labDetails?.sampleSource
                  }}</span>
                <span *ngIf="!report.labDetails?.sampleSource">FFPE Block</span>
              </div>
              <div class="col">
                <h6 class="mb-0 font-weight-bold">Tumor content</h6>
                <span>{{ report?.tmhReportInfo?.tumorContent }}</span>
              </div>
              <div class="w-100">
                <div class="col pt-4">
                  <p>(Confirmed by - Consultant Pathologist, HBCHRC, Muzaffarpur)</p>
                </div>
              </div>
            </div>
            <!--  -->
            <div class="row mt-2 py-2">
              <div class="col">
                <h6 class="mb-0 text-uppercase f-weight_700">Results</h6>
              </div>
            </div>
            <h6 class="py-2 h6_bg" [ngClass]="'skyBlue'">
              Genomic findings from tumor profiling
            </h6>

            <!--  -->
            <div class="row">
              <div style="width: 100%;" *ngIf="isReportDownload">
                <img [src]="firstPageTable" alt="" width="100%" class="img-fluid" />
              </div>
              <div class="col-12" id="firstPageTable" *ngIf="!isReportDownload">
                <table class="page_1 cust_table w-100 mt-3 first_1" [ngClass]="'table_skyblue'">
                  <tr>
                    <th>Genomic Alteration</th>
                    <th>Relevant Therapies (in Same Cancer Type)</th>
                    <th>Relevant Therapies (in Different Cancer)</th>
                  </tr>

                  <tr *ngIf="tableCount== 0 && prognasticCount>0 ">
                    <td colspan="3" class="text-center mt-2">
                      Clinically relevant genomic alterations associated with therapeutic significance were not detected.
                    </td>
                  </tr>
                  <tr *ngIf="tableCount== 0 && prognasticCount == 0">
                    <td colspan="3" class="text-center mt-2">
                      Clinically relevant genomic alterations associated with both therapeutic and prognostic significance were
                      not detected.
                    </td>
                  </tr>

                  <!-- SNV -->
                  <ng-container>
                    <tr *ngFor="
                                                                let snvHotspotTrail of reportPdfRes?.allHotspotTrailList  | slice : 0 : 3 ; let i=index
                                                              ">
                      <td>
                        <i>{{ snvHotspotTrail.gene }}</i>
                        &nbsp;<span *ngIf="!snvHotspotTrail.gene.includes('Wild type')">
                        <span *ngIf="snvHotspotTrail?.multiVariant==null">
                          <span *ngIf="snvHotspotTrail.exonNumber != null || snvHotspotTrail.pposition != null && snvHotspotTrail.pposition != ''">

                            <span *ngIf="snvHotspotTrail.exonNumber != null">{{snvHotspotTrail.exonNumber}}</span>&nbsp;
                            <span *ngIf="snvHotspotTrail.pposition != null && snvHotspotTrail.pposition != ''">({{snvHotspotTrail.pposition?.replace(' ','')}})</span></span>
                            <p *ngIf="snvHotspotTrail.mutantBurdenPercent != null && !snvHotspotTrail.gene.includes('Wild type')">
                              Allelic burden: {{ snvHotspotTrail.mutantBurdenPercent }}%
                            </p>
                            <span *ngIf="snvHotspotTrail.copyNumber!=null && snvHotspotTrail.copyNumber.length>0"> {{ snvHotspotTrail.copyNumber }}
                          </span>
                        </span>
                        <span *ngIf="snvHotspotTrail?.multiVariant!=null"><br/>
                          <span  style="font-size: 14px !important" [innerHtml]="snvHotspotTrail?.multiVariant"></span>
                        </span></span>
                      </td>
                      <td>
                        <table class="table first_table w-100">
                          <thead class="border-bottom cust_clr">
                            <tr>
                              <th class="w-50">
                                <span class="font-weight-normal"><i>Therapy</i></span>
                              </th>
                              <th class="w-33">
                                <span class="font-weight-normal"><i>Clinical Relevance</i></span>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngIf="snvHotspotTrail.sameCancerType == null">
                              <td class="w-50">NA</td>
                              <td class="w-50">NA</td>
                            </tr>

                            <ng-container *ngIf="snvHotspotTrail.sameCancerType != null">
                              <tr *ngFor="
                                                                          let item of snvHotspotTrail.sameTypeRelevantData
                                                                        ">
                                <td>{{ item.therapy }}</td>
                                <td>{{ item.association }}</td>
                                <!-- <td>{{item.cancerType}}</td> -->
                              </tr>
                            </ng-container>
                          </tbody>
                        </table>
                      </td>
                      <td>
                        <table class="table first_table w-100">
                          <thead class="border-bottom cust_clr">
                            <tr>
                              <th class="w-33">
                                <span class="font-weight-normal"><i>Therapy</i></span>
                              </th>
                              <th class="w-33">
                                <span class="font-weight-normal"><i>Clinical Relevance</i></span>
                              </th>
                              <th class="w-33">
                                <span class="font-weight-normal"><i>Cancer Type</i></span>
                              </th>
                            </tr>
                          </thead>
                          <tbody>

                            <ng-container *ngIf="snvHotspotTrail.otherCancerType != null">
                              <tr *ngFor="
                                                                          let item of snvHotspotTrail.otherTypeRelevantData
                                                                        ">
                                <td>{{ item.therapy }}</td>
                                <td>{{ item.association }}</td>
                                <td>{{ item.cancerType }}</td>
                              </tr>
                            </ng-container>
                            <tr *ngIf="snvHotspotTrail.otherCancerType == null">
                              <td class="w-33">NA</td>
                              <td class="w-33">NA</td>
                              <td class="w-33">NA</td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>


                  </ng-container>
                </table>
                <p *ngIf="
                                        snvHotSpot.length != 0 ||
                                        cnaHotSpot.length != 0 ||
                                                            fusionHotSpot.length !=0
                                                          " style="font-size: 13px !important" class="mt-0">
                  <i> *NA: Not Applicable</i>
                </p>
              </div>
            </div>
            <!--  -->

            <!--  -->



            <!--  -->
            <div class="row footer d-flex justify-content-between align-items-center">
              <div class="col">
                <div class="py-2">
                  <p>
                    <span class="font-weight-bold">Genomics Bihar,</span>
                  </p>
                  <p>Homi Bhabha cancer hospital & Research centre</p>
                  <p>Inside Srikrishna Medical College & Hospital</p>
                  <p>Umanagar, Muzaffarpur-842004.</p>
                  <p>Contact: ngs.mfp@gmail.com</p>
                </div>
              </div>
              <div class="col">
                <p class="text-right">
                  <img
                    src="../../../assets/tmc/tmc_footer.png"
                    alt="footer_image"
                    class="footer_img"
                  />
                </p>
              </div>
            </div>
          </div>
          <!-- page 1_1 -->
          <div id="page2" *ngIf="reportPdfRes.allHotspotTrailList.length>6" class="container position-relative page"
            style="background-color: white">
              <div class="d-flex justify-content-between logo_panel py-3">
                <div class="logo_part">
                  <img src="../../../assets/tmc/tmc_log1.png" alt="" class="logo_first" />
                </div>
                <div class="logo_part text-center">
                  <img src="../../../assets/tmc/tmc_logo2.png" alt="" class="logo_two" />
                  <p style="font-size: 10px">
                    An Initiative of Bihar Govt. & Doctors For You
                  </p>
                  <p class="text-uppercase">MEDICAL GENOMICS & MOLECULAR ONCOLOGY</p>
                </div>
                <div class="logo_part">
                  <img src="../../../assets/tmc/tmc_logo3.jpg" alt="" class="logo_three" />
                </div>
              </div>
              <div class="row header mt-1">
              <div></div>
              <div class="col">
                <div class="patient-detailes ml-auto float-right">
                  <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
                    <li>
                      Patient Id <br />
                      <span>{{ report.patient?.ecrfId }}</span>
                    </li>
                    <li class="ml-4">
                      Patient Name<br />
                      <span>{{ report.patient?.name }}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <!--  -->
              <div *ngIf="report.clientType == '4basecare_client'">
                <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
                  </div>
                </div>
                <div *ngIf="report.clientName == 'path_labs'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
                  </div>
                </div>
              </div>
            </div>

            <!--  -->
            <div class="row">
              <div style="width: 100%;" *ngIf="isReportDownload">
                <img [src]="firstPageTable1" alt="" width="100%" class="img-fluid" />
              </div>
              <div class="col-12" id="firstPageTable1" *ngIf="!isReportDownload">
                <table class="page_1 cust_table w-100 mt-3 first_1" [ngClass]="'table_skyblue'">
                  <tr>
                    <th>Genomic Alteration</th>
                    <th>Relevant Therapies (in Same Cancer Type)</th>
                    <th>Relevant Therapies (in Different Cancer)</th>
                  </tr>

                  <tr *ngIf="tableCount== 0 && prognasticCount>0 ">
                    <td colspan="3" class="text-center mt-2">
                      Clinically relevant genomic alterations associated with therapeutic significance were not detected.
                    </td>
                  </tr>
                  <tr *ngIf="tableCount== 0 && prognasticCount == 0">
                    <td colspan="3" class="text-center mt-2">
                      Clinically relevant genomic alterations associated with both therapeutic and prognostic significance were
                      not detected.
                    </td>
                  </tr>

                  <!-- SNV -->
                  <ng-container>
                    <tr *ngFor="
                                                                let snvHotspotTrail of reportPdfRes?.allHotspotTrailList  | slice : 3 : 8 ; let i=index
                                                              ">
                      <td>
                        <i>{{ snvHotspotTrail.gene }}</i>
                        &nbsp;<span *ngIf="!snvHotspotTrail.gene.includes('Wild type')">
                        <span *ngIf="snvHotspotTrail?.multiVariant==null">
                          <span *ngIf="snvHotspotTrail.exonNumber != null || snvHotspotTrail.pposition != null && snvHotspotTrail.pposition != ''">

                            <span *ngIf="snvHotspotTrail.exonNumber != null">{{snvHotspotTrail.exonNumber}}</span>&nbsp;
                            <span *ngIf="snvHotspotTrail.pposition != null && snvHotspotTrail.pposition != ''">({{snvHotspotTrail.pposition?.replace(' ','')}})</span></span>
                            <p *ngIf="snvHotspotTrail.mutantBurdenPercent != null && !snvHotspotTrail.gene.includes('Wild type')">
                              Allelic burden: {{ snvHotspotTrail.mutantBurdenPercent }}%
                            </p>
                            <span *ngIf="snvHotspotTrail.copyNumber!=null && snvHotspotTrail.copyNumber.length>0"> {{ snvHotspotTrail.copyNumber }}
                          </span>
                        </span>
                        <span *ngIf="snvHotspotTrail?.multiVariant!=null"><br/>
                        <span  style="font-size: 14px !important" [innerHtml]="snvHotspotTrail?.multiVariant"></span>
                        </span></span>
                      </td>
                      <td>
                        <table class="table first_table w-100">
                          <thead class="border-bottom cust_clr">
                            <tr>
                              <th class="w-50">
                                <span class="font-weight-normal"><i>Therapy</i></span>
                              </th>
                              <th class="w-33">
                                <span class="font-weight-normal"><i>Clinical Relevance</i></span>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngIf="snvHotspotTrail.sameCancerType == null">
                              <td class="w-50">NA</td>
                              <td class="w-50">NA</td>
                            </tr>

                            <ng-container *ngIf="snvHotspotTrail.sameCancerType != null">
                              <tr *ngFor="
                                                                          let item of snvHotspotTrail.sameTypeRelevantData
                                                                        ">
                                <td>{{ item.therapy }}</td>
                                <td>{{ item.association }}</td>
                                <!-- <td>{{item.cancerType}}</td> -->
                              </tr>
                            </ng-container>
                          </tbody>
                        </table>
                      </td>
                      <td>
                        <table class="table first_table w-100">
                          <thead class="border-bottom cust_clr">
                            <tr>
                              <th class="w-33">
                                <span class="font-weight-normal"><i>Therapy</i></span>
                              </th>
                              <th class="w-33">
                                <span class="font-weight-normal"><i>Clinical Relevance</i></span>
                              </th>
                              <th class="w-33">
                                <span class="font-weight-normal"><i>Cancer Type</i></span>
                              </th>
                            </tr>
                          </thead>
                          <tbody>

                            <ng-container *ngIf="snvHotspotTrail.otherCancerType != null">
                              <tr *ngFor="
                                                                          let item of snvHotspotTrail.otherTypeRelevantData
                                                                        ">
                                <td>{{ item.therapy }}</td>
                                <td>{{ item.association }}</td>
                                <td>{{ item.cancerType }}</td>
                              </tr>
                            </ng-container>
                            <tr *ngIf="snvHotspotTrail.otherCancerType == null">
                              <td class="w-33">NA</td>
                              <td class="w-33">NA</td>
                              <td class="w-33">NA</td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>


                  </ng-container>
                </table>
                <p *ngIf="
                                        snvHotSpot.length != 0 ||
                                        cnaHotSpot.length != 0 ||
                                                            fusionHotSpot.length !=0
                                                          " style="font-size: 13px !important" class="mt-0">
                  <i> *NA: Not Applicable</i>
                </p>
              </div>
            </div>


            <!--  -->
            <div class="row footer d-flex justify-content-between align-items-center">
              <div class="col">
                <div class="py-2">
                  <p>
                    <span class="font-weight-bold">Genomics Bihar,</span>
                  </p>
                  <p>Homi Bhabha cancer hospital & Research centre</p>
                  <p>Inside Srikrishna Medical College & Hospital</p>
                  <p>Umanagar, Muzaffarpur-842004.</p>
                  <p>Contact: ngs.mfp@gmail.com</p>
                </div>
              </div>
              <div class="col">
                <p class="text-right">
                  <img
                    src="../../../assets/tmc/tmc_footer.png"
                    alt="footer_image"
                    class="footer_img"
                  />
                </p>
              </div>
            </div>
          </div>


          <!-- page 1_2 -->
          <div id="page1_2" *ngIf="
                                  prognasticCount==0 || prognasticCount>0 ||  (report.disclaimer != '<p></p>' &&
                                  report.disclaimer != '' &&
                                  report.disclaimer != null) ||
                                  (reportPdfRes.allHotspotTrailList.length>3 && (reportPdfRes.allHotspotTrailList.length<=6 ||reportPdfRes.allHotspotTrailList.length>8))"
            class="container position-relative page" style="background-color: white">
              <div class="d-flex justify-content-between logo_panel py-3">
                <div class="logo_part">
                  <img src="../../../assets/tmc/tmc_log1.png" alt="" class="logo_first" />
                </div>
                <div class="logo_part text-center">
                  <img src="../../../assets/tmc/tmc_logo2.png" alt="" class="logo_two" />
                  <p style="font-size: 10px">
                    An Initiative of Bihar Govt. & Doctors For You
                  </p>
                  <p class="text-uppercase">MEDICAL GENOMICS & MOLECULAR ONCOLOGY</p>
                </div>
                <div class="logo_part">
                  <img src="../../../assets/tmc/tmc_logo3.jpg" alt="" class="logo_three" />
                </div>
              </div>
              <div class="row header mt-1">
              <div></div>
              <div class="col">
                <div class="patient-detailes ml-auto float-right">
                  <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
                    <li>
                      Patient Id <br />
                      <span>{{ report.patient?.ecrfId }}</span>
                    </li>
                    <li class="ml-4">
                      Patient Name<br />
                      <span>{{ report.patient?.name }}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <!--  -->

              <div *ngIf="report.clientType == '4basecare_client'">
                <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
                  </div>
                </div>
                <div *ngIf="report.clientName == 'path_labs'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
                  </div>
                </div>
              </div>
            </div>

            <div class="row" *ngIf="reportPdfRes.allHotspotTrailList.length>3 && reportPdfRes?.allHotspotTrailList.length<=6 || reportPdfRes?.allHotspotTrailList.length>8">
              <div style="width: 100%;" *ngIf="isReportDownload">
                <img [src]="firstPageTable2" alt="" width="100%" class="img-fluid" />
              </div>
              <div class="col-12" id="firstPageTable2" *ngIf="!isReportDownload">
                <table class="page_1 cust_table w-100 mt-3 first_1" [ngClass]="'table_skyblue'">
                  <tr>
                    <th>Genomic Alteration</th>
                    <th>Relevant Therapies (in Same Cancer Type)</th>
                    <th>Relevant Therapies (in Different Cancer)</th>
                  </tr>

                  <tr *ngIf="tableCount== 0 && prognasticCount>0 ">
                    <td colspan="3" class="text-center mt-2">
                      Clinically relevant genomic alterations associated with therapeutic significance were not detected.
                    </td>
                  </tr>
                  <tr *ngIf="tableCount== 0 && prognasticCount == 0">
                    <td colspan="3" class="text-center mt-2">
                      Clinically relevant genomic alterations associated with both therapeutic and prognostic significance were
                      not detected.
                    </td>
                  </tr>

                  <!-- SNV -->
                  <ng-container *ngIf="reportPdfRes?.allHotspotTrailList.length<=6">
                    <tr *ngFor="
                                                                let snvHotspotTrail of reportPdfRes?.allHotspotTrailList  | slice : 3 : 6 ; let i=index
                                                              ">
                      <td>
                        <i>{{ snvHotspotTrail.gene }}</i>
                        &nbsp; <span *ngIf="!snvHotspotTrail.gene.includes('Wild type')">
                                        <span *ngIf="snvHotspotTrail?.multiVariant==null">
                          <span *ngIf="snvHotspotTrail.exonNumber != null || snvHotspotTrail.pposition != null && snvHotspotTrail.pposition != ''">

                            <span *ngIf="snvHotspotTrail.exonNumber != null">{{snvHotspotTrail.exonNumber}}</span>&nbsp;
                            <span *ngIf="snvHotspotTrail.pposition != null && snvHotspotTrail.pposition != ''">({{snvHotspotTrail.pposition?.replace(' ','')}})</span></span>
                            <p *ngIf="snvHotspotTrail.mutantBurdenPercent != null && !snvHotspotTrail.gene.includes('Wild type')">
                              Allelic burden: {{ snvHotspotTrail.mutantBurdenPercent }}%
                            </p>
                            <span *ngIf="snvHotspotTrail.copyNumber!=null && snvHotspotTrail.copyNumber.length>0"> {{ snvHotspotTrail.copyNumber }}
                          </span>
                        </span>
                        <span *ngIf="snvHotspotTrail?.multiVariant!=null"><br/>
                          <span  style="font-size: 14px !important" [innerHtml]="snvHotspotTrail?.multiVariant"></span>
                        </span></span>
                      </td>
                      <td>
                        <table class="table first_table w-100">
                          <thead class="border-bottom cust_clr">
                            <tr>
                              <th class="w-50">
                                <span class="font-weight-normal"><i>Therapy</i></span>
                              </th>
                              <th class="w-33">
                                <span class="font-weight-normal"><i>Clinical Relevance</i></span>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngIf="snvHotspotTrail.sameCancerType == null">
                              <td class="w-50">NA</td>
                              <td class="w-50">NA</td>
                            </tr>

                            <ng-container *ngIf="snvHotspotTrail.sameCancerType != null">
                              <tr *ngFor="
                                                                          let item of snvHotspotTrail.sameTypeRelevantData
                                                                        ">
                                <td>{{ item.therapy }}</td>
                                <td>{{ item.association }}</td>
                                <!-- <td>{{item.cancerType}}</td> -->
                              </tr>
                            </ng-container>
                          </tbody>
                        </table>
                      </td>
                      <td>
                        <table class="table first_table w-100">
                          <thead class="border-bottom cust_clr">
                            <tr>
                              <th class="w-33">
                                <span class="font-weight-normal"><i>Therapy</i></span>
                              </th>
                              <th class="w-33">
                                <span class="font-weight-normal"><i>Clinical Relevance</i></span>
                              </th>
                              <th class="w-33">
                                <span class="font-weight-normal"><i>Cancer Type</i></span>
                              </th>
                            </tr>
                          </thead>
                          <tbody>

                            <ng-container *ngIf="snvHotspotTrail.otherCancerType != null">
                              <tr *ngFor="
                                                                          let item of snvHotspotTrail.otherTypeRelevantData
                                                                        ">
                                <td>{{ item.therapy }}</td>
                                <td>{{ item.association }}</td>
                                <td>{{ item.cancerType }}</td>
                              </tr>
                            </ng-container>
                            <tr *ngIf="snvHotspotTrail.otherCancerType == null">
                              <td class="w-33">NA</td>
                              <td class="w-33">NA</td>
                              <td class="w-33">NA</td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>


                  </ng-container>
                  <ng-container *ngIf="reportPdfRes?.allHotspotTrailList.length>8">
                    <tr *ngFor="
                                                                let snvHotspotTrail of reportPdfRes?.allHotspotTrailList  | slice : 8 : 12 ; let i=index
                                                              ">
                      <td>
                        <i>{{ snvHotspotTrail.gene }}</i>
                        &nbsp;   <span *ngIf="!snvHotspotTrail.gene.includes('Wild type')">
                                         <span *ngIf="snvHotspotTrail.exonNumber != null || snvHotspotTrail.pposition != null && snvHotspotTrail.pposition != ''">

                        <span *ngIf="snvHotspotTrail.exonNumber != null">{{snvHotspotTrail.exonNumber}}</span>&nbsp;
                        <span
                          *ngIf="snvHotspotTrail.pposition != null && snvHotspotTrail.pposition != ''">({{snvHotspotTrail.pposition?.replace(' ','')}})</span></span>
                        <p *ngIf="snvHotspotTrail.mutantBurdenPercent != null && !snvHotspotTrail.gene.includes('Wild type')">
                          Allelic burden: {{ snvHotspotTrail.mutantBurdenPercent }}%
                        </p>
                        <span *ngIf="snvHotspotTrail.copyNumber!=null && snvHotspotTrail.copyNumber.length>0"> {{ snvHotspotTrail.copyNumber }}
                        </span></span>
                      </td>
                      <td>
                        <table class="table first_table w-100">
                          <thead class="border-bottom cust_clr">
                            <tr>
                              <th class="w-50">
                                <span class="font-weight-normal"><i>Therapy</i></span>
                              </th>
                              <th class="w-33">
                                <span class="font-weight-normal"><i>Clinical Relevance</i></span>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngIf="snvHotspotTrail.sameCancerType == null">
                              <td class="w-50">NA</td>
                              <td class="w-50">NA</td>
                            </tr>

                            <ng-container *ngIf="snvHotspotTrail.sameCancerType != null">
                              <tr *ngFor="
                                                                          let item of snvHotspotTrail.sameTypeRelevantData
                                                                        ">
                                <td>{{ item.therapy }}</td>
                                <td>{{ item.association }}</td>
                                <!-- <td>{{item.cancerType}}</td> -->
                              </tr>
                            </ng-container>
                          </tbody>
                        </table>
                      </td>
                      <td>
                        <table class="table first_table w-100">
                          <thead class="border-bottom cust_clr">
                            <tr>
                              <th class="w-33">
                                <span class="font-weight-normal"><i>Therapy</i></span>
                              </th>
                              <th class="w-33">
                                <span class="font-weight-normal"><i>Clinical Relevance</i></span>
                              </th>
                              <th class="w-33">
                                <span class="font-weight-normal"><i>Cancer Type</i></span>
                              </th>
                            </tr>
                          </thead>
                          <tbody>

                            <ng-container *ngIf="snvHotspotTrail.otherCancerType != null">
                              <tr *ngFor="
                                                                          let item of snvHotspotTrail.otherTypeRelevantData
                                                                        ">
                                <td>{{ item.therapy }}</td>
                                <td>{{ item.association }}</td>
                                <td>{{ item.cancerType }}</td>
                              </tr>
                            </ng-container>
                            <tr *ngIf="snvHotspotTrail.otherCancerType == null">
                              <td class="w-33">NA</td>
                              <td class="w-33">NA</td>
                              <td class="w-33">NA</td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>


                  </ng-container>
                </table>
                <p *ngIf="
                                        snvHotSpot.length != 0 ||
                                        cnaHotSpot.length != 0 ||
                                                            fusionHotSpot.length !=0
                                                          " style="font-size: 13px !important" class="mt-0">
                  <i> *NA: Not Applicable</i>
                </p>
              </div>
            </div>
            <div *ngIf="(tableCount>0) &&
                              prognasticCount==0">
              <h6 class="py-2 h6_bg"> Prognostic Biomarkers</h6>
            </div>
            <div class="row" *ngIf="(tableCount>0 )&&
                              prognasticCount==0">
              <div class="col-12">
                <table class="page_1 cust_table w-100 mt-3 varient_details prognasticbiomarkertable"
                  [ngClass]="'table_variant-blue'">
                  <thead>
                    <tr>
                      <th>Genomic Alteration</th>
                      <th>Prognostic Significance</th>
                    </tr>
                  </thead>
                  <ng-container>
                    <tr>
                      <td colspan="2" style="text-align:center">
                        Clinically relevant genomic alterations associated with prognostic significance were not detected.
                      </td>
                    </tr>
                  </ng-container>
                </table>
              </div>
            </div>
            <div *ngIf="prognasticCount>0">
              <h6 class="py-2 h6_bg"> Prognostic Biomarkers</h6>
            </div>

            <div class="row" *ngIf="prognasticCount>0">
              <div class="col-12">
                <table class="page_1 cust_table w-100 mt-3 varient_details prognasticbiomarkertable"
                  [ngClass]="'table_variant-blue'">
                  <thead>
                    <tr>
                      <th>Genomic Alteration</th>
                      <th>Prognostic Significance</th>

                    </tr>
                  </thead>
                  <ng-container *ngFor="let prog of reportPdfRes.prognosticBiomarkers">
                    <tr>
                      <td style="padding-left: 3px !important">
                        <i>{{ prog.gene }}&nbsp; </i>
                        <span [innerHtml]="prog.geneDetails"></span>
                      </td>
                      <td style="padding-left: 3px !important;">{{prog.statement}}
                      </td>
                    </tr>
                  </ng-container>


                </table>
              </div>
            </div>

            <!-- gene Data -->
            <!-- <div *ngIf="geneMutationlist != null && isStatusDisplay">
              <div class="mt-3">
                <h6 class="py-2 h6_bg" [ngClass]="'skyBlue'">
                  Status of variants in cancer related Biomarkers
                </h6>
              </div>

              <table class="staus_gene bg_skyblue" *ngIf="geneMutationlist != null" [ngClass]="{
                                                                                      biomarker_table:
                                                                                        geneMutationlist.length >= 4 &&
                                                                                        geneMutationlist.length < 8
                                                                                    }">
                <tr style="background: #daedf7">
                  <td style="font-size: 18px !important; padding-left: 10px !important">
                    Gene
                  </td>
                  <td *ngFor="
                                                                                          let geneMutation of geneMutationlist
                                                                                            | slice : 0 : 8
                                                                                        ">
                    <i>{{ geneMutation.gene }}</i>
                  </td>
                </tr>
                <tr>
                  <td style="font-size: 18px !important; padding-left: 10px !important">
                    <b>Status</b>
                  </td>
                  <td *ngFor="
                                                                                          let geneMutation of geneMutationlist
                                                                                            | slice : 0 : 8
                                                                                        ">
                    <span *ngIf="
                                                                                            geneMutation.status == 'Pathogenic' ||
                                                                                            geneMutation.status == 'Likely Pathogenic' ||
                                                                                            geneMutation.status == 'Detected'
                                                                                          "
                      style="color: red; font-weight: 600px"><b>{{
                        geneMutation.status }}</b></span>
                    <span *ngIf="geneMutation.status == 'VUS'" style="color: black; font-weight: 600px"><b>{{
                        geneMutation.status }}</b></span>
                    <span *ngIf="
                                                                                            geneMutation.status == 'Not Detected' ||
                                                                                            geneMutation.status == 'Benige'
                                                                                          ">{{ geneMutation.status }}</span>
                  </td>
                </tr>
<br/>
              </table>
             <table class="staus_gene" [ngClass]="'bg_skyblue'" *ngIf="
                                                                                      geneMutationlist != null &&
                                                                                      geneMutationlist.length > 8
                                                                                    ">
                <tr style="background: #daedf7">
                  <td style="font-size: 18px !important; padding-left: 10px !important">
                    Gene
                  </td>
                  <td *ngFor="
                                                                                          let geneMutation of geneMutationlist
                                                                                            | slice : 8 : 17
                                                                                        ">
                    <i>{{ geneMutation.gene }}</i>
                  </td>
                </tr>
                <tr>
                  <td style="font-size: 18px !important; padding-left: 10px !important">
                    <b>Status</b>
                  </td>
                  <td *ngFor="
                                                                                          let geneMutation of geneMutationlist
                                                                                            | slice : 8 : 17
                                                                                        ">
                    <span *ngIf="
                                                                                            geneMutation.status == 'Pathogenic' ||
                                                                                            geneMutation.status == 'Likely Pathogenic' ||
                                                                                            geneMutation.status == 'Detected'
                                                                                          "
                      style="color: red; font-weight: 600px"><b>{{
                        geneMutation.status }}</b></span>
                    <span *ngIf="geneMutation.status == 'VUS'" style="color: black; font-weight: 600px"><b>{{
                        geneMutation.status }}</b></span>
                    <span *ngIf="
                                                                                            geneMutation.status == 'Not Detected' ||
                                                                                            geneMutation.status == 'Benige'
                                                                                          ">{{ geneMutation.status }}</span>
                  </td>
                </tr>
<br/>
              </table>
              <table
                class="staus_gene"
                [ngClass]="'bg_skyblue'"
                *ngIf="
                  geneMutationlist != null &&
                  geneMutationlist.length > 17
                "
              >
                <tr style="background: #daedf7">
                  <td
                    style="font-size: 18px !important; padding-left: 10px !important"
                  >
                    Gene
                  </td>
                  <td
                    *ngFor="
                      let geneMutation of geneMutationlist
                        | slice : 17 : 25
                    "
                  >
                    <i>{{ geneMutation.gene }}</i>
                  </td>
                </tr>
                <tr>
                  <td
                    style="font-size: 18px !important; padding-left: 10px !important"
                  >
                    <b>Status</b>
                  </td>
                  <td
                    *ngFor="
                      let geneMutation of geneMutationlist
                        | slice : 17 : 25
                    "
                  >
                    <span
                      *ngIf="
                        geneMutation.status == 'Pathogenic' ||
                        geneMutation.status == 'Likely Pathogenic' ||
                        geneMutation.status == 'Detected'
                      "
                      style="color: red; font-weight: 600px"
                      ><b>{{ geneMutation.status }}</b></span
                    >
                    <span
                      *ngIf="geneMutation.status == 'VUS'"
                      style="color: black; font-weight: 600px"
                      ><b>{{ geneMutation.status }}</b></span
                    >
                    <span
                      *ngIf="
                        geneMutation.status == 'Not Detected' ||
                        geneMutation.status == 'Benige'
                      "
                      >{{ geneMutation.status }}</span
                    >
                  </td>
                </tr>
<br/>
              </table>
              <table class="staus_gene" [ngClass]="'bg_skyblue'" *ngIf="
                    geneMutationlist != null &&
                    geneMutationlist.length > 25
                  ">
                <tr style="background: #daedf7">
                  <td style="font-size: 18px !important; padding-left: 10px !important">
                    Gene
                  </td>
                  <td *ngFor="
                        let geneMutation of geneMutationlist
                          | slice : 25 : 33
                      ">
                    <i>{{ geneMutation.gene }}</i>
                  </td>
                </tr>
                <tr>
                  <td style="font-size: 18px !important; padding-left: 10px !important">
                    <b>Status</b>
                  </td>
                  <td *ngFor="
                        let geneMutation of geneMutationlist
                          | slice : 25 : 33
                      ">
                    <span *ngIf="
                          geneMutation.status == 'Pathogenic' ||
                          geneMutation.status == 'Likely Pathogenic' ||
                          geneMutation.status == 'Detected'
                        " style="color: red; font-weight: 600px"><b>{{ geneMutation.status }}</b></span>
                    <span *ngIf="geneMutation.status == 'VUS'" style="color: black; font-weight: 600px"><b>{{
                        geneMutation.status }}</b></span>
                    <span *ngIf="
                          geneMutation.status == 'Not Detected' ||
                          geneMutation.status == 'Benige'
                        ">{{ geneMutation.status }}</span>
                  </td>
                </tr>
<br/>
              </table>
          <table class="staus_gene" [ngClass]="'bg_skyblue'" *ngIf="
                geneMutationlist != null &&
                geneMutationlist.length > 33
              ">
            <tr style="background: #daedf7">
              <td style="font-size: 18px !important; padding-left: 10px !important">
                Gene
              </td>
              <td *ngFor="
                    let geneMutation of geneMutationlist
                      | slice : 33 : 41
                  ">
                <i>{{ geneMutation.gene }}</i>
              </td>
            </tr>
            <tr>
              <td style="font-size: 18px !important; padding-left: 10px !important">
                <b>Status</b>
              </td>
              <td *ngFor="
                    let geneMutation of geneMutationlist
                      | slice : 33 : 41
                  ">
                <span *ngIf="
                      geneMutation.status == 'Pathogenic' ||
                      geneMutation.status == 'Likely Pathogenic' ||
                      geneMutation.status == 'Detected'
                    " style="color: red; font-weight: 600px"><b>{{ geneMutation.status }}</b></span>
                <span *ngIf="geneMutation.status == 'VUS'" style="color: black; font-weight: 600px"><b>{{
                    geneMutation.status }}</b></span>
                <span *ngIf="
                      geneMutation.status == 'Not Detected' ||
                      geneMutation.status == 'Benige'
                    ">{{ geneMutation.status }}</span>
              </td>
            </tr>
            <br/>
          </table>
              <h6 *ngIf="
                                                      (fusionGeneMutationList != null &&
                                                      fusionGeneMutationList.length > 0) "
                class="font-weight-bold clr-cyan text-uppercase" [ngClass]="'txt-skyblue'">
                Gene Fusion
              </h6>
              <table class="staus_gene" *ngIf="
                                                                                      (fusionGeneMutationList != null &&
                                                                                      fusionGeneMutationList.length > 0)
                                                                                    " [ngClass]="'bg_skyblue'">
                <tr style="background: #daedf7">
                  <td style="font-size: 18px !important; padding-left: 10px !important">
                    Gene
                  </td>
                  <td *ngFor="
                                                                                          let geneMutation of fusionGeneMutationList
                                                                                            | slice : 0 : 8
                                                                                        ">
                    <i>{{ geneMutation.gene }}</i>
                  </td>
                </tr>
                <tr>
                  <td style="font-size: 18px !important; padding-left: 10px !important">
                    <b>Status</b>
                  </td>
                  <td *ngFor="
                                                                                          let geneMutation of fusionGeneMutationList
                                                                                            | slice : 0 : 8
                                                                                        ">
                    <span *ngIf="
                                                                                            geneMutation.status == 'Pathogenic' ||
                                                                                            geneMutation.status == 'Likely Pathogenic' ||
                                                                                            geneMutation.status == 'Detected'
                                                                                          "
                      style="color: red; font-weight: 600px"><b>{{
                        geneMutation.status }}</b></span>
                    <span *ngIf="geneMutation.status == 'VUS'" style="color: black; font-weight: 600px"><b>{{
                        geneMutation.status }}</b></span>
                    <span *ngIf="
                                                                                            geneMutation.status == 'Not Detected' ||
                                                                                            geneMutation.status == 'Benige'
                                                                                          ">{{ geneMutation.status }}</span>
                  </td>
                </tr>
<br/>
              </table>
              <table class="staus_gene" *ngIf="
                                                                                      (fusionGeneMutationList != null &&
                                                                                      fusionGeneMutationList.length > 8)
                                                                                    " [ngClass]="'bg_skyblue'">
                <tr style="background: #daedf7">
                  <td style="font-size: 18px !important; padding-left: 10px !important">
                    Gene
                  </td>
                  <td *ngFor="
                                                                                          let geneMutation of fusionGeneMutationList
                                                                                            | slice : 8 : 17
                                                                                        ">
                    <i>{{ geneMutation.gene }}</i>
                  </td>
                </tr>
                <tr>
                  <td style="font-size: 18px !important; padding-left: 10px !important">
                    <b>Status</b>
                  </td>
                  <td *ngFor="
                                                                                          let geneMutation of fusionGeneMutationList
                                                                                            | slice : 8 : 17
                                                                                        ">
                    <span *ngIf="
                                                                                            geneMutation.status == 'Pathogenic' ||
                                                                                            geneMutation.status == 'Likely Pathogenic' ||
                                                                                            geneMutation.status == 'Detected'
                                                                                          "
                      style="color: red; font-weight: 600px"><b>{{
                        geneMutation.status }}</b></span>
                    <span *ngIf="geneMutation.status == 'VUS'" style="color: black; font-weight: 600px"><b>{{
                        geneMutation.status }}</b></span>
                    <span *ngIf="
                                                                                            geneMutation.status == 'Not Detected' ||
                                                                                            geneMutation.status == 'Benige'
                                                                                          ">{{ geneMutation.status }}</span>
                  </td>
                </tr>
              </table>
            </div> -->


            <!--  -->
            <div *ngIf="
                              report.disclaimerNote != ' ' &&
                              report.disclaimerNote != null
                            ">
              <h6 style="color: #208dcb"><b>Note: </b></h6>
              <div style="font-size: 14px !important;text-align: justify" [innerHtml]="report.disclaimerNote"></div>
            </div>



            <!--  -->
            <div class="row footer d-flex justify-content-between align-items-center">
              <div class="col">
                <div class="py-2">
                  <p>
                    <span class="font-weight-bold">Genomics Bihar,</span>
                  </p>
                  <p>Homi Bhabha cancer hospital & Research centre</p>
                  <p>Inside Srikrishna Medical College & Hospital</p>
                  <p>Umanagar, Muzaffarpur-842004.</p>
                  <p>Contact: ngs.mfp@gmail.com</p>
                </div>
              </div>
              <div class="col">
                <p class="text-right">
                  <img
                    src="../../../assets/tmc/tmc_footer.png"
                    alt="footer_image"
                    class="footer_img"
                  />
                </p>
              </div>
            </div>
          </div>



          <!-- page 2 -->
          <div id="page2" *ngIf="
                                                                    reportPdfRes.allVariantsList.length > 0
                                                                    " class="container position-relative page"
            style="background-color: white">
              <div class="d-flex justify-content-between logo_panel py-3">
                <div class="logo_part">
                  <img src="../../../assets/tmc/tmc_log1.png" alt="" class="logo_first" />
                </div>
                <div class="logo_part text-center">
                  <img src="../../../assets/tmc/tmc_logo2.png" alt="" class="logo_two" />
                  <p style="font-size: 10px">
                    An Initiative of Bihar Govt. & Doctors For You
                  </p>
                  <p class="text-uppercase">MEDICAL GENOMICS & MOLECULAR ONCOLOGY</p>
                </div>
                <div class="logo_part">
                  <img src="../../../assets/tmc/tmc_logo3.jpg" alt="" class="logo_three" />
                </div>
              </div>
              <div class="row header mt-1">
              <div></div>
              <div class="col">
                <div class="patient-detailes ml-auto float-right">
                  <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
                    <li>
                      Patient Id <br />
                      <span>{{ report.patient?.organisationPatientId }}</span>
                    </li>
                    <li class="ml-4">
                      Patient Name<br />
                      <span>{{ report.patient?.name }}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <!--  -->
              <div *ngIf="report.clientType == '4basecare_client'">
                <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
                  </div>
                </div>
                <div *ngIf="report.clientName == 'path_labs'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
                  </div>
                </div>
              </div>
            </div>

            <!--  -->

            <div class="row mt-3" *ngIf="
                                                                        otherCountSNV == true ||
                                                                        reportPdfRes.allVariantsList.length != 0
                                                                      ">
              <div class="col">
                <h6 class="font-weight-bold clr-cyan text-uppercase" [ngClass]="'txt-skyblue'">
                  Variant Details:
                </h6>
              </div>
            </div>

            <div class="row" *ngIf="
                                                                        otherCountSNV == true ||
                                                                        reportPdfRes.allVariantsList.length != 0
                                                                      ">
              <div class="col-12">
                <table class="page_1 cust_table w-100 mt-3 varient_details" [ngClass]="'table_variant-blue'">
                  <thead>
                    <tr>
                      <th>Gene</th>
                      <th>Variant Location</th>
                      <th>Variant Consequence</th>
                      <th>Clinical Significance</th>
                      <th>Variant Type</th>
                      <th>Reference</th>
                    </tr>
                  </thead>

                  <tr *ngFor="let variant of reportPdfRes.allVariantsList">
                    <td *ngIf="variant.significance != 'NA' && variant.significance != 'Not Classified'" style="padding-left: 3px !important">
                      <i>{{ variant.gene }}</i>
                    </td>
                    <td *ngIf="variant.significance != 'NA' && variant.significance != 'Not Classified'" style="padding-left: 3px !important">
                      <span *ngIf="variant?.variantType=='snv'">
                        {{ variant?.genomicAlteration }},<br />
                        {{ variant?.ensembleValue }},<br />
                        {{ variant?.exonNumber }}
                      </span>
                      <span *ngIf="variant?.variantType=='cna'">
                        chr{{ variant?.sample?.split(":")[0] }}:g.{{
                          variant.sample
                          .split(":")[1]
                          .replace("-", "_")
                          .split("|")[0]
                          }}
                      </span>
                      <span *ngIf="variant.variantType=='fusion'">
                        {{ variant?.leftBreakPoint }}<br />{{variant?.rightBreakPoint}}
                      </span>
                    </td>
                    <td *ngIf="variant?.significance != 'NA' && variant?.significance != 'Not Classified'" style="padding-left: 3px !important">
                      <span *ngIf="variant.variantType=='snv'">
                        <span *ngIf="variant?.selectedAAChangeknownGene">{{
                          variant?.selectedAAChangeknownGene|splitComma }}</span>, {{variant?.mutantBurdenPercent * 0.01 | percent}}
                        </span>
                      <span *ngIf="variant.variantType=='cna'">NA</span>
                      <span *ngIf="variant.variantType=='fusion'">NA</span>
                    </td>
                    <td *ngIf="variant?.significance != 'NA' && variant?.significance != 'Not Classified'" style="padding-left: 3px !important">
                      {{ variant?.significance }}
                    </td>
                    <td *ngIf="variant?.significance != 'NA' && variant?.significance != 'Not Classified'" style="padding-left: 3px !important">
                      <span *ngIf="variant.variantType=='snv'">
                        {{ variant?.exonicFuncknownGene }}
                      </span>
                      <span *ngIf="variant.variantType=='cna'">
                        Copy Number
                        {{ variant?.sample.split(":")[2] | titlecase }}
                        ({{ variant?.sample.split("|")[1].split(":")[0] }})
                      </span>
                      <span *ngIf="variant.variantType=='fusion'">Gene Fusions</span>
                    </td>
                    <td *ngIf="variant?.significance != 'NA' && variant?.significance != 'Not Classified'" style="padding-left: 3px !important">
                      {{ variant?.reference }}
                    </td>
                  </tr>
                </table>
                <p *ngIf="reportPdfRes.allVariantsList.length > 0 " style="font-size: 13px !important" class="mt-0">
                  <i> *NA: Not Applicable</i>
                </p>
              </div>
            </div>
            <!--  -->
            <div class="row mt-5" *ngIf="
                  !variantFlag &&
                  report.addNotes != null && report.addNotes != '<div></div>'
                ">
          <div class="col">
            <h6 class="py-2 h6_bg">Additional Notes</h6>
            <div class="py-2" [innerHtml]="report.addNotes" style="text-align: justify; font-size: 14px !important"></div>
          </div>
        </div>

            <!--  -->
            <div class="row footer d-flex justify-content-between align-items-center">
              <div class="col">
                <div class="py-2">
                  <p>
                    <span class="font-weight-bold">Genomics Bihar,</span>
                  </p>
                  <p>Homi Bhabha cancer hospital & Research centre</p>
                  <p>Inside Srikrishna Medical College & Hospital</p>
                  <p>Umanagar, Muzaffarpur-842004.</p>
                  <p>Contact: ngs.mfp@gmail.com</p>
                </div>
              </div>
              <div class="col">
                <p class="text-right">
                  <img
                    src="../../../assets/tmc/tmc_footer.png"
                    alt="footer_image"
                    class="footer_img"
                  />
                </p>
              </div>
            </div>
          </div>


         <!-- page 3 -->
      <!-- <div id="page3" *ngIf="reportPdfRes.drugsResList.length > 0" class="container position-relative page"
        style="background-color: white">
        <div class="d-flex justify-content-between logo_panel py-3">
          <div class="logo_part">
            <img src="../../../assets/tmc/tmc_log1.png" alt="" class="logo_first" />
          </div>
          <div class="logo_part text-center">
            <img src="../../../assets/tmc/tmc_logo2.png" alt="" class="logo_two" />
            <p style="font-size: 10px">
              An Initiative of Bihar Govt. & Doctors For You
            </p>
            <p class="text-uppercase">MEDICAL GENOMICS & MOLECULAR ONCOLOGY</p>
          </div>
          <div class="logo_part">
            <img src="../../../assets/tmc/tmc_logo3.jpg" alt="" class="logo_three" />
          </div>
        </div>
        <div class="row header mt-1">
          <div>

          </div>
          <div></div>
          <div class="col">
            <div class="patient-detailes ml-auto float-right">
              <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
                <li>
                  Patient Id <br />
                  <span>{{ report.patient?.organisationPatientId }}</span>
                </li>
                <li class="ml-4">
                  Patient Name<br />
                  <span>{{ report.patient?.name }}</span>
                </li>
              </ul>
            </div>
          </div>
          <div *ngIf="report.clientType == '4basecare_client'">
            <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
              <div class="logo">
                <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
              </div>
            </div>
            <div *ngIf="report.clientName == 'path_labs'" class="col-3">
              <div class="logo">
                <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
              </div>
            </div>
          </div>
        </div>
        <div class="mar_top"></div>

        <h6 class="py-2 h6_bg" [ngClass]="'skyBlue'">
          Alterations With Clinically Relevant Therapies
        </h6>
        <div class="row">
          <div class="col-12">
            <table class="page_1 cust_table w-100 mt-3 first_2 custom-table" [ngClass]="'bg_cyan'">
              <thead>
                <tr>
                  <th>Gene (Alterations) - Drug association</th>
                  <th>Summary</th>
                </tr>
              </thead>

              <tbody>
                <tr *ngFor="
                                                                  let drugsRes of reportPdfRes.drugsResList | slice : 0 : 4;
                                                                  let i = index
                                                                ">
                  <td>
                    <ul class="ml-0 pl-2">
                      <li>
                        <i>{{ drugsRes.gene.replace("Fusion","") }}</i><span *ngIf="drugsRes.gene.includes('Fusion')">Fusion</span> {{ drugsRes.pposition?.replace(' ','') }} :
                        <br />
                        <span *ngFor="let drug of drugsRes.drugs; let i = index">
                          {{ drug.name
                          }}<span *ngIf="drugsRes.drugs.length - 1 != i">,</span>
                        </span>
                        - {{ drugsRes.association }}
                        <p>{{ drugsRes.cancerType }}</p>
                      </li>
                    </ul>
                  </td>
                  <td>
                    <div [innerHtml]="drugsRes.summary"></div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div class="row footer d-flex justify-content-between align-items-center">
          <div class="col">
            <div class="py-2">
              <p>
                <span class="font-weight-bold">Genomics Bihar,</span>
              </p>
              <p>Homi Bhabha cancer hospital & Research centre</p>
              <p>Inside Srikrishna Medical College & Hospital</p>
              <p>Umanagar, Muzaffarpur-842004.</p>
              <p>Contact: ngs.mfp@gmail.com</p>
            </div>
          </div>
          <div class="col">
            <p class="text-right">
              <img src="../../../assets/tmc/tmc_footer.png" alt="footer_image" class="footer_img" />
            </p>
          </div>
        </div>
      </div> -->

      <!-- page 3_1 -->



          <!-- page 4 -->
          <!-- <div id="page4" *ngIf="prognasticCount>0" class="container position-relative page" style="background-color: white">
              <div class="d-flex justify-content-between logo_panel py-3">
                <div class="logo_part">
                  <img src="../../../assets/tmc/tmc_log1.png" alt="" class="logo_first" />
                </div>
                <div class="logo_part text-center">
                  <img src="../../../assets/tmc/tmc_logo2.png" alt="" class="logo_two" />
                  <p style="font-size: 10px">
                    An Initiative of Bihar Govt. & Doctors For You
                  </p>
                  <p class="text-uppercase">MEDICAL GENOMICS & MOLECULAR ONCOLOGY</p>
                </div>
                <div class="logo_part">
                  <img src="../../../assets/tmc/tmc_logo3.jpg" alt="" class="logo_three" />
                </div>
              </div>
              <div class="row header mt-1">
              <div></div>
              <div class="col">
                <div class="patient-detailes ml-auto float-right">
                  <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
                    <li>
                      Patient Id <br />
                      <span>{{ report.patient?.organisationPatientId }}</span>
                    </li>
                    <li class="ml-4">
                      Patient Name<br />
                      <span>{{ report.patient?.name }}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <div *ngIf="report.clientType == '4basecare_client'">
                <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
                  </div>
                </div>
                <div *ngIf="report.clientName == 'path_labs'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
                  </div>
                </div>
              </div>
            </div>
            <div class="mar_top"></div>
            <div *ngIf="prognasticCount>0">
              <div>
                <h6 class="py-2 h6_bg"> Alterations associated with prognostic significance</h6>

              </div>

              <div class="row">
                <div class="col-12">
                  <table class="page_1 cust_table w-100 mt-3 varient_details prognastictable" [ngClass]="'table_variant-blue'">
                    <thead>
                      <tr>
                        <th>Gene (Alterations)</th>
                        <th>Prognostic Significance</th>
                        <th>Summary</th>

                      </tr>
                    </thead>

                    <ng-container *ngFor="
                                    let selectedReportedVariant of reportPdfRes.snvReportedVariantList">
                      <tr *ngIf="selectedReportedVariant.prognasticSignificance!=null">
                        <td style="padding-left: 3px !important">
                          <i>{{ selectedReportedVariant.refGeneX }}</i>
                          <span *ngIf="getpposition(selectedReportedVariant.selectedAAChangeknownGene, selectedReportedVariant.exonicFuncknownGene)!=null">
                            ({{getpposition(selectedReportedVariant.selectedAAChangeknownGene?.replace(' ',''), selectedReportedVariant.exonicFuncknownGene)}})</span>
                        </td>
                        <td style="padding-left: 3px !important">
                          {{selectedReportedVariant.prognasticSignificance}}
                        </td>
                        <td style="padding-left: 3px !important">
                          <span [innerHtml]="selectedReportedVariant.prognasticDescription"></span>
                        </td>

                      </tr>
                    </ng-container>
                    <ng-container  *ngFor="
                  let selectedReportedVariant of reportPdfRes.cnaReportedVariantList
                ">
                    <tr
                    *ngIf="selectedReportedVariant.prognasticSignificance!=null"
                  >
                    <td
                      style="padding-left: 3px !important"
                    >
                    <i>{{ selectedReportedVariant.gene }} </i>
                    <span *ngIf="selectedReportedVariant.sample!=null
                    ">&nbsp;Copy Number
                      {{ selectedReportedVariant.sample.split(":")[2] | titlecase }}
                      ({{ selectedReportedVariant.sample.split("|")[1].split(":")[0] }})
                    </span>

                    </td>
                    <td
                      style="padding-left: 3px !important"
                    >
                    {{selectedReportedVariant.prognasticSignificance}}
                    </td>
                    <td
                      style="padding-left: 3px !important"
                    >
                    <span [innerHtml]="selectedReportedVariant.prognasticDescription"></span>
                    </td>

                  </tr>
                  </ng-container>


                  </table>
                </div>
              </div>
            </div>


            <div class="row footer d-flex justify-content-between align-items-center">
              <div class="col">
                <div class="py-2">
                  <p>
                    <span class="font-weight-bold">Genomics Bihar,</span>
                  </p>
                  <p>Homi Bhabha cancer hospital & Research centre</p>
                  <p>Inside Srikrishna Medical College & Hospital</p>
                  <p>Umanagar, Muzaffarpur-842004.</p>
                  <p>Contact: ngs.mfp@gmail.com</p>
                </div>
              </div>
              <div class="col">
                <p class="text-right">
                  <img
                    src="../../../assets/tmc/tmc_footer.png"
                    alt="footer_image"
                    class="footer_img"
                  />
                </p>
              </div>
            </div>
          </div> -->

          <!-- Page 5 -->
          <div id="page5" class="container position-relative page" style="background-color: white">
              <div class="d-flex justify-content-between logo_panel py-3">
                <div class="logo_part">
                  <img src="../../../assets/tmc/tmc_log1.png" alt="" class="logo_first" />
                </div>
                <div class="logo_part text-center">
                  <img src="../../../assets/tmc/tmc_logo2.png" alt="" class="logo_two" />
                  <p style="font-size: 10px">
                    An Initiative of Bihar Govt. & Doctors For You
                  </p>
                  <p class="text-uppercase">MEDICAL GENOMICS & MOLECULAR ONCOLOGY</p>
                </div>
                <div class="logo_part">
                  <img src="../../../assets/tmc/tmc_logo3.jpg" alt="" class="logo_three" />
                </div>
              </div>
              <div class="row header mt-1">
              <div></div>
              <div class="col">
                <div class="patient-detailes ml-auto float-right">
                  <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
                    <li>
                      Patient Id <br />
                      <span>{{ report.patient?.organisationPatientId }}</span>
                    </li>
                    <li class="ml-4">
                      Patient Name<br />
                      <span>{{ report.patient?.name }}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <!--  -->
              <div *ngIf="report.clientType == '4basecare_client'">
                <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
                  </div>
                </div>
                <div *ngIf="report.clientName == 'path_labs'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
                  </div>
                </div>
              </div>
            </div>
            <!--  -->
            <div class="mar_top"></div>
            <!--  -->

            <h6 class="py-2 h6_bg" [ngClass]="'skyBlue'">References</h6>
            <!--  -->
            <div class="row">
              <div class="col">
                <div class="disc">
                  <div class="ref_data mt-4">
                    <ul class="break-word" style="text-align: justify">
                      <li *ngFor="let referenceArticle of reportPdfRes.referenceArticles" style="
                                                                                  text-align: justify;
                                                                                  /* white-space:pre-wrap; */
                                                                                  word-break: break-word;
                                                                                ">
                        {{ referenceArticle }}
                      </li>
                    </ul>
                  </div>
                </div>

              </div>
            </div>

            <div class="row pr-2 above-footer">
              <div class="col pl-5">
                <img
                  src="../../../assets/tmc/basusigns1.png"
                  alt=""
                  class="mb-2 sign_width"
                />
                <div class="sign_text">
                  <p>Dr. Moitri Basu, Ph.D.</p>
                  <p>Scientific Officer</p>
                  <p>Homi Bhabha cancer hospital & Research centre, <br/>Muzaffarpur, A unit of Tata Memorial centre.</p>
                </div>
              </div>
            </div>
            <!--  -->
            <div class="row footer d-flex justify-content-between align-items-center">
              <div class="col">
                <div class="py-2">
                  <p>
                    <span class="font-weight-bold">Genomics Bihar,</span>
                  </p>
                  <p>Homi Bhabha cancer hospital & Research centre</p>
                  <p>Inside Srikrishna Medical College & Hospital</p>
                  <p>Umanagar, Muzaffarpur-842004.</p>
                  <p>Contact: ngs.mfp@gmail.com</p>
                </div>
              </div>
              <div class="col">
                <p class="text-right">
                  <img
                    src="../../../assets/tmc/tmc_footer.png"
                    alt="footer_image"
                    class="footer_img"
                  />
                </p>
              </div>
            </div>
          </div>

          <!-- Page 6 -->
          <div id="page6" class="container position-relative page" style="background-color: white">
              <div class="d-flex justify-content-between logo_panel py-3">
                <div class="logo_part">
                  <img src="../../../assets/tmc/tmc_log1.png" alt="" class="logo_first" />
                </div>
                <div class="logo_part text-center">
                  <img src="../../../assets/tmc/tmc_logo2.png" alt="" class="logo_two" />
                  <p style="font-size: 10px">
                    An Initiative of Bihar Govt. & Doctors For You
                  </p>
                  <p class="text-uppercase">MEDICAL GENOMICS & MOLECULAR ONCOLOGY</p>
                </div>
                <div class="logo_part">
                  <img src="../../../assets/tmc/tmc_logo3.jpg" alt="" class="logo_three" />
                </div>
              </div>
              <div class="row header mt-1">
              <div></div>
              <div class="col">
                <div class="patient-detailes ml-auto float-right">
                  <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
                    <li>
                      Patient Id <br />
                      <span>{{ report.patient?.organisationPatientId }}</span>
                    </li>
                    <li class="ml-4">
                      Patient Name<br />
                      <span>{{ report.patient?.name }}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <!--  -->
              <div *ngIf="report.clientType == '4basecare_client'">
                <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
                  </div>
                </div>
                <div *ngIf="report.clientName == 'path_labs'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
                  </div>
                </div>
              </div>
            </div>
            <!--  -->
            <div class="mar_top"></div>
            <!--  -->

            <div class="">
              <h6 class="py-2 h6_bg" [ngClass]="'skyBlue'">TEST DESCRIPTION</h6>
              <p class="mb-2" style="text-align:justify">
                <span class="font-weight-normal">TAR</span><b>GT</b>
                <span class="clr-cyan pl-2" [ngClass]="'txt-skyblue'">First Solid</span>
                is a Next Generation Sequencing based test which identifies
                genetic alterations in a comprehensive panel of well curated 72  genes which are having an impact response to approved
                therapy for a particular cancer type. Some of the alterations
                detected may have bearing on prognosis and/or therapeutic options
                and may provide relevant information that allows
                oncologists/clinicians to consider various lines of
                targeted treatment for the patient.
              </p>
            </div>

            <!--  -->
            <div class="row">
              <div class="col">
                <div class="">
                  <h6 class="py-2 h6_bg" [ngClass]="'skyBlue'">GENES EVALUATED</h6>
                  <p>
                    <span class="font-weight-normal">TAR</span><b>GT</b>
                    <span class="clr-cyan pl-2" [ngClass]="'txt-skyblue'">First Solid</span>
                    detects mutations (SNVs and Short InDels), Copy Number Variations (CNVs),
                    Gene Fusions and splice variants in the 72 genes:
                    <!-- detects mutations (SNVs and Short Indels), Copy Number Variations
                                                                          (CNVs), gene fusions and splice variants in the 53 genes : -->
                  </p>
                </div>
                <!--  -->
                <div class="row mx-0">
                  <div class="col-12 px-0">
                    <div class="genes_info">
                      <div class="title py-2">
                        <h6 class="mb-0 d-inline-block border-bottom py-2">
                          <b>SNVs and SHORT INDELS
                          </b> Covered in
                          <span class="font-weight-normal">TAR</span><b>GT</b>
                          <span class="clr-cyan pl-2" [ngClass]="'txt-skyblue'">First Solid</span>
                        </h6>
                      </div>
                      <div class="gene_body" [ngClass]="'gene-bg_blue'">
                        <i>
                          <div class="gene_list">
                            <span>ABL1</span><span>ALK</span><span>APC</span>
                            <span>AR</span><span>ATM</span><span>BARD1</span>
                            <span>BMPR1A</span><span>BRAF</span><span>BRCA1</span>
                            <span>BRCA2</span>
                            <!-- <span>ABL1</span><span>ALK</span><span>AR</span
                                                                                  ><span>ATM</span><span>BARD1</span><span>BRAF</span
                                                                                  ><span>BRCA1</span><span>BRCA2</span><span>BRIP1</span
                                                                                  ><span>CDK12</span> -->
                          </div>
                          <div class="gene_list bg-white">
                            <span>BRIP1</span><span>CDK12</span><span>CDK4</span>
                            <span>CDK6</span><span>CDKN2A</span><span>CHEK1</span>
                            <span>CHEK2</span><span>CTNNB1</span><span>EGFR</span>
                            <span>EPCAM</span>
                            <!-- <span>CDK4</span><span>CDK6</span><span>CDKN2A</span
                                                                                  ><span>CHEK1</span><span>CHEK2</span><span>EGFR</span
                                                                                  ><span>EPCAM</span><span>ERBB2</span><span>ERBB3</span
                                                                                  ><span>EZH2</span> -->
                          </div>
                          <div class="gene_list">
                            <span>ERBB2</span><span>ERBB3</span><span>EZH2</span>
                            <span>FANCL</span><span>FGFR1</span><span>FGFR2</span>
                            <span>FGFR3</span><span>GAPDH</span><span>IDH1</span>
                            <span>IDH2</span>
                            <!-- <span>FANCL</span><span>FGFR3</span><span>GAPDH</span
                                                                                  ><span>IDH1</span><span>IDH2</span><span>JAK2</span
                                                                                  ><span>KIT</span><span>KRAS</span><span>MAP2K1</span
                                                                                  ><span>MAP2K2</span> -->
                          </div>
                          <div class="gene_list">
                            <span>JAK2</span><span>KIT</span><span>KRAS</span>
                            <span>MAP2K1</span><span>MAP2K2</span><span>MDM2</span>
                            <span>MET</span><span>MLH1</span><span>MLH3</span>
                            <span>MSH2</span>
                            <!-- <span>MDM2</span><span>MET</span><span>MLH1</span
                                                                                  ><span>MLH3</span><span>MSH2</span><span>MSH6</span
                                                                                  ><span>NRAS</span><span>PALB2</span><span>PDGFRA</span
                                                                                  ><span>PDGFRB</span> -->
                          </div>
                          <div class="gene_list">
                            <span>MSH6</span><span>MUTYH</span><span>NRAS</span>
                            <span>PALB2</span><span>PDGFRA</span><span>PDGFRB</span>
                            <span>PIK3CA</span><span>PMS1</span><span>PMS2</span>
                            <span>POLD1</span>
                            <!-- <span>PIK3CA</span><span>PMS1</span><span>PMS2</span
                                                                                  ><span>PPP2R2A</span><span>RAD51B</span><span>RAD51C</span
                                                                                  ><span>RAD51D</span><span>RAD54L</span><span>RET</span
                                                                                  ><span>ROS1</span> -->
                          </div>
                          <div class="gene_list">
                            <span>POLE</span><span>POLH</span><span>PTEN</span>
                            <span>RAD50</span><span>RAD51</span><span>RAD51B</span>
                            <span>RAD51C</span><span>RAD51D</span><span>RAD54L</span>
                            <span>RB1</span>
                            <!-- <span>STK11</span><span>TSC1</span><span>TSC2</span> -->
                          </div>
                          <div class="gene_list">
                            <span>RET</span><span>ROS1</span><span>SMAD4</span>
                            <span>STK11</span><span>TP53</span><span>TSC1</span>
                            <span>TSC2</span>
                            <!-- <span>STK11</span><span>TSC1</span><span>TSC2</span> -->
                          </div>
                        </i>
                      </div>

                      <!-- <div class="title py-2 mt-4">
                                                                              <h6 class="mb-0 d-inline-block border-bottom py-2">
                                                                                <b>CNAs</b> Covered in
                                                                                <span class="font-weight-normal">TAR</span><b>GT</b>
                                                                                <span class="clr-cyan pl-2" [ngClass]="'txt-skyblue'"
                                                                                  >First</span
                                                                                >
                                                                              </h6>
                                                                            </div> -->
                      <!-- <div class="gene_body" [ngClass]="'gene-bg_blue'">
                                                                              <i>
                                                                                <div class="gene_list">
                                                                                  <span>ABL1</span><span>AR</span><span>ATM</span
                                                                                  ><span>BARD1</span><span>BRCA1</span><span>BRCA2</span
                                                                                  ><span>BRIP1</span><span>CDK12</span><span>CDK4</span
                                                                                  ><span>CDK6</span>
                                                                                </div>
                                                                                <div class="gene_list">
                                                                                  <span>CDKN2A</span><span>CHEK1</span><span>CHEK2</span
                                                                                  ><span>EGFR</span><span>EPCAM</span><span>ERBB2</span
                                                                                  ><span>ERBB3</span><span>EZH2</span><span>FANCL</span
                                                                                  ><span>FGFR3</span>
                                                                                </div>
                                                                                <div class="gene_list">
                                                                                  <span>GAPDH</span><span>IDH2</span><span>JAK2</span
                                                                                  ><span>KIT</span><span>MDM2</span><span>NRAS</span
                                                                                  ><span>PALB2</span><span>PDGFRA</span><span>PDGFRB</span
                                                                                  ><span>PPP2R2A</span>
                                                                                </div>
                                                                                <div class="gene_list">
                                                                                  <span>RAD51B</span><span>RAD51C</span><span>RAD51D</span
                                                                                  ><span>RAD54L</span><span>STK11</span><span>TSC1</span
                                                                                  ><span>TSC2</span>
                                                                                </div>
                                                                              </i>
                                                                            </div> -->
                    </div>
                  </div>
                  <!-- cnv_genes -->

            <!-- <div class="col-12 px-0">
              <div class="genes_info">
                <div class="title py-2">
                  <h6 class="mb-0 d-inline-block border-bottom py-2">
                    <b> CNVs
                    </b> Covered in
                    <span class="font-weight-normal">TAR</span><b>GT</b>
                    <span class="clr-cyan pl-2" [ngClass]="'txt-skyblue'"
                      >First Solid</span
                    >
                  </h6>
                </div>
                <div class="gene_body" [ngClass]="'gene-bg_blue'">
                  <i>
                    <div class="gene_list">
                      <span>ABL1</span><span>ALK</span><span>APC</span>
                      <span>AR</span><span>ATM</span><span>BARD1</span>
                      <span>BMPR1A</span><span>BRAF</span><span>BRCA1</span>
                      <span>BRCA2</span>
                    </div>
                    <div class="gene_list bg-white">
                      <span>BRIP1</span><span>CDK12</span><span>CDK4</span>
                      <span>CDK6</span><span>CDKN2A</span><span>CHEK1</span>
                      <span>CHEK2</span><span>CTNNB1</span><span>EGFR</span>
                      <span>EPCAM</span>
                    </div>
                    <div class="gene_list">
                      <span>ERBB2</span><span>ERBB3</span><span>EZH2</span>
                      <span>FANCL</span><span>FGFR1</span><span>FGFR2</span>
                      <span>FGFR3</span><span>GAPDH</span><span>IDH2</span>
                      <span>JAK2</span>
                    </div>
                    <div class="gene_list">
                     <span>KIT</span><span>MDM2</span>
                      <span>MET</span><span>MLH1</span><span>MUTYH</span>
                      <span>NRAS</span><span>PALB2</span><span>PDGFRA</span>
                      <span>PDGFRAB</span><span>POLD1</span>
                    </div>

                    <div class="gene_list">
                      <span>POLE</span><span>POLH</span><span>PTEN</span>
                      <span>RAD50</span><span>RAD51</span><span>RAD51B</span>
                      <span>RAD51C</span><span>RAD51D</span><span>RAD54L</span>
                      <span>RB1</span>
                    </div>
                    <div class="gene_list">
                      <span>RET</span><span>ROS1</span><span>SMAD4</span>
                      <span>STK11</span><span>TP53</span><span>TSC1</span>
                      <span>TSC2</span>
                    </div>
                  </i>
                </div>


              </div>
            </div> -->




                  <div class="col-12 px-0">
                    <div class="genes_info">
                      <div class="title py-2">
                        <h6 class="mb-0 d-inline-block border-bottom py-2">
                          <b>Gene Fusions/Splicing Variations</b> Covered in
                          <span class="font-weight-normal">TAR</span><b>GT</b>
                          <span class="clr-cyan pl-2" [ngClass]="'txt-skyblue'">First Solid</span>
                        </h6>
                      </div>
                      <div class="gene_body" [ngClass]="'gene-bg_blue'">
                        <i>
                          <div class="gene_list">
                          <span>ALK</span><span>FGFR2</span><span>FGFR3</span>
                          <span>MET</span><span>NRG1</span><span>NRG2</span>
                          <span>NTRK1</span><span>NTRK2</span> <span>NTRK3</span><span>RET</span>

                          </div>
                          <div class="gene_list">
                            <span>ROS1</span>
                          </div>
                        </i>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>

            <!--  -->
            <div class="row footer d-flex justify-content-between align-items-center">
              <div class="col">
                <div class="py-2">
                  <p>
                    <span class="font-weight-bold">Genomics Bihar,</span>
                  </p>
                  <p>Homi Bhabha cancer hospital & Research centre</p>
                  <p>Inside Srikrishna Medical College & Hospital</p>
                  <p>Umanagar, Muzaffarpur-842004.</p>
                  <p>Contact: ngs.mfp@gmail.com</p>
                </div>
              </div>
              <div class="col">
                <p class="text-right">
                  <img
                    src="../../../assets/tmc/tmc_footer.png"
                    alt="footer_image"
                    class="footer_img"
                  />
                </p>
              </div>
            </div>
          </div>

          <!-- Page 7 -->
          <div id="page7" class="container position-relative page" style="background-color: white">
              <div class="d-flex justify-content-between logo_panel py-3">
                <div class="logo_part">
                  <img src="../../../assets/tmc/tmc_log1.png" alt="" class="logo_first" />
                </div>
                <div class="logo_part text-center">
                  <img src="../../../assets/tmc/tmc_logo2.png" alt="" class="logo_two" />
                  <p style="font-size: 10px">
                    An Initiative of Bihar Govt. & Doctors For You
                  </p>
                  <p class="text-uppercase">MEDICAL GENOMICS & MOLECULAR ONCOLOGY</p>
                </div>
                <div class="logo_part">
                  <img src="../../../assets/tmc/tmc_logo3.jpg" alt="" class="logo_three" />
                </div>
              </div>
              <div class="row header mt-1">
              <div></div>
              <div class="col">
                <div class="patient-detailes ml-auto float-right">
                  <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
                    <li>
                      Patient Id <br />
                      <span>{{ report.patient?.organisationPatientId }}</span>
                    </li>
                    <li class="ml-4">
                      Patient Name<br />
                      <span>{{ report.patient?.name }}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <!--  -->
              <div *ngIf="report.clientType == '4basecare_client'">
                <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
                  </div>
                </div>
                <div *ngIf="report.clientName == 'path_labs'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
                  </div>
                </div>
              </div>
            </div>
            <!--  -->
            <div class="mar_top"></div>
            <!--  -->
            <h6 class="py-2 h6_bg mt-3" [ngClass]="'skyBlue'">TEST METHODOLOGY</h6>
            <!--  -->
            <div class="row mt-3">
              <div class="col">
                <h6 class="font-weight-bold">
                  Sample preparation and Library preparation :
                </h6>
                <!-- <p>
                                                DNA isolated from FFPE, or any other fresh tumor tissue source was
                                                used to perform targeted gene capture using a custom capture kit.
                                                The libraries were sequenced to mean >350X coverage on Illumina
                                                sequencing platform.
                                              </p> -->
                <p style="text-align: justify;">
                  DNA isolated from FFPE, or any other fresh tumor tissue source was used to perform targeted gene capture using
                  a custom capture kit. The libraries were sequenced to mean
                  >350X coverage on Illumina sequencing platform.
                </p>
              </div>
            </div>
            <!--  -->
            <div class="row mt-4">
              <div class="col">
                <h6 class="font-weight-bold">
                  Bioinformatics Analysis and Reporting :
                </h6>
                <!-- <p>
                                                The sequences obtained are aligned to human reference genome
                                                (GRCh37/hg19) and variant analysis was performed using set of
                                                Bioinformatics Pipeline. Only non-synonymous and splice site
                                                variants found in the panel consisting of specific set of genes were
                                                used for clinical interpretation. Silent variations that do not
                                                result in any change in amino acid in the coding region are not
                                                reported. Clinically relevant mutations were annotated using
                                                published variants in literature and a set of databases – ClinVar,
                                                COSMIC and dbSNP. Common variants are filtered based on allele
                                                frequency in 1000 Genome Phase 3, ExAC, dbSNP, gnomAD, etc. In the
                                                absence of a clinically significant reported known variation(s),
                                                pathogenicity will be predicted based on <i>in-silico</i> gene
                                                prioritization tools: CADD, SIFT, PolyPhen-2, Condel and Mutation
                                                taster and prioritized for clinical correlation. The identified
                                                pathogenic variant will be correlated with observed phenotypic
                                                features of the patient and interpreted according to American
                                                College of Medical Genetics (ACMG) guidelines.
                                              </p>
                                              <p class="mb-2 d-block">
                                                Somatic variants are classified into three tiers based on their level
                                                of clinical significance in cancer diagnosis, prognosis, and/or
                                                therapeutics as per international guidelines: ACMG, ASCO, AMP, CAP,
                                                NCCN and ESMO
                                              </p> -->
                <p style="text-align: justify;">
                  The sequences obtained are aligned to human reference genome (GRCh37/hg19) and variant analysis was performed
                  using set of Bioinformatics Pipeline. Only non-synonymous and splice site variants found in the panel
                  consisting of specific set of genes were used for clinical interpretation. Silent variations that do not
                  result in any change
                  in amino acid in the coding region are not reported. Clinically relevant mutations were annotated using
                  published variants in literature and a set of databases – ClinVar, COSMIC
                  and dbSNP. Common variants are filtered based on allele frequency in 1000 Genome Phase 3, ExAC, dbSNP, gnomAD,
                  etc. In the absence of a clinically significant reported
                  known variation(s), pathogenicity will be predicted based on <i>in-silico</i> gene prioritization tools: CADD, SIFT,
                  PolyPhen-2, Condel and Mutation taster and prioritized for clinical
                  correlation. The identified pathogenic variant will be correlated with observed phenotypic features of the patient and interpreted according to ACMG/AMP guidelines.
                </p>
                <p class="mb-2 d-block" style="text-align: justify;">
                  Somatic variants are classified into three tiers based on their level of clinical significance in cancer
                  diagnosis, prognosis, and/or therapeutics as per international guidelines:
                  ACMG, ASCO, AMP, CAP, NCCN and ESMO.
                </p>
              </div>
            </div>

            <h6 class="py-2 h6_bg mt-2" [ngClass]="'skyBlue'">
              LIMITATIONS AND DISCLAIMER
            </h6>

            <div class="row">
              <div class="col">
                <div class="disc">
                  <ul class="ml-0 pl-4" style="text-align: justify;">
                    <li>
                      TARGT FIRST SOLID test has been developed, validated and performed by 4baseCare Oncosolutions Pvt. Ltd and
                      has not been cleared or approved by the FDA.
                    </li>
                    <li>
                      DNA studies do not constitute a definitive test for the selected condition(s) in all individuals. It
                      should be realized that there are possible sources of error. Errors can result
                      from trace contamination, rare technical errors, rare genetic variants that interfere with analysis,
                      recent scientific developments, and alternative classification systems. This
                      test should be one of the many aspects used by the healthcare provider to help with a diagnosis and
                      treatment plan.
                    </li>
                    <li>
                      We are using the canonical transcript for clinical reporting which is usually the longest coding
                      transcript with strong/multiple supporting evidence. However, in rare cases,
                      clinically relevant variants annotated in alternate complete coding transcripts could also be reported.
                    </li>
                    <li>
                      The contents of this test should be carefully assessed by the treating physician and further interpreted
                      along with clinical, histopathological findings, contraindications and
                      guidelines before deciding the course of therapy.
                    </li>
                    <li>
                      The CNVs detected must be confirmed by an alternate method, such as IHC, for further clinical management decisions.
                    </li>
                    <!-- <li>
                      The sensitivity of this assay to detect large deletions/duplications of more than 10 bp or copy number
                      variations (CNV) is 70-75%. The CNVs detected have to be confirmed
                      by alternate method.
                    </li> -->
                    <li>
                      Most recent block is recommended for testing as the mutation profile may change in response to treatment
                      and hence differ at different sampling points.
                    </li>
                    <li>
                      The identified pathogenic variant will be correlated with observed phenotypic features of the patient and interpreted according to AMP guidelines.
                    </li>
                    <li>
                      Certain genes may not be covered completely, and few mutations could be missed. A negative result cannot
                      rule out the possibility that the tested tumor sample carries
                      mutations not previously associated with cancer and hence not included in the panel.
                    </li>
                    <li>
                      Our limit of detection for TARGT FIRST SOLID is 5% for SNVs, 10% for InDels and CNV gain &#8805; 6. In addition
                      to this, sequencing quality and coverage is dependent on many factors
                      such as homopolymers, GC-rich regions, intrinsic quality of FFPE DNA and that might impact the variant
                      detection.
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <!--  -->
            <div class="row footer d-flex justify-content-between align-items-center">
              <div class="col">
                <div class="py-2">
                  <p>
                    <span class="font-weight-bold">Genomics Bihar,</span>
                  </p>
                  <p>Homi Bhabha cancer hospital & Research centre</p>
                  <p>Inside Srikrishna Medical College & Hospital</p>
                  <p>Umanagar, Muzaffarpur-842004.</p>
                  <p>Contact: ngs.mfp@gmail.com</p>
                </div>
              </div>
              <div class="col">
                <p class="text-right">
                  <img
                    src="../../../assets/tmc/tmc_footer.png"
                    alt="footer_image"
                    class="footer_img"
                  />
                </p>
              </div>
            </div>
          </div>
        </div>




                <!-- HCG -->
        <!-- For HCG Template 1-->
        <div class="hcg" *ngIf="!is4basecare && isHCG && temp=='temp1'">

          <!-- Page 1 -->
          <div id="page1" class="container position-relative page" style="background-color: white">
            <!-- Header -->
            <div class="d-flex justify-content-between logo_panel py-3">
              <div class="logo_part">
                <img
                style="width:240px!important" src="../../../assets/hcg-new-logo.jpeg"
                  alt=""
                  class="logo_first"
                />
              </div>
              <div style=" border-left: 2px solid #fab216;padding-top:10px;
              height: 120px;"></div>

              <div class="logo_part text-center" style="font-family:arial Mt!important">

                                <h5 style="font-weight: bolder;margin-bottom:0px!important;">Triesta Sciences</h5>
                <p style="font-size: 15px!important;">
                                  A Unit of Health Care Global Enterprises Ltd <br/>
                                  5th Floor, Tower 1, #8, P.Kalinga Rao Road,<br/>
                                  Sampangiram Nagar, Bengaluru-560027. Tel: 080-40206305
                                </p>
                <h6 style="font-weight: bolder;font-size:15px!important">Department of Molecular and Clinical Genomics</h6>
              </div>
              <div style=" border-left: 2px solid #fab216;padding-top:10px;
              height: 120px;"></div>
              <div class="logo_part">
                <img  style="width:240px!important; margin-top:40px!important"
                  src="../../../assets//oncoquer1.png"
                  alt=""
                  class="logo_three"
                />
              </div>
            </div>
            <!--  -->
            <div class="mar_top"></div>
            <!--  -->
            <div class="row">
              <div class="col-7">
                <div class="">
                  <div class="w-50" style="min-height: 80px">
                    <p class="py-1 font-weight-bold" [ngClass]="'txt-black'">
                      SCOPE OF THE TEST
                    </p>
                    <p>SNVs, InDels, CNAs, Gene Fusions status</p>
                  </div>


                  <!-- <div style=" border-bottom: 3px solid #fab216;padding-top:10px; width: 610px;"></div> -->
                  <!-- <img src="../../../assets/BorderLine.png" class="mr-2 w-100" /> -->
                </div>
              </div>
              <div class="col">
                <div class="">
                  <div class="w-100" style="min-height: 80px">
                    <div class="w-50" style="min-height: 80px">
                      <p class="py-1 font-weight-bold" [ngClass]="'txt-black'">
                        NAME OF THE TEST
                      </p>
                      <p> OncQuer<sup>TM</sup> Assist Panel</p>
                    </div>
                    <!-- <p [ngClass]="'txt-yellow'" class="py-1 font-weight-bold" *ngIf="report.clinicalSummary != null">
                      <span *ngIf="report.clinicalSummary != ' '">CLINICAL INDICATION</span>
                    </p>

                    <p *ngIf="report.clinicalSummary != null">
                      <span style="color: #000 !important" *ngIf="report.clinicalSummary != ' '">{{ report.clinicalSummary
                        }}</span>
                    </p> -->
                  </div>
                  <!-- <div class="w-100" style="min-height: 80px">
                    <p [ngClass]="'txt-yellow'" class="py-1 font-weight-bold" *ngIf="report.clinicalSummary != null">
                    </p>

                    <p *ngIf="report.clinicalSummary != null">

                    </p>
                  </div> -->
                  <!-- <div style=" border-bottom: 3px solid #fab216;padding-top:10px; width: 430px;"></div> -->
                  <!-- <img src="../../../assets/BorderLine.png" class="mr-2 w-100" /> -->
                </div>
              </div>
            </div>
            <div class="text-center" style=" border-bottom: 3px solid #fab216;padding-right: 10px;
            width: 1074px;
            margin-left: 10px;
            margin-right: 10px;"></div>

            <!--  -->
            <div class="row pt-2">
              <div class="col-7">
                <h6 class="text-uppercase py-2 font-weight-bold" [ngClass]="'txt-black'">
                  Report Details
                </h6>
                <ul class="list-unstyled">
                  <li class="pb-1">
                    <span class="clr-cyan" [ngClass]="'txt-black'">Patient Name : </span>
                    <span class="text-black font-weight-bold" >{{
                      report.patient?.name | uppercase
                      }}</span>
                  </li>
                  <li class="pb-1">
                    <span class="clr-cyan" [ngClass]="'txt-black'">Gender : </span>
                    <span  class="text-black font-weight-bold">{{
                      report.patient?.gender | titlecase
                    }}</span>
                  </li>
                  <li class="pb-1">
                    <span class="clr-cyan" [ngClass]="'txt-black'">Age/DOB : </span>
                    <span  class="text-black font-weight-bold">{{
                      report.patient?.dateOfBirth
                        ? (report.patient?.dateOfBirth | date : "dd/MM/yyyy")
                        : report.patient?.age + " Years"
                    }}</span>
                  </li>
                  <li class="pb-1">
                    <span class="clr-cyan" [ngClass]="'txt-black'">MRN No : </span>
                    <span class="text-black font-weight-bold">{{ report?.patient?.orgMRNNo }}</span>
                  </li>

                  <li class="pb-1">
                    <span class="clr-cyan" [ngClass]="'txt-black'">Hospital Name : </span>
                    <span class="text-black font-weight-bold">{{ labHospital }}</span>
                  </li>
                </ul>
              </div>

              <div class="col">
                <ul class="list-unstyled pt-4 mt-3">
                  <li class="pb-1">
                    <span class="clr-cyan" [ngClass]="'txt-black'">Patient Id :
                    </span>
                    <span class="text-black font-weight-bold">{{ report.patient?.organisationPatientId }}</span>
                  </li>
                  <li class="pb-1">
                    <span class="clr-cyan" [ngClass]="'txt-black'">Collected on :
                    </span>
                    <span class="text-black font-weight-bold">{{
                      report.labDetails?.sampleCollected | date : "dd/MM/yyyy"
                    }}</span>
                  </li>
                  <li class="pb-1">
                    <span class="clr-cyan" [ngClass]="'txt-black'">Reported on :
                    </span>
                    <span class="text-black font-weight-bold">{{ date | date : "dd/MM/yyyy" }}</span>
                  </li>
                  <li class="pb-1">
                    <span class="clr-cyan" [ngClass]="'txt-black'">Referred By :
                    </span>
                    <span class="text-black font-weight-bold">{{ referringClinician }}</span>
                  </li>

                </ul>
              </div>
            </div>
            <!--  -->
            <h6 class="py-2 h6_bg" [ngClass]="'yellow'">CLINICAL HISTORY/SAMPLE DETAILS</h6>
            <div class="row mt-2 py-2">
              <div class="col-3">
                <h6 class="mb-0 font-weight-bold">Clinical Indications</h6>
                <span>{{ report?.cancerIdentifier }}</span>
              </div>
              <div class="col-3">
                <h6 class="mb-0 font-weight-bold">Sample Details</h6>
                <span *ngIf="report.labDetails?.sampleSource">{{
                  report.labDetails?.sampleSource
                  }}</span>
                <span *ngIf="!report.labDetails?.sampleSource">FFPE Block</span>
              </div>
              <div class="col-3">
                <h6 class="mb-0 font-weight-bold">Tumor content</h6>
                <span>{{report?.tmhReportInfo?.tumorContent}}</span>
              </div>
              <div class="col-3">
                <h6 class="mb-0 font-weight-bold">Specimen site</h6>
                <span>{{report?.tmhReportInfo?.biopsySite}}</span>
              </div>

            </div>
            <!--  -->
            <div class="row mt-2 py-2">
              <div class="col">
                <h6 class="mb-0 text-uppercase" style="font-weight: bolder;">Results</h6>
              </div>
            </div>
            <h6 class="py-2 h6_bg" [ngClass]="'yellow'">
              Genomic findings from tumor profiling
            </h6>

            <!--  -->
            <div class="row">
              <div style="width: 100%;" *ngIf="isReportDownload">
                <img [src]="firstPageTable" alt="" width="100%" class="img-fluid" />
              </div>
              <div class="col-12" id="firstPageTable" *ngIf="!isReportDownload">
                <table class="page_1 cust_table w-100 mt-3 first_1" [ngClass]="'table_yellow'">
                  <tr>
                    <th style="font-size:15px!important">Genomic Alteration</th>
                    <th style="font-size:15px!important">Relevant Therapies (in Same Cancer Type)</th>
                    <th style="font-size:15px!important">Relevant Therapies (in Different Cancer)</th>
                  </tr>
                  <tr *ngIf="tableCount== 0 && prognasticCount>0 ">
                    <td colspan="3" class="text-center mt-2">
                      Clinically relevant genomic alterations associated with therapeutic significance were not detected.
                    </td>
                  </tr>
                  <tr *ngIf="tableCount== 0 && prognasticCount == 0">
                    <td colspan="3" class="text-center mt-2">
                      Clinically relevant genomic alterations associated with both therapeutic and prognostic significance were
                      not detected.
                    </td>
                  </tr>



                  <ng-container>
                    <tr *ngFor="
                                                                let snvHotspotTrail of reportPdfRes?.allHotspotTrailList  | slice : 0 : 5 ; let i=index
                                                              ">
                      <td>
                        <i>{{ snvHotspotTrail.gene }}</i>
                        &nbsp;<span *ngIf="!snvHotspotTrail.gene.includes('Wild type')">
                        <span *ngIf="snvHotspotTrail?.multiVariant==null">
                          <span *ngIf="snvHotspotTrail.exonNumber != null || snvHotspotTrail.pposition != null && snvHotspotTrail.pposition != ''">

                            <span *ngIf="snvHotspotTrail.exonNumber != null">{{snvHotspotTrail.exonNumber}}</span>&nbsp;
                            <span *ngIf="snvHotspotTrail.pposition != null && snvHotspotTrail.pposition != ''">({{snvHotspotTrail.pposition?.replace(' ','')}})</span></span>
                            <p *ngIf="snvHotspotTrail.mutantBurdenPercent != null && !snvHotspotTrail.gene.includes('Wild type')">
                              Allelic burden: {{ snvHotspotTrail.mutantBurdenPercent }}%
                            </p>
                            <span *ngIf="snvHotspotTrail.copyNumber!=null && snvHotspotTrail.copyNumber.length>0"> {{ snvHotspotTrail.copyNumber }}
                          </span>
                        </span>
                        <span *ngIf="snvHotspotTrail?.multiVariant!=null"><br/>
                          <span  style="font-size: 14px !important" [innerHtml]="snvHotspotTrail?.multiVariant"></span>
                        </span></span>
                      </td>
                      <td>
                        <table class="table first_table w-100">
                          <thead class="border-bottom cust_clr">
                            <tr>
                              <th class="w-50">
                                <span class="font-weight-normal"><i>Therapy</i></span>
                              </th>
                              <th class="w-33">
                                <span class="font-weight-normal"><i>Clinical Relevance</i></span>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngIf="snvHotspotTrail.sameCancerType == null">
                              <td class="w-50">NA</td>
                              <td class="w-50">NA</td>
                            </tr>

                            <ng-container *ngIf="snvHotspotTrail.sameCancerType != null">
                              <tr *ngFor="
                                                                          let item of snvHotspotTrail.sameTypeRelevantData
                                                                        ">
                                <td>{{ item.therapy }}</td>
                                <td>{{ item.association }}</td>
                                <!-- <td>{{item.cancerType}}</td> -->
                              </tr>
                            </ng-container>
                          </tbody>
                        </table>
                      </td>
                      <td>
                        <table class="table first_table w-100">
                          <thead class="border-bottom cust_clr">
                            <tr>
                              <th class="w-33">
                                <span class="font-weight-normal"><i>Therapy</i></span>
                              </th>
                              <th class="w-33">
                                <span class="font-weight-normal"><i>Clinical Relevance</i></span>
                              </th>
                              <th class="w-33">
                                <span class="font-weight-normal"><i>Cancer Type</i></span>
                              </th>
                            </tr>
                          </thead>
                          <tbody>

                            <ng-container *ngIf="snvHotspotTrail.otherCancerType != null">
                              <tr *ngFor="
                                                                          let item of snvHotspotTrail.otherTypeRelevantData
                                                                        ">
                                <td>{{ item.therapy }}</td>
                                <td>{{ item.association }}</td>
                                <td>{{ item.cancerType }}</td>
                              </tr>
                            </ng-container>
                            <tr *ngIf="snvHotspotTrail.otherCancerType == null">
                              <td class="w-33">NA</td>
                              <td class="w-33">NA</td>
                              <td class="w-33">NA</td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>


                  </ng-container>
                </table>
                <p *ngIf="
                                snvHotSpot.length != 0 ||
                                cnaHotSpot.length != 0 ||
                                                    fusionHotSpot.length !=0
                                                  " style="font-size: 13px !important" class="mt-0">
                  <i> *NA: Not Applicable</i>
                </p>
              </div>
            </div>
            <!--  -->
            <div *ngIf="(tableCount>0) &&
                              prognasticCount==0">
              <h6 class="py-2 h6_bg" [ngClass]="'yellow'"> Prognostic Biomarkers</h6>
            </div>
            <div class="row" *ngIf="(tableCount>0 )&&
                              prognasticCount==0">
              <div class="col-12">
                <table class="page_1 cust_table w-100 mt-3 varient_detailsy prognasticbiomarkertable"
                  [ngClass]="'table_variant-blue'">
                  <thead>
                    <tr>
                      <th>Genomic Alteration</th>
                      <th>Prognostic Significance</th>

                    </tr>
                  </thead>
                  <ng-container>
                    <tr>
                      <td colspan="2" style="text-align:center">
                        Clinically relevant genomic alterations associated with prognostic significance were not detected.
                      </td>
                    </tr>
                  </ng-container>
                </table>
              </div>
            </div>
            <div *ngIf="prognasticCount>0">
              <h6 class="py-2 h6_bg" [ngClass]="'yellow'"> Prognostic Biomarkers</h6>
            </div>

            <div class="row" *ngIf="prognasticCount>0">
              <div class="col-12">
                <table class="page_1 cust_table w-100 mt-3 varient_detailsy prognasticbiomarkertable"
                  [ngClass]="'table_variant-blue'">
                  <thead>
                    <tr>
                      <th>Genomic Alteration</th>
                      <th>Prognostic Significance</th>

                    </tr>
                  </thead>
                  <ng-container *ngFor="let prog of reportPdfRes.prognosticBiomarkers">
                    <tr>
                      <td style="padding-left: 3px !important">
                        <i>{{ prog.gene }}&nbsp; </i>
                        <span [innerHtml]="prog.geneDetails"></span>
                      </td>
                      <td style="padding-left: 3px !important;">{{prog.statement}}
                      </td>
                    </tr>
                  </ng-container>

                </table>
              </div>
            </div>
            <!--  -->

            <!-- gene Data -->
            <div *ngIf="geneMutationlist != null && isStatusDisplay">
              <div class="mt-3">
                <h6 class="py-2 h6_bg" [ngClass]="'yellow'">
                  Status of variants in cancer related Biomarkers
                </h6>
              </div>

              <table class="staus_gene bg_skyblue" *ngIf="geneMutationlist != null" [ngClass]="{
                                                  biomarker_table:
                                                    geneMutationlist.length >= 4 &&
                                                    geneMutationlist.length < 8
                                                }">
                <tr style="background: #bfe6fa">
                  <td style="font-size: 18px !important; padding-left: 10px !important">
                    Gene
                  </td>
                  <td *ngFor="
                                                      let geneMutation of geneMutationlist
                                                        | slice : 0 : 8
                                                    ">
                    <i>{{ geneMutation.gene }}</i>
                  </td>
                </tr>
                <tr>
                  <td style="font-size: 18px !important; padding-left: 10px !important">
                    <b>Status</b>
                  </td>
                  <td *ngFor="
                                                      let geneMutation of geneMutationlist
                                                        | slice : 0 : 8
                                                    ">
                    <span *ngIf="
                                                        geneMutation.status == 'Pathogenic' ||
                                                        geneMutation.status == 'Likely Pathogenic' ||
                                                        geneMutation.status == 'Detected'
                                                      " style="color: red; font-weight: 600px"><b>{{ geneMutation.status
                        }}</b></span>
                    <span *ngIf="geneMutation.status == 'VUS'" style="color: black; font-weight: 600px"><b>{{
                        geneMutation.status }}</b></span>
                    <span *ngIf="
                                                        geneMutation.status == 'Not Detected' ||
                                                        geneMutation.status == 'Benige'
                                                      ">{{ geneMutation.status }}</span>
                  </td>
                </tr>
                <br/>
              </table>
              <table class="staus_gene" [ngClass]="'bg_skyblue'" *ngIf="
                                                  geneMutationlist != null &&
                                                  geneMutationlist.length > 8
                                                ">
                <tr style="background: #bfe6fa">
                  <td style="font-size: 18px !important; padding-left: 10px !important">
                    Gene
                  </td>
                  <td *ngFor="
                                                      let geneMutation of geneMutationlist
                                                        | slice : 8 : 17
                                                    ">
                    <i>{{ geneMutation.gene }}</i>
                  </td>
                </tr>
                <tr>
                  <td style="font-size: 18px !important; padding-left: 10px !important">
                    <b>Status</b>
                  </td>
                  <td *ngFor="
                                                      let geneMutation of geneMutationlist
                                                        | slice : 8 : 17
                                                    ">
                    <span *ngIf="
                                                        geneMutation.status == 'Pathogenic' ||
                                                        geneMutation.status == 'Likely Pathogenic' ||
                                                        geneMutation.status == 'Detected'
                                                      " style="color: red; font-weight: 600px"><b>{{ geneMutation.status
                        }}</b></span>
                    <span *ngIf="geneMutation.status == 'VUS'" style="color: black; font-weight: 600px"><b>{{
                        geneMutation.status }}</b></span>
                    <span *ngIf="
                                                        geneMutation.status == 'Not Detected' ||
                                                        geneMutation.status == 'Benige'
                                                      ">{{ geneMutation.status }}</span>
                  </td>
                </tr>
                <br/>
              </table>
              <table
                class="staus_gene"
                [ngClass]="'bg_skyblue'"
                *ngIf="
                  geneMutationlist != null &&
                  geneMutationlist.length > 17
                "
              >
                <tr style="background: #bfe6fa">
                  <td
                    style="font-size: 18px !important; padding-left: 10px !important"
                  >
                    Gene
                  </td>
                  <td
                    *ngFor="
                      let geneMutation of geneMutationlist
                        | slice : 17 : 25
                    "
                  >
                    <i>{{ geneMutation.gene }}</i>
                  </td>
                </tr>
                <tr>
                  <td
                    style="font-size: 18px !important; padding-left: 10px !important"
                  >
                    <b>Status</b>
                  </td>
                  <td
                    *ngFor="
                      let geneMutation of geneMutationlist
                        | slice : 17 : 25
                    "
                  >
                    <span
                      *ngIf="
                        geneMutation.status == 'Pathogenic' ||
                        geneMutation.status == 'Likely Pathogenic' ||
                        geneMutation.status == 'Detected'
                      "
                      style="color: red; font-weight: 600px"
                      ><b>{{ geneMutation.status }}</b></span
                    >
                    <span
                      *ngIf="geneMutation.status == 'VUS'"
                      style="color: black; font-weight: 600px"
                      ><b>{{ geneMutation.status }}</b></span
                    >
                    <span
                      *ngIf="
                        geneMutation.status == 'Not Detected' ||
                        geneMutation.status == 'Benige'
                      "
                      >{{ geneMutation.status }}</span
                    >
                  </td>
                </tr>
                <br/>
              </table>
              <table class="staus_gene" [ngClass]="'bg_skyblue'" *ngIf="
                    geneMutationlist != null &&
                    geneMutationlist.length > 25
                  ">
                <tr style="background: #bfe6fa">
                  <td style="font-size: 18px !important; padding-left: 10px !important">
                    Gene
                  </td>
                  <td *ngFor="
                        let geneMutation of geneMutationlist
                          | slice : 25 : 33
                      ">
                    <i>{{ geneMutation.gene }}</i>
                  </td>
                </tr>
                <tr>
                  <td style="font-size: 18px !important; padding-left: 10px !important">
                    <b>Status</b>
                  </td>
                  <td *ngFor="
                        let geneMutation of geneMutationlist
                          | slice : 25 : 33
                      ">
                    <span *ngIf="
                          geneMutation.status == 'Pathogenic' ||
                          geneMutation.status == 'Likely Pathogenic' ||
                          geneMutation.status == 'Detected'
                        " style="color: red; font-weight: 600px"><b>{{ geneMutation.status }}</b></span>
                    <span *ngIf="geneMutation.status == 'VUS'" style="color: black; font-weight: 600px"><b>{{
                        geneMutation.status }}</b></span>
                    <span *ngIf="
                          geneMutation.status == 'Not Detected' ||
                          geneMutation.status == 'Benige'
                        ">{{ geneMutation.status }}</span>
                  </td>
                </tr>
                <br/>
              </table>
          <table class="staus_gene" [ngClass]="'bg_skyblue'" *ngIf="
                geneMutationlist != null &&
                geneMutationlist.length > 33
              ">
            <tr style="background: #bfe6fa">
              <td style="font-size: 18px !important; padding-left: 10px !important">
                Gene
              </td>
              <td *ngFor="
                    let geneMutation of geneMutationlist
                      | slice : 33 : 41
                  ">
                <i>{{ geneMutation.gene }}</i>
              </td>
            </tr>
            <tr>
              <td style="font-size: 18px !important; padding-left: 10px !important">
                <b>Status</b>
              </td>
              <td *ngFor="
                    let geneMutation of geneMutationlist
                      | slice : 33 : 41
                  ">
                <span *ngIf="
                      geneMutation.status == 'Pathogenic' ||
                      geneMutation.status == 'Likely Pathogenic' ||
                      geneMutation.status == 'Detected'
                    " style="color: red; font-weight: 600px"><b>{{ geneMutation.status }}</b></span>
                <span *ngIf="geneMutation.status == 'VUS'" style="color: black; font-weight: 600px"><b>{{
                    geneMutation.status }}</b></span>
                <span *ngIf="
                      geneMutation.status == 'Not Detected' ||
                      geneMutation.status == 'Benige'
                    ">{{ geneMutation.status }}</span>
              </td>
            </tr>
            <br/>
          </table>
              <h6 *ngIf="
                                                  (fusionGeneMutationList != null &&
                                                  fusionGeneMutationList.length > 0)
                                                " class="font-weight-bold clr-cyan text-uppercase" [ngClass]="'txt-black'">
                Gene Fusion
              </h6>
              <table class="staus_gene" *ngIf="
                                                  (fusionGeneMutationList != null &&
                                                  fusionGeneMutationList.length > 0)
                                                " [ngClass]="'bg_skyblue'">
                <tr style="background: #bfe6fa">
                  <td style="font-size: 18px !important; padding-left: 10px !important">
                    Gene
                  </td>
                  <td *ngFor="
                                                      let geneMutation of fusionGeneMutationList
                                                        | slice : 0 : 8
                                                    ">
                    <i>{{ geneMutation.gene }}</i>
                  </td>
                </tr>
                <tr>
                  <td style="font-size: 18px !important; padding-left: 10px !important">
                    <b>Status</b>
                  </td>
                  <td *ngFor="
                                                      let geneMutation of fusionGeneMutationList
                                                        | slice : 0 : 8
                                                    ">
                    <span *ngIf="
                                                        geneMutation.status == 'Pathogenic' ||
                                                        geneMutation.status == 'Likely Pathogenic' ||
                                                        geneMutation.status == 'Detected'
                                                      " style="color: red; font-weight: 600px"><b>{{ geneMutation.status
                        }}</b></span>
                    <span *ngIf="geneMutation.status == 'VUS'" style="color: black; font-weight: 600px"><b>{{
                        geneMutation.status }}</b></span>
                    <span *ngIf="
                                                        geneMutation.status == 'Not Detected' ||
                                                        geneMutation.status == 'Benige'
                                                      ">{{ geneMutation.status }}</span>
                  </td>
                </tr>
<br/>
              </table>
              <table class="staus_gene" *ngIf="
                                                  (fusionGeneMutationList != null &&
                                                  fusionGeneMutationList.length > 8)
                                                " [ngClass]="'bg_skyblue'">
                <tr style="background: #bfe6fa">
                  <td style="font-size: 18px !important; padding-left: 10px !important">
                    Gene
                  </td>
                  <td *ngFor="
                                                      let geneMutation of fusionGeneMutationList
                                                        | slice : 8 : 17
                                                    ">
                    <i>{{ geneMutation.gene }}</i>
                  </td>
                </tr>
                <tr>
                  <td style="font-size: 18px !important; padding-left: 10px !important">
                    <b>Status</b>
                  </td>
                  <td *ngFor="
                                                      let geneMutation of fusionGeneMutationList
                                                        | slice : 8 : 17
                                                    ">
                    <span *ngIf="
                                                        geneMutation.status == 'Pathogenic' ||
                                                        geneMutation.status == 'Likely Pathogenic' ||
                                                        geneMutation.status == 'Detected'
                                                      " style="color: red; font-weight: 600px"><b>{{ geneMutation.status
                        }}</b></span>
                    <span *ngIf="geneMutation.status == 'VUS'" style="color: black; font-weight: 600px"><b>{{
                        geneMutation.status }}</b></span>
                    <span *ngIf="
                                                        geneMutation.status == 'Not Detected' ||
                                                        geneMutation.status == 'Benige'
                                                      ">{{ geneMutation.status }}</span>
                  </td>
                </tr>
<br/>
              </table>
            </div>
            <!--  -->
            <div *ngIf="
            report.disclaimerNote != ' ' &&
            report.disclaimerNote != null &&
            fusionGeneMutationList.length <= 0
          ">
        <h6 style="color: #000"><b>Note: </b></h6>
        <div style="font-size: 14px !important;text-align: justify" [innerHtml]="report.disclaimerNote"></div>
        <!-- <div class="py-2" [innerHtml]='report.disclaimerNote' style=" text-align: justify;font-size: 14px!important;"></div> -->

        <!-- <p>The data size we had received for this sample was inadequate. However, with the patient's best interest in mind we have further processed the sample. Kindly correlate clinically before making any treatment decisions.</p> -->
        <!-- <p>The data size we had received for this sample was inadequate. However, with the patient's best interest in mind we have further processed the sample and a false negative status of the result cannot be ruled out. Kindly correlate clinically before making any treatment decisions.</p> -->
      </div>

            <!--  -->
            <div class="row footer d-flex justify-content-between align-items-center">
              <!-- <div class="col">
                <div class="py-2">
                  <p>
                    <span class="font-weight-bold">Genomics Bihar,</span>
                  </p>
                  <p>Homi Bhabha cancer hospital & Research centre</p>
                  <p>Inside Srikrishna Medical College & Hospital</p>
                  <p>Umanagar, Muzaffarpur-842004.</p>
                  <p>Contact: ngs.mfp@gmail.com</p>
                </div>
              </div>
              <div class="col">
                <p class="text-right">
                  <img
                    src="../../../assets/tmc/tmc_footer.png"
                    alt="footer_image"
                    class="footer_img"
                  />
                </p>
              </div> -->
            </div>
          </div>

          <!-- page 2 -->
          <div id="page2" *ngIf="
                                                      otherCountSNV == true ||
                                                      reportPdfRes.allVariantsList.length > 0 ||
                                                      (report.disclaimer != '<p></p>' &&
                  report.disclaimer != null) || ( report.disclaimerNote != ' ' &&
            report.disclaimerNote != null &&
            fusionGeneMutationList.length > 0) || (report.addNotes!=null && report.addNotes != '<div></div>')
                                                      " class="container position-relative page" style="background-color: white">
            <!-- Header -->
            <div class="d-flex justify-content-between logo_panel py-3">
              <div class="logo_part">
                <img
                style="width:240px!important" src="../../../assets/hcg-new-logo.jpeg"
                  alt=""
                  class="logo_first"
                />
              </div>
              <div style=" border-left: 2px solid #fab216;padding-top:10px;
              height: 120px;"></div>

              <div class="logo_part text-center" style="font-family:arial Mt!important">

                                <h5 style="font-weight: bolder;margin-bottom:0px!important;">Triesta Sciences</h5>
                <p style="font-size: 15px!important;">
                                  A Unit of Health Care Global Enterprises Ltd <br/>
                                  5th Floor, Tower 1, #8, P.Kalinga Rao Road,<br/>
                                  Sampangiram Nagar, Bengaluru-560027. Tel: 080-40206305
                                </p>
                <h6 style="font-weight: bolder;font-size:15px!important">Department of Molecular and Clinical Genomics</h6>
              </div>
              <div style=" border-left: 2px solid #fab216;padding-top:10px;
              height: 120px;"></div>
              <div class="logo_part">
                <img  style="width:240px!important; margin-top:40px!important"
                  src="../../../assets//oncoquer1.png"
                  alt=""
                  class="logo_three"
                />
              </div>
            </div>
            <div class="row header mt-1">

              <div></div>
              <div class="col">
                <div class="patient-detailes ml-auto float-right">
                  <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
                    <li>
                      Patient Id <br />
                      <span>{{ report.patient?.organisationPatientId }}</span>
                    </li>
                    <li class="ml-4">
                      Patient Name<br />
                      <span>{{ report.patient?.name }}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <!--  -->
              <div *ngIf="report.clientType == '4basecare_client'">
                <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
                  </div>
                </div>
                <div *ngIf="report.clientName == 'path_labs'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
                  </div>
                </div>
              </div>
            </div>

            <div *ngIf="
            report.disclaimerNote != ' ' &&
            report.disclaimerNote != null &&
            fusionGeneMutationList.length > 0
          ">
        <h6 style="color: #000"><b>Note: </b></h6>
        <div style="font-size: 14px !important;text-align: justify" [innerHtml]="report.disclaimerNote"></div>
        <!-- <div class="py-2" [innerHtml]='report.disclaimerNote' style=" text-align: justify;font-size: 14px!important;"></div> -->

        <!-- <p>The data size we had received for this sample was inadequate. However, with the patient's best interest in mind we have further processed the sample. Kindly correlate clinically before making any treatment decisions.</p> -->
        <!-- <p>The data size we had received for this sample was inadequate. However, with the patient's best interest in mind we have further processed the sample and a false negative status of the result cannot be ruled out. Kindly correlate clinically before making any treatment decisions.</p> -->
      </div>
            <!--  -->

            <div class="row mt-3" *ngIf="
                                                          otherCountSNV == true ||
                                                          reportPdfRes.allVariantsList.length != 0
                                                        ">
              <div class="col">
                <h6 class="font-weight-bold clr-cyan text-uppercase" [ngClass]="'txt-black'">
                  Variant Details:
                </h6>
              </div>
            </div>

            <div class="row" *ngIf="
                                                          otherCountSNV == true ||
                                                          reportPdfRes.allVariantsList.length != 0
                                                        ">
              <div class="col-12">
                <table class="page_1 cust_table w-100 mt-3 varient_detailsy" [ngClass]="'table_variant-blue'">
                  <thead>
                    <tr>
                      <th>Gene</th>
                      <th>Variant Location</th>
                      <th>Variant Consequence</th>
                      <th>Clinical Significance</th>
                      <th>Variant Type</th>
                      <th>Reference</th>
                    </tr>
                  </thead>

                  <tr *ngFor="let variant of reportPdfRes.allVariantsList">
                    <td *ngIf="variant.significance != 'NA' && variant.significance != 'Not Classified'" style="padding-left: 3px !important">
                      <i>{{ variant.gene }}</i>
                    </td>
                    <td *ngIf="variant.significance != 'NA' && variant.significance != 'Not Classified'" style="padding-left: 3px !important">
                      <span *ngIf="variant?.variantType=='snv'">
                        {{ variant?.genomicAlteration }},<br />
                        {{ variant?.ensembleValue }},<br />
                        {{ variant?.exonNumber }}
                      </span>
                      <span *ngIf="variant?.variantType=='cna'">
                        chr{{ variant?.sample?.split(":")[0] }}:g.{{
                          variant.sample
                          .split(":")[1]
                          .replace("-", "_")
                          .split("|")[0]
                          }}
                      </span>
                      <span *ngIf="variant.variantType=='fusion'">
                        {{ variant?.leftBreakPoint }}<br />{{variant?.rightBreakPoint}}
                      </span>
                    </td>
                    <td *ngIf="variant?.significance != 'NA' && variant?.significance != 'Not Classified'" style="padding-left: 3px !important">
                      <span *ngIf="variant.variantType=='snv'">
                        <span *ngIf="variant?.selectedAAChangeknownGene">{{
                          variant?.selectedAAChangeknownGene|splitComma }}</span>, {{variant?.mutantBurdenPercent * 0.01 | percent}}
                        </span>
                      <span *ngIf="variant.variantType=='cna'">NA</span>
                      <span *ngIf="variant.variantType=='fusion'">NA</span>
                    </td>
                    <td *ngIf="variant?.significance != 'NA' && variant?.significance != 'Not Classified'" style="padding-left: 3px !important">
                      {{ variant?.significance }}
                    </td>
                    <td *ngIf="variant?.significance != 'NA' && variant?.significance != 'Not Classified'" style="padding-left: 3px !important">
                      <span *ngIf="variant.variantType=='snv'">
                        {{ variant?.exonicFuncknownGene }}
                      </span>
                      <span *ngIf="variant.variantType=='cna'">
                        Copy Number
                        {{ variant?.sample.split(":")[2] | titlecase }}
                        ({{ variant?.sample.split("|")[1].split(":")[0] }})
                      </span>
                      <span *ngIf="variant.variantType=='fusion'">Gene Fusions</span>
                    </td>
                    <td *ngIf="variant?.significance != 'NA' && variant?.significance != 'Not Classified'" style="padding-left: 3px !important">
                      {{ variant?.reference }}
                    </td>
                  </tr>
                </table>
                <p *ngIf="reportPdfRes.allVariantsList.length > 0 " style="font-size: 13px !important" class="mt-0">
                  <i> *NA: Not Applicable</i>
                </p>
              </div>
            </div>



            <div class="row mt-5" *ngIf="
                  !variantFlag &&
                  report.addNotes != null && report.addNotes != '<div></div>'
                ">
          <div class="col">
            <h6 class="py-2 h6_bg" [ngClass]="'yellow'">Additional Notes</h6>
            <div class="py-2" [innerHtml]="report.addNotes" style="text-align: justify; font-size: 14px !important"></div>
          </div>
        </div>

            <!--  -->
            <div class="row footer d-flex justify-content-between align-items-center">
              <!-- <div class="col">
                <div class="py-2">
                  <p>
                    <span class="font-weight-bold">Genomics Bihar,</span>
                  </p>
                  <p>Homi Bhabha cancer hospital & Research centre</p>
                  <p>Inside Srikrishna Medical College & Hospital</p>
                  <p>Umanagar, Muzaffarpur-842004.</p>
                  <p>Contact: ngs.mfp@gmail.com</p>
                </div>
              </div>
              <div class="col">
                <p class="text-right">
                  <img src="../../../assets/tmc/tmc_footer.png" alt="footer_image" class="footer_img" />
                </p>
              </div> -->
            </div>
          </div>

          <!-- page 3 -->
          <div id="page3" *ngIf="reportPdfRes.drugsResList.length > 0" class="container position-relative page"
            style="background-color: white">
            <div class="d-flex justify-content-between logo_panel py-3">
              <div class="logo_part">
                <img style="width:240px!important" src="../../../assets/hcg-new-logo.jpeg" alt="" class="logo_first" />
              </div>
              <div style=" border-left: 2px solid #fab216;padding-top:10px;
                        height: 120px;"></div>

              <div class="logo_part text-center" style="font-family:arial Mt!important">

                                <h5 style="font-weight: bolder;margin-bottom:0px!important;">Triesta Sciences</h5>
                <p style="font-size: 15px!important;">
                  A Unit of Health Care Global Enterprises Ltd <br />
                  5th Floor, Tower 1, #8, P.Kalinga Rao Road,<br />
                  Sampangiram Nagar, Bengaluru-560027. Tel: 080-40206305
                </p>
                <h6 style="font-weight: bolder;font-size:15px!important">Department of Molecular and Clinical Genomics</h6>
              </div>
              <div style=" border-left: 2px solid #fab216;padding-top:10px;
                        height: 120px;"></div>
              <div class="logo_part">
                <img style="width:240px!important; margin-top:40px!important" src="../../../assets//oncoquer1.png" alt=""
                  class="logo_three" />
              </div>
            </div>
            <div class="row header mt-1">
              <div>
                <!-- <div class="col-3">
                            <img src="../../../assets/logo.png" alt="" style="width: 140px" />
                          </div> -->
              </div>
              <div></div>
              <div class="col">
                <div class="patient-detailes ml-auto float-right">
                  <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
                    <li>
                      Patient Id <br />
                      <span>{{ report.patient?.organisationPatientId }}</span>
                    </li>
                    <li class="ml-4">
                      Patient Name<br />
                      <span>{{ report.patient?.name }}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <!--  -->
              <div *ngIf="report.clientType == '4basecare_client'">
                <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
                  </div>
                </div>
                <div *ngIf="report.clientName == 'path_labs'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
                  </div>
                </div>
              </div>
            </div>
            <!--  -->
            <div class="mar_top"></div>
            <!--  -->

            <h6 class="py-2 h6_bg" [ngClass]="'yellow'">
              Alterations With Clinically Relevant Therapies
            </h6>
            <!--  -->
            <div class="row">
              <div class="col-12">
                <table class="page_1 cust_table w-100 mt-3 varient_detailsy prognastictable1">
                  <thead>
                    <tr>
                      <th>Gene (Alterations) - Drug association</th>
                      <th>Summary</th>
                    </tr>
                  </thead>
                  <!-- SNV -->

                  <tbody>
                    <tr *ngFor="
                                                                          let drugsRes of reportPdfRes.drugsResList | slice : 0 : 4;
                                                                          let i = index
                                                                        ">
                      <td>

                            <i>{{ drugsRes.gene.replace("Fusion","") }}</i><span *ngIf="drugsRes.gene.includes('Fusion')">Fusion</span> {{ drugsRes.pposition?.replace(' ','') }} :
                            <br />
                            <span *ngFor="let drug of drugsRes.drugs; let i = index">
                              {{ drug.name
                              }}<span *ngIf="drugsRes.drugs.length - 1 != i">,</span>
                            </span>
                            - {{ drugsRes.association }}
                            <p>{{ drugsRes.cancerType }}</p>

                      </td>
                      <td>
                        <div [innerHtml]="drugsRes.summary"></div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div class="row footer">
              <!-- <div class="col-12">
                      <div class="pb-2 pl-4 text-center">
                        <div class="d-block">
                            <span class="font-weight-bold" style="font-size: 13px !important"
                              >4baseCare (Genomics Tarang ODC),</span
                            >
                          <span>
                            SJP2-S1-1F- C wing, Wipro Limited, SEZ, Sarjapur 2, Sy.No.69 (P),
                          </span>
                        </div>
                        <div class="d-block">
                          <span>Doddakannelli, Sarjapura Road,</span>
                          <span> Bengaluru 560035, KA, India</span></div>


                      </div>
                    </div>
                    <div class="col-12 pt-1 ">
                      <p  style="font-size: 8px!important; line-height: 14px!important;">{{footerDiscription}} </p>
                    </div> -->


            </div>
          </div>

          <!-- page 3_1 -->
          <div id="page3_1" *ngIf="reportPdfRes.drugsResList.length > 4" class="container position-relative page"
            style="background-color: white">
            <div class="d-flex justify-content-between logo_panel py-3">
              <div class="logo_part">
                <img style="width:240px!important" src="../../../assets/hcg-new-logo.jpeg" alt="" class="logo_first" />
              </div>
              <div style=" border-left: 2px solid #fab216;padding-top:10px;
                        height: 120px;"></div>

              <div class="logo_part text-center" style="font-family:arial Mt!important">

                                <h5 style="font-weight: bolder;margin-bottom:0px!important;">Triesta Sciences</h5>
                <p style="font-size: 15px!important;">
                  A Unit of Health Care Global Enterprises Ltd <br />
                  5th Floor, Tower 1, #8, P.Kalinga Rao Road,<br />
                  Sampangiram Nagar, Bengaluru-560027. Tel: 080-40206305
                </p>
                <h6 style="font-weight: bolder;font-size:15px!important">Department of Molecular and Clinical Genomics</h6>
              </div>
              <div style=" border-left: 2px solid #fab216;padding-top:10px;
                        height: 120px;"></div>
              <div class="logo_part">
                <img style="width:240px!important; margin-top:40px!important" src="../../../assets//oncoquer1.png" alt=""
                  class="logo_three" />
              </div>
            </div>
            <div class="row header mt-1">
              <div>
                <!-- <div class="col-3">
                            <img src="../../../assets/logo.png" alt="" style="width: 140px" />
                          </div> -->
              </div>
              <div></div>
              <div class="col">
                <div class="patient-detailes ml-auto float-right">
                  <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
                    <li>
                      Patient Id <br />
                      <span>{{ report.patient?.organisationPatientId }}</span>
                    </li>
                    <li class="ml-4">
                      Patient Name<br />
                      <span>{{ report.patient?.name }}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <!--  -->
              <div *ngIf="report.clientType == '4basecare_client'">
                <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
                  </div>
                </div>
                <div *ngIf="report.clientName == 'path_labs'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
                  </div>
                </div>
              </div>
            </div>
            <!--  -->
            <div class="mar_top"></div>
            <!--  -->

            <h6 class="py-2 h6_bg" [ngClass]="'yellow'">
              Alterations With Clinically Relevant Therapies
            </h6>
            <!--  -->
            <div class="row">
              <div class="col-12">
                <table class="page_1 cust_table w-100 mt-3 varient_detailsy prognastictable1">
                  <thead>
                    <tr>
                      <th>Gene (Alterations) - Drug association</th>
                      <th>Summary</th>
                    </tr>
                  </thead>
                  <!-- SNV -->

                  <tbody>
                    <tr *ngFor="
                                                                                    let drugsRes of reportPdfRes.drugsResList | slice : 4 : 8;
                                                                                    let i = index
                                                                                  ">
                      <td>

                            <i>{{ drugsRes.gene.replace("Fusion","") }}</i><span *ngIf="drugsRes.gene.includes('Fusion')">Fusion</span> {{ drugsRes.pposition?.replace(' ','') }} :
                            <br />
                            <span *ngFor="let drug of drugsRes.drugs; let i = index">
                              {{ drug.name
                              }}<span *ngIf="drugsRes.drugs.length - 1 != i">,</span>
                            </span>
                            - {{ drugsRes.association }}
                            <p>{{ drugsRes.cancerType }}</p>

                      </td>
                      <td>
                        <div [innerHtml]="drugsRes.summary"></div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <!--  -->

            <!--  -->
            <div class="row footer">
              <!-- <div class="col-12">
                      <div class="pb-2 pl-4 text-center">
                        <div class="d-block">
                            <span class="font-weight-bold" style="font-size: 13px !important"
                              >4baseCare (Genomics Tarang ODC),</span
                            >
                          <span>
                            SJP2-S1-1F- C wing, Wipro Limited, SEZ, Sarjapur 2, Sy.No.69 (P),
                          </span>
                        </div>
                        <div class="d-block">
                          <span>Doddakannelli, Sarjapura Road,</span>
                          <span> Bengaluru 560035, KA, India</span></div>


                      </div>
                    </div>
                    <div class="col-12 pt-1 ">
                      <p  style="font-size: 8px!important; line-height: 14px!important;">{{footerDiscription}} </p>
                    </div> -->


            </div>
          </div>

          <!-- page 3_2 -->
          <div id="page3_2" *ngIf="reportPdfRes.drugsResList.length > 8" class="container position-relative page"
            style="background-color: white">
            <div class="d-flex justify-content-between logo_panel py-3">
              <div class="logo_part">
                <img style="width:240px!important" src="../../../assets/hcg-new-logo.jpeg" alt="" class="logo_first" />
              </div>
              <div style=" border-left: 2px solid #fab216;padding-top:10px;
                        height: 120px;"></div>

              <div class="logo_part text-center" style="font-family:arial Mt!important">

                                <h5 style="font-weight: bolder;margin-bottom:0px!important;">Triesta Sciences</h5>
                <p style="font-size: 15px!important;">
                  A Unit of Health Care Global Enterprises Ltd <br />
                  5th Floor, Tower 1, #8, P.Kalinga Rao Road,<br />
                  Sampangiram Nagar, Bengaluru-560027. Tel: 080-40206305
                </p>
                <h6 style="font-weight: bolder;font-size:15px!important">Department of Molecular and Clinical Genomics</h6>
              </div>
              <div style=" border-left: 2px solid #fab216;padding-top:10px;
                        height: 120px;"></div>
              <div class="logo_part">
                <img style="width:240px!important; margin-top:40px!important" src="../../../assets//oncoquer1.png" alt=""
                  class="logo_three" />
              </div>
            </div>
            <div class="row header mt-1">
              <div>
                <!-- <div class="col-3">
                            <img src="../../../assets/logo.png" alt="" style="width: 140px" />
                          </div> -->
              </div>
              <div></div>
              <div class="col">
                <div class="patient-detailes ml-auto float-right">
                  <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
                    <li>
                      Patient Id <br />
                      <span>{{ report.patient?.organisationPatientId }}</span>
                    </li>
                    <li class="ml-4">
                      Patient Name<br />
                      <span>{{ report.patient?.name }}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <!--  -->
              <div *ngIf="report.clientType == '4basecare_client'">
                <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
                  </div>
                </div>
                <div *ngIf="report.clientName == 'path_labs'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
                  </div>
                </div>
              </div>
            </div>
            <!--  -->
            <div class="mar_top"></div>
            <!--  -->

            <h6 class="py-2 h6_bg" [ngClass]="'yellow'">
              Alterations With Clinically Relevant Therapies
            </h6>
            <!--  -->
            <div class="row">
              <div class="col-12">
                <table class="page_1 cust_table w-100 mt-3 varient_detailsy prognastictable1">
                  <thead>
                    <tr>
                      <th>Gene (Alterations) - Drug association</th>
                      <th>Summary</th>
                    </tr>
                  </thead>
                  <!-- SNV -->

                  <tbody>
                    <tr *ngFor="
                                                                                                        let drugsRes of reportPdfRes.drugsResList | slice : 8 : 12;
                                                                                                        let i = index
                                                                                                      ">
                      <td>

                            <i>{{ drugsRes.gene.replace("Fusion","") }}</i><span *ngIf="drugsRes.gene.includes('Fusion')">Fusion</span> {{ drugsRes.pposition?.replace(' ','')}} :
                            <br />
                            <span *ngFor="let drug of drugsRes.drugs; let i = index">
                              {{ drug.name
                              }}<span *ngIf="drugsRes.drugs.length - 1 != i">,</span>
                            </span>
                            - {{ drugsRes.association }}
                            <p>{{ drugsRes.cancerType }}</p>

                      </td>
                      <td>
                        <div [innerHtml]="drugsRes.summary"></div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <!--  -->

            <!--  -->
            <div class="row footer">



            </div>
          </div>

          <!-- page 3_3 -->
          <div id="page3_3" *ngIf="reportPdfRes.drugsResList.length > 12" class="container position-relative page"
            style="background-color: white">
            <div class="d-flex justify-content-between logo_panel py-3">
              <div class="logo_part">
                <img style="width:240px!important" src="../../../assets/hcg-new-logo.jpeg" alt="" class="logo_first" />
              </div>
              <div style=" border-left: 2px solid #fab216;padding-top:10px;
                        height: 120px;"></div>

              <div class="logo_part text-center" style="font-family:arial Mt!important">

                                <h5 style="font-weight: bolder;margin-bottom:0px!important;">Triesta Sciences</h5>
                <p style="font-size: 15px!important;">
                  A Unit of Health Care Global Enterprises Ltd <br />
                  5th Floor, Tower 1, #8, P.Kalinga Rao Road,<br />
                  Sampangiram Nagar, Bengaluru-560027. Tel: 080-40206305
                </p>
                <h6 style="font-weight: bolder;font-size:15px!important">Department of Molecular and Clinical Genomics</h6>
              </div>
              <div style=" border-left: 2px solid #fab216;padding-top:10px;
                        height: 120px;"></div>
              <div class="logo_part">
                <img style="width:240px!important; margin-top:40px!important" src="../../../assets//oncoquer1.png" alt=""
                  class="logo_three" />
              </div>
            </div>
            <div class="row header mt-1">
              <div>
                <!-- <div class="col-3">
                            <img src="../../../assets/logo.png" alt="" style="width: 140px" />
                          </div> -->
              </div>
              <div></div>
              <div class="col">
                <div class="patient-detailes ml-auto float-right">
                  <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
                    <li>
                      Patient Id <br />
                      <span>{{ report.patient?.organisationPatientId }}</span>
                    </li>
                    <li class="ml-4">
                      Patient Name<br />
                      <span>{{ report.patient?.name }}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <!--  -->
              <div *ngIf="report.clientType == '4basecare_client'">
                <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
                  </div>
                </div>
                <div *ngIf="report.clientName == 'path_labs'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
                  </div>
                </div>
              </div>
            </div>
            <!--  -->
            <div class="mar_top"></div>
            <!--  -->

            <h6 class="py-2 h6_bg" [ngClass]="'yellow'">
              Alterations With Clinically Relevant Therapies
            </h6>
            <!--  -->
            <div class="row">
              <div class="col-12">
                <table class="page_1 cust_table w-100 mt-3 varient_detailsy prognastictable1">
                  <thead>
                    <tr>
                      <th>Gene (Alterations) - Drug association</th>
                      <th>Summary</th>
                    </tr>
                  </thead>
                  <!-- SNV -->

                  <tbody>
                    <tr *ngFor="
                                                                                                                              let drugsRes of reportPdfRes.drugsResList | slice : 12 : 16;
                                                                                                                              let i = index
                                                                                                                            ">
                      <td>

                            <i>{{ drugsRes.gene.replace("Fusion","") }}</i><span *ngIf="drugsRes.gene.includes('Fusion')">Fusion</span> {{ drugsRes.pposition?.replace(' ','')}} :
                            <br />
                            <span *ngFor="let drug of drugsRes.drugs; let i = index">
                              {{ drug.name
                              }}<span *ngIf="drugsRes.drugs.length - 1 != i">,</span>
                            </span>
                            - {{ drugsRes.association }}
                            <p>{{ drugsRes.cancerType }}</p>

                      </td>
                      <td>
                        <div [innerHtml]="drugsRes.summary"></div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <!--  -->

            <!--  -->
            <div class="row footer">



            </div>
          </div>

          <!-- page 3_4 -->
          <div id="page3_4" *ngIf="reportPdfRes.drugsResList.length > 16" class="container position-relative page"
            style="background-color: white">
            <div class="d-flex justify-content-between logo_panel py-3">
              <div class="logo_part">
                <img style="width:240px!important" src="../../../assets/hcg-new-logo.jpeg" alt="" class="logo_first" />
              </div>
              <div style=" border-left: 2px solid #fab216;padding-top:10px;
                        height: 120px;"></div>

              <div class="logo_part text-center" style="font-family:arial Mt!important">

                                <h5 style="font-weight: bolder;margin-bottom:0px!important;">Triesta Sciences</h5>
                <p style="font-size: 15px!important;">
                  A Unit of Health Care Global Enterprises Ltd <br />
                  5th Floor, Tower 1, #8, P.Kalinga Rao Road,<br />
                  Sampangiram Nagar, Bengaluru-560027. Tel: 080-40206305
                </p>
                <h6 style="font-weight: bolder;font-size:15px!important">Department of Molecular and Clinical Genomics</h6>
              </div>
              <div style=" border-left: 2px solid #fab216;padding-top:10px;
                        height: 120px;"></div>
              <div class="logo_part">
                <img style="width:240px!important; margin-top:40px!important" src="../../../assets//oncoquer1.png" alt=""
                  class="logo_three" />
              </div>
            </div>
            <div class="row header mt-1">
              <div>
                <!-- <div class="col-3">
                            <img src="../../../assets/logo.png" alt="" style="width: 140px" />
                          </div> -->
              </div>
              <div></div>
              <div class="col">
                <div class="patient-detailes ml-auto float-right">
                  <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
                    <li>
                      Patient Id <br />
                      <span>{{ report.patient?.organisationPatientId }}</span>
                    </li>
                    <li class="ml-4">
                      Patient Name<br />
                      <span>{{ report.patient?.name }}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <!--  -->
              <div *ngIf="report.clientType == '4basecare_client'">
                <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
                  </div>
                </div>
                <div *ngIf="report.clientName == 'path_labs'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
                  </div>
                </div>
              </div>
            </div>
            <!--  -->
            <div class="mar_top"></div>
            <!--  -->

            <h6 class="py-2 h6_bg" [ngClass]="'yellow'">
              Alterations With Clinically Relevant Therapies
            </h6>
            <!--  -->
            <div class="row">
              <div class="col-12">
                <table class="page_1 cust_table w-100 mt-3 varient_detailsy prognastictable1">
                  <thead>
                    <tr>
                      <th>Gene (Alterations) - Drug association</th>
                      <th>Summary</th>
                    </tr>
                  </thead>
                  <!-- SNV -->

                  <tbody>
                    <tr *ngFor="
                                                                                                                    let drugsRes of reportPdfRes.drugsResList | slice : 16 : 20;
                                                                                                                    let i = index
                                                                                                                  ">
                      <td>

                            <i>{{ drugsRes.gene.replace("Fusion","") }}</i><span *ngIf="drugsRes.gene.includes('Fusion')">Fusion</span> {{ drugsRes.pposition?.replace(' ','')}} :
                            <br />
                            <span *ngFor="let drug of drugsRes.drugs; let i = index">
                              {{ drug.name
                              }}<span *ngIf="drugsRes.drugs.length - 1 != i">,</span>
                            </span>
                            - {{ drugsRes.association }}
                            <p>{{ drugsRes.cancerType }}</p>

                      </td>
                      <td>
                        <div [innerHtml]="drugsRes.summary"></div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <!--  -->

            <!--  -->
            <div class="row footer">



            </div>
          </div>

          <!-- page 3_5 -->
          <div id="page3_5" *ngIf="reportPdfRes.drugsResList.length > 20" class="container position-relative page"
            style="background-color: white">
            <div class="d-flex justify-content-between logo_panel py-3">
              <div class="logo_part">
                <img style="width:240px!important" src="../../../assets/hcg-new-logo.jpeg" alt="" class="logo_first" />
              </div>
              <div style=" border-left: 2px solid #fab216;padding-top:10px;
                        height: 120px;"></div>

              <div class="logo_part text-center" style="font-family:arial Mt!important">

                                <h5 style="font-weight: bolder;margin-bottom:0px!important;">Triesta Sciences</h5>
                <p style="font-size: 15px!important;">
                  A Unit of Health Care Global Enterprises Ltd <br />
                  5th Floor, Tower 1, #8, P.Kalinga Rao Road,<br />
                  Sampangiram Nagar, Bengaluru-560027. Tel: 080-40206305
                </p>
                <h6 style="font-weight: bolder;font-size:15px!important">Department of Molecular and Clinical Genomics</h6>
              </div>
              <div style=" border-left: 2px solid #fab216;padding-top:10px;
                        height: 120px;"></div>
              <div class="logo_part">
                <img style="width:240px!important; margin-top:40px!important" src="../../../assets//oncoquer1.png" alt=""
                  class="logo_three" />
              </div>
            </div>
            <div class="row header mt-1">
              <div>
                <!-- <div class="col-3">
                            <img src="../../../assets/logo.png" alt="" style="width: 140px" />
                          </div> -->
              </div>
              <div></div>
              <div class="col">
                <div class="patient-detailes ml-auto float-right">
                  <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
                    <li>
                      Patient Id <br />
                      <span>{{ report.patient?.organisationPatientId }}</span>
                    </li>
                    <li class="ml-4">
                      Patient Name<br />
                      <span>{{ report.patient?.name }}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <!--  -->
              <div *ngIf="report.clientType == '4basecare_client'">
                <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
                  </div>
                </div>
                <div *ngIf="report.clientName == 'path_labs'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
                  </div>
                </div>
              </div>
            </div>
            <!--  -->
            <div class="mar_top"></div>
            <!--  -->

            <h6 class="py-2 h6_bg" [ngClass]="'yellow'">
              Alterations With Clinically Relevant Therapies
            </h6>
            <!--  -->
            <div class="row">
              <div class="col-12">
                <table class="page_1 cust_table w-100 mt-3 varient_detailsy prognastictable1">
                  <thead>
                    <tr>
                      <th>Gene (Alterations) - Drug association</th>
                      <th>Summary</th>
                    </tr>
                  </thead>
                  <!-- SNV -->

                  <tbody>
                    <tr *ngFor="
                                let drugsRes of reportPdfRes.drugsResList | slice : 20 : 24; let i = index ">
                      <td>

                            <i>{{ drugsRes.gene.replace("Fusion","") }}</i><span *ngIf="drugsRes.gene.includes('Fusion')">Fusion</span> {{ drugsRes.pposition?.replace(' ','')}} :
                            <br />
                            <span *ngFor="let drug of drugsRes.drugs; let i = index">
                              {{ drug.name
                              }}<span *ngIf="drugsRes.drugs.length - 1 != i">,</span>
                            </span>
                            - {{ drugsRes.association }}
                            <p>{{ drugsRes.cancerType }}</p>

                      </td>
                      <td>
                        <div [innerHtml]="drugsRes.summary"></div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <!--  -->

            <!--  -->
            <div class="row footer">



            </div>
          </div>

          <!-- page 3_6 -->
          <div id="page3_6" *ngIf="reportPdfRes.drugsResList.length > 24" class="container position-relative page"
            style="background-color: white">
            <div class="d-flex justify-content-between logo_panel py-3">
              <div class="logo_part">
                <img style="width:240px!important" src="../../../assets/hcg-new-logo.jpeg" alt="" class="logo_first" />
              </div>
              <div style=" border-left: 2px solid #fab216;padding-top:10px;
                        height: 120px;"></div>

              <div class="logo_part text-center" style="font-family:arial Mt!important">

                                <h5 style="font-weight: bolder;margin-bottom:0px!important;">Triesta Sciences</h5>
                <p style="font-size: 15px!important;">
                  A Unit of Health Care Global Enterprises Ltd <br />
                  5th Floor, Tower 1, #8, P.Kalinga Rao Road,<br />
                  Sampangiram Nagar, Bengaluru-560027. Tel: 080-40206305
                </p>
                <h6 style="font-weight: bolder;font-size:15px!important">Department of Molecular and Clinical Genomics</h6>
              </div>
              <div style=" border-left: 2px solid #fab216;padding-top:10px;
                        height: 120px;"></div>
              <div class="logo_part">
                <img style="width:240px!important; margin-top:40px!important" src="../../../assets//oncoquer1.png" alt=""
                  class="logo_three" />
              </div>
            </div>
            <div class="row header mt-1">
              <div>
                <!-- <div class="col-3">
                            <img src="../../../assets/logo.png" alt="" style="width: 140px" />
                          </div> -->
              </div>
              <div></div>
              <div class="col">
                <div class="patient-detailes ml-auto float-right">
                  <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
                    <li>
                      Patient Id <br />
                      <span>{{ report.patient?.organisationPatientId }}</span>
                    </li>
                    <li class="ml-4">
                      Patient Name<br />
                      <span>{{ report.patient?.name }}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <!--  -->
              <div *ngIf="report.clientType == '4basecare_client'">
                <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
                  </div>
                </div>
                <div *ngIf="report.clientName == 'path_labs'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
                  </div>
                </div>
              </div>
            </div>
            <!--  -->
            <div class="mar_top"></div>
            <!--  -->

            <h6 class="py-2 h6_bg" [ngClass]="'yellow'">
              Alterations With Clinically Relevant Therapies
            </h6>
            <!--  -->
            <div class="row">
              <div class="col-12">
                <table class="page_1 cust_table w-100 mt-3 varient_detailsy prognastictable1">
                  <thead>
                    <tr>
                      <th>Gene (Alterations) - Drug association</th>
                      <th>Summary</th>
                    </tr>
                  </thead>
                  <!-- SNV -->

                  <tbody>
                    <tr *ngFor=" let drugsRes of reportPdfRes.drugsResList | slice : 24 : 28; let i = index ">
                      <td>

                            <i>{{ drugsRes.gene.replace("Fusion","") }}</i><span *ngIf="drugsRes.gene.includes('Fusion')">Fusion</span> {{ drugsRes.pposition?.replace(' ','')}} :
                            <br />
                            <span *ngFor="let drug of drugsRes.drugs; let i = index">
                              {{ drug.name
                              }}<span *ngIf="drugsRes.drugs.length - 1 != i">,</span>
                            </span>
                            - {{ drugsRes.association }}
                            <p>{{ drugsRes.cancerType }}</p>

                      </td>
                      <td>
                        <div [innerHtml]="drugsRes.summary"></div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <!--  -->

            <!--  -->
            <div class="row footer">



            </div>
          </div>

          <!-- page 3_7 -->
          <div id="page3_7" *ngIf="reportPdfRes.drugsResList.length > 28" class="container position-relative page"
            style="background-color: white">
            <div class="d-flex justify-content-between logo_panel py-3">
              <div class="logo_part">
                <img style="width:240px!important" src="../../../assets/hcg-new-logo.jpeg" alt="" class="logo_first" />
              </div>
              <div style=" border-left: 2px solid #fab216;padding-top:10px;
                        height: 120px;"></div>

              <div class="logo_part text-center" style="font-family:arial Mt!important">

                                <h5 style="font-weight: bolder;margin-bottom:0px!important;">Triesta Sciences</h5>
                <p style="font-size: 15px!important;">
                  A Unit of Health Care Global Enterprises Ltd <br />
                  5th Floor, Tower 1, #8, P.Kalinga Rao Road,<br />
                  Sampangiram Nagar, Bengaluru-560027. Tel: 080-40206305
                </p>
                <h6 style="font-weight: bolder;font-size:15px!important">Department of Molecular and Clinical Genomics</h6>
              </div>
              <div style=" border-left: 2px solid #fab216;padding-top:10px;
                        height: 120px;"></div>
              <div class="logo_part">
                <img style="width:240px!important; margin-top:40px!important" src="../../../assets//oncoquer1.png" alt=""
                  class="logo_three" />
              </div>
            </div>
            <div class="row header mt-1">
              <div>
                <!-- <div class="col-3">
                            <img src="../../../assets/logo.png" alt="" style="width: 140px" />
                          </div> -->
              </div>
              <div></div>
              <div class="col">
                <div class="patient-detailes ml-auto float-right">
                  <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
                    <li>
                      Patient Id <br />
                      <span>{{ report.patient?.organisationPatientId }}</span>
                    </li>
                    <li class="ml-4">
                      Patient Name<br />
                      <span>{{ report.patient?.name }}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <!--  -->
              <div *ngIf="report.clientType == '4basecare_client'">
                <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
                  </div>
                </div>
                <div *ngIf="report.clientName == 'path_labs'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
                  </div>
                </div>
              </div>
            </div>
            <!--  -->
            <div class="mar_top"></div>
            <!--  -->

            <h6 class="py-2 h6_bg" [ngClass]="'yellow'">
              Alterations With Clinically Relevant Therapies
            </h6>
            <!--  -->
            <div class="row">
              <div class="col-12">
                <table class="page_1 cust_table w-100 mt-3 varient_detailsy prognastictable1">
                  <thead>
                    <tr>
                      <th>Gene (Alterations) - Drug association</th>
                      <th>Summary</th>
                    </tr>
                  </thead>
                  <!-- SNV -->

                  <tbody>
                    <tr
                      *ngFor="
                                                                                                                                                                                    let drugsRes of reportPdfRes.drugsResList | slice : 28 : 32;
                                                                                                                                                                                    let i = index
                                                                                                                                                                                  ">
                      <td>

                            <i>{{ drugsRes.gene.replace("Fusion","") }}</i><span *ngIf="drugsRes.gene.includes('Fusion')">Fusion</span> {{ drugsRes.pposition?.replace(' ','')}} :
                            <br />
                            <span *ngFor="let drug of drugsRes.drugs; let i = index">
                              {{ drug.name
                              }}<span *ngIf="drugsRes.drugs.length - 1 != i">,</span>
                            </span>
                            - {{ drugsRes.association }}
                            <p>{{ drugsRes.cancerType }}</p>

                      </td>
                      <td>
                        <div [innerHtml]="drugsRes.summary"></div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <!--  -->

            <!--  -->
            <div class="row footer">



            </div>
          </div>

          <!-- page 3_8 -->
          <div id="page3_8" *ngIf="reportPdfRes.drugsResList.length > 32" class="container position-relative page"
            style="background-color: white">
            <div class="d-flex justify-content-between logo_panel py-3">
              <div class="logo_part">
                <img style="width:240px!important" src="../../../assets/hcg-new-logo.jpeg" alt="" class="logo_first" />
              </div>
              <div style=" border-left: 2px solid #fab216;padding-top:10px;
                        height: 120px;"></div>

              <div class="logo_part text-center" style="font-family:arial Mt!important">

                                <h5 style="font-weight: bolder;margin-bottom:0px!important;">Triesta Sciences</h5>
                <p style="font-size: 15px!important;">
                  A Unit of Health Care Global Enterprises Ltd <br />
                  5th Floor, Tower 1, #8, P.Kalinga Rao Road,<br />
                  Sampangiram Nagar, Bengaluru-560027. Tel: 080-40206305
                </p>
                <h6 style="font-weight: bolder;font-size:15px!important">Department of Molecular and Clinical Genomics</h6>
              </div>
              <div style=" border-left: 2px solid #fab216;padding-top:10px;
                        height: 120px;"></div>
              <div class="logo_part">
                <img style="width:240px!important; margin-top:40px!important" src="../../../assets//oncoquer1.png" alt=""
                  class="logo_three" />
              </div>
            </div>
            <div class="row header mt-1">
              <div>
                <!-- <div class="col-3">
                            <img src="../../../assets/logo.png" alt="" style="width: 140px" />
                          </div> -->
              </div>
              <div></div>
              <div class="col">
                <div class="patient-detailes ml-auto float-right">
                  <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
                    <li>
                      Patient Id <br />
                      <span>{{ report.patient?.organisationPatientId }}</span>
                    </li>
                    <li class="ml-4">
                      Patient Name<br />
                      <span>{{ report.patient?.name }}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <!--  -->
              <div *ngIf="report.clientType == '4basecare_client'">
                <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
                  </div>
                </div>
                <div *ngIf="report.clientName == 'path_labs'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
                  </div>
                </div>
              </div>
            </div>
            <!--  -->
            <div class="mar_top"></div>
            <!--  -->

            <h6 class="py-2 h6_bg" [ngClass]="'yellow'">
              Alterations With Clinically Relevant Therapies
            </h6>
            <!--  -->
            <div class="row">
              <div class="col-12">
                <table class="page_1 cust_table w-100 mt-3 varient_detailsy prognastictable1">
                  <thead>
                    <tr>
                      <th>Gene (Alterations) - Drug association</th>
                      <th>Summary</th>
                    </tr>
                  </thead>
                  <!-- SNV -->

                  <tbody>
                    <tr *ngFor=" let drugsRes of reportPdfRes.drugsResList | slice : 32 : 36; let i = index ">
                      <td>

                            <i>{{ drugsRes.gene.replace("Fusion","") }}</i><span *ngIf="drugsRes.gene.includes('Fusion')">Fusion</span> {{ drugsRes.pposition?.replace(' ','')}} :
                            <br />
                            <span *ngFor="let drug of drugsRes.drugs; let i = index">
                              {{ drug.name
                              }}<span *ngIf="drugsRes.drugs.length - 1 != i">,</span>
                            </span>
                            - {{ drugsRes.association }}
                            <p>{{ drugsRes.cancerType }}</p>

                      </td>
                      <td>
                        <div [innerHtml]="drugsRes.summary"></div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <!--  -->

            <!--  -->
            <div class="row footer">



            </div>
          </div>

          <!-- page 3_9 -->
          <div id="page3_9" *ngIf="reportPdfRes.drugsResList.length > 36" class="container position-relative page"
            style="background-color: white">
            <div class="d-flex justify-content-between logo_panel py-3">
              <div class="logo_part">
                <img style="width:240px!important" src="../../../assets/hcg-new-logo.jpeg" alt="" class="logo_first" />
              </div>
              <div style=" border-left: 2px solid #fab216;padding-top:10px;
                                  height: 120px;"></div>

              <div class="logo_part text-center" style="font-family:arial Mt!important">

                                <h5 style="font-weight: bolder;margin-bottom:0px!important;">Triesta Sciences</h5>
                <p style="font-size: 15px!important;">
                  A Unit of Health Care Global Enterprises Ltd <br />
                  5th Floor, Tower 1, #8, P.Kalinga Rao Road,<br />
                  Sampangiram Nagar, Bengaluru-560027. Tel: 080-40206305
                </p>
                <h6 style="font-weight: bolder;font-size:15px!important">Department of Molecular and Clinical Genomics</h6>
              </div>
              <div style=" border-left: 2px solid #fab216;padding-top:10px;
                                  height: 120px;"></div>
              <div class="logo_part">
                <img style="width:240px!important; margin-top:40px!important" src="../../../assets//oncoquer1.png" alt=""
                  class="logo_three" />
              </div>
            </div>
            <div class="row header mt-1">
              <div>
                <!-- <div class="col-3">
                                      <img src="../../../assets/logo.png" alt="" style="width: 140px" />
                                    </div> -->
              </div>
              <div></div>
              <div class="col">
                <div class="patient-detailes ml-auto float-right">
                  <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
                    <li>
                      Patient Id <br />
                      <span>{{ report.patient?.organisationPatientId }}</span>
                    </li>
                    <li class="ml-4">
                      Patient Name<br />
                      <span>{{ report.patient?.name }}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <!--  -->
              <div *ngIf="report.clientType == '4basecare_client'">
                <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
                  </div>
                </div>
                <div *ngIf="report.clientName == 'path_labs'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
                  </div>
                </div>
              </div>
            </div>
            <!--  -->
            <div class="mar_top"></div>
            <!--  -->

            <h6 class="py-2 h6_bg" [ngClass]="'yellow'">
              Alterations With Clinically Relevant Therapies
            </h6>
            <!--  -->
            <div class="row">
              <div class="col-12">
                <table class="page_1 cust_table w-100 mt-3 varient_detailsy prognastictable1">
                  <thead>
                    <tr>
                      <th>Gene (Alterations) - Drug association</th>
                      <th>Summary</th>
                    </tr>
                  </thead>
                  <!-- SNV -->

                  <tbody>
                    <tr *ngFor=" let drugsRes of reportPdfRes.drugsResList | slice : 36 : 40; let i = index ">
                      <td>

                            <i>{{ drugsRes.gene.replace("Fusion","") }}</i><span *ngIf="drugsRes.gene.includes('Fusion')">Fusion</span> {{ drugsRes.pposition?.replace(' ','')}} :
                            <br />
                            <span *ngFor="let drug of drugsRes.drugs; let i = index">
                              {{ drug.name
                              }}<span *ngIf="drugsRes.drugs.length - 1 != i">,</span>
                            </span>
                            - {{ drugsRes.association }}
                            <p>{{ drugsRes.cancerType }}</p>

                      </td>
                      <td>
                        <div [innerHtml]="drugsRes.summary"></div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <!--  -->

            <!--  -->
            <div class="row footer">



            </div>
          </div>


          <!-- page 4 -->
          <div id="page4" *ngIf="prognasticCount>0" class="container position-relative page" style="background-color: white">
              <!-- Header -->
            <div class="d-flex justify-content-between logo_panel py-3">
              <div class="logo_part">
                <img
                style="width:240px!important" src="../../../assets/hcg-new-logo.jpeg"
                  alt=""
                  class="logo_first"
                />
              </div>
              <div style=" border-left: 2px solid #fab216;padding-top:10px;
              height: 120px;"></div>

              <div class="logo_part text-center" style="font-family:arial Mt!important">

                                <h5 style="font-weight: bolder;margin-bottom:0px!important;">Triesta Sciences</h5>
                <p style="font-size: 15px!important;">
                                  A Unit of Health Care Global Enterprises Ltd <br/>
                                  5th Floor, Tower 1, #8, P.Kalinga Rao Road,<br/>
                                  Sampangiram Nagar, Bengaluru-560027. Tel: 080-40206305
                                </p>
                <h6 style="font-weight: bolder;font-size:15px!important">Department of Molecular and Clinical Genomics</h6>
              </div>
              <div style=" border-left: 2px solid #fab216;padding-top:10px;
              height: 120px;"></div>
              <div class="logo_part">
                <img  style="width:240px!important; margin-top:40px!important"
                  src="../../../assets//oncoquer1.png"
                  alt=""
                  class="logo_three"
                />
              </div>
            </div>
              <div class="row header mt-1">

              <div></div>
              <div class="col">
                <div class="patient-detailes ml-auto float-right">
                  <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
                    <li>
                      Patient Id <br />
                      <span>{{ report.patient?.organisationPatientId }}</span>
                    </li>
                    <li class="ml-4">
                      Patient Name<br />
                      <span>{{ report.patient?.name }}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <!--  -->
              <div *ngIf="report.clientType == '4basecare_client'">
                <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
                  </div>
                </div>
                <div *ngIf="report.clientName == 'path_labs'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
                  </div>
                </div>
              </div>
            </div>
            <!--  -->
            <div class="mar_top"></div>
            <!--  -->
            <div *ngIf="prognasticCount>0">
              <div>
                <h6 class="py-2 h6_bg" [ngClass]="'yellow'"> Alterations associated with prognostic significance</h6>

              </div>

              <div class="row">
                <div class="col-12">
                  <table class="page_1 cust_table w-100 mt-3 varient_detailsy prognastictable" [ngClass]="'table_variant-blue'">
                    <thead>
                      <tr>
                        <th>Gene (Alterations)</th>
                        <th>Prognostic Significance</th>
                        <th>Summary</th>

                      </tr>
                    </thead>

                    <ng-container *ngFor=" let prog of reportPdfRes.prognosticAlterations ">
                      <tr>
                        <td style="padding-left: 3px !important">
                          <i>{{ prog.gene }}&nbsp;</i> <span [innerHtml]="prog.pOrcDot"></span>
                        </td>
                        <td style="padding-left: 3px !important">
                          {{prog.prognosticSignificance}}
                        </td>
                        <td style="padding-left: 3px !important">
                          <span [innerHtml]="prog.description"></span>
                        </td>

                      </tr>
                    </ng-container>



                  </table>
                </div>
              </div>
            </div>


            <!--  -->
            <div class="row footer d-flex justify-content-between align-items-center">
              <!-- <div class="col">
                <div class="py-2">
                  <p>
                    <span class="font-weight-bold">Genomics Bihar,</span>
                  </p>
                  <p>Homi Bhabha cancer hospital & Research centre</p>
                  <p>Inside Srikrishna Medical College & Hospital</p>
                  <p>Umanagar, Muzaffarpur-842004.</p>
                  <p>Contact: ngs.mfp@gmail.com</p>
                </div>
              </div>
              <div class="col">
                <p class="text-right">
                  <img
                    src="../../../assets/tmc/tmc_footer.png"
                    alt="footer_image"
                    class="footer_img"
                  />
                </p>
              </div> -->
            </div>
          </div>

          <!-- Page 5 -->
          <div id="page5" class="container position-relative page" style="background-color: white">
              <!-- Header -->
            <div class="d-flex justify-content-between logo_panel py-3">
              <div class="logo_part">
                <img
                style="width:240px!important" src="../../../assets/hcg-new-logo.jpeg"
                  alt=""
                  class="logo_first"
                />
              </div>
              <div style=" border-left: 2px solid #fab216;padding-top:10px;
              height: 120px;"></div>

              <div class="logo_part text-center" style="font-family:arial Mt!important">

                                <h5 style="font-weight: bolder;margin-bottom:0px!important;">Triesta Sciences</h5>
                <p style="font-size: 15px!important;">
                                  A Unit of Health Care Global Enterprises Ltd <br/>
                                  5th Floor, Tower 1, #8, P.Kalinga Rao Road,<br/>
                                  Sampangiram Nagar, Bengaluru-560027. Tel: 080-40206305
                                </p>
                <h6 style="font-weight: bolder;font-size:15px!important">Department of Molecular and Clinical Genomics</h6>
              </div>
              <div style=" border-left: 2px solid #fab216;padding-top:10px;
              height: 120px;"></div>
              <div class="logo_part">
                <img  style="width:240px!important; margin-top:40px!important"
                  src="../../../assets//oncoquer1.png"
                  alt=""
                  class="logo_three"
                />
              </div>
            </div>
              <div class="row header mt-1">
              <div></div>
              <div class="col">
                <div class="patient-detailes ml-auto float-right">
                  <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
                    <li>
                      Patient Id <br />
                      <span>{{ report.patient?.organisationPatientId }}</span>
                    </li>
                    <li class="ml-4">
                      Patient Name<br />
                      <span>{{ report.patient?.name }}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <!--  -->
              <div *ngIf="report.clientType == '4basecare_client'">
                <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
                  </div>
                </div>
                <div *ngIf="report.clientName == 'path_labs'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
                  </div>
                </div>
              </div>
            </div>
            <!--  -->
            <div class="mar_top"></div>
            <!--  -->

            <h6 class="py-2 h6_bg" [ngClass]="'yellow'">References</h6>
            <!--  -->
            <div class="row">
              <div class="col">
                <div class="disc">
                  <div class="ref_data mt-4">
                    <ul class="break-word" style="text-align: justify">
                      <li *ngFor="let referenceArticle of reportPdfRes.referenceArticles" style="
                                                          text-align: justify;
                                                          /* white-space:pre-wrap; */
                                                          word-break: break-word;
                                                        ">
                        {{ referenceArticle }}
                      </li>
                    </ul>
                  </div>
                </div>

                <!-- <div class="img_signs">
                                                <img src="../../../assets/signatures.png" alt="" class="img-fluid">
                                              </div> -->
                <!--  -->

                <!--  -->
                <!-- <div class="mt-4">
                                                <h6 class="mt-3 mb-4 py-2 h6_bg" >
                                                  GENES EVALUATED </h6>
                                                 <p><span class="font-weight-normal">taR</span><b>GT</b> <span class="clr-cyan pl-2">First</span> detects mutations (SNVs and Short Indels), Copy Number Variations (CNVs), gene fusions and splice variants in the 53 genes :</p>
                                              </div> -->

                <!-- <div class="row mx-0">
                                                <div class="col-7 px-0">
                                                  <div class="genes_info">
                                                    <div class="title py-2">
                                                      <h6 class="mb-0 d-inline-block border-bottom  py-2"><b>SNVs/InDels</b> Coverage in <span class="font-weight-normal">TAR</span><b>GT</b> <span class="clr-cyan pl-2">First</span></h6></div>
                                                 <div class="gene_body">
                                                  <div class="genes_list"><span>ABL1</span><span>ALK</span><span>AR</span><span>ATM</span><span>BARD1</span><span>BRAF</span><span>BRCA1</span><span>BRCA2</span><span>BRIP1</span><span>CDK12</span></div>
                                                  <div class="genes_list"><span>CDK4</span><span>CDK6</span><span>CDKN2A</span><span>CHEK1</span><span>CHEK2</span><span>EGFR</span><span>EPCAM</span><span>ERBB2</span><span>ERBB3</span><span>EZH2</span></div>
                                                  <div class="genes_list"><span>FANCL</span><span>FGFR3</span><span>GAPDH</span><span>IDH1</span><span>IDH2</span><span>JAK2</span><span>KIT</span><span>KRAS</span><span>MAP2K1</span><span>MAP2K2</span></div>
                                                  <div class="genes_list"><span>MDM2</span><span>MET</span><span>MLH1</span><span>MLH3</span><span>MSH2</span><span>MSH6</span><span>NRAS</span><span>PALB2</span><span>PDGFRA</span><span>PDGFRB</span></div>
                                                  <div class="genes_list"><span>PIK3CA</span><span>PMS1</span><span>PMS2</span><span>PPP2R2A</span><span>RAD51B</span><span>RAD51C</span><span>RAD51D</span><span>RAD54L</span><span>RET</span><span>ROS1</span></div>
                                                  <div class="genes_list"><span>MDM2</span><span>MET</span><span>MLH1</span></div>
                                                 </div>
                                                    </div>
                                                </div>
                                                <div class="col">
                                                  <div class="title py-2">
                                                    <h6 class=" mb-0 d-inline-block border-bottom  py-2"><b>Gene Fusions </b> Coverage in <span class="font-weight-normal">TAR</span><b>GT</b> <span class="clr-cyan pl-2">First</span></h6></div>
                                                </div>


                                              </div> -->
              </div>
            </div>

            <div class="row pr-2 above-footer">
              <div class="col-4">
                <!-- <img
                  src="../../../assets/HCG/vijay.png"
                  alt=""
                  style="padding-top:20px;margin-left:20px;padding-left: 60px; padding-right: 60px; width: 90%"
                  class="mb-2"
                /> -->
                <span style="padding-left: 126px; font-weight: bolder"
                  >Dr. Vijay.K, PhD<br /><span
                    style="padding-left: 63px; font-weight: bolder"
                    >Senior Scientist I-Clinical Diagnostics</span
                  ></span
                >
              </div>
              <div class="col-4">
                <!-- <img
                  src="../../../assets/HCG/gautam.png"
                  alt=""
                  style="magin-left:20px;padding-left: 60px; padding-right: 60px; width: 90%"
                  class="mb-2"
                /> -->
                <span style="padding-left: 67px; font-weight: bolder"
                  >Dr. Gautam Balaram, MBBS, MD<br /><span
                    style="padding-left: 100px; font-weight: bolder"
                    >Clinical Pathologist</span
                  ></span
                >
              </div>
              <div class="col-4">
                <!-- <img
                  src="../../../assets/HCG/mithua.png"
                  alt=""
                  style="padding-top:30px;padding-left: 60px; padding-right: 60px; width: 100%"
                  class="mb-2"
                /> -->
                <span style="padding-left: 97px; font-weight: bolder"
                  >Dr. Mithua Ghosh, PhD, PD<br /><span
                    style="padding-left: 90px; font-weight: bolder"
                    >Director-Clinical Diagnostics</span
                  ></span
                >
              </div>

            </div>
            <!--  -->
            <div class="row footer d-flex justify-content-between align-items-center">
              <!-- <div class="col">
                <div class="py-2">
                  <p>
                    <span class="font-weight-bold">Genomics Bihar,</span>
                  </p>
                  <p>Homi Bhabha cancer hospital & Research centre</p>
                  <p>Inside Srikrishna Medical College & Hospital</p>
                  <p>Umanagar, Muzaffarpur-842004.</p>
                  <p>Contact: ngs.mfp@gmail.com</p>
                </div>
              </div>
              <div class="col">
                <p class="text-right">
                  <img
                    src="../../../assets/tmc/tmc_footer.png"
                    alt="footer_image"
                    class="footer_img"
                  />
                </p>
              </div> -->
            </div>
          </div>

          <!-- Page 6 -->
          <div id="page6" class="container position-relative page" style="background-color: white">
              <!-- Header -->
            <div class="d-flex justify-content-between logo_panel py-3">
              <div class="logo_part">
                <img
                style="width:240px!important" src="../../../assets/hcg-new-logo.jpeg"
                  alt=""
                  class="logo_first"
                />
              </div>
              <div style=" border-left: 2px solid #fab216;padding-top:10px;
              height: 120px;"></div>

              <div class="logo_part text-center" style="font-family:arial Mt!important">

                                <h5 style="font-weight: bolder;margin-bottom:0px!important;">Triesta Sciences</h5>
                <p style="font-size: 15px!important;">
                                  A Unit of Health Care Global Enterprises Ltd <br/>
                                  5th Floor, Tower 1, #8, P.Kalinga Rao Road,<br/>
                                  Sampangiram Nagar, Bengaluru-560027. Tel: 080-40206305
                                </p>
                <h6 style="font-weight: bolder;font-size:15px!important">Department of Molecular and Clinical Genomics</h6>
              </div>
              <div style=" border-left: 2px solid #fab216;padding-top:10px;
              height: 120px;"></div>
              <div class="logo_part">
                <img  style="width:240px!important; margin-top:40px!important"
                  src="../../../assets//oncoquer1.png"
                  alt=""
                  class="logo_three"
                />
              </div>
            </div>
              <div class="row header mt-1">
              <div></div>
              <div class="col">
                <div class="patient-detailes ml-auto float-right">
                  <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
                    <li>
                      Patient Id <br />
                      <span>{{ report.patient?.organisationPatientId }}</span>
                    </li>
                    <li class="ml-4">
                      Patient Name<br />
                      <span>{{ report.patient?.name }}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <!--  -->
              <div *ngIf="report.clientType == '4basecare_client'">
                <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
                  </div>
                </div>
                <div *ngIf="report.clientName == 'path_labs'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
                  </div>
                </div>
              </div>
            </div>
            <!--  -->
            <div class="mar_top"></div>
            <!--  -->

            <div class="">
              <h6 class="py-2 h6_bg" [ngClass]="'yellow'">TEST DESCRIPTION</h6>
              <p class="mb-2" style="text-align:justify">
                <span class="clr-cyan pl-2" [ngClass]="'txt-yellow'">OncQuer<sup>TM</sup> Assist Panel</span>
                is a Next Generation Sequencing based test which identifies
                genetic alterations in a comprehensive panel of well curated 72  genes which are having an impact response to approved
                therapy for a particular cancer type. Some of the alterations
                detected may have bearing on prognosis and/or therapeutic options
                and may provide relevant information that allows
                oncologists/clinicians to consider various lines of
                targeted treatment for the patient.
              </p>
            </div>

            <!--  -->
            <div class="row">
              <div class="col">
                <div class="">
                  <h6 class="py-2 h6_bg" [ngClass]="'yellow'">GENES EVALUATED</h6>
                  <p>
                    <span class="clr-cyan pl-2" [ngClass]="'txt-yellow'">OncQuer<sup>TM</sup> Assist Panel</span>
                    detects mutations (SNVs and Short InDels), Copy Number Variations (CNVs),
                    Gene Fusions and splice variants in the 72 genes:
                    <!-- detects mutations (SNVs and Short Indels), Copy Number Variations
                                                  (CNVs), gene fusions and splice variants in the 53 genes : -->
                  </p>
                </div>
                <!--  -->
                <div class="row mx-0">
                  <div class="col-12 px-0">
                    <div class="genes_info">
                      <div class="title py-2">
                        <h6 class="mb-0 d-inline-block border-bottom py-2">
                          <b>SNVs, SHORT INDELS and CNVs
                          </b> Covered in
                          <span class="clr-cyan pl-2" [ngClass]="'txt-yellow'">OncQuer<sup>TM</sup> Assist Panel</span>
                        </h6>
                      </div>
                      <div class="genes_body" [ngClass]="'gene-bg_blue'">
                        <i>
                          <div class="genes_list">
                            <span>ABL1</span><span>ALK</span><span>APC</span>
                            <span>AR</span><span>ATM</span><span>BARD1</span>
                            <span>BMPR1A</span><span>BRAF</span><span>BRCA1</span>
                            <span>BRCA2</span>
                            <!-- <span>ABL1</span><span>ALK</span><span>AR</span
                                                          ><span>ATM</span><span>BARD1</span><span>BRAF</span
                                                          ><span>BRCA1</span><span>BRCA2</span><span>BRIP1</span
                                                          ><span>CDK12</span> -->
                          </div>
                          <div class="genes_list bg-white">
                            <span>BRIP1</span><span>CDK12</span><span>CDK4</span>
                            <span>CDK6</span><span>CDKN2A</span><span>CHEK1</span>
                            <span>CHEK2</span><span>CTNNB1</span><span>EGFR</span>
                            <span>EPCAM</span>
                            <!-- <span>CDK4</span><span>CDK6</span><span>CDKN2A</span
                                                          ><span>CHEK1</span><span>CHEK2</span><span>EGFR</span
                                                          ><span>EPCAM</span><span>ERBB2</span><span>ERBB3</span
                                                          ><span>EZH2</span> -->
                          </div>
                          <div class="genes_list">
                            <span>ERBB2</span><span>ERBB3</span><span>EZH2</span>
                            <span>FANCL</span><span>FGFR1</span><span>FGFR2</span>
                            <span>FGFR3</span><span>GAPDH</span><span>IDH1</span>
                            <span>IDH2</span>
                            <!-- <span>FANCL</span><span>FGFR3</span><span>GAPDH</span
                                                          ><span>IDH1</span><span>IDH2</span><span>JAK2</span
                                                          ><span>KIT</span><span>KRAS</span><span>MAP2K1</span
                                                          ><span>MAP2K2</span> -->
                          </div>
                          <div class="genes_list">
                            <span>JAK2</span><span>KIT</span><span>KRAS</span>
                            <span>MAP2K1</span><span>MAP2K2</span><span>MDM2</span>
                            <span>MET</span><span>MLH1</span><span>MLH3</span>
                            <span>MSH2</span>
                            <!-- <span>MDM2</span><span>MET</span><span>MLH1</span
                                                          ><span>MLH3</span><span>MSH2</span><span>MSH6</span
                                                          ><span>NRAS</span><span>PALB2</span><span>PDGFRA</span
                                                          ><span>PDGFRB</span> -->
                          </div>
                          <div class="genes_list">
                            <span>MSH6</span><span>MUTYH</span><span>NRAS</span>
                            <span>PALB2</span><span>PDGFRA</span><span>PDGFRB</span>
                            <span>PIK3CA</span><span>PMS1</span><span>PMS2</span>
                            <span>POLD1</span>
                            <!-- <span>PIK3CA</span><span>PMS1</span><span>PMS2</span
                                                          ><span>PPP2R2A</span><span>RAD51B</span><span>RAD51C</span
                                                          ><span>RAD51D</span><span>RAD54L</span><span>RET</span
                                                          ><span>ROS1</span> -->
                          </div>
                          <div class="genes_list">
                            <span>POLE</span><span>POLH</span><span>PTEN</span>
                            <span>RAD50</span><span>RAD51</span><span>RAD51B</span>
                            <span>RAD51C</span><span>RAD51D</span><span>RAD54L</span>
                            <span>RB1</span>
                            <!-- <span>STK11</span><span>TSC1</span><span>TSC2</span> -->
                          </div>
                          <div class="genes_list">
                            <span>RET</span><span>ROS1</span><span>SMAD4</span>
                            <span>STK11</span><span>TP53</span><span>TSC1</span>
                            <span>TSC2</span>
                            <!-- <span>STK11</span><span>TSC1</span><span>TSC2</span> -->
                          </div>
                        </i>
                      </div>

                      <!-- <div class="title py-2 mt-4">
                                                      <h6 class="mb-0 d-inline-block border-bottom py-2">
                                                        <b>CNAs</b> Covered in
                                                        <span class="font-weight-normal">TAR</span><b>GT</b>
                                                        <span class="clr-cyan pl-2" [ngClass]="'txt-yellow'"
                                                          >First</span
                                                        >
                                                      </h6>
                                                    </div> -->
                      <!-- <div class="gene_body" [ngClass]="'gene-bg_blue'">
                                                      <i>
                                                        <div class="genes_list">
                                                          <span>ABL1</span><span>AR</span><span>ATM</span
                                                          ><span>BARD1</span><span>BRCA1</span><span>BRCA2</span
                                                          ><span>BRIP1</span><span>CDK12</span><span>CDK4</span
                                                          ><span>CDK6</span>
                                                        </div>
                                                        <div class="genes_list">
                                                          <span>CDKN2A</span><span>CHEK1</span><span>CHEK2</span
                                                          ><span>EGFR</span><span>EPCAM</span><span>ERBB2</span
                                                          ><span>ERBB3</span><span>EZH2</span><span>FANCL</span
                                                          ><span>FGFR3</span>
                                                        </div>
                                                        <div class="genes_list">
                                                          <span>GAPDH</span><span>IDH2</span><span>JAK2</span
                                                          ><span>KIT</span><span>MDM2</span><span>NRAS</span
                                                          ><span>PALB2</span><span>PDGFRA</span><span>PDGFRB</span
                                                          ><span>PPP2R2A</span>
                                                        </div>
                                                        <div class="genes_list">
                                                          <span>RAD51B</span><span>RAD51C</span><span>RAD51D</span
                                                          ><span>RAD54L</span><span>STK11</span><span>TSC1</span
                                                          ><span>TSC2</span>
                                                        </div>
                                                      </i>
                                                    </div> -->
                    </div>
                  </div>
                  <!-- cnv_genes -->

            <!-- <div class="col-12 px-0">
              <div class="genes_info">
                <div class="title py-2">
                  <h6 class="mb-0 d-inline-block border-bottom py-2">
                    <b> CNVs
                    </b> Covered in
                    <span class="clr-cyan pl-2" [ngClass]="'txt-yellow'">OncQuer<sup>TM</sup> Assist Panel</span>
                  </h6>
                </div>
                <div class="genes_body" [ngClass]="'gene-bg_blue'">
                  <i>
                    <div class="genes_list">
                      <span>ABL1</span><span>ALK</span><span>APC</span>
                      <span>AR</span><span>ATM</span><span>BARD1</span>
                      <span>BMPR1A</span><span>BRAF</span><span>BRCA1</span>
                      <span>BRCA2</span>
                    </div>
                    <div class="genes_list bg-white">
                      <span>BRIP1</span><span>CDK12</span><span>CDK4</span>
                      <span>CDK6</span><span>CDKN2A</span><span>CHEK1</span>
                      <span>CHEK2</span><span>CTNNB1</span><span>EGFR</span>
                      <span>EPCAM</span>
                    </div>
                    <div class="genes_list">
                      <span>ERBB2</span><span>ERBB3</span><span>EZH2</span>
                      <span>FANCL</span><span>FGFR1</span><span>FGFR2</span>
                      <span>FGFR3</span><span>GAPDH</span><span>IDH2</span>
                      <span>JAK2</span>
                    </div>
                    <div class="genes_list">
                     <span>KIT</span><span>MDM2</span>
                      <span>MET</span><span>MLH1</span><span>MUTYH</span>
                      <span>NRAS</span><span>PALB2</span><span>PDGFRA</span>
                      <span>PDGFRAB</span><span>POLD1</span>
                    </div>

                    <div class="genes_list">
                      <span>POLE</span><span>POLH</span><span>PTEN</span>
                      <span>RAD50</span><span>RAD51</span><span>RAD51B</span>
                      <span>RAD51C</span><span>RAD51D</span><span>RAD54L</span>
                      <span>RB1</span>
                    </div>
                    <div class="genes_list">
                      <span>RET</span><span>ROS1</span><span>SMAD4</span>
                      <span>STK11</span><span>TP53</span><span>TSC1</span>
                      <span>TSC2</span>
                    </div>
                  </i>
                </div>


              </div>
            </div> -->




                  <div class="col-12 px-0">
                    <div class="genes_info">
                      <div class="title py-2">
                        <h6 class="mb-0 d-inline-block border-bottom py-2">
                          <b>Gene Fusions/Splicing Variations</b> Covered in
                          <span class="clr-cyan pl-2" [ngClass]="'txt-yellow'">OncQuer<sup>TM</sup> Assist Panel</span>
                        </h6>
                      </div>
                      <div class="genes_body" [ngClass]="'gene-bg_blue'">
                        <i>
                          <div class="genes_list">
                          <span>ALK</span><span>FGFR2</span><span>FGFR3</span>
                          <span>MET</span><span>NRG1</span><span>NRG2</span>
                          <span>NTRK1</span><span>NTRK2</span> <span>NTRK3</span><span>RET</span>

                          </div>
                          <div class="genes_list">
                            <span>ROS1</span>
                          </div>
                        </i>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>

            <!--  -->
            <div class="row footer d-flex justify-content-between align-items-center">
              <!-- <div class="col">
                <div class="py-2">
                  <p>
                    <span class="font-weight-bold">Genomics Bihar,</span>
                  </p>
                  <p>Homi Bhabha cancer hospital & Research centre</p>
                  <p>Inside Srikrishna Medical College & Hospital</p>
                  <p>Umanagar, Muzaffarpur-842004.</p>
                  <p>Contact: ngs.mfp@gmail.com</p>
                </div>
              </div>
              <div class="col">
                <p class="text-right">
                  <img
                    src="../../../assets/tmc/tmc_footer.png"
                    alt="footer_image"
                    class="footer_img"
                  />
                </p>
              </div> -->
            </div>
          </div>

          <!-- Page 7 -->
          <div id="page7" class="container position-relative page" style="background-color: white">
              <!-- Header -->
            <div class="d-flex justify-content-between logo_panel py-3">
              <div class="logo_part">
                <img
                style="width:240px!important" src="../../../assets/hcg-new-logo.jpeg"
                  alt=""
                  class="logo_first"
                />
              </div>
              <div style=" border-left: 2px solid #fab216;padding-top:10px;
              height: 120px;"></div>

              <div class="logo_part text-center" style="font-family:arial Mt!important">

                                <h5 style="font-weight: bolder;margin-bottom:0px!important;">Triesta Sciences</h5>
                <p style="font-size: 15px!important;">
                                  A Unit of Health Care Global Enterprises Ltd <br/>
                                  5th Floor, Tower 1, #8, P.Kalinga Rao Road,<br/>
                                  Sampangiram Nagar, Bengaluru-560027. Tel: 080-40206305
                                </p>
                <h6 style="font-weight: bolder;font-size:15px!important">Department of Molecular and Clinical Genomics</h6>
              </div>
              <div style=" border-left: 2px solid #fab216;padding-top:10px;
              height: 120px;"></div>
              <div class="logo_part">
                <img  style="width:240px!important; margin-top:40px!important"
                  src="../../../assets//oncoquer1.png"
                  alt=""
                  class="logo_three"
                />
              </div>
            </div>
              <div class="row header mt-1">
              <div></div>
              <div class="col">
                <div class="patient-detailes ml-auto float-right">
                  <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
                    <li>
                      Patient Id <br />
                      <span>{{ report.patient?.organisationPatientId }}</span>
                    </li>
                    <li class="ml-4">
                      Patient Name<br />
                      <span>{{ report.patient?.name }}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <!--  -->
              <div *ngIf="report.clientType == '4basecare_client'">
                <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
                  </div>
                </div>
                <div *ngIf="report.clientName == 'path_labs'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
                  </div>
                </div>
              </div>
            </div>
            <!--  -->
            <div class="mar_top"></div>
            <!--  -->
            <h6 class="py-2 h6_bg mt-3" [ngClass]="'yellow'">TEST METHODOLOGY</h6>
            <!--  -->
            <div class="row mt-3">
              <div class="col">
                <h6 class="font-weight-bold">
                  Sample preparation and Library preparation :
                </h6>
                <!-- <p>
                                                DNA isolated from FFPE, or any other fresh tumor tissue source was
                                                used to perform targeted gene capture using a custom capture kit.
                                                The libraries were sequenced to mean >350X coverage on Illumina
                                                sequencing platform.
                                              </p> -->
                <p style="text-align: justify;">
                  DNA isolated from FFPE, or any other fresh tumor tissue source was used to perform targeted
                  gene captured in OncQuer<sup>TM</sup> Assist Panel using a TARGT First kit from 4baseCare.
                  The libraries were sequenced to mean>350X coverage on Illumina sequencing platform.
                </p>
              </div>
            </div>
            <!--  -->
            <div class="row mt-4">
              <div class="col">
                <h6 class="font-weight-bold">
                  Bioinformatics Analysis and Reporting :
                </h6>
                <!-- <p>
                                                The sequences obtained are aligned to human reference genome
                                                (GRCh37/hg19) and variant analysis was performed using set of
                                                Bioinformatics Pipeline. Only non-synonymous and splice site
                                                variants found in the panel consisting of specific set of genes were
                                                used for clinical interpretation. Silent variations that do not
                                                result in any change in amino acid in the coding region are not
                                                reported. Clinically relevant mutations were annotated using
                                                published variants in literature and a set of databases – ClinVar,
                                                COSMIC and dbSNP. Common variants are filtered based on allele
                                                frequency in 1000 Genome Phase 3, ExAC, dbSNP, gnomAD, etc. In the
                                                absence of a clinically significant reported known variation(s),
                                                pathogenicity will be predicted based on <i>in-silico</i> gene
                                                prioritization tools: CADD, SIFT, PolyPhen-2, Condel and Mutation
                                                taster and prioritized for clinical correlation. The identified
                                                pathogenic variant will be correlated with observed phenotypic
                                                features of the patient and interpreted according to American
                                                College of Medical Genetics (ACMG) guidelines.
                                              </p>
                                              <p class="mb-2 d-block">
                                                Somatic variants are classified into three tiers based on their level
                                                of clinical significance in cancer diagnosis, prognosis, and/or
                                                therapeutics as per international guidelines: ACMG, ASCO, AMP, CAP,
                                                NCCN and ESMO
                                              </p> -->
                <p style="text-align: justify;">
                  The sequences obtained are aligned to human reference genome (GRCh37/hg19) and variant analysis was performed
                  using set of Bioinformatics Pipeline. Only non-synonymous and splice site variants found in the panel
                  consisting of specific set of genes were used for clinical interpretation. Silent variations that do not
                  result in any change
                  in amino acid in the coding region are not reported. Clinically relevant mutations were annotated using
                  published variants in literature and a set of databases – ClinVar, COSMIC
                  and dbSNP. Common variants are filtered based on allele frequency in 1000 Genome Phase 3, ExAC, dbSNP, gnomAD,
                  etc. In the absence of a clinically significant reported
                  known variation(s), pathogenicity will be predicted based on <i>in-silico</i> gene prioritization tools: CADD, SIFT,
                  PolyPhen-2, Condel and Mutation taster and prioritized for clinical
                  correlation. The identified pathogenic variant will be correlated with observed phenotypic features of the patient and interpreted according to ACMG/AMP guidelines.
                </p>
                <p class="mb-2 d-block" style="text-align: justify;">
                  Somatic variants are classified into three tiers based on their level of clinical significance in cancer
                  diagnosis, prognosis, and/or therapeutics as per international guidelines:
                  ACMG, ASCO, AMP, CAP, NCCN and ESMO.
                </p>
              </div>
            </div>

            <h6 class="py-2 h6_bg mt-2" [ngClass]="'yellow'">
              LIMITATIONS AND DISCLAIMER
            </h6>

            <div class="row">
              <div class="col">
                <div class="disc">
                  <ul class="ml-0 pl-4" style="text-align: justify;">
                    <li>
                      OncQuer<sup>TM</sup> Assist Panel has been developed, validated and
                      has not been cleared or approved by the FDA.
                    </li>
                    <li>
                      DNA studies do not constitute a definitive test for the selected condition(s) in all individuals. It
                      should be realized that there are possible sources of error. Errors can result
                      from trace contamination, rare technical errors, rare genetic variants that interfere with analysis,
                      recent scientific developments, and alternative classification systems. This
                      test should be one of the many aspects used by the healthcare provider to help with a diagnosis and
                      treatment plan.
                    </li>
                    <li>
                      We are using the canonical transcript for clinical reporting which is usually the longest coding
                      transcript with strong/multiple supporting evidence. However, in rare cases,
                      clinically relevant variants annotated in alternate complete coding transcripts could also be reported.
                    </li>
                    <li>
                      The contents of this test should be carefully assessed by the treating physician and further interpreted
                      along with clinical, histopathological findings, contraindications and
                      guidelines before deciding the course of therapy.
                    </li>
                    <li>
                      The CNVs detected must be confirmed by an alternate method, such as IHC, for further clinical management decisions.
                    </li>
                    <!-- <li>
                      The sensitivity of this assay to detect large deletions/duplications of more than 10 bp or copy number
                      variations (CNV) is 70-75%. The CNVs detected have to be confirmed
                      by alternate method.
                    </li> -->
                    <li>
                      Most recent block is recommended for testing as the mutation profile may change in response to treatment
                      and hence differ at different sampling points.
                    </li>
                    <li>
                      The identified pathogenic variant will be correlated with observed phenotypic features of the patient and interpreted according to AMP guidelines.
                    </li>
                    <li>
                      Certain genes may not be covered completely, and few mutations could be missed. A negative result cannot
                      rule out the possibility that the tested tumor sample carries
                      mutations not previously associated with cancer and hence not included in the panel.
                    </li>
                    <li>
                      Our limit of detection for OncQuer<sup>TM</sup> Assist Panel is 5% for SNVs, 10% for InDels and CNV gain &#8805; 6. In addition
                      to this, sequencing quality and coverage is dependent on many factors
                      such as homopolymers, GC-rich regions, intrinsic quality of FFPE DNA and that might impact the variant
                      detection.
                    </li>
                    <li> A negative result does not rule out the possibility of mutations in the patient’s tumor tissue.</li>
                    <li>Certain regions may be undercovered or poorly amplified due to intrinsic nature of the sample or the tumor
                      biology across the panel, due to which the variants may be detected with compromised QC such as less number
                      of unique reads or lack of paired reads. In such instances, a reflex testing on alternate technologies such
                      as ddPCR or Realtime PCR may be suggested.</li>
                  </ul>
                </div>
              </div>
            </div>

            <!--  -->
            <div class="row footer d-flex justify-content-between align-items-center">
              <!-- <div class="col">
                <div class="py-2">
                  <p>
                    <span class="font-weight-bold">Genomics Bihar,</span>
                  </p>
                  <p>Homi Bhabha cancer hospital & Research centre</p>
                  <p>Inside Srikrishna Medical College & Hospital</p>
                  <p>Umanagar, Muzaffarpur-842004.</p>
                  <p>Contact: ngs.mfp@gmail.com</p>
                </div>
              </div>
              <div class="col">
                <p class="text-right">
                  <img
                    src="../../../assets/tmc/tmc_footer.png"
                    alt="footer_image"
                    class="footer_img"
                  />
                </p>
              </div> -->
            </div>
          </div>
        </div>

        <!-- For HCG Template 2-->
        <div class="hcg" *ngIf="!is4basecare && isHCG && temp=='temp2'">

          <!-- Page 1 -->
          <div id="page1" class="container position-relative page" style="background-color: white">
            <!-- Header -->
            <div class="d-flex justify-content-between logo_panel py-3">
              <div class="logo_part">
                <img
                style="width:240px!important" src="../../../assets/hcg-new-logo.jpeg"
                  alt=""
                  class="logo_first"
                />
              </div>
              <div style=" border-left: 2px solid #fab216;padding-top:10px;
              height: 120px;"></div>

              <div class="logo_part text-center" style="font-family:arial Mt!important">

                                <h5 style="font-weight: bolder;margin-bottom:0px!important;">Triesta Sciences</h5>
                <p style="font-size: 15px!important;">
                                  A Unit of Health Care Global Enterprises Ltd <br/>
                                  5th Floor, Tower 1, #8, P.Kalinga Rao Road,<br/>
                                  Sampangiram Nagar, Bengaluru-560027. Tel: 080-40206305
                                </p>
                <h6 style="font-weight: bolder;font-size:15px!important">Department of Molecular and Clinical Genomics</h6>
              </div>
              <div style=" border-left: 2px solid #fab216;padding-top:10px;
              height: 120px;"></div>
              <div class="logo_part">
                <img  style="width:240px!important; margin-top:40px!important"
                  src="../../../assets//oncoquer1.png"
                  alt=""
                  class="logo_three"
                />
              </div>
            </div>
            <!--  -->
            <div class="mar_top"></div>
            <!--  -->
            <div class="row">
              <div class="col-7">
                <div class="">
                  <div class="w-50" style="min-height: 80px">
                    <p class="py-1 font-weight-bold" [ngClass]="'txt-black'">
                      SCOPE OF THE TEST
                    </p>
                    <p>SNVs, InDels, CNAs, Gene Fusions status</p>
                  </div>


                  <!-- <div style=" border-bottom: 3px solid #fab216;padding-top:10px; width: 610px;"></div> -->
                  <!-- <img src="../../../assets/BorderLine.png" class="mr-2 w-100" /> -->
                </div>
              </div>
              <div class="col">
                <div class="">
                  <div class="w-100" style="min-height: 80px">
                    <div class="w-50" style="min-height: 80px">
                      <p class="py-1 font-weight-bold" [ngClass]="'txt-black'">
                        NAME OF THE TEST
                      </p>
                      <p> OncQuer<sup>TM</sup> Assist Panel</p>
                    </div>
                    <!-- <p [ngClass]="'txt-yellow'" class="py-1 font-weight-bold" *ngIf="report.clinicalSummary != null">
                      <span *ngIf="report.clinicalSummary != ' '">CLINICAL INDICATION</span>
                    </p>

                    <p *ngIf="report.clinicalSummary != null">
                      <span style="color: #000 !important" *ngIf="report.clinicalSummary != ' '">{{ report.clinicalSummary
                        }}</span>
                    </p> -->
                  </div>
                  <!-- <div class="w-100" style="min-height: 80px">
                    <p [ngClass]="'txt-yellow'" class="py-1 font-weight-bold" *ngIf="report.clinicalSummary != null">
                    </p>

                    <p *ngIf="report.clinicalSummary != null">

                    </p>
                  </div> -->
                  <!-- <div style=" border-bottom: 3px solid #fab216;padding-top:10px; width: 430px;"></div> -->
                  <!-- <img src="../../../assets/BorderLine.png" class="mr-2 w-100" /> -->
                </div>
              </div>
            </div>
            <div class="text-center" style=" border-bottom: 3px solid #fab216;padding-right: 10px;
            width: 1074px;
            margin-left: 10px;
            margin-right: 10px;"></div>

            <!--  -->
            <div class="row pt-2">
              <div class="col-7">
                <h6 class="text-uppercase py-2 font-weight-bold" [ngClass]="'txt-black'">
                  Report Details
                </h6>
                <ul class="list-unstyled">
                  <li class="pb-1">
                    <span class="clr-cyan" [ngClass]="'txt-black'">Patient Name : </span>
                    <span class="text-black font-weight-bold">{{
                      report.patient?.name | uppercase
                      }}</span>
                  </li>
                  <li class="pb-1">
                    <span class="clr-cyan" [ngClass]="'txt-black'">Gender : </span>
                    <span class="text-black font-weight-bold">{{
                      report.patient?.gender | titlecase
                    }}</span>
                  </li>
                  <li class="pb-1">
                    <span class="clr-cyan" [ngClass]="'txt-black'">Age/DOB : </span>
                    <span class="text-black font-weight-bold">{{
                      report.patient?.dateOfBirth
                        ? (report.patient?.dateOfBirth | date : "dd/MM/yyyy")
                        : report.patient?.age + " Years"
                    }}</span>
                  </li>
                  <li class="pb-1">
                    <span class="clr-cyan" [ngClass]="'txt-black'">MRN No : </span>
                    <span class="text-black font-weight-bold">{{ report?.patient?.orgMRNNo }}</span>
                  </li>

                  <li class="pb-1">
                    <span class="clr-cyan" [ngClass]="'txt-black'">Hospital Name : </span>
                    <span class="text-black font-weight-bold">{{ labHospital }}</span>
                  </li>
                </ul>
              </div>

              <div class="col">
                <ul class="list-unstyled pt-4 mt-3">
                  <li class="pb-1">
                    <span class="clr-cyan" [ngClass]="'txt-black'">Patient Id :
                    </span>
                    <span class="text-black font-weight-bold">{{ report.patient?.organisationPatientId }}</span>
                  </li>
                  <li class="pb-1">
                    <span class="clr-cyan" [ngClass]="'txt-black'">Collected on :
                    </span>
                    <span class="text-black font-weight-bold">{{
                      report.labDetails?.sampleCollected | date : "dd/MM/yyyy"
                    }}</span>
                  </li>
                  <li class="pb-1">
                    <span class="clr-cyan" [ngClass]="'txt-black'">Reported on :
                    </span>
                    <span class="text-black font-weight-bold">{{ date | date : "dd/MM/yyyy" }}</span>
                  </li>
                  <li class="pb-1">
                    <span class="clr-cyan" [ngClass]="'txt-black'">Referred By :
                    </span>
                    <span class="text-black font-weight-bold">{{ referringClinician }}</span>
                  </li>

                </ul>
              </div>
            </div>
            <!--  -->
            <h6 class="py-2 h6_bg" [ngClass]="'yellow'">CLINICAL HISTORY/SAMPLE DETAILS</h6>
            <div class="row mt-2 py-2">
              <div class="col-3">
                <h6 class="mb-0 font-weight-bold">Clinical Indications</h6>
                <span>{{ report?.cancerIdentifier }}</span>
              </div>
              <div class="col-3">
                <h6 class="mb-0 font-weight-bold">Sample Details</h6>
                <span *ngIf="report.labDetails?.sampleSource">{{
                  report.labDetails?.sampleSource
                  }}</span>
                <span *ngIf="!report.labDetails?.sampleSource">FFPE Block</span>
              </div>
              <div class="col-3">
                <h6 class="mb-0 font-weight-bold">Tumor content</h6>
                <span>{{report?.tmhReportInfo?.tumorContent}}</span>
              </div>
              <div class="col-3">
                <h6 class="mb-0 font-weight-bold">Specimen site</h6>
                <span>{{report?.tmhReportInfo?.biopsySite}}</span>
              </div>

            </div>
            <!--  -->
            <div class="row mt-2 py-2">
              <div class="col">
                <h6 class="mb-0 text-uppercase" style="font-weight: bolder;">Results</h6>
              </div>
            </div>
            <h6 class="py-2 h6_bg" [ngClass]="'yellow'">
              Genomic findings from tumor profiling
            </h6>

            <!--  -->
            <div class="row">
              <div style="width: 100%;" *ngIf="isReportDownload">
                <img [src]="firstPageTable" alt="" width="100%" class="img-fluid" />
              </div>
              <div class="col-12" id="firstPageTable" *ngIf="!isReportDownload">
                <table class="page_1 cust_table w-100 mt-3 first_1" [ngClass]="'table_yellow'">
                  <tr>
                    <th style="font-size:15px!important">Genomic Alteration</th>
                    <th style="font-size:15px!important">Relevant Therapies (in Same Cancer Type)</th>
                    <th style="font-size:15px!important">Relevant Therapies (in Different Cancer)</th>
                  </tr>

                  <tr *ngIf="tableCount== 0 && prognasticCount>0 ">
                    <td colspan="3" class="text-center mt-2">
                      Clinically relevant genomic alterations associated with therapeutic significance were not detected.
                    </td>
                  </tr>
                  <tr *ngIf="tableCount== 0 && prognasticCount == 0">
                    <td colspan="3" class="text-center mt-2">
                      Clinically relevant genomic alterations associated with both therapeutic and prognostic significance were
                      not detected.
                    </td>
                  </tr>

                  <ng-container>
                    <tr *ngFor="
                                                                let snvHotspotTrail of reportPdfRes?.allHotspotTrailList  | slice : 0 : 5 ; let i=index
                                                              ">
                      <td>
                        <i>{{ snvHotspotTrail.gene }}</i>
                        &nbsp;<span *ngIf="!snvHotspotTrail.gene.includes('Wild type')">
                        <span *ngIf="snvHotspotTrail?.multiVariant==null">
                          <span *ngIf="snvHotspotTrail.exonNumber != null || snvHotspotTrail.pposition != null && snvHotspotTrail.pposition != ''">

                            <span *ngIf="snvHotspotTrail.exonNumber != null">{{snvHotspotTrail.exonNumber}}</span>&nbsp;
                            <span *ngIf="snvHotspotTrail.pposition != null && snvHotspotTrail.pposition != ''">({{snvHotspotTrail.pposition?.replace(' ','')}})</span></span>
                            <p *ngIf="snvHotspotTrail.mutantBurdenPercent != null && !snvHotspotTrail.gene.includes('Wild type')">
                              Allelic burden: {{ snvHotspotTrail.mutantBurdenPercent }}%
                            </p>
                            <span *ngIf="snvHotspotTrail.copyNumber!=null && snvHotspotTrail.copyNumber.length>0"> {{ snvHotspotTrail.copyNumber }}
                          </span>
                        </span>
                        <span *ngIf="snvHotspotTrail?.multiVariant!=null"><br/>
                          <span  style="font-size: 14px !important" [innerHtml]="snvHotspotTrail?.multiVariant"></span>
                        </span></span>
                      </td>
                      <td>
                        <table class="table first_table w-100">
                          <thead class="border-bottom cust_clr">
                            <tr>
                              <th class="w-50">
                                <span class="font-weight-normal"><i>Therapy</i></span>
                              </th>
                              <th class="w-33">
                                <span class="font-weight-normal"><i>Clinical Relevance</i></span>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngIf="snvHotspotTrail.sameCancerType == null">
                              <td class="w-50">NA</td>
                              <td class="w-50">NA</td>
                            </tr>

                            <ng-container *ngIf="snvHotspotTrail.sameCancerType != null">
                              <tr *ngFor="
                                                                          let item of snvHotspotTrail.sameTypeRelevantData
                                                                        ">
                                <td>{{ item.therapy }}</td>
                                <td>{{ item.association }}</td>
                                <!-- <td>{{item.cancerType}}</td> -->
                              </tr>
                            </ng-container>
                          </tbody>
                        </table>
                      </td>
                      <td>
                        <table class="table first_table w-100">
                          <thead class="border-bottom cust_clr">
                            <tr>
                              <th class="w-33">
                                <span class="font-weight-normal"><i>Therapy</i></span>
                              </th>
                              <th class="w-33">
                                <span class="font-weight-normal"><i>Clinical Relevance</i></span>
                              </th>
                              <th class="w-33">
                                <span class="font-weight-normal"><i>Cancer Type</i></span>
                              </th>
                            </tr>
                          </thead>
                          <tbody>

                            <ng-container *ngIf="snvHotspotTrail.otherCancerType != null">
                              <tr *ngFor="
                                                                          let item of snvHotspotTrail.otherTypeRelevantData
                                                                        ">
                                <td>{{ item.therapy }}</td>
                                <td>{{ item.association }}</td>
                                <td>{{ item.cancerType }}</td>
                              </tr>
                            </ng-container>
                            <tr *ngIf="snvHotspotTrail.otherCancerType == null">
                              <td class="w-33">NA</td>
                              <td class="w-33">NA</td>
                              <td class="w-33">NA</td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>


                  </ng-container>
                </table>
                <p *ngIf="
                                snvHotSpot.length != 0 ||
                                cnaHotSpot.length != 0 ||
                                                    fusionHotSpot.length !=0
                                                  " style="font-size: 13px !important" class="mt-0">
                  <i> *NA: Not Applicable</i>
                </p>
              </div>
            </div>
            <!--  -->
            <div *ngIf="(tableCount>0) &&
                              prognasticCount==0">
              <h6 class="py-2 h6_bg" [ngClass]="'yellow'"> Prognostic Biomarkers</h6>
            </div>
            <div class="row" *ngIf="(tableCount>0 )&&
                              prognasticCount==0">
              <div class="col-12">
                <table class="page_1 cust_table w-100 mt-3 varient_detailsy prognasticbiomarkertable"
                  [ngClass]="'table_variant-blue'">
                  <thead>
                    <tr>
                      <th>Genomic Alteration</th>
                      <th>Prognostic Significance</th>

                    </tr>
                  </thead>
                  <ng-container>
                    <tr>
                      <td colspan="2" style="text-align:center">
                        Clinically relevant genomic alterations associated with prognostic significance were not detected.
                      </td>
                    </tr>
                  </ng-container>
                </table>
              </div>
            </div>
            <div *ngIf="prognasticCount>0">
              <h6 class="py-2 h6_bg" [ngClass]="'yellow'"> Prognostic Biomarkers</h6>
            </div>

            <div class="row" *ngIf="prognasticCount>0">
              <div class="col-12">
                <table class="page_1 cust_table w-100 mt-3 varient_detailsy prognasticbiomarkertable"
                  [ngClass]="'table_variant-blue'">
                  <thead>
                    <tr>
                      <th>Genomic Alteration</th>
                      <th>Prognostic Significance</th>

                    </tr>
                  </thead>
                  <ng-container *ngFor="let prog of reportPdfRes.prognosticBiomarkers">
                    <tr>
                      <td style="padding-left: 3px !important">
                        <i>{{ prog.gene }}&nbsp; </i>
                        <span [innerHtml]="prog.geneDetails"></span>
                      </td>
                      <td style="padding-left: 3px !important;">{{prog.statement}}
                      </td>
                    </tr>
                  </ng-container>

                </table>
              </div>
            </div>
            <!--  -->



            <!--  -->
            <div class="row footer d-flex justify-content-between align-items-center">
              <!-- <div class="col">
                <div class="py-2">
                  <p>
                    <span class="font-weight-bold">Genomics Bihar,</span>
                  </p>
                  <p>Homi Bhabha cancer hospital & Research centre</p>
                  <p>Inside Srikrishna Medical College & Hospital</p>
                  <p>Umanagar, Muzaffarpur-842004.</p>
                  <p>Contact: ngs.mfp@gmail.com</p>
                </div>
              </div>
              <div class="col">
                <p class="text-right">
                  <img
                    src="../../../assets/tmc/tmc_footer.png"
                    alt="footer_image"
                    class="footer_img"
                  />
                </p>
              </div> -->
            </div>
          </div>

          <!-- page 2 -->
          <div id="page2" *ngIf="
                          geneMutationlist != null ||
                                                    otherCountSNV == true ||
                                                    reportPdfRes.allVariantsList.length > 0
                                                    " class="container position-relative page" style="background-color: white">
              <!-- Header -->
            <div class="d-flex justify-content-between logo_panel py-3">
              <div class="logo_part">
                <img
                style="width:240px!important" src="../../../assets/hcg-new-logo.jpeg"
                  alt=""
                  class="logo_first"
                />
              </div>
              <div style=" border-left: 2px solid #fab216;padding-top:10px;
              height: 120px;"></div>

              <div class="logo_part text-center" style="font-family:arial Mt!important">

                                <h5 style="font-weight: bolder;margin-bottom:0px!important;">Triesta Sciences</h5>
                <p style="font-size: 15px!important;">
                  A Unit of Health Care Global Enterprises Ltd <br/>
                  5th Floor, Tower 1, #8, P.Kalinga Rao Road,<br/>
                  Sampangiram Nagar, Bengaluru-560027. Tel: 080-40206305
                </p>
                <h6 style="font-weight: bolder;font-size:15px!important">Department of Molecular and Clinical Genomics</h6>
              </div>
              <div style=" border-left: 2px solid #fab216;padding-top:10px;
              height: 120px;"></div>
              <div class="logo_part">
                <img  style="width:240px!important; margin-top:40px!important"
                  src="../../../assets//oncoquer1.png"
                  alt=""
                  class="logo_three"
                />
              </div>
            </div>
              <div class="row header mt-1">
              <div></div>
              <div class="col">
                <div class="patient-detailes ml-auto float-right">
                  <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
                    <li>
                      Patient Id <br />
                      <span>{{ report.patient?.organisationPatientId }}</span>
                    </li>
                    <li class="ml-4">
                      Patient Name<br />
                      <span>{{ report.patient?.name }}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <!--  -->
              <div *ngIf="report.clientType == '4basecare_client'">
                <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
                  </div>
                </div>
                <div *ngIf="report.clientName == 'path_labs'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
                  </div>
                </div>
              </div>
            </div>

            <!-- gene Data -->
            <div *ngIf="geneMutationlist != null && isStatusDisplay">
              <div class="mt-3">
                <h6 class="py-2 h6_bg" [ngClass]="'yellow'">
                  Status of variants in cancer related Biomarkers
                </h6>
              </div>

              <table class="staus_gene bg_skyblue" *ngIf="geneMutationlist != null" [ngClass]="{
                                                                      biomarker_table:
                                                                        geneMutationlist.length >= 4 &&
                                                                        geneMutationlist.length < 8
                                                                    }">
                <tr style="background: #bfe6fa">
                  <td style="font-size: 18px !important; padding-left: 10px !important">
                    Gene
                  </td>
                  <td *ngFor="
                                                                          let geneMutation of geneMutationlist
                                                                            | slice : 0 : 8
                                                                        ">
                    <i>{{ geneMutation.gene }}</i>
                  </td>
                </tr>
                <tr>
                  <td style="font-size: 18px !important; padding-left: 10px !important">
                    <b>Status</b>
                  </td>
                  <td *ngFor="
                                                                          let geneMutation of geneMutationlist
                                                                            | slice : 0 : 8
                                                                        ">
                    <span *ngIf="
                                                                            geneMutation.status == 'Pathogenic' ||
                                                                            geneMutation.status == 'Likely Pathogenic' ||
                                                                            geneMutation.status == 'Detected'
                                                                          " style="color: red; font-weight: 600px"><b>{{
                        geneMutation.status
                        }}</b></span>
                    <span *ngIf="geneMutation.status == 'VUS'" style="color: black; font-weight: 600px"><b>{{
                        geneMutation.status }}</b></span>
                    <span *ngIf="
                                                                            geneMutation.status == 'Not Detected' ||
                                                                            geneMutation.status == 'Benige'
                                                                          ">{{ geneMutation.status }}</span>
                  </td>
                </tr>
<br/>
              </table>
              <table class="staus_gene" [ngClass]="'bg_skyblue'" *ngIf="
                                                                      geneMutationlist != null &&
                                                                      geneMutationlist.length > 8
                                                                    ">
                <tr style="background: #bfe6fa">
                  <td style="font-size: 18px !important; padding-left: 10px !important">
                    Gene
                  </td>
                  <td *ngFor="
                                                                          let geneMutation of geneMutationlist
                                                                            | slice : 8 : 17
                                                                        ">
                    <i>{{ geneMutation.gene }}</i>
                  </td>
                </tr>
                <tr>
                  <td style="font-size: 18px !important; padding-left: 10px !important">
                    <b>Status</b>
                  </td>
                  <td *ngFor="
                                                                          let geneMutation of geneMutationlist
                                                                            | slice : 8 : 17
                                                                        ">
                    <span *ngIf="
                                                                            geneMutation.status == 'Pathogenic' ||
                                                                            geneMutation.status == 'Likely Pathogenic' ||
                                                                            geneMutation.status == 'Detected'
                                                                          " style="color: red; font-weight: 600px"><b>{{
                        geneMutation.status
                        }}</b></span>
                    <span *ngIf="geneMutation.status == 'VUS'" style="color: black; font-weight: 600px"><b>{{
                        geneMutation.status }}</b></span>
                    <span *ngIf="
                                                                            geneMutation.status == 'Not Detected' ||
                                                                            geneMutation.status == 'Benige'
                                                                          ">{{ geneMutation.status }}</span>
                  </td>
                  </tr>
                  <br/>
              </table>
              <table
                class="staus_gene"
                [ngClass]="'bg_skyblue'"
                *ngIf="
                  geneMutationlist != null &&
                  geneMutationlist.length > 17
                "
              >
                <tr style="background: #bfe6fa">
                  <td
                    style="font-size: 18px !important; padding-left: 10px !important"
                  >
                    Gene
                  </td>
                  <td
                    *ngFor="
                      let geneMutation of geneMutationlist
                        | slice : 17 : 25
                    "
                  >
                    <i>{{ geneMutation.gene }}</i>
                  </td>
                </tr>
                <tr>
                  <td
                    style="font-size: 18px !important; padding-left: 10px !important"
                  >
                    <b>Status</b>
                  </td>
                  <td
                    *ngFor="
                      let geneMutation of geneMutationlist
                        | slice : 17 : 25
                    "
                  >
                    <span
                      *ngIf="
                        geneMutation.status == 'Pathogenic' ||
                        geneMutation.status == 'Likely Pathogenic' ||
                        geneMutation.status == 'Detected'
                      "
                      style="color: red; font-weight: 600px"
                      ><b>{{ geneMutation.status }}</b></span
                    >
                    <span
                      *ngIf="geneMutation.status == 'VUS'"
                      style="color: black; font-weight: 600px"
                      ><b>{{ geneMutation.status }}</b></span
                    >
                    <span
                      *ngIf="
                        geneMutation.status == 'Not Detected' ||
                        geneMutation.status == 'Benige'
                      "
                      >{{ geneMutation.status }}</span
                    >
                  </td>
                </tr>
<br/>
              </table>
              <table class="staus_gene" [ngClass]="'bg_skyblue'" *ngIf="
                    geneMutationlist != null &&
                    geneMutationlist.length > 25
                  ">
                <tr style="background: #bfe6fa">
                  <td style="font-size: 18px !important; padding-left: 10px !important">
                    Gene
                  </td>
                  <td *ngFor="
                        let geneMutation of geneMutationlist
                          | slice : 25 : 33
                      ">
                    <i>{{ geneMutation.gene }}</i>
                  </td>
                </tr>
                <tr>
                  <td style="font-size: 18px !important; padding-left: 10px !important">
                    <b>Status</b>
                  </td>
                  <td *ngFor="
                        let geneMutation of geneMutationlist
                          | slice : 25 : 33
                      ">
                    <span *ngIf="
                          geneMutation.status == 'Pathogenic' ||
                          geneMutation.status == 'Likely Pathogenic' ||
                          geneMutation.status == 'Detected'
                        " style="color: red; font-weight: 600px"><b>{{ geneMutation.status }}</b></span>
                    <span *ngIf="geneMutation.status == 'VUS'" style="color: black; font-weight: 600px"><b>{{
                        geneMutation.status }}</b></span>
                    <span *ngIf="
                          geneMutation.status == 'Not Detected' ||
                          geneMutation.status == 'Benige'
                        ">{{ geneMutation.status }}</span>
                  </td>
                </tr>
<br/>
              </table>
          <table class="staus_gene" [ngClass]="'bg_skyblue'" *ngIf="
                geneMutationlist != null &&
                geneMutationlist.length > 33
              ">
            <tr style="background: #bfe6fa">
              <td style="font-size: 18px !important; padding-left: 10px !important">
                Gene
              </td>
              <td *ngFor="
                    let geneMutation of geneMutationlist
                      | slice : 33 : 41
                  ">
                <i>{{ geneMutation.gene }}</i>
              </td>
            </tr>
            <tr>
              <td style="font-size: 18px !important; padding-left: 10px !important">
                <b>Status</b>
              </td>
              <td *ngFor="
                    let geneMutation of geneMutationlist
                      | slice : 33 : 41
                  ">
                <span *ngIf="
                      geneMutation.status == 'Pathogenic' ||
                      geneMutation.status == 'Likely Pathogenic' ||
                      geneMutation.status == 'Detected'
                    " style="color: red; font-weight: 600px"><b>{{ geneMutation.status }}</b></span>
                <span *ngIf="geneMutation.status == 'VUS'" style="color: black; font-weight: 600px"><b>{{
                    geneMutation.status }}</b></span>
                <span *ngIf="
                      geneMutation.status == 'Not Detected' ||
                      geneMutation.status == 'Benige'
                    ">{{ geneMutation.status }}</span>
              </td>
            </tr>
            <br/>
          </table>
              <h6 *ngIf="
                                      (fusionGeneMutationList != null &&
                                      fusionGeneMutationList.length > 0) "
                class="font-weight-bold clr-cyan text-uppercase" [ngClass]="'txt-black'">
                Gene Fusion
              </h6>
              <table class="staus_gene" *ngIf="
                                                                      (fusionGeneMutationList != null &&
                                                                      fusionGeneMutationList.length > 0)
                                                                    " [ngClass]="'bg_skyblue'">
                <tr style="background: #bfe6fa">
                  <td style="font-size: 18px !important; padding-left: 10px !important">
                    Gene
                  </td>
                  <td *ngFor="
                                                                          let geneMutation of fusionGeneMutationList
                                                                            | slice : 0 : 8
                                                                        ">
                    <i>{{ geneMutation.gene }}</i>
                  </td>
                </tr>
                <tr>
                  <td style="font-size: 18px !important; padding-left: 10px !important">
                    <b>Status</b>
                  </td>
                  <td *ngFor="
                                                                          let geneMutation of fusionGeneMutationList
                                                                            | slice : 0 : 8
                                                                        ">
                    <span *ngIf="
                                                                            geneMutation.status == 'Pathogenic' ||
                                                                            geneMutation.status == 'Likely Pathogenic' ||
                                                                            geneMutation.status == 'Detected'
                                                                          " style="color: red; font-weight: 600px"><b>{{
                        geneMutation.status
                        }}</b></span>
                    <span *ngIf="geneMutation.status == 'VUS'" style="color: black; font-weight: 600px"><b>{{
                        geneMutation.status }}</b></span>
                    <span *ngIf="
                                                                            geneMutation.status == 'Not Detected' ||
                                                                            geneMutation.status == 'Benige'
                                                                          ">{{ geneMutation.status }}</span>
                  </td>
                </tr>
<br/>
              </table>
              <br/>
              <table class="staus_gene" *ngIf="
                                                                      (fusionGeneMutationList != null &&
                                                                      fusionGeneMutationList.length > 8)
                                                                    " [ngClass]="'bg_skyblue'">
                <tr style="background: #bfe6fa">
                  <td style="font-size: 18px !important; padding-left: 10px !important">
                    Gene
                  </td>
                  <td *ngFor="
                                                                          let geneMutation of fusionGeneMutationList
                                                                            | slice : 8 : 17
                                                                        ">
                    <i>{{ geneMutation.gene }}</i>
                  </td>
                </tr>
                <tr>
                  <td style="font-size: 18px !important; padding-left: 10px !important">
                    <b>Status</b>
                  </td>
                  <td *ngFor="
                                                                          let geneMutation of fusionGeneMutationList
                                                                            | slice : 8 : 17
                                                                        ">
                    <span *ngIf="
                                                                            geneMutation.status == 'Pathogenic' ||
                                                                            geneMutation.status == 'Likely Pathogenic' ||
                                                                            geneMutation.status == 'Detected'
                                                                          " style="color: red; font-weight: 600px"><b>{{
                        geneMutation.status
                        }}</b></span>
                    <span *ngIf="geneMutation.status == 'VUS'" style="color: black; font-weight: 600px"><b>{{
                        geneMutation.status }}</b></span>
                    <span *ngIf="
                                                                            geneMutation.status == 'Not Detected' ||
                                                                            geneMutation.status == 'Benige'
                                                                          ">{{ geneMutation.status }}</span>
                  </td>
                </tr>
              </table>
            </div>
            <!--  -->
            <div *ngIf="
            report.disclaimerNote != ' ' &&
            report.disclaimerNote != null
          ">
        <h6 style="color: #000"><b>Note: </b></h6>
        <div style="font-size: 14px !important;text-align: justify" [innerHtml]="report.disclaimerNote"></div>
      </div>

            <div class="row mt-3" *ngIf="
                                                        otherCountSNV == true ||
                                                        reportPdfRes.allVariantsList.length != 0
                                                      ">
              <div class="col">
                <h6 class="font-weight-bold clr-cyan text-uppercase" [ngClass]="'txt-black'">
                  Variant Details:
                </h6>
              </div>
            </div>

            <div class="row" *ngIf="
                                                        otherCountSNV == true ||
                                                        reportPdfRes.allVariantsList.length != 0
                                                      ">
              <div class="col-12">
                <table class="page_1 cust_table w-100 mt-3 varient_detailsy" [ngClass]="'table_variant-blue'">
                  <thead>
                    <tr>
                      <th>Gene</th>
                      <th>Variant Location</th>
                      <th>Variant Consequence</th>
                      <th>Clinical Significance</th>
                      <th>Variant Type</th>
                      <th>Reference</th>
                    </tr>
                  </thead>

                  <tr *ngFor="let variant of reportPdfRes.allVariantsList">
                    <td *ngIf="variant.significance != 'NA' && variant.significance != 'Not Classified'" style="padding-left: 3px !important">
                      <i>{{ variant.gene }}</i>
                    </td>
                    <td *ngIf="variant.significance != 'NA' && variant.significance != 'Not Classified'" style="padding-left: 3px !important">
                      <span *ngIf="variant?.variantType=='snv'">
                        {{ variant?.genomicAlteration }},<br />
                        {{ variant?.ensembleValue }},<br />
                        {{ variant?.exonNumber }}
                      </span>
                      <span *ngIf="variant?.variantType=='cna'">
                        chr{{ variant?.sample?.split(":")[0] }}:g.{{
                          variant.sample
                          .split(":")[1]
                          .replace("-", "_")
                          .split("|")[0]
                          }}
                      </span>
                      <span *ngIf="variant.variantType=='fusion'">
                        {{ variant?.leftBreakPoint }}<br />{{variant?.rightBreakPoint}}
                      </span>
                    </td>
                    <td *ngIf="variant?.significance != 'NA' && variant?.significance != 'Not Classified'" style="padding-left: 3px !important">
                      <span *ngIf="variant.variantType=='snv'">
                        <span *ngIf="variant?.selectedAAChangeknownGene">{{
                          variant?.selectedAAChangeknownGene|splitComma }}</span>, {{variant?.mutantBurdenPercent * 0.01 | percent}}
                        </span>
                      <span *ngIf="variant.variantType=='cna'">NA</span>
                      <span *ngIf="variant.variantType=='fusion'">NA</span>
                    </td>
                    <td *ngIf="variant?.significance != 'NA' && variant?.significance != 'Not Classified'" style="padding-left: 3px !important">
                      {{ variant?.significance }}
                    </td>
                    <td *ngIf="variant?.significance != 'NA' && variant?.significance != 'Not Classified'" style="padding-left: 3px !important">
                      <span *ngIf="variant.variantType=='snv'">
                        {{ variant?.exonicFuncknownGene }}
                      </span>
                      <span *ngIf="variant.variantType=='cna'">
                        Copy Number
                        {{ variant?.sample.split(":")[2] | titlecase }}
                        ({{ variant?.sample.split("|")[1].split(":")[0] }})
                      </span>
                      <span *ngIf="variant.variantType=='fusion'">Gene Fusions</span>
                    </td>
                    <td *ngIf="variant?.significance != 'NA' && variant?.significance != 'Not Classified'" style="padding-left: 3px !important">
                      {{ variant?.reference }}
                    </td>
                  </tr>
                </table>
                <p *ngIf="reportPdfRes.allVariantsList.length > 0 " style="font-size: 13px !important" class="mt-0">
                  <i> *NA: Not Applicable</i>
                </p>
              </div>
            </div>
            <div class="row mt-5" *ngIf="
                  !variantFlag &&
                  report.addNotes != null && report.addNotes != '<div></div>'
                ">
          <div class="col">
            <h6 class="py-2 h6_bg" [ngClass]="'yellow'">Additional Notes</h6>
            <div class="py-2" [innerHtml]="report.addNotes" style="text-align: justify; font-size: 14px !important"></div>
          </div>
        </div>
            <!--  -->
            <div class="row footer d-flex justify-content-between align-items-center">
              <!-- <div class="col">
                <div class="py-2">
                  <p>
                    <span class="font-weight-bold">Genomics Bihar,</span>
                  </p>
                  <p>Homi Bhabha cancer hospital & Research centre</p>
                  <p>Inside Srikrishna Medical College & Hospital</p>
                  <p>Umanagar, Muzaffarpur-842004.</p>
                  <p>Contact: ngs.mfp@gmail.com</p>
                </div>
              </div>
              <div class="col">
                <p class="text-right">
                  <img
                    src="../../../assets/tmc/tmc_footer.png"
                    alt="footer_image"
                    class="footer_img"
                  />
                </p>
              </div> -->
            </div>
          </div>

        <!-- page 3 -->
        <div id="page3" *ngIf="reportPdfRes.drugsResList.length > 0" class="container position-relative page"
          style="background-color: white">
          <div class="d-flex justify-content-between logo_panel py-3">
            <div class="logo_part">
              <img style="width:240px!important" src="../../../assets/hcg-new-logo.jpeg" alt="" class="logo_first" />
            </div>
            <div style=" border-left: 2px solid #fab216;padding-top:10px;
                      height: 120px;"></div>

            <div class="logo_part text-center" style="font-family:arial Mt!important">

              <h5 style="font-weight: bolder;margin-bottom:0px!important;">Triesta Sciences</h5>
              <p style="font-size: 15px!important;">
                A Unit of Health Care Global Enterprises Ltd <br />
                5th Floor, Tower 1, #8, P.Kalinga Rao Road,<br />
                Sampangiram Nagar, Bengaluru-560027. Tel: 080-40206305
              </p>
              <h6 style="font-weight: bolder;font-size:15px!important">Department of Molecular and Clinical Genomics</h6>
            </div>
            <div style=" border-left: 2px solid #fab216;padding-top:10px;
                      height: 120px;"></div>
            <div class="logo_part">
              <img style="width:240px!important; margin-top:40px!important" src="../../../assets//oncoquer1.png" alt=""
                class="logo_three" />
            </div>
          </div>
          <div class="row header mt-1">
            <div>
              <!-- <div class="col-3">
                          <img src="../../../assets/logo.png" alt="" style="width: 140px" />
                        </div> -->
            </div>
            <div></div>
            <div class="col">
              <div class="patient-detailes ml-auto float-right">
                <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
                  <li>
                    Patient Id <br />
                    <span>{{ report.patient?.organisationPatientId }}</span>
                  </li>
                  <li class="ml-4">
                    Patient Name<br />
                    <span>{{ report.patient?.name }}</span>
                  </li>
                </ul>
              </div>
            </div>
            <!--  -->
            <div *ngIf="report.clientType == '4basecare_client'">
              <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
                <div class="logo">
                  <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
                </div>
              </div>
              <div *ngIf="report.clientName == 'path_labs'" class="col-3">
                <div class="logo">
                  <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
                </div>
              </div>
            </div>
          </div>
          <!--  -->
          <div class="mar_top"></div>
          <!--  -->

          <h6 class="py-2 h6_bg" [ngClass]="'yellow'">
            Alterations With Clinically Relevant Therapies
          </h6>
          <!--  -->
          <div class="row">
            <div class="col-12">
              <table class="page_1 cust_table w-100 mt-3 varient_detailsy prognastictable1">
                <thead>
                  <tr>
                    <th>Gene (Alterations) - Drug association</th>
                    <th>Summary</th>
                  </tr>
                </thead>
                <!-- SNV -->

                <tbody>
                  <tr *ngFor="
                                                                        let drugsRes of reportPdfRes.drugsResList | slice : 0 : 4;
                                                                        let i = index
                                                                      ">
                    <td>

                          <i>{{ drugsRes.gene.replace("Fusion","") }}</i><span *ngIf="drugsRes.gene.includes('Fusion')">Fusion</span> {{ drugsRes.pposition?.replace(' ','') }} :
                          <br />
                          <span *ngFor="let drug of drugsRes.drugs; let i = index">
                            {{ drug.name
                            }}<span *ngIf="drugsRes.drugs.length - 1 != i">,</span>
                          </span>
                          - {{ drugsRes.association }}
                          <p>{{ drugsRes.cancerType }}</p>
                        </td>

                    <td>
                      <div [innerHtml]="drugsRes.summary"></div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div class="row footer">
            <!-- <div class="col-12">
                    <div class="pb-2 pl-4 text-center">
                      <div class="d-block">
                          <span class="font-weight-bold" style="font-size: 13px !important"
                            >4baseCare (Genomics Tarang ODC),</span
                          >
                        <span>
                          SJP2-S1-1F- C wing, Wipro Limited, SEZ, Sarjapur 2, Sy.No.69 (P),
                        </span>
                      </div>
                      <div class="d-block">
                        <span>Doddakannelli, Sarjapura Road,</span>
                        <span> Bengaluru 560035, KA, India</span></div>


                    </div>
                  </div>
                  <div class="col-12 pt-1 ">
                    <p  style="font-size: 8px!important; line-height: 14px!important;">{{footerDiscription}} </p>
                  </div> -->


          </div>
        </div>

        <!-- page 3_1 -->
        <div id="page3_1" *ngIf="reportPdfRes.drugsResList.length > 4" class="container position-relative page"
          style="background-color: white">
          <div class="d-flex justify-content-between logo_panel py-3">
            <div class="logo_part">
              <img style="width:240px!important" src="../../../assets/hcg-new-logo.jpeg" alt="" class="logo_first" />
            </div>
            <div style=" border-left: 2px solid #fab216;padding-top:10px;
                      height: 120px;"></div>

            <div class="logo_part text-center" style="font-family:arial Mt!important">

              <h5 style="font-weight: bolder;margin-bottom:0px!important;">Triesta Sciences</h5>
              <p style="font-size: 15px!important;">
                A Unit of Health Care Global Enterprises Ltd <br />
                5th Floor, Tower 1, #8, P.Kalinga Rao Road,<br />
                Sampangiram Nagar, Bengaluru-560027. Tel: 080-40206305
              </p>
              <h6 style="font-weight: bolder;font-size:15px!important">Department of Molecular and Clinical Genomics</h6>
            </div>
            <div style=" border-left: 2px solid #fab216;padding-top:10px;
                      height: 120px;"></div>
            <div class="logo_part">
              <img style="width:240px!important; margin-top:40px!important" src="../../../assets//oncoquer1.png" alt=""
                class="logo_three" />
            </div>
          </div>
          <div class="row header mt-1">
            <div>
              <!-- <div class="col-3">
                          <img src="../../../assets/logo.png" alt="" style="width: 140px" />
                        </div> -->
            </div>
            <div></div>
            <div class="col">
              <div class="patient-detailes ml-auto float-right">
                <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
                  <li>
                    Patient Id <br />
                    <span>{{ report.patient?.organisationPatientId }}</span>
                  </li>
                  <li class="ml-4">
                    Patient Name<br />
                    <span>{{ report.patient?.name }}</span>
                  </li>
                </ul>
              </div>
            </div>
            <!--  -->
            <div *ngIf="report.clientType == '4basecare_client'">
              <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
                <div class="logo">
                  <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
                </div>
              </div>
              <div *ngIf="report.clientName == 'path_labs'" class="col-3">
                <div class="logo">
                  <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
                </div>
              </div>
            </div>
          </div>
          <!--  -->
          <div class="mar_top"></div>
          <!--  -->

          <h6 class="py-2 h6_bg" [ngClass]="'yellow'">
            Alterations With Clinically Relevant Therapies
          </h6>
          <!--  -->
          <div class="row">
            <div class="col-12">
              <table class="page_1 cust_table w-100 mt-3 varient_detailsy prognastictable1">
                <thead>
                  <tr>
                    <th>Gene (Alterations) - Drug association</th>
                    <th>Summary</th>
                  </tr>
                </thead>
                <!-- SNV -->

                <tbody>
                  <tr *ngFor="
                                                                                  let drugsRes of reportPdfRes.drugsResList | slice : 4 : 8;
                                                                                  let i = index
                                                                                ">
                    <td>

                          <i>{{ drugsRes.gene.replace("Fusion","") }}</i><span *ngIf="drugsRes.gene.includes('Fusion')">Fusion</span> {{ drugsRes.pposition?.replace(' ','') }} :
                          <br />
                          <span *ngFor="let drug of drugsRes.drugs; let i = index">
                            {{ drug.name
                            }}<span *ngIf="drugsRes.drugs.length - 1 != i">,</span>
                          </span>
                          - {{ drugsRes.association }}
                          <p>{{ drugsRes.cancerType }}</p>

                    </td>
                    <td>
                      <div [innerHtml]="drugsRes.summary"></div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <!--  -->

          <!--  -->
          <div class="row footer">
            <!-- <div class="col-12">
                    <div class="pb-2 pl-4 text-center">
                      <div class="d-block">
                          <span class="font-weight-bold" style="font-size: 13px !important"
                            >4baseCare (Genomics Tarang ODC),</span
                          >
                        <span>
                          SJP2-S1-1F- C wing, Wipro Limited, SEZ, Sarjapur 2, Sy.No.69 (P),
                        </span>
                      </div>
                      <div class="d-block">
                        <span>Doddakannelli, Sarjapura Road,</span>
                        <span> Bengaluru 560035, KA, India</span></div>


                    </div>
                  </div>
                  <div class="col-12 pt-1 ">
                    <p  style="font-size: 8px!important; line-height: 14px!important;">{{footerDiscription}} </p>
                  </div> -->


          </div>
        </div>

        <!-- page 3_2 -->
        <div id="page3_2" *ngIf="reportPdfRes.drugsResList.length > 8" class="container position-relative page"
          style="background-color: white">
          <div class="d-flex justify-content-between logo_panel py-3">
            <div class="logo_part">
              <img style="width:240px!important" src="../../../assets/hcg-new-logo.jpeg" alt="" class="logo_first" />
            </div>
            <div style=" border-left: 2px solid #fab216;padding-top:10px;
                      height: 120px;"></div>

            <div class="logo_part text-center" style="font-family:arial Mt!important">

              <h5 style="font-weight: bolder;margin-bottom:0px!important;">Triesta Sciences</h5>
              <p style="font-size: 15px!important;">
                A Unit of Health Care Global Enterprises Ltd <br />
                5th Floor, Tower 1, #8, P.Kalinga Rao Road,<br />
                Sampangiram Nagar, Bengaluru-560027. Tel: 080-40206305
              </p>
              <h6 style="font-weight: bolder;font-size:15px!important">Department of Molecular and Clinical Genomics</h6>
            </div>
            <div style=" border-left: 2px solid #fab216;padding-top:10px;
                      height: 120px;"></div>
            <div class="logo_part">
              <img style="width:240px!important; margin-top:40px!important" src="../../../assets//oncoquer1.png" alt=""
                class="logo_three" />
            </div>
          </div>
          <div class="row header mt-1">
            <div>
              <!-- <div class="col-3">
                          <img src="../../../assets/logo.png" alt="" style="width: 140px" />
                        </div> -->
            </div>
            <div></div>
            <div class="col">
              <div class="patient-detailes ml-auto float-right">
                <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
                  <li>
                    Patient Id <br />
                    <span>{{ report.patient?.organisationPatientId }}</span>
                  </li>
                  <li class="ml-4">
                    Patient Name<br />
                    <span>{{ report.patient?.name }}</span>
                  </li>
                </ul>
              </div>
            </div>
            <!--  -->
            <div *ngIf="report.clientType == '4basecare_client'">
              <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
                <div class="logo">
                  <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
                </div>
              </div>
              <div *ngIf="report.clientName == 'path_labs'" class="col-3">
                <div class="logo">
                  <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
                </div>
              </div>
            </div>
          </div>
          <!--  -->
          <div class="mar_top"></div>
          <!--  -->

          <h6 class="py-2 h6_bg" [ngClass]="'yellow'">
            Alterations With Clinically Relevant Therapies
          </h6>
          <!--  -->
          <div class="row">
            <div class="col-12">
              <table class="page_1 cust_table w-100 mt-3 varient_detailsy prognastictable1">
                <thead>
                  <tr>
                    <th>Gene (Alterations) - Drug association</th>
                    <th>Summary</th>
                  </tr>
                </thead>
                <!-- SNV -->

                <tbody>
                  <tr *ngFor="
                                                                                                      let drugsRes of reportPdfRes.drugsResList | slice : 8 : 12;
                                                                                                      let i = index
                                                                                                    ">
                    <td>

                          <i>{{ drugsRes.gene.replace("Fusion","") }}</i><span *ngIf="drugsRes.gene.includes('Fusion')">Fusion</span> {{ drugsRes.pposition?.replace(' ','')}} :
                          <br />
                          <span *ngFor="let drug of drugsRes.drugs; let i = index">
                            {{ drug.name
                            }}<span *ngIf="drugsRes.drugs.length - 1 != i">,</span>
                          </span>
                          - {{ drugsRes.association }}
                          <p>{{ drugsRes.cancerType }}</p>

                    </td>
                    <td>
                      <div [innerHtml]="drugsRes.summary"></div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <!--  -->

          <!--  -->
          <div class="row footer">



          </div>
        </div>

        <!-- page 3_3 -->
        <div id="page3_3" *ngIf="reportPdfRes.drugsResList.length > 12" class="container position-relative page"
          style="background-color: white">
          <div class="d-flex justify-content-between logo_panel py-3">
            <div class="logo_part">
              <img style="width:240px!important" src="../../../assets/hcg-new-logo.jpeg" alt="" class="logo_first" />
            </div>
            <div style=" border-left: 2px solid #fab216;padding-top:10px;
                      height: 120px;"></div>

            <div class="logo_part text-center" style="font-family:arial Mt!important">

              <h5 style="font-weight: bolder;margin-bottom:0px!important;">Triesta Sciences</h5>
              <p style="font-size: 15px!important;">
                A Unit of Health Care Global Enterprises Ltd <br />
                5th Floor, Tower 1, #8, P.Kalinga Rao Road,<br />
                Sampangiram Nagar, Bengaluru-560027. Tel: 080-40206305
              </p>
              <h6 style="font-weight: bolder;font-size:15px!important">Department of Molecular and Clinical Genomics</h6>
            </div>
            <div style=" border-left: 2px solid #fab216;padding-top:10px;
                      height: 120px;"></div>
            <div class="logo_part">
              <img style="width:240px!important; margin-top:40px!important" src="../../../assets//oncoquer1.png" alt=""
                class="logo_three" />
            </div>
          </div>
          <div class="row header mt-1">
            <div>
              <!-- <div class="col-3">
                          <img src="../../../assets/logo.png" alt="" style="width: 140px" />
                        </div> -->
            </div>
            <div></div>
            <div class="col">
              <div class="patient-detailes ml-auto float-right">
                <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
                  <li>
                    Patient Id <br />
                    <span>{{ report.patient?.organisationPatientId }}</span>
                  </li>
                  <li class="ml-4">
                    Patient Name<br />
                    <span>{{ report.patient?.name }}</span>
                  </li>
                </ul>
              </div>
            </div>
            <!--  -->
            <div *ngIf="report.clientType == '4basecare_client'">
              <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
                <div class="logo">
                  <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
                </div>
              </div>
              <div *ngIf="report.clientName == 'path_labs'" class="col-3">
                <div class="logo">
                  <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
                </div>
              </div>
            </div>
          </div>
          <!--  -->
          <div class="mar_top"></div>
          <!--  -->

          <h6 class="py-2 h6_bg" [ngClass]="'yellow'">
            Alterations With Clinically Relevant Therapies
          </h6>
          <!--  -->
          <div class="row">
            <div class="col-12">
              <table class="page_1 cust_table w-100 mt-3 varient_detailsy prognastictable1">
                <thead>
                  <tr>
                    <th>Gene (Alterations) - Drug association</th>
                    <th>Summary</th>
                  </tr>
                </thead>
                <!-- SNV -->

                <tbody>
                  <tr *ngFor="
                                                                                                                            let drugsRes of reportPdfRes.drugsResList | slice : 12 : 16;
                                                                                                                            let i = index
                                                                                                                          ">
                    <td>

                          <i>{{ drugsRes.gene.replace("Fusion","") }}</i><span *ngIf="drugsRes.gene.includes('Fusion')">Fusion</span> {{ drugsRes.pposition?.replace(' ','')}} :
                          <br />
                          <span *ngFor="let drug of drugsRes.drugs; let i = index">
                            {{ drug.name
                            }}<span *ngIf="drugsRes.drugs.length - 1 != i">,</span>
                          </span>
                          - {{ drugsRes.association }}
                          <p>{{ drugsRes.cancerType }}</p>

                    </td>
                    <td>
                      <div [innerHtml]="drugsRes.summary"></div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <!--  -->

          <!--  -->
          <div class="row footer">



          </div>
        </div>

        <!-- page 3_4 -->
        <div id="page3_4" *ngIf="reportPdfRes.drugsResList.length > 16" class="container position-relative page"
          style="background-color: white">
          <div class="d-flex justify-content-between logo_panel py-3">
            <div class="logo_part">
              <img style="width:240px!important" src="../../../assets/hcg-new-logo.jpeg" alt="" class="logo_first" />
            </div>
            <div style=" border-left: 2px solid #fab216;padding-top:10px;
                      height: 120px;"></div>

            <div class="logo_part text-center" style="font-family:arial Mt!important">

              <h5 style="font-weight: bolder;margin-bottom:0px!important;">Triesta Sciences</h5>
              <p style="font-size: 15px!important;">
                A Unit of Health Care Global Enterprises Ltd <br />
                5th Floor, Tower 1, #8, P.Kalinga Rao Road,<br />
                Sampangiram Nagar, Bengaluru-560027. Tel: 080-40206305
              </p>
              <h6 style="font-weight: bolder;font-size:15px!important">Department of Molecular and Clinical Genomics</h6>
            </div>
            <div style=" border-left: 2px solid #fab216;padding-top:10px;
                      height: 120px;"></div>
            <div class="logo_part">
              <img style="width:240px!important; margin-top:40px!important" src="../../../assets//oncoquer1.png" alt=""
                class="logo_three" />
            </div>
          </div>
          <div class="row header mt-1">
            <div>
              <!-- <div class="col-3">
                          <img src="../../../assets/logo.png" alt="" style="width: 140px" />
                        </div> -->
            </div>
            <div></div>
            <div class="col">
              <div class="patient-detailes ml-auto float-right">
                <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
                  <li>
                    Patient Id <br />
                    <span>{{ report.patient?.organisationPatientId }}</span>
                  </li>
                  <li class="ml-4">
                    Patient Name<br />
                    <span>{{ report.patient?.name }}</span>
                  </li>
                </ul>
              </div>
            </div>
            <!--  -->
            <div *ngIf="report.clientType == '4basecare_client'">
              <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
                <div class="logo">
                  <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
                </div>
              </div>
              <div *ngIf="report.clientName == 'path_labs'" class="col-3">
                <div class="logo">
                  <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
                </div>
              </div>
            </div>
          </div>
          <!--  -->
          <div class="mar_top"></div>
          <!--  -->

          <h6 class="py-2 h6_bg" [ngClass]="'yellow'">
            Alterations With Clinically Relevant Therapies
          </h6>
          <!--  -->
          <div class="row">
            <div class="col-12">
              <table class="page_1 cust_table w-100 mt-3 varient_detailsy prognastictable1">
                <thead>
                  <tr>
                    <th>Gene (Alterations) - Drug association</th>
                    <th>Summary</th>
                  </tr>
                </thead>
                <!-- SNV -->

                <tbody>
                  <tr *ngFor="
                                                                                                                  let drugsRes of reportPdfRes.drugsResList | slice : 16 : 20;
                                                                                                                  let i = index
                                                                                                                ">
                    <td>

                          <i>{{ drugsRes.gene.replace("Fusion","") }}</i><span *ngIf="drugsRes.gene.includes('Fusion')">Fusion</span> {{ drugsRes.pposition?.replace(' ','')}} :
                          <br />
                          <span *ngFor="let drug of drugsRes.drugs; let i = index">
                            {{ drug.name
                            }}<span *ngIf="drugsRes.drugs.length - 1 != i">,</span>
                          </span>
                          - {{ drugsRes.association }}
                          <p>{{ drugsRes.cancerType }}</p>

                    </td>
                    <td>
                      <div [innerHtml]="drugsRes.summary"></div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <!--  -->

          <!--  -->
          <div class="row footer">



          </div>
        </div>

        <!-- page 3_5 -->
        <div id="page3_5" *ngIf="reportPdfRes.drugsResList.length > 20" class="container position-relative page"
          style="background-color: white">
          <div class="d-flex justify-content-between logo_panel py-3">
            <div class="logo_part">
              <img style="width:240px!important" src="../../../assets/hcg-new-logo.jpeg" alt="" class="logo_first" />
            </div>
            <div style=" border-left: 2px solid #fab216;padding-top:10px;
                      height: 120px;"></div>

            <div class="logo_part text-center"  style="font-family:arial Mt!important">

              <h5 style="font-weight: bolder;margin-bottom:0px!important;">Triesta Sciences</h5>
              <p style="font-size: 15px!important;">
                A Unit of Health Care Global Enterprises Ltd <br />
                5th Floor, Tower 1, #8, P.Kalinga Rao Road,<br />
                Sampangiram Nagar, Bengaluru-560027. Tel: 080-40206305
              </p>
              <h6 style="font-weight: bolder;font-size:15px!important">Department of Molecular and Clinical Genomics</h6>
            </div>
            <div style=" border-left: 2px solid #fab216;padding-top:10px;
                      height: 120px;"></div>
            <div class="logo_part">
              <img style="width:240px!important; margin-top:40px!important" src="../../../assets//oncoquer1.png" alt=""
                class="logo_three" />
            </div>
          </div>
          <div class="row header mt-1">
            <div>
              <!-- <div class="col-3">
                          <img src="../../../assets/logo.png" alt="" style="width: 140px" />
                        </div> -->
            </div>
            <div></div>
            <div class="col">
              <div class="patient-detailes ml-auto float-right">
                <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
                  <li>
                    Patient Id <br />
                    <span>{{ report.patient?.organisationPatientId }}</span>
                  </li>
                  <li class="ml-4">
                    Patient Name<br />
                    <span>{{ report.patient?.name }}</span>
                  </li>
                </ul>
              </div>
            </div>
            <!--  -->
            <div *ngIf="report.clientType == '4basecare_client'">
              <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
                <div class="logo">
                  <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
                </div>
              </div>
              <div *ngIf="report.clientName == 'path_labs'" class="col-3">
                <div class="logo">
                  <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
                </div>
              </div>
            </div>
          </div>
          <!--  -->
          <div class="mar_top"></div>
          <!--  -->

          <h6 class="py-2 h6_bg" [ngClass]="'yellow'">
            Alterations With Clinically Relevant Therapies
          </h6>
          <!--  -->
          <div class="row">
            <div class="col-12">
              <table class="page_1 cust_table w-100 mt-3 varient_detailsy prognastictable1">
                <thead>
                  <tr>
                    <th>Gene (Alterations) - Drug association</th>
                    <th>Summary</th>
                  </tr>
                </thead>
                <!-- SNV -->

                <tbody>
                  <tr *ngFor="
                              let drugsRes of reportPdfRes.drugsResList | slice : 20 : 24; let i = index ">
                    <td>

                          <i>{{ drugsRes.gene.replace("Fusion","") }}</i><span *ngIf="drugsRes.gene.includes('Fusion')">Fusion</span> {{ drugsRes.pposition?.replace(' ','')}} :
                          <br />
                          <span *ngFor="let drug of drugsRes.drugs; let i = index">
                            {{ drug.name
                            }}<span *ngIf="drugsRes.drugs.length - 1 != i">,</span>
                          </span>
                          - {{ drugsRes.association }}
                          <p>{{ drugsRes.cancerType }}</p>

                    </td>
                    <td>
                      <div [innerHtml]="drugsRes.summary"></div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <!--  -->

          <!--  -->
          <div class="row footer">



          </div>
        </div>

        <!-- page 3_6 -->
        <div id="page3_6" *ngIf="reportPdfRes.drugsResList.length > 24" class="container position-relative page"
          style="background-color: white">
          <div class="d-flex justify-content-between logo_panel py-3">
            <div class="logo_part">
              <img style="width:240px!important" src="../../../assets/hcg-new-logo.jpeg" alt="" class="logo_first" />
            </div>
            <div style=" border-left: 2px solid #fab216;padding-top:10px;
                      height: 120px;"></div>

            <div class="logo_part text-center" style="font-family:arial Mt!important">

              <h5 style="font-weight: bolder;margin-bottom:0px!important;">Triesta Sciences</h5>
              <p style="font-size: 15px!important;">
                A Unit of Health Care Global Enterprises Ltd <br />
                5th Floor, Tower 1, #8, P.Kalinga Rao Road,<br />
                Sampangiram Nagar, Bengaluru-560027. Tel: 080-40206305
              </p>
              <h6 style="font-weight: bolder;font-size:15px!important">Department of Molecular and Clinical Genomics</h6>
            </div>
            <div style=" border-left: 2px solid #fab216;padding-top:10px;
                      height: 120px;"></div>
            <div class="logo_part">
              <img style="width:240px!important; margin-top:40px!important" src="../../../assets//oncoquer1.png" alt=""
                class="logo_three" />
            </div>
          </div>
          <div class="row header mt-1">
            <div>
              <!-- <div class="col-3">
                          <img src="../../../assets/logo.png" alt="" style="width: 140px" />
                        </div> -->
            </div>
            <div></div>
            <div class="col">
              <div class="patient-detailes ml-auto float-right">
                <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
                  <li>
                    Patient Id <br />
                    <span>{{ report.patient?.organisationPatientId }}</span>
                  </li>
                  <li class="ml-4">
                    Patient Name<br />
                    <span>{{ report.patient?.name }}</span>
                  </li>
                </ul>
              </div>
            </div>
            <!--  -->
            <div *ngIf="report.clientType == '4basecare_client'">
              <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
                <div class="logo">
                  <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
                </div>
              </div>
              <div *ngIf="report.clientName == 'path_labs'" class="col-3">
                <div class="logo">
                  <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
                </div>
              </div>
            </div>
          </div>
          <!--  -->
          <div class="mar_top"></div>
          <!--  -->

          <h6 class="py-2 h6_bg" [ngClass]="'yellow'">
            Alterations With Clinically Relevant Therapies
          </h6>
          <!--  -->
          <div class="row">
            <div class="col-12">
              <table class="page_1 cust_table w-100 mt-3 varient_detailsy prognastictable1">
                <thead>
                  <tr>
                    <th>Gene (Alterations) - Drug association</th>
                    <th>Summary</th>
                  </tr>
                </thead>
                <!-- SNV -->

                <tbody>
                  <tr *ngFor=" let drugsRes of reportPdfRes.drugsResList | slice : 24 : 28; let i = index ">
                    <td>

                          <i>{{ drugsRes.gene.replace("Fusion","") }}</i><span *ngIf="drugsRes.gene.includes('Fusion')">Fusion</span> {{ drugsRes.pposition?.replace(' ','')}} :
                          <br />
                          <span *ngFor="let drug of drugsRes.drugs; let i = index">
                            {{ drug.name
                            }}<span *ngIf="drugsRes.drugs.length - 1 != i">,</span>
                          </span>
                          - {{ drugsRes.association }}
                          <p>{{ drugsRes.cancerType }}</p>

                    </td>
                    <td>
                      <div [innerHtml]="drugsRes.summary"></div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <!--  -->

          <!--  -->
          <div class="row footer">



          </div>
        </div>

        <!-- page 3_7 -->
        <div id="page3_7" *ngIf="reportPdfRes.drugsResList.length > 28" class="container position-relative page"
          style="background-color: white">
          <div class="d-flex justify-content-between logo_panel py-3">
            <div class="logo_part">
              <img style="width:240px!important" src="../../../assets/hcg-new-logo.jpeg" alt="" class="logo_first" />
            </div>
            <div style=" border-left: 2px solid #fab216;padding-top:10px;
                      height: 120px;"></div>

            <div class="logo_part text-center" style="font-family:arial Mt!important">

              <h5 style="font-weight: bolder;margin-bottom:0px!important;">Triesta Sciences</h5>
              <p style="font-size: 15px!important;">
                A Unit of Health Care Global Enterprises Ltd <br />
                5th Floor, Tower 1, #8, P.Kalinga Rao Road,<br />
                Sampangiram Nagar, Bengaluru-560027. Tel: 080-40206305
              </p>
              <h6 style="font-weight: bolder;font-size:15px!important">Department of Molecular and Clinical Genomics</h6>
            </div>
            <div style=" border-left: 2px solid #fab216;padding-top:10px;
                      height: 120px;"></div>
            <div class="logo_part">
              <img style="width:240px!important; margin-top:40px!important" src="../../../assets//oncoquer1.png" alt=""
                class="logo_three" />
            </div>
          </div>
          <div class="row header mt-1">
            <div>
              <!-- <div class="col-3">
                          <img src="../../../assets/logo.png" alt="" style="width: 140px" />
                        </div> -->
            </div>
            <div></div>
            <div class="col">
              <div class="patient-detailes ml-auto float-right">
                <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
                  <li>
                    Patient Id <br />
                    <span>{{ report.patient?.organisationPatientId }}</span>
                  </li>
                  <li class="ml-4">
                    Patient Name<br />
                    <span>{{ report.patient?.name }}</span>
                  </li>
                </ul>
              </div>
            </div>
            <!--  -->
            <div *ngIf="report.clientType == '4basecare_client'">
              <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
                <div class="logo">
                  <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
                </div>
              </div>
              <div *ngIf="report.clientName == 'path_labs'" class="col-3">
                <div class="logo">
                  <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
                </div>
              </div>
            </div>
          </div>
          <!--  -->
          <div class="mar_top"></div>
          <!--  -->

          <h6 class="py-2 h6_bg" [ngClass]="'yellow'">
            Alterations With Clinically Relevant Therapies
          </h6>
          <!--  -->
          <div class="row">
            <div class="col-12">
              <table class="page_1 cust_table w-100 mt-3 varient_detailsy prognastictable1">
                <thead>
                  <tr>
                    <th>Gene (Alterations) - Drug association</th>
                    <th>Summary</th>
                  </tr>
                </thead>
                <!-- SNV -->

                <tbody>
                  <tr
                    *ngFor="
                                                                                                                                                                                  let drugsRes of reportPdfRes.drugsResList | slice : 28 : 32;
                                                                                                                                                                                  let i = index
                                                                                                                                                                                ">
                    <td>

                          <i>{{ drugsRes.gene.replace("Fusion","") }}</i><span *ngIf="drugsRes.gene.includes('Fusion')">Fusion</span> {{ drugsRes.pposition?.replace(' ','')}} :
                          <br />
                          <span *ngFor="let drug of drugsRes.drugs; let i = index">
                            {{ drug.name
                            }}<span *ngIf="drugsRes.drugs.length - 1 != i">,</span>
                          </span>
                          - {{ drugsRes.association }}
                          <p>{{ drugsRes.cancerType }}</p>

                    </td>
                    <td>
                      <div [innerHtml]="drugsRes.summary"></div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <!--  -->

          <!--  -->
          <div class="row footer">



          </div>
        </div>

        <!-- page 3_8 -->
        <div id="page3_8" *ngIf="reportPdfRes.drugsResList.length > 32" class="container position-relative page"
          style="background-color: white">
          <div class="d-flex justify-content-between logo_panel py-3">
            <div class="logo_part">
              <img style="width:240px!important" src="../../../assets/hcg-new-logo.jpeg" alt="" class="logo_first" />
            </div>
            <div style=" border-left: 2px solid #fab216;padding-top:10px;
                      height: 120px;"></div>

            <div class="logo_part text-center" style="font-family:arial Mt!important">

              <h5 style="font-weight: bolder;margin-bottom:0px!important;">Triesta Sciences</h5>
              <p style="font-size: 15px!important;">
                A Unit of Health Care Global Enterprises Ltd <br />
                5th Floor, Tower 1, #8, P.Kalinga Rao Road,<br />
                Sampangiram Nagar, Bengaluru-560027. Tel: 080-40206305
              </p>
              <h6 style="font-weight: bolder;font-size:15px!important">Department of Molecular and Clinical Genomics</h6>
            </div>
            <div style=" border-left: 2px solid #fab216;padding-top:10px;
                      height: 120px;"></div>
            <div class="logo_part">
              <img style="width:240px!important; margin-top:40px!important" src="../../../assets//oncoquer1.png" alt=""
                class="logo_three" />
            </div>
          </div>
          <div class="row header mt-1">
            <div>
              <!-- <div class="col-3">
                          <img src="../../../assets/logo.png" alt="" style="width: 140px" />
                        </div> -->
            </div>
            <div></div>
            <div class="col">
              <div class="patient-detailes ml-auto float-right">
                <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
                  <li>
                    Patient Id <br />
                    <span>{{ report.patient?.organisationPatientId }}</span>
                  </li>
                  <li class="ml-4">
                    Patient Name<br />
                    <span>{{ report.patient?.name }}</span>
                  </li>
                </ul>
              </div>
            </div>
            <!--  -->
            <div *ngIf="report.clientType == '4basecare_client'">
              <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
                <div class="logo">
                  <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
                </div>
              </div>
              <div *ngIf="report.clientName == 'path_labs'" class="col-3">
                <div class="logo">
                  <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
                </div>
              </div>
            </div>
          </div>
          <!--  -->
          <div class="mar_top"></div>
          <!--  -->

          <h6 class="py-2 h6_bg" [ngClass]="'yellow'">
            Alterations With Clinically Relevant Therapies
          </h6>
          <!--  -->
          <div class="row">
            <div class="col-12">
              <table class="page_1 cust_table w-100 mt-3 varient_detailsy prognastictable1">
                <thead>
                  <tr>
                    <th>Gene (Alterations) - Drug association</th>
                    <th>Summary</th>
                  </tr>
                </thead>
                <!-- SNV -->

                <tbody>
                  <tr *ngFor=" let drugsRes of reportPdfRes.drugsResList | slice : 32 : 36; let i = index ">
                    <td>

                          <i>{{ drugsRes.gene.replace("Fusion","") }}</i><span *ngIf="drugsRes.gene.includes('Fusion')">Fusion</span> {{ drugsRes.pposition?.replace(' ','')}} :
                          <br />
                          <span *ngFor="let drug of drugsRes.drugs; let i = index">
                            {{ drug.name
                            }}<span *ngIf="drugsRes.drugs.length - 1 != i">,</span>
                          </span>
                          - {{ drugsRes.association }}
                          <p>{{ drugsRes.cancerType }}</p>

                    </td>
                    <td>
                      <div [innerHtml]="drugsRes.summary"></div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <!--  -->

          <!--  -->
          <div class="row footer">



          </div>
        </div>

        <!-- page 3_9 -->
        <div id="page3_9" *ngIf="reportPdfRes.drugsResList.length > 36" class="container position-relative page"
          style="background-color: white">
          <div class="d-flex justify-content-between logo_panel py-3">
            <div class="logo_part">
              <img style="width:240px!important" src="../../../assets/hcg-new-logo.jpeg" alt="" class="logo_first" />
            </div>
            <div style=" border-left: 2px solid #fab216;padding-top:10px;
                                height: 120px;"></div>

            <div class="logo_part text-center" style="font-family:arial Mt!important">

              <h5 style="font-weight: bolder;margin-bottom:0px!important;">Triesta Sciences</h5>
              <p style="font-size: 15px!important;">
                A Unit of Health Care Global Enterprises Ltd <br />
                5th Floor, Tower 1, #8, P.Kalinga Rao Road,<br />
                Sampangiram Nagar, Bengaluru-560027. Tel: 080-40206305
              </p>
              <h6 style="font-weight: bolder;font-size:15px!important">Department of Molecular and Clinical Genomics</h6>
            </div>
            <div style=" border-left: 2px solid #fab216;padding-top:10px;
                                height: 120px;"></div>
            <div class="logo_part">
              <img style="width:240px!important; margin-top:40px!important" src="../../../assets//oncoquer1.png" alt=""
                class="logo_three" />
            </div>
          </div>
          <div class="row header mt-1">
            <div>
              <!-- <div class="col-3">
                                    <img src="../../../assets/logo.png" alt="" style="width: 140px" />
                                  </div> -->
            </div>
            <div></div>
            <div class="col">
              <div class="patient-detailes ml-auto float-right">
                <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
                  <li>
                    Patient Id <br />
                    <span>{{ report.patient?.organisationPatientId }}</span>
                  </li>
                  <li class="ml-4">
                    Patient Name<br />
                    <span>{{ report.patient?.name }}</span>
                  </li>
                </ul>
              </div>
            </div>
            <!--  -->
            <div *ngIf="report.clientType == '4basecare_client'">
              <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
                <div class="logo">
                  <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
                </div>
              </div>
              <div *ngIf="report.clientName == 'path_labs'" class="col-3">
                <div class="logo">
                  <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
                </div>
              </div>
            </div>
          </div>
          <!--  -->
          <div class="mar_top"></div>
          <!--  -->

          <h6 class="py-2 h6_bg" [ngClass]="'yellow'">
            Alterations With Clinically Relevant Therapies
          </h6>
          <!--  -->
          <div class="row">
            <div class="col-12">
              <table class="page_1 cust_table w-100 mt-3 varient_detailsy prognastictable1">
                <thead>
                  <tr>
                    <th>Gene (Alterations) - Drug association</th>
                    <th>Summary</th>
                  </tr>
                </thead>
                <!-- SNV -->

                <tbody>
                  <tr *ngFor=" let drugsRes of reportPdfRes.drugsResList | slice : 36 : 40; let i = index ">
                    <td>

                          <i>{{ drugsRes.gene.replace("Fusion","") }}</i><span *ngIf="drugsRes.gene.includes('Fusion')">Fusion</span> {{ drugsRes.pposition?.replace(' ','')}} :
                          <br />
                          <span *ngFor="let drug of drugsRes.drugs; let i = index">
                            {{ drug.name
                            }}<span *ngIf="drugsRes.drugs.length - 1 != i">,</span>
                          </span>
                          - {{ drugsRes.association }}
                          <p>{{ drugsRes.cancerType }}</p>

                    </td>
                    <td>
                      <div [innerHtml]="drugsRes.summary"></div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <!--  -->

          <!--  -->
          <div class="row footer">



          </div>
        </div>

          <!-- page 4 -->
          <div id="page4" *ngIf="prognasticCount>0" class="container position-relative page" style="background-color: white">
              <!-- Header -->
            <div class="d-flex justify-content-between logo_panel py-3">
              <div class="logo_part">
                <img
                style="width:240px!important" src="../../../assets/hcg-new-logo.jpeg"
                  alt=""
                  class="logo_first"
                />
              </div>
              <div style=" border-left: 2px solid #fab216;padding-top:10px;
              height: 120px;"></div>

              <div class="logo_part text-center" style="font-family:arial Mt!important">

                                <h5 style="font-weight: bolder;margin-bottom:0px!important;">Triesta Sciences</h5>
                <p style="font-size: 15px!important;">
                                  A Unit of Health Care Global Enterprises Ltd <br/>
                                  5th Floor, Tower 1, #8, P.Kalinga Rao Road,<br/>
                                  Sampangiram Nagar, Bengaluru-560027. Tel: 080-40206305
                                </p>
                <h6 style="font-weight: bolder;font-size:15px!important">Department of Molecular and Clinical Genomics</h6>
              </div>
              <div style=" border-left: 2px solid #fab216;padding-top:10px;
              height: 120px;"></div>
              <div class="logo_part">
                <img  style="width:240px!important; margin-top:40px!important"
                  src="../../../assets//oncoquer1.png"
                  alt=""
                  class="logo_three"
                />
              </div>
            </div>
              <div class="row header mt-1">
              <div></div>
              <div class="col">
                <div class="patient-detailes ml-auto float-right">
                  <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
                    <li>
                      Patient Id <br />
                      <span>{{ report.patient?.organisationPatientId }}</span>
                    </li>
                    <li class="ml-4">
                      Patient Name<br />
                      <span>{{ report.patient?.name }}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <!--  -->
              <div *ngIf="report.clientType == '4basecare_client'">
                <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
                  </div>
                </div>
                <div *ngIf="report.clientName == 'path_labs'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
                  </div>
                </div>
              </div>
            </div>
            <!--  -->
            <div class="mar_top"></div>
            <!--  -->
            <div *ngIf="prognasticCount>0">
              <div>
                <h6 class="py-2 h6_bg" [ngClass]="'yellow'"> Alterations associated with prognostic significance</h6>

              </div>

              <div class="row">
                <div class="col-12">
                  <table class="page_1 cust_table w-100 mt-3 varient_detailsy prognastictable" [ngClass]="'table_variant-blue'">
                    <thead>
                      <tr>
                        <th>Gene (Alterations)</th>
                        <th>Prognostic Significance</th>
                        <th>Summary</th>

                      </tr>
                    </thead>

                    <ng-container *ngFor=" let prog of reportPdfRes.prognosticAlterations ">
                      <tr>
                        <td style="padding-left: 3px !important">
                          <i>{{ prog.gene }}&nbsp;</i> <span [innerHtml]="prog.pOrcDot"></span>
                        </td>
                        <td style="padding-left: 3px !important">
                          {{prog.prognosticSignificance}}
                        </td>
                        <td style="padding-left: 3px !important">
                          <span [innerHtml]="prog.description"></span>
                        </td>

                      </tr>
                    </ng-container>



                  </table>
                </div>
              </div>
            </div>


            <!--  -->
            <div class="row footer d-flex justify-content-between align-items-center">
              <!-- <div class="col">
                <div class="py-2">
                  <p>
                    <span class="font-weight-bold">Genomics Bihar,</span>
                  </p>
                  <p>Homi Bhabha cancer hospital & Research centre</p>
                  <p>Inside Srikrishna Medical College & Hospital</p>
                  <p>Umanagar, Muzaffarpur-842004.</p>
                  <p>Contact: ngs.mfp@gmail.com</p>
                </div>
              </div>
              <div class="col">
                <p class="text-right">
                  <img
                    src="../../../assets/tmc/tmc_footer.png"
                    alt="footer_image"
                    class="footer_img"
                  />
                </p>
              </div> -->
            </div>
          </div>

          <!-- Page 5 -->
          <div id="page5" class="container position-relative page" style="background-color: white">
              <!-- Header -->
            <div class="d-flex justify-content-between logo_panel py-3">
              <div class="logo_part">
                <img
                style="width:240px!important" src="../../../assets/hcg-new-logo.jpeg"
                  alt=""
                  class="logo_first"
                />
              </div>
              <div style=" border-left: 2px solid #fab216;padding-top:10px;
              height: 120px;"></div>

              <div class="logo_part text-center" style="font-family:arial Mt!important">

                                <h5 style="font-weight: bolder;margin-bottom:0px!important;">Triesta Sciences</h5>
                <p style="font-size: 15px!important;">
                                  A Unit of Health Care Global Enterprises Ltd <br/>
                                  5th Floor, Tower 1, #8, P.Kalinga Rao Road,<br/>
                                  Sampangiram Nagar, Bengaluru-560027. Tel: 080-40206305
                                </p>
                <h6 style="font-weight: bolder;font-size:15px!important">Department of Molecular and Clinical Genomics</h6>
              </div>
              <div style=" border-left: 2px solid #fab216;padding-top:10px;
              height: 120px;"></div>
              <div class="logo_part">
                <img  style="width:240px!important; margin-top:40px!important"
                  src="../../../assets//oncoquer1.png"
                  alt=""
                  class="logo_three"
                />
              </div>
            </div>
              <div class="row header mt-1">
              <div></div>
              <div class="col">
                <div class="patient-detailes ml-auto float-right">
                  <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
                    <li>
                      Patient Id <br />
                      <span>{{ report.patient?.organisationPatientId }}</span>
                    </li>
                    <li class="ml-4">
                      Patient Name<br />
                      <span>{{ report.patient?.name }}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <!--  -->
              <div *ngIf="report.clientType == '4basecare_client'">
                <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
                  </div>
                </div>
                <div *ngIf="report.clientName == 'path_labs'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
                  </div>
                </div>
              </div>
            </div>
            <!--  -->
            <div class="mar_top"></div>
            <!--  -->

            <h6 class="py-2 h6_bg" [ngClass]="'yellow'">References</h6>
            <!--  -->
            <div class="row">
              <div class="col">
                <div class="disc">
                  <div class="ref_data mt-4">
                    <ul class="break-word" style="text-align: justify">
                      <li *ngFor="let referenceArticle of reportPdfRes.referenceArticles" style="
                                                                  text-align: justify;
                                                                  /* white-space:pre-wrap; */
                                                                  word-break: break-word;
                                                                ">
                        {{ referenceArticle }}
                      </li>
                    </ul>
                  </div>
                </div>

              </div>
            </div>

            <div class="row pr-2 above-footer">
              <div class="col-4">
                <!-- <img
                  src="../../../assets/HCG/vijay.png"
                  alt=""
                  style="padding-top:20px;margin-left:20px;padding-left: 60px; padding-right: 60px; width: 90%"
                  class="mb-2"
                /> -->
                <span style="padding-left: 126px; font-weight: bolder"
                  >Dr. Vijay.K, PhD<br /><span
                    style="padding-left: 63px; font-weight: bolder"
                    >Senior Scientist I-Clinical Diagnostics</span
                  ></span
                >
              </div>
              <div class="col-4">
                <!-- <img
                  src="../../../assets/HCG/gautam.png"
                  alt=""
                  style="magin-left:20px;padding-left: 60px; padding-right: 60px; width: 90%"
                  class="mb-2"
                /> -->
                <span style="padding-left: 67px; font-weight: bolder"
                  >Dr. Gautam Balaram, MBBS, MD<br /><span
                    style="padding-left: 100px; font-weight: bolder"
                    >Clinical Pathologist</span
                  ></span
                >
              </div>
              <div class="col-4">
                <!-- <img
                  src="../../../assets/HCG/mithua.png"
                  alt=""
                  style="padding-top:30px;padding-left: 60px; padding-right: 60px; width: 100%"
                  class="mb-2"
                /> -->
                <span style="padding-left: 97px; font-weight: bolder"
                  >Dr. Mithua Ghosh, PhD, PD<br /><span
                    style="padding-left: 90px; font-weight: bolder"
                    >Director-Clinical Diagnostics</span
                  ></span
                >
              </div>

            </div>
            <!--  -->
            <div class="row footer d-flex justify-content-between align-items-center">
              <!-- <div class="col">
                <div class="py-2">
                  <p>
                    <span class="font-weight-bold">Genomics Bihar,</span>
                  </p>
                  <p>Homi Bhabha cancer hospital & Research centre</p>
                  <p>Inside Srikrishna Medical College & Hospital</p>
                  <p>Umanagar, Muzaffarpur-842004.</p>
                  <p>Contact: ngs.mfp@gmail.com</p>
                </div>
              </div>
              <div class="col">
                <p class="text-right">
                  <img
                    src="../../../assets/tmc/tmc_footer.png"
                    alt="footer_image"
                    class="footer_img"
                  />
                </p>
              </div> -->
            </div>
          </div>

          <!-- Page 6 -->
          <div id="page6" class="container position-relative page" style="background-color: white">
              <!-- Header -->
            <div class="d-flex justify-content-between logo_panel py-3">
              <div class="logo_part">
                <img
                style="width:240px!important" src="../../../assets/hcg-new-logo.jpeg"
                  alt=""
                  class="logo_first"
                />
              </div>
              <div style=" border-left: 2px solid #fab216;padding-top:10px;
              height: 120px;"></div>

              <div class="logo_part text-center" style="font-family:arial Mt!important">

                                <h5 style="font-weight: bolder;margin-bottom:0px!important;">Triesta Sciences</h5>
                <p style="font-size: 15px!important;">
                                  A Unit of Health Care Global Enterprises Ltd <br/>
                                  5th Floor, Tower 1, #8, P.Kalinga Rao Road,<br/>
                                  Sampangiram Nagar, Bengaluru-560027. Tel: 080-40206305
                                </p>
                <h6 style="font-weight: bolder;font-size:15px!important">Department of Molecular and Clinical Genomics</h6>
              </div>
              <div style=" border-left: 2px solid #fab216;padding-top:10px;
              height: 120px;"></div>
              <div class="logo_part">
                <img  style="width:240px!important; margin-top:40px!important"
                  src="../../../assets//oncoquer1.png"
                  alt=""
                  class="logo_three"
                />
              </div>
            </div>
              <div class="row header mt-1">
              <div></div>
              <div class="col">
                <div class="patient-detailes ml-auto float-right">
                  <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
                    <li>
                      Patient Id <br />
                      <span>{{ report.patient?.organisationPatientId }}</span>
                    </li>
                    <li class="ml-4">
                      Patient Name<br />
                      <span>{{ report.patient?.name }}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <!--  -->
              <div *ngIf="report.clientType == '4basecare_client'">
                <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
                  </div>
                </div>
                <div *ngIf="report.clientName == 'path_labs'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
                  </div>
                </div>
              </div>
            </div>
            <!--  -->
            <div class="mar_top"></div>
            <!--  -->

            <div class="">
              <h6 class="py-2 h6_bg" [ngClass]="'yellow'">TEST DESCRIPTION</h6>
              <p class="mb-2" style="text-align:justify">
                <span class="clr-cyan pl-2" [ngClass]="'txt-yellow'">OncQuer<sup>TM</sup> Assist Panel</span>
                is a Next Generation Sequencing based test which identifies
                genetic alterations in a comprehensive panel of well curated 72  genes which are having an impact response to approved
                therapy for a particular cancer type. Some of the alterations
                detected may have bearing on prognosis and/or therapeutic options
                and may provide relevant information that allows
                oncologists/clinicians to consider various lines of
                targeted treatment for the patient.
              </p>
            </div>

            <!--  -->
            <div class="row">
              <div class="col">
                <div class="">
                  <h6 class="py-2 h6_bg" [ngClass]="'yellow'">GENES EVALUATED</h6>
                  <p>
                    <span class="clr-cyan pl-2" [ngClass]="'txt-yellow'">OncQuer<sup>TM</sup> Assist Panel</span>
                    detects mutations (SNVs and Short InDels), Copy Number Variations (CNVs),
                    Gene Fusions and splice variants in the 72 genes:
                    <!-- detects mutations (SNVs and Short Indels), Copy Number Variations
                                                          (CNVs), gene fusions and splice variants in the 53 genes : -->
                  </p>
                </div>
                <!--  -->
                <div class="row mx-0">
                  <div class="col-12 px-0">
                    <div class="genes_info">
                      <div class="title py-2">
                        <h6 class="mb-0 d-inline-block border-bottom py-2">
                          <b>SNVs, SHORT INDELS and CNVs
                          </b> Covered in
                          <span class="clr-cyan pl-2" [ngClass]="'txt-yellow'">OncQuer<sup>TM</sup> Assist Panel</span>
                        </h6>
                      </div>
                      <div class="genes_body" [ngClass]="'gene-bg_blue'">
                        <i>
                          <div class="genes_list">
                            <span>ABL1</span><span>ALK</span><span>APC</span>
                            <span>AR</span><span>ATM</span><span>BARD1</span>
                            <span>BMPR1A</span><span>BRAF</span><span>BRCA1</span>
                            <span>BRCA2</span>
                            <!-- <span>ABL1</span><span>ALK</span><span>AR</span
                                                                  ><span>ATM</span><span>BARD1</span><span>BRAF</span
                                                                  ><span>BRCA1</span><span>BRCA2</span><span>BRIP1</span
                                                                  ><span>CDK12</span> -->
                          </div>
                          <div class="genes_list bg-white">
                            <span>BRIP1</span><span>CDK12</span><span>CDK4</span>
                            <span>CDK6</span><span>CDKN2A</span><span>CHEK1</span>
                            <span>CHEK2</span><span>CTNNB1</span><span>EGFR</span>
                            <span>EPCAM</span>
                            <!-- <span>CDK4</span><span>CDK6</span><span>CDKN2A</span
                                                                  ><span>CHEK1</span><span>CHEK2</span><span>EGFR</span
                                                                  ><span>EPCAM</span><span>ERBB2</span><span>ERBB3</span
                                                                  ><span>EZH2</span> -->
                          </div>
                          <div class="genes_list">
                            <span>ERBB2</span><span>ERBB3</span><span>EZH2</span>
                            <span>FANCL</span><span>FGFR1</span><span>FGFR2</span>
                            <span>FGFR3</span><span>GAPDH</span><span>IDH1</span>
                            <span>IDH2</span>
                            <!-- <span>FANCL</span><span>FGFR3</span><span>GAPDH</span
                                                                  ><span>IDH1</span><span>IDH2</span><span>JAK2</span
                                                                  ><span>KIT</span><span>KRAS</span><span>MAP2K1</span
                                                                  ><span>MAP2K2</span> -->
                          </div>
                          <div class="genes_list">
                            <span>JAK2</span><span>KIT</span><span>KRAS</span>
                            <span>MAP2K1</span><span>MAP2K2</span><span>MDM2</span>
                            <span>MET</span><span>MLH1</span><span>MLH3</span>
                            <span>MSH2</span>
                            <!-- <span>MDM2</span><span>MET</span><span>MLH1</span
                                                                  ><span>MLH3</span><span>MSH2</span><span>MSH6</span
                                                                  ><span>NRAS</span><span>PALB2</span><span>PDGFRA</span
                                                                  ><span>PDGFRB</span> -->
                          </div>
                          <div class="genes_list">
                            <span>MSH6</span><span>MUTYH</span><span>NRAS</span>
                            <span>PALB2</span><span>PDGFRA</span><span>PDGFRB</span>
                            <span>PIK3CA</span><span>PMS1</span><span>PMS2</span>
                            <span>POLD1</span>
                            <!-- <span>PIK3CA</span><span>PMS1</span><span>PMS2</span
                                                                  ><span>PPP2R2A</span><span>RAD51B</span><span>RAD51C</span
                                                                  ><span>RAD51D</span><span>RAD54L</span><span>RET</span
                                                                  ><span>ROS1</span> -->
                          </div>
                          <div class="genes_list">
                            <span>POLE</span><span>POLH</span><span>PTEN</span>
                            <span>RAD50</span><span>RAD51</span><span>RAD51B</span>
                            <span>RAD51C</span><span>RAD51D</span><span>RAD54L</span>
                            <span>RB1</span>
                            <!-- <span>STK11</span><span>TSC1</span><span>TSC2</span> -->
                          </div>
                          <div class="genes_list">
                            <span>RET</span><span>ROS1</span><span>SMAD4</span>
                            <span>STK11</span><span>TP53</span><span>TSC1</span>
                            <span>TSC2</span>
                            <!-- <span>STK11</span><span>TSC1</span><span>TSC2</span> -->
                          </div>
                        </i>
                      </div>

                      <!-- <div class="title py-2 mt-4">
                                                              <h6 class="mb-0 d-inline-block border-bottom py-2">
                                                                <b>CNAs</b> Covered in
                                                                <span class="font-weight-normal">TAR</span><b>GT</b>
                                                                <span class="clr-cyan pl-2" [ngClass]="'txt-yellow'"
                                                                  >First</span
                                                                >
                                                              </h6>
                                                            </div> -->
                      <!-- <div class="gene_body" [ngClass]="'gene-bg_blue'">
                                                              <i>
                                                                <div class="genes_list">
                                                                  <span>ABL1</span><span>AR</span><span>ATM</span
                                                                  ><span>BARD1</span><span>BRCA1</span><span>BRCA2</span
                                                                  ><span>BRIP1</span><span>CDK12</span><span>CDK4</span
                                                                  ><span>CDK6</span>
                                                                </div>
                                                                <div class="genes_list">
                                                                  <span>CDKN2A</span><span>CHEK1</span><span>CHEK2</span
                                                                  ><span>EGFR</span><span>EPCAM</span><span>ERBB2</span
                                                                  ><span>ERBB3</span><span>EZH2</span><span>FANCL</span
                                                                  ><span>FGFR3</span>
                                                                </div>
                                                                <div class="genes_list">
                                                                  <span>GAPDH</span><span>IDH2</span><span>JAK2</span
                                                                  ><span>KIT</span><span>MDM2</span><span>NRAS</span
                                                                  ><span>PALB2</span><span>PDGFRA</span><span>PDGFRB</span
                                                                  ><span>PPP2R2A</span>
                                                                </div>
                                                                <div class="genes_list">
                                                                  <span>RAD51B</span><span>RAD51C</span><span>RAD51D</span
                                                                  ><span>RAD54L</span><span>STK11</span><span>TSC1</span
                                                                  ><span>TSC2</span>
                                                                </div>
                                                              </i>
                                                            </div> -->
                    </div>
                  </div>
                  <!-- cnv_genes -->

            <!-- <div class="col-12 px-0">
              <div class="genes_info">
                <div class="title py-2">
                  <h6 class="mb-0 d-inline-block border-bottom py-2">
                    <b> CNVs
                    </b> Covered in
                    <span class="clr-cyan pl-2" [ngClass]="'txt-yellow'">OncQuer<sup>TM</sup> Assist Panel</span>
                  </h6>
                </div>
                <div class="genes_body" [ngClass]="'gene-bg_blue'">
                  <i>
                    <div class="genes_list">
                      <span>ABL1</span><span>ALK</span><span>APC</span>
                      <span>AR</span><span>ATM</span><span>BARD1</span>
                      <span>BMPR1A</span><span>BRAF</span><span>BRCA1</span>
                      <span>BRCA2</span>
                    </div>
                    <div class="genes_list bg-white">
                      <span>BRIP1</span><span>CDK12</span><span>CDK4</span>
                      <span>CDK6</span><span>CDKN2A</span><span>CHEK1</span>
                      <span>CHEK2</span><span>CTNNB1</span><span>EGFR</span>
                      <span>EPCAM</span>
                    </div>
                    <div class="genes_list">
                      <span>ERBB2</span><span>ERBB3</span><span>EZH2</span>
                      <span>FANCL</span><span>FGFR1</span><span>FGFR2</span>
                      <span>FGFR3</span><span>GAPDH</span><span>IDH2</span>
                      <span>JAK2</span>
                    </div>
                    <div class="genes_list">
                     <span>KIT</span><span>MDM2</span>
                      <span>MET</span><span>MLH1</span><span>MUTYH</span>
                      <span>NRAS</span><span>PALB2</span><span>PDGFRA</span>
                      <span>PDGFRAB</span><span>POLD1</span>
                    </div>

                    <div class="genes_list">
                      <span>POLE</span><span>POLH</span><span>PTEN</span>
                      <span>RAD50</span><span>RAD51</span><span>RAD51B</span>
                      <span>RAD51C</span><span>RAD51D</span><span>RAD54L</span>
                      <span>RB1</span>
                    </div>
                    <div class="genes_list">
                      <span>RET</span><span>ROS1</span><span>SMAD4</span>
                      <span>STK11</span><span>TP53</span><span>TSC1</span>
                      <span>TSC2</span>
                    </div>
                  </i>
                </div>


              </div>
            </div> -->




                  <div class="col-12 px-0">
                    <div class="genes_info">
                      <div class="title py-2">
                        <h6 class="mb-0 d-inline-block border-bottom py-2">
                          <b>Gene Fusions/Splicing Variations</b> Covered in
                          <span class="clr-cyan pl-2" [ngClass]="'txt-yellow'">OncQuer<sup>TM</sup> Assist Panel</span>
                        </h6>
                      </div>
                      <div class="genes_body" [ngClass]="'gene-bg_blue'">
                        <i>
                          <div class="genes_list">
                          <span>ALK</span><span>FGFR2</span><span>FGFR3</span>
                          <span>MET</span><span>NRG1</span><span>NRG2</span>
                          <span>NTRK1</span><span>NTRK2</span> <span>NTRK3</span><span>RET</span>

                          </div>
                          <div class="genes_list">
                            <span>ROS1</span>
                          </div>
                        </i>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>

            <!--  -->
            <div class="row footer d-flex justify-content-between align-items-center">
              <!-- <div class="col">
                <div class="py-2">
                  <p>
                    <span class="font-weight-bold">Genomics Bihar,</span>
                  </p>
                  <p>Homi Bhabha cancer hospital & Research centre</p>
                  <p>Inside Srikrishna Medical College & Hospital</p>
                  <p>Umanagar, Muzaffarpur-842004.</p>
                  <p>Contact: ngs.mfp@gmail.com</p>
                </div>
              </div>
              <div class="col">
                <p class="text-right">
                  <img
                    src="../../../assets/tmc/tmc_footer.png"
                    alt="footer_image"
                    class="footer_img"
                  />
                </p>
              </div> -->
            </div>
          </div>

          <!-- Page 7 -->
          <div id="page7" class="container position-relative page" style="background-color: white">
              <!-- Header -->
            <div class="d-flex justify-content-between logo_panel py-3">
              <div class="logo_part">
                <img
                style="width:240px!important" src="../../../assets/hcg-new-logo.jpeg"
                  alt=""
                  class="logo_first"
                />
              </div>
              <div style=" border-left: 2px solid #fab216;padding-top:10px;
              height: 120px;"></div>

              <div class="logo_part text-center" style="font-family:arial Mt!important">

                                <h5 style="font-weight: bolder;margin-bottom:0px!important;">Triesta Sciences</h5>
                <p style="font-size: 15px!important;">
                                  A Unit of Health Care Global Enterprises Ltd <br/>
                                  5th Floor, Tower 1, #8, P.Kalinga Rao Road,<br/>
                                  Sampangiram Nagar, Bengaluru-560027. Tel: 080-40206305
                                </p>
                <h6 style="font-weight: bolder;font-size:15px!important">Department of Molecular and Clinical Genomics</h6>
              </div>
              <div style=" border-left: 2px solid #fab216;padding-top:10px;
              height: 120px;"></div>
              <div class="logo_part">
                <img  style="width:240px!important; margin-top:40px!important"
                  src="../../../assets//oncoquer1.png"
                  alt=""
                  class="logo_three"
                />
              </div>
            </div>
              <div class="row header mt-1">
              <div></div>
              <div class="col">
                <div class="patient-detailes ml-auto float-right">
                  <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
                    <li>
                      Patient Id <br />
                      <span>{{ report.patient?.organisationPatientId }}</span>
                    </li>
                    <li class="ml-4">
                      Patient Name<br />
                      <span>{{ report.patient?.name }}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <!--  -->
              <div *ngIf="report.clientType == '4basecare_client'">
                <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
                  </div>
                </div>
                <div *ngIf="report.clientName == 'path_labs'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
                  </div>
                </div>
              </div>
            </div>
            <!--  -->
            <div class="mar_top"></div>
            <!--  -->
            <h6 class="py-2 h6_bg mt-3" [ngClass]="'yellow'">TEST METHODOLOGY</h6>
            <!--  -->
            <div class="row mt-3">
              <div class="col">
                <h6 class="font-weight-bold">
                  Sample preparation and Library preparation :
                </h6>
                <!-- <p>
                                                DNA isolated from FFPE, or any other fresh tumor tissue source was
                                                used to perform targeted gene capture using a custom capture kit.
                                                The libraries were sequenced to mean >350X coverage on Illumina
                                                sequencing platform.
                                              </p> -->
                <p style="text-align: justify;">
                  DNA isolated from FFPE, or any other fresh tumor tissue source was used to perform targeted
                  gene captured in OncQuer<sup>TM</sup> Assist Panel using a TARGT First kit from 4baseCare.
                  The libraries were sequenced to mean>350X coverage on Illumina sequencing platform.
                </p>
              </div>
            </div>
            <!--  -->
            <div class="row mt-4">
              <div class="col">
                <h6 class="font-weight-bold">
                  Bioinformatics Analysis and Reporting :
                </h6>
                <!-- <p>
                                                The sequences obtained are aligned to human reference genome
                                                (GRCh37/hg19) and variant analysis was performed using set of
                                                Bioinformatics Pipeline. Only non-synonymous and splice site
                                                variants found in the panel consisting of specific set of genes were
                                                used for clinical interpretation. Silent variations that do not
                                                result in any change in amino acid in the coding region are not
                                                reported. Clinically relevant mutations were annotated using
                                                published variants in literature and a set of databases – ClinVar,
                                                COSMIC and dbSNP. Common variants are filtered based on allele
                                                frequency in 1000 Genome Phase 3, ExAC, dbSNP, gnomAD, etc. In the
                                                absence of a clinically significant reported known variation(s),
                                                pathogenicity will be predicted based on <i>in-silico</i> gene
                                                prioritization tools: CADD, SIFT, PolyPhen-2, Condel and Mutation
                                                taster and prioritized for clinical correlation. The identified
                                                pathogenic variant will be correlated with observed phenotypic
                                                features of the patient and interpreted according to American
                                                College of Medical Genetics (ACMG) guidelines.
                                              </p>
                                              <p class="mb-2 d-block">
                                                Somatic variants are classified into three tiers based on their level
                                                of clinical significance in cancer diagnosis, prognosis, and/or
                                                therapeutics as per international guidelines: ACMG, ASCO, AMP, CAP,
                                                NCCN and ESMO
                                              </p> -->
                <p style="text-align: justify;">
                  The sequences obtained are aligned to human reference genome (GRCh37/hg19) and variant analysis was performed
                  using set of Bioinformatics Pipeline. Only non-synonymous and splice site variants found in the panel
                  consisting of specific set of genes were used for clinical interpretation. Silent variations that do not
                  result in any change
                  in amino acid in the coding region are not reported. Clinically relevant mutations were annotated using
                  published variants in literature and a set of databases – ClinVar, COSMIC
                  and dbSNP. Common variants are filtered based on allele frequency in 1000 Genome Phase 3, ExAC, dbSNP, gnomAD,
                  etc. In the absence of a clinically significant reported
                  known variation(s), pathogenicity will be predicted based on <i>in-silico</i> gene prioritization tools: CADD, SIFT,
                  PolyPhen-2, Condel and Mutation taster and prioritized for clinical
                  correlation. The identified pathogenic variant will be correlated with observed phenotypic features of the patient and interpreted according to ACMG/AMP guidelines.
                </p>
                <p class="mb-2 d-block" style="text-align: justify;">
                  Somatic variants are classified into three tiers based on their level of clinical significance in cancer
                  diagnosis, prognosis, and/or therapeutics as per international guidelines:
                  ACMG, ASCO, AMP, CAP, NCCN and ESMO.
                </p>
              </div>
            </div>

            <h6 class="py-2 h6_bg mt-2" [ngClass]="'yellow'">
              LIMITATIONS AND DISCLAIMER
            </h6>

            <div class="row">
              <div class="col">
                <div class="disc">
                  <ul class="ml-0 pl-4" style="text-align: justify;">
                    <li>
                      OncQuer<sup>TM</sup> Assist Panel has been developed, validated and
                      has not been cleared or approved by the FDA.
                    </li>
                    <li>
                      DNA studies do not constitute a definitive test for the selected condition(s) in all individuals. It
                      should be realized that there are possible sources of error. Errors can result
                      from trace contamination, rare technical errors, rare genetic variants that interfere with analysis,
                      recent scientific developments, and alternative classification systems. This
                      test should be one of the many aspects used by the healthcare provider to help with a diagnosis and
                      treatment plan.
                    </li>
                    <li>
                      We are using the canonical transcript for clinical reporting which is usually the longest coding
                      transcript with strong/multiple supporting evidence. However, in rare cases,
                      clinically relevant variants annotated in alternate complete coding transcripts could also be reported.
                    </li>
                    <li>
                      The contents of this test should be carefully assessed by the treating physician and further interpreted
                      along with clinical, histopathological findings, contraindications and
                      guidelines before deciding the course of therapy.
                    </li>
                    <li>
                      The CNVs detected must be confirmed by an alternate method, such as IHC, for further clinical management decisions.
                    </li>
                    <!-- <li>
                      The sensitivity of this assay to detect large deletions/duplications of more than 10 bp or copy number
                      variations (CNV) is 70-75%. The CNVs detected have to be confirmed
                      by alternate method.
                    </li> -->
                    <li>
                      Most recent block is recommended for testing as the mutation profile may change in response to treatment
                      and hence differ at different sampling points.
                    </li>
                    <li>
                      The identified pathogenic variant will be correlated with observed phenotypic features of the patient and interpreted according to AMP guidelines.
                    </li>
                    <li>
                      Certain genes may not be covered completely, and few mutations could be missed. A negative result cannot
                      rule out the possibility that the tested tumor sample carries
                      mutations not previously associated with cancer and hence not included in the panel.
                    </li>
                    <li>
                      Our limit of detection for OncQuer<sup>TM</sup> Assist Panel is 5% for SNVs, 10% for InDels and CNV gain &#8805; 6. In addition
                      to this, sequencing quality and coverage is dependent on many factors
                      such as homopolymers, GC-rich regions, intrinsic quality of FFPE DNA and that might impact the variant
                      detection.
                    </li>
                    <li> A negative result does not rule out the possibility of mutations in the patient’s tumor tissue.</li>
                    <li>Certain regions may be undercovered or poorly amplified due to intrinsic nature of the sample or the tumor
                      biology across the panel, due to which the variants may be detected with compromised QC such as less number
                      of unique reads or lack of paired reads. In such instances, a reflex testing on alternate technologies such
                      as ddPCR or Realtime PCR may be suggested.</li>
                  </ul>
                </div>
              </div>
            </div>

            <!--  -->
            <div class="row footer d-flex justify-content-between align-items-center">
              <!-- <div class="col">
                <div class="py-2">
                  <p>
                    <span class="font-weight-bold">Genomics Bihar,</span>
                  </p>
                  <p>Homi Bhabha cancer hospital & Research centre</p>
                  <p>Inside Srikrishna Medical College & Hospital</p>
                  <p>Umanagar, Muzaffarpur-842004.</p>
                  <p>Contact: ngs.mfp@gmail.com</p>
                </div>
              </div>
              <div class="col">
                <p class="text-right">
                  <img
                    src="../../../assets/tmc/tmc_footer.png"
                    alt="footer_image"
                    class="footer_img"
                  />
                </p>
              </div> -->
            </div>
          </div>
        </div>


        <!-- For HCG Template 3-->
        <div class="hcg" *ngIf="!is4basecare && isHCG && temp=='temp3'">

          <!-- Page 1 -->
          <div id="page1" class="container position-relative page" style="background-color: white">
            <!-- Header -->
            <div class="d-flex justify-content-between logo_panel py-3">
              <div class="logo_part">
                <img
                style="width:240px!important" src="../../../assets/hcg-new-logo.jpeg"
                  alt=""
                  class="logo_first"
                />
              </div>
              <div style=" border-left: 2px solid #fab216;padding-top:10px;
              height: 120px;"></div>

              <div class="logo_part text-center" style="font-family:arial Mt!important">

                                <h5 style="font-weight: bolder;margin-bottom:0px!important;">Triesta Sciences</h5>
                <p style="font-size: 15px!important;">
                                  A Unit of Health Care Global Enterprises Ltd <br/>
                                  5th Floor, Tower 1, #8, P.Kalinga Rao Road,<br/>
                                  Sampangiram Nagar, Bengaluru-560027. Tel: 080-40206305
                                </p>
                <h6 style="font-weight: bolder;font-size:15px!important">Department of Molecular and Clinical Genomics</h6>
              </div>
              <div style=" border-left: 2px solid #fab216;padding-top:10px;
              height: 120px;"></div>
              <div class="logo_part">
                <img  style="width:240px!important; margin-top:40px!important"
                  src="../../../assets//oncoquer1.png"
                  alt=""
                  class="logo_three"
                />
              </div>
            </div>
            <!--  -->
            <div class="mar_top"></div>
            <!--  -->
            <div class="row">
              <div class="col-7">
                <div class="">
                  <div class="w-50" style="min-height: 80px">
                    <p class="py-1 font-weight-bold" [ngClass]="'txt-black'">
                      SCOPE OF THE TEST
                    </p>
                    <p>SNVs, InDels, CNAs, Gene Fusions status</p>
                  </div>


                  <!-- <div style=" border-bottom: 3px solid #fab216;padding-top:10px; width: 610px;"></div> -->
                  <!-- <img src="../../../assets/BorderLine.png" class="mr-2 w-100" /> -->
                </div>
              </div>
              <div class="col">
                <div class="">
                  <div class="w-100" style="min-height: 80px">
                    <div class="w-50" style="min-height: 80px">
                      <p class="py-1 font-weight-bold" [ngClass]="'txt-black'">
                        NAME OF THE TEST
                      </p>
                      <p> OncQuer<sup>TM</sup> Assist Panel</p>
                    </div>
                    <!-- <p [ngClass]="'txt-yellow'" class="py-1 font-weight-bold" *ngIf="report.clinicalSummary != null">
                      <span *ngIf="report.clinicalSummary != ' '">CLINICAL INDICATION</span>
                    </p>

                    <p *ngIf="report.clinicalSummary != null">
                      <span style="color: #000 !important" *ngIf="report.clinicalSummary != ' '">{{ report.clinicalSummary
                        }}</span>
                    </p> -->
                  </div>
                  <!-- <div class="w-100" style="min-height: 80px">
                    <p [ngClass]="'txt-yellow'" class="py-1 font-weight-bold">
                    </p>

                    <p>

                    </p>
                  </div> -->
                  <!-- <div style=" border-bottom: 3px solid #fab216;padding-top:10px; width: 430px;"></div> -->
                  <!-- <img src="../../../assets/BorderLine.png" class="mr-2 w-100" /> -->
                </div>
              </div>
            </div>
            <div class="text-center" style=" border-bottom: 3px solid #fab216;padding-right: 10px;
            width: 1074px;
            margin-left: 10px;
            margin-right: 10px;"></div>

            <!--  -->
            <div class="row pt-2">
              <div class="col-7">
                <h6 class="text-uppercase py-2 font-weight-bold" [ngClass]="'txt-black'">
                  Report Details
                </h6>
                <ul class="list-unstyled">
                  <li class="pb-1">
                    <span class="clr-cyan" [ngClass]="'txt-black'">Patient Name : </span>
                    <span class="text-black font-weight-bold">{{
                      report.patient?.name | uppercase
                      }}</span>
                  </li>
                  <li class="pb-1">
                    <span class="clr-cyan" [ngClass]="'txt-black'">Gender : </span>
                    <span class="text-black font-weight-bold">{{
                      report.patient?.gender | titlecase
                    }}</span>
                  </li>
                  <li class="pb-1">
                    <span class="clr-cyan" [ngClass]="'txt-black'">Age/DOB : </span>
                    <span class="text-black font-weight-bold">{{
                      report.patient?.dateOfBirth
                        ? (report.patient?.dateOfBirth | date : "dd/MM/yyyy")
                        : report.patient?.age + " Years"
                    }}</span>
                  </li>
                  <li class="pb-1">
                    <span class="clr-cyan" [ngClass]="'txt-black'">MRN No : </span>
                    <span class="text-black font-weight-bold">{{ report?.patient?.orgMRNNo }}</span>
                  </li>

                  <li class="pb-1">
                    <span class="clr-cyan" [ngClass]="'txt-black'">Hospital Name : </span>
                    <span class="text-black font-weight-bold">{{ labHospital }}</span>
                  </li>
                </ul>
              </div>

              <div class="col">
                <ul class="list-unstyled pt-4 mt-3">
                  <li class="pb-1">
                    <span class="clr-cyan" [ngClass]="'txt-black'">Patient Id :
                    </span>
                    <span class="text-black font-weight-bold">{{ report.patient?.organisationPatientId}}</span>
                  </li>
                  <li class="pb-1">
                    <span class="clr-cyan" [ngClass]="'txt-black'">Collected on :
                    </span>
                    <span class="text-black font-weight-bold">{{
                      report.labDetails?.sampleCollected | date : "dd/MM/yyyy"
                    }}</span>
                  </li>
                  <li class="pb-1">
                    <span class="clr-cyan" [ngClass]="'txt-black'">Reported on :
                    </span>
                    <span class="text-black font-weight-bold">{{ date | date : "dd/MM/yyyy" }}</span>
                  </li>
                  <li class="pb-1">
                    <span class="clr-cyan" [ngClass]="'txt-black'">Referred By :
                    </span>
                    <span class="text-black font-weight-bold">{{ referringClinician }}</span>
                  </li>

                </ul>
              </div>
            </div>
            <!--  -->
            <h6 class="py-2 h6_bg" [ngClass]="'yellow'">CLINICAL HISTORY/SAMPLE DETAILS</h6>
            <div class="row mt-2 py-2">
              <div class="col-3">
                <h6 class="mb-0 font-weight-bold">Clinical Indications</h6>
                <span>{{ report?.cancerIdentifier }}</span>
              </div>
              <div class="col-3">
                <h6 class="mb-0 font-weight-bold">Sample Details</h6>
                <span *ngIf="report.labDetails?.sampleSource">{{
                  report.labDetails?.sampleSource
                  }}</span>
                <span *ngIf="!report.labDetails?.sampleSource">FFPE Block</span>
              </div>
              <div class="col-3">
                <h6 class="mb-0 font-weight-bold">Tumor Content</h6>
                <span>{{report?.tmhReportInfo?.tumorContent}}</span>
              </div>
              <div class="col-3">
                <h6 class="mb-0 font-weight-bold">Specimen Site</h6>
                <span>{{report?.tmhReportInfo?.biopsySite}}</span>
              </div>

            </div>
            <!--  -->
            <div class="row mt-2 py-2">
              <div class="col">
                <h6 class="mb-0 text-uppercase" style="font-weight: bolder;">Results</h6>
              </div>
            </div>
            <h6 class="py-2 h6_bg" [ngClass]="'yellow'">
              Genomic findings from tumor profiling
            </h6>

            <!--  -->
            <div class="row">
              <div style="width: 100%;" *ngIf="isReportDownload">
                <img [src]="firstPageTable" alt="" width="100%" class="img-fluid" />
              </div>
              <div class="col-12" id="firstPageTable" *ngIf="!isReportDownload">
                <table class="page_1 cust_table w-100 mt-3 first_1" [ngClass]="'table_yellow'">
                  <tr>
                    <th style="font-size:15px!important">Genomic Alteration</th>
                    <th style="font-size:15px!important">Relevant Therapies (in Same Cancer Type)</th>
                    <th style="font-size:15px!important">Relevant Therapies (in Different Cancer)</th>
                  </tr>

                  <tr *ngIf="tableCount== 0 && prognasticCount>0 ">
                    <td colspan="3" class="text-center mt-2">
                      Clinically relevant genomic alterations associated with therapeutic significance were not detected.
                    </td>
                  </tr>
                  <tr *ngIf="tableCount== 0 && prognasticCount == 0">
                    <td colspan="3" class="text-center mt-2">
                      Clinically relevant genomic alterations associated with both therapeutic and prognostic significance were
                      not detected.
                    </td>
                  </tr>

                  <!-- SNV -->
                  <ng-container>
                    <tr class="py-1 font-weight-bold" *ngFor="
                                                                let snvHotspotTrail of reportPdfRes?.allHotspotTrailList  | slice : 0 : 3 ; let i=index
                                                              ">
                      <td>
                        <i>{{ snvHotspotTrail.gene }}</i>
                        &nbsp;<span *ngIf="!snvHotspotTrail.gene.includes('Wild type')">
                        <span *ngIf="snvHotspotTrail?.multiVariant==null">
                          <span *ngIf="snvHotspotTrail.exonNumber != null || snvHotspotTrail.pposition != null && snvHotspotTrail.pposition != ''">

                            <span *ngIf="snvHotspotTrail.exonNumber != null">{{snvHotspotTrail.exonNumber}}</span>&nbsp;
                            <span *ngIf="snvHotspotTrail.pposition != null && snvHotspotTrail.pposition != ''">({{snvHotspotTrail.pposition?.replace(' ','')}})</span></span>
                            <p *ngIf="snvHotspotTrail.mutantBurdenPercent != null && !snvHotspotTrail.gene.includes('Wild type')">
                              Allelic burden: {{ snvHotspotTrail.mutantBurdenPercent }}%
                            </p>
                            <span *ngIf="snvHotspotTrail.copyNumber!=null && snvHotspotTrail.copyNumber.length>0"> {{ snvHotspotTrail.copyNumber }}
                          </span>
                        </span>
                        <span *ngIf="snvHotspotTrail?.multiVariant!=null"><br/>
                          <span  style="font-size: 14px !important" [innerHtml]="snvHotspotTrail?.multiVariant"></span>
                        </span></span>
                      </td>
                      <td>
                        <table class="table first_tabley w-100">
                          <thead class="border-bottom cust_clr">
                            <tr>
                              <th class="w-50">
                                <span><i>Therapy</i></span>
                              </th>
                              <th class="w-33">
                                <span><i>Clinical Relevance</i></span>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngIf="snvHotspotTrail.sameCancerType == null">
                              <td class="w-50">NA</td>
                              <td class="w-50">NA</td>
                            </tr>

                            <ng-container *ngIf="snvHotspotTrail.sameCancerType != null">
                              <tr *ngFor="
                                                                          let item of snvHotspotTrail.sameTypeRelevantData
                                                                        ">
                                <td>{{ item.therapy }}</td>
                                <td>{{ item.association }}</td>
                                <!-- <td>{{item.cancerType}}</td> -->
                              </tr>
                            </ng-container>
                          </tbody>
                        </table>
                      </td>
                      <td>
                        <table class="table first_tabley w-100">
                          <thead class="border-bottom cust_clr">
                            <tr>
                              <th class="w-33">
                                <span><i>Therapy</i></span>
                              </th>
                              <th class="w-33">
                                <span><i>Clinical Relevance</i></span>
                              </th>
                              <th class="w-33">
                                <span><i>Cancer Type</i></span>
                              </th>
                            </tr>
                          </thead>
                          <tbody>

                            <ng-container *ngIf="snvHotspotTrail.otherCancerType != null">
                              <tr *ngFor="
                                                                          let item of snvHotspotTrail.otherTypeRelevantData
                                                                        ">
                                <td>{{ item.therapy }}</td>
                                <td>{{ item.association }}</td>
                                <td>{{ item.cancerType }}</td>
                              </tr>
                            </ng-container>
                            <tr *ngIf="snvHotspotTrail.otherCancerType == null">
                              <td class="w-33">NA</td>
                              <td class="w-33">NA</td>
                              <td class="w-33">NA</td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>


                  </ng-container>
                </table>
                <p *ngIf="
                reportPdfRes.allHotspotTrailList.length != 0" style="font-size: 13px !important" class="mt-0">
                  <i> *NA: Not Applicable</i>
                </p>
              </div>
            </div>
            <!--  -->

            <!--  -->



            <!--  -->
            <div class="row footer d-flex justify-content-between align-items-center">
              <!-- <div class="col">
                <div class="py-2">
                  <p>
                    <span class="font-weight-bold">Genomics Bihar,</span>
                  </p>
                  <p>Homi Bhabha cancer hospital & Research centre</p>
                  <p>Inside Srikrishna Medical College & Hospital</p>
                  <p>Umanagar, Muzaffarpur-842004.</p>
                  <p>Contact: ngs.mfp@gmail.com</p>
                </div>
              </div>
              <div class="col">
                <p class="text-right">
                  <img
                    src="../../../assets/tmc/tmc_footer.png"
                    alt="footer_image"
                    class="footer_img"
                  />
                </p>
              </div> -->
            </div>
          </div>

          <div id="page1_1" *ngIf="reportPdfRes.allHotspotTrailList.length > 6" class="container position-relative page"
            style="background-color: white">
            <div class="d-flex justify-content-between logo_panel py-3">
              <div class="logo_part">
                <img
                style="width:240px!important" src="../../../assets/hcg-new-logo.jpeg"
                  alt=""
                  class="logo_first"
                />
              </div>
              <div style=" border-left: 2px solid #fab216;padding-top:10px;
              height: 120px;"></div>

              <div class="logo_part text-center" style="font-family:arial Mt!important">

                                <h5 style="font-weight: bolder;margin-bottom:0px!important;">Triesta Sciences</h5>
                <p style="font-size: 15px!important;">
                  A Unit of Health Care Global Enterprises Ltd <br/>
                  5th Floor, Tower 1, #8, P.Kalinga Rao Road,<br/>
                  Sampangiram Nagar, Bengaluru-560027. Tel: 080-40206305
                </p>
                <h6 style="font-weight: bolder;font-size:15px!important">Department of Molecular and Clinical Genomics</h6>
              </div>
              <div style=" border-left: 2px solid #fab216;padding-top:10px;
              height: 120px;"></div>
              <div class="logo_part">
                <img  style="width:240px!important; margin-top:40px!important"
                  src="../../../assets//oncoquer1.png"
                  alt=""
                  class="logo_three"
                />
              </div>
            </div>
            <div class="row header mt-1">
              <div>
                <!-- <div class="col-3">
                                  <img src="../../../assets/logo.png" alt="" style="width: 140px" />
                                </div> -->
              </div>
              <div></div>
              <div class="col">
                <div class="patient-detailes ml-auto float-right">
                  <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
                    <li>
                      Patient Id <br />
                      <span>{{ report.patient?.organisationPatientId }}</span>
                    </li>
                    <li class="ml-4">
                      Patient Name<br />
                      <span>{{ report.patient?.name }}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <!--  -->
              <div *ngIf="report.clientType == '4basecare_client'">
                <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
                  </div>
                </div>
                <div *ngIf="report.clientName == 'path_labs'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div style="width: 100%;" *ngIf="isReportDownload">
                <img [src]="firstPageTable1" alt="" width="100%" class="img-fluid" />
              </div>
              <div class="col-12" id="firstPageTable1" *ngIf="!isReportDownload">
                <table class="page_1 cust_table w-100 mt-3 first_1" [ngClass]="'table_yellow'">
                  <tr>
                    <th>Genomic Alteration</th>
                    <th>Relevant Therapies (in Same Cancer Type)</th>
                    <th>Relevant Therapies (in Different Cancer)</th>
                  </tr>

                  <tr *ngIf="tableCount== 0 && prognasticCount>0 ">
                    <td colspan="3" class="text-center mt-2">
                      Clinically relevant genomic alterations associated with therapeutic significance were not detected.
                    </td>
                  </tr>
                  <tr *ngIf="tableCount== 0 && prognasticCount == 0">
                    <td colspan="3" class="text-center mt-2">
                      Clinically relevant genomic alterations associated with both therapeutic and prognostic significance were
                      not detected.
                    </td>
                  </tr>

                  <!-- SNV -->
                  <ng-container>
                    <tr class="py-1 font-weight-bold" *ngFor="
                                                                let snvHotspotTrail of reportPdfRes?.allHotspotTrailList  | slice : 3 : 8 ; let i=index
                                                              ">
                      <td>
                        <i>{{ snvHotspotTrail.gene }}</i>
                        &nbsp;<span *ngIf="!snvHotspotTrail.gene.includes('Wild type')">
                        <span *ngIf="snvHotspotTrail?.multiVariant==null">
                          <span *ngIf="snvHotspotTrail.exonNumber != null || snvHotspotTrail.pposition != null && snvHotspotTrail.pposition != ''">

                            <span *ngIf="snvHotspotTrail.exonNumber != null">{{snvHotspotTrail.exonNumber}}</span>&nbsp;
                            <span *ngIf="snvHotspotTrail.pposition != null && snvHotspotTrail.pposition != ''">({{snvHotspotTrail.pposition?.replace(' ','')}})</span></span>
                            <p *ngIf="snvHotspotTrail.mutantBurdenPercent != null && !snvHotspotTrail.gene.includes('Wild type')">
                              Allelic burden: {{ snvHotspotTrail.mutantBurdenPercent }}%
                            </p>
                            <span *ngIf="snvHotspotTrail.copyNumber!=null && snvHotspotTrail.copyNumber.length>0"> {{ snvHotspotTrail.copyNumber }}
                          </span>
                        </span>
                        <span *ngIf="snvHotspotTrail?.multiVariant!=null"><br/>
                          <span  style="font-size: 14px !important" [innerHtml]="snvHotspotTrail?.multiVariant"></span>
                        </span></span>
                      </td>
                      <td>
                        <table class="table first_tabley w-100">
                          <thead class="border-bottom cust_clr">
                            <tr>
                              <th class="w-50">
                                <span><i>Therapy</i></span>
                              </th>
                              <th class="w-33">
                                <span><i>Clinical Relevance</i></span>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngIf="snvHotspotTrail.sameCancerType == null">
                              <td class="w-50">NA</td>
                              <td class="w-50">NA</td>
                            </tr>

                            <ng-container *ngIf="snvHotspotTrail.sameCancerType != null">
                              <tr *ngFor="
                                                                          let item of snvHotspotTrail.sameTypeRelevantData
                                                                        ">
                                <td>{{ item.therapy }}</td>
                                <td>{{ item.association }}</td>
                                <!-- <td>{{item.cancerType}}</td> -->
                              </tr>
                            </ng-container>
                          </tbody>
                        </table>
                      </td>
                      <td>
                        <table class="table first_tabley w-100">
                          <thead class="border-bottom cust_clr">
                            <tr>
                              <th class="w-33">
                                <span><i>Therapy</i></span>
                              </th>
                              <th class="w-33">
                                <span><i>Clinical Relevance</i></span>
                              </th>
                              <th class="w-33">
                                <span><i>Cancer Type</i></span>
                              </th>
                            </tr>
                          </thead>
                          <tbody>

                            <ng-container *ngIf="snvHotspotTrail.otherCancerType != null">
                              <tr *ngFor="
                                                                          let item of snvHotspotTrail.otherTypeRelevantData
                                                                        ">
                                <td>{{ item.therapy }}</td>
                                <td>{{ item.association }}</td>
                                <td>{{ item.cancerType }}</td>
                              </tr>
                            </ng-container>
                            <tr *ngIf="snvHotspotTrail.otherCancerType == null">
                              <td class="w-33">NA</td>
                              <td class="w-33">NA</td>
                              <td class="w-33">NA</td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>


                  </ng-container>
                </table>
                <p *ngIf="
                reportPdfRes.allHotspotTrailList.length != 0" style="font-size: 13px !important" class="mt-0">
                  <i> *NA: Not Applicable</i>
                </p>
              </div>
            </div>
            <!--  -->
                  <div class="row footer">
          <!-- <div class="col-12">
            <div class="pb-2 pl-4 text-center">
              <div class="d-block">
                  <span class="font-weight-bold" style="font-size: 13px !important"
                    >4baseCare (Genomics Tarang ODC),</span
                  >
                <span>
                  SJP2-S1-1F- C wing, Wipro Limited, SEZ, Sarjapur 2, Sy.No.69 (P),
                </span>
              </div>
              <div class="d-block">
                <span>Doddakannelli, Sarjapura Road,</span>
                <span> Bengaluru 560035, KA, India</span></div>


            </div>
          </div>
          <div class="col-12 pt-1 ">
            <p  style="font-size: 8px!important; line-height: 14px!important;">{{footerDiscription}} </p>
          </div> -->


        </div>
          </div>




          <!-- page 1_2 -->
          <div id="page1_2" *ngIf="
                                  prognasticCount==0 || prognasticCount>0 ||
                                  geneMutationlist != null ||  (report.disclaimer != '<p></p>' &&
                                  report.disclaimer != '' &&
                                  report.disclaimer != null ) ||
                                  (otherCountSNV == false &&
                                  rreportPdfRes.allVariantsList.length == 0) ||
                                  (reportPdfRes.allHotspotTrailList.length>3 && reportPdfRes.allHotspotTrailList.length<=6)"
            class="container position-relative page" style="background-color: white">
              <!-- Header -->
           <!-- Header -->
           <div class="d-flex justify-content-between logo_panel py-3">
            <div class="logo_part">
              <img
              style="width:240px!important" src="../../../assets/hcg-new-logo.jpeg"
                alt=""
                class="logo_first"
              />
            </div>
            <div style=" border-left: 2px solid #fab216;padding-top:10px;
            height: 120px;"></div>

            <div class="logo_part text-center" style="font-family:arial Mt!important">

              <h5 style="font-weight: bolder;margin-bottom:0px!important;">Triesta Sciences</h5>
              <p style="font-size: 15px!important;">
                A Unit of Health Care Global Enterprises Ltd <br/>
                5th Floor, Tower 1, #8, P.Kalinga Rao Road,<br/>
                Sampangiram Nagar, Bengaluru-560027. Tel: 080-40206305
              </p>
              <h6 style="font-weight: bolder;font-size:15px!important">Department of Molecular and Clinical Genomics</h6>
            </div>
            <div style=" border-left: 2px solid #fab216;padding-top:10px;
            height: 120px;"></div>
            <div class="logo_part">
              <img  style="width:240px!important; margin-top:40px!important"
                src="../../../assets//oncoquer1.png"
                alt=""
                class="logo_three"
              />
            </div>
          </div>
              <div class="row header mt-1">
              <div></div>
              <div class="col">
                <div class="patient-detailes ml-auto float-right">
                  <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
                    <li>
                      Patient Id <br />
                      <span>{{ report.patient?.organisationPatientId }}</span>
                    </li>
                    <li class="ml-4">
                      Patient Name<br />
                      <span>{{ report.patient?.name }}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <!--  -->
              <div *ngIf="report.clientType == '4basecare_client'">
                <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
                  </div>
                </div>
                <div *ngIf="report.clientName == 'path_labs'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
                  </div>
                </div>
              </div>
            </div>
            <div class="row" *ngIf=" reportPdfRes?.allHotspotTrailList.length<=6 || reportPdfRes?.allHotspotTrailList.length>8">
              <div style="width: 100%;" *ngIf="isReportDownload">
                <img [src]="firstPageTable2" alt="" width="100%" class="img-fluid" />
              </div>
              <div class="col-12" id="firstPageTable2" *ngIf="!isReportDownload">
                <table class="page_1 cust_table w-100 mt-3 first_1" [ngClass]="'table_yellow'">
                  <tr>
                    <th>Genomic Alteration</th>
                    <th>Relevant Therapies (in Same Cancer Type)</th>
                    <th>Relevant Therapies (in Different Cancer)</th>
                  </tr>

                  <tr *ngIf="tableCount== 0 && prognasticCount>0 ">
                    <td colspan="3" class="text-center mt-2">
                      Clinically relevant genomic alterations associated with therapeutic significance were not detected.
                    </td>
                  </tr>
                  <tr *ngIf="tableCount== 0 && prognasticCount == 0">
                    <td colspan="3" class="text-center mt-2">
                      Clinically relevant genomic alterations associated with both therapeutic and prognostic significance were
                      not detected.
                    </td>
                  </tr>

                  <!-- SNV -->
                  <ng-container  *ngIf=" reportPdfRes?.allHotspotTrailList.length<=6">
                    <tr class="py-1 font-weight-bold" *ngFor="
                                                                let snvHotspotTrail of reportPdfRes?.allHotspotTrailList  | slice : 3 : 6 ; let i=index
                                                              ">
                      <td>
                        <i>{{ snvHotspotTrail.gene }}</i>
                        &nbsp;<span *ngIf="!snvHotspotTrail.gene.includes('Wild type')">
                        <span *ngIf="snvHotspotTrail?.multiVariant==null">
                          <span *ngIf="snvHotspotTrail.exonNumber != null || snvHotspotTrail.pposition != null && snvHotspotTrail.pposition != ''">

                            <span *ngIf="snvHotspotTrail.exonNumber != null">{{snvHotspotTrail.exonNumber}}</span>&nbsp;
                            <span *ngIf="snvHotspotTrail.pposition != null && snvHotspotTrail.pposition != ''">({{snvHotspotTrail.pposition?.replace(' ','')}})</span></span>
                            <p *ngIf="snvHotspotTrail.mutantBurdenPercent != null && !snvHotspotTrail.gene.includes('Wild type')">
                              Allelic burden: {{ snvHotspotTrail.mutantBurdenPercent }}%
                            </p>
                            <span *ngIf="snvHotspotTrail.copyNumber!=null && snvHotspotTrail.copyNumber.length>0"> {{ snvHotspotTrail.copyNumber }}
                          </span>
                        </span>
                        <span *ngIf="snvHotspotTrail?.multiVariant!=null"><br/>
                          <span  style="font-size: 14px !important" [innerHtml]="snvHotspotTrail?.multiVariant"></span>
                        </span></span>
                      </td>
                      <td>
                        <table class="table first_tabley w-100">
                          <thead class="border-bottom cust_clr">
                            <tr>
                              <th class="w-50">
                                <span><i>Therapy</i></span>
                              </th>
                              <th class="w-33">
                                <span><i>Clinical Relevance</i></span>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngIf="snvHotspotTrail.sameCancerType == null">
                              <td class="w-50">NA</td>
                              <td class="w-50">NA</td>
                            </tr>

                            <ng-container *ngIf="snvHotspotTrail.sameCancerType != null">
                              <tr *ngFor="
                                                                          let item of snvHotspotTrail.sameTypeRelevantData
                                                                        ">
                                <td>{{ item.therapy }}</td>
                                <td>{{ item.association }}</td>
                                <!-- <td>{{item.cancerType}}</td> -->
                              </tr>
                            </ng-container>
                          </tbody>
                        </table>
                      </td>
                      <td>
                        <table class="table first_tabley w-100">
                          <thead class="border-bottom cust_clr">
                            <tr>
                              <th class="w-33">
                                <span><i>Therapy</i></span>
                              </th>
                              <th class="w-33">
                                <span><i>Clinical Relevance</i></span>
                              </th>
                              <th class="w-33">
                                <span><i>Cancer Type</i></span>
                              </th>
                            </tr>
                          </thead>
                          <tbody>

                            <ng-container *ngIf="snvHotspotTrail.otherCancerType != null">
                              <tr *ngFor="
                                                                          let item of snvHotspotTrail.otherTypeRelevantData
                                                                        ">
                                <td>{{ item.therapy }}</td>
                                <td>{{ item.association }}</td>
                                <td>{{ item.cancerType }}</td>
                              </tr>
                            </ng-container>
                            <tr *ngIf="snvHotspotTrail.otherCancerType == null">
                              <td class="w-33">NA</td>
                              <td class="w-33">NA</td>
                              <td class="w-33">NA</td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>


                  </ng-container>
                  <ng-container  *ngIf=" reportPdfRes?.allHotspotTrailList.length>8">
                    <tr class="py-1 font-weight-bold" *ngFor="
                                                                let snvHotspotTrail of reportPdfRes?.allHotspotTrailList  | slice : 8 : 12 ; let i=index
                                                              ">
                      <td>
                        <i>{{ snvHotspotTrail.gene }}</i>
                        &nbsp;<span *ngIf="!snvHotspotTrail.gene.includes('Wild type')">
                                            <span *ngIf="snvHotspotTrail.exonNumber != null || snvHotspotTrail.pposition != null && snvHotspotTrail.pposition != ''">

                        <span *ngIf="snvHotspotTrail.exonNumber != null">{{snvHotspotTrail.exonNumber}}</span>&nbsp;
                        <span
                          *ngIf="snvHotspotTrail.pposition != null && snvHotspotTrail.pposition != ''">({{snvHotspotTrail.pposition?.replace(' ','')}})</span></span>
                        <p *ngIf="snvHotspotTrail.mutantBurdenPercent != null && !snvHotspotTrail.gene.includes('Wild type')">
                          Allelic burden: {{ snvHotspotTrail.mutantBurdenPercent }}%
                        </p>
                        <span *ngIf="snvHotspotTrail.copyNumber!=null && snvHotspotTrail.copyNumber.length>0"> {{ snvHotspotTrail.copyNumber }}
                        </span></span>
                      </td>
                      <td>
                        <table class="table first_tabley w-100">
                          <thead class="border-bottom cust_clr">
                            <tr>
                              <th class="w-50">
                                <span><i>Therapy</i></span>
                              </th>
                              <th class="w-33">
                                <span><i>Clinical Relevance</i></span>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngIf="snvHotspotTrail.sameCancerType == null">
                              <td class="w-50">NA</td>
                              <td class="w-50">NA</td>
                            </tr>

                            <ng-container *ngIf="snvHotspotTrail.sameCancerType != null">
                              <tr *ngFor="
                                                                          let item of snvHotspotTrail.sameTypeRelevantData
                                                                        ">
                                <td>{{ item.therapy }}</td>
                                <td>{{ item.association }}</td>
                                <!-- <td>{{item.cancerType}}</td> -->
                              </tr>
                            </ng-container>
                          </tbody>
                        </table>
                      </td>
                      <td>
                        <table class="table first_tabley w-100">
                          <thead class="border-bottom cust_clr">
                            <tr>
                              <th class="w-33">
                                <span><i>Therapy</i></span>
                              </th>
                              <th class="w-33">
                                <span><i>Clinical Relevance</i></span>
                              </th>
                              <th class="w-33">
                                <span><i>Cancer Type</i></span>
                              </th>
                            </tr>
                          </thead>
                          <tbody>

                            <ng-container *ngIf="snvHotspotTrail.otherCancerType != null">
                              <tr *ngFor="
                                                                          let item of snvHotspotTrail.otherTypeRelevantData
                                                                        ">
                                <td>{{ item.therapy }}</td>
                                <td>{{ item.association }}</td>
                                <td>{{ item.cancerType }}</td>
                              </tr>
                            </ng-container>
                            <tr *ngIf="snvHotspotTrail.otherCancerType == null">
                              <td class="w-33">NA</td>
                              <td class="w-33">NA</td>
                              <td class="w-33">NA</td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>


                  </ng-container>
                </table>
                <p *ngIf="
                reportPdfRes.allHotspotTrailList.length != 0" style="font-size: 13px !important" class="mt-0">
                  <i> *NA: Not Applicable</i>
                </p>
              </div>
            </div>
            <div *ngIf="(tableCount>0) &&
                              prognasticCount==0">
              <h6 class="py-2 h6_bg" [ngClass]="'yellow'"> Prognostic Biomarkers</h6>
            </div>
            <div class="row" *ngIf="(tableCount>0 )&&
                              prognasticCount==0">
              <div class="col-12">
                <table class="page_1 cust_table w-100 mt-3 varient_detailsy prognasticbiomarkertable"
                  [ngClass]="'table_variant-blue'">
                  <thead>
                    <tr>
                      <th>Genomic Alteration</th>
                      <th>Prognostic Significance</th>
                    </tr>
                  </thead>
                  <ng-container>
                    <tr>
                      <td colspan="2" style="text-align:center">
                        Clinically relevant genomic alterations associated with prognostic significance were not detected.
                      </td>
                    </tr>
                  </ng-container>
                </table>
              </div>
            </div>
            <div *ngIf="prognasticCount>0">
              <h6 class="py-2 h6_bg" [ngClass]="'yellow'"> Prognostic Biomarkers</h6>
            </div>

            <div class="row" *ngIf="prognasticCount>0">
              <div class="col-12">
                <table class="page_1 cust_table w-100 mt-3 varient_detailsy prognasticbiomarkertable"
                  [ngClass]="'table_variant-blue'">
                  <thead>
                    <tr>
                      <th>Genomic Alteration</th>
                      <th>Prognostic Significance</th>

                    </tr>
                  </thead>
                  <ng-container *ngFor="let prog of reportPdfRes.prognosticBiomarkers">
                    <tr>
                      <td style="padding-left: 3px !important">
                        <i>{{ prog.gene }}&nbsp; </i>
                        <span [innerHtml]="prog.geneDetails"></span>
                      </td>
                      <td style="padding-left: 3px !important;">{{prog.statement}}
                      </td>
                    </tr>
                  </ng-container>

                </table>
              </div>
            </div>

            <!-- gene Data -->
            <div *ngIf="geneMutationlist != null && isStatusDisplay">
              <div class="mt-3">
                <h6 class="py-2 h6_bg" [ngClass]="'yellow'">
                  Status of variants in cancer related Biomarkers
                </h6>
              </div>

              <table class="staus_gene bg_skyblue" *ngIf="geneMutationlist != null" [ngClass]="{
                                                                                      biomarker_table:
                                                                                        geneMutationlist.length >= 4 &&
                                                                                        geneMutationlist.length < 8
                                                                                    }">
                <tr style="background: #bfe6fa">
                  <td style="font-size: 18px !important; padding-left: 10px !important">
                    Gene
                  </td>
                  <td *ngFor="
                                                                                          let geneMutation of geneMutationlist
                                                                                            | slice : 0 : 8
                                                                                        ">
                    <i>{{ geneMutation.gene }}</i>
                  </td>
                </tr>
                <tr>
                  <td style="font-size: 18px !important; padding-left: 10px !important">
                    <b>Status</b>
                  </td>
                  <td *ngFor="
                                                                                          let geneMutation of geneMutationlist
                                                                                            | slice : 0 : 8
                                                                                        ">
                    <span *ngIf="
                                                                                            geneMutation.status == 'Pathogenic' ||
                                                                                            geneMutation.status == 'Likely Pathogenic' ||
                                                                                            geneMutation.status == 'Detected'
                                                                                          "
                      style="color: red; font-weight: 600px"><b>{{
                        geneMutation.status }}</b></span>
                    <span *ngIf="geneMutation.status == 'VUS'" style="color: black; font-weight: 600px"><b>{{
                        geneMutation.status }}</b></span>
                    <span *ngIf="
                                                                                            geneMutation.status == 'Not Detected' ||
                                                                                            geneMutation.status == 'Benige'
                                                                                          ">{{ geneMutation.status }}</span>
                  </td>
                </tr>
<br/>
              </table>
              <table class="staus_gene" [ngClass]="'bg_skyblue'" *ngIf="
                                                                                      geneMutationlist != null &&
                                                                                      geneMutationlist.length > 8
                                                                                    ">
                <tr style="background: #bfe6fa">
                  <td style="font-size: 18px !important; padding-left: 10px !important">

                    Gene
                  </td>
                  <td *ngFor="
                                                                                          let geneMutation of geneMutationlist
                                                                                            | slice : 8 : 17
                                                                                        ">
                    <i>{{ geneMutation.gene }}</i>
                  </td>
                </tr>
                <tr>
                  <td style="font-size: 18px !important; padding-left: 10px !important">
                    <b>Status</b>
                  </td>
                  <td *ngFor="
                                                                                          let geneMutation of geneMutationlist
                                                                                            | slice : 8 : 17
                                                                                        ">
                    <span *ngIf="
                                                                                            geneMutation.status == 'Pathogenic' ||
                                                                                            geneMutation.status == 'Likely Pathogenic' ||
                                                                                            geneMutation.status == 'Detected'
                                                                                          "
                      style="color: red; font-weight: 600px"><b>{{
                        geneMutation.status }}</b></span>
                    <span *ngIf="geneMutation.status == 'VUS'" style="color: black; font-weight: 600px"><b>{{
                        geneMutation.status }}</b></span>
                    <span *ngIf="
                                                                                            geneMutation.status == 'Not Detected' ||
                                                                                            geneMutation.status == 'Benige'
                                                                                          ">{{ geneMutation.status }}</span>
                  </td>
                </tr>
<br/>
              </table>

              <table
                class="staus_gene"
                [ngClass]="'bg_skyblue'"
                *ngIf="
                  geneMutationlist != null &&
                  geneMutationlist.length > 17
                "
              >
                <tr style="background: #bfe6fa">
                  <td
                    style="font-size: 18px !important; padding-left: 10px !important"
                  >
                    Gene
                  </td>
                  <td
                    *ngFor="
                      let geneMutation of geneMutationlist
                        | slice : 17 : 25
                    "
                  >
                    <i>{{ geneMutation.gene }}</i>
                  </td>
                </tr>
                <tr>
                  <td
                    style="font-size: 18px !important; padding-left: 10px !important"
                  >
                    <b>Status</b>
                  </td>
                  <td
                    *ngFor="
                      let geneMutation of geneMutationlist
                        | slice : 17 : 25
                    "
                  >
                    <span
                      *ngIf="
                        geneMutation.status == 'Pathogenic' ||
                        geneMutation.status == 'Likely Pathogenic' ||
                        geneMutation.status == 'Detected'
                      "
                      style="color: red; font-weight: 600px"
                      ><b>{{ geneMutation.status }}</b></span
                    >
                    <span
                      *ngIf="geneMutation.status == 'VUS'"
                      style="color: black; font-weight: 600px"
                      ><b>{{ geneMutation.status }}</b></span
                    >
                    <span
                      *ngIf="
                        geneMutation.status == 'Not Detected' ||
                        geneMutation.status == 'Benige'
                      "
                      >{{ geneMutation.status }}</span
                    >
                  </td>
                </tr>
<br/>
              </table>
              <table class="staus_gene" [ngClass]="'bg_skyblue'" *ngIf="
                    geneMutationlist != null &&
                    geneMutationlist.length > 25
                  ">
                <tr style="background: #bfe6fa">
                  <td style="font-size: 18px !important; padding-left: 10px !important">
                    Gene
                  </td>
                  <td *ngFor="
                        let geneMutation of geneMutationlist
                          | slice : 25 : 33
                      ">
                    <i>{{ geneMutation.gene }}</i>
                  </td>
                </tr>
                <tr>
                  <td style="font-size: 18px !important; padding-left: 10px !important">
                    <b>Status</b>
                  </td>
                  <td *ngFor="
                        let geneMutation of geneMutationlist
                          | slice : 25 : 33
                      ">
                    <span *ngIf="
                          geneMutation.status == 'Pathogenic' ||
                          geneMutation.status == 'Likely Pathogenic' ||
                          geneMutation.status == 'Detected'
                        " style="color: red; font-weight: 600px"><b>{{ geneMutation.status }}</b></span>
                    <span *ngIf="geneMutation.status == 'VUS'" style="color: black; font-weight: 600px"><b>{{
                        geneMutation.status }}</b></span>
                    <span *ngIf="
                          geneMutation.status == 'Not Detected' ||
                          geneMutation.status == 'Benige'
                        ">{{ geneMutation.status }}</span>
                  </td>
                </tr>
<br/>
              </table>
          <table class="staus_gene" [ngClass]="'bg_skyblue'" *ngIf="
                geneMutationlist != null &&
                geneMutationlist.length > 33
              ">
            <tr style="background: #bfe6fa">
              <td style="font-size: 18px !important; padding-left: 10px !important">
                Gene
              </td>
              <td *ngFor="
                    let geneMutation of geneMutationlist
                      | slice : 33 : 41
                  ">
                <i>{{ geneMutation.gene }}</i>
              </td>
            </tr>
            <tr>
              <td style="font-size: 18px !important; padding-left: 10px !important">
                <b>Status</b>
              </td>
              <td *ngFor="
                    let geneMutation of geneMutationlist
                      | slice : 33 : 41
                  ">
                <span *ngIf="
                      geneMutation.status == 'Pathogenic' ||
                      geneMutation.status == 'Likely Pathogenic' ||
                      geneMutation.status == 'Detected'
                    " style="color: red; font-weight: 600px"><b>{{ geneMutation.status }}</b></span>
                <span *ngIf="geneMutation.status == 'VUS'" style="color: black; font-weight: 600px"><b>{{
                    geneMutation.status }}</b></span>
                <span *ngIf="
                      geneMutation.status == 'Not Detected' ||
                      geneMutation.status == 'Benige'
                    ">{{ geneMutation.status }}</span>
              </td>
            </tr>
            <br/>
          </table>
              <h6 *ngIf="
                                                      (fusionGeneMutationList != null &&
                                                      fusionGeneMutationList.length > 0) "
                class="font-weight-bold clr-cyan text-uppercase" [ngClass]="'txt-black'">
                Gene Fusion
              </h6>
              <br/>
              <table class="staus_gene" *ngIf="
                                                                                      (fusionGeneMutationList != null &&
                                                                                      fusionGeneMutationList.length > 0)
                                                                                    " [ngClass]="'bg_skyblue'">
                <tr style="background: #bfe6fa">
                  <td style="font-size: 18px !important; padding-left: 10px !important">
                    Gene
                  </td>
                  <td *ngFor="
                                                                                          let geneMutation of fusionGeneMutationList
                                                                                            | slice : 0 : 8
                                                                                        ">
                    <i>{{ geneMutation.gene }}</i>
                  </td>
                </tr>
                <tr>
                  <td style="font-size: 18px !important; padding-left: 10px !important">
                    <b>Status</b>
                  </td>
                  <td *ngFor="
                                                                                          let geneMutation of fusionGeneMutationList
                                                                                            | slice : 0 : 8
                                                                                        ">
                    <span *ngIf="
                                                                                            geneMutation.status == 'Pathogenic' ||
                                                                                            geneMutation.status == 'Likely Pathogenic' ||
                                                                                            geneMutation.status == 'Detected'
                                                                                          "
                      style="color: red; font-weight: 600px"><b>{{
                        geneMutation.status }}</b></span>
                    <span *ngIf="geneMutation.status == 'VUS'" style="color: black; font-weight: 600px"><b>{{
                        geneMutation.status }}</b></span>
                    <span *ngIf="
                                                                                            geneMutation.status == 'Not Detected' ||
                                                                                            geneMutation.status == 'Benige'
                                                                                          ">{{ geneMutation.status }}</span>
                  </td>
                </tr>
<br/>
              </table>
              <br/>
              <table class="staus_gene" *ngIf="
                                                                                      (fusionGeneMutationList != null &&
                                                                                      fusionGeneMutationList.length > 8)
                                                                                    " [ngClass]="'bg_skyblue'">
                <tr style="background: #bfe6fa">
                  <td style="font-size: 18px !important; padding-left: 10px !important">
                    Gene
                  </td>
                  <td *ngFor="
                                                                                          let geneMutation of fusionGeneMutationList
                                                                                            | slice : 8 : 17
                                                                                        ">
                    <i>{{ geneMutation.gene }}</i>
                  </td>
                </tr>
                <tr>
                  <td style="font-size: 18px !important; padding-left: 10px !important">
                    <b>Status</b>
                  </td>
                  <td *ngFor="
                                                                                          let geneMutation of fusionGeneMutationList
                                                                                            | slice : 8 : 17
                                                                                        ">
                    <span *ngIf="
                                                                                            geneMutation.status == 'Pathogenic' ||
                                                                                            geneMutation.status == 'Likely Pathogenic' ||
                                                                                            geneMutation.status == 'Detected'
                                                                                          "
                      style="color: red; font-weight: 600px"><b>{{
                        geneMutation.status }}</b></span>
                    <span *ngIf="geneMutation.status == 'VUS'" style="color: black; font-weight: 600px"><b>{{
                        geneMutation.status }}</b></span>
                    <span *ngIf="
                                                                                            geneMutation.status == 'Not Detected' ||
                                                                                            geneMutation.status == 'Benige'
                                                                                          ">{{ geneMutation.status }}</span>
                  </td>
                </tr>
              </table>
            </div>


            <!--  -->
            <br/>
            <div *ngIf="
            report.disclaimerNote != ' ' &&
            report.disclaimerNote != null
          ">
        <h6 style="color: #000"><b>Note: </b></h6>
        <div style="font-size: 14px !important;text-align: justify" [innerHtml]="report.disclaimerNote"></div>
        <!-- <div class="py-2" [innerHtml]='report.disclaimerNote' style=" text-align: justify;font-size: 14px!important;"></div> -->

        <!-- <p>The data size we had received for this sample was inadequate. However, with the patient's best interest in mind we have further processed the sample. Kindly correlate clinically before making any treatment decisions.</p> -->
        <!-- <p>The data size we had received for this sample was inadequate. However, with the patient's best interest in mind we have further processed the sample and a false negative status of the result cannot be ruled out. Kindly correlate clinically before making any treatment decisions.</p> -->
      </div>





            <!--  -->
            <div class="row footer d-flex justify-content-between align-items-center">
              <!-- <div class="col">
                <div class="py-2">
                  <p>
                    <span class="font-weight-bold">Genomics Bihar,</span>
                  </p>
                  <p>Homi Bhabha cancer hospital & Research centre</p>
                  <p>Inside Srikrishna Medical College & Hospital</p>
                  <p>Umanagar, Muzaffarpur-842004.</p>
                  <p>Contact: ngs.mfp@gmail.com</p>
                </div>
              </div>
              <div class="col">
                <p class="text-right">
                  <img
                    src="../../../assets/tmc/tmc_footer.png"
                    alt="footer_image"
                    class="footer_img"
                  />
                </p>
              </div> -->
            </div>
          </div>



          <!-- page 2 -->
          <div id="page2" *ngIf="
                                                                    otherCountSNV == true ||
                                                                    reportPdfRes.allVariantsList.length > 0 ||
                                                                        (report.disclaimer != '<p></p>' &&
            report.disclaimer != null) || (report.addNotes != '<div></div>')
                                                                    " class="container position-relative page"
            style="background-color: white">
              <!-- Header -->
            <div class="d-flex justify-content-between logo_panel py-3">
              <div class="logo_part">
                <img
                style="width:240px!important" src="../../../assets/hcg-new-logo.jpeg"
                  alt=""
                  class="logo_first"
                />
              </div>
              <div style=" border-left: 2px solid #fab216;padding-top:10px;
              height: 120px;"></div>

              <div class="logo_part text-center" style="font-family:arial Mt!important">

                                <h5 style="font-weight: bolder;margin-bottom:0px!important;">Triesta Sciences</h5>
                <p style="font-size: 15px!important;">
                                  A Unit of Health Care Global Enterprises Ltd <br/>
                                  5th Floor, Tower 1, #8, P.Kalinga Rao Road,<br/>
                                  Sampangiram Nagar, Bengaluru-560027. Tel: 080-40206305
                                </p>
                <h6 style="font-weight: bolder;font-size:15px!important">Department of Molecular and Clinical Genomics</h6>
              </div>
              <div style=" border-left: 2px solid #fab216;padding-top:10px;
              height: 120px;"></div>
              <div class="logo_part">
                <img  style="width:240px!important; margin-top:40px!important"
                  src="../../../assets//oncoquer1.png"
                  alt=""
                  class="logo_three"
                />
              </div>
            </div>
              <div class="row header mt-1">
              <div></div>
              <div class="col">
                <div class="patient-detailes ml-auto float-right">
                  <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
                    <li>
                      Patient Id <br />
                      <span>{{ report.patient?.organisationPatientId }}</span>
                    </li>
                    <li class="ml-4">
                      Patient Name<br />
                      <span>{{ report.patient?.name }}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <!--  -->
              <div *ngIf="report.clientType == '4basecare_client'">
                <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
                  </div>
                </div>
                <div *ngIf="report.clientName == 'path_labs'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
                  </div>
                </div>
              </div>
            </div>

            <!--  -->

            <div class="row mt-3" *ngIf="
                                                                        otherCountSNV == true ||
                                                                        reportPdfRes.allVariantsList.length != 0
                                                                      ">
              <div class="col">
                <h6 class="font-weight-bold clr-cyan text-uppercase" [ngClass]="'txt-black'">
                  Variant Details:
                </h6>
              </div>
            </div>

            <div class="row" *ngIf="
                                                                        otherCountSNV == true ||
                                                                        reportPdfRes.allVariantsList.length != 0
                                                                      ">
              <div class="col-12">
                <table class="page_1 cust_table w-100 mt-3 varient_detailsy" [ngClass]="'table_variant-blue'">
                  <thead>
                    <tr>
                      <th>Gene</th>
                      <th>Variant Location</th>
                      <th>Variant Consequence</th>
                      <th>Clinical Significance</th>
                      <th>Variant Type</th>
                      <th>Reference</th>
                    </tr>
                  </thead>

                  <tr *ngFor="let variant of reportPdfRes.allVariantsList">
                    <td *ngIf="variant.significance != 'NA' && variant.significance != 'Not Classified'" style="padding-left: 3px !important">
                      <i>{{ variant.gene }}</i>
                    </td>
                    <td *ngIf="variant.significance != 'NA' && variant.significance != 'Not Classified'" style="padding-left: 3px !important">
                      <span *ngIf="variant?.variantType=='snv'">
                        {{ variant?.genomicAlteration }},<br />
                        {{ variant?.ensembleValue }},<br />
                        {{ variant?.exonNumber }}
                      </span>
                      <span *ngIf="variant?.variantType=='cna'">
                        chr{{ variant?.sample?.split(":")[0] }}:g.{{
                          variant.sample
                          .split(":")[1]
                          .replace("-", "_")
                          .split("|")[0]
                          }}
                      </span>
                      <span *ngIf="variant.variantType=='fusion'">
                        {{ variant?.leftBreakPoint }}<br />{{variant?.rightBreakPoint}}
                      </span>
                    </td>
                    <td *ngIf="variant?.significance != 'NA' && variant?.significance != 'Not Classified'" style="padding-left: 3px !important">
                      <span *ngIf="variant.variantType=='snv'">
                        <span *ngIf="variant?.selectedAAChangeknownGene">{{
                          variant?.selectedAAChangeknownGene|splitComma }}</span>, {{variant?.mutantBurdenPercent * 0.01 | percent}}
                        </span>
                      <span *ngIf="variant.variantType=='cna'">NA</span>
                      <span *ngIf="variant.variantType=='fusion'">NA</span>
                    </td>
                    <td *ngIf="variant?.significance != 'NA' && variant?.significance != 'Not Classified'" style="padding-left: 3px !important">
                      {{ variant?.significance }}
                    </td>
                    <td *ngIf="variant?.significance != 'NA' && variant?.significance != 'Not Classified'" style="padding-left: 3px !important">
                      <span *ngIf="variant.variantType=='snv'">
                        {{ variant?.exonicFuncknownGene }}
                      </span>
                      <span *ngIf="variant.variantType=='cna'">
                        Copy Number
                        {{ variant?.sample.split(":")[2] | titlecase }}
                        ({{ variant?.sample.split("|")[1].split(":")[0] }})
                      </span>
                      <span *ngIf="variant.variantType=='fusion'">Gene Fusions</span>
                    </td>
                    <td *ngIf="variant?.significance != 'NA' && variant?.significance != 'Not Classified'" style="padding-left: 3px !important">
                      {{ variant?.reference }}
                    </td>
                  </tr>
                </table>
                <p *ngIf="reportPdfRes.allVariantsList.length > 0 " style="font-size: 13px !important" class="mt-0">
                  <i> *NA: Not Applicable</i>
                </p>
              </div>
            </div>
            <!--  -->
            <div class="row mt-5" *ngIf="
                  !variantFlag &&
                  report.addNotes != null && report.addNotes != '<div></div>'
                ">
          <div class="col">
            <h6 class="py-2 h6_bg" [ngClass]="'yellow'">Additional Notes</h6>
            <div class="py-2" [innerHtml]="report.addNotes" style="text-align: justify; font-size: 14px !important"></div>
          </div>
        </div>

            <!--  -->
            <div class="row footer d-flex justify-content-between align-items-center">
              <!-- <div class="col">
                <div class="py-2">
                  <p>
                    <span class="font-weight-bold">Genomics Bihar,</span>
                  </p>
                  <p>Homi Bhabha cancer hospital & Research centre</p>
                  <p>Inside Srikrishna Medical College & Hospital</p>
                  <p>Umanagar, Muzaffarpur-842004.</p>
                  <p>Contact: ngs.mfp@gmail.com</p>
                </div>
              </div>
              <div class="col">
                <p class="text-right">
                  <img
                    src="../../../assets/tmc/tmc_footer.png"
                    alt="footer_image"
                    class="footer_img"
                  />
                </p>
              </div> -->
            </div>
          </div>


          <!-- page 3 -->
          <div id="page3" *ngIf="reportPdfRes.drugsResList.length > 0" class="container position-relative page"
            style="background-color: white">
            <div class="d-flex justify-content-between logo_panel py-3">
              <div class="logo_part">
                <img style="width:240px!important" src="../../../assets/hcg-new-logo.jpeg" alt="" class="logo_first" />
              </div>
              <div style=" border-left: 2px solid #fab216;padding-top:10px;
                        height: 120px;"></div>

              <div class="logo_part text-center" style="font-family:arial Mt!important">

                                <h5 style="font-weight: bolder;margin-bottom:0px!important;">Triesta Sciences</h5>
                <p style="font-size: 15px!important;">
                  A Unit of Health Care Global Enterprises Ltd <br />
                  5th Floor, Tower 1, #8, P.Kalinga Rao Road,<br />
                  Sampangiram Nagar, Bengaluru-560027. Tel: 080-40206305
                </p>
                <h6 style="font-weight: bolder;font-size:15px!important">Department of Molecular and Clinical Genomics</h6>
              </div>
              <div style=" border-left: 2px solid #fab216;padding-top:10px;
                        height: 120px;"></div>
              <div class="logo_part">
                <img style="width:240px!important; margin-top:40px!important" src="../../../assets//oncoquer1.png" alt=""
                  class="logo_three" />
              </div>
            </div>
            <div class="row header mt-1">
              <div>
                <!-- <div class="col-3">
                            <img src="../../../assets/logo.png" alt="" style="width: 140px" />
                          </div> -->
              </div>
              <div></div>
              <div class="col">
                <div class="patient-detailes ml-auto float-right">
                  <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
                    <li>
                      Patient Id <br />
                      <span>{{ report.patient?.organisationPatientId }}</span>
                    </li>
                    <li class="ml-4">
                      Patient Name<br />
                      <span>{{ report.patient?.name }}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <!--  -->
              <div *ngIf="report.clientType == '4basecare_client'">
                <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
                  </div>
                </div>
                <div *ngIf="report.clientName == 'path_labs'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
                  </div>
                </div>
              </div>
            </div>
            <!--  -->
            <div class="mar_top"></div>
            <!--  -->

            <h6 class="py-2 h6_bg" [ngClass]="'yellow'">
              Alterations With Clinically Relevant Therapies
            </h6>
            <!--  -->

            <div class="row">
              <div class="col-12">
                <table class="page_1 cust_table w-100 mt-3 varient_detailsy prognastictable1">
                  <thead>
                    <tr>
                      <th>Gene (Alterations) - Drug association</th>
                      <th>Summary</th>
                    </tr>
                  </thead>
                  <!-- SNV -->

                  <tbody>
                    <tr *ngFor="
                                                                          let drugsRes of reportPdfRes.drugsResList | slice : 0 : 4;
                                                                          let i = index
                                                                        ">
                      <td>

                            <i>{{ drugsRes.gene.replace("Fusion","") }}</i><span *ngIf="drugsRes.gene.includes('Fusion')">Fusion</span> {{ drugsRes.pposition?.replace(' ','') }} :
                            <br />
                            <span *ngFor="let drug of drugsRes.drugs; let i = index">
                              {{ drug.name
                              }}<span *ngIf="drugsRes.drugs.length - 1 != i">,</span>
                            </span>
                            - {{ drugsRes.association }}
                            <p>{{ drugsRes.cancerType }}</p>

                      </td>
                      <td>
                        <div [innerHtml]="drugsRes.summary"></div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div class="row footer">
              <!-- <div class="col-12">
                      <div class="pb-2 pl-4 text-center">
                        <div class="d-block">
                            <span class="font-weight-bold" style="font-size: 13px !important"
                              >4baseCare (Genomics Tarang ODC),</span
                            >
                          <span>
                            SJP2-S1-1F- C wing, Wipro Limited, SEZ, Sarjapur 2, Sy.No.69 (P),
                          </span>
                        </div>
                        <div class="d-block">
                          <span>Doddakannelli, Sarjapura Road,</span>
                          <span> Bengaluru 560035, KA, India</span></div>


                      </div>
                    </div>
                    <div class="col-12 pt-1 ">
                      <p  style="font-size: 8px!important; line-height: 14px!important;">{{footerDiscription}} </p>
                    </div> -->


            </div>
          </div>

          <!-- page 3_1 -->
          <div id="page3_1" *ngIf="reportPdfRes.drugsResList.length > 4" class="container position-relative page"
            style="background-color: white">
            <div class="d-flex justify-content-between logo_panel py-3">
              <div class="logo_part">
                <img style="width:240px!important" src="../../../assets/hcg-new-logo.jpeg" alt="" class="logo_first" />
              </div>
              <div style=" border-left: 2px solid #fab216;padding-top:10px;
                        height: 120px;"></div>

              <div class="logo_part text-center" style="font-family:arial Mt!important">

                                <h5 style="font-weight: bolder;margin-bottom:0px!important;">Triesta Sciences</h5>
                <p style="font-size: 15px!important;">
                  A Unit of Health Care Global Enterprises Ltd <br />
                  5th Floor, Tower 1, #8, P.Kalinga Rao Road,<br />
                  Sampangiram Nagar, Bengaluru-560027. Tel: 080-40206305
                </p>
                <h6 style="font-weight: bolder;font-size:15px!important">Department of Molecular and Clinical Genomics</h6>
              </div>
              <div style=" border-left: 2px solid #fab216;padding-top:10px;
                        height: 120px;"></div>
              <div class="logo_part">
                <img style="width:240px!important; margin-top:40px!important" src="../../../assets//oncoquer1.png" alt=""
                  class="logo_three" />
              </div>
            </div>
            <div class="row header mt-1">
              <div>
                <!-- <div class="col-3">
                            <img src="../../../assets/logo.png" alt="" style="width: 140px" />
                          </div> -->
              </div>
              <div></div>
              <div class="col">
                <div class="patient-detailes ml-auto float-right">
                  <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
                    <li>
                      Patient Id <br />
                      <span>{{ report.patient?.organisationPatientId }}</span>
                    </li>
                    <li class="ml-4">
                      Patient Name<br />
                      <span>{{ report.patient?.name }}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <!--  -->
              <div *ngIf="report.clientType == '4basecare_client'">
                <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
                  </div>
                </div>
                <div *ngIf="report.clientName == 'path_labs'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
                  </div>
                </div>
              </div>
            </div>
            <!--  -->
            <div class="mar_top"></div>
            <!--  -->

            <h6 class="py-2 h6_bg" [ngClass]="'yellow'">
              Alterations With Clinically Relevant Therapies
            </h6>
            <!--  -->


            <div class="row">
              <div class="col-12">
                <table class="page_1 cust_table w-100 mt-3 varient_detailsy prognastictable1">
                  <thead>
                    <tr>
                      <th>Gene (Alterations) - Drug association</th>
                      <th>Summary</th>
                    </tr>
                  </thead>
                  <!-- SNV -->

                  <tbody>
                    <tr *ngFor="
                                                                                    let drugsRes of reportPdfRes.drugsResList | slice : 4 : 8;
                                                                                    let i = index
                                                                                  ">
                      <td>

                            <i>{{ drugsRes.gene.replace("Fusion","") }}</i><span *ngIf="drugsRes.gene.includes('Fusion')">Fusion</span> {{ drugsRes.pposition?.replace(' ','') }} :
                            <br />
                            <span *ngFor="let drug of drugsRes.drugs; let i = index">
                              {{ drug.name
                              }}<span *ngIf="drugsRes.drugs.length - 1 != i">,</span>
                            </span>
                            - {{ drugsRes.association }}
                            <p>{{ drugsRes.cancerType }}</p>

                      </td>
                      <td>
                        <div [innerHtml]="drugsRes.summary"></div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <!--  -->

            <!--  -->
            <div class="row footer">
              <!-- <div class="col-12">
                      <div class="pb-2 pl-4 text-center">
                        <div class="d-block">
                            <span class="font-weight-bold" style="font-size: 13px !important"
                              >4baseCare (Genomics Tarang ODC),</span
                            >
                          <span>
                            SJP2-S1-1F- C wing, Wipro Limited, SEZ, Sarjapur 2, Sy.No.69 (P),
                          </span>
                        </div>
                        <div class="d-block">
                          <span>Doddakannelli, Sarjapura Road,</span>
                          <span> Bengaluru 560035, KA, India</span></div>


                      </div>
                    </div>
                    <div class="col-12 pt-1 ">
                      <p  style="font-size: 8px!important; line-height: 14px!important;">{{footerDiscription}} </p>
                    </div> -->


            </div>
          </div>

          <!-- page 3_2 -->
          <div id="page3_2" *ngIf="reportPdfRes.drugsResList.length > 8" class="container position-relative page"
            style="background-color: white">
            <div class="d-flex justify-content-between logo_panel py-3">
              <div class="logo_part">
                <img style="width:240px!important" src="../../../assets/hcg-new-logo.jpeg" alt="" class="logo_first" />
              </div>
              <div style=" border-left: 2px solid #fab216;padding-top:10px;
                        height: 120px;"></div>

              <div class="logo_part text-center" style="font-family:arial Mt!important">

                                <h5 style="font-weight: bolder;margin-bottom:0px!important;">Triesta Sciences</h5>
                <p style="font-size: 15px!important;">
                  A Unit of Health Care Global Enterprises Ltd <br />
                  5th Floor, Tower 1, #8, P.Kalinga Rao Road,<br />
                  Sampangiram Nagar, Bengaluru-560027. Tel: 080-40206305
                </p>
                <h6 style="font-weight: bolder;font-size:15px!important">Department of Molecular and Clinical Genomics</h6>
              </div>
              <div style=" border-left: 2px solid #fab216;padding-top:10px;
                        height: 120px;"></div>
              <div class="logo_part">
                <img style="width:240px!important; margin-top:40px!important" src="../../../assets//oncoquer1.png" alt=""
                  class="logo_three" />
              </div>
            </div>
            <div class="row header mt-1">
              <div>
                <!-- <div class="col-3">
                            <img src="../../../assets/logo.png" alt="" style="width: 140px" />
                          </div> -->
              </div>
              <div></div>
              <div class="col">
                <div class="patient-detailes ml-auto float-right">
                  <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
                    <li>
                      Patient Id <br />
                      <span>{{ report.patient?.organisationPatientId }}</span>
                    </li>
                    <li class="ml-4">
                      Patient Name<br />
                      <span>{{ report.patient?.name }}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <!--  -->
              <div *ngIf="report.clientType == '4basecare_client'">
                <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
                  </div>
                </div>
                <div *ngIf="report.clientName == 'path_labs'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
                  </div>
                </div>
              </div>
            </div>
            <!--  -->
            <div class="mar_top"></div>
            <!--  -->

            <h6 class="py-2 h6_bg" [ngClass]="'yellow'">
              Alterations With Clinically Relevant Therapies
            </h6>
            <!--  -->
            <div class="row">
              <div class="col-12">
                <table class="page_1 cust_table w-100 mt-3 varient_detailsy prognastictable1">
                  <thead>
                    <tr>
                      <th>Gene (Alterations) - Drug association</th>
                      <th>Summary</th>
                    </tr>
                  </thead>
                  <!-- SNV -->

                  <tbody>
                    <tr *ngFor="
                                                                                                        let drugsRes of reportPdfRes.drugsResList | slice : 8 : 12;
                                                                                                        let i = index
                                                                                                      ">
                      <td>

                            <i>{{ drugsRes.gene.replace("Fusion","") }}</i><span *ngIf="drugsRes.gene.includes('Fusion')">Fusion</span> {{ drugsRes.pposition?.replace(' ','')}} :
                            <br />
                            <span *ngFor="let drug of drugsRes.drugs; let i = index">
                              {{ drug.name
                              }}<span *ngIf="drugsRes.drugs.length - 1 != i">,</span>
                            </span>
                            - {{ drugsRes.association }}
                            <p>{{ drugsRes.cancerType }}</p>
                          </td>

                      <td>
                        <div [innerHtml]="drugsRes.summary"></div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <!--  -->

            <!--  -->
            <div class="row footer">



            </div>
          </div>

          <!-- page 3_3 -->
          <div id="page3_3" *ngIf="reportPdfRes.drugsResList.length > 12" class="container position-relative page"
            style="background-color: white">
            <div class="d-flex justify-content-between logo_panel py-3">
              <div class="logo_part">
                <img style="width:240px!important" src="../../../assets/hcg-new-logo.jpeg" alt="" class="logo_first" />
              </div>
              <div style=" border-left: 2px solid #fab216;padding-top:10px;
                        height: 120px;"></div>

              <div class="logo_part text-center" style="font-family:arial Mt!important">

                                <h5 style="font-weight: bolder;margin-bottom:0px!important;">Triesta Sciences</h5>
                <p style="font-size: 15px!important;">
                  A Unit of Health Care Global Enterprises Ltd <br />
                  5th Floor, Tower 1, #8, P.Kalinga Rao Road,<br />
                  Sampangiram Nagar, Bengaluru-560027. Tel: 080-40206305
                </p>
                <h6 style="font-weight: bolder;font-size:15px!important">Department of Molecular and Clinical Genomics</h6>
              </div>
              <div style=" border-left: 2px solid #fab216;padding-top:10px;
                        height: 120px;"></div>
              <div class="logo_part">
                <img style="width:240px!important; margin-top:40px!important" src="../../../assets//oncoquer1.png" alt=""
                  class="logo_three" />
              </div>
            </div>
            <div class="row header mt-1">
              <div>
                <!-- <div class="col-3">
                            <img src="../../../assets/logo.png" alt="" style="width: 140px" />
                          </div> -->
              </div>
              <div></div>
              <div class="col">
                <div class="patient-detailes ml-auto float-right">
                  <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
                    <li>
                      Patient Id <br />
                      <span>{{ report.patient?.organisationPatientId }}</span>
                    </li>
                    <li class="ml-4">
                      Patient Name<br />
                      <span>{{ report.patient?.name }}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <!--  -->
              <div *ngIf="report.clientType == '4basecare_client'">
                <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
                  </div>
                </div>
                <div *ngIf="report.clientName == 'path_labs'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
                  </div>
                </div>
              </div>
            </div>
            <!--  -->
            <div class="mar_top"></div>
            <!--  -->

            <h6 class="py-2 h6_bg" [ngClass]="'yellow'">
              Alterations With Clinically Relevant Therapies
            </h6>
            <!--  -->
            <div class="row">
              <div class="col-12">
                <table class="page_1 cust_table w-100 mt-3 varient_detailsy prognastictable1">
                  <thead>
                    <tr>
                      <th>Gene (Alterations) - Drug association</th>
                      <th>Summary</th>
                    </tr>
                  </thead>
                  <!-- SNV -->

                  <tbody>
                    <tr *ngFor="
                                                                                                                              let drugsRes of reportPdfRes.drugsResList | slice : 12 : 16;
                                                                                                                              let i = index
                                                                                                                            ">
                      <td>

                            <i>{{ drugsRes.gene.replace("Fusion","") }}</i><span *ngIf="drugsRes.gene.includes('Fusion')">Fusion</span> {{ drugsRes.pposition?.replace(' ','')}} :
                            <br />
                            <span *ngFor="let drug of drugsRes.drugs; let i = index">
                              {{ drug.name
                              }}<span *ngIf="drugsRes.drugs.length - 1 != i">,</span>
                            </span>
                            - {{ drugsRes.association }}
                            <p>{{ drugsRes.cancerType }}</p>
                          </td>

                      <td>
                        <div [innerHtml]="drugsRes.summary"></div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <!--  -->

            <!--  -->
            <div class="row footer">



            </div>
          </div>

          <!-- page 3_4 -->
          <div id="page3_4" *ngIf="reportPdfRes.drugsResList.length > 16" class="container position-relative page"
            style="background-color: white">
            <div class="d-flex justify-content-between logo_panel py-3">
              <div class="logo_part">
                <img style="width:240px!important" src="../../../assets/hcg-new-logo.jpeg" alt="" class="logo_first" />
              </div>
              <div style=" border-left: 2px solid #fab216;padding-top:10px;
                        height: 120px;"></div>

              <div class="logo_part text-center" style="font-family:arial Mt!important">

                                <h5 style="font-weight: bolder;margin-bottom:0px!important;">Triesta Sciences</h5>
                <p style="font-size: 15px!important;">
                  A Unit of Health Care Global Enterprises Ltd <br />
                  5th Floor, Tower 1, #8, P.Kalinga Rao Road,<br />
                  Sampangiram Nagar, Bengaluru-560027. Tel: 080-40206305
                </p>
                <h6 style="font-weight: bolder;font-size:15px!important">Department of Molecular and Clinical Genomics</h6>
              </div>
              <div style=" border-left: 2px solid #fab216;padding-top:10px;
                        height: 120px;"></div>
              <div class="logo_part">
                <img style="width:240px!important; margin-top:40px!important" src="../../../assets//oncoquer1.png" alt=""
                  class="logo_three" />
              </div>
            </div>
            <div class="row header mt-1">
              <div>
                <!-- <div class="col-3">
                            <img src="../../../assets/logo.png" alt="" style="width: 140px" />
                          </div> -->
              </div>
              <div></div>
              <div class="col">
                <div class="patient-detailes ml-auto float-right">
                  <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
                    <li>
                      Patient Id <br />
                      <span>{{ report.patient?.organisationPatientId }}</span>
                    </li>
                    <li class="ml-4">
                      Patient Name<br />
                      <span>{{ report.patient?.name }}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <!--  -->
              <div *ngIf="report.clientType == '4basecare_client'">
                <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
                  </div>
                </div>
                <div *ngIf="report.clientName == 'path_labs'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
                  </div>
                </div>
              </div>
            </div>
            <!--  -->
            <div class="mar_top"></div>
            <!--  -->

            <h6 class="py-2 h6_bg" [ngClass]="'yellow'">
              Alterations With Clinically Relevant Therapies
            </h6>
            <!--  -->
            <div class="row">
              <div class="col-12">
                <table class="page_1 cust_table w-100 mt-3 varient_detailsy prognastictable1">
                  <thead>
                    <tr>
                      <th>Gene (Alterations) - Drug association</th>
                      <th>Summary</th>
                    </tr>
                  </thead>
                  <!-- SNV -->

                  <tbody>
                    <tr *ngFor="
                                                                                                                    let drugsRes of reportPdfRes.drugsResList | slice : 16 : 20;
                                                                                                                    let i = index
                                                                                                                  ">
                      <td>

                            <i>{{ drugsRes.gene.replace("Fusion","") }}</i><span *ngIf="drugsRes.gene.includes('Fusion')">Fusion</span> {{ drugsRes.pposition?.replace(' ','')}} :
                            <br />
                            <span *ngFor="let drug of drugsRes.drugs; let i = index">
                              {{ drug.name
                              }}<span *ngIf="drugsRes.drugs.length - 1 != i">,</span>
                            </span>
                            - {{ drugsRes.association }}
                            <p>{{ drugsRes.cancerType }}</p>

                      </td>
                      <td>
                        <div [innerHtml]="drugsRes.summary"></div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <!--  -->

            <!--  -->
            <div class="row footer">



            </div>
          </div>

          <!-- page 3_5 -->
          <div id="page3_5" *ngIf="reportPdfRes.drugsResList.length > 20" class="container position-relative page"
            style="background-color: white">
            <div class="d-flex justify-content-between logo_panel py-3">
              <div class="logo_part">
                <img style="width:240px!important" src="../../../assets/hcg-new-logo.jpeg" alt="" class="logo_first" />
              </div>
              <div style=" border-left: 2px solid #fab216;padding-top:10px;
                        height: 120px;"></div>

              <div class="logo_part text-center" style="font-family:arial Mt!important">

                                <h5 style="font-weight: bolder;margin-bottom:0px!important;">Triesta Sciences</h5>
                <p style="font-size: 15px!important;">
                  A Unit of Health Care Global Enterprises Ltd <br />
                  5th Floor, Tower 1, #8, P.Kalinga Rao Road,<br />
                  Sampangiram Nagar, Bengaluru-560027. Tel: 080-40206305
                </p>
                <h6 style="font-weight: bolder;font-size:15px!important">Department of Molecular and Clinical Genomics</h6>
              </div>
              <div style=" border-left: 2px solid #fab216;padding-top:10px;
                        height: 120px;"></div>
              <div class="logo_part">
                <img style="width:240px!important; margin-top:40px!important" src="../../../assets//oncoquer1.png" alt=""
                  class="logo_three" />
              </div>
            </div>
            <div class="row header mt-1">
              <div>
                <!-- <div class="col-3">
                            <img src="../../../assets/logo.png" alt="" style="width: 140px" />
                          </div> -->
              </div>
              <div></div>
              <div class="col">
                <div class="patient-detailes ml-auto float-right">
                  <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
                    <li>
                      Patient Id <br />
                      <span>{{ report.patient?.organisationPatientId }}</span>
                    </li>
                    <li class="ml-4">
                      Patient Name<br />
                      <span>{{ report.patient?.name }}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <!--  -->
              <div *ngIf="report.clientType == '4basecare_client'">
                <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
                  </div>
                </div>
                <div *ngIf="report.clientName == 'path_labs'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
                  </div>
                </div>
              </div>
            </div>
            <!--  -->
            <div class="mar_top"></div>
            <!--  -->

            <h6 class="py-2 h6_bg" [ngClass]="'yellow'">
              Alterations With Clinically Relevant Therapies
            </h6>
            <!--  -->
            <div class="row">
              <div class="col-12">
                <table class="page_1 cust_table w-100 mt-3 varient_detailsy prognastictable1">
                  <thead>
                    <tr>
                      <th>Gene (Alterations) - Drug association</th>
                      <th>Summary</th>
                    </tr>
                  </thead>
                  <!-- SNV -->

                  <tbody>
                    <tr *ngFor="
                                let drugsRes of reportPdfRes.drugsResList | slice : 20 : 24; let i = index ">
                      <td>

                            <i>{{ drugsRes.gene.replace("Fusion","") }}</i><span *ngIf="drugsRes.gene.includes('Fusion')">Fusion</span> {{ drugsRes.pposition?.replace(' ','')}} :
                            <br />
                            <span *ngFor="let drug of drugsRes.drugs; let i = index">
                              {{ drug.name
                              }}<span *ngIf="drugsRes.drugs.length - 1 != i">,</span>
                            </span>
                            - {{ drugsRes.association }}
                            <p>{{ drugsRes.cancerType }}</p>

                      </td>
                      <td>
                        <div [innerHtml]="drugsRes.summary"></div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <!--  -->

            <!--  -->
            <div class="row footer">



            </div>
          </div>

          <!-- page 3_6 -->
          <div id="page3_6" *ngIf="reportPdfRes.drugsResList.length > 24" class="container position-relative page"
            style="background-color: white">
            <div class="d-flex justify-content-between logo_panel py-3">
              <div class="logo_part">
                <img style="width:240px!important" src="../../../assets/hcg-new-logo.jpeg" alt="" class="logo_first" />
              </div>
              <div style=" border-left: 2px solid #fab216;padding-top:10px;
                        height: 120px;"></div>

              <div class="logo_part text-center" style="font-family:arial Mt!important">

                                <h5 style="font-weight: bolder;margin-bottom:0px!important;">Triesta Sciences</h5>
                <p style="font-size: 15px!important;">
                  A Unit of Health Care Global Enterprises Ltd <br />
                  5th Floor, Tower 1, #8, P.Kalinga Rao Road,<br />
                  Sampangiram Nagar, Bengaluru-560027. Tel: 080-40206305
                </p>
                <h6 style="font-weight: bolder;font-size:15px!important">Department of Molecular and Clinical Genomics</h6>
              </div>
              <div style=" border-left: 2px solid #fab216;padding-top:10px;
                        height: 120px;"></div>
              <div class="logo_part">
                <img style="width:240px!important; margin-top:40px!important" src="../../../assets//oncoquer1.png" alt=""
                  class="logo_three" />
              </div>
            </div>
            <div class="row header mt-1">
              <div>
                <!-- <div class="col-3">
                            <img src="../../../assets/logo.png" alt="" style="width: 140px" />
                          </div> -->
              </div>
              <div></div>
              <div class="col">
                <div class="patient-detailes ml-auto float-right">
                  <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
                    <li>
                      Patient Id <br />
                      <span>{{ report.patient?.organisationPatientId }}</span>
                    </li>
                    <li class="ml-4">
                      Patient Name<br />
                      <span>{{ report.patient?.name }}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <!--  -->
              <div *ngIf="report.clientType == '4basecare_client'">
                <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
                  </div>
                </div>
                <div *ngIf="report.clientName == 'path_labs'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
                  </div>
                </div>
              </div>
            </div>
            <!--  -->
            <div class="mar_top"></div>
            <!--  -->

            <h6 class="py-2 h6_bg" [ngClass]="'yellow'">
              Alterations With Clinically Relevant Therapies
            </h6>
            <!--  -->
            <div class="row">
              <div class="col-12">
                <table class="page_1 cust_table w-100 mt-3 varient_detailsy prognastictable1">
                  <thead>
                    <tr>
                      <th>Gene (Alterations) - Drug association</th>
                      <th>Summary</th>
                    </tr>
                  </thead>
                  <!-- SNV -->

                  <tbody>
                    <tr *ngFor=" let drugsRes of reportPdfRes.drugsResList | slice : 24 : 28; let i = index ">
                      <td>

                            <i>{{ drugsRes.gene.replace("Fusion","") }}</i><span *ngIf="drugsRes.gene.includes('Fusion')">Fusion</span> {{ drugsRes.pposition?.replace(' ','')}} :
                            <br />
                            <span *ngFor="let drug of drugsRes.drugs; let i = index">
                              {{ drug.name
                              }}<span *ngIf="drugsRes.drugs.length - 1 != i">,</span>
                            </span>
                            - {{ drugsRes.association }}
                            <p>{{ drugsRes.cancerType }}</p>

                      </td>
                      <td>
                        <div [innerHtml]="drugsRes.summary"></div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <!--  -->

            <!--  -->
            <div class="row footer">



            </div>
          </div>

          <!-- page 3_7 -->
          <div id="page3_7" *ngIf="reportPdfRes.drugsResList.length > 28" class="container position-relative page"
            style="background-color: white">
            <div class="d-flex justify-content-between logo_panel py-3">
              <div class="logo_part">
                <img style="width:240px!important" src="../../../assets/hcg-new-logo.jpeg" alt="" class="logo_first" />
              </div>
              <div style=" border-left: 2px solid #fab216;padding-top:10px;
                        height: 120px;"></div>

              <div class="logo_part text-center" style="font-family:arial Mt!important">

                                <h5 style="font-weight: bolder;margin-bottom:0px!important;">Triesta Sciences</h5>
                <p style="font-size: 15px!important;">
                  A Unit of Health Care Global Enterprises Ltd <br />
                  5th Floor, Tower 1, #8, P.Kalinga Rao Road,<br />
                  Sampangiram Nagar, Bengaluru-560027. Tel: 080-40206305
                </p>
                <h6 style="font-weight: bolder;font-size:15px!important">Department of Molecular and Clinical Genomics</h6>
              </div>
              <div style=" border-left: 2px solid #fab216;padding-top:10px;
                        height: 120px;"></div>
              <div class="logo_part">
                <img style="width:240px!important; margin-top:40px!important" src="../../../assets//oncoquer1.png" alt=""
                  class="logo_three" />
              </div>
            </div>
            <div class="row header mt-1">
              <div>
                <!-- <div class="col-3">
                            <img src="../../../assets/logo.png" alt="" style="width: 140px" />
                          </div> -->
              </div>
              <div></div>
              <div class="col">
                <div class="patient-detailes ml-auto float-right">
                  <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
                    <li>
                      Patient Id <br />
                      <span>{{ report.patient?.organisationPatientId }}</span>
                    </li>
                    <li class="ml-4">
                      Patient Name<br />
                      <span>{{ report.patient?.name }}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <!--  -->
              <div *ngIf="report.clientType == '4basecare_client'">
                <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
                  </div>
                </div>
                <div *ngIf="report.clientName == 'path_labs'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
                  </div>
                </div>
              </div>
            </div>
            <!--  -->
            <div class="mar_top"></div>
            <!--  -->

            <h6 class="py-2 h6_bg" [ngClass]="'yellow'">
              Alterations With Clinically Relevant Therapies
            </h6>
            <!--  -->
            <div class="row">
              <div class="col-12">
                <table class="page_1 cust_table w-100 mt-3 varient_detailsy prognastictable1">
                  <thead>
                    <tr>
                      <th>Gene (Alterations) - Drug association</th>
                      <th>Summary</th>
                    </tr>
                  </thead>
                  <!-- SNV -->

                  <tbody>
                    <tr
                      *ngFor="
                                                                                                                                                                                    let drugsRes of reportPdfRes.drugsResList | slice : 28 : 32;
                                                                                                                                                                                    let i = index
                                                                                                                                                                                  ">
                      <td>

                            <i>{{ drugsRes.gene.replace("Fusion","") }}</i><span *ngIf="drugsRes.gene.includes('Fusion')">Fusion</span> {{ drugsRes.pposition?.replace(' ','')}} :
                            <br />
                            <span *ngFor="let drug of drugsRes.drugs; let i = index">
                              {{ drug.name
                              }}<span *ngIf="drugsRes.drugs.length - 1 != i">,</span>
                            </span>
                            - {{ drugsRes.association }}
                            <p>{{ drugsRes.cancerType }}</p>

                      </td>
                      <td>
                        <div [innerHtml]="drugsRes.summary"></div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <!--  -->

            <!--  -->
            <div class="row footer">



            </div>
          </div>

          <!-- page 3_8 -->
          <div id="page3_8" *ngIf="reportPdfRes.drugsResList.length > 32" class="container position-relative page"
            style="background-color: white">
            <div class="d-flex justify-content-between logo_panel py-3">
              <div class="logo_part">
                <img style="width:240px!important" src="../../../assets/hcg-new-logo.jpeg" alt="" class="logo_first" />
              </div>
              <div style=" border-left: 2px solid #fab216;padding-top:10px;
                        height: 120px;"></div>

              <div class="logo_part text-center" style="font-family:arial Mt!important">

                                <h5 style="font-weight: bolder;margin-bottom:0px!important;">Triesta Sciences</h5>
                <p style="font-size: 15px!important;">
                  A Unit of Health Care Global Enterprises Ltd <br />
                  5th Floor, Tower 1, #8, P.Kalinga Rao Road,<br />
                  Sampangiram Nagar, Bengaluru-560027. Tel: 080-40206305
                </p>
                <h6 style="font-weight: bolder;font-size:15px!important">Department of Molecular and Clinical Genomics</h6>
              </div>
              <div style=" border-left: 2px solid #fab216;padding-top:10px;
                        height: 120px;"></div>
              <div class="logo_part">
                <img style="width:240px!important; margin-top:40px!important" src="../../../assets//oncoquer1.png" alt=""
                  class="logo_three" />
              </div>
            </div>
            <div class="row header mt-1">
              <div>
                <!-- <div class="col-3">
                            <img src="../../../assets/logo.png" alt="" style="width: 140px" />
                          </div> -->
              </div>
              <div></div>
              <div class="col">
                <div class="patient-detailes ml-auto float-right">
                  <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
                    <li>
                      Patient Id <br />
                      <span>{{ report.patient?.organisationPatientId }}</span>
                    </li>
                    <li class="ml-4">
                      Patient Name<br />
                      <span>{{ report.patient?.name }}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <!--  -->
              <div *ngIf="report.clientType == '4basecare_client'">
                <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
                  </div>
                </div>
                <div *ngIf="report.clientName == 'path_labs'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
                  </div>
                </div>
              </div>
            </div>
            <!--  -->
            <div class="mar_top"></div>
            <!--  -->

            <h6 class="py-2 h6_bg" [ngClass]="'yellow'">
              Alterations With Clinically Relevant Therapies
            </h6>
            <!--  -->
            <div class="row">
              <div class="col-12">
                <table class="page_1 cust_table w-100 mt-3 varient_detailsy prognastictable1">
                  <thead>
                    <tr>
                      <th>Gene (Alterations) - Drug association</th>
                      <th>Summary</th>
                    </tr>
                  </thead>
                  <!-- SNV -->

                  <tbody>
                    <tr *ngFor=" let drugsRes of reportPdfRes.drugsResList | slice : 32 : 36; let i = index ">
                      <td>

                            <i>{{ drugsRes.gene.replace("Fusion","") }}</i><span *ngIf="drugsRes.gene.includes('Fusion')">Fusion</span> {{ drugsRes.pposition?.replace(' ','')}} :
                            <br />
                            <span *ngFor="let drug of drugsRes.drugs; let i = index">
                              {{ drug.name
                              }}<span *ngIf="drugsRes.drugs.length - 1 != i">,</span>
                            </span>
                            - {{ drugsRes.association }}
                            <p>{{ drugsRes.cancerType }}</p>

                      </td>
                      <td>
                        <div [innerHtml]="drugsRes.summary"></div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <!--  -->

            <!--  -->
            <div class="row footer">



            </div>
          </div>

          <!-- page 3_9 -->
          <div id="page3_9" *ngIf="reportPdfRes.drugsResList.length > 36" class="container position-relative page"
            style="background-color: white">
            <div class="d-flex justify-content-between logo_panel py-3">
              <div class="logo_part">
                <img style="width:240px!important" src="../../../assets/hcg-new-logo.jpeg" alt="" class="logo_first" />
              </div>
              <div style=" border-left: 2px solid #fab216;padding-top:10px;
                                  height: 120px;"></div>

              <div class="logo_part text-center" style="font-family:arial Mt!important">

                                <h5 style="font-weight: bolder;margin-bottom:0px!important;">Triesta Sciences</h5>
                <p style="font-size: 15px!important;">
                  A Unit of Health Care Global Enterprises Ltd <br />
                  5th Floor, Tower 1, #8, P.Kalinga Rao Road,<br />
                  Sampangiram Nagar, Bengaluru-560027. Tel: 080-40206305
                </p>
                <h6 style="font-weight: bolder;font-size:15px!important">Department of Molecular and Clinical Genomics</h6>
              </div>
              <div style=" border-left: 2px solid #fab216;padding-top:10px;
                                  height: 120px;"></div>
              <div class="logo_part">
                <img style="width:240px!important; margin-top:40px!important" src="../../../assets//oncoquer1.png" alt=""
                  class="logo_three" />
              </div>
            </div>
            <div class="row header mt-1">
              <div>
                <!-- <div class="col-3">
                                      <img src="../../../assets/logo.png" alt="" style="width: 140px" />
                                    </div> -->
              </div>
              <div></div>
              <div class="col">
                <div class="patient-detailes ml-auto float-right">
                  <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
                    <li>
                      Patient Id <br />
                      <span>{{ report.patient?.organisationPatientId }}</span>
                    </li>
                    <li class="ml-4">
                      Patient Name<br />
                      <span>{{ report.patient?.name }}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <!--  -->
              <div *ngIf="report.clientType == '4basecare_client'">
                <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
                  </div>
                </div>
                <div *ngIf="report.clientName == 'path_labs'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
                  </div>
                </div>
              </div>
            </div>
            <!--  -->
            <div class="mar_top"></div>
            <!--  -->

            <h6 class="py-2 h6_bg" [ngClass]="'yellow'">
              Alterations With Clinically Relevant Therapies
            </h6>
            <!--  -->
            <div class="row">
              <div class="col-12">
                <table class="page_1 cust_table w-100 mt-3 varient_detailsy prognastictable1">
                  <thead>
                    <tr>
                      <th>Gene (Alterations) - Drug association</th>
                      <th>Summary</th>
                    </tr>
                  </thead>
                  <!-- SNV -->

                  <tbody>
                    <tr *ngFor=" let drugsRes of reportPdfRes.drugsResList | slice : 36 : 40; let i = index ">
                      <td>

                            <i>{{ drugsRes.gene.replace("Fusion","") }}</i><span *ngIf="drugsRes.gene.includes('Fusion')">Fusion</span> {{ drugsRes.pposition?.replace(' ','')}} :
                            <br />
                            <span *ngFor="let drug of drugsRes.drugs; let i = index">
                              {{ drug.name
                              }}<span *ngIf="drugsRes.drugs.length - 1 != i">,</span>
                            </span>
                            - {{ drugsRes.association }}
                            <p>{{ drugsRes.cancerType }}</p>

                      </td>
                      <td>
                        <div [innerHtml]="drugsRes.summary"></div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <!--  -->

            <!--  -->
            <div class="row footer">



            </div>
          </div>


          <!-- page 4 -->
          <div id="page4" *ngIf="prognasticCount>0" class="container position-relative page" style="background-color: white">
              <!-- Header -->
            <div class="d-flex justify-content-between logo_panel py-3">
              <div class="logo_part">
                <img
                style="width:240px!important" src="../../../assets/hcg-new-logo.jpeg"
                  alt=""
                  class="logo_first"
                />
              </div>
              <div style=" border-left: 2px solid #fab216;padding-top:10px;
              height: 120px;"></div>

              <div class="logo_part text-center" style="font-family:arial Mt!important">

                                <h5 style="font-weight: bolder;margin-bottom:0px!important;">Triesta Sciences</h5>
                <p style="font-size: 15px!important;">
                  A Unit of Health Care Global Enterprises Ltd <br/>
                  5th Floor, Tower 1, #8, P.Kalinga Rao Road,<br/>
                  Sampangiram Nagar, Bengaluru-560027. Tel: 080-40206305
                </p>
                <h6 style="font-weight: bolder;font-size:15px!important">Department of Molecular and Clinical Genomics</h6>
              </div>
              <div style=" border-left: 2px solid #fab216;padding-top:10px;
              height: 120px;"></div>
              <div class="logo_part">
                <img  style="width:240px!important; margin-top:40px!important"
                  src="../../../assets//oncoquer1.png"
                  alt=""
                  class="logo_three"
                />
              </div>
            </div>
              <div class="row header mt-1">
              <div></div>
              <div class="col">
                <div class="patient-detailes ml-auto float-right">
                  <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
                    <li>
                      Patient Id <br />
                      <span>{{ report.patient?.organisationPatientId }}</span>
                    </li>
                    <li class="ml-4">
                      Patient Name<br />
                      <span>{{ report.patient?.name }}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <!--  -->
              <div *ngIf="report.clientType == '4basecare_client'">
                <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
                  </div>
                </div>
                <div *ngIf="report.clientName == 'path_labs'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
                  </div>
                </div>
              </div>
            </div>
            <!--  -->
            <div class="mar_top"></div>
            <!--  -->
            <div *ngIf="prognasticCount>0">
              <div>
                <h6 class="py-2 h6_bg" [ngClass]="'yellow'"> Alterations associated with prognostic significance</h6>

              </div>

              <div class="row">
                <div class="col-12">
                  <table class="page_1 cust_table w-100 mt-3 varient_detailsy prognastictable" [ngClass]="'table_variant-blue'">
                    <thead>
                      <tr>
                        <th>Gene (Alterations)</th>
                        <th>Prognostic Significance</th>
                        <th>Summary</th>

                      </tr>
                    </thead>

                    <ng-container *ngFor=" let prog of reportPdfRes.prognosticAlterations ">
                      <tr>
                        <td style="padding-left: 3px !important">
                          <i>{{ prog.gene }}&nbsp;</i> <span [innerHtml]="prog.pOrcDot"></span>
                        </td>
                        <td style="padding-left: 3px !important">
                          {{prog.prognosticSignificance}}
                        </td>
                        <td style="padding-left: 3px !important">
                          <span [innerHtml]="prog.description"></span>
                        </td>

                      </tr>
                    </ng-container>

                  </table>
                </div>
              </div>
            </div>


            <!--  -->
            <div class="row footer d-flex justify-content-between align-items-center">
              <!-- <div class="col">
                <div class="py-2">
                  <p>
                    <span class="font-weight-bold">Genomics Bihar,</span>
                  </p>
                  <p>Homi Bhabha cancer hospital & Research centre</p>
                  <p>Inside Srikrishna Medical College & Hospital</p>
                  <p>Umanagar, Muzaffarpur-842004.</p>
                  <p>Contact: ngs.mfp@gmail.com</p>
                </div>
              </div>
              <div class="col">
                <p class="text-right">
                  <img
                    src="../../../assets/tmc/tmc_footer.png"
                    alt="footer_image"
                    class="footer_img"
                  />
                </p>
              </div> -->
            </div>
          </div>

          <!-- Page 5 -->
          <div id="page5" class="container position-relative page" style="background-color: white">
              <!-- Header -->
            <div class="d-flex justify-content-between logo_panel py-3">
              <div class="logo_part">
                <img
                style="width:240px!important" src="../../../assets/hcg-new-logo.jpeg"
                  alt=""
                  class="logo_first"
                />
              </div>
              <div style=" border-left: 2px solid #fab216;padding-top:10px;
              height: 120px;"></div>

              <div class="logo_part text-center" style="font-family:arial Mt!important">

                                <h5 style="font-weight: bolder;margin-bottom:0px!important;">Triesta Sciences</h5>
                <p style="font-size: 15px!important;">
                  A Unit of Health Care Global Enterprises Ltd <br/>
                  5th Floor, Tower 1, #8, P.Kalinga Rao Road,<br/>
                  Sampangiram Nagar, Bengaluru-560027. Tel: 080-40206305
                </p>
                <h6 style="font-weight: bolder;font-size:15px!important">Department of Molecular and Clinical Genomics</h6>
              </div>
              <div style=" border-left: 2px solid #fab216;padding-top:10px;
              height: 120px;"></div>
              <div class="logo_part">
                <img  style="width:240px!important; margin-top:40px!important"
                  src="../../../assets//oncoquer1.png"
                  alt=""
                  class="logo_three"
                />
              </div>
            </div>
              <div class="row header mt-1">
              <div></div>
              <div class="col">
                <div class="patient-detailes ml-auto float-right">
                  <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
                    <li>
                      Patient Id <br />
                      <span>{{ report.patient?.organisationPatientId }}</span>
                    </li>
                    <li class="ml-4">
                      Patient Name<br />
                      <span>{{ report.patient?.name }}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <!--  -->
              <div *ngIf="report.clientType == '4basecare_client'">
                <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
                  </div>
                </div>
                <div *ngIf="report.clientName == 'path_labs'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
                  </div>
                </div>
              </div>
            </div>
            <!--  -->
            <div class="mar_top"></div>
            <!--  -->

            <h6 class="py-2 h6_bg" [ngClass]="'yellow'">References</h6>
            <!--  -->
            <div class="row">
              <div class="col">
                <div class="disc">
                  <div class="ref_data mt-4">
                    <ul class="break-word" style="text-align: justify">
                      <li *ngFor="let referenceArticle of reportPdfRes.referenceArticles" style="
                                                                                  text-align: justify;
                                                                                  /* white-space:pre-wrap; */
                                                                                  word-break: break-word;
                                                                                ">
                        {{ referenceArticle }}
                      </li>
                    </ul>
                  </div>
                </div>

              </div>
            </div>

            <div class="row pr-2 above-footer">
              <div class="col-4">
                <!-- <img
                  src="../../../assets/HCG/vijay.png"
                  alt=""
                  style="padding-top:20px;margin-left:20px;padding-left: 60px; padding-right: 60px; width: 90%"
                  class="mb-2"
                /> -->
                <span style="padding-left: 126px; font-weight: bolder"
                  >Dr. Vijay.K, PhD<br /><span
                    style="padding-left: 63px; font-weight: bolder"
                    >Senior Scientist I-Clinical Diagnostics</span
                  ></span
                >
              </div>
              <div class="col-4">
                <!--<img
                   src="../../../assets/HCG/gautam.png"
                  alt=""
                  style="magin-left:20px;padding-left: 60px; padding-right: 60px; width: 90%"
                  class="mb-2"
                /> -->
                <span style="padding-left: 67px; font-weight: bolder"
                  >Dr. Gautam Balaram, MBBS, MD<br /><span
                    style="padding-left: 100px; font-weight: bolder"
                    >Clinical Pathologist</span
                  ></span
                >
              </div>
              <div class="col-4">
                <!-- <img
                  src="../../../assets/HCG/mithua.png"
                  alt=""
                  style="padding-top:30px;padding-left: 60px; padding-right: 60px; width: 100%"
                  class="mb-2"
                /> -->
                <span style="padding-left: 97px; font-weight: bolder"
                  >Dr. Mithua Ghosh, PhD, PD<br /><span
                    style="padding-left: 90px; font-weight: bolder"
                    >Director-Clinical Diagnostics</span
                  ></span
                >
              </div>

            </div>
            <!--  -->
            <div class="row footer d-flex justify-content-between align-items-center">
              <!-- <div class="col">
                <div class="py-2">
                  <p>
                    <span class="font-weight-bold">Genomics Bihar,</span>
                  </p>
                  <p>Homi Bhabha cancer hospital & Research centre</p>
                  <p>Inside Srikrishna Medical College & Hospital</p>
                  <p>Umanagar, Muzaffarpur-842004.</p>
                  <p>Contact: ngs.mfp@gmail.com</p>
                </div>
              </div>
              <div class="col">
                <p class="text-right">
                  <img
                    src="../../../assets/tmc/tmc_footer.png"
                    alt="footer_image"
                    class="footer_img"
                  />
                </p>
              </div> -->
            </div>
          </div>

          <!-- Page 6 -->
          <div id="page6" class="container position-relative page" style="background-color: white">
              <!-- Header -->
            <div class="d-flex justify-content-between logo_panel py-3">
              <div class="logo_part">
                <img
                style="width:240px!important" src="../../../assets/hcg-new-logo.jpeg"
                  alt=""
                  class="logo_first"
                />
              </div>
              <div style=" border-left: 2px solid #fab216;padding-top:10px;
              height: 120px;"></div>

              <div class="logo_part text-center" style="font-family:arial Mt!important">

                                <h5 style="font-weight: bolder;margin-bottom:0px!important;">Triesta Sciences</h5>
                <p style="font-size: 15px!important;">
                  A Unit of Health Care Global Enterprises Ltd <br/>
                  5th Floor, Tower 1, #8, P.Kalinga Rao Road,<br/>
                  Sampangiram Nagar, Bengaluru-560027. Tel: 080-40206305
                </p>
                <h6 style="font-weight: bolder;font-size:15px!important">Department of Molecular and Clinical Genomics</h6>
              </div>
              <div style=" border-left: 2px solid #fab216;padding-top:10px;
              height: 120px;"></div>
              <div class="logo_part">
                <img  style="width:240px!important; margin-top:40px!important"
                  src="../../../assets//oncoquer1.png"
                  alt=""
                  class="logo_three"
                />
              </div>
            </div>
              <div class="row header mt-1">
              <div></div>
              <div class="col">
                <div class="patient-detailes ml-auto float-right">
                  <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
                    <li>
                      Patient Id <br />
                      <span>{{ report.patient?.organisationPatientId }}</span>
                    </li>
                    <li class="ml-4">
                      Patient Name<br />
                      <span>{{ report.patient?.name }}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <!--  -->
              <div *ngIf="report.clientType == '4basecare_client'">
                <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
                  </div>
                </div>
                <div *ngIf="report.clientName == 'path_labs'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
                  </div>
                </div>
              </div>
            </div>
            <!--  -->
            <div class="mar_top"></div>
            <!--  -->

            <div class="">
              <h6 class="py-2 h6_bg" [ngClass]="'yellow'">TEST DESCRIPTION</h6>
              <p class="mb-2" style="text-align:justify">
                <span class="clr-cyan pl-2" [ngClass]="'txt-yellow'">OncQuer<sup>TM</sup> Assist Panel</span>
                is a Next Generation Sequencing based test which identifies
                genetic alterations in a comprehensive panel of well curated 72  genes which are having an impact response to approved
                therapy for a particular cancer type. Some of the alterations
                detected may have bearing on prognosis and/or therapeutic options
                and may provide relevant information that allows
                oncologists/clinicians to consider various lines of
                targeted treatment for the patient.
              </p>
            </div>

            <!--  -->
            <div class="row">
              <div class="col">
                <div class="">
                  <h6 class="py-2 h6_bg" [ngClass]="'yellow'">GENES EVALUATED</h6>
                  <p>
                    <span class="clr-cyan pl-2" [ngClass]="'txt-yellow'">OncQuer<sup>TM</sup> Assist Panel</span>
                    detects mutations (SNVs and Short InDels), Copy Number Variations (CNVs),
                    Gene Fusions and splice variants in the 72 genes:
                    <!-- detects mutations (SNVs and Short Indels), Copy Number Variations
                                                                          (CNVs), gene fusions and splice variants in the 53 genes : -->
                  </p>
                </div>
                <!--  -->
                <div class="row mx-0">
                  <div class="col-12 px-0">
                    <div class="genes_info">
                      <div class="title py-2">
                        <h6 class="mb-0 d-inline-block border-bottom py-2">
                          <b>SNVs, SHORT INDELS and CNVs
                          </b> Covered in
                          <span class="clr-cyan pl-2" [ngClass]="'txt-yellow'">OncQuer<sup>TM</sup> Assist Panel</span>
                        </h6>
                      </div>
                      <div class="genes_body" [ngClass]="'gene-bg_blue'">
                        <i>
                          <div class="genes_list">
                            <span>ABL1</span><span>ALK</span><span>APC</span>
                            <span>AR</span><span>ATM</span><span>BARD1</span>
                            <span>BMPR1A</span><span>BRAF</span><span>BRCA1</span>
                            <span>BRCA2</span>
                            <!-- <span>ABL1</span><span>ALK</span><span>AR</span
                                                                                  ><span>ATM</span><span>BARD1</span><span>BRAF</span
                                                                                  ><span>BRCA1</span><span>BRCA2</span><span>BRIP1</span
                                                                                  ><span>CDK12</span> -->
                          </div>
                          <div class="genes_list bg-white">
                            <span>BRIP1</span><span>CDK12</span><span>CDK4</span>
                            <span>CDK6</span><span>CDKN2A</span><span>CHEK1</span>
                            <span>CHEK2</span><span>CTNNB1</span><span>EGFR</span>
                            <span>EPCAM</span>
                            <!-- <span>CDK4</span><span>CDK6</span><span>CDKN2A</span
                                                                                  ><span>CHEK1</span><span>CHEK2</span><span>EGFR</span
                                                                                  ><span>EPCAM</span><span>ERBB2</span><span>ERBB3</span
                                                                                  ><span>EZH2</span> -->
                          </div>
                          <div class="genes_list">
                            <span>ERBB2</span><span>ERBB3</span><span>EZH2</span>
                            <span>FANCL</span><span>FGFR1</span><span>FGFR2</span>
                            <span>FGFR3</span><span>GAPDH</span><span>IDH1</span>
                            <span>IDH2</span>
                            <!-- <span>FANCL</span><span>FGFR3</span><span>GAPDH</span
                                                                                  ><span>IDH1</span><span>IDH2</span><span>JAK2</span
                                                                                  ><span>KIT</span><span>KRAS</span><span>MAP2K1</span
                                                                                  ><span>MAP2K2</span> -->
                          </div>
                          <div class="genes_list">
                            <span>JAK2</span><span>KIT</span><span>KRAS</span>
                            <span>MAP2K1</span><span>MAP2K2</span><span>MDM2</span>
                            <span>MET</span><span>MLH1</span><span>MLH3</span>
                            <span>MSH2</span>
                            <!-- <span>MDM2</span><span>MET</span><span>MLH1</span
                                                                                  ><span>MLH3</span><span>MSH2</span><span>MSH6</span
                                                                                  ><span>NRAS</span><span>PALB2</span><span>PDGFRA</span
                                                                                  ><span>PDGFRB</span> -->
                          </div>
                          <div class="genes_list">
                            <span>MSH6</span><span>MUTYH</span><span>NRAS</span>
                            <span>PALB2</span><span>PDGFRA</span><span>PDGFRB</span>
                            <span>PIK3CA</span><span>PMS1</span><span>PMS2</span>
                            <span>POLD1</span>
                            <!-- <span>PIK3CA</span><span>PMS1</span><span>PMS2</span
                                                                                  ><span>PPP2R2A</span><span>RAD51B</span><span>RAD51C</span
                                                                                  ><span>RAD51D</span><span>RAD54L</span><span>RET</span
                                                                                  ><span>ROS1</span> -->
                          </div>
                          <div class="genes_list">
                            <span>POLE</span><span>POLH</span><span>PTEN</span>
                            <span>RAD50</span><span>RAD51</span><span>RAD51B</span>
                            <span>RAD51C</span><span>RAD51D</span><span>RAD54L</span>
                            <span>RB1</span>
                            <!-- <span>STK11</span><span>TSC1</span><span>TSC2</span> -->
                          </div>
                          <div class="genes_list">
                            <span>RET</span><span>ROS1</span><span>SMAD4</span>
                            <span>STK11</span><span>TP53</span><span>TSC1</span>
                            <span>TSC2</span>
                            <!-- <span>STK11</span><span>TSC1</span><span>TSC2</span> -->
                          </div>
                        </i>
                      </div>

                      <!-- <div class="title py-2 mt-4">
                                                                              <h6 class="mb-0 d-inline-block border-bottom py-2">
                                                                                <b>CNAs</b> Covered in
                                                                                <span class="font-weight-normal">TAR</span><b>GT</b>
                                                                                <span class="clr-cyan pl-2" [ngClass]="'txt-yellow'"
                                                                                  >First</span
                                                                                >
                                                                              </h6>
                                                                            </div> -->
                      <!-- <div class="gene_body" [ngClass]="'gene-bg_blue'">
                                                                              <i>
                                                                                <div class="genes_list">
                                                                                  <span>ABL1</span><span>AR</span><span>ATM</span
                                                                                  ><span>BARD1</span><span>BRCA1</span><span>BRCA2</span
                                                                                  ><span>BRIP1</span><span>CDK12</span><span>CDK4</span
                                                                                  ><span>CDK6</span>
                                                                                </div>
                                                                                <div class="genes_list">
                                                                                  <span>CDKN2A</span><span>CHEK1</span><span>CHEK2</span
                                                                                  ><span>EGFR</span><span>EPCAM</span><span>ERBB2</span
                                                                                  ><span>ERBB3</span><span>EZH2</span><span>FANCL</span
                                                                                  ><span>FGFR3</span>
                                                                                </div>
                                                                                <div class="genes_list">
                                                                                  <span>GAPDH</span><span>IDH2</span><span>JAK2</span
                                                                                  ><span>KIT</span><span>MDM2</span><span>NRAS</span
                                                                                  ><span>PALB2</span><span>PDGFRA</span><span>PDGFRB</span
                                                                                  ><span>PPP2R2A</span>
                                                                                </div>
                                                                                <div class="genes_list">
                                                                                  <span>RAD51B</span><span>RAD51C</span><span>RAD51D</span
                                                                                  ><span>RAD54L</span><span>STK11</span><span>TSC1</span
                                                                                  ><span>TSC2</span>
                                                                                </div>
                                                                              </i>
                                                                            </div> -->
                    </div>
                  </div>
                  <!-- cnv_genes -->

            <!-- <div class="col-12 px-0">
              <div class="genes_info">
                <div class="title py-2">
                  <h6 class="mb-0 d-inline-block border-bottom py-2">
                    <b> CNVs
                    </b> Covered in
                    <span class="clr-cyan pl-2" [ngClass]="'txt-yellow'">OncQuer<sup>TM</sup> Assist Panel</span>
                  </h6>
                </div>
                <div class="genes_body" [ngClass]="'gene-bg_blue'">
                  <i>
                    <div class="genes_list">
                      <span>ABL1</span><span>ALK</span><span>APC</span>
                      <span>AR</span><span>ATM</span><span>BARD1</span>
                      <span>BMPR1A</span><span>BRAF</span><span>BRCA1</span>
                      <span>BRCA2</span>
                    </div>
                    <div class="genes_list bg-white">
                      <span>BRIP1</span><span>CDK12</span><span>CDK4</span>
                      <span>CDK6</span><span>CDKN2A</span><span>CHEK1</span>
                      <span>CHEK2</span><span>CTNNB1</span><span>EGFR</span>
                      <span>EPCAM</span>
                    </div>
                    <div class="genes_list">
                      <span>ERBB2</span><span>ERBB3</span><span>EZH2</span>
                      <span>FANCL</span><span>FGFR1</span><span>FGFR2</span>
                      <span>FGFR3</span><span>GAPDH</span><span>IDH2</span>
                      <span>JAK2</span>
                    </div>
                    <div class="genes_list">
                     <span>KIT</span><span>MDM2</span>
                      <span>MET</span><span>MLH1</span><span>MUTYH</span>
                      <span>NRAS</span><span>PALB2</span><span>PDGFRA</span>
                      <span>PDGFRAB</span><span>POLD1</span>
                    </div>

                    <div class="genes_list">
                      <span>POLE</span><span>POLH</span><span>PTEN</span>
                      <span>RAD50</span><span>RAD51</span><span>RAD51B</span>
                      <span>RAD51C</span><span>RAD51D</span><span>RAD54L</span>
                      <span>RB1</span>
                    </div>
                    <div class="genes_list">
                      <span>RET</span><span>ROS1</span><span>SMAD4</span>
                      <span>STK11</span><span>TP53</span><span>TSC1</span>
                      <span>TSC2</span>
                    </div>
                  </i>
                </div>


              </div>
            </div> -->




                  <div class="col-12 px-0">
                    <div class="genes_info">
                      <div class="title py-2">
                        <h6 class="mb-0 d-inline-block border-bottom py-2">
                          <b>Gene Fusions/Splicing Variations</b> Covered in
                          <span class="clr-cyan pl-2" [ngClass]="'txt-yellow'">OncQuer<sup>TM</sup> Assist Panel</span>
                        </h6>
                      </div>
                      <div class="genes_body" [ngClass]="'gene-bg_blue'">
                        <i>
                          <div class="genes_list">
                          <span>ALK</span><span>FGFR2</span><span>FGFR3</span>
                          <span>MET</span><span>NRG1</span><span>NRG2</span>
                          <span>NTRK1</span><span>NTRK2</span> <span>NTRK3</span><span>RET</span>

                          </div>
                          <div class="genes_list">
                            <span>ROS1</span>
                          </div>
                        </i>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>

            <!--  -->
            <div class="row footer d-flex justify-content-between align-items-center">
              <!-- <div class="col">
                <div class="py-2">
                  <p>
                    <span class="font-weight-bold">Genomics Bihar,</span>
                  </p>
                  <p>Homi Bhabha cancer hospital & Research centre</p>
                  <p>Inside Srikrishna Medical College & Hospital</p>
                  <p>Umanagar, Muzaffarpur-842004.</p>
                  <p>Contact: ngs.mfp@gmail.com</p>
                </div>
              </div>
              <div class="col">
                <p class="text-right">
                  <img
                    src="../../../assets/tmc/tmc_footer.png"
                    alt="footer_image"
                    class="footer_img"
                  />
                </p>
              </div> -->
            </div>
          </div>

          <!-- Page 7 -->
          <div id="page7" class="container position-relative page" style="background-color: white">
              <!-- Header -->
            <div class="d-flex justify-content-between logo_panel py-3">
              <div class="logo_part">
                <img
                style="width:240px!important" src="../../../assets/hcg-new-logo.jpeg"
                  alt=""
                  class="logo_first"
                />
              </div>
              <div style=" border-left: 2px solid #fab216;padding-top:10px;
              height: 120px;"></div>

              <div class="logo_part text-center" style="font-family:arial Mt!important">

                                <h5 style="font-weight: bolder;margin-bottom:0px!important;">Triesta Sciences</h5>
                <p style="font-size: 15px!important;">
                  A Unit of Health Care Global Enterprises Ltd <br/>
                  5th Floor, Tower 1, #8, P.Kalinga Rao Road,<br/>
                  Sampangiram Nagar, Bengaluru-560027. Tel: 080-40206305
                </p>
                <h6 style="font-weight: bolder;font-size:15px!important">Department of Molecular and Clinical Genomics</h6>
              </div>
              <div style=" border-left: 2px solid #fab216;padding-top:10px;
              height: 120px;"></div>
              <div class="logo_part">
                <img  style="width:240px!important; margin-top:40px!important"
                  src="../../../assets//oncoquer1.png"
                  alt=""
                  class="logo_three"
                />
              </div>
            </div>
              <div class="row header mt-1">
              <div></div>
              <div class="col">
                <div class="patient-detailes ml-auto float-right">
                  <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
                    <li>
                      Patient Id <br />
                      <span>{{ report.patient?.organisationPatientId }}</span>
                    </li>
                    <li class="ml-4">
                      Patient Name<br />
                      <span>{{ report.patient?.name }}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <!--  -->
              <div *ngIf="report.clientType == '4basecare_client'">
                <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
                  </div>
                </div>
                <div *ngIf="report.clientName == 'path_labs'" class="col-3">
                  <div class="logo">
                    <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
                  </div>
                </div>
              </div>
            </div>
            <!--  -->
            <div class="mar_top"></div>
            <!--  -->
            <h6 class="py-2 h6_bg mt-3" [ngClass]="'yellow'">TEST METHODOLOGY</h6>
            <!--  -->
            <div class="row mt-3">
              <div class="col">
                <h6 class="font-weight-bold">
                  Sample preparation and Library preparation :
                </h6>
                <!-- <p>
                                                DNA isolated from FFPE, or any other fresh tumor tissue source was
                                                used to perform targeted gene capture using a custom capture kit.
                                                The libraries were sequenced to mean >350X coverage on Illumina
                                                sequencing platform.
                                              </p> -->
                <p style="text-align: justify;">
                  DNA isolated from FFPE, or any other fresh tumor tissue source was used to perform targeted
                  gene captured in OncQuer<sup>TM</sup> Assist Panel using a TARGT First kit from 4baseCare.
                  The libraries were sequenced to mean>350X coverage on Illumina sequencing platform.
                </p>
              </div>
            </div>
            <!--  -->
            <div class="row mt-4">
              <div class="col">
                <h6 class="font-weight-bold">
                  Bioinformatics Analysis and Reporting :
                </h6>
                <!-- <p>
                                                The sequences obtained are aligned to human reference genome
                                                (GRCh37/hg19) and variant analysis was performed using set of
                                                Bioinformatics Pipeline. Only non-synonymous and splice site
                                                variants found in the panel consisting of specific set of genes were
                                                used for clinical interpretation. Silent variations that do not
                                                result in any change in amino acid in the coding region are not
                                                reported. Clinically relevant mutations were annotated using
                                                published variants in literature and a set of databases – ClinVar,
                                                COSMIC and dbSNP. Common variants are filtered based on allele
                                                frequency in 1000 Genome Phase 3, ExAC, dbSNP, gnomAD, etc. In the
                                                absence of a clinically significant reported known variation(s),
                                                pathogenicity will be predicted based on <i>in-silico</i> gene
                                                prioritization tools: CADD, SIFT, PolyPhen-2, Condel and Mutation
                                                taster and prioritized for clinical correlation. The identified
                                                pathogenic variant will be correlated with observed phenotypic
                                                features of the patient and interpreted according to American
                                                College of Medical Genetics (ACMG) guidelines.
                                              </p>
                                              <p class="mb-2 d-block">
                                                Somatic variants are classified into three tiers based on their level
                                                of clinical significance in cancer diagnosis, prognosis, and/or
                                                therapeutics as per international guidelines: ACMG, ASCO, AMP, CAP,
                                                NCCN and ESMO
                                              </p> -->
                <p style="text-align: justify;">
                  The sequences obtained are aligned to human reference genome (GRCh37/hg19) and variant analysis was performed
                  using set of Bioinformatics Pipeline. Only non-synonymous and splice site variants found in the panel
                  consisting of specific set of genes were used for clinical interpretation. Silent variations that do not
                  result in any change
                  in amino acid in the coding region are not reported. Clinically relevant mutations were annotated using
                  published variants in literature and a set of databases – ClinVar, COSMIC
                  and dbSNP. Common variants are filtered based on allele frequency in 1000 Genome Phase 3, ExAC, dbSNP, gnomAD,
                  etc. In the absence of a clinically significant reported
                  known variation(s), pathogenicity will be predicted based on <i>in-silico</i> gene prioritization tools: CADD, SIFT,
                  PolyPhen-2, Condel and Mutation taster and prioritized for clinical
                  correlation. The identified pathogenic variant will be correlated with observed phenotypic features of the patient and interpreted according to ACMG/AMP guidelines.
                </p>
                <p class="mb-2 d-block" style="text-align: justify;">
                  Somatic variants are classified into three tiers based on their level of clinical significance in cancer
                  diagnosis, prognosis, and/or therapeutics as per international guidelines:
                  ACMG, ASCO, AMP, CAP, NCCN and ESMO.
                </p>
              </div>
            </div>

            <h6 class="py-2 h6_bg mt-2" [ngClass]="'yellow'">
              LIMITATIONS AND DISCLAIMER
            </h6>

            <div class="row">
              <div class="col">
                <div class="disc">
                  <ul class="ml-0 pl-4" style="text-align: justify;">
                    <li>
                      OncQuer<sup>TM</sup> Assist Panel  has been developed, validated and
                      has not been cleared or approved by the FDA.
                    </li>
                    <li>
                      DNA studies do not constitute a definitive test for the selected condition(s) in all individuals. It
                      should be realized that there are possible sources of error. Errors can result
                      from trace contamination, rare technical errors, rare genetic variants that interfere with analysis,
                      recent scientific developments, and alternative classification systems. This
                      test should be one of the many aspects used by the healthcare provider to help with a diagnosis and
                      treatment plan.
                    </li>
                    <li>
                      We are using the canonical transcript for clinical reporting which is usually the longest coding
                      transcript with strong/multiple supporting evidence. However, in rare cases,
                      clinically relevant variants annotated in alternate complete coding transcripts could also be reported.
                    </li>
                    <li>
                      The contents of this test should be carefully assessed by the treating physician and further interpreted
                      along with clinical, histopathological findings, contraindications and
                      guidelines before deciding the course of therapy.
                    </li>
                    <li>
                      The CNVs detected must be confirmed by an alternate method, such as IHC, for further clinical management decisions.
                    </li>
                    <!-- <li>
                      The sensitivity of this assay to detect large deletions/duplications of more than 10 bp or copy number
                      variations (CNV) is 70-75%. The CNVs detected have to be confirmed
                      by alternate method.
                    </li> -->
                    <li>
                      Most recent block is recommended for testing as the mutation profile may change in response to treatment
                      and hence differ at different sampling points.
                    </li>
                    <li>
                      The identified pathogenic variant will be correlated with observed phenotypic features of the patient and interpreted according to AMP guidelines.
                    </li>
                    <li>
                      Certain genes may not be covered completely, and few mutations could be missed. A negative result cannot
                      rule out the possibility that the tested tumor sample carries
                      mutations not previously associated with cancer and hence not included in the panel.
                    </li>
                    <li>
                      Our limit of detection for OncQuer<sup>TM</sup> Assist Panel is 5% for SNVs, 10% for InDels and CNV gain &#8805; 6. In addition
                      to this, sequencing quality and coverage is dependent on many factors
                      such as homopolymers, GC-rich regions, intrinsic quality of FFPE DNA and that might impact the variant
                      detection.
                    </li>
                    <li> A negative result does not rule out the possibility of mutations in the patient’s tumor tissue.</li>
                    <li>Certain regions may be undercovered or poorly amplified due to intrinsic nature of the sample or the tumor
                      biology across the panel, due to which the variants may be detected with compromised QC such as less number
                      of unique reads or lack of paired reads. In such instances, a reflex testing on alternate technologies such
                      as ddPCR or Realtime PCR may be suggested.</li>
                  </ul>
                </div>
              </div>
            </div>

            <!--  -->
            <div class="row footer d-flex justify-content-between align-items-center">
              <!-- <div class="col">
                <div class="py-2">
                  <p>
                    <span class="font-weight-bold">Genomics Bihar,</span>
                  </p>
                  <p>Homi Bhabha cancer hospital & Research centre</p>
                  <p>Inside Srikrishna Medical College & Hospital</p>
                  <p>Umanagar, Muzaffarpur-842004.</p>
                  <p>Contact: ngs.mfp@gmail.com</p>
                </div>
              </div>
              <div class="col">
                <p class="text-right">
                  <img
                    src="../../../assets/tmc/tmc_footer.png"
                    alt="footer_image"
                    class="footer_img"
                  />
                </p>
              </div> -->
            </div>
          </div>
        </div>


        </div>
