import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Gene } from 'src/app/_interfaces.ts/admin';
import { CoverageFile } from 'src/app/_interfaces.ts/excel';
import { GermlineReportPdfRes, ReportRes } from 'src/app/_interfaces.ts/reports';
import { ReportService } from 'src/app/_services/report.service';
import jspdf from "jspdf";
import html2canvas from "html2canvas";

@Component({
  selector: 'app-hrr-germline-report',
  templateUrl: './hrr-germline-report.component.html',
  styleUrls: ['./hrr-germline-report.component.css']
})
export class HrrGermlineReportComponent implements OnInit {

  // footerDiscription = "All the 4baseCare test samples are processed at 4basecare Offshore Development Centre (ODC) - Genomics lab inside Wipro campus in Sarjapur. This lab has been accredited by for NABL: ISO 15189:2012 for the lab operations at the address WIPRO LIFE SCIENCES LAB, WIPRO LIMITED, SY. NO. 69P,71/4P,78/8AP,134P,76P,77P,80P,70P, 79/1P, UNIT 1, SARJAPUR ROAD, DODDAKANNELLI VILLAGE, VARTHUR HOBLI, BENGALURU, KARNATAKA, INDIA having NABL 15189 certificate number MC-5155 and test is covered under the scope of this NABL certification"
  footerDiscription ="All the 4baseCare test samples are processed at 4baseCare Offshore Development Centre (ODC) - "+
                "Genomics lab inside Wipro campus in Sarjapur. This lab has been accredited by for NABL: ISO 15109:2012 "+
                "for the lab operations at the address WIPRO LIFE SCIENCES LAB, WIPRO LIMITED, SY. NO 69P,71/4P,70/BAP,134P, "+
                "76P,77P,80P,70P, 79/1P, UNIT 1, SARJAPUR ROAD, DODDAKANNELLI VILLAGE, VARTHUR HOBLI, BENGALURU, KARNATAKA, "+
                "INDIA having NABL 15189 certificate number MC-5155 and test is covered under the scope of this NABL certification "+
                "4baseCare (Genomics Tarang ODC), SJP2-S1-1F-C wing, Wipro Limited, SEZ, Sarjapur 2, SyNo.69 (P), Doddakannelli, "+
                "Sarjapura Road, Bengaluru 560035, KA, India"

  is4basecare = false;
  isReportDownload=false
  reportId!: number;
  tables:any=''
  tag:any=''
  report = {} as ReportRes;
  reportData = false;
  statusMsg!: string;
  downloadSpinner = false;
  todayDate = Date();
  germlineReportPdfRes = {} as GermlineReportPdfRes;

  data: any;

  constructor(
    private reportService: ReportService,
    private routerAct: ActivatedRoute,
    private toastr: ToastrService,
    private titleService: Title
  ) { }

  showSuccess() {
    this.toastr.success(this.statusMsg, '',
      { timeOut: 2000 }
    );
  }
  showFail() {
    this.toastr.error(this.statusMsg, '',
      { timeOut: 10000 }
    );
  }

  ngOnInit(): void {
    this.titleService.setTitle("view report");
    this.germlineReportPdfRes.reportedStarGenes=[]
    this.reportId = this.routerAct.snapshot.params['id'];
    if (this.reportId) {
      this.getReport();
    }
  }

  isPathogenic = false;
  isLikelyPathogenic = false;
  isVUS = false;
  isOthers = false;
  coverageMiddleIndex!: number;
  allcoverageFileList: CoverageFile[] = [];
  firstHalfCoverageFileList: CoverageFile[] = [];
  secondHalfCoverageFileList: CoverageFile[] = [];
  sampleSource!:string;
  referringClinician!:string;
  labHospital!:string;
  getReport() {
    this.reportService.germlineReportPdfResponse(this.reportId).subscribe(
      (response: any) => {
        this.reportData = true;
        this.statusMsg = response.message;
        this.showSuccess();
        this.germlineReportPdfRes = response['payLoad'];
        if(this.germlineReportPdfRes.familyDetails &&  this.germlineReportPdfRes.familyDetails.length>0 &&this.germlineReportPdfRes.familyDetails.length<4){
          let length = +(4-this.germlineReportPdfRes.familyDetails.length)
            for(let i=1;i<=length;i++){
              let familyDetails={
                  id:null,
                  familyType: 'NA',
                  relationship: 'NA',
                  cancerType: 'NA',
                  diagnosisAge: 'NA',

              }
              this.germlineReportPdfRes.familyDetails.push(familyDetails)
            }
        }
        console.log(" this.germlineReportPdfRes",this.germlineReportPdfRes);
        this.allcoverageFileList = this.germlineReportPdfRes.coverageFileList;
        this.coverageMiddleIndex = Math.ceil(this.allcoverageFileList.length / 2);
        this.firstHalfCoverageFileList = this.allcoverageFileList.splice(0, this.coverageMiddleIndex);
        this.secondHalfCoverageFileList = this.allcoverageFileList.splice(-this.coverageMiddleIndex);
        this.firstHalfCoverageFileList.forEach((ele:any)=>{
          ele.coveragePercent=JSON.parse(JSON.stringify(ele.coveragePercent.replace(/\%/g, "")));

        })
        this.secondHalfCoverageFileList.forEach((ele:any)=>{
          ele.coveragePercent=JSON.parse(JSON.stringify(ele.coveragePercent.replace(/\%/g, "")));
        })
        console.log("ele",this.firstHalfCoverageFileList)
        this.report = response['payLoad']['report'];

        if(this.report.patient.organisationName != null){
          if(this.report.patient.organisationName.includes("4baseCare") || this.report.patient.organisationName.includes("4basecare")){
            this.is4basecare = true;
          } else {
            this.is4basecare = false;
          }
        }
        if(this.is4basecare==true && this.report?.labDetails?.hospital.includes("Cytecare")){
          this.referringClinician="Cytecare Hospitals";
          this.labHospital="Cytecare Hospitals"
        }else{
          this.referringClinician=this.report?.labDetails?.referringClinician?.replace(/,/g, ', ');
          this.labHospital=this.report?.labDetails?.hospital;
        }
        this.geneDescriptionReplace(this.germlineReportPdfRes.reportedGenes);
        if (this.report.referenceWebsites != '<p></p>' || this.report.referenceWebsites != null) {
          this.report.referenceWebsites = this.report.referenceWebsites?.replace(/<ul>/g, '');
          this.report.referenceWebsites = this.report.referenceWebsites?.replace(/<\/ul>/g, '');
        }
        if (this.report.labDetails != null) {
          // console.log("sampleScorce-->",this.report.labDetails.sampleSource)
          this.sampleSource = this.report.labDetails?.sampleSource?.replace(/FFPE BlockId:/g, '');
        }
        this.assignSignificanceResult(this.report.significanceResult);
        this.getExonNumbers();
      }
    );
  }

  getExonNumbers() {

    this.germlineReportPdfRes.snvStarVariants.forEach(reportedVariant => {
      reportedVariant.aachangeknownGeneList.forEach((aachangeknownGene, index) => {
        // console.log("==>", aachangeknownGene, reportedVariant.ensembleValue);
        if(reportedVariant.selectedAAChangeknownGene && reportedVariant.selectedAAChangeknownGene.length>0){
          if (aachangeknownGene.includes(reportedVariant.ensembleValue)) {

            reportedVariant.exonNumber = aachangeknownGene.split(":")[2].substring(4);
          }
        }else{
          if (aachangeknownGene == 'UNKNOWN') {
            // console.log("UNKNOWN");
            reportedVariant.selectedAAChangeknownGene = ":::::";
            // console.log("reportedVariant.selectedAAChangeknownGene UNKNOWN", reportedVariant.selectedAAChangeknownGene);
          } else if (aachangeknownGene == '.') {
            reportedVariant.selectedAAChangeknownGene = ":::::";
            // console.log("reportedVariant.selectedAAChangeknownGene dot", reportedVariant.selectedAAChangeknownGene);
          } else if (aachangeknownGene.includes(reportedVariant.ensembleValue)) {
            reportedVariant.selectedAAChangeknownGene = aachangeknownGene.split(":")[3];
            reportedVariant.selectedAAChangeknownGene=reportedVariant.selectedAAChangeknownGene+", "+ aachangeknownGene.split(":")[4];

            reportedVariant.exonNumber = aachangeknownGene.split(":")[2].substring(4);
          }

          // console.log("reportedVariant.selectedAAChangeknownGene", reportedVariant.selectedAAChangeknownGene);


          if (reportedVariant.aachangeknownGeneList.length - 1 == index) {
            // console.log("reportedVariant.selectedAAChangeknownGene index", reportedVariant.selectedAAChangeknownGene);

            if (reportedVariant.selectedAAChangeknownGene == null) {
              let selectedAA:any= reportedVariant.aachangeknownGeneList[0];
              reportedVariant.selectedAAChangeknownGene = selectedAA.split(":")[3];
              reportedVariant.selectedAAChangeknownGene=reportedVariant.selectedAAChangeknownGene+", "+ selectedAA.split(":")[4];
              reportedVariant.exonNumber = reportedVariant.aachangeknownGeneList[0].split(":")[2].substring(4);
              // console.log("reportedVariant.selectedAAChangeknownGene null", reportedVariant.selectedAAChangeknownGene);
            }
          }
        }


      });
    });

    this.germlineReportPdfRes.snvVariants.forEach(reportedVariant => {
      reportedVariant.aachangeknownGeneList.forEach((aachangeknownGene, index) => {
        if(reportedVariant.selectedAAChangeknownGene && reportedVariant.selectedAAChangeknownGene.length>0){
          if (aachangeknownGene.includes(reportedVariant.ensembleValue)) {

            reportedVariant.exonNumber = aachangeknownGene.split(":")[2].substring(4);
          }
        }else{
          if (aachangeknownGene == 'UNKNOWN') {
            // console.log("UNKNOWN");
            reportedVariant.selectedAAChangeknownGene = ":::::";
            // console.log("reportedVariant.selectedAAChangeknownGene UNKNOWN", reportedVariant.selectedAAChangeknownGene);
          } else if (aachangeknownGene == '.') {
            reportedVariant.selectedAAChangeknownGene = ":::::";
            // console.log("reportedVariant.selectedAAChangeknownGene dot", reportedVariant.selectedAAChangeknownGene);
          } else if (aachangeknownGene.includes(reportedVariant.ensembleValue)) {
            reportedVariant.selectedAAChangeknownGene = aachangeknownGene.split(":")[3];
            reportedVariant.selectedAAChangeknownGene=reportedVariant.selectedAAChangeknownGene+", "+ aachangeknownGene.split(":")[4];

            reportedVariant.exonNumber = aachangeknownGene.split(":")[2].substring(4);
          }

          // console.log("reportedVariant.selectedAAChangeknownGene", reportedVariant.selectedAAChangeknownGene);


          if (reportedVariant.aachangeknownGeneList.length - 1 == index) {
            // console.log("reportedVariant.selectedAAChangeknownGene index", reportedVariant.selectedAAChangeknownGene);

            if (reportedVariant.selectedAAChangeknownGene == null) {
              let selectedAA:any= reportedVariant.aachangeknownGeneList[0];
              reportedVariant.selectedAAChangeknownGene = selectedAA.split(":")[3];
              reportedVariant.selectedAAChangeknownGene=reportedVariant.selectedAAChangeknownGene+", "+ selectedAA.split(":")[4];
              reportedVariant.exonNumber = reportedVariant.aachangeknownGeneList[0].split(":")[2].substring(4);
              // console.log("reportedVariant.selectedAAChangeknownGene null", reportedVariant.selectedAAChangeknownGene);
            }
          }

        }
        // console.log("==>", aachangeknownGene, reportedVariant.ensembleValue);


      });
    });
  }

  geneDescriptionReplace(gene: Gene[]) {
    for (var i = 0; i < gene.length; i++) {
      if (gene[i].description != null) {
        gene[i].description = gene[i].description.replace(/<p>/g, '<div>');
        gene[i].description = gene[i].description.replace(/<\/p>/g, '</div>');
        gene[i].description = gene[i].description.replace(/{/g, '<sup>');
        gene[i].description = gene[i].description.replace(/}/g, '</sup>');
        gene[i].description = gene[i].description.replace(/~/g, '<sub>');
        gene[i].description = gene[i].description.replace(/!/g, '</sub>');
      }
    }
  }

  assignSignificanceResult(significanceResult: string){
    let length=this.germlineReportPdfRes.cnaStarVariants.length+this.germlineReportPdfRes.cnaVariants.length+this.germlineReportPdfRes.snvStarVariants.length+this.germlineReportPdfRes.snvVariants.length;
    let pathogenicCount=0
    let likelyPathogenicCount=0;
    let vusCount=0;
    let othersCount=0;
    console.log("length==>\n",length)
    for (let i=0; i<this.germlineReportPdfRes.cnaVariants.length;i++){
      if(this.germlineReportPdfRes.cnaVariants[i].significance == 'Pathogenic'){
        pathogenicCount++;
      }
      if(this.germlineReportPdfRes.cnaVariants[i].significance == 'Likely Pathogenic'){
        likelyPathogenicCount++;
      }
      if(this.germlineReportPdfRes.cnaVariants[i].significance == 'VUS'){
        vusCount++;
      }
      if(this.germlineReportPdfRes.cnaVariants[i].significance == 'Benign'){
        othersCount++;
      }
      if(this.germlineReportPdfRes.cnaVariants[i].significance == 'NA' || this.germlineReportPdfRes.cnaVariants[i].significance == 'Not Classified'){
        othersCount++;
      }
    }
      for (let i=0; i<this.germlineReportPdfRes.snvVariants.length;i++){
        if(this.germlineReportPdfRes.snvVariants[i].significance == 'Pathogenic'){
          pathogenicCount++;
        }
        if(this.germlineReportPdfRes.snvVariants[i].significance == 'Likely Pathogenic'){
          likelyPathogenicCount++;
        }
        if(this.germlineReportPdfRes.snvVariants[i].significance == 'VUS'){
          vusCount++;
        }
        if(this.germlineReportPdfRes.snvVariants[i].significance == 'Benign'){
          othersCount++;
        }
        if(this.germlineReportPdfRes.snvVariants[i].significance == 'NA' || this.germlineReportPdfRes.snvVariants[i].significance == 'Not Classified'){
          othersCount++;
        }
      }
      if(pathogenicCount>0){
        this.isPathogenic =true;
      }
      else if(pathogenicCount==0 && likelyPathogenicCount>0){
        this.isLikelyPathogenic=true;
      }
      else if(pathogenicCount==0 && likelyPathogenicCount==0 && vusCount>0){
        this.isVUS=true;
      }
      else{
        this.isOthers=true;
      }
  }

  downloadReport() {
    this.downloadSpinner = true;
    this.print()
    // let tag:any = document.getElementById("tag");

    // html2canvas(tag).then(canvas => {
    //   // var foreignObjectRendering: true;
    //   var imgWidth = 208;
    //   var imgHeight = (canvas.height * imgWidth) / canvas.width;
    //   const contentDataURL = canvas.toDataURL("image/png");
    //   this.tag=contentDataURL
    //   this.page();

    // });

  }

  page() {
    this.print()
  //   if((this.germlineReportPdfRes.snvVariants.length>0 || this.germlineReportPdfRes.cnaVariants.length>0)){
  //   let data:any = document.getElementById("tables");
  //   html2canvas(data).then(canvas => {
  //     // var foreignObjectRendering: true;
  //     var imgWidth = 208;
  //     var imgHeight = (canvas.height * imgWidth) / canvas.width;
  //     const contentDataURL = canvas.toDataURL("image/png");
  //     var position = 0;
  //     this.tables=contentDataURL
  //     this.print()
  //   });
  // }else{

  // }
  }
  print(){
    this.isReportDownload=true;
    setTimeout(() => {
      this.downloadSpinner = false;
      document.title="tarGT_Hrr_Germline" + "-" + this.report?.patient?.name?.replace(/\./g, ' ')
      window.print()
      this.isReportDownload=false
    }, 3000);
  }
  page1(pdf: jspdf) {
    this.data = document.getElementById("page1");
    html2canvas(this.data).then(canvas => {
      var imgWidth = 208;
      var imgHeight = (canvas.height * imgWidth) / canvas.width;
      const contentDataURL = canvas.toDataURL("image/png");
      var position = 0;
      pdf.addImage(contentDataURL, "JPEG", 0, position, imgWidth, imgHeight, undefined, 'SLOW');
      pdf.addPage();
      // if((this.germlineReportPdfRes.reportedGenes && this.germlineReportPdfRes.reportedGenes.length>0 )|| (this.germlineReportPdfRes.snvVariants && this.germlineReportPdfRes.snvVariants.length>0))
      // {
      //   this.page2(pdf);
      // }
      // if(this.firstHalfCoverageFileList && this.firstHalfCoverageFileList.length>0){
        this.page2(pdf);
      // }else{
      //   this.page3(pdf);
      // }

    });
  }

  page2(pdf: jspdf) {
    this.data = document.getElementById("page2");
    html2canvas(this.data).then(canvas => {
      var imgWidth = 208;
      var imgHeight = (canvas.height * imgWidth) / canvas.width;
      const contentDataURL = canvas.toDataURL("image/png");
      var position = 0;
      pdf.addImage(contentDataURL, "JPEG", 0, position, imgWidth, imgHeight, undefined, 'SLOW');
      pdf.addPage();
      this.page2_2(pdf);
      // if(this.firstHalfCoverageFileList && this.firstHalfCoverageFileList.length>0){

      //   this.page3(pdf);
      // }else{
      //   this.page4(pdf);
      // }
    });
  }

  page2_2(pdf: jspdf) {
    this.data = document.getElementById("page2_2");
    html2canvas(this.data).then(canvas => {
      var imgWidth = 208;
      var imgHeight = (canvas.height * imgWidth) / canvas.width;
      const contentDataURL = canvas.toDataURL("image/png");
      var position = 0;
      pdf.addImage(contentDataURL, "JPEG", 0, position, imgWidth, imgHeight, undefined, 'SLOW');
      pdf.addPage();
      this.page3(pdf);
      // if(this.firstHalfCoverageFileList && this.firstHalfCoverageFileList.length>0){

      //   this.page3(pdf);
      // }else{
      //   this.page4(pdf);
      // }
    });
  }

  page3(pdf: jspdf) {
    this.data = document.getElementById("page3");
    html2canvas(this.data).then(canvas => {
      var imgWidth = 208;
      var imgHeight = (canvas.height * imgWidth) / canvas.width;
      const contentDataURL = canvas.toDataURL("image/png");
      var position = 0;
      pdf.addImage(contentDataURL, "JPEG", 0, position, imgWidth, imgHeight, undefined, 'SLOW');
      pdf.addPage();
      this.page4(pdf);
    });
  }

  page4(pdf: jspdf) {
    this.data = document.getElementById("page4");
    html2canvas(this.data).then(canvas => {
      var imgWidth = 208;
      var imgHeight = (canvas.height * imgWidth) / canvas.width;
      const contentDataURL = canvas.toDataURL("image/png");
      var position = 0;
      pdf.addImage(contentDataURL, "JPEG", 0, position, imgWidth, imgHeight, undefined, 'SLOW');
      pdf.addPage();
      this.page5(pdf);
    });
  }

  page5(pdf: jspdf) {
    this.data = document.getElementById("page5");
    html2canvas(this.data).then(canvas => {
      var imgWidth = 208;
      var imgHeight = (canvas.height * imgWidth) / canvas.width;
      const contentDataURL = canvas.toDataURL("image/png");
      var position = 0;
      pdf.addImage(contentDataURL, "JPEG", 0, position, imgWidth, imgHeight, undefined, 'SLOW');
      pdf.addPage();
      this.page7(pdf);
    });
  }

  page6(pdf: jspdf) {
    this.data = document.getElementById("page6");
    html2canvas(this.data).then(canvas => {
      var imgWidth = 208;
      var imgHeight = (canvas.height * imgWidth) / canvas.width;
      const contentDataURL = canvas.toDataURL("image/png");
      var position = 0;
      pdf.addImage(contentDataURL, "JPEG", 0, position, imgWidth, imgHeight, undefined, 'SLOW');
      pdf.addPage();
      this.page7(pdf);
    });
  }

  page7(pdf: jspdf) {
    this.data = document.getElementById("page7");
    html2canvas(this.data).then(canvas => {
      var imgWidth = 208;
      var imgHeight = (canvas.height * imgWidth) / canvas.width;
      const contentDataURL = canvas.toDataURL("image/png");
      var position = 0;
      pdf.addImage(contentDataURL, "JPEG", 0, position, imgWidth, imgHeight, undefined, 'SLOW');
      pdf.addPage();
      // this.page8(pdf);
      this.page11(pdf);
    });
  }

  page8(pdf: jspdf) {
    this.data = document.getElementById("page8");
    html2canvas(this.data).then(canvas => {
      var imgWidth = 208;
      var imgHeight = (canvas.height * imgWidth) / canvas.width;
      const contentDataURL = canvas.toDataURL("image/png");
      var position = 0;
      pdf.addImage(contentDataURL, "JPEG", 0, position, imgWidth, imgHeight, undefined, 'SLOW');
      pdf.addPage();
      this.page11(pdf);
    });
  }

  page9(pdf: jspdf) {
    this.data = document.getElementById("page9");
    html2canvas(this.data).then(canvas => {
      var imgWidth = 208;
      var imgHeight = (canvas.height * imgWidth) / canvas.width;
      const contentDataURL = canvas.toDataURL("image/png");
      var position = 0;
      pdf.addImage(contentDataURL, "JPEG", 0, position, imgWidth, imgHeight, undefined, 'SLOW');
      pdf.addPage();
      this.page10(pdf);
    });
  }

  page10(pdf: jspdf) {
    this.data = document.getElementById("page10");
    html2canvas(this.data).then(canvas => {
      var imgWidth = 208;
      var imgHeight = (canvas.height * imgWidth) / canvas.width;
      const contentDataURL = canvas.toDataURL("image/png");
      var position = 0;
      pdf.addImage(contentDataURL, "JPEG", 0, position, imgWidth, imgHeight, undefined, 'SLOW');
      pdf.addPage();
      this.page11(pdf);
    });
  }

  page11(pdf: jspdf) {
    this.data = document.getElementById("page11");
    html2canvas(this.data).then(canvas => {
      var imgWidth = 208;
      var imgHeight = (canvas.height * imgWidth) / canvas.width;
      const contentDataURL = canvas.toDataURL("image/png");
      var position = 0;
      pdf.addImage(contentDataURL, "JPEG", 0, position, imgWidth, imgHeight, undefined, 'SLOW');
      this.savePdf(pdf);
    });
  }

  savePdf(pdf: jspdf) {
    console.log("FINISHEDDDDDDDDDDDDDDDDDDDDD")
    this.downloadSpinner = false;
    pdf.save("tarGT_Hrr_Germline" + "-" + this.report?.patient?.name?.replace(/\./g, ' '));
  }

}
