import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { element } from 'protractor';
import { CancerType, ClinicalTrialRes } from 'src/app/_interfaces.ts/admin';
import { CNAVariants, FilteresCSV, FusionVariant } from 'src/app/_interfaces.ts/excel';
import { SelectedTrail, selectedTrailPriority } from 'src/app/_interfaces.ts/trails';
import { ClinicalTrailService } from 'src/app/_services/clinical-trail.service';
import { CnaService } from 'src/app/_services/cna.service';
import { DataAdminService } from 'src/app/_services/data-admin.service';
import { FusionService } from 'src/app/_services/fusion.service';
import { ReportService } from 'src/app/_services/report.service';

@Component({
  selector: 'app-fda-clinical-trails',
  templateUrl: './fda-clinical-trails.component.html',
  styleUrls: ['./fda-clinical-trails.component.css']
})
export class FdaClinicalTrailsComponent implements OnInit {
  @ViewChild('closebutton') closebutton: any;

  fdatitle:string="Trials List";
  fdaShow:string="Show selected trials";
  reportedVariantId!: number;
  cnaVariantId!: number;
  fusionVariantId!: number;
  reportedVariant = {} as FilteresCSV;
  cnaVariant = {} as CNAVariants;
  fusionVariant= {} as FusionVariant;
  clinicalTrialResList: ClinicalTrialRes[] = [];
  selectedClinicalTrialResList: ClinicalTrialRes[] = [];
  isSelectedTrail!: boolean;
  isStarSelectedTrail!: boolean;
  selectedTrail = {} as SelectedTrail;
  selectedTrailList: SelectedTrail[] = [];
  trailsListSpinner = false;
  gene!: string;
  selectedTrailIds: number[] = [];
  selectedStarTrailIds: number[] = [];
  dropdownSettings = {};
  isSubmited!: boolean;
  switchToggleButton!: boolean;
  selectedTrailIdPriority:selectedTrailPriority[] = [];
  constructor(
    private reportService: ReportService,
    private routerAct: ActivatedRoute,
    private trailService: ClinicalTrailService,
    private dataAdminService: DataAdminService,
    private router: Router,
    private cnaService: CnaService,
    private fusionService: FusionService
  ) { }

  ngOnInit(): void {
    this.reportedVariantId = this.routerAct.snapshot.params['id'];
    if (this.reportedVariantId) {
      console.log("this.reportedVariantId", this.reportedVariantId);
      this.getReportedVariant();
    }

    this.cnaVariantId = this.routerAct.snapshot.params['cid'];
    if (this.cnaVariantId) {
      console.log("this.cnaVariantId", this.cnaVariantId);
      this.getCNAVariant();
    }

    this.fusionVariantId = this.routerAct.snapshot.params['fid'];
    if (this.fusionVariantId) {
      console.log("this.fusionVariantId", this.fusionVariantId);
      this.getFusionVariant();
    }

    this.getCancerTypes();

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
  }


  addSelectedTrails(event: any, internalId: number) {
    this.isSelectedTrail = event['target']['checked'];
    console.log(this.isSelectedTrail);

    if (this.isSelectedTrail) {
      this.selectedTrail.isInternal = true;
      this.selectedTrail.isStar = false;
      if (this.reportedVariantId) {
        this.selectedTrail.type = 0;
        this.selectedTrail.referenceId = this.reportedVariant.id;
      } else if (this.cnaVariantId) {
        this.selectedTrail.type = 1;
        this.selectedTrail.referenceId = this.cnaVariant.id;
      } else if (this.fusionVariantId) {
        this.selectedTrail.type = 2;
        this.selectedTrail.referenceId = this.fusionVariant.id;
      }
      this.selectedTrail.internalId = internalId;
      console.log("this.selectedTrail", this.selectedTrail);

      this.trailService.addSelectedTrail(this.selectedTrail).subscribe(
        (response: any) => {
          console.log("addSelectedTrails", response);
          this.reload();

        }
      );

    } else {
      if (this.reportedVariantId) {
        this.removeSelectedTrailMethod(internalId,this.reportedVariant.id,0);
      } else if (this.cnaVariantId) {
        this.removeSelectedTrailMethod(internalId,this.cnaVariant.id,1);
      } else if (this.fusionVariantId) {
        this.removeSelectedTrailMethod(internalId,this.fusionVariant.id,2);
      }
    }
  }

  removeSelectedTrailMethod(internalId: number,referenceId: number, type: number){
    this.trailService.removeSelectedTrail(internalId, referenceId,type).subscribe(
      (response: any) => {
        console.log("removeSelectedTrail", response);
        this.reload();
      }
    );
  }

  addStarSelectedTrails(event: any, internalId: number) {
    this.isStarSelectedTrail = event['target']['checked'];
    console.log("isStarSelectedTrail", this.isStarSelectedTrail);

    if (this.isStarSelectedTrail) {
      if(this.reportedVariantId){
        this.addStarSelectedTrailMethod(internalId,this.reportedVariant.id,0);
      } else if(this.cnaVariantId){
        this.addStarSelectedTrailMethod(internalId,this.cnaVariant.id,1);
      } else if(this.fusionVariantId){
        this.addStarSelectedTrailMethod(internalId,this.fusionVariant.id,2);
      }
    } else {
      if(this.reportedVariantId){
        this.removeStarSelectedTrailMethod(internalId,this.reportedVariant.id,0);
      } else if(this.cnaVariantId){
        this.removeStarSelectedTrailMethod(internalId,this.cnaVariant.id,1);
      } else if(this.fusionVariantId){
        this.removeStarSelectedTrailMethod(internalId,this.fusionVariant.id,2);
      }

    }
  }

  addStarSelectedTrailMethod(internalId: number,referenceId: number, type: number){
    this.trailService.addStarSelectedTrail(internalId, referenceId, type).subscribe(
      (response: any) => {
        console.log("addStarSelectedTrail", response);
      }
    );
  }

  removeStarSelectedTrailMethod(internalId: number,referenceId: number, type: number){
    this.trailService.removeStarSelectedTrail(internalId, referenceId, type).subscribe(
      (response: any) => {
        console.log("addStarSelectedTrail", response);
      }
    );
  }





  isFirstReport = false;
  isHrrSomaticReport=false
  firstCancerType!: string;
  isFist72=false;
  getReportedVariant() {
    this.reportService.getReportVariantById(this.reportedVariantId).subscribe(
      (response: any) => {
        this.reportedVariant = response['payLoad'];
        console.log("this.reportedVariant->",this.reportedVariant)
        this.gene = this.reportedVariant.refGeneX.split(';', 1).toString();
        if (response['success']) {
          console.log("report type==>\n",this.reportedVariant.report.reportType.name)
          if (this.reportedVariant.report.reportType.name == 'First' || this.reportedVariant.report.reportType.name == 'First 72 Solid' || this.reportedVariant.report.reportType.name == 'First 72 Liquid' || this.reportedVariant.report.reportType.name == 'Lung Liquid Basic' || this.reportedVariant.report.reportType.name == 'Lung Liquid Advanced'   || this.reportedVariant.report.reportType.name == 'HRR Somatic'){
            this.isFirstReport = true;
            this.firstCancerType = this.reportedVariant.report.firstCancerType?.name;
            this.fdatitle="Drugs List";
            this.fdaShow="show selected drugs";
            console.log("report type",this.reportedVariant.report.reportType.name);

          this.getAllHotspotClinicalTrailByGene(this.reportedVariantId, 0);
          } else {
            this.getAllClinicalTrailByGene(this.reportedVariantId, 0);
          }
          if(this.reportedVariant.report.reportType.name == 'First 72 Solid' || this.reportedVariant.report.reportType.name == 'First 72 Liquid'){
            this.isFist72=true
          }
        }

      }
    );
  }
  accepted=false;

  deSelectOption(event:any){
    console.log("accept->",event['target']['checked'])
    if (event['target']['checked']) {
      if (confirm('Are you sure you want unselect..!')) {
        this.reportService
          .reportVariantDeSelectAllTrails(this.reportedVariant.id)
          .subscribe((response: any) => {
            console.log("===>",response);
            if (response['success'] == true) {
              this.reload();
            }
          });
      } else {
        this.reload();
      }
    }
  }
  getCNAVariant() {
    this.cnaService.getCNAVariant(this.cnaVariantId).subscribe(
      (response: any) => {
        this.cnaVariant = response['payLoad'];
        this.gene = this.cnaVariant.gene.split(';', 1).toString();
        console.log("cnaVariant", this.cnaVariant);

        if (response['success']) {
          this.fdatitle="Drugs List";
          this.fdaShow="show selected drugs";
          if (this.cnaVariant.report.reportType.name == 'First' || this.cnaVariant.report.reportType.name == 'First 72 Solid' ||this.cnaVariant.report.reportType.name == 'First 72 Liquid' || this.fusionVariant.report.reportType.name == 'Lung Liquid Basic'|| this.fusionVariant.report.reportType.name == 'Lung Liquid Advanced'  || this.cnaVariant.report.reportType.name == 'HRR Somatic'){
            this.isFirstReport = true;
            this.firstCancerType = this.cnaVariant.report.firstCancerType.name;
            this.getAllHotspotClinicalTrailByGene(this.cnaVariantId, 1);
          } else {
          this.getAllClinicalTrailByGene(this.cnaVariantId, 1);
          }
        }

      }
    );
  }

  fusionGenes: string[] = [];
  getFusionVariant() {
    this.fusionGenes = [];
    this.fusionService.getFusionVariant(this.fusionVariantId).subscribe(
      (response: any) => {
        this.fusionVariant = response['payLoad'];
        let fusionGene =this.fusionVariant.fusionGene;
        let fusionGene1 = this.fusionVariant.fusionGene.split('--')[0];
        let fusionGene2 = this.fusionVariant.fusionGene.split('--')[1];
        this.gene = fusionGene1;
        this.fusionGenes.push(fusionGene1);
        this.fusionGenes.push(fusionGene2);
        this.fusionGenes.push(fusionGene1 + "--");
        this.fusionGenes.push(fusionGene2 + "--");
        this.fusionGenes.push("--" + fusionGene1);
        this.fusionGenes.push("--" + fusionGene2);
        this.fusionGenes.push(fusionGene)
        console.log("fusionVariant", this.fusionVariant);

        if (response['success']) {
          if (this.fusionVariant.report.reportType.name == 'First' || this.fusionVariant.report.reportType.name == 'First 72 Solid' || this.fusionVariant.report.reportType.name == 'First 72 Liquid' || this.fusionVariant.report.reportType.name == 'Lung Liquid Basic'|| this.fusionVariant.report.reportType.name == 'Lung Liquid Advanced'  || this.fusionVariant.report.reportType.name == 'HRR Somatic'){
            this.isFirstReport = true;
            this.firstCancerType = this.fusionVariant.report.firstCancerType.name;
            this.fdatitle="Drugs List";
            this.fdaShow="show selected drugs";
            this.getAllHotspotClinicalTrailByGene(this.fusionVariantId, 2);
          } else {
          this.getAllClinicalTrailByGene(this.fusionVariantId, 2);
          }
        }

      }
    );
  }

  onChangeFusionGene(){
    this.clinicalTrialResList = [];
    if (this.fusionVariant.report.reportType.name == 'First' || this.fusionVariant.report.reportType.name == 'First 72 Solid'|| this.fusionVariant.report.reportType.name == 'Lung Liquid Basic'|| this.fusionVariant.report.reportType.name == 'Lung Liquid Advanced' || this.fusionVariant.report.reportType.name == 'First 72 Liquid'|| this.fusionVariant.report.reportType.name == 'HRR Somatic'){
      this.getAllHotspotClinicalTrailByGene(this.fusionVariantId, 2);
    } else {
      this.getAllClinicalTrailByGene(this.fusionVariantId, 2);
    }
  }



  getSelectedTrails(referenceId: number, type: number) {
    this.trailService.getSelectedTrails(referenceId, type,true).subscribe(
      (response: any) => {
        this.selectedTrailList = response['payLoad'];
        console.log("getSelectedTrails", this.selectedTrailList);
        this.selectedTrailList.forEach(
          element => {
            this.selectedTrailIds.push(element.internalId);
            this.selectedTrailIdPriority.push({id:element.internalId,priorityOrder:element.priorityOrder})
            if (element.isStar) {
              this.selectedStarTrailIds.push(element.internalId);
            }
          }
        )

        this.clinicalTrialResList.forEach(
          element => {
            if (this.selectedTrailIds.includes(element.id)) {
              element.selected = true;
              this.selectedTrailIdPriority.forEach(ele=>{
                if(ele.id==element.id){
                  element.priorityOrder=ele.priorityOrder
                }
              })
              if (this.checkedTrailList) {
                console.log("pushing");
                this.selectedClinicalTrialResList.push(element);
              }
            }
            if (this.selectedStarTrailIds.includes(element.id)) {
              element.isStarSelected = true;
            }

            this.selectedTrailList.forEach(
              selectedTrail => {
                if (element.id == selectedTrail.internalId) {
                  element.cancerTypeEnum = selectedTrail.cancerTypeEnum;
                  element.cancerTypes = selectedTrail.cancerTypes;
                }
              }
            );
          }
        );

        if (this.checkedTrailList) {
          console.log("this.selectedClinicalTrialResList", this.selectedClinicalTrialResList);
          this.clinicalTrialResList = this.selectedClinicalTrialResList;
          this.trailsListSpinner = true;
        }


      }
    );
  }

  cancerIds: number[] = [];
  assignedClinicalTrail = {} as ClinicalTrialRes;
  assignClinicalTrail(clinicalTrial: ClinicalTrialRes) {
    this.assignedClinicalTrail = clinicalTrial;
  }

  editSelectedTrail() {
    this.closebutton.nativeElement.click();
    this.selectedTrail.internalId = this.assignedClinicalTrail.id;
    if(this.reportedVariantId){
      this.selectedTrail.type = 0;
      this.selectedTrail.referenceId = this.reportedVariantId;
    } else if(this.cnaVariantId){
      this.selectedTrail.type = 1;
      this.selectedTrail.referenceId = this.cnaVariant.id;
    } else if(this.fusionVariantId){
      this.selectedTrail.type = 2;
      this.selectedTrail.referenceId = this.fusionVariant.id;
    }

    this.selectedTrail.cancerTypeEnum = this.assignedClinicalTrail.cancerTypeEnum;
    if (this.assignedClinicalTrail.cancerTypes.length > 0) {
      for (let i = 0; i < this.assignedClinicalTrail.cancerTypes.length; i++) {
        const id = this.assignedClinicalTrail.cancerTypes[i]['id'];
        this.cancerIds.push(id);
      }
    }
    this.selectedTrail.cancerIds = this.cancerIds;

    console.log(this.selectedTrail);

    this.trailService.editSelectedTrail(this.selectedTrail).subscribe(
      (response: any) => {
        console.log(response);
        this.cancerIds = [];
      }
    );
  }

  getAllClinicalTrailByGene(referenceId: number, type: number) {
    this.dataAdminService.getAllClinicalTrailByGene(this.gene).subscribe(
      (response: any) => {
        this.trailsListSpinner = true;
        this.clinicalTrialResList = response['payLoad'];
        console.log("getAllClinicalTrailByGene  db", this.clinicalTrialResList);
        this.getSelectedTrails(referenceId, type);
      }
    );
  }

  getAllHotspotClinicalTrailByGene(referenceId: number, type: number) {
    this.dataAdminService.getAllHotspotClinicalTrailByGene(this.gene).subscribe(
      (response: any) => {
        this.trailsListSpinner = true;
        this.clinicalTrialResList = response['payLoad'];
        console.log("getAllHotspotClinicalTrailByGene db", this.clinicalTrialResList);
        this.getSelectedTrails(referenceId, type);
      }
    );
  }


  cancerTypeList: CancerType[] = [];
  getCancerTypes() {
    this.dataAdminService.cancerTypeList().subscribe(
      (response: any) => {
        this.cancerTypeList = response['payLoad'];
        console.log("cancerTypeList ==> ", this.cancerTypeList);

      }
    );
  }

  checkedTrailList!: boolean;
  selectedTrailsList(event: any){
    this.checkedTrailList = event['target']['checked'];
    this.trailsListSpinner = false;
    this.switchToggleButton=event['target']['checked'];
    console.log(this.checkedTrailList);
    if (this.checkedTrailList) {
      if(this.reportedVariantId){
        this.getSelectedTrails(this.reportedVariantId,0);
      } else if(this.cnaVariantId){
        this.getSelectedTrails(this.cnaVariantId,1);
      } else if(this.fusionVariantId){
        this.getSelectedTrails(this.fusionVariantId,2);
      }

    } else {
      this.selectedClinicalTrialResList = [];
      this.clinicalTrialResList = [];
      if(this.reportedVariantId){
        this.getReportedVariant();
      } else if(this.cnaVariantId){
        this.getCNAVariant();
      } else if(this.fusionVariantId){
        this.getFusionVariant();
      }
    }
  }

  backReportedVariant() {
    if (this.reportedVariantId) {
      this.router.navigate(['/report', this.reportedVariant.report.id, 'view_variant', this.reportedVariant.id]);
    } else if (this.cnaVariantId) {
      this.router.navigate(['/report', this.cnaVariant.report.id, 'cna_variant', this.cnaVariant.id]);
    } else if (this.fusionVariantId) {
      this.router.navigate(['/report', this.fusionVariant.report.id, 'fusion_variant', this.fusionVariant.id]);
    }
  }

  reload() {
    console.log("reload");
    this.router.navigateByUrl('/reports', { skipLocationChange: true }).then(() => {
      if (this.reportedVariantId) { this.router.navigate(['/fda_trail_list', this.reportedVariant.id]); }
      if (this.cnaVariantId) { this.router.navigate(['/cna_fda_trail_list', this.cnaVariant.id]); }
      if (this.fusionVariantId) { this.router.navigate(['/fusion_fda_trail_list', this.fusionVariant.id]); }

    });
  }

  priorityOrder(orderNumber: number, selectedTrailsId: any){
    if(orderNumber != null && orderNumber > 0){
      this.selectedTrailList.forEach((element:any)=>{
        if(element.internalId==selectedTrailsId)
        {
          if(element.id){
            this.reportService.setTailsPriorityOrder(element.id, orderNumber).subscribe(
              (response: any) => {
                console.log("setPriorityOrder",response);
              }
            );
          }

        }

      })

    }
  }

}
